import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import { MAIL_FUNCTION_URL, FUNCTION_API_KEY, GATEWAY_URL } from '../../config/siteConfig';
import { getDateInUTC } from './../livechat/components/Comman';
import parse from 'html-react-parser';
import { checkWidgetStatus } from '../../services/service';
import dummyData from '../../localization/dummy-data.json';
import ReactTooltip from "react-tooltip";
import firebaseServices from '../../firebase';
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';

const NEW_VISITOR_STATUS = "incoming";

class Chatanalytics extends React.Component {

    incomingSnapshot = null;
    allDataSnapShot = null;
    agentsSnapShot = null;
    onlineInterval = null;
    incomingVisitorData = [];
    allVisitorData = [];
    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    timer = null;
    isRunNext = true;
    hasStarted = false;

    constructor() {
        super();
        this.state = {
            loader: false,
            domain_list: [],
            totalIncomingVisitors: 0,
            currentIncoming: {},
            currentIncomingChatId: "",
            allChats: [],
            wait_time_min: 0,
            wait_time_max: 0,
            wait_time_avg: 0,
            chat_duration_min: 0,
            chat_duration_max: 0,
            chat_duration_avg: 0,
            totalMissedChats: 0,
            totalServedChats: 0,
            response_time_min: 0,
            response_time_max: 0,
            response_time_avg: 0,
            totalAgents: 0,
            onlineAgents: 0,
            online_duration_min: 0,
            online_duration_max: 0,
            online_duration_avg: 0,
            served_chat_min: 0,
            served_chat_max: 0,
            served_chat_avg: 0,
            satisfactionOverallProduct: 0,
            satisfactionGoodProduct: 0,
            satisfactionBadProduct: 0,
            showDummyData: false,
            isPageLoad: true,
            feedback_type: 1,

            fiveStarRatingProduct: 0,
            fourStarRatingProduct: 0,
            threeStarRatingProduct: 0,
            twoStarRatingProduct: 0,
            oneStarRatingProduct: 0,
            averageStarRatingProduct: 0,

            fiveStarRatingAgent: 0,
            fourStarRatingAgent: 0,
            threeStarRatingAgent: 0,
            twoStarRatingAgent: 0,
            oneStarRatingAgent: 0,
            averageStarRatingAgent: 0,

            satisfactionOverallAgent: 0,
            satisfactionGoodAgent: 0,
            satisfactionBadAgent: 0,
            payment_status: true,
            multi_feedback_switch: false
        }
    }

    /* Private functions start */

    secondsToHms = (d) => {
        d = Number(d);
        if (d === 0) {
            var hDisplay = "<span class='avggbig ml-2 big'>0</span><span class='small'>m</span>";
            hDisplay += "<span class='avggbig ml-2 big'>0</span><span class='small'>s</span>";
            return hDisplay;
        }
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? "<span class='avggbig ml-2 big'> " + h + " </span>" + "<span class='small'> " + "h" + " </span>" : "";
        var mDisplay = m > 0 ? "<span class='avggbig asa ml-2 big'> " + m + " </span>" + "<span class='small'> " + "m" + "</span>" : "";
        var sDisplay = s > 0 ? "<span class='avggbig ml-2 big'> " + s + " </span>" + "<span class='small'> " + "s" + "</span>" : "";
        return hDisplay + mDisplay + sDisplay;
    }

    secondsToTime = (secs) => {
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        if (hours > 0) {
            hours = (hours).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            minutes = (minutes).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            seconds = (seconds).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            return '<span class="big">' + hours + '</span><span class="small">h</span><span class="big">' + minutes + '</span><span class="small">m</span><span class="big">' + seconds + '</span><span class="small">s</span>';
        } else {
            minutes = (minutes).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            seconds = (seconds).toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false });
            return '<span class="big">' + minutes + '</span><span class="small">m</span><span class="big">' + seconds + '</span><span class="small">s</span>';
        }
    }

    delayFirstTime = () => {
        return new Promise(res => setTimeout(res, 2000));
    }

    showIncomingVisitors = async (visitorData) => {
        //console.log("--------------     START     -----------------");
        if (this.state.isPageLoad) {
            this.setState({ isPageLoad: false });
            await this.delayFirstTime();
        }

        let filteredVisitors = visitorData.filter(data => {
            return (data.isChatend === 0) ? true : false;
        });

        if (!this.isRunNext && filteredVisitors.length === 0) {
            return false;
        }

        if (filteredVisitors.length > 0) {
            this.isRunNext = true;
        } else {
            this.isRunNext = false;
        }
        var incVisitors = filteredVisitors;
        incVisitors.sort(function (a, b) { return b.formStartTime - a.formStartTime; });
        let totalActiveIncChats = incVisitors.length;

        /* let totalActiveIncChats = incVisitors.reduce((all, item, index) => {
            all += (item.isChatend === 0) ? 1 : 0;
            return all;
        }, 0); */

        let currInc = {};
        let currIncChatId = "";
        if (totalActiveIncChats > 0) {
            currInc = totalActiveIncChats > 0 ? incVisitors[0] : {};
            currIncChatId = Object.keys(currInc).length > 0 ? currInc.id : "";
        }

        if (currIncChatId === "") {
            this.clearIntervalData();
        } else if (currIncChatId !== "" && currIncChatId !== this.state.currentIncomingChatId) {
            ///console.log("-------------    REPLACED the visitor   ---------------");
            this.hasStarted = false;
            try {
                clearInterval(this.timer);
                this.timer = null;
            } catch (errr) { }
        } else {
            //console.log("-------------   ELSE First >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  ---------------");
        }

        let currChatStartTime = (Object.keys(currInc).length > 0 && currInc.formStartTime) ? currInc.formStartTime : "";
        this.setState({ currentIncoming: currInc, currentIncomingChatId: currIncChatId, totalIncomingVisitors: totalActiveIncChats }, async () => {
            if (Object.keys(currInc).length >= 0 && currIncChatId === "") {
                this.clearIntervalData();
            } else if (!this.timer && currIncChatId && currChatStartTime && !this.hasStarted) {
                //console.log("-----------------    COMING TO Start  111111111  -----------------------")
                this.hasStarted = true;
                let serverTimestamp = await getDateInUTC();
                // console.log("-----------------    COMING TO Start  2222222222  -----------------------")
                /*  try {
                     clearInterval(this.timer);
                 } catch (errr) {} */
                var i = parseInt((Math.ceil(serverTimestamp - currChatStartTime) / 1000));
                this.timer = setInterval(() => {
                    try {
                        var fetchtime = this.secondsToTime(i);
                        document.getElementById('currentwaittime').innerHTML = fetchtime;
                        i++;
                    } catch (errr) { }
                }, 1000);
            } else {
                //console.log("-------------   ELSE SetInterval ---------------");
            }
        });
    }

    clearIntervalData = () => {
        try {
            clearInterval(this.timer);
            this.timer = null;
        } catch (errr) { }
        try {
            document.getElementById('currentwaittime').innerText = "-- : --";
        } catch (errr) { }
    }

    showAllData = () => {
        let allChats = this.state.allChats;
        let feedback_type = this.state.feedback_type;
        console.log('feedbac_type', feedback_type);

        let wait_time_array = [];
        let wait_time_min = 0;
        let wait_time_max = 0;
        let wait_time_avg = 0;

        let chat_duration_array = [];
        let chat_duration_min = 0;
        let chat_duration_max = 0;
        let chat_duration_avg = 0;

        let missed_chat_array = [];
        let totalMissedChats = 0;

        let served_chat_array = [];
        let chatByAgentsObj = {};
        let totalServedChats = 0;
        let served_chat_min = 0;
        let served_chat_max = 0;
        let served_chat_avg = 0;

        let response_time_array = [];
        let response_time_min = 0;
        let response_time_max = 0;
        let response_time_avg = 0;

        let satisfactionArrayProduct = [];
        let satisfactionOverallProduct = 0;
        let satisfactionGoodProduct = 0;
        let satisfactionBadProduct = 0;

        let fiveStarRatingProduct = 0;
        let fourStarRatingProduct = 0;
        let threeStarRatingProduct = 0;
        let twoStarRatingProduct = 0;
        let oneStarRatingProduct = 0;
        let averageStarRatingProduct = 0;

        let fiveStarRatingAgent = 0;
        let fourStarRatingAgent = 0;
        let threeStarRatingAgent = 0;
        let twoStarRatingAgent = 0;
        let oneStarRatingAgent = 0;
        let averageStarRatingAgent = 0;

        let satisfactionArrayAgent = [];
        let satisfactionOverallAgent = 0;
        let satisfactionGoodAgent = 0;
        let satisfactionBadAgent = 0;

        allChats.forEach(visitor => {

            if (visitor.isChatend === 1 && visitor.agent_ids.length > 0 && visitor.agent_start !== "" && (visitor.visitor_status === "ongoing" || visitor.visitor_status === "close")) {
                served_chat_array.push(visitor);
                if (chatByAgentsObj[visitor.agent_start] === undefined) {
                    chatByAgentsObj[visitor.agent_start] = [];
                }
                chatByAgentsObj[visitor.agent_start].push(visitor.id);

                if (visitor.feedback_received === 1) {
                    satisfactionArrayProduct.push(visitor.feedback_score);
                }
                if (visitor.feedback_received_agent === 1) {
                    satisfactionArrayAgent.push(visitor.feedback_score_agent);
                }
            }

            if (visitor.chat_response_time) {
                wait_time_array.push(visitor.chat_response_time);
            }

            if (visitor.hasOwnProperty('chat_duration') && visitor.chat_duration) {
                chat_duration_array.push(visitor.chat_duration);
            }

            if (visitor.isChatend === 1 && visitor.agent_ids.length === 0 && visitor.agent_start === "" && (visitor.visitor_status === "missed" || visitor.visitor_status === "incoming")) {
                missed_chat_array.push(visitor.id);
            }

            if (visitor.hasOwnProperty('first_res_time')) {
                let firstResTime = visitor.first_res_time - visitor.picktime;
                firstResTime = Math.ceil(firstResTime / 1000);
                if (!isNaN(firstResTime) && firstResTime > 0) {
                    response_time_array.push(firstResTime);
                }
            }
        });

        /* Calculate Wait Time data */

        let sortedArr = wait_time_array.sort((a, b) => a - b);
        let totalWaitTimecount = sortedArr.length;
        if (totalWaitTimecount > 0) {
            wait_time_min = sortedArr[0];
            wait_time_max = sortedArr[totalWaitTimecount - 1];
            let sumOfWaitTime = sortedArr.reduce((a, b) => a + b, 0);
            wait_time_avg = Math.ceil(sumOfWaitTime / totalWaitTimecount)
        }

        /* Calculate Wait Time data */

        /* Calculate Wait Time data */

        let sortedDurationArr = chat_duration_array.sort((a, b) => a - b);
        let totalDurationcount = sortedDurationArr.length;
        if (totalDurationcount > 0) {
            chat_duration_min = sortedDurationArr[0];
            chat_duration_max = sortedDurationArr[totalDurationcount - 1];
            let sumOfDurationTime = sortedDurationArr.reduce((a, b) => a + b, 0);
            chat_duration_avg = Math.ceil(sumOfDurationTime / totalDurationcount)
        }

        /* Calculate Wait Time data */


        /* Calculate Missed Chat data */

        totalMissedChats = missed_chat_array.length;

        /* Calculate Missed Chat data */

        /* Calculate Served Chat data */

        let agentLength = Object.keys(chatByAgentsObj).length;
        if (agentLength > 0) {
            let array22 = Object.keys(chatByAgentsObj).map(key => {
                return chatByAgentsObj[key].length;
            });
            served_chat_min = Math.min.apply(0, array22);
            served_chat_max = Math.max.apply(0, array22);
        }

        totalServedChats = served_chat_array.length;
        if (agentLength > 0) {
            served_chat_avg = Math.ceil(totalServedChats / agentLength);
        }

        /* Calculate Served Chat data */

        /* Calculate Response Time data */

        let sortedArr22 = response_time_array.sort((a, b) => a - b);
        let totalrescount = sortedArr22.length;
        if (totalrescount > 0) {
            response_time_min = sortedArr22[0];
            response_time_max = sortedArr22[totalrescount - 1];
            let sumOfResTime = sortedArr22.reduce((a, b) => a + b, 0);
            response_time_avg = Math.ceil(sumOfResTime / totalrescount)
        }

        /* Calculate Response Time data */

        /* Calculate Satisfaction data */

        let totalFeedbackProduct = 0;
        if (satisfactionArrayProduct.length > 0) {
            let tmpRatedChatsArray = satisfactionArrayProduct.reduce((all, item) => {
                if (0 <= item && item <= 5) {
                    totalFeedbackProduct += 1;
                    all["sum"] += item;
                    all['5star'] = item === 5 ? all['5star'] + 1 : all['5star'];
                    all['4star'] = item === 4 ? all['4star'] + 1 : all['4star'];
                    all['3star'] = item === 3 ? all['3star'] + 1 : all['3star'];
                    all['2star'] = item === 2 ? all['2star'] + 1 : all['2star'];
                    all['1star'] = item === 1 ? all['1star'] + 1 : all['1star'];
                    if (item > 2) {
                        all["good"] += 1;
                        all["goodsum"] += item;
                    } else {
                        all["bad"] += 1;
                        all["badsum"] += item;
                    }
                }
                return all;
            }, { sum: 0, '5star': 0, '4star': 0, '3star': 0, '2star': 0, '1star': 0, good: 0, bad: 0 });

            // let tmpRatingArray = satisfactionArrayProduct.reduce((all, item) => {
            //     all['5star'] = item === 5 ? all['5star'] + 1 : all['5star'];
            //     all['4star'] = item === 4 ? all['4star'] + 1 : all['4star'];
            //     all['3star'] = item === 3 ? all['3star'] + 1 : all['3star'];
            //     all['2star'] = item === 2 ? all['2star'] + 1 : all['2star'];
            //     all['1star'] = item === 1 ? all['1star'] + 1 : all['1star'];
            //     return all;
            // }, {'5star' : 0, '4star' : 0, '3star' : 0, '2star' : 0, '1star' : 0});

            // console.log('tmpRatingArray >>>', tmpRatingArray);

            averageStarRatingProduct = (tmpRatedChatsArray.sum / totalFeedbackProduct).toFixed(1);
            fiveStarRatingProduct = tmpRatedChatsArray['5star'] ? tmpRatedChatsArray['5star'] : 0;
            fourStarRatingProduct = tmpRatedChatsArray['4star'] ? tmpRatedChatsArray['4star'] : 0;
            threeStarRatingProduct = tmpRatedChatsArray['3star'] ? tmpRatedChatsArray['3star'] : 0;
            twoStarRatingProduct = tmpRatedChatsArray['2star'] ? tmpRatedChatsArray['2star'] : 0;
            oneStarRatingProduct = tmpRatedChatsArray['1star'] ? tmpRatedChatsArray['1star'] : 0;
            // satisfactionOverallProduct = Math.ceil((tmpRatedChatsArray.sum / (totalFeedbackProduct * 5)) * 100);
            // satisfactionGoodProduct = satisfactionOverallProduct;
            // satisfactionBadProduct = 100 - satisfactionOverallProduct;
            satisfactionGoodProduct = tmpRatedChatsArray['good'];
            satisfactionBadProduct = tmpRatedChatsArray['bad'];
            satisfactionOverallProduct = Math.ceil(satisfactionGoodProduct / (satisfactionGoodProduct + satisfactionBadProduct) * 100);
        }
        let totalFeedbackAgent = 0;
        if (satisfactionArrayAgent.length > 0) {
            let tmpRatedChatsArray = satisfactionArrayAgent.reduce((all, item) => {
                if (0 <= item && item <= 5) {
                    totalFeedbackAgent += 1;
                    all["sum"] += item;
                    all['5star'] = item === 5 ? all['5star'] + 1 : all['5star'];
                    all['4star'] = item === 4 ? all['4star'] + 1 : all['4star'];
                    all['3star'] = item === 3 ? all['3star'] + 1 : all['3star'];
                    all['2star'] = item === 2 ? all['2star'] + 1 : all['2star'];
                    all['1star'] = item === 1 ? all['1star'] + 1 : all['1star'];
                    if (item > 2) {
                        all["good"] += 1;
                        all["goodsum"] += item;
                    } else {
                        all["bad"] += 1;
                        all["badsum"] += item;
                    }
                }
                return all;
            }, { sum: 0, '5star': 0, '4star': 0, '3star': 0, '2star': 0, '1star': 0, good: 0, bad: 0 });

            averageStarRatingAgent = (tmpRatedChatsArray.sum / totalFeedbackAgent).toFixed(1);
            fiveStarRatingAgent = tmpRatedChatsArray['5star'] ? tmpRatedChatsArray['5star'] : 0;
            fourStarRatingAgent = tmpRatedChatsArray['4star'] ? tmpRatedChatsArray['4star'] : 0;
            threeStarRatingAgent = tmpRatedChatsArray['3star'] ? tmpRatedChatsArray['3star'] : 0;
            twoStarRatingAgent = tmpRatedChatsArray['2star'] ? tmpRatedChatsArray['2star'] : 0;
            oneStarRatingAgent = tmpRatedChatsArray['1star'] ? tmpRatedChatsArray['1star'] : 0;

            satisfactionGoodAgent = tmpRatedChatsArray['good'];
            satisfactionBadAgent = tmpRatedChatsArray['bad'];
            satisfactionOverallAgent = Math.ceil(satisfactionGoodAgent / (satisfactionGoodAgent + satisfactionBadAgent) * 100);
        }
        /* Calculate Satisfaction data */

        this.setState({ wait_time_min: wait_time_min, wait_time_max: wait_time_max, wait_time_avg: wait_time_avg, chat_duration_min: chat_duration_min, chat_duration_max: chat_duration_max, chat_duration_avg: chat_duration_avg, totalMissedChats: totalMissedChats, totalServedChats: totalServedChats, response_time_min: response_time_min, response_time_max: response_time_max, response_time_avg: response_time_avg, served_chat_min: served_chat_min, served_chat_max: served_chat_max, served_chat_avg: served_chat_avg, satisfactionOverallProduct: satisfactionOverallProduct, satisfactionGoodProduct: satisfactionGoodProduct, satisfactionBadProduct: satisfactionBadProduct, loader: false, averageStarRatingProduct: averageStarRatingProduct, fiveStarRatingProduct: fiveStarRatingProduct, fourStarRatingProduct: fourStarRatingProduct, threeStarRatingProduct: threeStarRatingProduct, twoStarRatingProduct: twoStarRatingProduct, oneStarRatingProduct: oneStarRatingProduct, averageStarRatingAgent: averageStarRatingAgent, fiveStarRatingAgent: fiveStarRatingAgent, fourStarRatingAgent: fourStarRatingAgent, threeStarRatingAgent: threeStarRatingAgent, twoStarRatingAgent: twoStarRatingAgent, oneStarRatingAgent: oneStarRatingAgent, satisfactionOverallAgent: satisfactionOverallAgent, satisfactionGoodAgent: satisfactionGoodAgent, satisfactionBadAgent: satisfactionBadAgent });
    }

    /* Private functions end  */

    loadIncomingChats = () => {
        this.incomingSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("visitor_status", "==", NEW_VISITOR_STATUS).orderBy('updatedon', 'desc').onSnapshot((visitors) => {
            visitors.docChanges().forEach((visitor) => {
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;
                const visitor_Id = visitor.doc.id;

                if (visitor.type === "added") {
                    if (vistSnap.incoming === 1) {
                        const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                        if (index === -1) {
                            this.incomingVisitorData.splice(0, 0, vistSnap); // Insert the new one 
                        }
                    } else if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.incomingVisitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "modified") {
                    if (vistSnap.incoming === 1) {
                        const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.incomingVisitorData.splice(index, 1, vistSnap); // replace value of an index
                        } else {
                            this.incomingVisitorData.splice(0, 0, vistSnap); // Insert the new one 
                        }
                    } else if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.incomingVisitorData.splice(index, 1);
                        }
                    }
                } else if (visitor.type === "removed") {
                    if (vistSnap.incoming === 2 || vistSnap.incoming === 3 || vistSnap.visitor_status === "close" || vistSnap.visitor_status === "ongoing") {
                        const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                        if (index !== -1) {
                            this.incomingVisitorData.splice(index, 1);
                        }
                    } else {
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(visitor_Id).get().then((doc) => {
                            if (doc.exists) {
                                var _data = doc.data();
                                if (_data.visitor_status === "close" || _data.visitor_status === "ongoing" || _data.incoming === 2 || _data.incoming === 3) {
                                    const index = this.incomingVisitorData.findIndex(x => x.id === visitor_Id);
                                    if (index !== -1) {
                                        this.incomingVisitorData.splice(index, 1);
                                        this.showIncomingVisitors(this.incomingVisitorData);
                                    }
                                }
                            }
                        });
                    }
                }
            });
            this.showIncomingVisitors(this.incomingVisitorData);
        });
    }

    loadTodayChats = () => {
        let todayDate = new Date();
        //todayDate.setDate(todayDate.getDate() -1);
        todayDate.setHours(0, 0, 0, 0);
        this.allDataSnapShot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').where("addedon", ">=", parseInt(todayDate.getTime())).where('visitor_status', 'in', ['incoming', 'ongoing', 'missed', 'close']).onSnapshot((visitors) => {

            visitors.docChanges().forEach((visitor) => {
                var vistSnap = visitor.doc.data();
                vistSnap.id = visitor.doc.id;
                const visitor_Id = visitor.doc.id;

                if (visitor.type === "added") {
                    const index = this.allVisitorData.findIndex(x => x.id === visitor_Id);
                    if (index === -1) {
                        this.allVisitorData.splice(0, 0, vistSnap); // Insert the new one 
                    }
                } else if (visitor.type === "modified") {
                    const index = this.allVisitorData.findIndex(x => x.id === visitor_Id);
                    if (index !== -1) {
                        this.allVisitorData.splice(index, 1, vistSnap); // replace value of an index
                    } else {
                        this.allVisitorData.splice(0, 0, vistSnap); // Insert the new one 
                    }
                }
            });

            this.setState({ allChats: this.allVisitorData }, () => {
                this.showAllData();
            });
        });
    }

    getAgentsList = () => {
        let agentListArray = [];
        this.agentsSnapShot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where("deletetime", "==", 0).onSnapshot(agents => {
            agents.docChanges().forEach((agent) => {
                var agentData = agent.doc.data();
                agentData.id = agent.doc.id;
                const agent_Id = agent.doc.id;

                if (agent.type === "added") {
                    const index = agentListArray.findIndex(x => x.id === agent_Id);
                    if (index === -1) {
                        agentListArray.splice(0, 0, agentData); // Insert the new one 
                    }
                } else if (agent.type === "modified") {
                    const index = agentListArray.findIndex(x => x.id === agent_Id);
                    if (index !== -1) {
                        agentListArray.splice(index, 1, agentData); // replace value of an index
                    } else {
                        agentListArray.splice(0, 0, agentData); // Insert the new one 
                    }
                }
            });

            let totalOnline = agentListArray.reduce((all, item) => {
                all += (item.online_status === 1) ? 1 : 0;
                return all;
            }, 0);

            this.setState({ totalAgents: agentListArray.length, onlineAgents: totalOnline });
        });
    }

    getOnlineLogedIn = () => {
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        var body = [`ownerId=${this.agent.ownerId}&date_range=today&start_date=&end_date=&numberOfDays=&currentDate=${todayDate.getTime()}`];
        //fetch(MAIL_FUNCTION_URL + "/analytics/getAgentSessions", {
        fetch(GATEWAY_URL + "/getAgentSessions", {
            method: "post",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            if (response.status === "success") {
                let tmpResponseData = Object.assign([], response.data);
                let tmpResponseData22 = {};
                tmpResponseData.map(item => {
                    if (tmpResponseData22[item.agent_id] === undefined) {
                        tmpResponseData22[item.agent_id] = [];
                    }
                    tmpResponseData22[item.agent_id].push(item);
                });
                this.setState({ onlineLoggedInData: tmpResponseData22 }, () => {
                    this.loadOnlineAgents();
                });
            } else {
                this.setState({ loader: false });
            }
        }).catch((err) => { });
    }

    loadOnlineAgents = async () => {
        let onlineLoggedInData = this.state.onlineLoggedInData;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let agentsOnlineTime = {};

        if (Object.keys(onlineLoggedInData).length > 0) {

            let agentTimeZone = localStorage.getItem("agentTimeZone") ? JSON.parse(localStorage.getItem('agentTimeZone')) : false;
            let serverTime = 0;
            if (agentTimeZone && agentTimeZone.serverTime) {
                serverTime = parseInt(agentTimeZone.serverTime);
            }

            for (let agent_id in onlineLoggedInData) {
                let tmpAgentData = onlineLoggedInData[agent_id];
                let totalRows = tmpAgentData.length;
                var iooi = 1;
                tmpAgentData.forEach(loginD => {

                    let online = loginD.online ? parseInt(loginD.online) : 0;
                    let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                    let type = loginD.type ? loginD.type : "";
                    if (!offline && iooi === totalRows && serverTime > 0) {
                        offline = serverTime;
                    }

                    if (type === "online_offline") {
                        let onlineD = new Date(online);
                        let houronlineD = onlineD.getHours();
                        let minuteonlineD = onlineD.getMinutes();
                        let offlineD = new Date(offline);
                        let hourofflineD = offlineD.getHours();
                        let minuteofflineD = offlineD.getMinutes();

                        if (offline) {
                            if (agentsOnlineTime[agent_id] === undefined) {
                                agentsOnlineTime[agent_id] = [];
                            }
                            if ((hourofflineD - houronlineD) >= 1) {
                                for (var j = houronlineD; j <= hourofflineD; j++) {
                                    var workSeconds = 3600;
                                    if (j === houronlineD) {
                                        workSeconds = (60 - eval(minuteonlineD)) * 60;
                                    } else if (j === hourofflineD) {
                                        workSeconds = minuteofflineD * 60;
                                    }
                                    agentsOnlineTime[agent_id].push(workSeconds);
                                }
                            } else {
                                agentsOnlineTime[agent_id].push(((minuteofflineD - minuteonlineD) * 60));
                            }
                        }
                    }

                    iooi++;
                });
            }
        }

        let online_duration_min = 0;
        let online_duration_max = 0;
        let online_duration_avg = 0;

        let totalAgentsOnline = {};
        let onlineTimeArray = [];
        if (Object.keys(agentsOnlineTime).length > 0) {
            for (var agentId in agentsOnlineTime) {
                let agentOnlineData = agentsOnlineTime[agentId];
                let totalTime = agentOnlineData.reduce(function (a, b) {
                    return a + b;
                }, 0);
                totalAgentsOnline[agentId] = totalTime;
                onlineTimeArray.push(totalTime);
            }
        }

        let sortedArr33 = onlineTimeArray.sort((a, b) => a - b);
        let totalOnlineTime = sortedArr33.length;
        if (totalOnlineTime > 0) {
            online_duration_min = sortedArr33[0];
            online_duration_max = sortedArr33[totalOnlineTime - 1];
        }

        let avgTotalOnline = 0;
        let totalAgentCount = Object.keys(totalAgentsOnline).length;
        if (totalAgentCount > 0) {
            for (let agentid in totalAgentsOnline) {
                if (totalAgentsOnline.hasOwnProperty(agentid)) {
                    avgTotalOnline += totalAgentsOnline[agentid];
                }
            }
            online_duration_avg = Math.ceil(avgTotalOnline / totalAgentCount);
        }

        this.setState({ online_duration_min: online_duration_min, online_duration_max: online_duration_max, online_duration_avg: online_duration_avg });
    }

    checkFeedBackType = () => { // check feedback type and check the multiple feedback switch activated
        return new Promise((res, rej) => {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('chatbots').where('status', '==', 1).limit(1).get().then((docs) => {
                let feedback_type = 1;
                let multi_feedback_switch = false;
                if (!docs.empty) {
                    docs.forEach((doc) => {
                        feedback_type = doc.data().feedback_type ? doc.data().feedback_type : 1;
                        multi_feedback_switch = doc.data().multi_feedback_switch ? doc.data().multi_feedback_switch : false;
                    })
                }
                this.setState({
                    feedback_type: feedback_type,
                    multi_feedback_switch: multi_feedback_switch
                }, () => {
                    res(true);
                })
            }).catch((err) => {
                res(true);
            });
        })
    }

    async componentDidMount() {
        this.setState({ loader: true, isPageLoad: true });
        let widgetStatus = await checkWidgetStatus();
        this.setState({
            widgetStatus: widgetStatus
        })
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        if (widgetStatus === true) {
            await this.checkFeedBackType();
            this.loadIncomingChats();
            this.loadTodayChats();
            this.getAgentsList();
            this.getOnlineLogedIn();
            this.onlineInterval = setInterval(() => {
                this.getOnlineLogedIn();
            }, 30000);
        } else {
            this.loadDummyData();
        }
    }

    loadDummyData = () => {
        var chatDummyData = dummyData.chatAnalytics;
        this.setState({
            loader: false,
            totalIncomingVisitors: chatDummyData.totalIncomingVisitors,
            totalMissedChats: chatDummyData.totalMissedChats,
            totalAgents: chatDummyData.totalAgents,
            onlineAgents: chatDummyData.onlineAgents,
            totalServedChats: chatDummyData.totalServedChats,
            online_duration_max: chatDummyData.online_duration_max,
            online_duration_min: chatDummyData.online_duration_min,
            online_duration_avg: chatDummyData.online_duration_avg,
            wait_time_max: chatDummyData.wait_time_max,
            wait_time_min: chatDummyData.wait_time_min,
            wait_time_avg: chatDummyData.wait_time_avg,
            served_chat_avg: chatDummyData.served_chat_avg,
            served_chat_max: chatDummyData.served_chat_max,
            served_chat_min: chatDummyData.served_chat_min,
            chat_duration_max: chatDummyData.chat_duration_max,
            chat_duration_avg: chatDummyData.chat_duration_avg,
            chat_duration_min: chatDummyData.chat_duration_min,
            response_time_max: chatDummyData.response_time_max,
            response_time_avg: chatDummyData.response_time_avg,
            response_time_min: chatDummyData.response_time_min,
            satisfactionOverallProduct: chatDummyData.satisfactionOverallProduct,
            satisfactionBadProduct: chatDummyData.satisfactionBadProduct,
            satisfactionGoodProduct: chatDummyData.satisfactionGoodProduct,
            satisfactionOverallAgent: chatDummyData.satisfactionOverallAgent,
            satisfactionBadAgent: chatDummyData.satisfactionBadAgent,
            satisfactionGoodAgent: chatDummyData.satisfactionGoodAgent,
            showDummyData: true
        })
        var timeDiff = 30;
        this.dummyTimer = setInterval(() => {
            try {
                var fetchtime = this.secondsToTime(timeDiff);
                document.getElementById('currentwaittime').innerHTML = fetchtime;
                timeDiff++;
            } catch (errr) { }
        }, 1000)
    }

    componentWillUnmount() {
        this.clearIntervalData();

        try {
            this.incomingSnapshot();
            this.allDataSnapShot();
            this.agentsSnapShot();
        } catch (errr) { }

        try {
            clearInterval(this.onlineInterval);
        } catch (errr) { }

        try {
            clearInterval(this.dummyTimer);
            this.dummyTimer = null;
        } catch (err1) { }
    }

    /*  changeFilterHandle = (e) => {
         this.setState({ [e.target.name]: e.target.value }, () => {
         });
     } */

    sideMenuePhone = () => {
        try {
            document.getElementById('sidebarnavChild').classList.toggle('sideMenue');
        } catch (err) { }
    }

    hideDummyData = () => {
        this.setState({
            showDummyData: false,
            loader: true
        })
        this.loadIncomingChats();
        this.loadTodayChats();
        this.getAgentsList();
        this.getOnlineLogedIn();
        this.onlineInterval = setInterval(() => {
            this.getOnlineLogedIn();
        }, 30000);
        try {
            clearInterval(this.dummyTimer);
            this.dummyTimer = null;
        } catch (err1) { }
    }

    render() {
        return (<>
            <ReactTooltip
                place={'bottom'}
                effect={'solid'}
                multiline={true} />
            <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <LeftPanel />
          <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Analytics</h3>
                <div className="ml-auto"></div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            {this.state.payment_status? <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">
                            {/* Filters Start  */}
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                                    <div className="main-heading main-heading-title">
                                        <h3 className="heding-text mb-3">Real Time Analysis</h3>
                                        {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '10px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                                    </div>

                                    {/* <div className="bg-white left-widgetsetings-box k-flex align-items-center px-5 py-5 mob-analytics">
                                        <div className="k-flex align-items-center label-style">
                                            <label className="pl-3 pr-3 mb-0 text-14">Widget</label>
                                            <span>
                                                <select className="customArrow" name="domain" id="domain" onChange={(e) => this.changeFilterHandle(e)}>
                                                    <option value="">All</option>
                                                    {this.state.domain_list.map((domn, indx) => {
                                                        return <option key={indx} value={domn}>{domn}</option>
                                                    })}
                                                </select>
                                            </span>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* Filters End  */}

                            <div className="row">
                                <div className="col-xs-3 col-sm-12 col-md-6 col-lg-3 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">Incoming Chat&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Number of new chats of your customers on your dashboard" />
                                            </span>
                                        </span>
                                        <div className="chatReport-total-chats k-flex align-items-center pt-8">
                                            <div className="online-time mob-pd-0">
                                                <p className="numberReport">
                                                    <span className="big">{this.state.totalIncomingVisitors}</span> </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-5 col-sm-12 col-md-6 col-lg-5 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">Wait Time&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Wait time of visitors before their chat gets picked by an agent" />
                                            </span>
                                        </span>
                                        <div className="chatReport-total-chats k-flex align-items-center">
                                            <div className="online-time">
                                                <p className="numberReport k-flex align-items-baseline justify-content-center" id="currentwaittime"></p>
                                                <p>Current:</p>
                                            </div>

                                            <div className="wait-time-others">
                                                <p className="k-flex align-items-baseline"><span className="left">Maximum:</span>  {parse(this.secondsToHms(this.state.wait_time_max))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Minimum:</span>  {parse(this.secondsToHms(this.state.wait_time_min))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Average:</span>  {parse(this.secondsToHms(this.state.wait_time_avg))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-4 col-sm-12 col-md-6 col-lg-4 mb-5 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <div className="chatReport-total-chats text-left pt-0">
                                            <span className="title-total-chat analytics-title-size">Chat Duration&nbsp;
                                                <span className="info-icon">
                                                    <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Length of chat duration" />
                                                </span>
                                            </span>
                                            <div className="chat-duration-all wait-time-others pt-4">
                                                <p className="k-flex align-items-baseline"><span className="left">Maximum:</span> {parse(this.secondsToHms(this.state.chat_duration_max))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Minimum:</span> {parse(this.secondsToHms(this.state.chat_duration_min))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Average:</span> {parse(this.secondsToHms(this.state.chat_duration_avg))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-3 col-sm-12 col-md-6 col-lg-3 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">Missed Chats&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Number of chats that the agent did not pick" />
                                            </span>
                                        </span>
                                        <div className="chatReport-total-chats k-flex align-items-center pt-8">
                                            <div className="online-time mob-pd-0">
                                                <p className="numberReport">
                                                    <span className="big">{this.state.totalMissedChats}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-5 col-sm-12 col-md-6 col-lg-5 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">Completed Chats&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Number of chats completed" />
                                            </span>
                                        </span>
                                        <div className="chatReport-total-chats k-flex align-items-center">
                                            <div className="online-time">
                                                <p className="numberReport k-flex align-items-baseline justify-content-center">
                                                    <span className="big">{this.state.totalServedChats}</span>
                                                </p>
                                                <p>Total :</p>
                                            </div>
                                            <div className="chat-served-others wait-time-others">
                                                <p className="k-flex align-items-baseline"><span className="left"> Per agent average:</span> <span className="big">{this.state.served_chat_avg}</span></p>
                                                <p className="k-flex align-items-baseline"><span className="left">Maximum :</span> <span className="big">{this.state.served_chat_max}</span></p>
                                                <p className="k-flex align-items-baseline"><span className="left">Minimum:</span> <span className="big">{this.state.served_chat_min}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-4 col-sm-12 col-md-6 col-lg-4 mb-5 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <div className="chatReport-total-chats text-left pt-0">
                                            <span className="title-total-chat analytics-title-size">Response Time&nbsp;
                                                <span className="info-icon">
                                                    <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Time an agent takes to answer a visitor's initial query or initial request" />
                                                </span>
                                            </span>
                                            <div className="response-time-all wait-time-others pt-4">
                                                <p className="k-flex align-items-baseline"><span className="left">Maximum:</span> {parse(this.secondsToHms(this.state.response_time_max))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Minimum:</span> {parse(this.secondsToHms(this.state.response_time_min))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Average:</span> {parse(this.secondsToHms(this.state.response_time_avg))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xs-4 col-sm-12 col-md-6 col-lg-4 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">Agents&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Number of agents that are online at the moment" data-offset="{'right': 20}" />
                                            </span>
                                        </span>
                                        <div className="chatReport-total-chats k-flex align-items-center">
                                            <div className="online-time">
                                                <p className="numberReport k-flex align-items-baseline justify-content-center">
                                                    <span className="big">{this.state.totalAgents}</span>
                                                </p>
                                                <p>Total:</p>
                                            </div>
                                            <div className="chat-served-others wait-time-others line-diff">
                                                {/* <p className="k-flex align-items-baseline dot"><span className="left">Signed:</span> {this.state.onlineAgents}</p> */}
                                                <p className="k-flex align-items-baseline dot green"><span className="left">Online:</span> <span className="big"> {this.state.onlineAgents}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-xs-4 col-sm-12 col-md-6 col-lg-4 mb-5 pr-0 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                                        <div className="chatReport-total-chats text-left">
                                            <span className="title-total-chat analytics-title-size">Status Online Duration&nbsp;
                                                <span className="info-icon">
                                                    <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Agent's online duration" />
                                                </span>
                                            </span>
                                            <div className="response-time-all wait-time-others pt-6">
                                                <p className="k-flex align-items-baseline"><span className="left">Maximum: </span> {parse(this.secondsToHms(this.state.online_duration_max))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Minimum: </span> {parse(this.secondsToHms(this.state.online_duration_min))}</p>
                                                <p className="k-flex align-items-baseline"><span className="left">Average: </span> {parse(this.secondsToHms(this.state.online_duration_avg))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-4 col-sm-12 col-md-6 col-lg-4 mb-5 mob-res">
                                    <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                                        <span className="title-total-chat analytics-title-size">{parseInt(this.state.feedback_type) === 0 ? "Average" : "Satisfaction"}&nbsp;
                                            <span className="info-icon">
                                                <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Feedback Rating" />
                                            </span>
                                        </span>

                                        {this.state.multi_feedback_switch ?
                                            (<div className="chatReport-total-chats k-flex align-items-center satisfactionArea average-rating">
                                                <div className="online-time">
                                                    <p>Avg. Product Rating:</p>
                                                    <p className="numberReport k-flex align-items-baseline justify-content-center">
                                                        <span className="big">{parseInt(this.state.feedback_type) === 0 ? this.state.averageStarRatingProduct : this.state.satisfactionOverallProduct}</span>
                                                        <span className="small" style={{ position: 'relative', bottom: parseInt(this.state.feedback_type) === 0 ? 8 : 0 }}>{parseInt(this.state.feedback_type) === 0 ? "" : "%"}</span>
                                                    </p>

                                                    <div className="chat-served-others wait-time-others line-diff">
                                                        {parseInt(this.state.feedback_type) === 0 ? (<>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★★ </span>
                                                                {this.state.fiveStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★☆ </span>
                                                                {this.state.fourStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★☆☆ </span>
                                                                {this.state.threeStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★☆☆☆ </span>
                                                                {this.state.twoStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★☆☆☆☆ </span>
                                                                {this.state.oneStarRatingProduct}
                                                            </p>
                                                        </>) : (<><p className="k-flex align-items-baseline"><span className="left">Good: </span>
                                                            <span className="info-icon">
                                                                &nbsp;<img alt="" src={require('../../assets/img/icon/like.svg')} data-tip="Positive Product Ratings" />
                                                            </span>
                                                            <span className="satisfaction-result">{this.state.satisfactionGoodProduct}</span>
                                                        </p>
                                                            <p className="k-flex align-items-baseline"><span className="left">Bad: </span>
                                                                <span className="info-icon">
                                                                    &nbsp;<img alt="" src={require('../../assets/img/icon/dislike.svg')} data-tip="Negative Product Ratings" />
                                                                </span>
                                                                <span className="satisfaction-result">{this.state.satisfactionBadProduct}</span>
                                                            </p></>)}
                                                    </div>
                                                </div>
                                                <div className="online-time">
                                                    <p>Avg. Service/Agent Rating:</p>
                                                    <p className="numberReport k-flex align-items-baseline justify-content-center">
                                                        <span className="big">{parseInt(this.state.feedback_type) === 0 ? this.state.averageStarRatingAgent : this.state.satisfactionOverallAgent}</span>
                                                        <span className="small" style={{ position: 'relative', bottom: parseInt(this.state.feedback_type) === 0 ? 8 : 0 }}>{parseInt(this.state.feedback_type) === 0 ? "" : "%"}</span>
                                                    </p>
                                                    <div className="chat-served-others wait-time-others line-diff">
                                                        {parseInt(this.state.feedback_type) === 0 ? (<>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★★ </span>
                                                                {this.state.fiveStarRatingAgent}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★☆ </span>
                                                                {this.state.fourStarRatingAgent}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★☆☆ </span>
                                                                {this.state.threeStarRatingAgent}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★☆☆☆ </span>
                                                                {this.state.twoStarRatingAgent}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★☆☆☆☆ </span>
                                                                {this.state.oneStarRatingAgent}
                                                            </p>
                                                        </>) : (<><p className="k-flex align-items-baseline"><span className="left">Good: </span>
                                                            <span className="info-icon">
                                                                &nbsp;<img alt="" src={require('../../assets/img/icon/like.svg')} data-tip="Positive Service/Agent Ratings" />
                                                            </span>
                                                            <span className="satisfaction-result">{this.state.satisfactionGoodAgent}</span>
                                                        </p>
                                                            <p className="k-flex align-items-baseline"><span className="left">Bad: </span>
                                                                <span className="info-icon">
                                                                    &nbsp;<img alt="" src={require('../../assets/img/icon/dislike.svg')} data-tip="Negative Service/Agent Ratings" />
                                                                </span>
                                                                <span className="satisfaction-result">{this.state.satisfactionBadAgent}</span>
                                                            </p></>)}
                                                    </div>
                                                </div>
                                            </div>) : (
                                                <div className="chatReport-total-chats k-flex align-items-center satisfactionArea">
                                                    <div className="online-time">
                                                        <p className="numberReport k-flex align-items-baseline justify-content-center">
                                                            <span className="big">{parseInt(this.state.feedback_type) === 0 ? this.state.averageStarRatingProduct : this.state.satisfactionOverallProduct}</span>
                                                            <span className="small" style={{ position: 'relative', bottom: parseInt(this.state.feedback_type) === 0 ? 8 : 0 }}>{parseInt(this.state.feedback_type) === 0 ? "" : "%"}</span>
                                                        </p>
                                                        <p>Overall:</p>
                                                    </div>
                                                    <div className="chat-served-others wait-time-others line-diff">
                                                        {parseInt(this.state.feedback_type) === 0 ? (<>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★★ </span>
                                                                {this.state.fiveStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★★☆ </span>
                                                                {this.state.fourStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★★☆☆ </span>
                                                                {this.state.threeStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★★☆☆☆ </span>
                                                                {this.state.twoStarRatingProduct}
                                                            </p>
                                                            <p className="k-flex align-items-baseline">
                                                                <span className="left">★☆☆☆☆ </span>
                                                                {this.state.oneStarRatingProduct}
                                                            </p>
                                                        </>) : (<><p className="k-flex align-items-baseline"><span className="left">Good: </span>
                                                            <span className="info-icon">
                                                                &nbsp;<img alt="" src={require('../../assets/img/icon/like.svg')} data-tip="Positive Ratings" />
                                                            </span>
                                                            <span className="satisfaction-result">{this.state.satisfactionGoodProduct}</span>
                                                        </p>
                                                            <p className="k-flex align-items-baseline"><span className="left">Bad: </span>
                                                                <span className="info-icon">
                                                                    &nbsp;<img alt="" src={require('../../assets/img/icon/dislike.svg')} data-tip="Negative Ratings" />
                                                                </span>
                                                                <span className="satisfaction-result">{this.state.satisfactionBadProduct}</span>
                                                            </p></>)}
                                                    </div>
                                                </div>
                                            )}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div> : <PopUp />}
        </>
        )
    }
}

export default Chatanalytics;