import React, { PureComponent } from 'react';
import LeftPanel from './view/LeftPanel';
import Thankyou from './view/Thankyou';

class Widgetcustamization extends PureComponent {
    render() {
        return (<>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Settings</h3>
            </header>
            <LeftPanel />
            <Thankyou />
        </>); 
    }
}

export default Widgetcustamization;