import React from 'react';
import TextField from '@material-ui/core/TextField';
import ReactHtmlParser from "react-html-parser";
/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* For Dropdown End */

class Desktop extends React.Component {
    constructor(props) {
        super();
        this.state = {
            show: true,
            team_name:""
        }
    }

    handleTeam =(e) => {
        this.setState({ team_name: e.target.value });
    }

    render() {
        let currentscreen = '';
        // let getNameLabel = (this.props.values.online_name_required === true) ? this.props.values.online_name_label+"*" : this.props.values.online_name_label;
        // let getEmailLabel = (this.props.values.online_email_required === true) ? this.props.values.online_email_label+"*" : this.props.values.online_email_label;
        // let getContactLabel = (this.props.values.online_contact_required === true) ? this.props.values.online_contact_label+"*" : this.props.values.online_contact_label;
        // let getQueryLabel = (this.props.values.online_query_required === true) ? this.props.values.online_query_label+"*" : this.props.values.online_query_label;
        // let getTeamLabel = (this.props.values.online_team_required === true) ? this.props.values.online_team_label+"*" : this.props.values.online_team_label;
        const formFields = this.props.values.onlineFormFields;

        if (this.props.view === 'desktop' && this.props.preview === 'online' && this.props.values.form_type === "1") {
            currentscreen = <div className="screen position-relative mr-auto screen-chatsetting">
                <div className="screen-header px-5 py-3" style={{ background: 'rgb(10, 10, 10)' }}>
                    <div className="k-flex align-items-center">
                        <div className="screenBackArroe">
                            <svg focusable="false" aria-hidden="true">
                                <g fill="none">
                                    <g fill="#FFF">
                                        <polygon transform="translate(-40 -29)translate(47.071068 36.071068)rotate(-315)translate(-47.071068 -36.071068)" points="44.3 38.8 44.3 31.1 42.1 31.1 42.1 40 42.1 41.1 52.1 41.1 52.1 38.8" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="screenBackArroe">
                            <p>{this.props.values.company_name}</p>
                        </div>
                        <div className="screenRightNav k-flex align-items-center ml-auto">
                            <div className="screenMenuArrow mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
                                    <g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)">
                                        <rect id="Rectangle_1688" data-name="Rectangle 1688" width="4" height="4" transform="translate(1378.075 219.72)" fill="#fff" />
                                        <rect id="Rectangle_1689" data-name="Rectangle 1689" width="4" height="4" transform="translate(1378.075 226.72)" fill="#fff" />
                                        <rect id="Rectangle_1690" data-name="Rectangle 1690" width="4" height="4" transform="translate(1378.075 233.72)" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                            <div className="screeCloseArrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243">
                                    <g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)">
                                        <g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#fff" stroke="#fff" strokeWidth="1">
                                            <circle cx="9.622" cy="9.622" r="9.622" stroke="none" />
                                            <circle cx="9.622" cy="9.622" r="9.122" fill="none" />
                                        </g>
                                        <path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#1c223a" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="screen-body">
                    <div className="body-chat">
                        {this.props.values.online_welcomemessage_check && this.props.values.pre_chat_form ? 
                        (<div className="recived k-flex align-items-flex-end pt-3">
                            <span className="user"><img alt="" src="/static/media/comments.188cfe62.svg" style={{ width: 18 }} /> </span>
                            <div className="message-box">
                                <div className="message first-messages">
                                    {ReactHtmlParser(this.props.values.online_welcomemessage_text)}
                                </div>
                            </div>
                        </div>) : null}
                        <div className={this.props.values.pre_chat_form ? "form-introduce" : ""}>
                            {this.props.values.pre_chat_form ? (<div className="introduceed-details">
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="87.699" height="109.116" viewBox="0 0 87.699 109.116">
                                        <g id="_1250689" data-name="1250689" transform="translate(3.727)">
                                            <path id="Path_2552" data-name="Path 2552" d="M113.354,54.466A27.233,27.233,0,1,0,86.121,27.233,27.232,27.232,0,0,0,113.354,54.466Zm0-49.85A22.617,22.617,0,1,1,90.737,27.233,22.617,22.617,0,0,1,113.354,4.616Zm0,0" transform="translate(-73.231)" fill="#fff" />
                                            <path id="Path_2553" data-name="Path 2553" d="M40.123,331.313A42.984,42.984,0,0,0,9.106,344.26,46.127,46.127,0,0,0-3.727,376.848a2.315,2.315,0,0,0,2.308,2.307H81.665a2.314,2.314,0,0,0,2.307-2.307,46.074,46.074,0,0,0-12.831-32.564A43.007,43.007,0,0,0,40.123,331.313ZM.936,374.539a41.219,41.219,0,0,1,11.447-27.026A38.453,38.453,0,0,1,40.1,335.952a38.45,38.45,0,0,1,27.717,11.562,41.107,41.107,0,0,1,11.448,27.026Zm0,0" transform="translate(0 -270.038)" fill="#fff" />
                                        </g>
                                    </svg>
                                </span>
                                {/* Commented for ticket 1425 */}
                                {/* { this.props.values.online_introducetext_check && this.props.values.online_introducetext ? (<p>{this.props.values.online_introducetext}</p>) : (null) } */}
                                {/* Commented for ticket 1425 */}
                            </div>) : (null)}
                            <div className="screen-forems">

                                { formFields.map((item, index) => {
                                    const textLabel = (this.props.values[item.required] === true) ? this.props.values[item.name]+"*" : this.props.values[item.name];
                                    if(item.type === 'select' && this.props.values.pre_chat_form && this.props.values[item.checkbox]) {
                                        return <div className="screen-feilds" key={index}>
                                            <div className="input-group material-select widget-input mt-4">
                                                <FormControl variant="outlined" className="material-outlined from-control w-100 small-drop">
                                                    <InputLabel id="team_name-select-outlined-label">{textLabel}</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="team_name-select-outlined-label" value={this.state.team_name} id="team_name" name="team_name" label="Team" onChange={this.handleTeam}>
                                                        {this.props.values.teams.map((teamd, indx) => {
                                                            return <MenuItem key={indx} value={teamd.id}>{teamd.name}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    } else if(item.type === 'input' && this.props.values.pre_chat_form && this.props.values[item.checkbox]){
                                        return <div className="screen-feilds" key={index}>
                                            <div className="input-group material-input widget-input mt-2">
                                                <TextField className="form-control w-100" variant="outlined" placeholder={this.props.values[item.placeHoldertext]} label= {textLabel} multiline={item.multiline} />
                                            </div>
                                        </div>
                                    } else {
                                        return null;
                                    }
                                }) }
                                {/* {this.props.values.online_team_check && this.props.values.pre_chat_form && (<div className="screen-feilds">
                                    <div className="input-group material-select widget-input mt-4">
                                        <FormControl variant="outlined" className="material-outlined from-control w-100 small-drop">
                                            <InputLabel id="team_name-select-outlined-label">{getTeamLabel}</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true }} labelId="team_name-select-outlined-label" value={this.state.team_name} id="team_name" name="team_name" label="Team" onChange={this.handleTeam}>
                                                {this.props.values.teams.map((teamd, indx) => {
                                                    return <MenuItem key={indx} value={teamd.id}>{teamd.name}</MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>)}

                                {this.props.values.online_name_check && this.props.values.pre_chat_form && (<div className="screen-feilds">
                                    <div className="input-group material-input widget-input mt-2">
                                        <TextField className="form-control w-100" variant="outlined" placeholder={this.props.values.namePlaceHolderText} label= {getNameLabel} />
                                    </div>
                                </div>)}
                                
                                {this.props.values.online_email_check && this.props.values.pre_chat_form && (<div className="screen-feilds">
                                    <div className="input-group material-input widget-input mt-2">
                                        <TextField className="form-control w-100" variant="outlined" placeholder={this.props.values.emailPlaceHolderText} label= {getEmailLabel} />
                                    </div>
                                </div>)}
                                {this.props.values.online_contact_check && this.props.values.pre_chat_form && (<div className="screen-feilds">
                                    <div className="input-group material-input widget-input mt-2">
                                        <TextField className="form-control w-100" variant="outlined" placeholder="+91-2345678564" label= {getContactLabel} />
                                    </div>
                                </div>)}
                                {this.props.values.online_query_check && this.props.values.pre_chat_form && (<div className="screen-feilds">
                                    <div className="input-group material-input widget-input mt-3">
                                        <TextField className="form-control w-100" rows={4} variant="outlined" placeholder={this.props.values.queryPlaceholderText} label={getQueryLabel} multiline ={true}/>
                                    </div>
                                </div>)} */}
                                <div className="screen-feilds text-center btn-feilds bottom-fix">
                                    <button className="btnBlueSettings py-2 px-4" style={{ fontSize: '10px' }}>{this.props.values.startChatText}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="screen-footer">
                    <div className="powerby">
                        <p>
                            <span className="powerdBySnapyIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                    <g data-name="Layer 2">
                                        <g data-name="Layer 1">
                                            <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                            <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{ width: '22%', position: 'relative', top: '-2px' }} />
                        </p>
                    </div>
                </div>
            </div>
        }
        return (<>{currentscreen}</>)
    }
}

export default Desktop;