import React from 'react';
class Paymentsuccess extends React.Component {
    constructor() {
        super();
        var agentData = JSON.parse(localStorage.getItem('agent'));
        this.state = {
            ownerIdentifire: agentData.ownerIdentifire
        };
    }
    render() {
        return (
            <>
                <div class="payment main-center-para">
                    <div class="main-center-data-img">
                        <img src={require("../../assets/images/payment-page.svg")} />
                    </div>
                    <div class="para-center-data">
                        <h3 className="text-black card-title mb-1 mt-4">Yikes! Your payment didn’t go through</h3>

                    </div>
                    <div class="main-Datacenter-btn mt-5">
                        <a className="btnBlueSettings hoverA" href={"/" + this.state.ownerIdentifire + "/subscription"} >Retry again</a>
                    </div>
                </div>
            </>
        )
    }
}

export default Paymentsuccess;
