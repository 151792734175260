import React from 'react';
import { Route,Redirect } from 'react-router-dom';
import SidebarNavigation from "./sidebarNavigation";
import Sessioncheck from "./Sessioncheck";
import { isLogin } from './utils';

const PrivateRoute = ({component: Component, ...rest}) => {
     return (
        <>
         <Sessioncheck {...rest} />
        <Route {...rest} render={props => (
           isLogin() && (null) ?
                <Redirect to="/" />
            : <Component {...props} />
         )} />
        </>
    );
};
export default PrivateRoute;
