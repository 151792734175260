import React from 'react';
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import firebaseServices from '../../firebase';

class EditRole extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showEditRoles:'',
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[],
            agents:[],
            ownerId:'',
            roleId:'',
            loader:false
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showEditRoles:this.props.sendRoleStatus,
            roleId:this.props.editId,
            ownerId:userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').where('deletetime', '==', 0).get().then((docs) => {
            var i=0;
            var agents=[];
            docs.forEach((doc)=>{
                agents[i] = doc.data();
                agents[i]['id']=doc.id;
                i++;
            });
            this.setState({
                agents:agents
            })
        });
    }

    componentDidUpdate(prev_props) {
        if(this.props.editId!==prev_props.editId) {
            this.setState({
                roleId:this.props.editId,
            }, () => {
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').doc(this.state.roleId).get().then((docs) => {
                    let roleData=docs.data();
                    this.setState({
                        name:roleData.name,
                        description:roleData.description
                    })
                })
            })
        }
    }

    handleChange = (e) => {
        let target = e.target;
        let values;
        if(target.name==="agentList") {
            values = [].filter.call(target.options, o => o.selected).map(o => o.value);
        } else {
            values = target.value;
        }
        this.setState({
            [target.name]:values
        })
    }

    hideRole = (e) => {
        this.props.getEditRoleStatus(false);
        this.setState({
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            roleId:'',
            agentList:[]
        })
    }

    cancelFormButton = (e) => {
        e.preventDefault();
        this.hideRole();
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validation();
        if(validate===true){
            this.setState({
                loader:true
            })
            let updatedon = Date.now();
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').doc(this.state.roleId).update({updatedon:updatedon,name:this.state.name,description:this.state.description}).then((ref)=> {
                if(this.state.agentList.length>0) {
                    this.updateRoles();
                }
                this.setState({
                    loader:false
                })
                this.hideRole();
                toast.success('Role Details have been updated successfully!');
            }).catch((err) => {
                console.log(err);
            })
            return false;
        }
    }

    updateRoles = () => {
        this.state.agentList.forEach((agentId) => {
            let newRole = this.state.roleId;
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({role:newRole}).then((ref) => {
                console.log(ref);
            }).catch((err) => {
                console.log(err);
            })
        })
    }

    validation() {
        let name_error='';
        let description_error='';
        if(this.state.name===''){
            name_error = 'error';
            this.setState({
                name_error:'First Name should not be empty'
            })
        } else {
            name_error = '';
            this.setState({
                name_error:''
            })
        }
        if(this.state.description===''){
            description_error='error';
            this.setState({
                description_error:'Alias should not be empty'
            })
        } else {
            description_error='';
            this.setState({
                description_error:''
            })
        }

        if(name_error!=='' || description_error!=='') {
            return false;
        } else {
            return true;
        }

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showEditRoles !== props.sendRoleStatus) {
          return {
            showEditRoles: props.sendRoleStatus
          }
        }
        return null
    }

    render() {
        let agents = this.state.agents.map((value,index) => {
            return <option key={index} value={value.id}>{value.name} ({value.alias})</option>
        })
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showEditRoles ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Edit Role</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideRole}>
                                        <img src={ require('../../assets/img/icon/right-panel-crose.svg') } alt="" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Name</label>
                                        <input type="text" placeholder="Enter Role*" className="w-100" name="name" onChange={this.handleChange} value={this.state.name} />
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Description</label>
                                        <textarea type="text" placeholder="Description" className="w-100" name="description"  onChange={this.handleChange} value={this.state.description}></textarea>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Select Agent</label>
                                        <select className="customArrow" name="agentList" value={this.state.agentList} onChange={this.handleChange} multiple>
                                            {agents}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Update</button>
                                        <button className="btnWhite" onClick={this.cancelFormButton}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default EditRole;