import React from 'react';
import { NavLink } from 'react-router-dom';
// import db from '../../../config/fireb   ase';
import firebaseServices from '../../../firebase';

class LeftPanel extends React.Component {
    constructor(){
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            roles:[],
            role:'',
            ownerId:'',
            currentRole:''
        }
    }

    componentWillMount() {
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId,
            currentRole:userDetails.role
        }, () => {
            this.getRolesVal();
        })
    }

    getRolesVal = () => {
        let promise1 = new Promise((res,rej) => {
            firebaseServices.db.collection('agent-roles').doc(this.state.currentRole).get().then((docs) => {
                if(docs.exists) {
                    res(docs.data());
                } else {
                    res(false);
                }
            }).catch(() => {
                rej();
            })
        })

        let promise2 = new Promise((Resolve,Reject) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').doc(this.state.currentRole).get().then((docs) => {
                if(docs.exists) {
                    Resolve(docs.data());
                } else {
                    Resolve(false);
                }
            }).catch(() => {
                Reject();
            })
        })

        Promise.all([promise1,promise2]).then((result) => {
            if(result[0]!==false) {
                let name=result[0].name;
                this.setState({
                    role:name
                })
            } else {
                let roleType = result[1].type;
                if(roleType === 'default') {
                    let name=result[1].name;
                    this.setState({
                        role:name
                    })
                } else {
                    let access=result[1].access;
                    let roles = []
                    Object.keys(access).map(j => {
                        Object.keys(access[j]).map(m => {
                            roles.push(access[j][m]);
                        })
                    })
                    this.setState({
                        roles:roles
                    })
                }
            }
        }).catch(() => {
            this.getRolesVal();
        })
    }


    render () {
        let agents='';
        let departments='';
        let roles='';
        if(this.state.roles.indexOf('agents')>-1 || this.state.role==='Admin' || this.state.role==='Owner') {
            agents=<li><NavLink to={"/"+this.agent.ownerIdentifire+"/agents"} activeClassName="active"> Agents</NavLink></li>
        }
        if(this.state.roles.indexOf('departments')>-1 || this.state.role==='Admin' || this.state.role==='Owner') {
            departments=<li><NavLink to={"/"+this.agent.ownerIdentifire+"/departments"} activeClassName="active">Teams</NavLink></li>
        }
        if(this.state.roles.indexOf('roles')>-1 || this.state.role==='Admin' || this.state.role==='Owner') {
            roles=<li><NavLink to={"/"+this.agent.ownerIdentifire+"/roles"} activeClassName="active">Roles</NavLink></li>
        }
        return (
            <div className="sidebarnavChild" id="sidebarnavChild">
                <div className="tab-content h-100" role="tablist">
                    <div className="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar"> 
                            <div className="left-Menu-notlivechat py-2">
                                <ul>
                                    {agents}
                                    {departments}
                                    {roles}
                                </ul>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;