import React, { PureComponent } from 'react';
import Ongoing from './ongoing';
import Upgrade from './upgrade'
import ChatContext from '../../components/ChatContext';

class Livechat extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is

    // To use global data pass second parameter context in constructor
    constructor(props, context) {
        super(props);
        this.state = {};
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    }

    render() {
        return (<>
            <div className="container-fluid py-6 pl-5 pr-4">
                {this.agent.roleType === 'Owner' ? <Upgrade /> :

                    <Ongoing />}
            </div>
        </>);
    }
}

export default React.memo(Livechat);