import React from 'react';
// import db from "../../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment-timezone";
//import {mailUrl} from "../../../config/mailFunctions.js";
import { GATEWAY_URL, FUNCTION_API_KEY } from "../../../config/siteConfig";
import Resizer from 'react-image-file-resizer';
import { getDateInUTC } from "../../livechat/components/Comman";
import firebaseServices from '../../../firebase';
var timezones = require('../../../localization/timezones.json');
const dateFormat = "X";

class AddAgentInLivechat extends React.Component {
    constructor(props) {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        this.timezoneOptions = [{ value: "", label: "Select Timezone" }];
        timezones.map((values) => {
            this.timezoneOptions.push({
                value: values, label: values
            })
        })
        this.state = {
            showAddAgent: false,
            first_name: '',
            last_name: '',
            alias: '',
            email: '',
            department: [],
            role: '',
            timezone: '',
            status: 0,
            first_name_error: '',
            last_name_error: '',
            alias_error: '',
            email_error: '',
            department_error: '',
            ownerId: '',
            profile_picture: '',
            role_error: '',
            timezone_error: '',
            departmentList: [],
            roleList: [],
            loader: false,
            base64file: '',
            selectedTimezone: { value: "", label: "Select Timezone" }
        }
    }

    componentDidMount() {

        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showAddAgent: this.props.sendAgentStatus,
            ownerId: userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('departments').get().then((docs) => {
            docs.forEach((doc) => {
                var i = 0;
                var departmentList = [];
                docs.forEach((doc) => {
                    departmentList[i] = doc.data();
                    departmentList[i]['id'] = doc.id;
                    i++;
                });
                this.setState({
                    departmentList: departmentList
                })
            })
        })

        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').get().then((data) => {
            var i = 0;
            var roleList = [];
            data.forEach((dat) => {
                roleList[i] = dat.data();
                roleList[i]['id'] = dat.id;
                i++;
            })
            this.setState({
                roleList: roleList
            })
        })
    }

    componentDidUpdate() {
        if (this.state.agentId != this.props.agentId) {
            this.setState({
                agentId: this.props.agentId,
                first_name: this.props.livechatAgentDetails.firstName,
                last_name: this.props.livechatAgentDetails.lastName,
                email: this.props.livechatAgentDetails.email,
                alias: this.props.livechatAgentDetails.alias,
                timezone: this.props.livechatAgentDetails.timezone
            }, () => {
            })
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showAddAgent !== props.agentStatus) {
            return {
                showAddAgent: props.agentStatus
            }
        }
        return null
    }

    hideAddAgent = () => {
        this.props.hideProfile(false);
        this.setState({
            name: '',
            alias: '',
            email: '',
            department: '',
            role: '',
            timezone: '',
            status: 0,
            name_error: '',
            alias_error: '',
            email_error: '',
            department_error: '',
            profile_picture: '',
            role_error: '',
            timezone_error: '',
            selectedTimezone: { value: "", label: "Select Timezone" }
        })
    }

    changehandle = (e) => {
        let target = e.target;
        let values;
        if (target.name === "department") {
            values = [].filter.call(target.options, o => o.selected).map(o => o.value);
        } else {
            values = target.value;
        }
        this.setState({
            [target.name]: values
        })
        let errorName = target.name + '_error';
        if (target.name === 'name' && errorName !== '' && values === '') {
            this.setState({
                [errorName]: 'Name should not be empty'
            })
        } else if (target.name === 'email' && errorName !== '' && values === '') {
            this.setState({
                [errorName]: 'Email should not be empty'
            })
        } else if (target.name === 'email' && errorName !== '' && !this.validateEmail(values)) {
            this.setState({
                [errorName]: 'Email is incorrect'
            })
        } else if (errorName !== '' && values === '' && target.name !== 'alias') {
            this.setState({
                [errorName]: 'Plese select a ' + target.name
            })
        } else {
            this.setState({
                [errorName]: ''
            })
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        let validate = this.validation();
        if (validate === true) {
            this.setState({
                loader: true
            })
            let shortName = '';
            let name = this.state.first_name + ' ' + this.state.last_name;
            let names = name.trim().split(" ");
            if (names.length > 1) {
                shortName = names[0].substring(0, 1) + names[1].substring(0, 1);
            } else {
                shortName = names[0].substring(0, 2);
            }
            shortName = shortName.toUpperCase();
            let addedon = await getDateInUTC();
            console.log('this.state.agentId >>>> ' + this.state.agentId);
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).set({
                addedon: addedon,
                agent_short: shortName,
                name: name,
                departments: this.state.department,
                online_status: 0,
                status: 1,
                role: this.state.role,
                alias: this.state.alias,
                timezone: this.state.timezone,
                deletetime: 0
            }).then(async (ref) => {
                console.log('done');
                this.hideAddAgent();
                this.setState({
                    loader: false
                })
                toast.success("Agent has been added successfully!");
            }).catch((err) => {
                console.log('error at line 200 ', err);
                this.setState({
                    loader: true
                })
                toast.error("Something went wrong! Please try again after some time!");
            });
        }
    }

    generateAlias() {
        let randId = this.makeid(5);
        let newAlias = this.state.alias + randId;
        this.setState({
            alias_error: 'Alias is not available'
        })
    }

    timeZoneFormat = (date) => {
        var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
        todayUTCDate = parseInt(todayUTCDate);
        return todayUTCDate;
    }

    sendAgentVerificationEmail = (opt, name) => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let varificationUrl = window.location.origin + "/" + userDetails.ownerIdentifire + "/agent-verification";
        let company_name = userDetails.ownerIdentifire
        let agent_email = this.state.email.toLowerCase()
        var body = [
            `email=${this.state.email.toLowerCase()}&&verification_code=${opt}&url=${varificationUrl}&type=agent_added&agent_name=${name}&company_name=${company_name}&agent_email=${agent_email}`
        ];
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }


    sendEmail = (token, name) => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let verificationLink = window.location.origin + "/" + userDetails.ownerIdentifire + "/agent-verification/" + token;
        let company_name = userDetails.ownerIdentifire
        let agent_email = this.state.email.toLowerCase()
        var body = [
            `email=${this.state.email.toLowerCase()}&&verifyLink=${verificationLink}&&type=account_created&&agent_name=${name}&company_name=${company_name}&agent_email=${agent_email}`
        ];
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    validation = () => {
        let returnValue = true;
        if (this.state.first_name === '') {
            console.log(1);
            returnValue = false;
            this.setState({
                first_name_error: 'First name should not be empty'
            })
        } else {
            this.setState({
                first_name_error: ''
            })
        }

        if (this.state.last_name === '') {
            returnValue = false;
            console.log(2);
            this.setState({
                last_name_error: 'Last name should not be empty'
            })
        } else {
            this.setState({
                last_name_error: ''
            })
        }

        if (this.state.email == '') {
            returnValue = false;
            console.log(3);
            this.setState({
                email_error: 'Email Should not be empty'
            })
        } else if (!this.validateEmail(this.state.email)) {
            returnValue = false;
            console.log(4);
            this.setState({
                email_error: 'Email is incorrect'
            })
        } else {
            this.setState({
                email_error: ''
            })
        }

        if (this.state.department.length == 0) {
            returnValue = false;
            console.log(5);
            this.setState({
                department_error: 'Plese select a department'
            })
        } else {
            this.setState({
                department_error: ''
            })
        }

        if (this.state.role == '') {
            console.log(6);
            returnValue = false;
            this.setState({
                role_error: 'Plese select a role'
            })
        } else {
            this.setState({
                role_error: ''
            })
        }

        if (this.state.timezone == '') {
            returnValue = false;
            console.log(7);
            this.setState({
                timezone_error: 'Plese select a timeZone'
            })
        } else {
            this.setState({
                timezone_error: ''
            })
        }

        return returnValue;

    }

    handleFile = async (files) => {
        this.setState({ 'uploadFileError': '' })
        const tempFile = files[0];
        Resizer.imageFileResizer(
            tempFile,
            64,
            64,
            'PNG',
            100,
            0,
            uri => {
                this.setState({
                    base64file: uri
                })
            },
            'base64'
        );
    }

    validateEmail = email => {
        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    };

    cancelForm = (e) => {
        e.preventDefault();
        this.hideAddAgent();
    }

    render() {
        let timezoneList = timezones.map((value, index) => {
            return <option key={index} value={value}>{value}</option>
        })
        let departmentList = this.state.departmentList.map((value, index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        })
        let rolesList = this.state.roleList.map((value, index) => {
            if (value.name !== 'Owner') {
                return <option key={index} value={value.id}>{value.name}</option>
            }
        })
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                {this.state.showAddAgent ? (<div className="rightPanelSection add-agent" style={{ display: this.state.showAddAgent ? 'block' : 'none' }}>
                    <div className="categoryRightPanel">
                        {this.state.agentId}
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Add Agent</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.hideAddAgent}>
                                            <img src={require('../../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Team</label>
                                                <select name="department" multiple className="customArrow" value={this.state.department} onChange={this.changehandle}>
                                                    {departmentList}
                                                </select>
                                                <p className="error">{this.state.department_error}</p>
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Role</label>
                                                <select name="role" className="customArrow" value={this.state.role} onChange={this.changehandle}>
                                                    <option value="">Select Role</option>
                                                    {rolesList}
                                                </select>
                                                <p className="error">{this.state.role_error}</p>
                                            </div>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <button className="mr-2 btnBlueSettings">Add</button>
                                                <button className="btnWhiteSettings" onClick={this.cancelForm}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : (null)}
            </>
        )
    }
}

export default AddAgentInLivechat;