import React, { PureComponent } from 'react';
import Chat from './chat';
// import db from '../../../config/firebase';
import moment from 'moment-timezone';
import * as algoliasearch from "algoliasearch";
/// For pagination start 
import Pagination from "react-js-pagination";
/// For pagination end 
import { ALGOLIA_APP_ID, ALGOLIA_KEY, NO_REPLY_EMAIL, MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../../config/siteConfig';
import { validateEmail, isMobileDevice } from '../../livechat/components/Comman';
//import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { activityLog, checkWidgetStatus, elasticSearchArchives, elasticArchivesHistory } from "../../../services/service";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { CSVLink } from "react-csv";
import dummyData from '../../../localization/dummy-data.json';
import countries from '../../../localization/countries.json';

import TextField from '@material-ui/core/TextField';
/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
/* For Dropdown End */
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactTooltip from "react-tooltip";
import queryString from 'query-string'
import firebaseServices from '../../../firebase';

//const dateFormat = "X";

class History extends PureComponent {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};

    fileheaders = [
        { label: "Date And Time", key: "date_time" },
        { label: "Session id", key: "session_id" },
        { label: "Agents Name", key: "agents_name" },
        { label: "Visitor name", key: "visitor_name" },
        { label: "Visitor email", key: "visitor_email" },
        { label: "Chat status", key: "status" },
        { label: "Type", key: "type" },
        { label: "Product Feedback", key: "feedback" },
        { label: "Service/Agent Feedback", key: "feedback_score_agent" },
        { label: "Comment", key: "comment" }
    ];

    constructor(props) {
        super(props);
        this.state = {
            limitedVisitors: [],
            allAgents: [],
            loader: true,
            orderBy: "addedon",
            orderByType: "desc",
            selectedVisitor: {},
            searchAgent: "all",
            searchStatus: "",
            limit: 50,
            currentPage: 1,
            totalPages: 0,
            totalRecords: 0,
            ongoingChecked: false,
            completeChecked: false,
            missedChecked: false,
            isShowSearchOpt: false,
            searchOption: "all",
            searchWords: "",
            fromDateValue: '',
            toDateValue: '',
            startDate: '',
            endDate: '',
            dateError: '',
            paidChecked: false,
            trialChecked: false,
            visitorChecked: false,
            agentChecked: false,
            isMobileDevice: isMobileDevice(),
            disPlaytext: "",
            showDescPopup: false,
            disPlaytextId: "",
            cinfirmType: "",
            download_otp: "",
            otpError: "",
            historyData: [],
            downloadStatus: "",
            widgetStatus: false,
            showDummyData: false,
            todayDate: moment().format("YYYY-MM-DD"),
            chatBotData: {},
            rating1StarProduct: false,
            rating2StarProduct: false,
            rating3StarProduct: false,
            rating4StarProduct: false,
            rating5StarProduct: false,
            rating1StarAgent: false,
            rating2StarAgent: false,
            rating3StarAgent: false,
            rating4StarAgent: false,
            rating5StarAgent: false,
            country: "all",
            searchvisitor: "",
            sourceTextChecked: false,
            sourceCallChecked: false
        };

        this.csvLinkEl = React.createRef();

        this.getAllAgents();
        this.getchatBot();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    countryToFlag = (isoCode) => {
        return typeof String.fromCodePoint !== 'undefined' ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397)) : isoCode;
    }

    getAllAgents = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where('deletetime', '==', 0).get().then((querySnapshot) => {
            var allAgents = [];
            querySnapshot.forEach((doc) => {
                allAgents.push({ label: doc.data().alias, name: doc.data().name, value: doc.id });
            });
            // sort by name
            allAgents.sort(function (a, b) {
                var nameA = a.name.toUpperCase(); // ignore upper and lowercase
                var nameB = b.name.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                // names must be equal
                return 0;
            });
            this.setState({ allAgents: allAgents });
        });
    }

    getchatBot = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('chatbots').where("status", '==', 1).limit(1).get().then((docs) => {
            if (!docs.empty) {
                let chatBotData = {};
                docs.forEach((doc) => {
                    chatBotData.post_chat_form = doc.data().post_chat_form ? doc.data().post_chat_form : false;
                    chatBotData.feedback_type = doc.data().feedback_type ? parseInt(doc.data().feedback_type) : 2;
                });
                this.setState({
                    chatBotData: chatBotData
                });
            }
        });
    }

    /* Date functions Start */

    getDateInFormat = (timestamp) => {
        timestamp = Math.floor(timestamp / 1000);
        var formateddate = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("MMM DD YYYY hh:mm A");
        return formateddate;
    }

    /* Date functions End */

    ////////// Private function start //////////

    nameIntials = (nameData) => {
        var arrayData = nameData.split(' ');
        if (arrayData.length === 1) {
            return nameData.substr(0, 2).toUpperCase();
        } else {
            return arrayData[0].charAt(0).toUpperCase() + arrayData[arrayData.length - 1].charAt(0).toUpperCase();
        }
    }

    encryptData = (visData) => {
        let encEmail = NO_REPLY_EMAIL;
        let encName = visData.name;
        if (validateEmail(visData.email)) {
            let narrayData = visData.email.split("@");
            if (narrayData.length === 2) {
                encEmail = narrayData[0].replace(/./g, (c, i) => i == 0 ? c : '*') + "@" + narrayData[1];
            } else {
                encEmail = visData.email
            }
        }

        if (encName.indexOf("#Visitor") === -1) {
            let narrayData = encName.split(' ');
            if (narrayData.length === 1) {
                encName = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
            } else if (narrayData.length === 3) {
                encName = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[1].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[2].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
            } else {
                encName = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[1].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
            }
        }

        return { encName: encName, encEmail: encEmail };
    }

    /* Download button functions start */

    sendOtp = () => {
        if (this.state.downloadStatus !== "") {
            return false;
        }
        this.setState({ otpError: '' });
        if (localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") {
            var body222222 = [
                `email=${this.agent.email.toLowerCase()}&type=download_history_otp&ownerId=${this.agent.ownerId}&agentId=${this.agent.agentId}`
            ];
            //fetch(MAIL_FUNCTION_URL + "/mailFunctions/sendEmail", {
            fetch(GATEWAY_URL + "/sendEmail", {
                method: "post",
                body: body222222,
                headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
            })
                .then(response => response.json())
                .then(response => {
                    if (response.status === false || response.err !== null) {
                        NotificationManager.error("", 'Something went wrong!', 5000);
                    } else {
                        NotificationManager.success("", 'New OTP has been sent to your registered email id', 5000);
                    }
                }).catch(() => {
                    NotificationManager.error("", 'Something went wrong!', 5000);
                });
        } else {
            return false;
        }
    }

    checkOTP = () => {
        if (localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") { /* Continue */ } else { return false; }
        this.setState({ otpError: '' });
        if (this.state.download_otp.trim() === '') {
            this.setState({
                otpError: 'OTP can not be blank',
                download_otp: ''
            })
            return false;
        }
        var body = [`ownerId=${this.agent.ownerId}&dOtp=${this.state.download_otp}&agentId=${this.agent.agentId}`];
        ///fetch(MAIL_FUNCTION_URL + "/loginApi/verifydownloadotp", {
        fetch(GATEWAY_URL + "/verifydownloadotp", {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(response => {
                if (response.status === 200) {
                    this.setState({ downloadStatus: "Downloading...", otpError: "", download_otp: "" }, () => {
                        ////this.getHistoryData();
                        this.getHistoryData_Elastic();
                    });
                } else if (response.status === 202) {
                    this.setState({
                        otpError: 'OTP has expired please click on "resend OTP" button to receive new OTP'
                    })
                } else {
                    this.setState({
                        otpError: 'Incorrect OTP'
                    })
                }
            }).catch(() => {
                NotificationManager.error("", 'Something went wrong!', 5000);
            });
    }

    getHistoryData_Elastic = async () => {
        if (localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") { /* Continue */ } else { return false; }
        var searchWords = "";
        searchWords = document.getElementById("searchvisitor").value;
        searchWords = searchWords.trim();

        var searchAgent = (this.state.searchAgent && this.state.searchAgent !== "all") ? this.state.searchAgent : "";
        var ongoingChecked = this.state.ongoingChecked;
        var completeChecked = this.state.completeChecked;
        var missedChecked = this.state.missedChecked;
        var paidChecked = this.state.paidChecked;
        var trialChecked = this.state.trialChecked;
        var visitorChecked = this.state.visitorChecked;
        var agentChecked = this.state.agentChecked;
        var country = (this.state.country && this.state.country !== "all") ? this.state.country : "";

        let ratingsProduct = {
            rating1StarProduct: this.state.rating1StarProduct,
            rating2StarProduct: this.state.rating2StarProduct,
            rating3StarProduct: this.state.rating3StarProduct,
            rating4StarProduct: this.state.rating4StarProduct,
            rating5StarProduct: this.state.rating5StarProduct,
            ratingType: this.state.chatBotData.feedback_type
        }
        let ratingsAgent = {
            rating1StarAgent: this.state.rating1StarAgent,
            rating2StarAgent: this.state.rating2StarAgent,
            rating3StarAgent: this.state.rating3StarAgent,
            rating4StarAgent: this.state.rating4StarAgent,
            rating5StarAgent: this.state.rating5StarAgent,
            ratingType: this.state.chatBotData.feedback_type
        }

        let sourceTextChecked = this.state.sourceTextChecked;
        let sourceCallChecked = this.state.sourceCallChecked;

        if (this.state.fromDateValue !== '' && this.state.toDateValue !== '') {
            let fromDate = moment(this.state.fromDateValue, "D/M/YYYY").valueOf();
            let toDate = moment(this.state.toDateValue, "D/M/YYYY").valueOf();
            toDate = toDate + 86400000;
            var Difference_In_Time = toDate - fromDate;
            var totalDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
            if (totalDays > 62) {
                NotificationManager.error("", 'You can not download data more than 2 months at a time!', 10000);
                return false;
            }
        } else if (this.state.fromDateValue !== '' && this.state.toDateValue === '') {
            let fromDate = moment(this.state.fromDateValue, "D/M/YYYY").valueOf();
            let toDate = new Date();
            toDate.setHours(23, 23, 59, 0);
            var Difference_In_Time = toDate.getTime() - fromDate;
            var totalDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
            if (totalDays > 62) {
                NotificationManager.error("", 'You can not download data more than 2 months at a time!', 10000);
                return false;
            }
        } else if (this.state.fromDateValue === '' && this.state.toDateValue !== '') {
            NotificationManager.error("", 'Please select "From date" first!', 10000);
            return false;
        }

        //const ELASTICINDEX = 'livechat_transcript_'+this.agent.ownerId;
        const archiveRecords = await elasticArchivesHistory(this.agent.ownerId, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, this.state.searchOption, 1, this.state.limit, searchWords, this.state.fromDateValue, this.state.toDateValue, ratingsProduct, ratingsAgent, 'addedon', 'desc', [], country, sourceTextChecked, sourceCallChecked);
        // console.log('archiveRecords >> ', archiveRecords, searchWords);
        if (archiveRecords.status === 200) {
            const allVisitors = [];
            var allUsers = archiveRecords.data;
            allUsers.forEach(vData => {
                var dateInFormat = "";
                if (vData.picktime) {
                    dateInFormat = this.getDateInFormat(vData.picktime);
                } else {
                    dateInFormat = this.getDateInFormat(vData.addedon);
                }
                if (vData.visitor_status === 7) {
                    vData.visitor_status = "Missed";
                } else if (vData.visitor_status === "close" || vData.visitor_status === 5) {
                    vData.visitor_status = "Completed";
                } else {
                    vData.visitor_status = "No Status";
                }
                if (!validateEmail(vData.email)) {
                    vData.email = NO_REPLY_EMAIL;
                }
                var vType = "--";
                if (vData.isPaid === 1) {
                    vType = "Paid";
                } else if (vData.isPaid === 2) {
                    vType = "Trial";
                } else if (vData.chat_type === 2) {
                    vType = "Internal";
                }

                let agentsName = "";
                if (vData.agent_names && vData.agent_names.length > 0) {
                    vData.agent_names.forEach((agtnmmm, index) => {
                        if (index > 0) {
                            agentsName += ", ";
                        }
                        agentsName += agtnmmm.name;
                    });
                }
                const detail = {
                    session_id: vData.visitorId,
                    date_time: dateInFormat,
                    addedon_timestamp: vData.addedon,
                    visitor_email: vData.email,
                    visitor_name: vData.name,
                    status: vData.visitor_status,
                    feedback: vData.feedback_score,
                    feedback_score_agent: vData.feedback_score_agent ? vData.feedback_score_agent : "",
                    type: vType,
                    comment: vData.feedback_comment ? vData.feedback_comment : "",
                    agents_name: agentsName,
                }
                allVisitors.push(detail);
            });

            var sortArray = allVisitors.sort(function (a, b) {
                return b.addedon_timestamp - a.addedon_timestamp;
            });

            this.setState({ historyData: sortArray }, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                    this.setState({ downloadStatus: "Download successfully" }, () => {
                        setTimeout(() => {
                            this.closeVerification();
                        }, 2000);
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).update({ downloadOtp: "", downloadOtpTime: 0 });
                    });
                }, 1000);
            });
        } else {
            const notMsg = archiveRecords.msg ? archiveRecords.msg : 'Something went wrong, Please try again after some time!';
            NotificationManager.error("", notMsg, 10000);
            return false;
        }
    }

    getHistoryData = () => {
        if (localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") { /* Continue */ } else { return false; }
        var searchWords = "";
        searchWords = document.getElementById("searchvisitor").value;
        searchWords = searchWords.trim();

        var searchAgent = (this.state.searchAgent && this.state.searchAgent !== "all") ? this.state.searchAgent : "";
        var ongoingChecked = this.state.ongoingChecked;
        var completeChecked = this.state.completeChecked;
        var missedChecked = this.state.missedChecked;
        var paidChecked = this.state.paidChecked;
        var trialChecked = this.state.trialChecked;
        var visitorChecked = this.state.visitorChecked;
        var agentChecked = this.state.agentChecked;

        var client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_KEY);
        var index = client.initIndex(this.agent.ownerId);

        var statusArr = [];
        if (ongoingChecked) {
            statusArr.push(4);
        }

        if (completeChecked) {
            statusArr.push(5);
            statusArr.push(6);
        }

        if (missedChecked) {
            statusArr.push(1);
            statusArr.push(2);
            statusArr.push(7);
        }

        var tmpStatusQury = '';
        if (statusArr.length > 0) {
            var countp = 0;
            statusArr.forEach(val => {
                if (countp > 0) {
                    tmpStatusQury += ' OR '
                }
                tmpStatusQury += 'visitor_status=' + val;
                countp++;
            });
        }

        var stsFilter = '';
        if (this.state.searchOption !== "all") {
            if (searchAgent && tmpStatusQury !== "") {
                stsFilter = '(agent_ids:"' + parseInt(searchAgent) + '" OR agent_ids:"' + searchAgent + '") AND (' + tmpStatusQury + ')';
            } else if (searchAgent) {
                stsFilter = '(agent_ids:"' + parseInt(searchAgent) + '" OR agent_ids:"' + searchAgent + '")';
            } else if (tmpStatusQury !== "") {
                stsFilter = tmpStatusQury;
            }
            var tmpstsFilter = '';
            if (searchWords !== "") {
                tmpstsFilter = this.state.searchOption + ':"' + searchWords + '"';
            }
            if (stsFilter !== "" && tmpstsFilter !== "") {
                stsFilter += ' AND ' + tmpstsFilter;
            } else if (tmpstsFilter !== "") {
                stsFilter += tmpstsFilter;
            }
        } else {
            if (searchAgent && tmpStatusQury !== "") {
                stsFilter = '(agent_ids:"' + parseInt(searchAgent) + '" OR agent_ids:"' + searchAgent + '") AND (' + tmpStatusQury + ')';
            } else if (searchAgent) {
                stsFilter = '(agent_ids:"' + parseInt(searchAgent) + '" OR agent_ids:"' + searchAgent + '")';
            } else if (tmpStatusQury !== "") {
                stsFilter = tmpStatusQury;
            }
        }

        if (trialChecked && paidChecked) {
            if (stsFilter !== '') {
                stsFilter += ' AND (isPaid = 1 OR isPaid = 2)';
            } else {
                stsFilter = '(isPaid = 1 OR isPaid = 2)';
            }
        } else {
            if (paidChecked) {
                if (stsFilter !== '') {
                    stsFilter += ' AND isPaid = 1';
                } else {
                    stsFilter = 'isPaid = 1';
                }
            }
            if (trialChecked) {
                if (stsFilter !== '') {
                    stsFilter += ' AND isPaid = 2';
                } else {
                    stsFilter = 'isPaid = 2';
                }
            }
        }

        if (visitorChecked && agentChecked) {
            if (stsFilter !== '') {
                stsFilter += ' AND (chat_type = 1 OR chat_type = 2)';
            } else {
                stsFilter = '(chat_type = 1 OR chat_type = 2)';
            }
        } else {
            if (visitorChecked) {
                if (stsFilter !== '') {
                    stsFilter += ' AND chat_type = 1';
                } else {
                    stsFilter = 'chat_type = 1';
                }
            }
            if (agentChecked) {
                if (stsFilter !== '') {
                    stsFilter += ' AND chat_type = 2';
                } else {
                    stsFilter = 'chat_type = 2';
                }
            }
        }

        if (this.state.fromDateValue !== '' && this.state.toDateValue !== '') {
            let fromDate = moment(this.state.fromDateValue, "D/M/YYYY").valueOf();
            let toDate = moment(this.state.toDateValue, "D/M/YYYY").valueOf();
            toDate = toDate + 86400000;
            var Difference_In_Time = toDate - fromDate;
            var totalDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
            if (totalDays > 62) {
                NotificationManager.error("", 'You can not download data more than 2 months at a time!', 10000);
                return false;
            } else {
                if (stsFilter !== '') {
                    stsFilter += ' AND addedon > ' + fromDate + ' AND addedon < ' + toDate;
                } else {
                    stsFilter = 'addedon > ' + fromDate + ' AND addedon < ' + toDate;
                }
            }
        } else if (this.state.fromDateValue !== '' && this.state.toDateValue === '') {
            let fromDate = moment(this.state.fromDateValue, "D/M/YYYY").valueOf();
            let toDate = new Date();
            toDate.setHours(23, 23, 59, 0);
            var Difference_In_Time = toDate.getTime() - fromDate;
            var totalDays = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));
            if (totalDays > 62) {
                NotificationManager.error("", 'You can not download data more than 2 months at a time!', 10000);
                return false;
            } else {
                if (stsFilter !== '') {
                    stsFilter += ' AND addedon > ' + fromDate + ' AND addedon < ' + toDate.getTime();
                } else {
                    stsFilter = 'addedon > ' + fromDate + ' AND addedon < ' + toDate.getTime();
                }
            }
        } else if (this.state.fromDateValue === '' && this.state.toDateValue !== '') {
            NotificationManager.error("", 'Please select "From date" first!', 10000);
            return false;
        }

        if (stsFilter === "") {
            let todayD = new Date();
            todayD.setHours(0, 0, 0, 0);
            todayD.setDate(todayD.getDate() - 62);
            stsFilter = 'addedon > ' + todayD.getTime();
        }

        let hits = [];
        index.browseObjects({
            query: searchWords, // Empty query will match all records
            filters: stsFilter,
            batch: batch => {
                console.log("Downloading ---->>>>>>>>>>>>>");
                hits = hits.concat(batch);
            }
        }).then(() => {
            const allVisitors = [];
            hits.forEach(vData => {
                var dateInFormat = "";
                if (vData.picktime) {
                    dateInFormat = this.getDateInFormat(vData.picktime);
                } else {
                    dateInFormat = this.getDateInFormat(vData.addedon);
                }
                if (vData.visitor_status === 7) {
                    vData.visitor_status = "Missed";
                } else if (vData.visitor_status === "close" || vData.visitor_status === 5) {
                    vData.visitor_status = "Completed";
                } else {
                    vData.visitor_status = "No Status";
                }
                if (!validateEmail(vData.email)) {
                    vData.email = NO_REPLY_EMAIL;
                }
                var vType = "--";
                if (vData.isPaid === 1) {
                    vType = "Paid";
                } else if (vData.isPaid === 2) {
                    vType = "Trial";
                } else if (vData.chat_type === 2) {
                    vType = "Internal";
                }
                const detail = {
                    session_id: vData.visitorId,
                    date_time: dateInFormat,
                    addedon_timestamp: vData.addedon,
                    visitor_email: vData.email,
                    visitor_name: vData.name,
                    status: vData.visitor_status,
                    feedback: vData.feedback_score,
                    feedback_score_agent: vData.feedback_score_agent ? vData.feedback_score_agent : "",
                    type: vType,
                    comment: vData.feedback_comment ? vData.feedback_comment : "",
                }
                allVisitors.push(detail);
            });

            var sortArray = allVisitors.sort(function (a, b) {
                return b.addedon_timestamp - a.addedon_timestamp;
            });

            this.setState({ historyData: sortArray }, () => {
                setTimeout(() => {
                    this.csvLinkEl.current.link.click();
                    this.setState({ downloadStatus: "Download successfully" }, () => {
                        setTimeout(() => {
                            this.closeVerification();
                        }, 2000);
                        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).update({ downloadOtp: "", downloadOtpTime: 0 });
                    });
                }, 1000);
            });
        });
    }

    handleDownloadOtp = (e) => {
        let target = e.target;
        if (target.name === "download_otp") {
            this.setState({ download_otp: target.value, otpError: "" });
        }
    }

    downloadAuth = () => {
        if (this.state.showDummyData === true && this.state.widgetStatus === false) {
            NotificationManager.error("", 'Download will not work for sample report', 5000);
            return;
        }
        if (localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") {
            this.setState({ cinfirmType: "otp", otpError: '', download_otp: "" }, () => {
                this.sendOtp();
                try {
                    document.getElementById("download_otp").focus();
                } catch (err) { }
            });
        } else {
            return false;
        }
    }

    closeVerification = () => {
        this.setState({ cinfirmType: "", downloadStatus: "" });
    }

    checkSubmit = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.checkOTP();
            return false;
        }
    }

    /* Download button functions start */

    ////////// Private function end //////////

    /* Search functions Start */

    setAgent = (e) => {
        this.setState({ searchAgent: e.target.value });
    }

    setSelectOption = (e, cntry) => {
        this.setState({ country: cntry ? cntry.code : "all" });
    }

    checkenter = (event) => {
        if (event.keyCode === 13) {
            //this.searchVisitors(true, 1);
            this.searchVisitors_Elastic(true, 1);
        }
    }

    searchVisitors_Elastic = async (isSearch = false, type = 0) => {
        var searchWords = "";
        if (isSearch) {
            searchWords = document.getElementById("searchvisitor").value;
            searchWords = searchWords.trim();
        }
        this.setState({ searchWords: searchWords, loader: true, showDummyData: false });
        var searchAgent = (this.state.searchAgent && this.state.searchAgent !== "all") ? this.state.searchAgent : "";
        var ongoingChecked = this.state.ongoingChecked;
        var completeChecked = this.state.completeChecked;
        var missedChecked = this.state.missedChecked;
        var paidChecked = this.state.paidChecked;
        var trialChecked = this.state.trialChecked;
        var visitorChecked = this.state.visitorChecked;
        var agentChecked = this.state.agentChecked;
        var country = (this.state.country && this.state.country !== "all") ? this.state.country : "";

        let ratingsProduct = {
            rating1StarProduct: this.state.rating1StarProduct,
            rating2StarProduct: this.state.rating2StarProduct,
            rating3StarProduct: this.state.rating3StarProduct,
            rating4StarProduct: this.state.rating4StarProduct,
            rating5StarProduct: this.state.rating5StarProduct,
            ratingType: this.state.chatBotData.feedback_type
        }
        let ratingsAgent = {
            rating1StarAgent: this.state.rating1StarAgent,
            rating2StarAgent: this.state.rating2StarAgent,
            rating3StarAgent: this.state.rating3StarAgent,
            rating4StarAgent: this.state.rating4StarAgent,
            rating5StarAgent: this.state.rating5StarAgent,
            ratingType: this.state.chatBotData.feedback_type
        }

        let sourceTextChecked = this.state.sourceTextChecked;
        let sourceCallChecked = this.state.sourceCallChecked;

        if (type === 1) {
            this.state.currentPage = 1;
            this.setState({});
        }

        const archiveRecords = await elasticSearchArchives(this.agent.ownerId, searchAgent, ongoingChecked, completeChecked, missedChecked, paidChecked, trialChecked, visitorChecked, agentChecked, this.state.searchOption, this.state.currentPage, this.state.limit, searchWords, this.state.fromDateValue, this.state.toDateValue, 'addedon', 'desc', ratingsProduct, ratingsAgent, country, sourceTextChecked, sourceCallChecked);

        if (archiveRecords.status === 200) {
            var totalRecords = archiveRecords.data.totalRecords;
            var totalPages = Math.ceil(totalRecords / this.state.limit);
            var allUsers = archiveRecords.data.data;
            const allVisitors = [];

            if (totalRecords > 0) {
                allUsers.forEach((vData) => {
                    var dateInFormat = "";
                    if (vData.picktime) {
                        dateInFormat = this.getDateInFormat(vData.picktime);
                    } else {
                        dateInFormat = this.getDateInFormat(vData.addedon);
                    }

                    //const client_short = this.nameIntials(vData.name);
                    vData.clEmail = vData.email;
                    const encryptData = this.encryptData(vData);
                    vData.email = encryptData.encEmail;
                    vData.name = encryptData.encName;

                    if (vData.visitor_status === 7) {
                        vData.visitor_status = "Missed";
                    } else if (vData.visitor_status === "close" || vData.visitor_status === 5) {
                        vData.visitor_status = "Completed";
                    } else {
                        vData.visitor_status = "No Status";
                    }

                    /* if (!validateEmail(vData.email)) {
                        vData.email = NO_REPLY_EMAIL;
                    } */

                    var vType = "--";
                    if (vData.isPaid === 1) {
                        vType = "Paid";
                    } else if (vData.isPaid === 2) {
                        vType = "Trial";
                    } else if (vData.chat_type === 2) {
                        vType = "Internal";
                    }

                    let agentsName = "";
                    if (vData.agent_names && vData.agent_names.length > 0) {
                        vData.agent_names.forEach((agtnmmm, index) => {
                            if (index > 0) {
                                agentsName += ", ";
                            }
                            agentsName += agtnmmm.name;
                        });
                    }

                    const detail = {
                        id: vData.objectID,
                        visitorId: vData.visitorId,
                        addedon: dateInFormat,
                        addedon_timestamp: vData.picktime ? vData.picktime : vData.addedon,
                        email: vData.email,
                        browser: vData.browser ? vData.browser : "",
                        country: vData.country ? vData.country : "",
                        operating: vData.operating ? vData.operating : "",
                        agent_ids: vData.agent_ids ? vData.agent_ids : [],
                        agent_leave: vData.agent_leave ? vData.agent_leave : [],
                        //client_short: client_short,
                        updatedon: vData.updatedon,
                        visitor_timezone: vData.visitor_timezone,
                        isChatend: vData.isChatend,
                        geoDetails: vData.geoDetails,
                        name: vData.name,
                        phone: vData.client_phone,
                        agent_end: vData.agent_end,
                        status: vData.status,
                        visitor_email: vData.visitor_email,
                        incoming: vData.incoming,
                        visitor_status: vData.visitor_status,
                        feedback_score: vData.feedback_score,
                        feedback_received: vData.feedback_received,
                        feedback_received_agent: vData.feedback_received_agent,
                        type: vType,
                        feedback_comment: vData.feedback_comment ? vData.feedback_comment : "",
                        feedback_type: vData.feedback_type ? vData.feedback_type : "star",
                        clEmail: vData.clEmail ? vData.clEmail : "",
                        agents_name: agentsName,
                        feedback_score_agent: vData.feedback_score_agent ? vData.feedback_score_agent : ""
                    }
                    allVisitors.push(detail);
                })
            } else if (searchWords.indexOf("CLIENT") === 0) {
                let record = await this.checkInDbOnce(searchWords);
                if (record) {
                    /* Send Data to Elastic */
                    let body = [`ownerId=${this.agent.ownerId}&visitorId=${searchWords}`];
                    fetch(GATEWAY_URL + "/sendchat", {
                        method: "post",
                        body: body,
                        headers: {
                            "X-API-Key": FUNCTION_API_KEY,
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Accept": "application/json",
                            "Origin": window.location.origin
                        }
                    }).then(res => res.json())
                        .then(response => { })
                        .catch(e => { });
                    /* Send Data to Elastic  */
                    allVisitors.push(record);
                }
            }

            var sortArray = allVisitors.sort(function (a, b) {
                return b.addedon_timestamp - a.addedon_timestamp;
            });

            this.setState({ limitedVisitors: sortArray, totalPages: totalPages, totalRecords: totalRecords, loader: false });
        } else {
            this.setState({
                loader: false
            })
        }
    }

    checkInDbOnce = (chatId) => {
        return new Promise(resolve => {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(chatId).get().then((doc) => {
                if (doc.exists) {
                    let _data = doc.data();
                    if (_data.isChatend === 1) {
                        let vStts = "No Status";
                        if (_data.visitor_status === "incoming" || _data.visitor_status === "missed") {
                            vStts = "Missed";
                        } else if (_data.visitor_status === "ongoing" || _data.visitor_status === "close" || _data.visitor_status === "end_ongoing_chat") {
                            vStts = "Completed";
                        }

                        let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        const validEmail = re.test(String(_data.client_email).toLowerCase());

                        let dateInFormat = "";
                        if (_data.picktime) {
                            dateInFormat = this.getDateInFormat(_data.picktime);
                        } else {
                            dateInFormat = this.getDateInFormat(_data.addedon);
                        }

                        var plan_name = _data.plan_name ? _data.plan_name : false;
                        var isPlanTrial = _data.isPlanTrial ? _data.isPlanTrial : false;
                        var vType = "--";
                        if (plan_name) {
                            if (isPlanTrial) {
                                vType = "Trial";
                            } else {
                                vType = "Paid";
                            }
                        } else {
                            if (isPlanTrial) {
                                vType = "Trial";
                            }
                        }
                        if (_data.bg_color === "agent-voip-call") {
                            vType = "Internal";
                        }

                        let tmpValidEmail = (_data.client_email !== "" && validEmail) ? _data.client_email : "noreply@appypie.com";
                        let requiredData = {
                            id: doc.id,
                            visitorId: doc.id,
                            addedon: dateInFormat,
                            addedon_timestamp: _data.picktime ? _data.picktime : _data.addedon,
                            email: tmpValidEmail,
                            browser: _data.browser ? _data.browser : "",
                            country: _data.country ? _data.country : "",
                            operating: _data.operating ? _data.operating : "",
                            agent_ids: _data.agent_ids ? _data.agent_ids : [],
                            agent_leave: _data.agent_leave ? _data.agent_leave : [],
                            updatedon: _data.updatedon,
                            visitor_timezone: _data.visitor_timezone,
                            isChatend: _data.isChatend,
                            geoDetails: _data.geoDetails,
                            name: _data.client_name,
                            phone: _data.client_phone,
                            agent_end: _data.agent_end,
                            status: _data.status,
                            visitor_email: _data.visitor_email,
                            incoming: _data.incoming,
                            visitor_status: vStts,
                            feedback_score: _data.feedback_score ? _data.feedback_score : "",
                            feedback_score_agent: _data.feedback_score_agent ? _data.feedback_score_agent : "",
                            feedback_received: _data.feedback_received ? _data.feedback_received : "",
                            feedback_received_agent: _data.feedback_received_agent ? _data.feedback_received_agent : "",
                            type: vType,
                            feedback_comment: _data.feedback_comment ? _data.feedback_comment : "",
                            feedback_type: _data.feedback_type ? _data.feedback_type : "star",
                            clEmail: tmpValidEmail
                        };
                        const encryptData = this.encryptData(requiredData);
                        requiredData.email = encryptData.encEmail;
                        requiredData.name = encryptData.encName;
                        resolve(requiredData);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }).catch((err) => {
                resolve(false);
            });
        });
    }

    handlePageChange = (pageno) => {
        this.setState({ currentPage: pageno }, () => {
            //this.searchVisitors(true, 0);
            this.searchVisitors_Elastic(true, 0);
        });
    }

    /* Search functions End */
    componentWillMount = async () => {
        let widgetStatus = await checkWidgetStatus();
        this.setState({
            widgetStatus: widgetStatus
        })
        if (widgetStatus === true) {
            const urlValues = queryString.parse(window.location.search);
            if (urlValues.chatId) {
                document.getElementById('searchvisitor').value = urlValues.chatId;
                //this.searchVisitors(true, 1);
                this.setState({
                    searchvisitor: urlValues.chatId,
                    searchOption: 'visitorId'
                }, () => {
                    this.searchVisitors_Elastic(true, 1)
                })
            } else {
                //this.searchVisitors(false, 0);
                this.searchVisitors_Elastic(false, 0);
            }
        } else {
            this.getDummyData();
        }
        activityLog("Opened chat history page");
    }

    getDummyData = () => {
        var archivesData = dummyData.archives;
        var allVisitors = [];
        archivesData.forEach((vData) => {
            var dateInFormat = "";
            if (vData.picktime) {
                dateInFormat = this.getDateInFormat(vData.picktime);
            } else {
                dateInFormat = this.getDateInFormat(vData.addedon);
            }

            //const client_short = this.nameIntials(vData.name);
            const encryptData = this.encryptData(vData);
            vData.email = encryptData.encEmail;
            vData.name = encryptData.encName;

            if (vData.visitor_status === 7) {
                vData.visitor_status = "Missed";
            } else if (vData.visitor_status === "close" || vData.visitor_status === 5) {
                vData.visitor_status = "Completed";
            } else {
                vData.visitor_status = "No Status";
            }

            /* if (!validateEmail(vData.email)) {
                vData.email = NO_REPLY_EMAIL;
            } */

            var vType = "--";
            if (vData.isPaid === 1) {
                vType = "Paid";
            } else if (vData.isPaid === 2) {
                vType = "Trial";
            } else if (vData.chat_type === 2) {
                vType = "Internal";
            }

            const detail = {
                id: vData.objectID,
                visitorId: vData.visitorId,
                addedon: dateInFormat,
                addedon_timestamp: vData.addedon,
                email: vData.email,
                browser: vData.browser ? vData.browser : "",
                country: vData.country ? vData.country : "",
                operating: vData.operating ? vData.operating : "",
                agent_ids: vData.agent_ids ? vData.agent_ids : [],
                agent_leave: vData.agent_leave ? vData.agent_leave : [],
                //client_short: client_short,
                updatedon: vData.updatedon,
                visitor_timezone: vData.visitor_timezone,
                isChatend: vData.isChatend,
                geoDetails: vData.geoDetails,
                name: vData.name,
                phone: vData.client_phone,
                agent_end: vData.agent_end,
                status: vData.status,
                visitor_email: vData.visitor_email,
                incoming: vData.incoming,
                visitor_status: vData.visitor_status,
                feedback_score: vData.feedback_score,
                feedback_received: vData.feedback_received,
                feedback_received_agent: vData.feedback_received_agent,
                type: vType,
                feedback_comment: vData.feedback_comment ? vData.feedback_comment : "",
                feedback_type: vData.feedback_type ? vData.feedback_type : "star",
                feedback_score_agent: vData.feedback_score_agent ? vData.feedback_score_agent : ""
            }
            allVisitors.push(detail);
        })

        this.setState({
            loader: false,
            limitedVisitors: allVisitors,
            totalRecords: allVisitors.length,
            showDummyData: true
        })
    }

    viewChat = (visitor) => {
        var client_short = this.nameIntials(visitor.name);
        visitor.client_short = client_short;
        try {
            document.getElementById("shadowMasking").style.display = "block";
        } catch (err) { }

        this.setState({ selectedVisitor: visitor });
        activityLog("Viewed chat transcript");
    }

    handleCloseChat = () => {
        this.setState({ selectedVisitor: {} });
    }

    handleCheck = (e) => {
        this.setState({ searchOption: e.target.value });
    }

    /* Search function start */

    viewSearchOptions = () => {
        try {
            document.getElementById("shadowMasking").style.display = "block";
        } catch (err) { }
        this.setState({ isShowSearchOpt: true });
    }

    closeSearchOptions = () => {
        try {
            document.getElementById("shadowMasking").style.display = "none";
        } catch (err) { }
        this.setState({ isShowSearchOpt: false });
    }

    handleCheckStatus = (event) => {

        if (event.target.value === "ongoing") {
            this.setState({ ongoingChecked: !this.state.ongoingChecked })
        }

        if (event.target.value === "missed") {
            this.setState({ missedChecked: !this.state.missedChecked })
        }

        if (event.target.value === "completed") {
            this.setState({ completeChecked: !this.state.completeChecked })
        }

        if (event.target.value === "paid") {
            this.setState({ paidChecked: !this.state.paidChecked })
        }

        if (event.target.value === "trial") {
            this.setState({ trialChecked: !this.state.trialChecked })
        }

        if (event.target.value === "visitor") {
            this.setState({ visitorChecked: !this.state.visitorChecked })
        }

        if (event.target.value === "agent") {
            this.setState({ agentChecked: !this.state.agentChecked })
        }

        if (event.target.value === "1star") {
            this.setState({ rating1StarProduct: !this.state.rating1StarProduct })
        } else if (event.target.value === "2star") {
            this.setState({ rating2StarProduct: !this.state.rating2StarProduct })
        } else if (event.target.value === "3star") {
            this.setState({ rating3StarProduct: !this.state.rating3StarProduct })
        } else if (event.target.value === "4star") {
            this.setState({ rating4StarProduct: !this.state.rating4StarProduct })
        } else if (event.target.value === "5star") {
            this.setState({ rating5StarProduct: !this.state.rating5StarProduct })
        }

        if (event.target.value === "1starAgent") {
            this.setState({ rating1StarAgent: !this.state.rating1StarAgent })
        } else if (event.target.value === "2starAgent") {
            this.setState({ rating2StarAgent: !this.state.rating2StarAgent })
        } else if (event.target.value === "3starAgent") {
            this.setState({ rating3StarAgent: !this.state.rating3StarAgent })
        } else if (event.target.value === "4starAgent") {
            this.setState({ rating4StarAgent: !this.state.rating4StarAgent })
        } else if (event.target.value === "5starAgent") {
            this.setState({ rating5StarAgent: !this.state.rating5StarAgent })
        }

        if (event.target.value === "sourceText") {
            this.setState({ sourceTextChecked: !this.state.sourceTextChecked })
        }
        if (event.target.value === "sourceCall") {
            this.setState({ sourceCallChecked: !this.state.sourceCallChecked })
        }
    }

    applyFilter = () => {
        if (this.state.showDummyData === true && this.state.widgetStatus === false) {
            NotificationManager.error("", 'Filter will not work for sample report', 5000);
            return;
        }
        var validate = this.dateValidate(this.state.fromDateValue, this.state.toDateValue);
        if (validate === true) {
            try {
                document.getElementById("shadowMasking").style.display = "none";
            } catch (err) { }

            ////this.searchVisitors(true, 1);
            this.searchVisitors_Elastic(true, 1);
            this.closeSearchOptions();
        }
    }

    clearFilter = () => {
        try {
            document.getElementById("from_date").value = "";
            document.getElementById("to_date").value = "";
            document.getElementById("country").value = "";
            document.getElementById("shadowMasking").style.display = "none";
        } catch (err) { }
        this.setState({ ongoingChecked: false, missedChecked: false, completeChecked: false, paidChecked: false, trialChecked: false, visitorChecked: false, agentChecked: false, searchAgent: "all", fromDateValue: "", toDateValue: "", startDate: "", endDate: "", rating1StarProduct: false, rating2StarProduct: false, rating3StarProduct: false, rating4StarProduct: false, rating5StarProduct: false, rating1StarAgent: false, rating2StarAgent: false, rating3StarAgent: false, rating4StarAgent: false, rating5StarAgent: false, country: "all", sourceTextChecked: false, sourceCallChecked: false }, () => {
            /////this.searchVisitors(true, 1);
            this.searchVisitors_Elastic(true, 1);
            this.closeSearchOptions();
        });
    }

    formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [day, month, year].join('/');
    }

    dateValidate = (fromDate, toDate) => {
        let returnValue = true;
        var fromDateValue = moment(fromDate, "D/M/YYYY").valueOf();
        var toDateValue = moment(toDate, "D/M/YYYY").valueOf();
        if (fromDateValue > toDateValue) {
            this.setState({
                dateError: 'To date can not be less than from date'
            })
            returnValue = false;
        } else {
            this.setState({
                dateError: ''
            })
        }
        return returnValue;
    }

    handleToDatePicker = (e) => {
        let date = this.formatDate(e.target.value);
        this.dateValidate(this.state.fromDateValue, date);
        this.setState({
            toDateValue: date,
            endDate: e.target.value
        })
    }

    handleFromDatePicker = (e) => {
        let date = this.formatDate(e.target.value);
        this.dateValidate(date, this.state.toDateValue);
        this.setState({
            fromDateValue: date,
            startDate: e.target.value
        })
    }

    hideDummyData = () => {
        this.setState({
            showDummyData: false
        });
        ///this.searchVisitors(false, 0);
        this.searchVisitors_Elastic(false, 0);
    }

    render() {
        let displayPaging;
        if (this.state.limitedVisitors.length === 0) {
            displayPaging = "none";
        } else {
            displayPaging = "block";
        }


        var isShowSearchOpt = "none";
        if (this.state.isShowSearchOpt) {
            isShowSearchOpt = "block";
        }

        let selectedOption;
        if (this.state.searchOption === "all") {
            selectedOption = "All";
        } else if (this.state.searchOption === "name") {
            selectedOption = "Visitor Name";
        } else if (this.state.searchOption === "email") {
            selectedOption = "Visitor Email Id";
        } else if (this.state.searchOption === "visitorId") {
            selectedOption = "Visitor";
        }

        let activeClr = '#efefef';
        let activeClrtext = '';
        if (this.state.searchAgent !== "all" || this.state.ongoingChecked || this.state.completeChecked || this.state.missedChecked || this.state.paidChecked || this.state.trialChecked || this.state.visitorChecked || this.state.agentChecked || this.state.rating1StarProduct || this.state.rating2StarProduct || this.state.rating3StarProduct || this.state.rating4StarProduct || this.state.rating5StarProduct || this.state.rating1StarAgent || this.state.rating2StarAgent || this.state.rating3StarAgent || this.state.rating4StarAgent || this.state.rating5StarAgent || this.state.fromDateValue || this.state.country !== "all" || this.state.sourceCallChecked || this.state.sourceTextChecked ) {
            activeClr = '#4092f9';
            activeClrtext = '#fff';
        }

        let activeBkndClr = '#4092fa';
        if (this.state.searchWords) {
            activeBkndClr = '#4092fa';
        }

        let selectedCountry = [];
        countries.map(v => {
            if (v.code === this.state.country) {
                selectedCountry = v;
            }
        });

        return (<>
            <ReactTooltip place={'bottom'} effect={'solid'} multiline={true} />                
            <NotificationContainer />
            <CSVLink
                headers={this.fileheaders}
                filename="chathistory.csv"
                data={this.state.historyData}
                ref={this.csvLinkEl}
            />            
            <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="page-wrapper" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                        {/* <h3 className="heding-text">Conversation history</h3>  */}
                        {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '10px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                    </div>
                    <div className="body-section bg-white px-5 py-5 mob-view-cont">
                        {/* Legends: <img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} /> : Ratings for Product <br/>
                        <img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} /> : Ratings for Service/Agent */}
                        <div className="agent-section">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table-header table-header row mb-4">
                                        <div className="col-xs-12 col-sm-12 col-md-12 input-group mob-fltr download-manager">
                                            <div className={(localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") ? "search-feilds-history k-flex align-items-center mob-drop no-border" : "search-feilds-history k-flex align-items-center mob-drop no-border cstm-widthfull"}>
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="searchOption-select-outlined-label">Search By</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="searchOption-select-outlined-label" id="searchOption-select-outlined" name="searchOption" onChange={this.handleCheck} value={this.state.searchOption} label="Search By">
                                                            <MenuItem value="all">All</MenuItem>
                                                            <MenuItem value="visitorId">Visitor</MenuItem>
                                                            <MenuItem value="name">Visitor Name</MenuItem>
                                                            <MenuItem value="email">Visitor Email Id</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className="select-search-drpdown w-100P position-relative input-group visitore-search material-input">
                                                    <TextField className="form-control" id="searchvisitor" label="Search" variant="outlined" name="searchvisitor" value={this.state.searchvisitor ? this.state.searchvisitor : ""} onChange={this.handleChange} onKeyDown={(e) => this.checkenter(e)} />
                                                    {/* <input type="text" id="searchvisitor" onKeyDown={(e) => this.checkenter(e)} name="searchvisitor" className="form-control form-control-lg" placeholder="Search" aria-label="Search for messages or users..." /> */}
                                                    <div className="input-group-append agent">
                                                        <button style={{ backgroundColor: activeBkndClr }} onClick={() => {
                                                            if (this.state.showDummyData === true && this.state.widgetStatus === false) { NotificationManager.error("", 'Search will not work for sample report', 5000); } else {
                                                                this.searchVisitors_Elastic(true, 1);
                                                            }
                                                        }} className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="button">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                                <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#fff" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="histroy-filter pull-right">
                                                <div className="aside-agent k-flex align-items-center justify-content-end">
                                                    <span style={{ background: activeClr }} onClick={this.viewSearchOptions} className="gridTileOption dropdown mr-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="20.899" viewBox="0 0 22.674 20.899"><path style={{ fill: activeClrtext }} id="Path_1489" data-name="Path 1489" d="M22.608,10.077a.687.687,0,0,0-.622-.394H.687a.687.687,0,0,0-.53,1.124l8.188,9.931V29.9a.687.687,0,0,0,.992.616l4.6-2.28a.687.687,0,0,0,.382-.615l.009-6.878,8.188-9.931A.687.687,0,0,0,22.608,10.077Zm-9.5,9.977a.687.687,0,0,0-.157.436l-.009,6.7-3.226,1.6v-8.3a.687.687,0,0,0-.157-.437l-7.418-9H20.529Z" transform="translate(0 -9.683)" fill="#a2abd1"></path></svg>
                                                    </span>

                                                </div>
                                            </div>

                                            {(localStorage.getItem('userRole') === "Owner" || localStorage.getItem('userRole') === "Admin") ? (<div className="download-btn">
                                                <button onClick={this.downloadAuth} className="btn btn-primary waves-effect">Download</button>
                                                <div className="download-instruction">
                                                    Click on this button to download data. Data will have date, time,
                                                    session ID, name, email, chat status, type, feedback and comment.
                                                </div>
                                            </div>) : (null)}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table scroll-view-tbl">
                                        <table className="table table-borderless mb-0 liveCgatUserTable mob-tbl-view">
                                            <thead className="bg-darksecound white-text table-head-border-radius border-radius-table ">
                                                <tr>
                                                    <th>Date And Time</th>
                                                    <th>Session id</th>
                                                    <th>Visitor name</th>
                                                    <th>Visitor email</th>
                                                    <th>Agents Name</th>
                                                    <th>Chat status</th>
                                                    <th>Type</th>
                                                    <th>Feedback</th>
                                                    <th>Comment</th>
                                                    <th className="text-right pr-7">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="outline-1-black border-radius-table">
                                                {!this.state.loader ? (
                                                    this.state.limitedVisitors.length === 0 ? (<tr key="nodata">
                                                        <td className="error-img" colSpan="9">
                                                            <div className="no-recordFound text-center py-5">
                                                                <img alt="" src={require('../../../assets/images/noRecordes.svg')} />
                                                                <p>No records found!</p>
                                                            </div>
                                                        </td></tr>) : (this.state.limitedVisitors.map(visitor =>
                                                            <tr key={visitor.id}>
                                                                <td ><p className="small text-screen1">{visitor.addedon}</p></td>
                                                                <td><p className="small text-screen1">{visitor.visitorId}</p></td>
                                                                <td><p className="small text-screen1"><span className="table-in-longPara">{visitor.name}</span></p></td>
                                                                <td><p className="small text-screen1"><span className="table-in-longPara">{visitor.email}</span></p></td>
                                                                <td className='agent_name'><p className="small text-screen1"><span className="table-in-longPara">{visitor.agents_name ? visitor.agents_name : ""}</span></p></td>
                                                                <td><p className="small text-screen1">{visitor.visitor_status}</p></td>
                                                                <td><p className="small text-screen1">{visitor.type}</p></td>
                                                                <td><p className="small text-screen1">
                                                                    <span title="Ratings of Product">(P)</span>
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'thumb' && visitor.feedback_score === 1 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/dislike.png")} title="Ratings of Product"/> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'thumb' && visitor.feedback_score === 5 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/like.png")} title="Ratings of Product"/> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score === 1 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product"/> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score === 2 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /> </> : (null)}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score === 3 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /> </> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score === 4 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /></> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score === 5 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/starblue.svg")} title="Ratings of Product" /> </> : null}
                                                                    {!visitor.feedback_score ? <span title="Ratings of Product"> ---</span> : null}
                                                                    <br />
                                                                    <span title="Ratings of Service/Agent">(A)</span>
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'thumb' && visitor.feedback_score_agent === 1 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/dislike.png")} title="Ratings of Service/Agent"/> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'thumb' && visitor.feedback_score_agent === 5 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/like.png")} title="Ratings of Service/Agent"/> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score_agent === 1 ? <img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score_agent === 2 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /> </> : (null)}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score_agent === 3 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /> </> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score_agent === 4 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /></> : null}
                                                                    {(visitor.feedback_received || visitor.feedback_received_agent) && visitor.feedback_type === 'star' && visitor.feedback_score_agent === 5 ? <><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /><img alt="" width={"15px"} src={require("../../../assets/img/icon/staryellow.svg")} title="Ratings of Service/Agent" /> </> : null}
                                                                    {!visitor.feedback_score_agent ? <span title="Ratings of Service/Agent"> ---</span> : null}
                                                                </p></td>
                                                                <td>
                                                                    <p className="small text-screen1 position-relative">{visitor.feedback_comment.length > 25 ? <span onMouseEnter={() => this.setState({ showDescPopup: true, disPlaytext: visitor.feedback_comment, disPlaytextId: visitor.visitorId })} onMouseLeave={() => this.setState({ showDescPopup: false, disPlaytext: "", disPlaytextId: "" })} style={{ cursor: 'pointer' }}>{visitor.feedback_comment.slice(0, 25)}...</span> : visitor.feedback_comment}

                                                                        {this.state.showDescPopup && this.state.disPlaytext && this.state.disPlaytextId === visitor.visitorId ? (<div className="popupInstallLivechat popupLivechat feedbackHistory">
                                                                            <div className="py-5 px-5 feilds-instruction">
                                                                                <p className="card-body-text mb-0 text-break ">{this.state.disPlaytext}</p>
                                                                            </div>
                                                                        </div>) : (null)}
                                                                    </p>
                                                                </td>
                                                                <td><p className="small text-screen1 chtHis text-right"><span className="viewTranscript" onClick={() => this.viewChat(visitor)}>View transcript</span></p></td>
                                                            </tr>
                                                        ))) : (<tr key="loading"><td colSpan="9"><p style={{ textAlign: "center" }} className="small text-screen1">Loading...</p></td></tr>)}
                                            </tbody>
                                        </table>
                                    </div>

                                    <div style={{ display: displayPaging }} className="pagingnation">
                                        <div className="pagingnation-list">
                                            <Pagination
                                                hideDisabled={false}
                                                innerClass="nav"
                                                activeClass="selected"
                                                firstPageText='First'
                                                lastPageText='Last'
                                                disabledClass="disabled"
                                                activePage={this.state.currentPage}
                                                itemsCountPerPage={this.state.limit}
                                                totalItemsCount={this.state.totalRecords}
                                                pageRangeDisplayed={this.state.isMobileDevice ? 3 : 5}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.cinfirmType === "otp" ? (<div className="verification-popup">
                    <div className="cstmpopup">
                        <div className="popup-header">
                            <strong>2 step verification</strong>
                            <span onClick={this.closeVerification} className="close-icon-cstm"></span>
                        </div>
                        <div className="popup-body">
                            <div className="input-control">
                                <label>Enter OTP to authenticate</label>
                                <div className="input-group material-input">
                                    {/* {this.state.downloadStatus === "" ? (<input name="download_otp" id="download_otp" value={this.state.download_otp} onChange={this.handleDownloadOtp} onKeyDown={(e) => this.checkSubmit(e)} type="text" className="cstm-input" placeholder="OTP" />) : (<input name="download_otp" id="download_otp" disabled="disabled" value={this.state.download_otp} type="text" className="cstm-input" placeholder="OTP" />)} */}
                                    {this.state.downloadStatus === "" ? (<TextField className="form-control" id="download_otp" value={this.state.download_otp} label="OTP" variant="outlined" name="download_otp" onChange={this.handleDownloadOtp} onKeyDown={(e) => this.checkSubmit(e)} />) : (<TextField className="form-control" name="download_otp" disabled="disabled" value={this.state.download_otp} label="OTP" variant="outlined" />)}
                                </div>

                                {/* <p className="download-status"><img src={require("../../../assets/img/icon/download.gif")} />Downloading...</p> */}

                                {this.state.downloadStatus === "Downloading..." ? (<p className="download-status"><img src={require("../../../assets/img/icon/download.gif")} />Downloading...</p>) : (null)}
                                {this.state.downloadStatus === "Download successfully" ? (<p style={{ color: "#549246" }} className="download-status">{this.state.downloadStatus}</p>) : (null)}
                                {this.state.otpError !== '' ? (<p className="error" style={{ marginTop: 10, marginBottom: -8 }}><img alt="" src={require("../../../assets/images/danger.png")} /> {this.state.otpError}</p>) : (null)}
                                <div className="text-center"><span onClick={this.checkOTP} className="btn btn-primary cstm-input-resend">Verify</span><span className="btn btn-primary cstm-input-resend" onClick={this.sendOtp}>Re-send</span></div>
                            </div>
                        </div>
                    </div>
                </div>) : (null)}

            </div>

            {/* Search bar start */}

            <div style={{ display: isShowSearchOpt }} className="rightPanelSection chat-his-rightpanel">
                <div className="categoryRightPanel chat-his-name filter-view">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Filter</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.closeSearchOptions}>
                                        <img alt="" src={require("../../../assets/images/livechat/right-panel-crose.svg")} />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel scroll-4 b-space">
                        <div className="rightPanelMain">
                            <div className="chat-his-chat">
                                <div className="container-xxl chat-content px-4">
                                    <div className="forem-rightPanel">
                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Agents</span>
                                        </div>
                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="checkboxRadio">
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-100">
                                                        <InputLabel id="agents-select-outlined-label">Agents</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="agents-select-outlined-label" id="agents" name="agents" onChange={this.setAgent} value={this.state.searchAgent} label="Agents">
                                                            <MenuItem value="all">All</MenuItem>
                                                            {
                                                                this.state.allAgents.map(agent => {
                                                                    let agentName = agent.name.length > 30 ? agent.name.substr(0, 30) + "..." : agent.name;
                                                                    if (this.state.isMobileDevice) {
                                                                        agentName = agent.name.length > 20 ? agent.name.substr(0, 20) + "..." : agent.name;
                                                                    }
                                                                    return <MenuItem key={agent.value} value={agent.value}>{agentName} {agent.label !== '' ? ('(' + agent.label + ')') : null}</MenuItem>
                                                                })
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Country</span>
                                        </div>
                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="checkboxRadio">
                                                <div className="material-select search-select">
                                                    <Autocomplete
                                                        className="material-outlined w-100"
                                                        id="country"
                                                        name="country"
                                                        value={selectedCountry}
                                                        style={{ width: 300 }}
                                                        options={countries}
                                                        onChange={(e, option) => this.setSelectOption(e, option)}
                                                        autoHighlight
                                                        getOptionLabel={(option) => option.name}
                                                        renderOption={(option) => (
                                                            <><span>{this.countryToFlag(option.code)}</span>{option.name}</>
                                                        )}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Country"
                                                                variant="outlined"
                                                                inputProps={{
                                                                    ...params.inputProps,
                                                                    autoComplete: 'country', // disable autocomplete and autofill
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* chatBotData.feedback_type */}
                                        {/* Ratings Starts here */}
                                        {this.state.chatBotData.post_chat_form === true && <><div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Product Rating</span>
                                        </div>

                                            {this.state.chatBotData.feedback_type === 1 && <div className="rightFeilds mt-3 mb20">
                                                <div className="checkboxRadio">
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="1star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating1StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Thumbs Down</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="5star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating5StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Thumbs Up</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}

                                            {this.state.chatBotData.feedback_type === 0 && <div className="rightFeilds mt-3 mb20">
                                                <div className="checkboxRadio">
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="1star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating1StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">1 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="2star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating2StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">2 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="3star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating3StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">3 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="4star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating4StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">4 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="5star" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating5StarProduct} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">5 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                        </>}
                                        {/* **************************** */}
                                        {this.state.chatBotData.post_chat_form === true && <><div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Service/Agent Rating</span>
                                        </div>

                                            {this.state.chatBotData.feedback_type === 1 && <div className="rightFeilds mt-3 mb20">
                                                <div className="checkboxRadio">
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="1starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating1StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Thumbs Down</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="5starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating5StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Thumbs Up</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}

                                            {this.state.chatBotData.feedback_type === 0 && <div className="rightFeilds mt-3 mb20">
                                                <div className="checkboxRadio">
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="1starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating1StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">1 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="2starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating2StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">2 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="3starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating3StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">3 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="4starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating4StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">4 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="5starAgent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.rating5StarAgent} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">5 Star</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>}
                                        </>}

                                        
                                        {/* Ratings ends here */}
                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Chat status</span>
                                        </div>

                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="checkboxRadio">
                                                {/*<div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="ongoing" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.ongoingChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Ongoing Chats</span>
                                                        </label>
                                                    </span>
                                                </div> */}
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="completed" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.completeChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Completed Chats</span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="missed" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.missedChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Missed Chats</span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {this.agent.isInHouseAccount === 1 ? (<><div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Type</span>
                                        </div>

                                            <div className="rightFeilds mt-3 mb20">
                                                <div className="checkboxRadio">
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="paid" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.paidChecked} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Paid</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                    <div className="mb-4">
                                                        <span className="checkBoxDesign">
                                                            <label className="containerCheckBox">
                                                                <input type="checkbox" defaultValue="trial" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.trialChecked} />
                                                                <span className="checkmark" />
                                                                <span className="title clr-grey fnt-15">Trial</span>
                                                            </label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div></>) : (null)}

                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Chat Type</span>
                                        </div>

                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="checkboxRadio">
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="visitor" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.visitorChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Visitor</span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="agent" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.agentChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Agent</span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Chat Source</span>
                                        </div>

                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="checkboxRadio">
                                                {/*<div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="ongoing" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.ongoingChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Ongoing Chats</span>
                                                        </label>
                                                    </span>
                                                </div> */}
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="sourceText" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.sourceTextChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Text</span>
                                                        </label>
                                                    </span>
                                                </div>
                                                <div className="mb-4">
                                                    <span className="checkBoxDesign">
                                                        <label className="containerCheckBox">
                                                            <input type="checkbox" defaultValue="sourceCall" onChange={(e) => this.handleCheckStatus(e)} checked={this.state.sourceCallChecked} />
                                                            <span className="checkmark" />
                                                            <span className="title clr-grey fnt-15">Call</span>
                                                        </label>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="rightFeildsTitle">
                                            <svg xmlns="http://www.w3.org/2000/svg" width={12} height="7.41" viewBox="0 0 12 7.41">
                                                <path id="ic_expand_less_24px" d="M6,0,0,6,1.41,7.41,6,2.83l4.59,4.58L12,6Z" transform="translate(12 7.41) rotate(180)" fill="#64b5f6" />
                                            </svg>
                                            <span>Chat Date</span>
                                        </div>
                                        <div className="rightFeilds mt-3 mb20">
                                            <div className="k-flex from-to-date">

                                                <TextField className="mr-5"
                                                    id="from_date"
                                                    name="from_date"
                                                    variant="outlined"
                                                    label="From"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="date"
                                                    defaultValue={this.state.fromDateValue}
                                                    onChange={this.handleFromDatePicker}
                                                    InputProps={{ inputProps: { /* min: this.state.todayDate, */ max: this.state.todayDate } }}
                                                />

                                                <TextField
                                                    id="to_date"
                                                    name="to_date"
                                                    variant="outlined"
                                                    label="To"
                                                    InputLabelProps={{ shrink: true }}
                                                    type="date"
                                                    defaultValue={this.state.toDateValue}
                                                    onChange={this.handleToDatePicker}
                                                    InputProps={{ inputProps: { /* min: this.state.todayDate, */ max: this.state.todayDate } }}
                                                />


                                                {/* <div className=">
                                                    <label>From</label>date-from-inpto mr-5px"
                                                    <DatePicker onChange={this.handleFromDatePicker} value={this.state.fromDateValue} selected={this.state.startDate} maxDate={new Date()} dateFormat="dd/MM/yyyy" />
                                                </div>
                                                <div className="date-from-inpto ml-auto datePic_transform">
                                                    <label>To </label>
                                                    <DatePicker onChange={this.handleToDatePicker} value={this.state.toDateValue} selected={this.state.endDate} maxDate={new Date()} dateFormat="dd/MM/yyyy" />
                                                    <p className="err" style={{ color: 'red', fontSize: 13 }}>{this.state.dateError}</p>
                                                </div> */}

                                            </div>
                                            <p className="err" style={{ color: 'red', fontSize: 13 }}>{this.state.dateError}</p>
                                        </div>

                                        <div className="rightFeilds mt-3 mb20">
                                            <button onClick={this.applyFilter} className="btnBlueSettings" type="button" name="btnApply" >Apply</button>
                                            <button onClick={this.clearFilter} className="btnWhiteSettings" type="button" name="btnClear" >Clear</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Search bar end */}

            <Chat visitor={this.state.selectedVisitor} closeChat={this.handleCloseChat} widgetStatus={this.state.widgetStatus} showDummyData={this.state.showDummyData} />
        </>);
    }
}

export default React.memo(History);