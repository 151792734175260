import React from 'react';

class SettingView extends React.Component {
    constructor(props) {
        super();
        this.state = { display:'desktop'}
    }

    changeDisplay = (display) => {
        this.setState({
            display:display
        })
    }
    render () {
        return (
            <div className="bg-white">{console.log(this.props.values)}
            <div className="chatforms-rightPanel-Phone w-100P position-relative">
                <div className="chat-form-widget bg-black px-5 py-4">
                    <div className="k-flex align-items-center">
                        <h4 className="heding-text white-text">Preview</h4>
                        
                    </div>
                </div>
                <div className="mixListingPreview">
                    <ul>
                        <li className={this.state.display==='desktop' ? 'actives' : ''} onClick={() => this.changeDisplay('desktop')}><span className="device desktop-device"><img alt="" src="https://chatbot.appypie.com/dist/img/public/img/desktop-monitor.svg" /></span></li>
                        <li className={this.state.display==='mobile' ? 'actives' : ''} onClick={() => this.changeDisplay('mobile')}><span className="device phone-device"><img alt="" src="https://chatbot.appypie.com/dist/img/public/img/smartphone.svg" /></span></li>
                    </ul>
                </div>

                <div className="k-flex align-items-flexend">
                    <div className="screen position-relative screen-less" style={{display:this.state.display==='desktop' ? 'block' : 'none'}}>
                        <div className="screen-header px-5 py-3" style={{background:this.props.values.headerbgColor, color:'black'}}>
                            <div className="k-flex align-items-center">
                                <div className="screenBackArroe">
                                    <svg focusable="false" aria-hidden="true">
                                        <g fill="none">
                                            <g fill="#FFF">
                                                <polygon transform="translate(-40 -29)translate(47.071068 36.071068)rotate(-315)translate(-47.071068 -36.071068)" points="44.3 38.8 44.3 31.1 42.1 31.1 42.1 40 42.1 41.1 52.1 41.1 52.1 38.8" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="screenBackArroe">
                                    <p style={{color:this.props.values.headerTextColor}}>{this.props.values.headerText}</p>
                                </div>
                                <div className="screenRightNav k-flex align-items-center ml-auto">
                                    <div className="screenMenuArrow mr-3">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
                                            <g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)">
                                                <rect id="Rectangle_1688" data-name="Rectangle 1688" width="4" height="4" transform="translate(1378.075 219.72)" fill="#fff"/>
                                                <rect id="Rectangle_1689" data-name="Rectangle 1689" width="4" height="4" transform="translate(1378.075 226.72)" fill="#fff"/>
                                                <rect id="Rectangle_1690" data-name="Rectangle 1690" width="4" height="4" transform="translate(1378.075 233.72)" fill="#fff"/>
                                            </g>
                                        </svg>
                                    </div>
                                    <div className="screeCloseArrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243">
                                            <g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)">
                                                <g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#fff" stroke="#fff" strokeWidth="1">
                                                <circle cx="9.622" cy="9.622" r="9.622" stroke="none"/>
                                                <circle cx="9.622" cy="9.622" r="9.122" fill="none"/>
                                                </g>
                                                <path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#1c223a"/>
                                            </g>
                                        </svg>
                                    </div>
                                </div>   
                            </div>
                        </div>
                        <div className="screen-body" style={{background:this.props.values.showGradient ? `linear-gradient(${this.props.values.chatbgfromColor}, ${this.props.values.chatbgtoColor})` : this.props.values.chatbgColor}}>
                            <div className="body-chat">
                                <div className="recived k-flex align-items-flex-end pt-3">
                                    <span style={{ marginLeft:10,marginRight:10}}><img alt="" src={this.props.values.brand_logo} style={{width: 27}} /></span>
                                    <div className="message-box">
                                        <div className="message message-line" style={{background:this.props.values.agentbgColor}}>
                                                <span className="chat-Line" style={{ width: '150px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '100px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '70px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                    <div className="message-box">
                                        <div className="message message-line" style={{background:this.props.values.userbgColor}}>
                                                <span className="chat-Line" style={{ width: '150px',background:this.props.values.usertextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '100px',background:this.props.values.usertextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '70px',background:this.props.values.usertextColor }}>&nbsp;</span>
                                        </div>
                                    </div>
                                    <span className="user"><img alt="" src={require('./../../../assets/images/avtare-user.svg')} /> </span>
                                </div>
                                <div className="recived k-flex align-items-flex-end pt-3">
                                <span style={{ marginLeft:10,marginRight:10}}><img alt="" src={this.props.values.brand_logo} style={{width: 27}} /></span>
                                    <div className="message-box">
                                        <div className="message message-line" style={{background:this.props.values.agentbgColor}}>
                                                <span className="chat-Line" style={{ width: '150px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '100px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '70px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                    <div className="message-box">
                                        <div className="message message-line" style={{background:this.props.values.userbgColor}}>
                                                <span className="chat-Line" style={{ width: '150px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '100px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                                <span className="chat-Line" style={{ width: '70px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                        </div>
                                    </div>
                                    <span className="user"><img alt="" src={require('./../../../assets/images/avtare-user.svg')} /> </span>
                                </div>
                            </div>
                        </div>
                        <div className="screen-footer">
                            <div className="scr-footer-1 k-flex align-items-center py-2 px-3 border-top pb-4 radius-bottom"><input type="text" placeholder={this.props.values.typingAreaTextPlaceholder} /><span className="send-btn" style={{background: this.props.values.sendButtonColor}}><img alt="" src="/static/media/send2.926141e2.svg" /></span></div>
                            {this.props.values.white_label_widget === true && this.props.values.showAdvancedSettings === true ? (null) : (<div className="powerby">
                            <p>
                                <span className="powerdBySnapyIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                    <g data-name="Layer 2">
                                    <g data-name="Layer 1">
                                        <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                        <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                    </g>
                                    </g>
                                </svg>
                                </span>
                                Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{width: '22%', position: 'relative', top: '-2px'}} />
                            </p>
                            </div>)}
                        </div>
                    </div>
                    <div className={this.props.values.avatarActive?"widgets ml-4 avtarView" :"widgets ml-4 "} id='widAvatar' style={{ background: this.props.values.widgetbgColor,display:this.state.display==='desktop' ? 'block' : 'none' }} >
                        <img alt="" src={this.props.values.avatarActive ? this.props.values.AvtarImg.fbwtimage:this.props.values.widgetImage.fbwtimage} />
                    </div>
                </div>

            <div className="k-flex align-items-flexend">
            <div className="mobileScreeNew screen-less-Mobile" style={{display:this.state.display==='mobile' ? 'block' : 'none'}}>
                <div className="demo-device-phone-speakers k-flex align-items-center px-4">
                    <div className="circle1 white-text"><small>10:27</small></div>
                    <div className="circle2 ml-auto"><img alt="" src="https://chatbot.appypie.com/dist/img/public/img/mobiletopIcon.png" /></div>
                </div>

                <div className="screen position-relative">
                    <div className="screen-header px-5 py-3" style={{background: this.props.values.headerbgColor}}>
                        <div className="k-flex align-items-center">
                            <div className="screenBackArroe">
                                <svg focusable="false" aria-hidden="true">
                                    <g fill="none">
                                        <g fill="#FFF">
                                            <polygon transform="translate(-40 -29)translate(47.071068 36.071068)rotate(-315)translate(-47.071068 -36.071068)" points="44.3 38.8 44.3 31.1 42.1 31.1 42.1 40 42.1 41.1 52.1 41.1 52.1 38.8" />
                                        </g>
                                    </g>
                                </svg>
                            </div>
                            <div className="screenBackArroe" style={{color:this.props.values.headerTextColor}}>
                                    <p>{this.props.values.headerText}</p>
                            </div>
                            <div className="screenRightNav k-flex align-items-center ml-auto">
                                <div className="screenMenuArrow mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
                                            <g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)">
                                                <rect id="Rectangle_1688" data-name="Rectangle 1688" width="4" height="4" transform="translate(1378.075 219.72)" fill="#fff"/>
                                                <rect id="Rectangle_1689" data-name="Rectangle 1689" width="4" height="4" transform="translate(1378.075 226.72)" fill="#fff"/>
                                                <rect id="Rectangle_1690" data-name="Rectangle 1690" width="4" height="4" transform="translate(1378.075 233.72)" fill="#fff"/>
                                            </g>
                                        </svg>
                                </div>
                                <div className="screeCloseArrow">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243">
                                            <g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)">
                                                <g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#fff" stroke="#fff" strokeWidth="1">
                                                <circle cx="9.622" cy="9.622" r="9.622" stroke="none"/>
                                                <circle cx="9.622" cy="9.622" r="9.122" fill="none"/>
                                                </g>
                                                <path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#1c223a"/>
                                            </g>
                                        </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="screen-body" style={{background:this.props.values.showGradient ? `linear-gradient(${this.props.values.chatbgfromColor}, ${this.props.values.chatbgtoColor})` : this.props.values.chatbgColor}}>
                    <div className="body-chat">
                            <div className="recived k-flex align-items-flex-end pt-3">
                            <span style={{ marginLeft:10,marginRight:10}}><img alt="" src={this.props.values.brand_logo} style={{width: 27}} /></span>
                                <div className="message-box">
                                    <div className="message message-line" style={{background:this.props.values.agentbgColor}}>
                                            <span className="chat-Line" style={{ width: '150px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '100px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '70px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                <div className="message-box">
                                    <div className="message message-line" style={{background:this.props.values.userbgColor}}>
                                            <span className="chat-Line" style={{ width: '150px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '100px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '70px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                    </div>
                                </div>
                                <span className="user"><img alt="" src={require('./../../../assets/images/avtare-user.svg')} /> </span>
                            </div>
                            <div className="recived k-flex align-items-flex-end pt-3">
                            <span style={{ marginLeft:10,marginRight:10}}><img alt="" src={this.props.values.brand_logo} style={{width: 27}} /></span>
                                <div className="message-box">
                                    <div className="message message-line" style={{background:this.props.values.agentbgColor}}>
                                            <span className="chat-Line" style={{ width: '150px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '100px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '70px', background:this.props.values.agentTextColor }}>&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                <div className="message-box">
                                    <div className="message message-line" style={{background:this.props.values.userbgColor}}>
                                            <span className="chat-Line" style={{ width: '150px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '100px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                            <span className="chat-Line" style={{ width: '70px', background:this.props.values.usertextColor }}>&nbsp;</span>
                                    </div>
                                </div>
                                <span className="user"><img alt="" src={require('./../../../assets/images/avtare-user.svg')} /> </span>
                            </div>
                        </div>
                    </div>
                    <div className="screen-footer">
                        <div className="scr-footer-1 k-flex align-items-center py-2 px-3 border-top"><input type="text" placeholder={this.props.values.typingAreaTextPlaceholder} /><span className="send-btn" style={{background: this.props.values.sendButtonColor}}><img alt="" src="/static/media/send2.926141e2.svg" /></span></div>
                        {this.props.values.white_label_widget === true && this.props.values.showAdvancedSettings === true ? (null) : (<div className="powerby">
                        <p>
                            <span className="powerdBySnapyIcon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                <g data-name="Layer 2">
                                <g data-name="Layer 1">
                                    <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                    <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                </g>
                                </g>
                            </svg>
                            </span>
                            Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{width: '22%', position: 'relative', top: '-2px'}} />
                        </p>
                        </div>)}
                    </div>
                </div>

                <div className="demo-device-chrome-phone1 home-button1" />
                    
            </div>
            <div className={this.props.values.avatarActive?"widgets ml-4 avtarView" :"widgets ml-4 "} style={{ background: this.props.values.widgetbgColor,display:this.state.display==='mobile' ? 'block' : 'none' }} >
                    <img alt="" src={this.props.values.avatarActive ? this.props.values.AvtarImg.fbwtimage:this.props.values.widgetImage.fbwtimage} />
            </div> 
            </div>

                                        
            </div>
        </div>
        )
    }


}

export default SettingView;