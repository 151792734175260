import React from 'react';
import { Route } from 'react-router-dom';
import DeactivatedNavigation from "./sidebarNavigation/deactivatedNav";

const PrivateRoute = ({component: Component, ...rest}) => {
    return (
        <>
        <Route {...rest} render={props => (
            localStorage.getItem('agent') ?
                (<><DeactivatedNavigation /><Component {...props} /></>)
            : (window.location.href="/")
        )} />
        </>
    );
};

export default PrivateRoute;
