import React from 'react';
import { toast } from 'react-toastify';
import LeftPanel from "./views/LeftPanel";
import ApexCharts from "react-apexcharts";
import moment from 'moment-timezone';
// import db from '../../config/firebase';
import { MAIL_FUNCTION_URL, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, FUNCTION_API_KEY, GATEWAY_URL } from '../../config/siteConfig';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { getOwnerDetails, getAllAwsAgents, checkWidgetStatus } from "../../services/service";
import IndividualAgent from "./individualAgent";
import dummyData from '../../localization/dummy-data.json';
import { NotificationContainer, NotificationManager } from "react-notifications";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../firebase';
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';

const conf_chart = { type: 'bar', height: 350, stacked: false, toolbar: { show: false }, zoom: { enabled: false } };
const conf_responsive = [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }];
const conf_plot_options = { bar: { borderRadius: 8, horizontal: false } };
const conf_legend = { position: 'bottom', offsetX: -10, offsetY: 0 };
const conf_fill = { opacity: 1, colors: ['#77c66d'] };
const DATE_FORMATES = { miliseconds: "x", hour: 'HH A', day: 'DD MMM', hours_start: "ddd, MMM DD YYYY hh:00 A", hours_end: "hh:00 A", day_start: "ddd, MMM DD YYYY" };
class Agentsreport extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.userRole = localStorage.getItem('userRole') ? localStorage.getItem('userRole') : 'Agent';
        this.state = {
            loader: true,
            currentchats: [],
            agentList: [],
            agentListArray: [],
            domain_list: [],
            date_range: "today",
            chart_base: "hourly",
            domain: "all",
            start_date: "",
            end_date: "",
            fill_start_date: addDays(new Date(), -7),
            fill_end_date: new Date(),
            numberOfDays: 0,
            showDateRange: false,
            applyCustom: false,
            agentId: "online",
            sortedAgent: [],
            agentActivity: {},
            payment_status: true,
            onlineLoggedInData: [],
            tmpAgentOnlineData: {},
            totalAgentsOnline: {},
            selectedAgentOnline: 0,
            averageAllTotalOnline: 0,
            avgAgentResTime: 0,
            avgAgentChatDuration: 0,
            avgAllAgentResTime: 0,
            avgAllAgentChatDuration: 0,
            onlineAgentsColumns: [],
            showActiBreakGraph: false,
            sort_by: "completed",
            sort_order: "DESC",
            series: [{
                name: 'Agents Online',
                color: '#77c66d',
                data: []
            }],
            options: {
                chart: conf_chart,
                responsive: conf_responsive,
                plotOptions: conf_plot_options,
                xaxis: {
                    type: '',
                    categories: []
                },
                legend: conf_legend,
                fill: conf_fill,
                colors: ['#77c66d'],
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ['#000', '#000']
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: this.agentsOnlineToolTip
                },
                title: {
                    align: 'left'
                },
            },
            onlineSeries: [{
                data: []
            }],
            onlineOptions: {
                chart: {
                    height: "90%",
                    width: '100%',
                    type: 'rangeBar',
                    offsetX: 0,
                    offsetY: 0,
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '100px',
                        rangeBarOverlap: false,
                        /* borderRadius: 0,
                        columnWidth: '100%',
                        barHeight: '100%',
                        distributed: false,
                        rangeBarOverlap: true, */
                    }
                },
                legend: { show: false },
                yaxis: {},
                xaxis: {
                    type: 'datetime',
                    position: "top",
                    labels: {
                        datetimeFormatter: {
                            year: 'yyyy',
                            month: 'MMM \'yy',
                            day: 'dd MMM',
                            hour: 'HH:mm'
                        },
                        formatter: this.breakdownXaxis
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: this.breakdownToolTip
                },
                grid: {
                    show: true,
                    borderColor: '#90A4AE',
                    xaxis: {
                        lines: {
                            show: true
                        }
                    }
                },
            },
            showDummyData: false
        }
    }

    getDomainsList = () => {
        firebaseServices.db.collection('reports').doc('widget').collection('installation').doc(this.agent.ownerId).collection('domains').get().then((domains) => {
            if (!domains.empty) {
                let allDomains = [];
                domains.forEach((domain) => {
                    allDomains.push(domain.data().domain);
                })
                this.setState({
                    domain_list: allDomains
                });
            }
        });
    }

    getAgentsList = async () => {
        let ownerDetail = await getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
        // let ownerDetail = await getOwnerAfterLogin();
        let ownerDetailsData = {}
        if (ownerDetail.status === 200) {
            ownerDetailsData = ownerDetail.data;
        }
        let allAgentss = await getAllAwsAgents();
        var agents = [];
        var owner = [];
        var i = 0;
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where("deletetime", "==", 0).get().then((agentsData) => {
            if (!agentsData.empty) {
                agentsData.forEach((doc) => {
                    if (doc.data().roleType === 'Owner') {
                        let ownerData = doc.data();
                        if (ownerDetail.status === 200) {
                            ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                            ownerData.last_name = ownerDetailsData.last_name ? ownerDetailsData.last_name : '';
                            ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                            ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                            ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                            ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                            ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                            ownerData.alias = ownerDetailsData.alias ? ownerDetailsData.alias : '';
                            ownerData.profile_pic = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail : ''
                        }
                        owner[0] = ownerData;
                        owner[0]['id'] = doc.id;
                    } else {
                        let agentData = doc.data();
                        delete agentData.alias;
                        agents[i] = agentData;
                        agents[i]['id'] = doc.id;
                        i++;
                    }
                });
            }
            let merged = [];
            for (let j = 0; j < allAgentss.length; j++) {
                merged.push({
                    ...allAgentss[j],
                    ...(agents.find((itmInner) => itmInner.id == allAgentss[j].preferred_username))
                });
            }
            merged.push(owner[0]);
            let agentsObj = {};
            let sortedAgent = [];
            let agentListArray = [];
            merged.forEach(agtData => {
                if (agtData.status && agtData.status === 1) {
                    let fname = (agtData.first_name).trim();
                    fname = (fname !== "") ? fname[0].toUpperCase() + fname.slice(1) : "";
                    let lname = (agtData.last_name).trim();
                    lname = (lname !== "") ? lname[0].toUpperCase() + lname.slice(1) : "";
                    agtData.first_name = fname;
                    agtData.last_name = lname;
                    if (fname && lname && lname !== "NA") {
                        agtData.name = fname + " " + lname;
                    } else {
                        agtData.name = fname;
                    }
                    sortedAgent.push(agtData.id);
                    agentListArray.push(agtData);
                    agentsObj[agtData.id] = agtData;
                }
            });


            /* Sort agents by name */
            /* agentListArray.sort((a, b) => {
                return a.name.localeCompare(b.name);
            }); */

            this.setState({
                agentList: agentsObj,
                sortedAgent: sortedAgent,
                agentListArray: agentListArray
            }, () => {
                this.getchats();
                this.getOnlineLogedIn();
            });
        });
    }

    secondsToHms = (d) => {
        d = Number(d);
        if (d === 0) {
            return "0s";
        }

        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + "h " : "";
        var mDisplay = m > 0 ? m + "m " : "";
        var sDisplay = s > 0 ? s + "s " : "";
        return hDisplay + mDisplay + sDisplay;
    }

    showTooltip = (e, agtId, column) => {
        if (!e) { e = window.event; }
        let agentsOD = this.state.tmpAgentOnlineData;
        let totalOnline = this.secondsToHms(parseInt(agentsOD[agtId][column.index]));
        let heading;
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.chart_base === "hourly") {
                let stTime111 = parseInt(column.date);
                let tmpDate = stTime111 + 3600000; /// Add 1 Hour
                heading = moment(stTime111, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_start) + "-" + moment(tmpDate, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_end);
            } else {
                heading = moment(parseInt(column.start)).format(DATE_FORMATES.day_start);
            }
        } else {
            if (this.state.chart_base === "hourly") {
                let stTime111 = parseInt(column.date);
                let tmpDate = new Date(stTime111);
                tmpDate.setHours(column.end);
                heading = moment(stTime111).format(DATE_FORMATES.hours_start) + "-" + moment(tmpDate.getTime()).format(DATE_FORMATES.hours_end);
            } else {
                heading = moment(parseInt(column.start)).format(DATE_FORMATES.day_start);
            }
        }
        var str = "<h5>" + heading + "</h5>";
        let bcolor = "#77c66d";
        str += "<p><span>Online</span>: <span style= \"background-color:" + bcolor + "\" >" + totalOnline + "</span></p>";

        try {
            document.getElementById("tooltipOnlineAgts").innerHTML = str;
            let left = e.clientX;
            let top = e.clientY;
            left = left - 130;
            top = top - 90;
            let div = document.getElementById("tooltipOnlineAgts");
            div.style.left = left + "px";
            div.style.top = top + "px";
            div.style.visibility = "visible";
            return false;
        } catch (err) { }
    }

    hideTooltip = () => {
        try {
            let div = document.getElementById("tooltipOnlineAgts");
            div.style.visibility = "hidden";
            return false;
        } catch (err) { }
    }

    agentsOnlineToolTip = (e) => {
        //let seriesIndex = e.seriesIndex;
        let dataPointIndex = e.dataPointIndex;
        let w = e.w ? e.w : {};
        let config = w.config ? w.config : {};
        let series = config.series ? config.series : {};
        let xaxis = config.xaxis ? config.xaxis : {};
        let categories = xaxis.categories ? xaxis.categories : [];
        let xVal = categories[dataPointIndex];
        let heading;
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            let fDate = new Date(xVal);
            if (this.state.chart_base === "hourly") {
                let fDate2 = xVal + 3600000; /// Add 1 Hour
                heading = moment(xVal, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        } else {
            let fDate = new Date(xVal);
            if (this.state.chart_base === "hourly") {
                let fDate2 = new Date(fDate);
                fDate2.setHours(fDate2.getHours() + 1);
                heading = moment(fDate).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
            var str = "<h5>" + heading + "</h5>";
            for (var i = 0; i < series.length; i++) {
                str += "<p><span>" + series[i].name + "</span>: <span style= \"background-color:" + series[i].color + "\" >" + series[i].data[dataPointIndex] + "</span></p>";
            }
        }
        var str = "<h5>" + heading + "</h5>";
        for (var i = 0; i < series.length; i++) {
            str += "<p><span>" + series[i].name + "</span>: <span style= \"background-color:" + series[i].color + "\" >" + series[i].data[dataPointIndex] + "</span></p>";
        }
        return str;
    }

    breakdownToolTip = (e) => {
        //let dataPointIndex = e.dataPointIndex;
        let seriesIndex = e.seriesIndex;
        let y1 = parseInt(e.y1);
        let y2 = parseInt(e.y2);
        let w = e.w ? e.w : {};
        let config = w.config ? w.config : {};
        let series = config.series ? config.series : {};
        let agentsOD = this.state.tmpAgentOnlineData;
        let totalOnline = this.secondsToHms(parseInt(agentsOD[series[seriesIndex].name][y1 + "_" + y2]));
        let heading;
        if (this.state.chart_base === "hourly") {
            heading = moment(y1).format(DATE_FORMATES.hours_start) + "-" + moment(y2).format(DATE_FORMATES.hours_end);
        } else {
            heading = moment(y1).format(DATE_FORMATES.day_start);
        }
        var str = "<h5>" + heading + "</h5>";
        str += "<p><span>Online</span>: <span style= \"background-color:" + series[seriesIndex].color + "\" >" + totalOnline + "</span></p>";
        return str;
    }

    breakdownXaxis = (value) => {
        let fDate2 = new Date(value);
        if (this.state.date_range === "today") {
            return moment(fDate2).format(DATE_FORMATES.hour);
        } else {
            return moment(fDate2).format(DATE_FORMATES.day);
        }
    }

    formatXAxis = (category) => {
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.date_range === "today") {
                return moment(category, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hour);
            } else if (this.state.date_range === "custom") {
                return moment(category, "x").format(DATE_FORMATES.day);
            } else {
                return moment(category, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.day);
            }
        } else {
            if (this.state.date_range === "today") {
                return moment(category).format(DATE_FORMATES.hour);
            } else {
                return moment(category).format(DATE_FORMATES.day);
            }
        }
    }

    sortData = (field) => {
        this.setState({ loader: true });
        let sortOrder = (this.state.sort_order === "DESC") ? "ASC" : "DESC";
        let tmpAgentActivity = this.state.agentActivity;
        let tmpSortedAgents = Object.assign([], this.state.sortedAgent);
        let tmpAgentListObj = this.state.agentList;

        if (field === "name") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].name.localeCompare(tmpAgentListObj[a].name);
                } else {
                    return tmpAgentListObj[a].name.localeCompare(tmpAgentListObj[b].name);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "name", sort_order: sortOrder, loader: false });
        } else if (field === "alias") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].alias.localeCompare(tmpAgentListObj[a].alias);
                } else {
                    return tmpAgentListObj[a].alias.localeCompare(tmpAgentListObj[b].alias);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "alias", sort_order: sortOrder, loader: false });
        } else if (field === "email") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].email.localeCompare(tmpAgentListObj[a].email);
                } else {
                    return tmpAgentListObj[a].email.localeCompare(tmpAgentListObj[b].email);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "email", sort_order: sortOrder, loader: false });
        } else if (field === "satisfaction") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].satisfaction - tmpAgentActivity[a].satisfaction;
                } else {
                    return tmpAgentActivity[a].satisfaction - tmpAgentActivity[b].satisfaction;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "satisfaction", sort_order: sortOrder, loader: false });
        } else if (field === "feedback") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].feedbacks_received - tmpAgentActivity[a].feedbacks_received;
                } else {
                    return tmpAgentActivity[a].feedbacks_received - tmpAgentActivity[b].feedbacks_received;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "feedback", sort_order: sortOrder, loader: false });
        } else if (field === "response") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].responseTime - tmpAgentActivity[a].responseTime;
                } else {
                    return tmpAgentActivity[a].responseTime - tmpAgentActivity[b].responseTime;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "response", sort_order: sortOrder, loader: false });
        } else if (field === "duration") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].duration - tmpAgentActivity[a].duration;
                } else {
                    return tmpAgentActivity[a].duration - tmpAgentActivity[b].duration;
                }
            });

            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });

            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "duration", sort_order: sortOrder, loader: false });
        } else {

            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].complete_chats - tmpAgentActivity[a].complete_chats;
                } else {
                    return tmpAgentActivity[a].complete_chats - tmpAgentActivity[b].complete_chats;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }

            this.setState({ sortedAgent: allSortedAgts, sort_by: "completed", sort_order: sortOrder, loader: false });
        }
    }

    async componentDidMount() {
        let widgetStatus = await checkWidgetStatus();
        this.setState({
            widgetStatus: widgetStatus
        })
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        if (widgetStatus === true) {
            this.getAgentsList();
            this.getDomainsList();
        } else {
            this.loadDummyData();
        }
    }

    loadDummyData = () => {
        let agentDummyData = dummyData.AllAgents;
        let agentsObj = {};
        let sortedAgent = [];
        let agentListArray = [];
        agentDummyData.forEach(agtData => {
            if (agtData.status && agtData.status === 1) {
                let fname = (agtData.first_name).trim();
                fname = (fname !== "") ? fname[0].toUpperCase() + fname.slice(1) : "";
                let lname = (agtData.last_name).trim();
                lname = (lname !== "") ? lname[0].toUpperCase() + lname.slice(1) : "";
                agtData.first_name = fname;
                agtData.last_name = lname;
                if (fname && lname && lname !== "NA") {
                    agtData.name = fname + " " + lname;
                } else {
                    agtData.name = fname;
                }
                sortedAgent.push(agtData.id);
                agentListArray.push(agtData);
                agentsObj[agtData.id] = agtData;
            }
        });
        let tmpResponseData = Object.assign([], dummyData.onlineAgentData);
        let tmpResponseData22 = {};
        tmpResponseData.map(item => {
            if (tmpResponseData22[item.agent_id] === undefined) {
                tmpResponseData22[item.agent_id] = [];
            }
            tmpResponseData22[item.agent_id].push(item);
        });
        // this.setState({ onlineLoggedInData: tmpResponseData22 }, () => {
        //     this.loadOnlineAgents();
        // });
        this.setState({
            agentList: agentsObj,
            sortedAgent: sortedAgent,
            agentListArray: agentListArray,
            currentchats: dummyData.chatData,
            onlineLoggedInData: tmpResponseData22,
            showDummyData: true
            // loader: false 
        }, () => {
            this.loadChats();
            this.loadOnlineAgents();
        })
    }

    goToAgent = (agentID) => {
        console.log(agentID);
        let avgTotalOnline = 0;
        let selectedAgentOnline = 0;
        let totalAgentsOnline = this.state.totalAgentsOnline;
        console.log(totalAgentsOnline);
        let totalAgentCount = Object.keys(totalAgentsOnline).length;
        if (totalAgentCount > 0) {
            selectedAgentOnline = (agentID && totalAgentsOnline.hasOwnProperty(agentID)) ? totalAgentsOnline[agentID] : 0;
            for (let agentid in totalAgentsOnline) {
                if (totalAgentsOnline.hasOwnProperty(agentid)) {
                    avgTotalOnline += totalAgentsOnline[agentid];
                }
            }
            avgTotalOnline = Math.floor(avgTotalOnline / totalAgentCount);
        }

        let tmpResTime = 0;
        let tmpChatDuration = 0;
        let avgTmpResTime = 0;
        let avgTmpChatDuration = 0;
        let agentActivity = this.state.agentActivity;
        let actLength = Object.keys(agentActivity).length;
        if (actLength > 0 && agentID) {
            if (agentActivity.hasOwnProperty(agentID)) {
                tmpResTime = agentActivity[agentID].responseTime;
                tmpChatDuration = agentActivity[agentID].duration;
            }
            let t1 = 0;
            let t2 = 0;
            let t3 = 0;
            for (let agtiddd in agentActivity) {
                let tmpactD = agentActivity[agtiddd];
                t1 += tmpactD.responseTime;
                t2 += tmpactD.duration;
                t3 += tmpactD.complete_chats;
            }
            avgTmpResTime = Math.floor(t1 / t3);
            avgTmpChatDuration = Math.floor(t2 / t3);
        }
        this.setState({ agentId: agentID, selectedAgentOnline: selectedAgentOnline, averageAllTotalOnline: avgTotalOnline, avgAgentResTime: tmpResTime, avgAgentChatDuration: tmpChatDuration, avgAllAgentResTime: avgTmpResTime, avgAllAgentChatDuration: avgTmpChatDuration });
    }

    changeFilterHandle = (e) => {
        if (this.state.widgetStatus === false && this.state.showDummyData === true && e.target.name !== 'agentId') {
            NotificationManager.error("", 'Filter will not work for sample report', 5000);
            return;
        }
        if (e.target.name === "date_range" && e.target.value === "custom") {
            this.setState({ [e.target.name]: e.target.value, chart_base: "daily", showDateRange: true, applyCustom: false });
            return false;
        } else {
            this.setState({ showDateRange: false, applyCustom: false });
        }
        var chartBaseVal = "";
        if (e.target.name === "date_range" && (e.target.value === "14_days" || e.target.value === "7_days")) {
            chartBaseVal = "daily";
        } else if (e.target.name === "date_range" && e.target.value === "today") {
            chartBaseVal = "hourly";
        } else if (e.target.name === "date_range" && e.target.value === "30_days") {
            chartBaseVal = "weekly";
        } else if (e.target.name === "domain") {
            chartBaseVal = "domain";
        } else if (e.target.name === "agentId") {
            chartBaseVal = "agentId";
        }

        if (chartBaseVal !== "" && chartBaseVal !== "domain" && chartBaseVal !== "agentId") {
            this.setState({ [e.target.name]: e.target.value, chart_base: chartBaseVal }, () => {
                this.getchats();
                this.getOnlineLogedIn();
            });
        } else if (chartBaseVal !== "" && chartBaseVal === "domain") {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.getchats();
            });
        } else if (chartBaseVal !== "" && chartBaseVal === "agentId") {
            let agentIDD = e.target.value;
            if (agentIDD === "online") {
                this.setState({ agentId: agentIDD });
            } else {
                let avgTotalOnline = 0;
                let selectedAgentOnline = 0;
                let totalAgentsOnline = this.state.totalAgentsOnline;
                let totalAgentCount = Object.keys(totalAgentsOnline).length;
                if (totalAgentCount > 0) {
                    selectedAgentOnline = (agentIDD && totalAgentsOnline.hasOwnProperty(agentIDD)) ? totalAgentsOnline[agentIDD] : 0;
                    for (let agentid in totalAgentsOnline) {
                        if (totalAgentsOnline.hasOwnProperty(agentid)) {
                            avgTotalOnline += totalAgentsOnline[agentid];
                        }
                    }
                    avgTotalOnline = Math.floor(avgTotalOnline / totalAgentCount);
                }

                let tmpResTime = 0;
                let tmpChatDuration = 0;
                let avgTmpResTime = 0;
                let avgTmpChatDuration = 0;
                let agentActivity = this.state.agentActivity;
                let actLength = Object.keys(agentActivity).length;
                if (actLength > 0 && agentIDD) {
                    if (agentActivity.hasOwnProperty(agentIDD)) {
                        tmpResTime = agentActivity[agentIDD].responseTime;
                        tmpChatDuration = agentActivity[agentIDD].duration;
                    }

                    let t1 = 0;
                    let t2 = 0;
                    let t3 = 0;
                    for (let agtiddd in agentActivity) {
                        let tmpactD = agentActivity[agtiddd];
                        t1 += tmpactD.responseTime;
                        t2 += tmpactD.duration;
                        t3 += tmpactD.complete_chats;
                    }

                    avgTmpResTime = Math.floor(t1 / t3);
                    avgTmpChatDuration = Math.floor(t2 / t3);
                }

                this.setState({ agentId: agentIDD, selectedAgentOnline: selectedAgentOnline, averageAllTotalOnline: avgTotalOnline, avgAgentResTime: tmpResTime, avgAgentChatDuration: tmpChatDuration, avgAllAgentResTime: avgTmpResTime, avgAllAgentChatDuration: avgTmpChatDuration });
            }
            /* this.setState({ agentId: e.target.value }, () => {
                this.getOnlineLogedIn();
            }); */
        } else {
            this.setState({ [e.target.name]: e.target.value, loader: true }, () => {
                if (this.agent.org_timezone && this.agent.org_timezone !== "") {
                    this.loadOnlineAgentsByZone();
                } else {
                    this.loadOnlineAgents();
                }
            });
        }
    }

    onDatesSelect = (ranges) => {
        this.setState({ fill_start_date: ranges.selection.startDate, fill_end_date: ranges.selection.endDate });
    }

    applyDateRange = () => {
        let tmpstDate = this.state.fill_start_date;
        let stDate = moment(tmpstDate).format(DATE_FORMATES.miliseconds);
        let tmpedDate = this.state.fill_end_date;
        tmpedDate.setHours(23, 59, 0, 0);
        let edDate = moment(tmpedDate).format(DATE_FORMATES.miliseconds);

        let diffDate = edDate - stDate;
        diffDate = Math.ceil(diffDate / (1000 * 3600 * 24));
        if (diffDate > 92) {
            toast.error("You can select date within 3 months only!");
            return false;
        } else {
            this.setState({ showDateRange: false, start_date: stDate, end_date: edDate, numberOfDays: diffDate, applyCustom: true }, () => {
                this.getchats();
                this.getOnlineLogedIn();
            });
        }
    }

    cancelDateRange = () => {
        this.setState({ showDateRange: false, applyCustom: false });
    }

    showHideDatePicker = () => {
        this.setState({ showDateRange: !this.state.showDateRange, applyCustom: false });
    }

    getOnlineLogedIn = () => {
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        this.setState({ loader: true });
        var body = [`ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&currentDate=${todayDate.getTime()}&org_timezone=${encodeURIComponent(this.agent.org_timezone ? this.agent.org_timezone : "")}`];
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            //fetch(MAIL_FUNCTION_URL + "/analytics/getAgentSessionsWithZone", {
            fetch(GATEWAY_URL + "/getAgentSessionsWithZone", {
                method: "POST",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    this.setState({ onlineLoggedInData: response.data }, () => {
                        this.loadOnlineAgentsByZone();
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        } else {
            ///fetch(MAIL_FUNCTION_URL + "/analytics/getAgentSessions", {
            fetch(GATEWAY_URL + "/getAgentSessions", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    let tmpResponseData = Object.assign([], response.data);
                    let tmpResponseData22 = {};
                    tmpResponseData.map(item => {
                        if (tmpResponseData22[item.agent_id] === undefined) {
                            tmpResponseData22[item.agent_id] = [];
                        }
                        tmpResponseData22[item.agent_id].push(item);
                    });
                    this.setState({ onlineLoggedInData: tmpResponseData22 }, () => {
                        this.loadOnlineAgents();
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        }
    }

    loadOnlineAgents = async () => {
        let onlineLoggedInData = this.state.onlineLoggedInData;
        let onlineOptions = this.state.onlineOptions;
        let onlineSeries = this.state.onlineSeries;
        let agentList = this.state.agentList;
        let allAgentIdes = Object.assign([], this.state.sortedAgent);
        let tmpCategories = [];
        let onlineAgentsColumns = [];

        var options = this.state.options;
        var series = this.state.series;
        this.setState({ series: [], options: {}, onlineSeries: [], onlineOptions: {} });
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let xaxis_categories = [];
        let xaxis_type = "category";  // category, datetime, numeric
        //let tmpLoggedInagents = [];
        let tmpOnlineagents = [];
        //let series_data_loggedIn = [];
        let series_data_online = [];
        let agentsOnlineTime = {};

        if (this.state.chart_base === "weekly") {
            if (this.state.date_range === "custom") {
                let stttDate = parseInt(this.state.start_date);
                let stttEndDate = parseInt(this.state.end_date);
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = new Date(stttDate);
                    tmpdate.setDate(tmpdate.getDate() + i);
                    let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                    let datt = tmpdate.getDate();
                    let month = tmpdate.getMonth() + 1;
                    let year = tmpdate.getFullYear();
                    let totalWeekDays = 6 - dayOfWeek;
                    i = i + totalWeekDays;

                    let tmpdate22 = new Date(stttDate);
                    tmpdate22.setDate(tmpdate22.getDate() + i);
                    let toDate22;
                    let gtTime22 = tmpdate22.getTime();

                    if (gtTime22 > stttEndDate) {
                        tmpdate22 = new Date(stttEndDate);
                        toDate22 = stttEndDate;
                    } else {
                        toDate22 = (gtTime22 + 86399000);
                    }
                    let datt22 = tmpdate22.getDate();
                    let month22 = tmpdate22.getMonth() + 1;
                    let year22 = tmpdate22.getFullYear();
                    let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                    tmpCategories.push({ date: tmpdate.getTime(), toDate: toDate22, index: createIndex });
                }

                /* For Agent Online */
                for (var i = 0; i <= this.state.numberOfDays; i++) {
                    let tmpdate33333 = new Date(stttDate);
                    tmpdate33333.setDate(tmpdate33333.getDate() + i);
                    let tmpdate2 = new Date(tmpdate33333);
                    tmpdate2.setHours(0, 0, 0, 0)
                    let tmpdate3 = new Date(tmpdate33333);
                    tmpdate3.setHours(23, 59, 59, 0);
                    let st1 = tmpdate2.getTime();
                    let st2 = tmpdate3.getTime();
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdate33333.getTime() });
                }
                /* For Agent Online */

            } else {
                let maxDays = 0;
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                    let datt = tmpdate.getDate();
                    let month = tmpdate.getMonth() + 1;
                    let year = tmpdate.getFullYear();
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ date: tmpdate.getTime(), toDate: (tmpdate.getTime() + 86399000), index: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = new Date(today);
                        tmpdate22.setDate(tmpdate22.getDate() - i);
                        let datt22 = tmpdate22.getDate();
                        let month22 = tmpdate22.getMonth() + 1;
                        let year22 = tmpdate22.getFullYear();
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ date: tmpdate22.getTime(), toDate: (tmpdate.getTime() + 86399000), index: createIndex });
                    }
                }
                tmpCategories.reverse();

                /* For Agent Online */
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate33333 = new Date(today);
                    tmpdate33333.setDate(tmpdate33333.getDate() - i);
                    let tmpdate2 = new Date(tmpdate33333);
                    tmpdate2.setHours(0, 0, 0, 0)
                    let tmpdate3 = new Date(tmpdate33333);
                    tmpdate3.setHours(23, 59, 59, 0);
                    let st1 = tmpdate2.getTime();
                    let st2 = tmpdate3.getTime();
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdate33333.getTime() });
                }
                onlineAgentsColumns.reverse();
                /* For Agent Online */
            }

            if (Object.keys(onlineLoggedInData).length > 0) {
                for (let agent_id in onlineLoggedInData) {
                    let tmpAgentData = onlineLoggedInData[agent_id];
                    tmpAgentData.forEach(loginD => {
                        let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                        let online = loginD.online ? parseInt(loginD.online) : 0;
                        let type = loginD.type ? loginD.type : "";
                        let agent_id = loginD.agent_id ? loginD.agent_id : "";

                        let addedon = new Date(parseInt(loginD.addedon));
                        let timeAddedOn = addedon.getTime();
                        tmpCategories.forEach(valObj => {
                            if (timeAddedOn >= valObj.date && timeAddedOn <= valObj.toDate) {
                                let createIndex = valObj.index;
                                /* if (tmpLoggedInagents[createIndex] === undefined) {
                                    tmpLoggedInagents[createIndex] = [];
                                }
                                if(logged_in >= parseInt(loginD.addedon)) {
                                    if(tmpLoggedInagents[createIndex].indexOf(agent_id) === -1) {
                                        tmpLoggedInagents[createIndex].push(agent_id);
                                    }
                                } */
                                if (tmpOnlineagents[createIndex] === undefined) {
                                    tmpOnlineagents[createIndex] = [];
                                }

                                if (type === "online_offline") {

                                    if (online >= parseInt(loginD.addedon)) {
                                        if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                            tmpOnlineagents[createIndex].push(agent_id);
                                        }
                                    }

                                    if (offline) {
                                        let onlineD = new Date(online);
                                        onlineD.setHours(0, 0, 0, 0);
                                        let offlineD = new Date(onlineD);
                                        offlineD.setHours(23, 59, 59, 0);
                                        let dayOnlineD = onlineD.getDate();
                                        let offlineDy = new Date(offline);
                                        let dayOfflineD = offlineDy.getDate();
                                        if (agentsOnlineTime[agent_id] === undefined) {
                                            agentsOnlineTime[agent_id] = {};
                                        }

                                        if ((dayOfflineD - dayOnlineD) >= 1) {
                                            for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                                var workSeconds = 86400;
                                                if (j === dayOnlineD) {
                                                    let onlineD22 = new Date(online);
                                                    let on2Hours = onlineD22.getHours();
                                                    let on2minutes = onlineD22.getMinutes();
                                                    let on2Seconds = onlineD22.getSeconds();
                                                    let hrs = 24 - on2Hours;
                                                    let hrsInMinutes = hrs * 60;
                                                    let minusMinutes = hrsInMinutes - on2minutes;
                                                    let minusMinutesInSec = minusMinutes * 60;
                                                    workSeconds = minusMinutesInSec - on2Seconds;
                                                    let fromTime = new Date(online);
                                                    fromTime.setHours(0, 0, 0, 0);
                                                    let toTime = new Date(online);
                                                    toTime.setHours(23, 59, 59, 0);
                                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                                } else {

                                                    if (j === dayOfflineD) {
                                                        let onlineD22 = new Date(offline);
                                                        let on2Hours = onlineD22.getHours();
                                                        let on2minutes = onlineD22.getMinutes();
                                                        let on2Seconds = onlineD22.getSeconds();
                                                        workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                                    }

                                                    let fromTime = new Date(online);
                                                    fromTime.setDate(j);
                                                    fromTime.setHours(0, 0, 0, 0);
                                                    let toTime = new Date(fromTime);
                                                    toTime.setHours(23, 59, 59, 0);
                                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                                }
                                            }
                                        } else {
                                            let onlineinTime = onlineD.getTime();
                                            let offlineinTime = offlineD.getTime();
                                            let diffMiliseconds = offline - online;
                                            let diffSeconds = 0;
                                            if (diffMiliseconds > 0) {
                                                diffSeconds = Math.floor(diffMiliseconds / 1000);
                                            }
                                            if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                                agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                            }
                                            agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            }


            /* if (onlineLoggedInData.length > 0) {
                onlineLoggedInData.forEach(loginD => {
                    let logged_in = loginD.logged_in ? parseInt(loginD.logged_in) : 0;
                    //let logged_out = loginD.logged_out ? parseInt(loginD.logged_out) : 0;
                    let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                    let online = loginD.online ? parseInt(loginD.online) : 0;
                    let type = loginD.type ? loginD.type : "";
                    let agent_id = loginD.agent_id ? loginD.agent_id : "";

                    let addedon = new Date(parseInt(loginD.addedon));
                    let timeAddedOn = addedon.getTime();
                    tmpCategories.forEach(valObj => {
                        if (timeAddedOn >= valObj.date && timeAddedOn <= valObj.toDate) {

                            let createIndex = valObj.index;

                            //if (tmpLoggedInagents[createIndex] === undefined) {
                                //tmpLoggedInagents[createIndex] = [];
                            ///}

                            ///if(logged_in >= parseInt(loginD.addedon)) {
                                //if(tmpLoggedInagents[createIndex].indexOf(agent_id) === -1) {
                                    //tmpLoggedInagents[createIndex].push(agent_id);
                                //}
                            //} 

                            if (tmpOnlineagents[createIndex] === undefined) {
                                tmpOnlineagents[createIndex] = [];
                            }

                            if (type === "online_offline") {
                                if (online >= parseInt(loginD.addedon)) {
                                    if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                        tmpOnlineagents[createIndex].push(agent_id);
                                    }
                                }


                                if (offline) {
                                    let onlineD = new Date(online);
                                    onlineD.setHours(0, 0, 0, 0);
                                    let offlineD = new Date(onlineD);
                                    offlineD.setHours(23, 59, 59, 0);
                                    let dayOnlineD = onlineD.getDate();
                                    let offlineDy = new Date(offline);
                                    let dayOfflineD = offlineDy.getDate();
                                    if (agentsOnlineTime[agent_id] === undefined) {
                                        agentsOnlineTime[agent_id] = {};
                                    }

                                    if ((dayOfflineD - dayOnlineD) >= 1) {
                                        for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                            var workSeconds = 86400;
                                            if (j === dayOnlineD) {
                                                let onlineD22 = new Date(online);
                                                let on2Hours = onlineD22.getHours();
                                                let on2minutes = onlineD22.getMinutes();
                                                let on2Seconds = onlineD22.getSeconds();
                                                let hrs = 24 - on2Hours;
                                                let hrsInMinutes = hrs * 60;
                                                let minusMinutes = hrsInMinutes - on2minutes;
                                                let minusMinutesInSec = minusMinutes * 60;
                                                workSeconds = minusMinutesInSec - on2Seconds;
                                                let fromTime = new Date(online);
                                                fromTime.setHours(0, 0, 0, 0);
                                                let toTime = new Date(online);
                                                toTime.setHours(23, 59, 59, 0);
                                                if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                }
                                                agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                            } else {

                                                if (j === dayOfflineD) {
                                                    let onlineD22 = new Date(offline);
                                                    let on2Hours = onlineD22.getHours();
                                                    let on2minutes = onlineD22.getMinutes();
                                                    let on2Seconds = onlineD22.getSeconds();
                                                    workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                                }

                                                let fromTime = new Date(online);
                                                fromTime.setDate(j);
                                                fromTime.setHours(0, 0, 0, 0);
                                                let toTime = new Date(fromTime);
                                                toTime.setHours(23, 59, 59, 0);
                                                if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                }
                                                agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                            }
                                        }
                                    } else {
                                        let onlineinTime = onlineD.getTime();
                                        let offlineinTime = offlineD.getTime();
                                        let diffMiliseconds = offline - online;
                                        let diffSeconds = 0;
                                        if (diffMiliseconds > 0) {
                                            diffSeconds = Math.floor(diffMiliseconds / 1000);
                                        }
                                        if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                            agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                        }
                                        agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                                    }
                                }
                            }
                        }
                    });
                });
            } */
        } else if (this.state.chart_base === "daily") {
            if (this.state.date_range === "custom") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = new Date(parseInt(this.state.start_date));
                    tmpdate.setDate(tmpdate.getDate() + i);
                    let dayAddedOn = tmpdate.getDate();
                    let monthAddedOn = tmpdate.getMonth() + 1;
                    let yearAddedOn = tmpdate.getFullYear();
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdate.getTime(), index: createIndex });

                    /* For Agent Online */
                    let tmpdate2 = new Date(tmpdate);
                    tmpdate2.setHours(0, 0, 0, 0)
                    let tmpdate3 = new Date(tmpdate);
                    tmpdate3.setHours(23, 59, 59, 0);
                    let st1 = tmpdate2.getTime();
                    let st2 = tmpdate3.getTime();
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdate.getTime() });
                    /* For Agent Online */
                }
            } else {
                let maxDays = 6;
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayAddedOn = tmpdate.getDate();
                    let monthAddedOn = tmpdate.getMonth() + 1;
                    let yearAddedOn = tmpdate.getFullYear();
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdate.getTime(), index: createIndex });

                    /* For Agent Online */
                    let tmpdate2 = new Date(tmpdate);
                    tmpdate2.setHours(0, 0, 0, 0)
                    let tmpdate3 = new Date(tmpdate);
                    tmpdate3.setHours(23, 59, 59, 0);
                    let st1 = tmpdate2.getTime();
                    let st2 = tmpdate3.getTime();
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdate.getTime() });
                    /* For Agent Online */
                }
                tmpCategories.reverse();
                onlineAgentsColumns.reverse();
            }

            if (Object.keys(onlineLoggedInData).length > 0) {
                for (let agent_id in onlineLoggedInData) {
                    let tmpAgentData = onlineLoggedInData[agent_id];
                    tmpAgentData.forEach(loginD => {
                        let online = loginD.online ? parseInt(loginD.online) : 0;
                        let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                        let type = loginD.type ? loginD.type : "";

                        let addedon = new Date(parseInt(loginD.addedon));
                        let dateAddedOn = addedon.getDate();
                        let monthAddedOn = addedon.getMonth() + 1;
                        let yearAddedOn = addedon.getFullYear();
                        let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                        if (tmpOnlineagents[createIndex] === undefined) {
                            tmpOnlineagents[createIndex] = [];
                        }

                        if (type === "online_offline") {

                            if (online >= parseInt(loginD.addedon)) {
                                if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                    tmpOnlineagents[createIndex].push(agent_id);
                                }
                            }

                            if (offline) {
                                let onlineD = new Date(online);
                                onlineD.setHours(0, 0, 0, 0);
                                let offlineD = new Date(onlineD);
                                offlineD.setHours(23, 59, 59, 0);
                                let dayOnlineD = onlineD.getDate();
                                let offlineDy = new Date(offline);
                                let dayOfflineD = offlineDy.getDate();
                                if (agentsOnlineTime[agent_id] === undefined) {
                                    agentsOnlineTime[agent_id] = {};
                                }

                                if ((dayOfflineD - dayOnlineD) >= 1) {
                                    for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                        var workSeconds = 86400;
                                        if (j === dayOnlineD) {
                                            let onlineD22 = new Date(online);
                                            let on2Hours = onlineD22.getHours();
                                            let on2minutes = onlineD22.getMinutes();
                                            let on2Seconds = onlineD22.getSeconds();
                                            let hrs = 24 - on2Hours;
                                            let hrsInMinutes = hrs * 60;
                                            let minusMinutes = hrsInMinutes - on2minutes;
                                            let minusMinutesInSec = minusMinutes * 60;
                                            workSeconds = minusMinutesInSec - on2Seconds;

                                            let fromTime = new Date(online);
                                            fromTime.setHours(0, 0, 0, 0);
                                            let toTime = new Date(online);
                                            toTime.setHours(23, 59, 59, 0);

                                            if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                            }

                                            agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                        } else {

                                            if (j === dayOfflineD) {
                                                let onlineD22 = new Date(offline);
                                                let on2Hours = onlineD22.getHours();
                                                let on2minutes = onlineD22.getMinutes();
                                                let on2Seconds = onlineD22.getSeconds();
                                                workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                            }

                                            let fromTime = new Date(online);
                                            fromTime.setDate(j);
                                            fromTime.setHours(0, 0, 0, 0);
                                            let toTime = new Date(fromTime);
                                            toTime.setHours(23, 59, 59, 0);
                                            if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                            }
                                            agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                        }
                                    }
                                } else {
                                    let onlineinTime = onlineD.getTime();
                                    let offlineinTime = offlineD.getTime();
                                    let diffMiliseconds = offline - online;
                                    let diffSeconds = 0;
                                    if (diffMiliseconds > 0) {
                                        diffSeconds = Math.floor(diffMiliseconds / 1000);
                                    }
                                    if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                        agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                    }
                                    agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                                }
                            }
                        }
                    });
                }
            }

            /*  if (onlineLoggedInData.length > 0) {
                 onlineLoggedInData.forEach(loginD => {
                     let logged_in = loginD.logged_in ? parseInt(loginD.logged_in) : 0;
                     let online = loginD.online ? parseInt(loginD.online) : 0;
                     let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                     let type = loginD.type ? loginD.type : "";
                     let agent_id = loginD.agent_id ? loginD.agent_id : "";
 
                     let addedon = new Date(parseInt(loginD.addedon));
                     let dateAddedOn = addedon.getDate();
                     let monthAddedOn = addedon.getMonth() + 1;
                     let yearAddedOn = addedon.getFullYear();
                     let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
 
                     if (tmpOnlineagents[createIndex] === undefined) {
                         tmpOnlineagents[createIndex] = [];
                     }
 
                     if (type === "online_offline") {
                         if (online >= parseInt(loginD.addedon)) {
                             if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                 tmpOnlineagents[createIndex].push(agent_id);
                             }
                         }
 
                         if (offline) {
                             let onlineD = new Date(online);
                             onlineD.setHours(0, 0, 0, 0);
                             let offlineD = new Date(onlineD);
                             offlineD.setHours(23, 59, 59, 0);
                             let dayOnlineD = onlineD.getDate();
                             let offlineDy = new Date(offline);
                             let dayOfflineD = offlineDy.getDate();
                             if (agentsOnlineTime[agent_id] === undefined) {
                                 agentsOnlineTime[agent_id] = {};
                             }
 
                             if ((dayOfflineD - dayOnlineD) >= 1) {
                                 for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                     var workSeconds = 86400;
                                     if (j === dayOnlineD) {
                                         let onlineD22 = new Date(online);
                                         let on2Hours = onlineD22.getHours();
                                         let on2minutes = onlineD22.getMinutes();
                                         let on2Seconds = onlineD22.getSeconds();
                                         let hrs = 24 - on2Hours;
                                         let hrsInMinutes = hrs * 60;
                                         let minusMinutes = hrsInMinutes - on2minutes;
                                         let minusMinutesInSec = minusMinutes * 60;
                                         workSeconds = minusMinutesInSec - on2Seconds;
 
                                         let fromTime = new Date(online);
                                         fromTime.setHours(0, 0, 0, 0);
                                         let toTime = new Date(online);
                                         toTime.setHours(23, 59, 59, 0);
 
                                         if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                             agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                         }
 
                                         agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                     } else {
 
                                         if (j === dayOfflineD) {
                                             let onlineD22 = new Date(offline);
                                             let on2Hours = onlineD22.getHours();
                                             let on2minutes = onlineD22.getMinutes();
                                             let on2Seconds = onlineD22.getSeconds();
                                             workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                         }
 
                                         let fromTime = new Date(online);
                                         fromTime.setDate(j);
                                         fromTime.setHours(0, 0, 0, 0);
                                         let toTime = new Date(fromTime);
                                         toTime.setHours(23, 59, 59, 0);
                                         if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                             agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                         }
                                         agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                     }
                                 }
                             } else {
                                 let onlineinTime = onlineD.getTime();
                                 let offlineinTime = offlineD.getTime();
                                 let diffMiliseconds = offline - online;
                                 let diffSeconds = 0;
                                 if (diffMiliseconds > 0) {
                                     diffSeconds = Math.floor(diffMiliseconds / 1000);
                                 }
                                 if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                     agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                 }
                                 agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                             }
                         }
                     }
 
                 });
             } */
        } else if (this.state.chart_base === "hourly") {

            let tmpdate = new Date();
            for (var i = 0; i <= tmpdate.getHours(); i++) {
                let tmpdate2 = new Date();
                tmpdate2.setHours(i);
                let monthAddedOn = tmpdate2.getMonth() + 1;
                let yearAddedOn = tmpdate2.getFullYear();
                let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: tmpdate2.getTime(), index: createIndex });
                /* For Agent Online */
                let j = i + 1;
                onlineAgentsColumns.push({ start: i, end: i + 1, index: i + "_" + j, date: tmpdate2.getTime() });
                /* For Agent Online */
            }

            if (Object.keys(onlineLoggedInData).length > 0) {
                let agentTimeZone = localStorage.getItem("agentTimeZone") ? JSON.parse(localStorage.getItem('agentTimeZone')) : false;
                let serverTime = 0;
                if (agentTimeZone && agentTimeZone.serverTime) {
                    serverTime = parseInt(agentTimeZone.serverTime);
                }

                for (let agent_id in onlineLoggedInData) {
                    let tmpAgentData = onlineLoggedInData[agent_id];
                    let totalRows = tmpAgentData.length;
                    var iooi = 1;
                    tmpAgentData.forEach(loginD => {
                        let online = loginD.online ? parseInt(loginD.online) : 0;
                        let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                        let type = loginD.type ? loginD.type : "";
                        ///let agent_id = loginD.agent_id ? loginD.agent_id : "";
                        if (!offline && iooi === totalRows && serverTime > 0) {
                            offline = serverTime;
                        }
                        if (type === "online_offline") {
                            let addedon = new Date(parseInt(loginD.addedon));
                            let hourAddedOn = addedon.getHours();
                            let monthAddedOn = addedon.getMonth() + 1;
                            let yearAddedOn = addedon.getFullYear();
                            let createIndex = hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                            if (tmpOnlineagents[createIndex] === undefined) {
                                tmpOnlineagents[createIndex] = [];
                            }

                            let onlineD = new Date(online);
                            let houronlineD = onlineD.getHours();
                            let minuteonlineD = onlineD.getMinutes();
                            let offlineD = new Date(offline);
                            let hourofflineD = offlineD.getHours();
                            let minuteofflineD = offlineD.getMinutes();

                            if ((hourofflineD - houronlineD) >= 1) {
                                for (var j = houronlineD; j <= hourofflineD; j++) {
                                    let createIndex2 = j + "_" + monthAddedOn + "_" + yearAddedOn;
                                    if (tmpOnlineagents[createIndex2] === undefined) {
                                        tmpOnlineagents[createIndex2] = [];
                                    }
                                    if (tmpOnlineagents[createIndex2].indexOf(agent_id) === -1) {
                                        tmpOnlineagents[createIndex2].push(agent_id);
                                    }
                                }
                            } else {
                                if (online >= parseInt(loginD.addedon)) {
                                    if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                        tmpOnlineagents[createIndex].push(agent_id);
                                    }
                                }
                            }

                            if (offline) {

                                if (agentsOnlineTime[agent_id] === undefined) {
                                    agentsOnlineTime[agent_id] = {};
                                }

                                if ((hourofflineD - houronlineD) >= 1) {
                                    for (var j = houronlineD; j <= hourofflineD; j++) {
                                        var workSeconds = 3600;
                                        if (j === houronlineD) {
                                            workSeconds = (60 - eval(minuteonlineD)) * 60;
                                        } else if (j === hourofflineD) {
                                            workSeconds = minuteofflineD * 60;
                                        }
                                        let nxtHour = eval(j) + 1;
                                        if (agentsOnlineTime[agent_id][j + "_" + nxtHour] === undefined) {
                                            agentsOnlineTime[agent_id][j + "_" + nxtHour] = [];
                                        }
                                        agentsOnlineTime[agent_id][j + "_" + nxtHour].push(workSeconds);
                                    }
                                } else {
                                    let nxtHour = eval(houronlineD) + 1;
                                    if (agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] === undefined) {
                                        agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] = [];
                                    }
                                    agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour].push(((minuteofflineD - minuteonlineD) * 60));
                                }
                            }

                        }
                        iooi++;
                    });
                }
            }

            /* if (onlineLoggedInData.length > 0) {
                onlineLoggedInData.forEach(loginD => {
                    let online = loginD.online ? parseInt(loginD.online) : 0;
                    let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                    let type = loginD.type ? loginD.type : "";
                    let agent_id = loginD.agent_id ? loginD.agent_id : "";

                    let addedon = new Date(parseInt(loginD.addedon));
                    let hourAddedOn = addedon.getHours();
                    let monthAddedOn = addedon.getMonth() + 1;
                    let yearAddedOn = addedon.getFullYear();
                    let createIndex = hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpOnlineagents[createIndex] === undefined) {
                        tmpOnlineagents[createIndex] = [];
                    }

                    if (type === "online_offline") {
                        if (online >= parseInt(loginD.addedon)) {
                            if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                tmpOnlineagents[createIndex].push(agent_id);
                            }
                        }

                        if (offline) {
                            let onlineD = new Date(online);
                            let houronlineD = onlineD.getHours();
                            let minuteonlineD = onlineD.getMinutes();
                            let offlineD = new Date(offline);
                            let hourofflineD = offlineD.getHours();
                            let minuteofflineD = offlineD.getMinutes();
                            if (agentsOnlineTime[agent_id] === undefined) {
                                agentsOnlineTime[agent_id] = {};
                            }

                            if ((hourofflineD - houronlineD) >= 1) {
                                for (var j = houronlineD; j <= hourofflineD; j++) {
                                    var workSeconds = 3600;
                                    if (j === houronlineD) {
                                        workSeconds = (60 - eval(minuteonlineD)) * 60;
                                    } else if (j === hourofflineD) {
                                        workSeconds = minuteofflineD * 60;
                                    }
                                    let nxtHour = eval(j) + 1;
                                    if (agentsOnlineTime[agent_id][j + "_" + nxtHour] === undefined) {
                                        agentsOnlineTime[agent_id][j + "_" + nxtHour] = [];
                                    }
                                    agentsOnlineTime[agent_id][j + "_" + nxtHour].push(workSeconds);
                                }
                            } else {
                                let nxtHour = eval(houronlineD) + 1;
                                if (agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] === undefined) {
                                    agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] = [];
                                }
                                agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour].push(((minuteofflineD - minuteonlineD) * 60));
                            }
                        }

                    }
                });
            } */
        }

        let tmpAgentOnlineData = {};
        let totalAgentsOnline = {};
        let seriesArray = [];

        if (Object.keys(agentsOnlineTime).length > 0) {
            for (var agentId in agentsOnlineTime) {
                /* Remove agent if find here */
                allAgentIdes.splice(allAgentIdes.indexOf(agentId), 1);
                /* Remove agent if find here */
                let agentOnlineData = agentsOnlineTime[agentId];
                tmpAgentOnlineData[agentId] = {};
                let serAgentObj = {
                    name: agentId,
                    color: "#77c66d"
                };
                let serDataArray = [];
                let grandTotal = 0;
                if (Object.keys(agentOnlineData).length > 0) {
                    if (this.state.chart_base === "hourly") {
                        for (var time2 in agentOnlineData) {
                            let spltThis = time2.split("_");
                            let totalTime = agentOnlineData[time2].reduce(function (a, b) {
                                return a + b;
                            }, 0);
                            grandTotal += totalTime;
                            let tmpdate = new Date(today);
                            tmpdate.setHours(spltThis[0]);
                            let tmpdate2 = new Date(today);
                            tmpdate2.setHours(spltThis[1]);

                            let sTime1 = tmpdate.getTime();
                            let sTime2 = tmpdate2.getTime();
                            //tmpAgentOnlineData[agentId][sTime1 + "_" + sTime2] = totalTime;
                            tmpAgentOnlineData[agentId][time2] = totalTime;
                            let seriesObj = {
                                x: agentId,
                                y: [sTime1, sTime2]
                            }
                            serDataArray.push(seriesObj);
                        }
                    } else {
                        for (var time2 in agentOnlineData) {
                            let spltThis = time2.split("_");
                            let totalTime = agentOnlineData[time2].reduce(function (a, b) {
                                return a + b;
                            }, 0);
                            grandTotal += totalTime;
                            let sTime1 = spltThis[0];
                            let sTime2 = spltThis[1];
                            tmpAgentOnlineData[agentId][sTime1 + "_" + sTime2] = totalTime;
                            let seriesObj = {
                                x: agentId,
                                y: [parseInt(sTime1), parseInt(sTime2)]
                            }
                            serDataArray.push(seriesObj);
                        }
                    }
                }
                totalAgentsOnline[agentId] = grandTotal;
                serAgentObj.data = serDataArray;
                serAgentObj.totalTime = grandTotal;
                seriesArray.push(serAgentObj);
            }
        }

        let avgTotalOnline = 0;
        let selectedAgentOnline = 0;
        let totalAgentCount = Object.keys(totalAgentsOnline).length;
        if (totalAgentCount > 0) {
            selectedAgentOnline = (this.state.agentId && this.state.agentId !== "all" && totalAgentsOnline.hasOwnProperty(this.state.agentId)) ? totalAgentsOnline[this.state.agentId] : 0;
            for (let agentid in totalAgentsOnline) {
                if (totalAgentsOnline.hasOwnProperty(agentid)) {
                    avgTotalOnline += totalAgentsOnline[agentid];
                }
            }
            avgTotalOnline = Math.floor(avgTotalOnline / totalAgentCount);
        }

        let sortedAgents = Object.keys(totalAgentsOnline).sort((a, b) => {
            return totalAgentsOnline[b] - totalAgentsOnline[a];
        });
        let allSortedAgts = sortedAgents.concat(allAgentIdes);
        if (allAgentIdes.length > 0) {
            allAgentIdes.forEach(agtIdddd => {
                seriesArray.push({
                    name: agtIdddd,
                    color: "#77c66d",
                    data: [{
                        x: agtIdddd,
                        y: []
                    }]
                });
            });
        }

        tmpCategories.forEach(mydate => {
            xaxis_categories.push(mydate.date);
            const index = mydate.index;
            /*if (tmpLoggedInagents[index] !== undefined) {
                series_data_loggedIn.push(tmpLoggedInagents[index].length);
            } else {
                series_data_loggedIn.push(0);
            } */
            if (tmpOnlineagents[index] !== undefined) {
                series_data_online.push(tmpOnlineagents[index].length);
            } else {
                series_data_online.push(0);
            }
        });
        //series = [{ name: 'Agents Logged in', color: '#2ab8c9', data: series_data_loggedIn }, { name: 'Agents Online', color: '#77c66d', data: series_data_online }];
        series = [{ name: 'Agents Online', color: '#77c66d', data: series_data_online }];
        options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        onlineSeries = seriesArray;
        onlineOptions.yaxis = {
            labels: {
                formatter: function (value) {
                    if (agentList.hasOwnProperty(value)) {
                        return agentList[value].name;
                    }
                }
            }
        };

        this.setState({ series: series, onlineSeries: onlineSeries, tmpAgentOnlineData: tmpAgentOnlineData, onlineAgentsColumns: onlineAgentsColumns, totalAgentsOnline: totalAgentsOnline, selectedAgentOnline: selectedAgentOnline, averageAllTotalOnline: avgTotalOnline, sortedAgent: allSortedAgts }, () => {
            this.setState({ options: options, onlineOptions: onlineOptions, loader: false });
        });
    }

    loadOnlineAgentsByZone = async () => {
        let onlineLoggedInData = this.state.onlineLoggedInData;
        let onlineOptions = this.state.onlineOptions;
        let onlineSeries = this.state.onlineSeries;
        let agentList = this.state.agentList;
        let allAgentIdes = Object.assign([], this.state.sortedAgent);
        let tmpCategories = [];
        let onlineAgentsColumns = [];

        var options = this.state.options;
        var series = this.state.series;
        this.setState({ series: [], options: {}, onlineSeries: [], onlineOptions: {} });
        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let xaxis_categories = [];
        let xaxis_type = "category";  // category, datetime, numeric
        let tmpOnlineagents = [];
        let series_data_online = [];
        let agentsOnlineTime = {};

        if (this.state.chart_base === "weekly") {
            if (this.state.date_range === "custom") {
                let stttDate = parseInt(this.state.start_date);
                let stttEndDate = parseInt(this.state.end_date);
                let endDateFormat = moment(stttEndDate).format("YYYY-MM-DD");
                let tmpEndDate222 = moment(endDateFormat).parseZone().tz(this.agent.org_timezone, true);
                let endDateTimestamp = parseInt(tmpEndDate222.format("x"));
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(stttDate).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let datt = tmpdate.format("DD");
                    let month = tmpdate.format("MM");
                    let year = tmpdate.format("YYYY");
                    let dayOfWeek = parseInt(tmpdate.format("d"));
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));

                    let totalWeekDays = 6 - dayOfWeek;
                    i = i + totalWeekDays;

                    let tmpdate22 = moment(stttDate).add(i, 'days');

                    let toDate22;
                    let gtTime22 = dtTimestamp;

                    if (gtTime22 > endDateTimestamp) {
                        tmpdate22 = tmpEndDate222;
                        toDate22 = endDateTimestamp;
                    } else {
                        let tmpDateOnly22 = tmpdate22.format("YYYY-MM-DD");
                        toDate22 = parseInt(moment(tmpDateOnly22).parseZone().tz(this.agent.org_timezone, true).format("x"));
                        ////(gtTime22 + 604800000);; //(gtTime22 + 86399000);
                    }
                    let datt22 = tmpdate22.format("DD");
                    let month22 = tmpdate22.format("MM");
                    let year22 = tmpdate22.format("YYYY");
                    let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                    tmpCategories.push({ date: dtTimestamp, toDate: toDate22, index: createIndex });
                }

                /* For Agent Online */
                for (var i = 0; i <= this.state.numberOfDays; i++) {
                    let tmpdate33333 = moment(stttDate).add(i, 'days');
                    let tmpDateOnly33333 = tmpdate33333.format("YYYY-MM-DD");
                    let dtTimestamp33333 = parseInt(moment(tmpDateOnly33333).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    let tmpdate44444 = parseInt(moment.tz(dtTimestamp33333, "x", this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x"));
                    let st1 = dtTimestamp33333;
                    let st2 = tmpdate44444;
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: dtTimestamp33333 });
                }
                /* For Agent Online */
            } else {
                let maxDays = 0;
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    let dayOfWeek = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("d"));
                    const splitDate = tmpdate.split("-");
                    let datt = splitDate[2];
                    let month = splitDate[1];
                    let year = splitDate[0];
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ date: tmpdateTimestamp, toDate: (tmpdateTimestamp + 86399000), index: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                        const tmpdateTimestamp22 = parseInt(moment.tz(tmpdate22, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                        const splitDate22 = tmpdate22.split("-");
                        let datt22 = splitDate22[2];
                        let month22 = splitDate22[1];
                        let year22 = splitDate22[0];
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ date: tmpdateTimestamp22, toDate: (tmpdateTimestamp + 86399000), index: createIndex });
                    }
                }
                tmpCategories.reverse();

                /* For Agent Online */
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    let tmpdate2 = moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format("x");
                    let tmpdate3 = moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x");
                    let st1 = parseInt(tmpdate2);
                    let st2 = parseInt(tmpdate3);
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdateTimestamp });
                }
                onlineAgentsColumns.reverse();
                /* For Agent Online */
            }

            if (onlineLoggedInData.length > 0) {
                onlineLoggedInData.forEach(onlineRow => {
                    let agent_id = onlineRow.agent_id;
                    let login_duration = onlineRow.login_duration;
                    let hourAddedOn = onlineRow.hours;
                    let loginDate = onlineRow.login_date.value;

                    let timeAddedOn = parseInt(moment.tz(loginDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));

                    tmpCategories.forEach(valObj => {
                        if (timeAddedOn >= valObj.date && timeAddedOn <= valObj.toDate) {
                            let createIndex = valObj.index;
                            if (tmpOnlineagents[createIndex] === undefined) {
                                tmpOnlineagents[createIndex] = [];
                            }
                            if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                tmpOnlineagents[createIndex].push(agent_id);
                            }
                        }
                    });

                    /* For Individual Online Agents Start */

                    if (agentsOnlineTime[agent_id] === undefined) {
                        agentsOnlineTime[agent_id] = {};
                    }

                    let fromTime = moment.tz(loginDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format("x");
                    let toTime = moment.tz(loginDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x");
                    let st1 = parseInt(fromTime);
                    let st2 = parseInt(toTime);
                    if (agentsOnlineTime[agent_id][st1 + "_" + st2] === undefined) {
                        agentsOnlineTime[agent_id][st1 + "_" + st2] = [];
                    }
                    agentsOnlineTime[agent_id][st1 + "_" + st2].push(login_duration);

                    /* For Individual Online Agents End */
                });
            }

            /* if (Object.keys(onlineLoggedInData).length > 0) {
                for (let agent_id in onlineLoggedInData) {
                    let tmpAgentData = onlineLoggedInData[agent_id];
                    tmpAgentData.forEach(loginD => {
                        let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                        let online = loginD.online ? parseInt(loginD.online) : 0;
                        let type = loginD.type ? loginD.type : "";
                        let agent_id = loginD.agent_id ? loginD.agent_id : "";
    
                        let addedon = new Date(parseInt(loginD.addedon));
                        let timeAddedOn = addedon.getTime();
                        
                        tmpCategories.forEach(valObj => {
                            if (timeAddedOn >= valObj.date && timeAddedOn <= valObj.toDate) {
                                let createIndex = valObj.index;
                                if (tmpOnlineagents[createIndex] === undefined) {
                                    tmpOnlineagents[createIndex] = [];
                                }
    
                                if (type === "online_offline") {
    
                                    if (online >= parseInt(loginD.addedon)) {
                                        if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                            tmpOnlineagents[createIndex].push(agent_id);
                                        }
                                    }
    
                                    if (offline) {
                                        let onlineD = new Date(online);
                                        onlineD.setHours(0, 0, 0, 0);
                                        let offlineD = new Date(onlineD);
                                        offlineD.setHours(23, 59, 59, 0);
                                        let dayOnlineD = onlineD.getDate();
                                        let offlineDy = new Date(offline);
                                        let dayOfflineD = offlineDy.getDate();
                                        if (agentsOnlineTime[agent_id] === undefined) {
                                            agentsOnlineTime[agent_id] = {};
                                        }
    
                                        if ((dayOfflineD - dayOnlineD) >= 1) {
                                            for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                                var workSeconds = 86400;
                                                if (j === dayOnlineD) {
                                                    let onlineD22 = new Date(online);
                                                    let on2Hours = onlineD22.getHours();
                                                    let on2minutes = onlineD22.getMinutes();
                                                    let on2Seconds = onlineD22.getSeconds();
                                                    let hrs = 24 - on2Hours;
                                                    let hrsInMinutes = hrs * 60;
                                                    let minusMinutes = hrsInMinutes - on2minutes;
                                                    let minusMinutesInSec = minusMinutes * 60;
                                                    workSeconds = minusMinutesInSec - on2Seconds;
                                                    let fromTime = new Date(online);
                                                    fromTime.setHours(0, 0, 0, 0);
                                                    let toTime = new Date(online);
                                                    toTime.setHours(23, 59, 59, 0);
                                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                                } else {
    
                                                    if (j === dayOfflineD) {
                                                        let onlineD22 = new Date(offline);
                                                        let on2Hours = onlineD22.getHours();
                                                        let on2minutes = onlineD22.getMinutes();
                                                        let on2Seconds = onlineD22.getSeconds();
                                                        workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                                    }
    
                                                    let fromTime = new Date(online);
                                                    fromTime.setDate(j);
                                                    fromTime.setHours(0, 0, 0, 0);
                                                    let toTime = new Date(fromTime);
                                                    toTime.setHours(23, 59, 59, 0);
                                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                                }
                                            }
                                        } else {
                                            let onlineinTime = onlineD.getTime();
                                            let offlineinTime = offlineD.getTime();
                                            let diffMiliseconds = offline - online;
                                            let diffSeconds = 0;
                                            if (diffMiliseconds > 0) {
                                                diffSeconds = Math.floor(diffMiliseconds / 1000);
                                            }
                                            if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                                agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                            }
                                            agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            } */

            /* if (Object.keys(onlineLoggedInData).length > 0) {
                for (let agent_id in onlineLoggedInData) {
                    let tmpAgentData = onlineLoggedInData[agent_id];
                    tmpAgentData.forEach(loginD => {
                        let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                        let online = loginD.online ? parseInt(loginD.online) : 0;
                        let type = loginD.type ? loginD.type : "";
                        let agent_id = loginD.agent_id ? loginD.agent_id : "";
                        let timeAddedOn = parseInt(moment.tz(parseInt(loginD.addedon), "x", "UTC").tz(this.agent.org_timezone).format("x"));
                        
                        tmpCategories.forEach(valObj => {
                            if (timeAddedOn >= valObj.date && timeAddedOn <= valObj.toDate) {
                                let createIndex = valObj.index;
                                if (tmpOnlineagents[createIndex] === undefined) {
                                    tmpOnlineagents[createIndex] = [];
                                }
                                if (type === "online_offline") {
                                    let tmpOnlineTimestamp = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).format("x");
                                    if (tmpOnlineTimestamp >= timeAddedOn) {
                                        if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                                            tmpOnlineagents[createIndex].push(agent_id);
                                        }
                                    }

                                    if (offline) {
                                        let onlineD = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':0, 'minute':0, 'second':0, 'millisecond':0 }).format("x");
                                        onlineD = parseInt(onlineD);
                                        let offlineD = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':23, 'minute':59, 'second':59, 'millisecond':999 }).format("x");
                                        offlineD = parseInt(offlineD);
                                        let dayOnlineD = moment.tz(onlineD, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format("DD");
                                        dayOnlineD = parseInt(dayOnlineD);
                                        let dayOfflineD = moment.tz(offline, "x", "UTC").tz(this.agent.org_timezone).format("DD");
                                        dayOfflineD = parseInt(dayOfflineD);
                                       
                                        if (agentsOnlineTime[agent_id] === undefined) {
                                            agentsOnlineTime[agent_id] = {};
                                        }

                                        if ((dayOfflineD - dayOnlineD) >= 1) {
                                            for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                                var workSeconds = 86400;
                                                if (j === dayOnlineD) {
                                                    let tmpTimess = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).format("HH:mm:ss");
                                                    let splitTimes = tmpTimess.split(":");
                                                    let on2Hours = parseInt(splitTimes[0]);
                                                    let on2minutes = parseInt(splitTimes[1]);
                                                    let on2Seconds = parseInt(splitTimes[2]);
                                                    let hrs = 24 - on2Hours;
                                                    let hrsInMinutes = hrs * 60;
                                                    let minusMinutes = hrsInMinutes - on2minutes;
                                                    let minusMinutesInSec = minusMinutes * 60;
                                                    workSeconds = minusMinutesInSec - on2Seconds;

                                                    let fromTime = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':0, 'minute':0, 'second':0, 'millisecond':0 }).format("x");
                                                    let toTime = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':23, 'minute':59, 'second':59, 'millisecond':999 }).format("x");
                                                    if (agentsOnlineTime[agent_id][fromTime + "_" + toTime] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime + "_" + toTime] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime + "_" + toTime].push(workSeconds);
                                                } else {
                                                    if (j === dayOfflineD) {
                                                        let tmpTimess = moment.tz(offline, "x", "UTC").tz(this.agent.org_timezone).format("HH:mm:ss");
                                                        let splitTimes = tmpTimess.split(":");
                                                        let on2Hours = parseInt(splitTimes[0]);
                                                        let on2minutes = parseInt(splitTimes[1]);
                                                        let on2Seconds = parseInt(splitTimes[2]);
                                                        workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                                    }
                                                    let fromTime = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':0, 'minute':0, 'second':0, 'millisecond':0 }).format("x");
                                                    let toTime = moment.tz(online, "x", "UTC").tz(this.agent.org_timezone).set({'hour':23, 'minute':59, 'second':59, 'millisecond':999 }).format("x");
                                                    if (agentsOnlineTime[agent_id][fromTime + "_" + toTime] === undefined) {
                                                        agentsOnlineTime[agent_id][fromTime + "_" + toTime] = [];
                                                    }
                                                    agentsOnlineTime[agent_id][fromTime + "_" + toTime].push(workSeconds);
                                                }
                                            }
                                        } else {
                                            let onlineinTime = onlineD;
                                            let offlineinTime = offlineD;
                                            let diffMiliseconds = offline - online;
                                            let diffSeconds = 0;
                                            if (diffMiliseconds > 0) {
                                                diffSeconds = Math.floor(diffMiliseconds / 1000);
                                            }
                                            if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                                agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                                            }
                                            agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                                        }
                                    }
                                }
                            }
                        });
                    });
                }
            } */
        } else if (this.state.chart_base === "daily") {
            if (this.state.date_range === "custom") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(parseInt(this.state.start_date)).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let dayAddedOn = tmpdate.format("DD");
                    let monthAddedOn = tmpdate.format("MM");
                    let yearAddedOn = tmpdate.format("YYYY");
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: dtTimestamp, index: createIndex });

                    let tmpdate2 = parseInt(dtTimestamp);
                    let tmpdate3 = parseInt(moment.tz(tmpdate2, "x", this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x"));
                    let st1 = tmpdate2;
                    let st2 = tmpdate3;
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: dtTimestamp });
                }
            } else {
                let maxDays = 6;
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }

                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    const splitDate = tmpdate.split("-");
                    let dayAddedOn = splitDate[2];
                    let monthAddedOn = splitDate[1];
                    let yearAddedOn = splitDate[0];
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdateTimestamp, index: createIndex });

                    /* For Agent Online */
                    let tmpdate2 = moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format("x");
                    let tmpdate3 = moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x");
                    let st1 = parseInt(tmpdate2);
                    let st2 = parseInt(tmpdate3);
                    onlineAgentsColumns.push({ start: st1, end: st2, index: st1 + "_" + st2, date: tmpdateTimestamp });
                    /* For Agent Online */
                }
                tmpCategories.reverse();
                onlineAgentsColumns.reverse();
            }

            console.log("------------       tmpCategories    22222222   -------------");
            console.log(tmpCategories);

            if (onlineLoggedInData.length > 0) {
                onlineLoggedInData.forEach(onlineRow => {
                    let agent_id = onlineRow.agent_id;
                    let login_duration = onlineRow.login_duration;
                    let hourAddedOn = onlineRow.hours;
                    let loginDate = onlineRow.login_date.value;
                    let splitLdate = loginDate.split("-");
                    let dateAddedOn = splitLdate[2];
                    let monthAddedOn = splitLdate[1];
                    let yearAddedOn = splitLdate[0];
                    let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    if (tmpOnlineagents[createIndex] === undefined) {
                        tmpOnlineagents[createIndex] = [];
                    }
                    if (tmpOnlineagents[createIndex].indexOf(agent_id) === -1) {
                        tmpOnlineagents[createIndex].push(agent_id);
                    }

                    if (agentsOnlineTime[agent_id] === undefined) {
                        agentsOnlineTime[agent_id] = {};
                    }

                    let fromTime = moment.tz(loginDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 0, 'minute': 0, 'second': 0, 'millisecond': 0 }).format("x");
                    let toTime = moment.tz(loginDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).set({ 'hour': 23, 'minute': 59, 'second': 59, 'millisecond': 999 }).format("x");
                    let st1 = parseInt(fromTime);
                    let st2 = parseInt(toTime);
                    if (agentsOnlineTime[agent_id][st1 + "_" + st2] === undefined) {
                        agentsOnlineTime[agent_id][st1 + "_" + st2] = [];
                    }
                    agentsOnlineTime[agent_id][st1 + "_" + st2].push(login_duration);
                });


                console.log("------------       tmpOnlineagents    22222222   -------------");
                console.log(tmpOnlineagents);
            }
        } else if (this.state.chart_base === "hourly") {
            let tmpdate = new Date();
            let todayHour = moment.tz(tmpdate.getTime(), "x").tz(this.agent.org_timezone).format("HH");
            for (var i = 0; i <= todayHour; i++) {
                let tmpdate2 = new Date();
                tmpdate2 = tmpdate2.getTime();
                let tmpHourrr = (i).toString().padStart(2, "0") + ":00:00";
                let timeWithHour = moment.tz(tmpdate2, "x").tz(this.agent.org_timezone).format("YYYY-MM-DD " + tmpHourrr);
                let timestampp = parseInt(moment.tz(timeWithHour, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));

                let monthAddedOn = moment.tz(tmpdate2, "x").tz(this.agent.org_timezone).format("MM");
                let yearAddedOn = moment.tz(tmpdate2, "x").tz(this.agent.org_timezone).format("YYYY");
                let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: timestampp, index: createIndex });

                /* For Agent Online */
                let j = i + 1;
                onlineAgentsColumns.push({ start: i, end: j, index: i + "_" + j, date: timestampp });
                /* For Agent Online */
            }

            if (onlineLoggedInData.length > 0) {
                onlineLoggedInData.forEach(onlineRow => {
                    let agent_id = onlineRow.agent_id;
                    let login_duration = onlineRow.login_duration;
                    let hourAddedOn = onlineRow.hours;
                    let loginDate = onlineRow.login_date.value;
                    let splitLdate = loginDate.split("-");
                    let monthAddedOn = splitLdate[1];
                    let yearAddedOn = splitLdate[0];
                    let createIndex = hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    if (tmpOnlineagents[createIndex] === undefined) {
                        tmpOnlineagents[createIndex] = [];
                    }
                    tmpOnlineagents[createIndex].push(1);
                    if (agentsOnlineTime[agent_id] === undefined) {
                        agentsOnlineTime[agent_id] = {};
                    }
                    let nxtHour = parseInt(hourAddedOn) + 1;
                    if (agentsOnlineTime[agent_id][hourAddedOn + "_" + nxtHour] === undefined) {
                        agentsOnlineTime[agent_id][hourAddedOn + "_" + nxtHour] = [];
                    }
                    agentsOnlineTime[agent_id][hourAddedOn + "_" + nxtHour] = login_duration;
                });
            }
        }

        let tmpAgentOnlineData = {};
        let totalAgentsOnline = {};
        let seriesArray = [];

        if (Object.keys(agentsOnlineTime).length > 0) {
            for (var agentId in agentsOnlineTime) {
                /* Remove agent if find here */
                allAgentIdes.splice(allAgentIdes.indexOf(agentId), 1);
                /* Remove agent if find here */

                let agentOnlineData = agentsOnlineTime[agentId];
                tmpAgentOnlineData[agentId] = {};
                let serAgentObj = {
                    name: agentId,
                    color: "#77c66d"
                };
                let serDataArray = [];
                let grandTotal = 0;
                if (Object.keys(agentOnlineData).length > 0) {
                    if (this.state.chart_base === "hourly") {
                        for (var time2 in agentOnlineData) {
                            let spltThis = time2.split("_");
                            let totalTime = parseInt(agentOnlineData[time2]);
                            grandTotal += totalTime;
                            let tmpStartHour = (spltThis[0]).toString().padStart(2, "0");
                            let tmpEndHour = (spltThis[0]).toString().padStart(2, "1");
                            let timeWithHour = moment().tz(this.agent.org_timezone).format("YYYY-MM-DD " + tmpStartHour + ":00:00");
                            let timeWithHour1 = moment().tz(this.agent.org_timezone).format("YYYY-MM-DD " + tmpEndHour + ":00:00");
                            let sTime1 = parseInt(moment.tz(timeWithHour, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone).format("x"));
                            let sTime2 = parseInt(moment.tz(timeWithHour1, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone).format("x"));
                            tmpAgentOnlineData[agentId][time2] = totalTime;
                            let seriesObj = {
                                x: agentId,
                                y: [sTime1, sTime2]
                            }
                            serDataArray.push(seriesObj);
                        }
                    } else {
                        for (let time2 in agentOnlineData) {
                            let spltThis = time2.split("_");
                            let totalTime = agentOnlineData[time2].reduce(function (a, b) {
                                return a + b;
                            }, 0);
                            grandTotal += totalTime;
                            let sTime1 = spltThis[0];
                            let sTime2 = spltThis[1];
                            tmpAgentOnlineData[agentId][sTime1 + "_" + sTime2] = totalTime;
                            let seriesObj = {
                                x: agentId,
                                y: [parseInt(sTime1), parseInt(sTime2)]
                            }
                            serDataArray.push(seriesObj);
                        }
                    }
                }
                totalAgentsOnline[agentId] = grandTotal;
                serAgentObj.data = serDataArray;
                serAgentObj.totalTime = grandTotal;
                seriesArray.push(serAgentObj);
            }
        }

        let avgTotalOnline = 0;
        let selectedAgentOnline = 0;
        let totalAgentCount = Object.keys(totalAgentsOnline).length;
        if (totalAgentCount > 0) {
            selectedAgentOnline = (this.state.agentId && this.state.agentId !== "all" && totalAgentsOnline.hasOwnProperty(this.state.agentId)) ? totalAgentsOnline[this.state.agentId] : 0;
            for (let agentid in totalAgentsOnline) {
                if (totalAgentsOnline.hasOwnProperty(agentid)) {
                    avgTotalOnline += totalAgentsOnline[agentid];
                }
            }
            avgTotalOnline = Math.floor(avgTotalOnline / totalAgentCount);
        }

        let sortedAgents = Object.keys(totalAgentsOnline).sort((a, b) => {
            return totalAgentsOnline[b] - totalAgentsOnline[a];
        });
        let allSortedAgts = sortedAgents.concat(allAgentIdes);
        if (allAgentIdes.length > 0) {
            allAgentIdes.forEach(agtIdddd => {
                seriesArray.push({
                    name: agtIdddd,
                    color: "#77c66d",
                    data: [{
                        x: agtIdddd,
                        y: []
                    }]
                });
            });
        }

        tmpCategories.forEach(mydate => {
            xaxis_categories.push(mydate.date);
            const index = mydate.index;
            if (tmpOnlineagents[index] !== undefined) {
                series_data_online.push(tmpOnlineagents[index].length);
            } else {
                series_data_online.push(0);
            }
        });

        series = [{ name: 'Agents Online', color: '#77c66d', data: series_data_online }];
        options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        onlineSeries = seriesArray;
        onlineOptions.yaxis = {
            labels: {
                formatter: function (value) {
                    if (agentList.hasOwnProperty(value)) {
                        return agentList[value].name;
                    }
                }
            }
        };

        this.setState({ series: series, onlineSeries: onlineSeries, tmpAgentOnlineData: tmpAgentOnlineData, onlineAgentsColumns: onlineAgentsColumns, totalAgentsOnline: totalAgentsOnline, selectedAgentOnline: selectedAgentOnline, averageAllTotalOnline: avgTotalOnline, sortedAgent: allSortedAgts }, () => {
            this.setState({ options: options, onlineOptions: onlineOptions, loader: false });
        });
    }

    getchats = () => {
        let todayDate = new Date()
        todayDate.setHours(0, 0, 0, 0);
        this.setState({ loader: true });
        var body = [`ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&domain=${encodeURIComponent((this.state.domain && this.state.domain !== "all") ? this.state.domain : "")}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&currentDate=${todayDate.getTime()}`];
        //fetch(MAIL_FUNCTION_URL + "/analytics/getAgentChats", {
        fetch(GATEWAY_URL + "/getAgentChats", {
            method: "post",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            if (response.status === "success") {
                this.setState({ currentchats: response.data }, () => {
                    this.loadChats();
                });
            } else {
                this.setState({ loader: false });
            }
        }).catch((err) => { });
    }

    loadChats = () => {
        var currentchats = this.state.currentchats;
        /* let allAgentIdes = this.state.sortedAgent; */
        let agentChats = {};
        currentchats.forEach(chatData => {
            var agent_ides = chatData.agent_ides;
            let agtIdesArr = agent_ides.split(",");
            let chatObj = {
                chat_duration: chatData.chat_duration,
                rating: chatData.rating,
                response_time: chatData.response_time,
                wait_time: chatData.wait_time
            };
            if (agtIdesArr.length > 1) {
                agtIdesArr.forEach(agtId => {
                    if (agentChats[agtId] === undefined) {
                        agentChats[agtId] = [];
                    }
                    agentChats[agtId].push(chatObj);
                });
            } else {
                if (agentChats[agent_ides] === undefined) {
                    agentChats[agent_ides] = [];
                }
                agentChats[agent_ides].push(chatObj);
            }
        });

        let agentActivity = {};
        if (Object.keys(agentChats).length > 0) {
            for (var key in agentChats) {
                let agtdata = agentChats[key];
                let totalcompchats = agtdata.length;
                let tmp_chat_duration = 0; // Average
                let tmp_rating = 0;
                let tmp_feedback_received = 0; // Count chat 
                let tmp_response_time = 0;   // Average

                agtdata.forEach(reptData => {
                    let chat_duration = reptData.chat_duration === null ? 0 : reptData.chat_duration;
                    let rating = reptData.rating === null ? 0 : reptData.rating;
                    let response_time = reptData.response_time === null ? 0 : reptData.response_time;

                    tmp_chat_duration += chat_duration;
                    tmp_response_time += response_time;
                    tmp_rating += rating;

                    if (rating > 0) {
                        tmp_feedback_received++;
                    }
                });

                /* Remove agent if find here */
                /*  let index = allAgentIdes.indexOf(key);
                 allAgentIdes.splice(index, 1); */
                /* Remove agent if find here */

                agentActivity[key] = {
                    agentId: key,
                    complete_chats: totalcompchats,
                    satisfaction: tmp_rating === 0 ? 0 : tmp_rating / (tmp_feedback_received * 100),
                    feedbacks_received: tmp_feedback_received,
                    responseTime: tmp_response_time / totalcompchats,
                    duration: tmp_chat_duration / totalcompchats
                };
            }
        }

        let tmpResTime = 0;
        let tmpChatDuration = 0;
        let avgTmpResTime = 0;
        let avgTmpChatDuration = 0;
        let actLength = Object.keys(agentActivity).length;
        if (actLength > 0 && this.state.agentId && this.state.agentId !== "all") {
            if (agentActivity.hasOwnProperty(this.state.agentId)) {
                tmpResTime = agentActivity[this.state.agentId].responseTime;
                tmpChatDuration = agentActivity[this.state.agentId].duration;
            }
            let t1 = 0;
            let t2 = 0;
            let t3 = 0;
            for (let agtiddd in agentActivity) {
                let tmpactD = agentActivity[agtiddd];
                t1 += tmpactD.responseTime;
                t2 += tmpactD.duration;
                t3 += tmpactD.complete_chats;
            }
            avgTmpResTime = Math.floor(t1 / t3);
            avgTmpChatDuration = Math.floor(t2 / t3);
        }

        /* function compareData(a, b) {
            return agentActivity[b].complete_chats - agentActivity[a].complete_chats;
        }

        let sortedAgents = Object.keys(agentActivity).sort(compareData);
        let allSortedAgts = sortedAgents.concat(allAgentIdes); */

        this.setState({ agentActivity: agentActivity, avgAgentResTime: Math.floor(tmpResTime), avgAgentChatDuration: Math.floor(tmpChatDuration), avgAllAgentResTime: avgTmpResTime, avgAllAgentChatDuration: avgTmpChatDuration }, () => {
            console.log(this.state.agentActivity, this.state.avgAgentResTime, this.state.avgAgentChatDuration, this.state.avgAllAgentResTime, this.state.avgAllAgentChatDuration);
            /* this.setState({ sortedAgent: allSortedAgts, loader: false }); */
            this.setState({ loader: false });
        });
    }

    sideMenuePhone = () => {
        try {
            document.getElementById('sidebarnavChild').classList.toggle('sideMenue');
        } catch (err) { }
    }

    hideDummyData = () => {
        this.setState({
            showDummyData: false,
            loader: true
        })
        this.getAgentsList();
        this.getDomainsList();
        this.goToAgent("");
    }

    render() {
        let totalHeadersColumns = this.state.onlineAgentsColumns.length;
        let lastColumns = 0;
        if (this.state.date_range === "today") {
            lastColumns = totalHeadersColumns % 4;
        } else if (this.state.date_range === "14_days") {
            lastColumns = totalHeadersColumns % 5;
        } else if (this.state.date_range === "30_days" || this.state.date_range === "custom") {
            lastColumns = totalHeadersColumns % 7;
        }

        var getHeaders = this.state.onlineAgentsColumns.map((column, key) => {
            if (totalHeadersColumns > 4) {
                if (this.state.date_range === "today") {
                    if (key === 0) {
                        return <th key={"kk_" + key} colSpan="4">{this.formatXAxis(column.date)}</th>
                    } else if (lastColumns > 0 && (totalHeadersColumns - key) === lastColumns) {
                        return <th key={"kk_" + key} colSpan={lastColumns}>{this.formatXAxis(column.date)}</th>
                    } else {
                        if (key % 4 === 0) {
                            return <th key={"kk_" + key} colSpan="4">{this.formatXAxis(column.date)}</th>
                        }
                    }
                } else if (this.state.date_range === "7_days") {
                    return <th key={"kk_" + key}>{this.formatXAxis(column.date)}</th>
                } else if (this.state.date_range === "14_days") {
                    if (key === 0) {
                        return <th key={"kk_" + key} colSpan="5">{this.formatXAxis(column.date)}</th>
                    } else if (lastColumns > 0 && (totalHeadersColumns - key) === lastColumns) {
                        return <th key={"kk_" + key} colSpan={lastColumns}>{this.formatXAxis(column.date)}</th>
                    } else {
                        if (key % 5 === 0) {
                            return <th key={"kk_" + key} colSpan="5">{this.formatXAxis(column.date)}</th>
                        }
                    }
                } else {
                    if (key === 0) {
                        return <th key={"kk_" + key} colSpan="7">{this.formatXAxis(column.date)}</th>
                    } else if (lastColumns > 0 && (totalHeadersColumns - key) === lastColumns) {
                        return <th key={"kk_" + key} colSpan={lastColumns}>{this.formatXAxis(column.date)}</th>
                    } else {
                        if (key % 7 === 0) {
                            return <th key={"kk_" + key} colSpan="7">{this.formatXAxis(column.date)}</th>
                        }
                    }
                }
            } else {
                return <th key={"kk_" + key} >{this.formatXAxis(column.date)}</th>
            }
        });

        let dateShow = "";
        if (this.state.date_range === "custom") {
            dateShow = "manage-icon";
        }

        let agentName = (this.state.agentId && this.state.agentId !== "all" && this.state.agentList[this.state.agentId] && this.state.agentList[this.state.agentId].name) ? this.state.agentList[this.state.agentId].name : "Agent";

        return (<>
            <NotificationContainer />
            {(!this.state.agentId || this.state.agentId === "online" || this.state.agentId === "all") ? (<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>) : (null)}
            <LeftPanel />
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Analytics</h3>
                <div className="ml-auto"></div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
          {this.state.payment_status?  <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">

                            {/* Filters Start  */}
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                                    <div className="pb-4">
                                        <div className="main-heading main-heading-title py-5">
                                            <h3 className="heding-text mb-3">Activity Report</h3>
                                            {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '10px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                                            <span className="agent_breadcrumb">{this.state.agentId === "" ? (<ul className="breadcrumb mb-0 bg-white"><li className="active">All</li></ul>) : (<ul className="breadcrumb mb-0 bg-white"><li className="agent-online-names" onClick={() => this.goToAgent("")}>All</li><li className="active">{agentName}</li></ul>)}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="bg-white left-widgetsetings-box k-flex align-items-center px-5 py-5 mob-analytics">
                                        {/* <div className="k-flex align-items-center label-style">
                                            <div className="material-select">
                                                <FormControl variant="outlined" className="material-outlined w-150">
                                                    <InputLabel id="domain-outlined-label">Widget</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true}} labelId="domain-outlined-label" id="domain" name="domain" value={this.state.domain?this.state.domain:"all"} onChange={(e) => this.changeFilterHandle(e)} label="Widget">
                                                        <MenuItem value="all">All</MenuItem>
                                                        {this.state.domain_list.map((domn, indx) => {
                                                            return <MenuItem key={indx} value={domn}>{domn}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div> */}

                                        <div className="k-flex align-items-center label-style">
                                            <div className="material-select ml-5">
                                                <div className="k-flex align-items-center label-style">
                                                    <div className="material-select ml-5">
                                                        <FormControl variant="outlined" className="material-outlined w-150">
                                                            <InputLabel id="ddomain-outlined-label">Agent</InputLabel>
                                                            {(this.userRole === 'Owner' || this.userRole === 'Admin') ? (<Select MenuProps={{ disableScrollLock: true }} labelId="agentId-outlined-label" id="agentId" name="agentId" value={this.state.agentId ? this.state.agentId : "online"} onChange={(e) => this.changeFilterHandle(e)} label="Agent">
                                                                <MenuItem value="online">Online Agents</MenuItem>
                                                                <MenuItem value="all">All Agents</MenuItem>
                                                                {this.state.agentListArray.map((agtttt, indx) => {
                                                                    let agentName = agtttt.first_name ? agtttt.first_name + ' ' + (agtttt.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agtttt.last_name : '') : (agtttt.name ? agtttt.name : '');
                                                                    if (agentName.length > 40) {
                                                                        agentName = agentName.substring(0, 35) + '....';
                                                                    }
                                                                    return <MenuItem key={indx} value={agtttt.id}>{agentName}</MenuItem>
                                                                })}
                                                            </Select>) : (<Select MenuProps={{ disableScrollLock: true }} labelId="agentId-outlined-label" id="agentId" name="agentId" value={this.agent.agentId} onChange={(e) => this.changeFilterHandle(e)} label="Agent">
                                                                {this.state.agentListArray.map((agtttt, indx) => {
                                                                    if (this.agent.agentId === agtttt.id) {
                                                                        let agentName = agtttt.first_name ? agtttt.first_name + ' ' + (agtttt.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agtttt.last_name : '') : (agtttt.name ? agtttt.name : '');
                                                                        if (agentName.length > 40) {
                                                                            agentName = agentName.substring(0, 35) + '....';
                                                                        }
                                                                        return <MenuItem key={indx} value={agtttt.id}>{agentName}</MenuItem>
                                                                    }
                                                                })}
                                                            </Select>)}
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="k-flex align-items-center ml-auto label-style">
                                            <div className={`k-flex position-relative mob-view ${dateShow}`}>

                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="ddomain-outlined-label">Date Range</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="date_range-outlined-label" id="date_range" name="date_range" value={this.state.date_range ? this.state.date_range : "today"} onChange={(e) => this.changeFilterHandle(e)} label="Date Range">
                                                            <MenuItem value="today">Today</MenuItem>
                                                            <MenuItem value="7_days">Last 7 days</MenuItem>
                                                            <MenuItem value="14_days">Last 14 days</MenuItem>
                                                            <MenuItem value="30_days">Last 30 days</MenuItem>
                                                            <MenuItem value="custom">Custom</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {this.state.date_range === "custom" ? (<span className="pl-3 pr-3 mb-0 text-14 cursor-pointer pt-3 date-icon" onClick={this.showHideDatePicker}>
                                                    <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 58.382 58.382">
                                                        <g id="Group_4585" data-name="Group 4585">
                                                            <g id="Group_4584" data-name="Group 4584">
                                                                <path id="Path_2553" data-name="Path 2553" d="M51.541,4.561H48.8V0H44.243V4.561h-30.1V0H9.578V4.561H6.842A6.849,6.849,0,0,0,0,11.4V51.541a6.849,6.849,0,0,0,6.842,6.842h44.7a6.849,6.849,0,0,0,6.842-6.842V11.4A6.849,6.849,0,0,0,51.541,4.561Zm2.281,46.98a2.283,2.283,0,0,1-2.281,2.281H6.842a2.283,2.283,0,0,1-2.281-2.281v-30.1h49.26Zm0-34.665H4.561V11.4A2.283,2.283,0,0,1,6.842,9.122H9.578v4.561h4.561V9.122h30.1v4.561H48.8V9.122h2.737A2.283,2.283,0,0,1,53.821,11.4Z" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4587" data-name="Group 4587" transform="translate(8.74 26.218)">
                                                            <g id="Group_4586" data-name="Group 4586" transform="translate(0 0)">
                                                                <rect id="Rectangle_1782" data-name="Rectangle 1782" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4589" data-name="Group 4589" transform="translate(17.843 26.218)">
                                                            <g id="Group_4588" data-name="Group 4588" transform="translate(0 0)">
                                                                <rect id="Rectangle_1783" data-name="Rectangle 1783" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4591" data-name="Group 4591" transform="translate(26.947 26.218)">
                                                            <g id="Group_4590" data-name="Group 4590" transform="translate(0 0)">
                                                                <rect id="Rectangle_1784" data-name="Rectangle 1784" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4593" data-name="Group 4593" transform="translate(36.05 26.218)">
                                                            <g id="Group_4592" data-name="Group 4592" transform="translate(0 0)">
                                                                <rect id="Rectangle_1785" data-name="Rectangle 1785" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4595" data-name="Group 4595" transform="translate(45.154 26.218)">
                                                            <g id="Group_4594" data-name="Group 4594" transform="translate(0 0)">
                                                                <rect id="Rectangle_1786" data-name="Rectangle 1786" width="4.734" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4597" data-name="Group 4597" transform="translate(8.74 35.322)">
                                                            <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                                                                <rect id="Rectangle_1787" data-name="Rectangle 1787" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4599" data-name="Group 4599" transform="translate(17.843 35.322)">
                                                            <g id="Group_4598" data-name="Group 4598" transform="translate(0 0)">
                                                                <rect id="Rectangle_1788" data-name="Rectangle 1788" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4601" data-name="Group 4601" transform="translate(26.947 35.322)">
                                                            <g id="Group_4600" data-name="Group 4600" transform="translate(0 0)">
                                                                <rect id="Rectangle_1789" data-name="Rectangle 1789" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4603" data-name="Group 4603" transform="translate(36.05 35.322)">
                                                            <g id="Group_4602" data-name="Group 4602" transform="translate(0 0)">
                                                                <rect id="Rectangle_1790" data-name="Rectangle 1790" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4605" data-name="Group 4605" transform="translate(8.74 44.426)">
                                                            <g id="Group_4604" data-name="Group 4604" transform="translate(0 0)">
                                                                <rect id="Rectangle_1791" data-name="Rectangle 1791" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4607" data-name="Group 4607" transform="translate(17.843 44.426)">
                                                            <g id="Group_4606" data-name="Group 4606" transform="translate(0 0)">
                                                                <rect id="Rectangle_1792" data-name="Rectangle 1792" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4609" data-name="Group 4609" transform="translate(26.947 44.426)">
                                                            <g id="Group_4608" data-name="Group 4608" transform="translate(0 0)">
                                                                <rect id="Rectangle_1793" data-name="Rectangle 1793" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4611" data-name="Group 4611" transform="translate(36.05 44.426)">
                                                            <g id="Group_4610" data-name="Group 4610" transform="translate(0 0)">
                                                                <rect id="Rectangle_1794" data-name="Rectangle 1794" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4613" data-name="Group 4613" transform="translate(45.154 35.322)">
                                                            <g id="Group_4612" data-name="Group 4612" transform="translate(0 0)">
                                                                <rect id="Rectangle_1795" data-name="Rectangle 1795" width="4.734" height="4.734" />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                </span>) : (null)}
                                                <span className="analytics-datepicker" style={{ display: this.state.showDateRange ? "block" : "none" }}>
                                                    <DateRangePicker
                                                        staticRanges={[]}
                                                        inputRanges={[]}
                                                        onChange={this.onDatesSelect}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        ranges={[{
                                                            startDate: this.state.fill_start_date,
                                                            endDate: this.state.fill_end_date,
                                                            key: 'selection'
                                                        }]}
                                                        direction="horizontal"
                                                    />
                                                    <div className="py-5 px-5 text-right">
                                                        <button className="btnBlueSettings mr-3" onClick={this.applyDateRange}>Apply</button>
                                                        <button className="btnBorderSettings mr-3" onClick={this.cancelDateRange}>Cancel</button>
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="material-select ml-5">
                                                <FormControl variant="outlined" className="material-outlined w-150">
                                                    <InputLabel id="chart_base-outlined-label">Chart Base</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true }} labelId="chart_base-outlined-label" id="chart_base" name="chart_base" value={this.state.chart_base ? this.state.chart_base : "hourly"} onChange={(e) => this.changeFilterHandle(e)} label="Chart Base">
                                                        {this.state.date_range === "today" ? (<MenuItem value="hourly">Hourly</MenuItem>) : (null)}
                                                        {(this.state.date_range === "7_days" || this.state.date_range === "14_days" || this.state.date_range === "30_days" || this.state.date_range === "custom") ? (<MenuItem value="daily">Daily</MenuItem>) : (null)}
                                                        {(this.state.date_range === "14_days" || this.state.date_range === "30_days" || (this.state.date_range === "custom" && this.state.numberOfDays > 14)) ? (<MenuItem value="weekly">Weekly</MenuItem>) : (null)}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Filters End  */}

                            {(this.state.agentId && this.state.agentId !== "all" && this.state.agentId !== "online") ? (<IndividualAgent agentId={this.state.agentId} chart_base={this.state.chart_base} date_range={this.state.date_range} domain={this.state.domain} agentListArray={this.state.agentListArray} agentList={this.state.agentList} start_date={this.state.start_date} end_date={this.state.end_date} numberOfDays={this.state.numberOfDays} applyCustom={this.state.applyCustom} averageAllTotalOnline={this.state.averageAllTotalOnline} selectedAgentOnline={this.state.selectedAgentOnline} avgAgentResTime={this.state.avgAgentResTime} avgAgentChatDuration={this.state.avgAgentChatDuration} avgAllAgentResTime={this.state.avgAllAgentResTime} avgAllAgentChatDuration={this.state.avgAllAgentChatDuration} widgetStatus={this.state.widgetStatus} />) : (<>
                                {this.userRole !== 'Agent' ? (<div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-4">
                                        <div className="bg-white left-widgetsetings-box">
                                            <div id="chart" className="py-5 px-5">
                                                <div className="chartTitle">
                                                    <h5>Agents Online</h5>
                                                </div>
                                                <div className="chart_center_M">
                                                    <ApexCharts options={this.state.options} series={this.state.series} type="bar" height={350} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>) : (null)}
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-4">
                                        <div className="bg-white left-widgetsetings-box">
                                            <div id="chart" className="py-5 px-5">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="chartTitle mb-5">
                                                            <h5>Activity Breakdown</h5>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div style={{ position: "relative" }} className="agent-table mobile-agent-table mobile-tranparent">
                                                            <table className="table table-borderless mb-0 liveCgatUserTable agentslistTbl mob-tbl-view online-agent">
                                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table bg-lightother">
                                                                    <tr>
                                                                        <th>Agent</th>
                                                                        {getHeaders}
                                                                        <th>Total Online</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="border-radius-table">
                                                                    {this.state.sortedAgent.map((agtId, key) => {
                                                                        var value2 = this.state.agentList[agtId] ? this.state.agentList[agtId] : {};
                                                                        if (Object.keys(value2).length === 0) {
                                                                            return null;
                                                                        }
                                                                        let agentName = value2.first_name ? value2.first_name + ' ' + (value2.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value2.last_name : '') : (value2.name ? value2.name : '');
                                                                        try {
                                                                            if (agentName.length > 40) {
                                                                                agentName = agentName.substring(0, 35) + '....';
                                                                            }
                                                                        } catch (rer) { }
                                                                        let isOwner = (value2.roleType && value2.roleType === "Owner") ? true : false;
                                                                        if (this.userRole === 'Owner' || this.userRole === 'Admin') {
                                                                            if (this.state.agentId === "online") {
                                                                                if (this.state.totalAgentsOnline[agtId]) {
                                                                                    return <tr key={key}>
                                                                                        <td><p onClick={() => this.goToAgent(agtId)} className="small text-screen1 agent-online-names">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /></span>) : (null)}</p>
                                                                                        </td>
                                                                                        {this.state.onlineAgentsColumns.map((column, key2) => {
                                                                                            return <td key={key + "_" + key2}>
                                                                                                {this.state.tmpAgentOnlineData[agtId] && this.state.tmpAgentOnlineData[agtId][column.index] ? (<div onMouseOut={this.hideTooltip} onMouseOver={(e) => this.showTooltip(e, agtId, column)} className="agent_online_bar"></div>) : (null)}
                                                                                            </td>
                                                                                        })}
                                                                                        <td>{this.state.totalAgentsOnline[agtId] ? this.secondsToHms(this.state.totalAgentsOnline[agtId]) : 0} </td>
                                                                                    </tr>
                                                                                }
                                                                            } else {
                                                                                return <tr key={key}>
                                                                                    <td><p onClick={() => this.goToAgent(agtId)} className="small text-screen1 agent-online-names">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /></span>) : (null)}</p>
                                                                                    </td>
                                                                                    {this.state.onlineAgentsColumns.map((column, key2) => {
                                                                                        return <td key={key + "_" + key2}>
                                                                                            {this.state.tmpAgentOnlineData[agtId] && this.state.tmpAgentOnlineData[agtId][column.index] ? (<div onMouseOut={this.hideTooltip} onMouseOver={(e) => this.showTooltip(e, agtId, column)} className="agent_online_bar"></div>) : (null)}
                                                                                        </td>
                                                                                    })}
                                                                                    <td>{this.state.totalAgentsOnline[agtId] ? this.secondsToHms(this.state.totalAgentsOnline[agtId]) : 0} </td>
                                                                                </tr>
                                                                            }
                                                                        } else {
                                                                            if (this.agent.agentId === agtId) {
                                                                                return <tr key={key}>
                                                                                    <td><p onClick={() => this.goToAgent(agtId)} className="small text-screen1 agent-online-names">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /></span>) : (null)}</p>
                                                                                    </td>
                                                                                    {this.state.onlineAgentsColumns.map((column, key2) => {
                                                                                        return <td key={key + "_" + key2}>
                                                                                            {this.state.tmpAgentOnlineData[agtId] && this.state.tmpAgentOnlineData[agtId][column.index] ? (<div onMouseOut={this.hideTooltip} onMouseOver={(e) => this.showTooltip(e, agtId, column)} className="agent_online_bar"></div>) : (null)}
                                                                                        </td>
                                                                                    })}
                                                                                    <td>{this.state.totalAgentsOnline[agtId] ? this.secondsToHms(this.state.totalAgentsOnline[agtId]) : 0} </td>
                                                                                </tr>
                                                                            }
                                                                        }
                                                                    })}

                                                                    {this.state.sortedAgent.length === 0 ? (<tr key="notf"><td colSpan="2">No agent found!</td></tr>) : (null)}
                                                                </tbody>
                                                            </table>

                                                            <div id="tooltipOnlineAgts" className="graph-custom-theme graph-custom-tooltip hover-title" style={{ position: "fixed", left: "0", top: "0" }}></div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></>)}

                        </div>
                    </div>
                </div>
            </div> : <PopUp />}
        </>
        )
    }
}

export default Agentsreport;