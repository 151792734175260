import React from 'react';
// import db from '../../config/firebase';
import { WIDGET_DOMAIN } from '../../config/siteConfig';
import queryString from 'query-string'
import firebaseServices from '../../firebase';

class TestDrive extends React.Component {
    constructor() {
        super();
        this.state = {
            loader: true
        }
    }

    componentDidMount() {
        const urlValues = queryString.parse(window.location.search);
        console.log(urlValues);
        let testWidget = urlValues.widget_type && urlValues.widget_type === 'drive' ? true : false;
        let ownerId = this.props.match.params.ownerIden ? this.props.match.params.ownerIden : false;
        let uid = urlValues.uid ? urlValues.uid : false;
        if(uid) {
            this.loadScript(uid.split('-')[0], uid.split('-')[1]);
        } else if(ownerId && testWidget) {
            console.log(ownerId);
            firebaseServices.db.collection('users').where("organization_alias", "==", ownerId).where("deleted", '==', false).get().then((docs) => {
                let docId;
                if (!docs.empty) {
                    docs.forEach((doc) => {
                        docId = doc.id;
                    })
                    if (docId) {
                        firebaseServices.db.collection('users').doc(docId).collection("chatbots").where("status", '==', 1).limit(1).get().then((cDocs) => {
                            let cDocId;
                            if (!cDocs.empty) {
                                cDocs.forEach((cDoc) => {
                                    cDocId = cDoc.id;
                                })
                                if (docId && cDocId) {
                                    this.loadScript(docId, cDocId);
                                }
                            }
                        })
                    }
                }
            })
        } else {
            this.setState({
                loader: false
            })
        }
    }

    loadScript = (docId, cDocId) => {
        console.log(docId, cDocId);
        let script = document.createElement("script")
        script.id = "appyWidget";
        script.src = WIDGET_DOMAIN + "/widget/buildone.js?cid=" + docId + "-" + cDocId;
        document.getElementsByTagName("head")[0].appendChild(script);
        let firstInterval = setInterval(() => {
            let z = document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890');
            if (z) {
                clearInterval(firstInterval);
                this.openWidget();
                try {
                    z.style.display = "none";
                } catch (err) { }
            }
        }, 100);
    }

    openWidget = () => {
        let intervalVar = setInterval(() => {
            try {
                var x = document.getElementById('Appypie').contentWindow.document.getElementsByClassName('iconBotdynamic');
                var y = document.getElementById('Appypie').contentWindow.document.getElementsByClassName('iconBotdynamicInner');
                if(x && y) {
                    x[0].click();
                    document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890').style.display = "initial";
                    this.setState({
                        loader: false
                    })
                    clearInterval(intervalVar);
                    y[0].click();
                }
            } catch(error){}
        }, 100)
    }

    render() {
        return (
            <>
                <div className="loginBG pos-abs bg-design">
                    <div className="header-customer-side py-3">
                        <div className="container">
                            <h3 className="side-logo">Customer Side</h3>  
                        </div>
                    </div>
                    <div className="container">
                        <div className="main-cont">
                                <h2 className="card-title"> This is how widget will appear in front of your customers and visitors who visit your website. </h2>
                        </div>
                    </div>
                </div>
                {this.state.loader ? (<div className="loading" style={{ display: 'block' }}>Loading&#8230;</div>) : (null)}
            </>
        )
    }
}

export default TestDrive;