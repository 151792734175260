/**
 * @description success page on Onbording screen 
 * @file WidgetSuccessComponent.js
 * @version 1.0
 * @template
 * @author Bineet Kumar Chaubey <bineet@appypiellp.com>  
 * 
 */
import React, { Component } from 'react';
import { validation } from "./../../config/SignupLogin";
// import db from '../../config/firebase';
import firebaseServices from '../../firebase';
// import "@lottiefiles/lottie-player";

class WidgetSuccessComponent extends Component {

    constructor(props){
        super(props);
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state ={
            loader: false
        }

    }


    // w = window.innerWidth;
    // h = window.innerHeight;

    gotoDashBoard = () => {
        window.location = '/'+this.agent.organization_alias;
        return;
    }

    /**
     * component did mount
     */
    componentDidMount = async () =>  {
        this.setState({loader: true });
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        
        if(userDetails && userDetails.ownerId){
			var res = await validation(userDetails.ownerId);
			if (res.valid) {
                const ref = firebaseServices.db.collection('users').doc(userDetails.ownerId)
                ref.get().then(querySnapshot => {
                        
                        if(!querySnapshot.data().widget_status){
                            this.props.history.push("/")
                        }else{
                            this.setState({ loader: false });
                            return 
                        }
                })				
			} else {
                
                this.props.history.push("/login");
                this.setState({ loader: false });
                return
			}
		}else {
            this.setState({ loader: false });
            this.props.history.push("/login");
            return
        }
    }

    render() {
        return (
            <div className="login-container loginBG">
                <div className="verificationCodeSection">


                {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                

                    <div className="container">
                        <div className="login-header">
                            <img className="loginHeaderLogo" src={require('./../../assets/images/appypie-livechat.svg')} />
                        </div>
                    </div>
                    <div className="container ps-relative">
                        <div className="custmize-your-widget pt-10">
                            <div className="container">
                            <div className="verificationCodePara congratulation">
                                <h4>Congratulations! </h4>
                                <h4>You've set up your livechat successfully. </h4>
                            </div>
                            <div className="cong-img text-center py-8">
                                <img className="loginHeaderLogo" src={require('./../../assets/images/congratulation.png')} style={{width:'350px'}} />
                                <p className="pt-5">Now, start engaging with your visitors and customers.</p>
                            </div>
                            <div className="text-center">
                                <button className="btnBlue px-5 py-4 w-200 btn-radius" onClick={()=> this.gotoDashBoard()} >Start Chatting</button>
                            </div>
                            </div>
                        </div>
                    </div>


                    {/* <lottie-player
                        autoplay
                        mode="normal"
                        src="http://localhost:3000/lf20_sTumYD.json"
                        style={{
                            width: `${this.w}px`,
                            position: "absolute",
                            top: -10,
                            left: 100,
                            height: `${this.h}px`
                        }}
                    /> */}

                </div>
            </div>
        );
    }
}

export default WidgetSuccessComponent;