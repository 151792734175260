import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import { toast } from 'react-toastify';
import { activityLog } from '../../services/service';
import firebaseServices from '../../firebase';

class Settings extends React.Component {

    constructor() {
        super();
        this.state = {
            loader:false,
            sessiontimeout:0,
            timeout:'',
            trigger_message:'',
            trigger_time:'',
            trigger_interval:'',
            timeout_error:'',
            trigger_time_error:'',
            trigger_message_error:'',
            trigger_interval_error:'',
            ownerId:''
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId
        });
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection("settings").doc('DEFAULTSETTINGS').get().then((docs) => {
            let data=docs.data();
            this.setState({
                timeout:data.session_timeout,
                trigger_message:data.trigger_message,
                trigger_interval:data.trigger_interval,
                trigger_time:data.trigger_time
            })
        })
        activityLog('Opened timeout settings');
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.value
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validateForm();
        if(validate) {
            this.setState({
                loader:true
            })
            let updatedon=Date.now();
            firebaseServices.db.collection("users").doc(this.state.ownerId).collection("settings").doc('DEFAULTSETTINGS').update({updatedon:updatedon,session_timeout:this.state.timeout,trigger_message:this.state.trigger_message,trigger_time:this.state.trigger_time,trigger_interval:this.state.trigger_interval}).then((ref) => {
                // console.log(ref);
                toast.success("Settings have been Updated Successfully!");
                this.setState({
                    loader:false
                })
            })
        }
    }

    validateForm = () => {
        let validReturn=true;
        if(this.state.timeout==='') {
            validReturn=false;
            this.setState({
                timeout_error:'This Field Can not be blank!'
            })
        } else {
            this.setState({
                timeout_error:''
            })
        }
        if(this.state.trigger_message==='') {
            validReturn=false;
            this.setState({
                trigger_message_error:'This Field Can not be blank!'
            })
        } else {
            this.setState({
                trigger_message_error:''
            })
        }
        if(this.state.trigger_time==='') {
            validReturn=false;
            this.setState({
                trigger_time_error:'This Field Can not be blank!'
            })
        } else {
            this.setState({
                trigger_time_error:''
            })
        }
        if(this.state.trigger_interval==='') {
            validReturn=false;
            this.setState({
                trigger_interval_error:'This Field Can not be blank!'
            })
        } else {
            this.setState({
                trigger_interval_error:''
            })
        }

        return validReturn;
    }

    cancelFormSubmit = (e) => {
        e.preventDefault();
        this.componentDidMount();
    }


    render() {
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Settings</h3>
            </header>
            <LeftPanel />
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Settings</h3>
                    </div>
                    <div className="body-section bg-white px-5 py-5">
                        <div className="agent-section">
                            <div className="row">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Session Auto Timeout Time(In Minutes)</label>
                                        <input type="number" min="1" placeholder="Time in Minutes" className="form-control" name="timeout" onChange={this.handleChange} value={this.state.timeout} />
                                        <p className="error">{this.state.timeout_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Trigger Message if agent not picking up chat</label>
                                        <input type="text" placeholder="Enter Message*" className="form-control" name="trigger_message" onChange={this.handleChange} value={this.state.trigger_message} />
                                        <p className="error">{this.state.trigger_message_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Trigger Message Time(In Seconds)</label>
                                        <input type="number" placeholder="Enter Trigger Message Time*" className="form-control" name="trigger_time" onChange={this.handleChange} value={this.state.trigger_time} />
                                        <p className="error">{this.state.trigger_time_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Trigger Message Time Interval(In Seconds)</label>
                                        <input type="number" placeholder="Trigger Message Time Interval" className="form-control" name="trigger_interval" onChange={this.handleChange} value={this.state.trigger_interval} />
                                        <p className="error">{this.state.trigger_interval_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Update</button>
                                        <button className="btnWhite" onClick={this.cancelFormSubmit}>Cancel</button>
                                    </div>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default Settings;