// import db from'../config/firebase';
import firebaseServices from '../firebase';
import { getDateInUTC } from "../modules/livechat/components/Comman";
import moment from "moment-timezone"; 

export const getOwnerSnapShot = (ownerId, response) => {
    if(ownerId) {
        firebaseServices.db.collection('users').doc(ownerId).onSnapshot(async(docs) => {
            if(docs.exists) {
                let _data = docs.data();
                let paymentStatus = false;
                if(_data.payment_status === 'complete' || _data.payment_status === 'trial' || _data.payment_status === "trialWithoutCC" ) {
                    paymentStatus = true;
                } else {
                    let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
                    if(nextBillingDate) {
                        let serverTimeStamp = await getDateInUTC();
                        let splitDate = nextBillingDate.split('-');
                        let splitDay = parseInt(splitDate[2]);
                        var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                        paymentDate = paymentDate + 86400000;
                        if(paymentDate < serverTimeStamp) {
                            paymentStatus = false;
                        } else {
                            paymentStatus = true;
                        }
                    } else {
                        paymentStatus = false;
                    }
                }
                _data.paymentStatus = paymentStatus;
                let returnValue = { status : 200, data : _data };
                response(returnValue);
            } else {
                let returnValue = { status: 400 }
                response(returnValue);
            }
        })
    } else {
        let returnValue = { status: 500 }
        response(returnValue);
    }
}

export const checkPaymentStatus = (_data) => {
    return new Promise(async(resolve, reject) => {
        let paymentStatus = false;
        if (_data.payment_status === 'complete' || (_data.payment_status === 'trial' && _data.plan_price !== 'trialWithoutCC') ||_data.payment_status === 'trialWithoutCC') {
            paymentStatus = true;
        } else {
            let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
            if (nextBillingDate) {
                let serverTimeStamp = await getDateInUTC();
                let splitDate = nextBillingDate.split('-');
                let splitDay = parseInt(splitDate[2]) + 1;
                var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                if (paymentDate < serverTimeStamp) {
                    paymentStatus = false;
                } else {
                    paymentStatus = true;
                }
            } else {
                paymentStatus = false;
            }
        }
        _data.paymentStatus = paymentStatus;
        resolve(paymentStatus);
    })
}

/* Added By AG Start */

export const getPaymentStatus = (_data) => {
    return new Promise( async resolve => {
        let agentTimeZone = localStorage.getItem("agentTimeZone") ? JSON.parse(localStorage.getItem('agentTimeZone')) : false;
        let paymentStatus = false;
        if (_data.payment_status === 'complete') {
            paymentStatus = true;
        } else {
            let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
            if (nextBillingDate) {
                let serverTimeStamp;
                if(!agentTimeZone) {
                    serverTimeStamp = parseInt(agentTimeZone.serverTime);
                } else {
                    serverTimeStamp = await getDateInUTC();
                }
                let splitDate = nextBillingDate.split('-');
                let splitDay = parseInt(splitDate[2]);
                var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                paymentDate = paymentDate + 86400000;
                if (paymentDate < serverTimeStamp) {
                    paymentStatus = false;
                } else {
                    paymentStatus = true;
                }
            } else {
                paymentStatus = false;
            }
        }
        resolve(paymentStatus);
    });
}

/* Added By AG End */
