import React from "react";
import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import LeftPanel from "./views/LeftPanel";
import ApexCharts from "react-apexcharts";
import moment from "moment-timezone";
// import db from "../../config/firebase";
import {
  MAIL_FUNCTION_URL,
  FUNCTION_API_KEY,
  GATEWAY_URL,
} from "../../config/siteConfig";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { addDays } from "date-fns";
import { activityLog, checkWidgetStatus } from "../../services/service";
import dummyData from "../../localization/dummy-data.json";
import { getDateInUTC } from "../livechat/components/Comman";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import qs from "query-string";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { countryListAlpha2 } from "../../store/country";
import { Tooltip } from "@material-ui/core";
import { getOwnerSnapShot } from "../../services/payment";
import PopUp from "../../layout/components/popup";

const conf_chart = {
  type: "bar",
  height: 350,
  stacked: true,
  toolbar: { show: false },
  zoom: { enabled: false },
};
const conf_responsive = [
  {
    breakpoint: 480,
    options: { legend: { position: "bottom", offsetX: -10, offsetY: 0 } },
  },
];
const conf_plot_options = { bar: { borderRadius: 8, horizontal: false } };
const conf_legend = { position: "bottom", offsetX: -10, offsetY: 0 };
const conf_fill = { opacity: 1, colors: ["#77c66d", "#eb867d"] };
const DATE_FORMATES = {
  miliseconds: "x",
  hour: "hh A",
  day: "DD MMM",
  hours_start: "ddd, MMM DD YYYY hh:00 A",
  hours_end: "hh:00 A",
  day_start: "ddd, MMM DD YYYY",
};

class Analytics extends React.Component {
  constructor() {
    super();
    this.agent = localStorage.getItem("agent")
      ? JSON.parse(localStorage.getItem("agent"))
      : {};
    this.state = {
      payment_status: true,
      loader: true,
      currentchats: [],
      previouschats: [],
      domain_list: [],
      date_range: "today",
      chart_base: "hourly",
      domain: "all",
      enabled_country_filter: false,
      start_date: "",
      end_date: "",
      fill_start_date: addDays(new Date(), -7),
      fill_end_date: new Date(),
      numberOfDays: 0,
      showDateRange: false,
      chats_total: 0,
      chats_served: 0,
      chats_missed: 0,
      prev_chats_total: 0,
      prev_chats_served: 0,
      prev_chats_missed: 0,
      tooltip_total_chats: "",
      percentage_total_chats: 0,
      tooltip_served_chats: "",
      percentage_served_chats: 0,
      tooltip_missed_chats: "",
      percantage_missed_chats: 0,
      tooltip_nps_rating: "",
      npscurrentAndPrev: "",
      total_rated_chats: 0,
      showSatisfaction: false,
      chatRatedPercentage: 0,
      data_offset: 0,
      isAllLoaded: false,
      series: [
        {
          name: "Chats completed",
          color: "#77c66d",
          data: [],
        },
        {
          name: "Chats missed",
          color: "#eb867d",
          data: [],
        },
      ],
      options: {
        chart: conf_chart,
        responsive: conf_responsive,
        plotOptions: conf_plot_options,
        xaxis: {
          type: "",
          categories: [],
        },
        legend: conf_legend,
        fill: conf_fill,
        colors: ["#77c66d", "#eb867d"],
        dataLabels: {
          enabled: false,
          style: {
            colors: ["#000", "#000"],
          },
        },
        tooltip: {
          enabled: true,
          custom: this.chatCountToolTip,
        },
        title: {
          // text: 'Chat Count',
          align: "left",
        },
      },
      chatTiming_series: [
        {
          name: "Response Time",
          color: "#77c66d",
          data: [],
        },
        {
          name: "Chat Duration",
          data: [],
        },
        {
          name: "Wait Time (Completed)",
          data: [],
        },
        {
          name: "Wait Time (Missed)",
          data: [],
        },
      ],
      chatTiming_options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#7CC771", "#EBBB58", "#71B2E3", "#E3706A"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          // text: 'Chat Timings',
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 5,
        },
        xaxis: {
          type: "category",
          convertedCatToNumeric: false,
          categories: [],
          /*title: {
                        text: 'Month'
                    } */
        },
        /*yaxis: {
                    title: {
                        text: 'Temperature'
                    },
                    min: 5,
                    max: 100
                },*/
        legend: {
          position: "bottom",
          //horizontalAlign: 'right',
          //floating: true,
          offsetY: 0, // -25,
          offsetX: -10, //-5
        },
        tooltip: {
          enabled: true,
          custom: this.chatTimingsToolTip,
        },
      },
      response_options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ["#7CC771"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "end",
          offsetX: 250,
          offsetY: 0,
          style: {
            fontSize: "14px",
            colors: ["#8e8e8e"],
          },
        },
        xaxis: {
          categories: ["<15s", "15s-30s", "31s-45s", "46s-1m", ">1m"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      response_series: [
        {
          name: "Number of Chats",
          data: [],
        },
      ],
      chatduration_options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ["#EBBB58"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "end",
          offsetX: 250,
          offsetY: 0,
          style: {
            fontSize: "14px",
            colors: ["#8e8e8e"],
          },
        },
        xaxis: {
          categories: ["<10m", "10m-30m", "31m-1h", "1h-1.5h", ">1.5h"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      chatduration_series: [
        {
          name: "Number of Chats",
          data: [],
        },
      ],
      waittimeserved_options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ["#71B2E3"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "end",
          offsetX: 250,
          offsetY: 0,
          style: {
            fontSize: "14px",
            colors: ["#8e8e8e"],
          },
        },
        xaxis: {
          categories: ["<15s", "15s-30s", "31s-45s", "46s-1m", ">1m"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      waittimeserved_series: [
        {
          name: "Number of Chats",
          data: [],
        },
      ],
      waittimemissed_options: {
        chart: {
          type: "bar",
          height: 350,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        colors: ["#E3706A"],
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "end",
          offsetX: 250,
          offsetY: 0,
          style: {
            fontSize: "14px",
            colors: ["#8e8e8e"],
          },
        },
        xaxis: {
          categories: ["<15s", "15s-30s", "31s-45s", "46s-1m", ">1m"],
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          axisBorder: {
            show: false,
          },
          crosshairs: {
            show: false,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      waittimemissed_series: [
        {
          name: "Number of Chats",
          data: [],
        },
      ],
      satisfaction_series: [
        {
          name: "Chats rated",
          data: [],
        },
      ],
      satisfaction_options: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#6675be"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Satisfaction",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 5,
        },
        xaxis: {
          categories: ["A", "B", "C"],
        },
        legend: {
          position: "bottom",
          offsetY: 0, // -25,
          offsetX: -10, //-5
        },
        tooltip: {
          enabled: true,
          custom: this.satisfactionToolTip,
        },
      },
      nps_series: [],
      nps_options: {
        chart: {
          width: 380,
          type: "donut",
        },
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "65%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 300,
                  color: "#77c66d",
                  offsetY: 30,
                  /*formatter: function (val) {
                                      return val
                                    }*/
                },
                value: {
                  show: true,
                  fontSize: "30px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 400,
                  color: "#77c66d",
                  offsetY: -10,
                  /*formatter: function (val) {
                                      return val
                                    }*/
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Satisfaction",
                  fontSize: "22px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 300,
                  color: "#77c66d",
                  formatter: function (w) {
                    return w.globals.seriesTotals[0] + "%";
                  },
                },
              },
            },
          },
        },
        colors: ["#77c66d", "#eb867d"],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        labels: ["Good", "Bad"],
        // title: {
        //     // text: 'NPS',
        //     align: 'left'
        // },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"],
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        legend: {
          show: true,
          position: "bottom",
          /*markers: {
                        width: 24,
                        height: 24,
                        strokeWidth: 10,
                        //strokeColor: '#fff',
                        fillColors: false,
                        //radius: 12,
                        customHTML : function() {
                            return '<span>Al</span>';
                        },
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    }, */
          offsetY: 0, // -25,
          offsetX: -10, //-5
        },
      },
      showDummyData: false,
      data_type: 2,
      country: "all",
    };
  }

  getDomainsList = () => {
    return false;
    /*  db.collection('reports').doc('widget').collection('installation').doc(this.agent.ownerId).collection('domains').get().then((domains) => {
             if (!domains.empty) {
                 let allDomains = [];
                 domains.forEach((domain) => {
                     allDomains.push(domain.data().domain);
                 })
                 this.setState({
                     domain_list: allDomains
                 });
             }
         }) */
  };

  chatCountToolTip = (e) => {
    //let seriesIndex = e.seriesIndex;
    let dataPointIndex = e.dataPointIndex;
    let w = e.w ? e.w : {};
    let config = w.config ? w.config : {};
    let series = config.series ? config.series : {};
    let xaxis = config.xaxis ? config.xaxis : {};
    let categories = xaxis.categories ? xaxis.categories : [];
    let xVal = categories[dataPointIndex];
    let fDate = new Date(xVal);
    let heading;
    if (this.agent.org_timezone && this.agent.org_timezone !== "") {
      if (this.state.chart_base === "hourly") {
        let fDate2 = xVal + 3600000; /// Add 1 Hour
        heading =
          moment(xVal, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    } else {
      if (this.state.chart_base === "hourly") {
        let fDate2 = new Date(fDate);
        fDate2.setHours(fDate2.getHours() + 1);
        heading =
          moment(fDate).format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    }
    var str = "<h5>" + heading + "</h5>";
    for (var i = 0; i < series.length; i++) {
      str +=
        "<p><span>" +
        series[i].name +
        '</span>: <span style= "background-color:' +
        series[i].color +
        '" >' +
        series[i].data[dataPointIndex] +
        "</span></p>";
    }
    return str;
  };

  chatTimingsToolTip = (e) => {
    //let seriesIndex = e.seriesIndex;
    let dataPointIndex = e.dataPointIndex;
    let w = e.w ? e.w : {};
    let config = w.config ? w.config : {};
    let series = config.series ? config.series : {};
    let xaxis = this.state.options.xaxis ? this.state.options.xaxis : {};
    let categories = xaxis.categories ? xaxis.categories : [];
    let xVal = categories[dataPointIndex];
    let fDate = new Date(xVal);
    let heading;
    if (this.agent.org_timezone && this.agent.org_timezone !== "") {
      if (this.state.chart_base === "hourly") {
        let fDate2 = xVal + 3600000; /// Add 1 Hour
        heading =
          moment(xVal, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    } else {
      if (this.state.chart_base === "hourly") {
        let fDate2 = new Date(fDate);
        fDate2.setHours(fDate2.getHours() + 1);
        heading =
          moment(fDate).format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    }
    var str = "<h5>" + heading + "</h5>";
    for (var i = 0; i < series.length; i++) {
      if (series[i].data.length > 0) {
        let tmpMin = parseInt(series[i].data[dataPointIndex]);
        if (tmpMin > 60) {
          tmpMin = tmpMin / 60;
          if (Math.floor(tmpMin) > 1) {
            tmpMin = tmpMin.toFixed(2) + " Hours";
          } else {
            tmpMin = tmpMin.toFixed(2) + " Hour";
          }
        } else {
          tmpMin += tmpMin > 1 ? " Minutes" : " Minute";
        }
        str +=
          "<p><span>" +
          series[i].name +
          '</span>: <span style= "background-color:' +
          series[i].color +
          '" >' +
          tmpMin +
          "</span></p>";
      }
    }
    return str;
  };

  satisfactionToolTip = (e) => {
    let dataPointIndex = e.dataPointIndex;
    let w = e.w ? e.w : {};
    let config = w.config ? w.config : {};
    let series = config.series ? config.series : {};
    let xaxis = this.state.options.xaxis ? this.state.options.xaxis : {};
    let categories = xaxis.categories ? xaxis.categories : [];
    let xVal = categories[dataPointIndex];
    let fDate = new Date(xVal);
    let heading;
    if (this.agent.org_timezone && this.agent.org_timezone !== "") {
      if (this.state.chart_base === "hourly") {
        let fDate2 = xVal + 3600000; /// Add 1 Hour
        heading =
          moment(xVal, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2, "x", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    } else {
      if (this.state.chart_base === "hourly") {
        let fDate2 = new Date(fDate);
        fDate2.setHours(fDate2.getHours() + 1);
        heading =
          moment(fDate).format(DATE_FORMATES.hours_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.hours_end);
      } else if (this.state.chart_base === "weekly") {
        let fDate2 = new Date(fDate);
        fDate2.setDate(fDate2.getDate() + 6);
        heading =
          moment(fDate).format(DATE_FORMATES.day_start) +
          "-" +
          moment(fDate2).format(DATE_FORMATES.day_start);
      } else {
        heading = moment(fDate).format(DATE_FORMATES.day_start);
      }
    }
    var str = "<h5>" + heading + "</h5>";
    for (var i = 0; i < series.length; i++) {
      if (series[i].data.length > 0) {
        str +=
          "<p><span>" +
          series[i].name +
          '</span>: <span style= "background-color:' +
          series[i].color +
          '" >' +
          series[i].data[dataPointIndex] +
          "</span></p>";
      }
    }
    return str;
  };

  formatXAxis = (category) => {
    if (this.agent.org_timezone && this.agent.org_timezone !== "") {
      if (
        this.state.date_range === "today" ||
        this.state.date_range === "yesterday"
      ) {
        return moment(category, "x", this.agent.org_timezone)
          .tz(this.agent.org_timezone)
          .format(DATE_FORMATES.hour);
      } else if (this.state.date_range === "custom") {
        return moment(category, "x").format(DATE_FORMATES.day);
      } else {
        return moment(category, "x", this.agent.org_timezone)
          .tz(this.agent.org_timezone)
          .format(DATE_FORMATES.day);
      }
    } else {
      if (
        this.state.date_range === "today" ||
        this.state.date_range === "yesterday"
      ) {
        return moment(category).format(DATE_FORMATES.hour);
      } else {
        return moment(category).format(DATE_FORMATES.day);
      }
    }
  };

  componentDidUpdate(prevProps) {
    ReactTooltip.rebuild();
  }

  async componentDidMount() {
    const urlValues = qs.parse(window.location.search);
    const data_type = urlValues.data_type ? parseInt(urlValues.data_type) : 0; //return 0
    let userDetails = JSON.parse(localStorage.getItem('agent'));
    if(userDetails.ownerId) {
        getOwnerSnapShot(userDetails.ownerId, async (results) => {
            if (results.status === 200) {
                let _data = results.data;
                this.setState({
                    payment_status:  _data.payment_status !== undefined  ? true : false
                })
            } else {
                this.setState({
                    payment_status: false
                })
                
            }
        })
    }
    const orgTimezone = this.agent.org_timezone ? this.agent.org_timezone : "";
    if (data_type !== 2 && (data_type === 1 || orgTimezone !== "")) {
      this.setState({ data_type: 2 }); //change data_type=2 from data_type=1
    }
    let widgetStatus = await checkWidgetStatus();
    this.setState({
      widgetStatus: widgetStatus,
    });
    if (widgetStatus === true) {
      this.getDomainsList();
      this.getchats();
    } else {
      this.loadDummyData();
    }
    activityLog("Opened analytics page");
  }

  loadDummyData = async () => {
    let analyticsData = dummyData.Analytics;
    let serverTimeStamp = await getDateInUTC();
    let counterValue = serverTimeStamp;
    var currentChats = [];
    analyticsData.current.forEach((data) => {
      data.addedon = counterValue;
      counterValue = counterValue - 30 * 60 * 1000;
      currentChats.push(data);
    });

    this.setState(
      {
        currentchats: currentChats,
        previouschats: analyticsData.previous,
        showDummyData: true,
      },
      () => {
        this.loadChats();
      }
    );
  };

  changeCountryHandle = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        chart_base: this.state.chart_base,
        isAllLoaded: false,
        data_offset: 0,
      },
      () => {
        this.getchats();
      }
    );
  };

  changeFilterHandle = (e) => {
    if (
      this.state.widgetStatus === false &&
      this.state.showDummyData === true
    ) {
      NotificationManager.error(
        "",
        "Filter will not work for sample report",
        5000
      );
      return;
    }

    if (e.target.name === "date_range" && e.target.value === "custom") {
      this.setState({
        [e.target.name]: e.target.value,
        chart_base: "daily",
        showDateRange: true,
      });
      return false;
    } else {
      this.setState({ showDateRange: false });
    }
    var chartBaseVal = "";
    if (
      e.target.name === "date_range" &&
      (e.target.value === "14_days" || e.target.value === "7_days")
    ) {
      chartBaseVal = "daily";
    } else if (
      e.target.name === "date_range" &&
      (e.target.value === "today" || e.target.value === "yesterday")
    ) {
      chartBaseVal = "hourly";
    } else if (e.target.name === "date_range" && e.target.value === "30_days") {
      chartBaseVal = "weekly";
    } else if (e.target.name === "domain") {
      chartBaseVal = "domain";
    }
    if (
      e.target.name === "date_range" &&
      ["30_days", "14_days", "custom"].includes(e.target.value)
    ) {
      this.setState({
        enabled_country_filter: true,
        data_type: 1,
        country: "all",
      });
    } else if (
      e.target.name === "date_range" &&
      ["today", "yesterday", "7_days"].includes(e.target.value)
    ) {
      this.setState({ enabled_country_filter: false, data_type: 2 });
    }

    if (chartBaseVal !== "" && chartBaseVal !== "domain") {
      this.setState(
        {
          [e.target.name]: e.target.value,
          chart_base: chartBaseVal,
          isAllLoaded: false,
          data_offset: 0,
        },
        () => {
          this.getchats();
        }
      );
    } else if (chartBaseVal !== "" && chartBaseVal === "domain") {
      this.setState(
        { [e.target.name]: e.target.value, isAllLoaded: false, data_offset: 0 },
        () => {
          this.getchats();
        }
      );
    } else {
      this.setState({ [e.target.name]: e.target.value, loader: true }, () => {
        if (this.state.data_type === 1) {
          this.loadGraphChats();
        } else {
          this.loadChats();
        }
      });
    }
  };

  onDatesSelect = (ranges) => {
    this.setState({
      fill_start_date: ranges.selection.startDate,
      fill_end_date: ranges.selection.endDate,
    });
  };

  applyDateRange = () => {
    let tmpstDate = this.state.fill_start_date;
    let stDate = moment(tmpstDate).format(DATE_FORMATES.miliseconds);
    let tmpedDate = this.state.fill_end_date;
    tmpedDate.setHours(23, 59, 0, 0);
    let edDate = moment(tmpedDate).format(DATE_FORMATES.miliseconds);

    let diffDate = edDate - stDate;
    diffDate = Math.ceil(diffDate / (1000 * 3600 * 24));
    if (diffDate > 92) {
      toast.error("You can select date within 3 months only!");
      return false;
    } else {
      this.setState(
        {
          showDateRange: false,
          start_date: stDate,
          end_date: edDate,
          numberOfDays: diffDate,
          isAllLoaded: false,
          data_offset: 0,
        },
        () => {
          this.getchats();
        }
      );
    }
  };

  cancelDateRange = () => {
    this.setState({ showDateRange: false });
  };

  showHideDatePicker = () => {
    this.setState({ showDateRange: !this.state.showDateRange });
  };

  /* For Graph API Start */

  callGraphChatAPI = (body) => {
    return new Promise((resolve) => {
      //fetch(MAIL_FUNCTION_URL + "/analytics/getGraphChats", {
      fetch(GATEWAY_URL + "/getGraphChats", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.status === "success") {
            resolve(response);
          } else {
            resolve({ data: { current: [], previous: [] } });
          }
        })
        .catch((err) => {
          resolve({ data: { current: [], previous: [] } });
        });
    });
  };

  loadGraphChats = () => {
    var currentchats = this.state.currentchats;
    var previouschats = this.state.previouschats;

    if (currentchats.length > 0) {
      currentchats = currentchats.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.chat_hours === v.chat_hours &&
              t.chat_date.value === v.chat_date.value
          ) === i
      );
    }
    if (previouschats.length > 0) {
      previouschats = previouschats.filter(
        (v, i, a) =>
          a.findIndex(
            (t) =>
              t.chat_hours === v.chat_hours &&
              t.chat_date.value === v.chat_date.value
          ) === i
      );
    }

    var options = this.state.options;
    var series = this.state.series;
    var chatTiming_options = this.state.chatTiming_options;
    var chatTiming_series = this.state.chatTiming_series;
    var response_series = this.state.response_series;
    var waittimeserved_series = this.state.waittimeserved_series;
    var chatduration_series = this.state.chatduration_series;
    var waittimemissed_series = this.state.waittimemissed_series;
    var satisfaction_options = this.state.satisfaction_options;
    var satisfaction_series = this.state.satisfaction_series;
    var nps_series = this.state.nps_series;
    var nps_options = this.state.nps_options;
    this.setState({
      series: [],
      options: {},
      chatTiming_options: {},
      chatTiming_series: [],
      response_series: [],
      waittimeserved_series: [],
      chatduration_series: [],
      waittimemissed_series: [],
      satisfaction_options: {},
      satisfaction_series: [],
      nps_series: [],
      nps_options: {},
      total_rated_chats: 0,
      showSatisfaction: false,
    });

    /* For charting start */

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let xaxis_categories = [];
    let xaxis_type = "category"; // category, datetime, numeric
    let series_data_served = [];
    let series_data_missed = [];
    let tmpCurrServedChats = [];
    let tmpCurrMissedChats = [];

    let tmpResponseTime = [];
    let tmpChatDuration = [];
    let tmpWaitTimeServed = [];
    let tmpWaitTimemissed = [];
    let series_response_time = [];
    let series_chat_duration = [];
    let series_wait_time_served = [];
    let series_wait_time_missed = [];

    let response_lessthan15 = 0;
    let response_inbtw15_30 = 0;
    let response_inbtw31_45 = 0;
    let response_inbtw46_60 = 0;
    let response_greaterthan60 = 0;

    let wtime_served_lessthan15 = 0;
    let wtime_served_inbtw15_30 = 0;
    let wtime_served_inbtw31_45 = 0;
    let wtime_served_inbtw46_60 = 0;
    let wtime_served_greaterthan60 = 0;

    let duration_lessthan10 = 0;
    let duration_inbtw10_30 = 0;
    let duration_inbtw31_60 = 0;
    let duration_inbtw1h_15 = 0;
    let duration_greaterthan15 = 0;

    let mtime_served_lessthan15 = 0;
    let mtime_served_inbtw15_30 = 0;
    let mtime_served_inbtw31_45 = 0;
    let mtime_served_inbtw46_60 = 0;
    let mtime_served_greaterthan60 = 0;

    let tmpRatings = [];
    let series_ratings = [];
    let showSatisfaction = false;
    let tmpCategories = [];
    let tmpPrevRatings = [];

    let sumCurrChatMissed = 0;
    let sumCurrChatServed = 0;
    let sumPrevChatServed = 0;
    let sumPrevChatMissed = 0;
    let sumRatings = 0;
    let totalChatsrated = 0;
    let sumPrevRatings = 0;
    let totalPrevChatsrated = 0;

    if (this.state.chart_base === "daily") {
      if (currentchats.length > 0) {
        currentchats.forEach((schat) => {
          let createIndex = schat.chat_date.value;
          if (tmpCurrServedChats[createIndex] === undefined) {
            tmpCurrServedChats[createIndex] = [];
          }
          if (tmpCurrMissedChats[createIndex] === undefined) {
            tmpCurrMissedChats[createIndex] = [];
          }
          if (tmpResponseTime[createIndex] === undefined) {
            tmpResponseTime[createIndex] = [];
          }
          if (tmpChatDuration[createIndex] === undefined) {
            tmpChatDuration[createIndex] = [];
          }
          if (tmpWaitTimeServed[createIndex] === undefined) {
            tmpWaitTimeServed[createIndex] = [];
          }
          if (tmpWaitTimemissed[createIndex] === undefined) {
            tmpWaitTimemissed[createIndex] = [];
          }
          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          tmpCurrServedChats[createIndex].push(schat.served_chats);
          tmpCurrMissedChats[createIndex].push(schat.missed_chats);
          tmpResponseTime[createIndex].push(schat.response_time);
          tmpChatDuration[createIndex].push(schat.chat_duration);
          tmpWaitTimeServed[createIndex].push(schat.wait_time);
          tmpWaitTimemissed[createIndex].push(schat.wait_time_missed);
          tmpRatings[createIndex].push(schat.chats_rated);

          sumCurrChatServed += schat.served_chats;
          sumCurrChatMissed += schat.missed_chats;

          if (schat.ratings_sum > 0) {
            sumRatings += schat.ratings_sum;
          }

          if (schat.chats_rated > 0) {
            totalChatsrated += schat.chats_rated;
          }

          /* Response time chats start */
          if (schat.res1 > 0) {
            response_lessthan15 += schat.res1;
          }
          if (schat.res2 > 0) {
            response_inbtw15_30 += schat.res2;
          }
          if (schat.res3 > 0) {
            response_inbtw31_45 += schat.res3;
          }
          if (schat.res4 > 0) {
            response_inbtw46_60 += schat.res4;
          }
          if (schat.res5 > 0) {
            response_greaterthan60 += schat.res5;
          }
          /* Response time chats end */

          /* Wait time served chats start */
          if (schat.wt1 > 0) {
            wtime_served_lessthan15 += schat.wt1;
          }
          if (schat.wt2 > 0) {
            wtime_served_inbtw15_30 += schat.wt2;
          }
          if (schat.wt3 > 0) {
            wtime_served_inbtw31_45 += schat.wt3;
          }
          if (schat.wt4 > 0) {
            wtime_served_inbtw46_60 += schat.wt4;
          }
          if (schat.wt5 > 0) {
            wtime_served_greaterthan60 += schat.wt5;
          }
          /* Wait time served chats end */

          /* chat duration start */
          if (schat.dur1 > 0) {
            duration_lessthan10 += schat.dur1;
          }
          if (schat.dur2 > 0) {
            duration_inbtw10_30 += schat.dur2;
          }
          if (schat.dur3 > 0) {
            duration_inbtw31_60 += schat.dur3;
          }
          if (schat.dur4 > 0) {
            duration_inbtw1h_15 += schat.dur4;
          }
          if (schat.dur5 > 0) {
            duration_greaterthan15 += schat.dur5;
          }
          /* chat duration end */

          /* Wait time missed chats start */
          if (schat.wtm1 > 0) {
            mtime_served_lessthan15 += schat.wtm1;
          }
          if (schat.wtm2 > 0) {
            mtime_served_inbtw15_30 += schat.wtm2;
          }
          if (schat.wtm3 > 0) {
            mtime_served_inbtw31_45 += schat.wtm3;
          }
          if (schat.wtm4 > 0) {
            mtime_served_inbtw46_60 += schat.wtm4;
          }
          if (schat.wtm5 > 0) {
            mtime_served_greaterthan60 += schat.wtm5;
          }
          /* Wait time missed chats end */
        });
      }

      if (previouschats.length > 0) {
        previouschats.forEach((schat) => {
          sumPrevChatServed += schat.served_chats;
          sumPrevChatMissed += schat.missed_chats;
          if (schat.ratings_sum > 0) {
            sumPrevRatings += schat.ratings_sum;
          }
          if (schat.chats_rated > 0) {
            totalPrevChatsrated += schat.chats_rated;
          }
        });
      }
    } else if (this.state.chart_base === "weekly") {
      if (this.state.date_range === "custom") {
        let stttDate = parseInt(this.state.start_date);
        let stttEndDate = parseInt(this.state.end_date);
        let endDateFormat = moment(stttEndDate).format("YYYY-MM-DD");
        let tmpEndDate222 = moment(endDateFormat)
          .parseZone()
          .tz(this.agent.org_timezone, true);
        let endDateTimestamp = parseInt(tmpEndDate222.format("x"));
        for (var i = 0; i < this.state.numberOfDays; i++) {
          let tmpdate = moment(stttDate).add(i, "days");
          let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
          let datt = tmpdate.format("DD");
          let month = tmpdate.format("MM");
          let year = tmpdate.format("YYYY");
          let dayOfWeek = parseInt(tmpdate.format("d"));
          let dtTimestamp = parseInt(
            moment(tmpDateOnly)
              .parseZone()
              .tz(this.agent.org_timezone, true)
              .format("x")
          );

          let totalWeekDays = 6 - dayOfWeek;
          i = i + totalWeekDays;

          let tmpdate22 = moment(stttDate).add(i, "days");

          //let toDate22;
          let gtTime22 = dtTimestamp;

          if (gtTime22 > endDateTimestamp) {
            tmpdate22 = tmpEndDate222;
            //toDate22 = endDateTimestamp;
          } else {
            let tmpDateOnly22 = tmpdate22.format("YYYY-MM-DD");
            //toDate22 = parseInt(moment(tmpDateOnly22).parseZone().tz(this.agent.org_timezone, true).format("x"));
          }
          let datt22 = tmpdate22.format("DD");
          let month22 = tmpdate22.format("MM");
          let year22 = tmpdate22.format("YYYY");
          let createIndex =
            datt +
            "-" +
            datt22 +
            "_" +
            month +
            "-" +
            month22 +
            "_" +
            year +
            "-" +
            year22;

          tmpCategories.push({
            fromDateMS: dtTimestamp,
            fromDate: year + "-" + month + "-" + datt,
            toDate: year22 + "-" + month22 + "-" + datt22,
            day: createIndex,
          });

          /* let stttDate = parseInt(this.state.start_date);
                    let stttEndDate = parseInt(this.state.end_date);
                    for (var i = 0; i < this.state.numberOfDays; i++) {
                        let tmpdate = new Date(stttDate);
                        tmpdate.setDate(tmpdate.getDate() + i);
                        let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                        let datt = tmpdate.getDate().toString().padStart(2, "0");
                        let month = (tmpdate.getMonth() + 1).toString().padStart(2, "0");
                        let year = tmpdate.getFullYear();
                        let totalWeekDays = 6 - dayOfWeek;
                        i = i + totalWeekDays;
                        let tmpdate22 = new Date(stttDate);
                        tmpdate22.setDate(tmpdate22.getDate() + i);
                        let toDate22;
                        let gtTime22 = tmpdate22.getTime();
                        if (gtTime22 > stttEndDate) {
                            tmpdate22 = new Date(stttEndDate);
                            toDate22 = stttEndDate;
                        } else {
                            toDate22 = (gtTime22 + 86399000);
                        }
                        let datt22 = tmpdate22.getDate().toString().padStart(2, "0");
                        let month22 = (tmpdate22.getMonth() + 1).toString().padStart(2, "0");
                        let year22 = tmpdate22.getFullYear();
                        let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                        let tmpdate33 = new Date(toDate22);
                        let datt33 = tmpdate33.getDate().toString().padStart(2, "0");
                        let month33 = (tmpdate33.getMonth() + 1).toString().padStart(2, "0");
                        let year33 = tmpdate33.getFullYear();
                        tmpCategories.push({ fromDateMS: tmpdate.getTime(), fromDate: year + '-' + month + '-' + datt, toDate: year33 + '-' + month33 + '-' + datt33, day: createIndex }); */
        }
      } else {
        var maxDays = 0;
        if (this.state.date_range === "14_days") {
          maxDays = 13;
        } else if (this.state.date_range === "30_days") {
          maxDays = 29;
        }

        for (var i = 0; i <= maxDays; i++) {
          let tmpdate = moment()
            .tz(this.agent.org_timezone)
            .subtract(i, "d")
            .format("YYYY-MM-DD");
          const tmpdateTimestamp = parseInt(
            moment
              .tz(tmpdate, "YYYY-MM-DD", this.agent.org_timezone)
              .tz(this.agent.org_timezone)
              .format("x")
          );
          let dayOfWeek = parseInt(
            moment
              .tz(tmpdate, "YYYY-MM-DD", this.agent.org_timezone)
              .tz(this.agent.org_timezone)
              .format("d")
          );
          const splitDate = tmpdate.split("-");
          let datt = splitDate[2];
          let month = splitDate[1];
          let year = splitDate[0];
          let createIndex;
          if (dayOfWeek === 0) {
            createIndex =
              datt +
              "-" +
              datt +
              "_" +
              month +
              "-" +
              month +
              "_" +
              year +
              "-" +
              year;
            tmpCategories.push({
              fromDateMS: tmpdateTimestamp,
              fromDate: year + "-" + month + "-" + datt,
              toDate: year + "-" + month + "-" + datt,
              day: createIndex,
            });
          } else {
            i = i + dayOfWeek;
            if (i > maxDays) {
              i = maxDays;
            }
            let tmpdate22 = moment()
              .tz(this.agent.org_timezone)
              .subtract(i, "d")
              .format("YYYY-MM-DD");
            const tmpdateTimestamp22 = parseInt(
              moment
                .tz(tmpdate22, "YYYY-MM-DD", this.agent.org_timezone)
                .tz(this.agent.org_timezone)
                .format("x")
            );
            const splitDate22 = tmpdate22.split("-");
            let datt22 = splitDate22[2];
            let month22 = splitDate22[1];
            let year22 = splitDate22[0];
            createIndex =
              datt22 +
              "-" +
              datt +
              "_" +
              month22 +
              "-" +
              month +
              "_" +
              year22 +
              "-" +
              year;
            tmpCategories.push({
              fromDateMS: tmpdateTimestamp22,
              fromDate: year22 + "-" + month22 + "-" + datt22,
              toDate: year + "-" + month + "-" + datt,
              day: createIndex,
            });
          }
        }

        /* for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                    let datt = tmpdate.getDate().toString().padStart(2, "0");
                    let month = (tmpdate.getMonth() + 1).toString().padStart(2, "0");
                    let year = tmpdate.getFullYear();
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ fromDateMS: tmpdate.getTime(), fromDate: year + '-' + month + '-' + datt, toDate: year + '-' + month + '-' + datt, day: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = new Date(today);
                        tmpdate22.setDate(tmpdate22.getDate() - i);
                        let datt22 = tmpdate22.getDate().toString().padStart(2, "0");
                        let month22 = (tmpdate22.getMonth() + 1).toString().padStart(2, "0");
                        let year22 = tmpdate22.getFullYear();
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ fromDateMS: tmpdate22.getTime(), fromDate: year22 + '-' + month22 + '-' + datt22, toDate: year + '-' + month + '-' + datt, day: createIndex });
                    }
                } */
        tmpCategories.reverse();
      }

      if (currentchats.length > 0) {
        currentchats.forEach((schat) => {
          let timeAddedOn = schat.chat_date.value;

          sumCurrChatServed += schat.served_chats;
          sumCurrChatMissed += schat.missed_chats;

          if (schat.ratings_sum > 0) {
            sumRatings += schat.ratings_sum;
          }

          if (schat.chats_rated > 0) {
            totalChatsrated += schat.chats_rated;
          }

          /* Response time chats start */
          if (schat.res1 > 0) {
            response_lessthan15 += schat.res1;
          }
          if (schat.res2 > 0) {
            response_inbtw15_30 += schat.res2;
          }
          if (schat.res3 > 0) {
            response_inbtw31_45 += schat.res3;
          }
          if (schat.res4 > 0) {
            response_inbtw46_60 += schat.res4;
          }
          if (schat.res5 > 0) {
            response_greaterthan60 += schat.res5;
          }
          /* Response time chats end */

          /* Wait time served chats start */
          if (schat.wt1 > 0) {
            wtime_served_lessthan15 += schat.wt1;
          }
          if (schat.wt2 > 0) {
            wtime_served_inbtw15_30 += schat.wt2;
          }
          if (schat.wt3 > 0) {
            wtime_served_inbtw31_45 += schat.wt3;
          }
          if (schat.wt4 > 0) {
            wtime_served_inbtw46_60 += schat.wt4;
          }
          if (schat.wt5 > 0) {
            wtime_served_greaterthan60 += schat.wt5;
          }
          /* Wait time served chats end */

          /* chat duration start */
          if (schat.dur1 > 0) {
            duration_lessthan10 += schat.dur1;
          }
          if (schat.dur2 > 0) {
            duration_inbtw10_30 += schat.dur2;
          }
          if (schat.dur3 > 0) {
            duration_inbtw31_60 += schat.dur3;
          }
          if (schat.dur4 > 0) {
            duration_inbtw1h_15 += schat.dur4;
          }
          if (schat.dur5 > 0) {
            duration_greaterthan15 += schat.dur5;
          }
          /* chat duration end */

          /* Wait time missed chats start */
          if (schat.wtm1 > 0) {
            mtime_served_lessthan15 += schat.wtm1;
          }
          if (schat.wtm2 > 0) {
            mtime_served_inbtw15_30 += schat.wtm2;
          }
          if (schat.wtm3 > 0) {
            mtime_served_inbtw31_45 += schat.wtm3;
          }
          if (schat.wtm4 > 0) {
            mtime_served_inbtw46_60 += schat.wtm4;
          }
          if (schat.wtm5 > 0) {
            mtime_served_greaterthan60 += schat.wtm5;
          }
          /* Wait time missed chats end */

          tmpCategories.forEach((valObj) => {
            if (
              timeAddedOn >= valObj.fromDate &&
              timeAddedOn <= valObj.toDate
            ) {
              let createIndex = valObj.day;
              if (tmpCurrServedChats[createIndex] === undefined) {
                tmpCurrServedChats[createIndex] = [];
              }
              if (tmpCurrMissedChats[createIndex] === undefined) {
                tmpCurrMissedChats[createIndex] = [];
              }
              if (tmpResponseTime[createIndex] === undefined) {
                tmpResponseTime[createIndex] = [];
              }
              if (tmpChatDuration[createIndex] === undefined) {
                tmpChatDuration[createIndex] = [];
              }
              if (tmpWaitTimeServed[createIndex] === undefined) {
                tmpWaitTimeServed[createIndex] = [];
              }
              if (tmpWaitTimemissed[createIndex] === undefined) {
                tmpWaitTimemissed[createIndex] = [];
              }
              if (tmpRatings[createIndex] === undefined) {
                tmpRatings[createIndex] = [];
              }

              tmpCurrServedChats[createIndex].push(schat.served_chats);
              tmpCurrMissedChats[createIndex].push(schat.missed_chats);
              tmpResponseTime[createIndex].push(schat.response_time);
              tmpChatDuration[createIndex].push(schat.chat_duration);
              tmpWaitTimeServed[createIndex].push(schat.wait_time);
              tmpWaitTimemissed[createIndex].push(schat.wait_time_missed);
              tmpRatings[createIndex].push(schat.chats_rated);
            }
          });
        });
      }

      if (previouschats.length > 0) {
        previouschats.forEach((schat) => {
          sumPrevChatServed += schat.served_chats;
          sumPrevChatMissed += schat.missed_chats;
          if (schat.ratings_sum > 0) {
            sumPrevRatings += schat.ratings_sum;
          }
          if (schat.chats_rated > 0) {
            totalPrevChatsrated += schat.chats_rated;
          }
        });
      }
    } else if (this.state.chart_base === "hourly") {
      if (currentchats.length > 0) {
        currentchats.forEach((schat) => {
          let createIndex = schat.chat_hours + "-" + schat.chat_date.value;
          if (tmpCurrServedChats[createIndex] === undefined) {
            tmpCurrServedChats[createIndex] = [];
          }
          if (tmpCurrMissedChats[createIndex] === undefined) {
            tmpCurrMissedChats[createIndex] = [];
          }
          if (tmpResponseTime[createIndex] === undefined) {
            tmpResponseTime[createIndex] = [];
          }
          if (tmpChatDuration[createIndex] === undefined) {
            tmpChatDuration[createIndex] = [];
          }
          if (tmpWaitTimeServed[createIndex] === undefined) {
            tmpWaitTimeServed[createIndex] = [];
          }
          if (tmpWaitTimemissed[createIndex] === undefined) {
            tmpWaitTimemissed[createIndex] = [];
          }
          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          tmpCurrServedChats[createIndex].push(schat.served_chats);
          tmpCurrMissedChats[createIndex].push(schat.missed_chats);
          tmpResponseTime[createIndex].push(schat.response_time);
          tmpChatDuration[createIndex].push(schat.chat_duration);
          tmpWaitTimeServed[createIndex].push(schat.wait_time);
          tmpWaitTimemissed[createIndex].push(schat.wait_time_missed);
          tmpRatings[createIndex].push(schat.chats_rated);

          sumCurrChatServed += schat.served_chats;
          sumCurrChatMissed += schat.missed_chats;

          if (schat.ratings_sum > 0) {
            sumRatings += schat.ratings_sum;
          }

          if (schat.chats_rated > 0) {
            totalChatsrated += schat.chats_rated;
          }

          /* Response time chats start */
          if (schat.res1 > 0) {
            response_lessthan15 += schat.res1;
          }
          if (schat.res2 > 0) {
            response_inbtw15_30 += schat.res2;
          }
          if (schat.res3 > 0) {
            response_inbtw31_45 += schat.res3;
          }
          if (schat.res4 > 0) {
            response_inbtw46_60 += schat.res4;
          }
          if (schat.res5 > 0) {
            response_greaterthan60 += schat.res5;
          }
          /* Response time chats end */

          /* Wait time served chats start */
          if (schat.wt1 > 0) {
            wtime_served_lessthan15 += schat.wt1;
          }
          if (schat.wt2 > 0) {
            wtime_served_inbtw15_30 += schat.wt2;
          }
          if (schat.wt3 > 0) {
            wtime_served_inbtw31_45 += schat.wt3;
          }
          if (schat.wt4 > 0) {
            wtime_served_inbtw46_60 += schat.wt4;
          }
          if (schat.wt5 > 0) {
            wtime_served_greaterthan60 += schat.wt5;
          }
          /* Wait time served chats end */

          /* chat duration start */
          if (schat.dur1 > 0) {
            duration_lessthan10 += schat.dur1;
          }
          if (schat.dur2 > 0) {
            duration_inbtw10_30 += schat.dur2;
          }
          if (schat.dur3 > 0) {
            duration_inbtw31_60 += schat.dur3;
          }
          if (schat.dur4 > 0) {
            duration_inbtw1h_15 += schat.dur4;
          }
          if (schat.dur5 > 0) {
            duration_greaterthan15 += schat.dur5;
          }
          /* chat duration end */

          /* Wait time missed chats start */
          if (schat.wtm1 > 0) {
            mtime_served_lessthan15 += schat.wtm1;
          }
          if (schat.wtm2 > 0) {
            mtime_served_inbtw15_30 += schat.wtm2;
          }
          if (schat.wtm3 > 0) {
            mtime_served_inbtw31_45 += schat.wtm3;
          }
          if (schat.wtm4 > 0) {
            mtime_served_inbtw46_60 += schat.wtm4;
          }
          if (schat.wtm5 > 0) {
            mtime_served_greaterthan60 += schat.wtm5;
          }
          /* Wait time missed chats end */
        });
      }

      if (previouschats.length > 0) {
        previouschats.forEach((schat) => {
          sumPrevChatServed += schat.served_chats;
          sumPrevChatMissed += schat.missed_chats;
          if (schat.ratings_sum > 0) {
            sumPrevRatings += schat.ratings_sum;
          }
          if (schat.chats_rated > 0) {
            totalPrevChatsrated += schat.chats_rated;
          }
        });
      }
    }

    if (this.state.date_range === "custom") {
      if (this.state.chart_base === "daily") {
        for (var i = 0; i < this.state.numberOfDays; i++) {
          let tmpdate = moment(parseInt(this.state.start_date)).add(i, "days");
          let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
          let dayAddedOn = tmpdate.format("DD");
          let monthAddedOn = tmpdate.format("MM");
          let yearAddedOn = tmpdate.format("YYYY");
          let dtTimestamp = parseInt(
            moment(tmpDateOnly)
              .parseZone()
              .tz(this.agent.org_timezone, true)
              .format("x")
          );
          let createIndex = yearAddedOn + "-" + monthAddedOn + "-" + dayAddedOn;
          tmpCategories.push({ date: dtTimestamp, day: createIndex });
        }
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            if (resTimeSum > 0) {
              let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
              let avgInMinutes = Math.ceil(avgResponseTime / 60);
              series_response_time.push(avgInMinutes);
            } else {
              series_response_time.push(0);
            }
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDateMS);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            if (resTimeSum > 0) {
              let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
              let avgInMinutes = Math.ceil(avgResponseTime / 60);
              series_response_time.push(avgInMinutes);
            } else {
              series_response_time.push(0);
            }
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "30_days") {
      if (this.state.chart_base === "daily") {
        for (var i = 0; i <= 29; i++) {
          let tmpdate = moment()
            .tz(this.agent.org_timezone)
            .subtract(i, "d")
            .format("YYYY-MM-DD");
          const tmpdateTimestamp = parseInt(
            moment
              .tz(tmpdate, "YYYY-MM-DD", this.agent.org_timezone)
              .tz(this.agent.org_timezone)
              .format("x")
          );
          const splitDate = tmpdate.split("-");
          let dayAddedOn = splitDate[2];
          let monthAddedOn = splitDate[1];
          let yearAddedOn = splitDate[0];
          let createIndex = yearAddedOn + "-" + monthAddedOn + "-" + dayAddedOn;
          tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
        }
        tmpCategories.reverse();
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            if (resTimeSum > 0) {
              let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
              let avgInMinutes = Math.ceil(avgResponseTime / 60);
              series_response_time.push(avgInMinutes);
            } else {
              series_response_time.push(0);
            }
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDateMS);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            if (resTimeSum > 0) {
              let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
              let avgInMinutes = Math.ceil(avgResponseTime / 60);
              series_response_time.push(avgInMinutes);
            } else {
              series_response_time.push(0);
            }
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "14_days") {
      if (this.state.chart_base === "weekly") {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDateMS);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            if (resTimeSum > 0) {
              let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
              let avgInMinutes = Math.ceil(avgResponseTime / 60);
              series_response_time.push(avgInMinutes);
            } else {
              series_response_time.push(0);
            }
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        for (var i = 0; i <= 13; i++) {
          let tmpdate = moment()
            .tz(this.agent.org_timezone)
            .subtract(i, "d")
            .format("YYYY-MM-DD");
          const tmpdateTimestamp = parseInt(
            moment
              .tz(tmpdate, "YYYY-MM-DD", this.agent.org_timezone)
              .tz(this.agent.org_timezone)
              .format("x")
          );
          const splitDate = tmpdate.split("-");
          let dayAddedOn = splitDate[2];
          let monthAddedOn = splitDate[1];
          let yearAddedOn = splitDate[0];
          let createIndex = yearAddedOn + "-" + monthAddedOn + "-" + dayAddedOn;
          tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
        }
        tmpCategories.reverse();
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          let totalServedChatsSum = 0;
          let totalMissedChatsSum = 0;
          if (tmpCurrServedChats[day] !== undefined) {
            totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_served.push(totalServedChatsSum);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            series_data_missed.push(totalMissedChatsSum);
          } else {
            series_data_missed.push(0);
          }
          if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / totalServedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / totalMissedChatsSum
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            let totalSum = tmpRatings[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            series_ratings.push(totalSum);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "7_days") {
      for (var i = 0; i <= 6; i++) {
        let tmpdate = moment()
          .tz(this.agent.org_timezone)
          .subtract(i, "d")
          .format("YYYY-MM-DD");
        const tmpdateTimestamp = parseInt(
          moment
            .tz(tmpdate, "YYYY-MM-DD", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format("x")
        );
        const splitDate = tmpdate.split("-");
        let dayAddedOn = splitDate[2];
        let monthAddedOn = splitDate[1];
        let yearAddedOn = splitDate[0];
        let createIndex = yearAddedOn + "-" + monthAddedOn + "-" + dayAddedOn;
        tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
      }
      tmpCategories.reverse();
      tmpCategories.forEach((mydate) => {
        xaxis_categories.push(mydate.date);
        const day = mydate.day;
        let totalServedChatsSum = 0;
        let totalMissedChatsSum = 0;
        if (tmpCurrServedChats[day] !== undefined) {
          totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          series_data_served.push(totalServedChatsSum);
        } else {
          series_data_served.push(0);
        }
        if (tmpCurrMissedChats[day] !== undefined) {
          totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          series_data_missed.push(totalMissedChatsSum);
        } else {
          series_data_missed.push(0);
        }
        if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
          let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
          let avgInMinutes = Math.ceil(avgResponseTime / 60);
          series_response_time.push(avgInMinutes);
        } else {
          series_response_time.push(0);
        }

        if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
          let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
          let avgInMinutes = Math.ceil(avgChatDur / 60);
          series_chat_duration.push(avgInMinutes);
        } else {
          series_chat_duration.push(0);
        }

        if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
          let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          let avgWtTimeServedTime = Math.ceil(
            reswtTimeSum / totalServedChatsSum
          );
          let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
          series_wait_time_served.push(avgInMinutes);
        } else {
          series_wait_time_served.push(0);
        }

        if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
          let waitTimeSum = tmpWaitTimemissed[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgWtTimeMissedTime = Math.ceil(
            waitTimeSum / totalMissedChatsSum
          );
          let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
          series_wait_time_missed.push(avgInMinutes);
        } else {
          series_wait_time_missed.push(0);
        }

        if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
          let totalSum = tmpRatings[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          series_ratings.push(totalSum);
        } else {
          series_ratings.push(0);
        }
      });
    } else if (
      this.state.date_range === "today" ||
      this.state.date_range === "yesterday"
    ) {
      let todayHour;
      let tmpdate2 = new Date();
      if (this.state.date_range === "yesterday") {
        todayHour = 23;
        tmpdate2.setDate(tmpdate2.getDate() - 1);
      } else {
        todayHour = parseInt(moment().tz(this.agent.org_timezone).format("HH"));
      }
      tmpdate2 = tmpdate2.getTime();
      for (var i = 0; i <= todayHour; i++) {
        let tmpHourrr = i.toString().padStart(2, "0") + ":00:00";
        let timeWithHour = moment(tmpdate2)
          .tz(this.agent.org_timezone)
          .format("YYYY-MM-DD " + tmpHourrr);
        let timestampp = parseInt(
          moment
            .tz(timeWithHour, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone)
            .tz(this.agent.org_timezone)
            .format("x")
        );
        let dayAddedOn = moment(tmpdate2)
          .tz(this.agent.org_timezone)
          .format("DD");
        let monthAddedOn = moment(tmpdate2)
          .tz(this.agent.org_timezone)
          .format("MM");
        let yearAddedOn = moment(tmpdate2)
          .tz(this.agent.org_timezone)
          .format("YYYY");
        let createIndex =
          i + "-" + yearAddedOn + "-" + monthAddedOn + "-" + dayAddedOn;
        tmpCategories.push({ date: timestampp, hour: createIndex });
      }

      tmpCategories.forEach((mydate) => {
        xaxis_categories.push(mydate.date);
        const day = mydate.hour;
        let totalServedChatsSum = 0;
        let totalMissedChatsSum = 0;
        if (tmpCurrServedChats[day] !== undefined) {
          totalServedChatsSum = tmpCurrServedChats[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          series_data_served.push(totalServedChatsSum);
        } else {
          series_data_served.push(0);
        }
        if (tmpCurrMissedChats[day] !== undefined) {
          totalMissedChatsSum = tmpCurrMissedChats[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          series_data_missed.push(totalMissedChatsSum);
        } else {
          series_data_missed.push(0);
        }
        if (tmpResponseTime[day] !== undefined && totalServedChatsSum > 0) {
          let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgResponseTime = Math.ceil(resTimeSum / totalServedChatsSum);
          let avgInMinutes = Math.ceil(avgResponseTime / 60);
          series_response_time.push(avgInMinutes);
        } else {
          series_response_time.push(0);
        }

        if (tmpChatDuration[day] !== undefined && totalServedChatsSum > 0) {
          let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgChatDur = Math.ceil(chatDurSum / totalServedChatsSum);
          let avgInMinutes = Math.ceil(avgChatDur / 60);
          series_chat_duration.push(avgInMinutes);
        } else {
          series_chat_duration.push(0);
        }

        if (tmpWaitTimeServed[day] !== undefined && totalServedChatsSum > 0) {
          let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          let avgWtTimeServedTime = Math.ceil(
            reswtTimeSum / totalServedChatsSum
          );
          let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
          series_wait_time_served.push(avgInMinutes);
        } else {
          series_wait_time_served.push(0);
        }

        if (tmpWaitTimemissed[day] !== undefined && totalMissedChatsSum > 0) {
          let waitTimeSum = tmpWaitTimemissed[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgWtTimeMissedTime = Math.ceil(
            waitTimeSum / totalMissedChatsSum
          );
          let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
          series_wait_time_missed.push(avgInMinutes);
        } else {
          series_wait_time_missed.push(0);
        }

        if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
          let totalSum = tmpRatings[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          series_ratings.push(totalSum);
        } else {
          series_ratings.push(0);
        }
      });
    }

    let sumCurrChatTotal = sumCurrChatServed + sumCurrChatMissed;
    let sumPrevChatTotal = sumPrevChatServed + sumPrevChatMissed;

    series = [
      { name: "Chats completed", color: "#77c66d", data: series_data_served },
      { name: "Chats missed", color: "#eb867d", data: series_data_missed },
    ];
    options.xaxis = {
      type: xaxis_type, ////"datetime",
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };
    chatTiming_series = [
      { name: "Response Time", color: "#7CC771", data: series_response_time },
      { name: "Chat Duration", color: "#EBBB58", data: series_chat_duration },
      {
        name: "Wait Time (Completed)",
        color: "#71B2E3",
        data: series_wait_time_served,
      },
      {
        name: "Wait Time (Missed)",
        color: "#E3706A",
        data: series_wait_time_missed,
      },
    ];
    chatTiming_options.xaxis = {
      type: xaxis_type,
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };

    satisfaction_options.xaxis = {
      type: xaxis_type,
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };

    satisfaction_series = [{ name: "Chats rated", data: series_ratings }];
    response_series = [
      {
        name: "Number of Chats",
        data: [
          response_lessthan15,
          response_inbtw15_30,
          response_inbtw31_45,
          response_inbtw46_60,
          response_greaterthan60,
        ],
      },
    ];
    waittimeserved_series = [
      {
        name: "Number of Chats",
        data: [
          wtime_served_lessthan15,
          wtime_served_inbtw15_30,
          wtime_served_inbtw31_45,
          wtime_served_inbtw46_60,
          wtime_served_greaterthan60,
        ],
      },
    ];
    chatduration_series = [
      {
        name: "Number of Chats",
        data: [
          duration_lessthan10,
          duration_inbtw10_30,
          duration_inbtw31_60,
          duration_inbtw1h_15,
          duration_greaterthan15,
        ],
      },
    ];
    waittimemissed_series = [
      {
        name: "Number of Chats",
        data: [
          mtime_served_lessthan15,
          mtime_served_inbtw15_30,
          mtime_served_inbtw31_45,
          mtime_served_inbtw46_60,
          mtime_served_greaterthan60,
        ],
      },
    ];

    /* NPS start */

    let tooltip_nps_rating = "";
    let npscurrentAndPrev = "";

    let totalRatedPoints = totalChatsrated * 5;
    var goodPercentage = Math.ceil((sumRatings / totalRatedPoints) * 100);
    if (isNaN(goodPercentage)) {
      goodPercentage = 0;
    }
    var badPercentage = 100 - goodPercentage;
    nps_series = [goodPercentage, badPercentage];

    var goodPrevPercentage = 0;
    if (totalPrevChatsrated > 0) {
      let totalPrevRatedPoints = totalPrevChatsrated * 5;
      goodPrevPercentage = Math.ceil(
        (sumPrevRatings / totalPrevRatedPoints) * 100
      );
      if (isNaN(goodPrevPercentage)) {
        goodPrevPercentage = 0;
      }
    }

    if (goodPercentage === goodPrevPercentage) {
      npscurrentAndPrev = "equal";
      tooltip_nps_rating =
        "Previous period satisfaction " + goodPrevPercentage + "%.";
    } else if (goodPercentage > goodPrevPercentage) {
      npscurrentAndPrev = "up";
      let diffpr = goodPercentage - goodPrevPercentage;
      diffpr = diffpr.toFixed(2);
      tooltip_nps_rating =
        "Previous period satisfaction " +
        goodPrevPercentage +
        "%. Increase of " +
        diffpr +
        "%";
    } else {
      npscurrentAndPrev = "down";
      let diffpr = goodPrevPercentage - goodPercentage;
      diffpr = diffpr.toFixed(2);
      tooltip_nps_rating =
        "Previous period satisfaction " +
        goodPrevPercentage +
        "%. Decrease of " +
        diffpr +
        "%";
    }

    /* NPS end */

    /* Get percentage start */

    let tooltip_total_chats = "";
    let tooltip_served_chats = "";
    let tooltip_missed_chats = "";
    let percentage_total_chats = 0;
    let percentage_served_chats = 0;
    let percantage_missed_chats = 0;

    if (sumCurrChatTotal === sumPrevChatTotal) {
      tooltip_total_chats = "Total chat in previous period are same.";
    } else if (sumCurrChatTotal > sumPrevChatTotal) {
      if (sumPrevChatTotal === 0) {
        tooltip_total_chats =
          "Total chat in previous period " + sumPrevChatTotal;
      } else {
        let diff = sumCurrChatTotal - sumPrevChatTotal;
        let percentage = (diff * 100) / sumPrevChatTotal;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percentage_total_chats = percentage;
        tooltip_total_chats =
          "Total chat in previous period " +
          sumPrevChatTotal +
          " Increased of " +
          percentage +
          "%";
      }
    } else {
      let diff = sumPrevChatTotal - sumCurrChatTotal;
      let percentage = (diff * 100) / sumPrevChatTotal;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percentage_total_chats = percentage;
      tooltip_total_chats =
        "Total chat in previous period " +
        sumPrevChatTotal +
        " Decreased of " +
        percentage +
        "%";
    }

    if (sumPrevChatServed === sumCurrChatServed) {
      tooltip_served_chats = "Completed chat in previous period are same.";
    } else if (sumCurrChatServed > sumPrevChatServed) {
      if (sumPrevChatServed > 0) {
        let diff = sumCurrChatServed - sumPrevChatServed;
        let percentage = (diff * 100) / sumPrevChatServed;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percentage_served_chats = percentage;
        tooltip_served_chats =
          "Completed chat in previous period " +
          sumPrevChatServed +
          " Increased of " +
          percentage +
          "%";
      } else {
        tooltip_served_chats =
          "Completed chat in previous period " + sumPrevChatServed;
      }
    } else {
      let diff = sumPrevChatServed - sumCurrChatServed;
      let percentage = (diff * 100) / sumPrevChatServed;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percentage_served_chats = percentage;
      tooltip_served_chats =
        "Completed chat in previous period " +
        sumPrevChatServed +
        " Decreased of " +
        percentage +
        "%";
    }

    if (sumCurrChatMissed === sumPrevChatMissed) {
      tooltip_missed_chats = "Missed chat in previous period are same.";
    } else if (sumCurrChatMissed > sumPrevChatMissed) {
      if (sumPrevChatMissed > 0) {
        let diff = sumCurrChatMissed - sumPrevChatMissed;
        let percentage = (diff * 100) / sumPrevChatMissed;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percantage_missed_chats = percentage;
        tooltip_missed_chats =
          "Missed chat in previous period " +
          sumPrevChatMissed +
          " Increased of " +
          percentage +
          "%";
      } else {
        tooltip_missed_chats =
          "Missed chat in previous period " + sumPrevChatMissed;
      }
    } else {
      let diff = sumPrevChatMissed - sumCurrChatMissed;
      let percentage = (diff * 100) / sumPrevChatMissed;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percantage_missed_chats = percentage;
      tooltip_missed_chats =
        "Missed chat in previous period " +
        sumPrevChatMissed +
        " Decreased of " +
        percentage +
        "%";
    }

    let chatRatedPercentage = (totalChatsrated * 100) / sumCurrChatTotal;
    chatRatedPercentage = chatRatedPercentage.toFixed(2);
    if (isNaN(chatRatedPercentage)) {
      chatRatedPercentage = 0;
    }

    this.setState(
      {
        chats_total: sumCurrChatTotal,
        chats_served: sumCurrChatServed,
        chats_missed: sumCurrChatMissed,
        prev_chats_total: sumPrevChatTotal,
        prev_chats_served: sumPrevChatServed,
        prev_chats_missed: sumPrevChatMissed,
        tooltip_total_chats: tooltip_total_chats,
        tooltip_served_chats: tooltip_served_chats,
        tooltip_missed_chats: tooltip_missed_chats,
        tooltip_nps_rating: tooltip_nps_rating,
        series: series,
        chatTiming_series: chatTiming_series,
        response_series: response_series,
        waittimeserved_series: waittimeserved_series,
        chatduration_series: chatduration_series,
        waittimemissed_series: waittimemissed_series,
        satisfaction_series: satisfaction_series,
        nps_series: nps_series,
        total_rated_chats: totalChatsrated,
        chatRatedPercentage: chatRatedPercentage,
        percentage_total_chats,
        percentage_served_chats,
        percantage_missed_chats,
      },
      () => {
        this.setState({
          options: options,
          chatTiming_options: chatTiming_options,
          satisfaction_options: satisfaction_options,
          npscurrentAndPrev: npscurrentAndPrev,
          nps_options: nps_options,
          loader: false,
        });
      }
    );
  };

  /* For Graph API End */

  callChatAPI = (body) => {
    return new Promise((resolve) => {
      //fetch(MAIL_FUNCTION_URL + "/analytics/getGraphChats", {
      fetch(GATEWAY_URL + "/getChats", {
        method: "post",
        body: body,
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          Accept: "application/json",
          Origin: window.location.origin,
        },
      })
        .then((res) => res.json())
        .then(async (response) => {
          if (response.status === "success") {
            resolve(response);
          } else {
            resolve({ data: { current: [], previous: [] }, load_more: false });
          }
        })
        .catch((err) => {
          resolve({ data: { current: [], previous: [] }, load_more: false });
        });
    });
  };

  getchats = async () => {
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    this.setState({ loader: true });
    let tmpCurrentchats = [];
    let tmpPreviouschats = [];
    let yesterDayDt;
    let befYest;
    if (this.state.date_range === "yesterday") {
      yesterDayDt = new Date();
      yesterDayDt.setDate(yesterDayDt.getDate() - 1);
      yesterDayDt.setHours(0, 0, 0, 0);
      befYest = new Date();
      befYest.setDate(befYest.getDate() - 2);
      befYest.setHours(0, 0, 0, 0);
    }
    if (this.state.data_type === 1) {
      let start_date = this.state.start_date;
      let end_date = this.state.end_date;
      if (this.state.date_range === "yesterday") {
        start_date = befYest.getTime();
        end_date = yesterDayDt.getTime();
      }
      let body = [
        `ownerId=${this.agent.ownerId}&date_range=${
          this.state.date_range
        }&start_date=${encodeURIComponent(
          start_date
        )}&end_date=${encodeURIComponent(end_date)}&numberOfDays=${
          this.state.numberOfDays
        }&currentDate=${todayDate.getTime()}&org_timezone=${encodeURIComponent(
          this.agent.org_timezone ? this.agent.org_timezone : ""
        )}`,
      ];
      const { data } = await this.callGraphChatAPI(body);
      this.setState(
        { currentchats: data.current, previouschats: data.previous },
        () => {
          this.loadGraphChats();
        }
      );
    } else {
      while (!this.state.isAllLoaded) {
        let start_date = this.state.start_date;
        let end_date = this.state.end_date;
        if (this.state.date_range === "yesterday") {
          start_date = befYest.getTime();
          end_date = yesterDayDt.getTime();
        }
        let body = [
          `ownerId=${this.agent.ownerId}&date_range=${
            this.state.date_range
          }&domain=${encodeURIComponent(
            this.state.domain && this.state.domain !== "all"
              ? this.state.domain
              : ""
          )}&start_date=${encodeURIComponent(
            start_date
          )}&end_date=${encodeURIComponent(end_date)}&numberOfDays=${
            this.state.numberOfDays
          }&currentDate=${todayDate.getTime()}&offset=${
            this.state.data_offset
          }&country=${this.state.country}`,
        ];
        const { data, last_addedon, load_more } = await this.callChatAPI(body);
        tmpCurrentchats = [...tmpCurrentchats, ...data.current];
        tmpPreviouschats = [...tmpPreviouschats, ...data.previous];
        this.setState({
          isAllLoaded: load_more ? false : true,
          data_offset: last_addedon,
        });
      }
      if (this.state.isAllLoaded) {
        this.setState(
          { currentchats: tmpCurrentchats, previouschats: tmpPreviouschats },
          () => {
            this.loadChats();
          }
        );
      }
    }
  };

  loadChats = () => {
    var currentchats = this.state.currentchats;
    var previouschats = this.state.previouschats;

    var options = this.state.options;
    var series = this.state.series;

    var chatTiming_options = this.state.chatTiming_options;
    var chatTiming_series = this.state.chatTiming_series;

    var response_series = this.state.response_series;
    var waittimeserved_series = this.state.waittimeserved_series;
    var chatduration_series = this.state.chatduration_series;
    var waittimemissed_series = this.state.waittimemissed_series;

    var satisfaction_options = this.state.satisfaction_options;
    var satisfaction_series = this.state.satisfaction_series;

    var nps_series = this.state.nps_series;
    var nps_options = this.state.nps_options;

    this.setState({
      series: [],
      options: {},
      chatTiming_options: {},
      chatTiming_series: [],
      response_series: [],
      waittimeserved_series: [],
      chatduration_series: [],
      waittimemissed_series: [],
      satisfaction_options: {},
      satisfaction_series: [],
      nps_series: [],
      nps_options: {},
      total_rated_chats: 0,
      showSatisfaction: false,
    });
    let bothCurrData = currentchats.reduce(
      function (all, item, index) {
        all[item.status].push(item);
        return all;
      },
      { served: [], missed: [] }
    );

    let bothPrevData = previouschats.reduce(
      function (all, item, index) {
        all[item.status].push(item);
        return all;
      },
      { served: [], missed: [] }
    );

    /* For charting start */

    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let xaxis_categories = [];
    let xaxis_type = "category"; // category, datetime, numeric
    let series_data_served = [];
    let series_data_missed = [];
    let tmpCurrServedChats = [];
    let tmpCurrMissedChats = [];

    let tmpResponseTime = [];
    let tmpChatDuration = [];
    let tmpWaitTimeServed = [];
    let tmpWaitTimemissed = [];
    let series_response_time = [];
    let series_chat_duration = [];
    let series_wait_time_served = [];
    let series_wait_time_missed = [];

    let response_lessthan15 = 0;
    let response_inbtw15_30 = 0;
    let response_inbtw31_45 = 0;
    let response_inbtw46_60 = 0;
    let response_greaterthan60 = 0;

    let wtime_served_lessthan15 = 0;
    let wtime_served_inbtw15_30 = 0;
    let wtime_served_inbtw31_45 = 0;
    let wtime_served_inbtw46_60 = 0;
    let wtime_served_greaterthan60 = 0;

    let duration_lessthan10 = 0;
    let duration_inbtw10_30 = 0;
    let duration_inbtw31_60 = 0;
    let duration_inbtw1h_15 = 0;
    let duration_greaterthan15 = 0;

    let mtime_served_lessthan15 = 0;
    let mtime_served_inbtw15_30 = 0;
    let mtime_served_inbtw31_45 = 0;
    let mtime_served_inbtw46_60 = 0;
    let mtime_served_greaterthan60 = 0;

    let tmpRatings = [];
    let tmpServedRatings = [];
    let tmpMissedRatings = [];
    let series_ratings = [];
    let showSatisfaction = false;
    let tmpCategories = [];
    let tmpPrevRatings = [];

    if (this.state.chart_base === "daily") {
      if (bothCurrData.served.length > 0) {
        bothCurrData.served.forEach((schat) => {
          let addedon = new Date(schat.addedon);
          let dateAddedOn = addedon.getDate();
          let monthAddedOn = addedon.getMonth() + 1;
          let yearAddedOn = addedon.getFullYear();
          let createIndex =
            dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

          if (tmpCurrServedChats[createIndex] === undefined) {
            tmpCurrServedChats[createIndex] = [];
          }
          if (tmpResponseTime[createIndex] === undefined) {
            tmpResponseTime[createIndex] = [];
          }
          if (tmpChatDuration[createIndex] === undefined) {
            tmpChatDuration[createIndex] = [];
          }
          if (tmpWaitTimeServed[createIndex] === undefined) {
            tmpWaitTimeServed[createIndex] = [];
          }
          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          tmpCurrServedChats[createIndex].push(schat.chat_id);
          let restime = schat.response_time === null ? 0 : schat.response_time;
          let chatduration =
            schat.chat_duration === null ? 0 : schat.chat_duration;
          let waittime = schat.wait_time === null ? 0 : schat.wait_time;
          tmpResponseTime[createIndex].push(restime);
          tmpChatDuration[createIndex].push(chatduration);
          tmpWaitTimeServed[createIndex].push(waittime);

          let rating = schat.rating === null ? 0 : schat.rating;
          if (rating > 0) {
            showSatisfaction = true;
            tmpServedRatings.push(rating);
            tmpRatings[createIndex].push(rating);
          }

          /* Response time chats start */
          if (restime < 15) {
            response_lessthan15++;
          } else if (restime >= 15 && restime <= 30) {
            response_inbtw15_30++;
          } else if (restime >= 31 && restime <= 45) {
            response_inbtw31_45++;
          } else if (restime >= 46 && restime <= 60) {
            response_inbtw46_60++;
          } else if (restime > 60) {
            response_greaterthan60++;
          }
          /* Response time chats end */

          /* Wait time served chats start */
          if (waittime < 15) {
            wtime_served_lessthan15++;
          } else if (waittime >= 15 && waittime <= 30) {
            wtime_served_inbtw15_30++;
          } else if (waittime >= 31 && waittime <= 45) {
            wtime_served_inbtw31_45++;
          } else if (waittime >= 46 && waittime <= 60) {
            wtime_served_inbtw46_60++;
          } else if (waittime > 60) {
            wtime_served_greaterthan60++;
          }
          /* Wait time served chats end */

          /* chat duration start */
          if (chatduration < 600) {
            duration_lessthan10++;
          } else if (chatduration >= 600 && chatduration <= 1800) {
            duration_inbtw10_30++;
          } else if (chatduration >= 1801 && chatduration <= 3600) {
            duration_inbtw31_60++;
          } else if (chatduration >= 3601 && chatduration <= 5400) {
            duration_inbtw1h_15++;
          } else if (chatduration > 5401) {
            duration_greaterthan15++;
          }
          /* chat duration end */
        });
      }

      if (bothCurrData.missed.length > 0) {
        bothCurrData.missed.forEach((schat) => {
          let addedon = new Date(schat.addedon);
          let dateAddedOn = addedon.getDate();
          let monthAddedOn = addedon.getMonth() + 1;
          let yearAddedOn = addedon.getFullYear();
          let createIndex =
            dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          if (tmpCurrMissedChats[createIndex] === undefined) {
            tmpCurrMissedChats[createIndex] = [];
          }
          tmpCurrMissedChats[createIndex].push(schat.chat_id);
          let waittimemissed =
            schat.wait_time_missed === null ? 0 : schat.wait_time_missed;

          if (tmpWaitTimemissed[createIndex] === undefined) {
            tmpWaitTimemissed[createIndex] = [];
          }
          tmpWaitTimemissed[createIndex].push(waittimemissed);

          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          let rating = schat.rating === null ? 0 : schat.rating;
          if (rating > 0) {
            showSatisfaction = true;
            tmpMissedRatings.push(rating);
            tmpRatings[createIndex].push(rating);
          }

          /* Wait time missed chats start */
          if (waittimemissed < 15) {
            mtime_served_lessthan15++;
          } else if (waittimemissed >= 15 && waittimemissed <= 30) {
            mtime_served_inbtw15_30++;
          } else if (waittimemissed >= 31 && waittimemissed <= 45) {
            mtime_served_inbtw31_45++;
          } else if (waittimemissed >= 46 && waittimemissed <= 60) {
            mtime_served_inbtw46_60++;
          } else if (waittimemissed > 60) {
            mtime_served_greaterthan60++;
          }
          /* Wait time missed chats end */
        });
      }
    } else if (this.state.chart_base === "weekly") {
      let stttDate = parseInt(this.state.start_date);
      let stttEndDate = parseInt(this.state.end_date);
      if (this.state.date_range === "custom") {
        for (var i = 0; i < this.state.numberOfDays; i++) {
          let tmpdate = new Date(stttDate);
          tmpdate.setDate(tmpdate.getDate() + i);
          let dayOfWeek = parseInt(moment(tmpdate).format("d"));
          let datt = tmpdate.getDate();
          let month = tmpdate.getMonth() + 1;
          let year = tmpdate.getFullYear();
          let totalWeekDays = 6 - dayOfWeek;
          i = i + totalWeekDays;

          let tmpdate22 = new Date(stttDate);
          tmpdate22.setDate(tmpdate22.getDate() + i);
          let toDate22;
          let gtTime22 = tmpdate22.getTime();

          if (gtTime22 > stttEndDate) {
            tmpdate22 = new Date(stttEndDate);
            toDate22 = stttEndDate;
          } else {
            toDate22 = gtTime22 + 86399000;
          }
          let datt22 = tmpdate22.getDate();
          let month22 = tmpdate22.getMonth() + 1;
          let year22 = tmpdate22.getFullYear();
          let createIndex =
            datt +
            "-" +
            datt22 +
            "_" +
            month +
            "-" +
            month22 +
            "_" +
            year +
            "-" +
            year22;
          tmpCategories.push({
            fromDate: tmpdate.getTime(),
            toDate: toDate22,
            day: createIndex,
          });
        }
      } else {
        var maxDays = 0;
        if (this.state.date_range === "14_days") {
          maxDays = 13;
        } else if (this.state.date_range === "30_days") {
          maxDays = 29;
        }
        for (var i = 0; i <= maxDays; i++) {
          let tmpdate = new Date(today);
          tmpdate.setDate(tmpdate.getDate() - i);
          let dayOfWeek = parseInt(moment(tmpdate).format("d"));
          let datt = tmpdate.getDate();
          let month = tmpdate.getMonth() + 1;
          let year = tmpdate.getFullYear();
          let createIndex;
          if (dayOfWeek === 0) {
            createIndex =
              datt +
              "-" +
              datt +
              "_" +
              month +
              "-" +
              month +
              "_" +
              year +
              "-" +
              year;
            tmpCategories.push({
              fromDate: tmpdate.getTime(),
              toDate: tmpdate.getTime() + 86399000,
              day: createIndex,
            });
          } else {
            i = i + dayOfWeek;
            if (i > maxDays) {
              i = maxDays;
            }
            let tmpdate22 = new Date(today);
            tmpdate22.setDate(tmpdate22.getDate() - i);
            let datt22 = tmpdate22.getDate();
            let month22 = tmpdate22.getMonth() + 1;
            let year22 = tmpdate22.getFullYear();
            createIndex =
              datt22 +
              "-" +
              datt +
              "_" +
              month22 +
              "-" +
              month +
              "_" +
              year22 +
              "-" +
              year;
            tmpCategories.push({
              fromDate: tmpdate22.getTime(),
              toDate: tmpdate.getTime() + 86399000,
              day: createIndex,
            });
          }
        }
        tmpCategories.reverse();
      }

      bothCurrData.served.forEach((schat) => {
        let addedon = new Date(schat.addedon);
        let timeAddedOn = addedon.getTime();
        tmpCategories.forEach((valObj) => {
          if (timeAddedOn >= valObj.fromDate && timeAddedOn <= valObj.toDate) {
            let createIndex = valObj.day;
            if (tmpCurrServedChats[createIndex] === undefined) {
              tmpCurrServedChats[createIndex] = [];
            }
            if (tmpResponseTime[createIndex] === undefined) {
              tmpResponseTime[createIndex] = [];
            }
            if (tmpChatDuration[createIndex] === undefined) {
              tmpChatDuration[createIndex] = [];
            }
            if (tmpWaitTimeServed[createIndex] === undefined) {
              tmpWaitTimeServed[createIndex] = [];
            }
            tmpCurrServedChats[createIndex].push(schat.chat_id);
            let restime =
              schat.response_time === null ? 0 : schat.response_time;
            let chatduration =
              schat.chat_duration === null ? 0 : schat.chat_duration;
            let waittime = schat.wait_time === null ? 0 : schat.wait_time;
            tmpResponseTime[createIndex].push(restime);
            tmpChatDuration[createIndex].push(chatduration);
            tmpWaitTimeServed[createIndex].push(waittime);
            if (tmpRatings[createIndex] === undefined) {
              tmpRatings[createIndex] = [];
            }
            let rating = schat.rating === null ? 0 : schat.rating;
            if (rating > 0) {
              showSatisfaction = true;
              tmpServedRatings.push(rating);
              tmpRatings[createIndex].push(rating);
            }

            /* Response time chats start */
            if (restime < 15) {
              response_lessthan15++;
            } else if (restime >= 15 && restime <= 30) {
              response_inbtw15_30++;
            } else if (restime >= 31 && restime <= 45) {
              response_inbtw31_45++;
            } else if (restime >= 46 && restime <= 60) {
              response_inbtw46_60++;
            } else if (restime > 60) {
              response_greaterthan60++;
            }
            /* Response time chats end */

            /* Wait time served chats start */
            if (waittime < 15) {
              wtime_served_lessthan15++;
            } else if (waittime >= 15 && waittime <= 30) {
              wtime_served_inbtw15_30++;
            } else if (waittime >= 31 && waittime <= 45) {
              wtime_served_inbtw31_45++;
            } else if (waittime >= 46 && waittime <= 60) {
              wtime_served_inbtw46_60++;
            } else if (waittime > 60) {
              wtime_served_greaterthan60++;
            }
            /* Wait time served chats end */

            /* chat duration start */
            if (chatduration < 600) {
              duration_lessthan10++;
            } else if (chatduration >= 600 && chatduration <= 1800) {
              duration_inbtw10_30++;
            } else if (chatduration >= 1801 && chatduration <= 3600) {
              duration_inbtw31_60++;
            } else if (chatduration >= 3601 && chatduration <= 5400) {
              duration_inbtw1h_15++;
            } else if (chatduration > 5401) {
              duration_greaterthan15++;
            }
            /* chat duration end */
          }
        });
      });

      if (bothCurrData.missed.length > 0) {
        bothCurrData.missed.forEach((schat) => {
          let addedon = new Date(schat.addedon);
          let timeAddedOn = addedon.getTime();
          tmpCategories.forEach((valObj) => {
            if (
              timeAddedOn >= valObj.fromDate &&
              timeAddedOn <= valObj.toDate
            ) {
              let createIndex = valObj.day;
              if (tmpCurrMissedChats[createIndex] === undefined) {
                tmpCurrMissedChats[createIndex] = [];
              }
              tmpCurrMissedChats[createIndex].push(schat.chat_id);
              let waittimemissed =
                schat.wait_time_missed === null ? 0 : schat.wait_time_missed;

              if (tmpWaitTimemissed[createIndex] === undefined) {
                tmpWaitTimemissed[createIndex] = [];
              }
              tmpWaitTimemissed[createIndex].push(waittimemissed);

              if (tmpRatings[createIndex] === undefined) {
                tmpRatings[createIndex] = [];
              }
              let rating = schat.rating === null ? 0 : schat.rating;
              if (rating > 0) {
                showSatisfaction = true;
                tmpMissedRatings.push(rating);
                tmpRatings[createIndex].push(rating);
              }

              /* Wait time missed chats start */
              if (waittimemissed < 15) {
                mtime_served_lessthan15++;
              } else if (waittimemissed >= 15 && waittimemissed <= 30) {
                mtime_served_inbtw15_30++;
              } else if (waittimemissed >= 31 && waittimemissed <= 45) {
                mtime_served_inbtw31_45++;
              } else if (waittimemissed >= 46 && waittimemissed <= 60) {
                mtime_served_inbtw46_60++;
              } else if (waittimemissed > 60) {
                mtime_served_greaterthan60++;
              }
              /* Wait time missed chats end */
            }
          });
        });
      }
    } else if (this.state.chart_base === "hourly") {
      if (bothCurrData.served.length > 0) {
        bothCurrData.served.forEach((schat) => {
          let addedon = new Date(schat.addedon);
          let hourAddedOn = addedon.getHours();
          let monthAddedOn = addedon.getMonth() + 1;
          let yearAddedOn = addedon.getFullYear();
          let createIndex =
            hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          if (tmpCurrServedChats[createIndex] === undefined) {
            tmpCurrServedChats[createIndex] = [];
          }
          if (tmpResponseTime[createIndex] === undefined) {
            tmpResponseTime[createIndex] = [];
          }
          if (tmpChatDuration[createIndex] === undefined) {
            tmpChatDuration[createIndex] = [];
          }
          if (tmpWaitTimeServed[createIndex] === undefined) {
            tmpWaitTimeServed[createIndex] = [];
          }
          tmpCurrServedChats[createIndex].push(schat.chat_id);
          let restime = schat.response_time === null ? 0 : schat.response_time;
          let chatduration =
            schat.chat_duration === null ? 0 : schat.chat_duration;
          let waittime = schat.wait_time === null ? 0 : schat.wait_time;
          tmpResponseTime[createIndex].push(restime);
          tmpChatDuration[createIndex].push(chatduration);
          tmpWaitTimeServed[createIndex].push(waittime);

          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          let rating = schat.rating === null ? 0 : schat.rating;
          if (rating > 0) {
            showSatisfaction = true;
            tmpServedRatings.push(rating);
            tmpRatings[createIndex].push(rating);
          }

          /* Response time chats start */
          if (restime < 15) {
            response_lessthan15++;
          } else if (restime >= 15 && restime <= 30) {
            response_inbtw15_30++;
          } else if (restime >= 31 && restime <= 45) {
            response_inbtw31_45++;
          } else if (restime >= 46 && restime <= 60) {
            response_inbtw46_60++;
          } else if (restime > 60) {
            response_greaterthan60++;
          }
          /* Response time chats end */

          /* Wait time served chats start */
          if (waittime < 15) {
            wtime_served_lessthan15++;
          } else if (waittime >= 15 && waittime <= 30) {
            wtime_served_inbtw15_30++;
          } else if (waittime >= 31 && waittime <= 45) {
            wtime_served_inbtw31_45++;
          } else if (waittime >= 46 && waittime <= 60) {
            wtime_served_inbtw46_60++;
          } else if (waittime > 60) {
            wtime_served_greaterthan60++;
          }
          /* Wait time served chats end */

          /* chat duration start */
          if (chatduration < 600) {
            duration_lessthan10++;
          } else if (chatduration >= 600 && chatduration <= 1800) {
            duration_inbtw10_30++;
          } else if (chatduration >= 1801 && chatduration <= 3600) {
            duration_inbtw31_60++;
          } else if (chatduration >= 3601 && chatduration <= 5400) {
            duration_inbtw1h_15++;
          } else if (chatduration > 5401) {
            duration_greaterthan15++;
          }
          /* chat duration end */
        });
      }
      if (bothCurrData.missed.length > 0) {
        bothCurrData.missed.forEach((schat) => {
          let addedon = new Date(schat.addedon);
          let hourAddedOn = addedon.getHours();
          let monthAddedOn = addedon.getMonth() + 1;
          let yearAddedOn = addedon.getFullYear();
          let createIndex =
            hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          if (tmpCurrMissedChats[createIndex] === undefined) {
            tmpCurrMissedChats[createIndex] = [];
          }
          tmpCurrMissedChats[createIndex].push(schat.chat_id);
          let waittimemissed =
            schat.wait_time_missed === null ? 0 : schat.wait_time_missed;

          if (tmpWaitTimemissed[createIndex] === undefined) {
            tmpWaitTimemissed[createIndex] = [];
          }
          tmpWaitTimemissed[createIndex].push(waittimemissed);

          if (tmpRatings[createIndex] === undefined) {
            tmpRatings[createIndex] = [];
          }
          let rating = schat.rating === null ? 0 : schat.rating;
          if (rating > 0) {
            showSatisfaction = true;
            tmpMissedRatings.push(rating);
            tmpRatings[createIndex].push(rating);
          }

          /* Wait time missed chats start */
          if (waittimemissed < 15) {
            mtime_served_lessthan15++;
          } else if (waittimemissed >= 15 && waittimemissed <= 30) {
            mtime_served_inbtw15_30++;
          } else if (waittimemissed >= 31 && waittimemissed <= 45) {
            mtime_served_inbtw31_45++;
          } else if (waittimemissed >= 46 && waittimemissed <= 60) {
            mtime_served_inbtw46_60++;
          } else if (waittimemissed > 60) {
            mtime_served_greaterthan60++;
          }
          /* Wait time missed chats end */
        });
      }
    }

    if (this.state.date_range === "custom") {
      if (this.state.chart_base === "daily") {
        for (var i = 0; i < this.state.numberOfDays; i++) {
          let tmpdate = new Date(parseInt(this.state.start_date));
          tmpdate.setDate(tmpdate.getDate() + i);
          let dayAddedOn = tmpdate.getDate();
          let monthAddedOn = tmpdate.getMonth() + 1;
          let yearAddedOn = tmpdate.getFullYear();
          let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
        }

        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }

          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDate);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }
          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "30_days") {
      if (this.state.chart_base === "daily") {
        for (var i = 0; i <= 29; i++) {
          let tmpdate = new Date(today);
          tmpdate.setDate(tmpdate.getDate() - i);
          let dayAddedOn = tmpdate.getDate();
          let monthAddedOn = tmpdate.getMonth() + 1;
          let yearAddedOn = tmpdate.getFullYear();
          let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
        }
        tmpCategories.reverse();
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }

          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDate);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }
          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "14_days") {
      if (this.state.chart_base === "weekly") {
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.fromDate);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }
          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      } else {
        for (var i = 0; i <= 13; i++) {
          let tmpdate = new Date(today);
          tmpdate.setDate(tmpdate.getDate() - i);
          let dayAddedOn = tmpdate.getDate();
          let monthAddedOn = tmpdate.getMonth() + 1;
          let yearAddedOn = tmpdate.getFullYear();
          let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
          tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
        }
        tmpCategories.reverse();
        tmpCategories.forEach((mydate) => {
          xaxis_categories.push(mydate.date);
          const day = mydate.day;
          if (tmpCurrServedChats[day] !== undefined) {
            series_data_served.push(tmpCurrServedChats[day].length);
          } else {
            series_data_served.push(0);
          }
          if (tmpCurrMissedChats[day] !== undefined) {
            series_data_missed.push(tmpCurrMissedChats[day].length);
          } else {
            series_data_missed.push(0);
          }
          if (
            tmpResponseTime[day] !== undefined &&
            tmpResponseTime[day].length > 0
          ) {
            let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            let avgInMinutes = Math.ceil(avgResponseTime / 60);
            series_response_time.push(avgInMinutes);
          } else {
            series_response_time.push(0);
          }

          if (
            tmpChatDuration[day] !== undefined &&
            tmpChatDuration[day].length > 0
          ) {
            let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
              all += item;
              return all;
            }, 0);
            let avgChatDur = Math.ceil(
              chatDurSum / tmpChatDuration[day].length
            );
            let avgInMinutes = Math.ceil(avgChatDur / 60);
            series_chat_duration.push(avgInMinutes);
          } else {
            series_chat_duration.push(0);
          }

          if (
            tmpWaitTimeServed[day] !== undefined &&
            tmpWaitTimeServed[day].length > 0
          ) {
            let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeServedTime = Math.ceil(
              reswtTimeSum / tmpWaitTimeServed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
            series_wait_time_served.push(avgInMinutes);
          } else {
            series_wait_time_served.push(0);
          }

          if (
            tmpWaitTimemissed[day] !== undefined &&
            tmpWaitTimemissed[day].length > 0
          ) {
            let waitTimeSum = tmpWaitTimemissed[day].reduce(function (
              all,
              item
            ) {
              all += item;
              return all;
            },
            0);
            let avgWtTimeMissedTime = Math.ceil(
              waitTimeSum / tmpWaitTimemissed[day].length
            );
            let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
            series_wait_time_missed.push(avgInMinutes);
          } else {
            series_wait_time_missed.push(0);
          }

          if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
            /* let resRatings = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                        series_ratings.push(avgRatings); */
            series_ratings.push(tmpRatings[day].length);
          } else {
            series_ratings.push(0);
          }
        });
      }
    } else if (this.state.date_range === "7_days") {
      for (var i = 0; i <= 6; i++) {
        let tmpdate = new Date(today);
        tmpdate.setDate(tmpdate.getDate() - i);
        let dayAddedOn = tmpdate.getDate();
        let monthAddedOn = tmpdate.getMonth() + 1;
        let yearAddedOn = tmpdate.getFullYear();
        let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
        tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
      }
      tmpCategories.reverse();
      tmpCategories.forEach((mydate) => {
        xaxis_categories.push(mydate.date);
        const day = mydate.day;
        if (tmpCurrServedChats[day] !== undefined) {
          series_data_served.push(tmpCurrServedChats[day].length);
        } else {
          series_data_served.push(0);
        }
        if (tmpCurrMissedChats[day] !== undefined) {
          series_data_missed.push(tmpCurrMissedChats[day].length);
        } else {
          series_data_missed.push(0);
        }

        if (
          tmpResponseTime[day] !== undefined &&
          tmpResponseTime[day].length > 0
        ) {
          let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgInMinutes = 0;
          if (resTimeSum > 0) {
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[day].length
            );
            avgInMinutes = Math.ceil(avgResponseTime / 60);
          }
          series_response_time.push(avgInMinutes);
        } else {
          series_response_time.push(0);
        }

        if (
          tmpChatDuration[day] !== undefined &&
          tmpChatDuration[day].length > 0
        ) {
          let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
          let avgInMinutes = Math.ceil(avgChatDur / 60);
          series_chat_duration.push(avgInMinutes);
        } else {
          series_chat_duration.push(0);
        }

        if (
          tmpWaitTimeServed[day] !== undefined &&
          tmpWaitTimeServed[day].length > 0
        ) {
          let reswtTimeSum = tmpWaitTimeServed[day].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          let avgWtTimeServedTime = Math.ceil(
            reswtTimeSum / tmpWaitTimeServed[day].length
          );
          let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
          series_wait_time_served.push(avgInMinutes);
        } else {
          series_wait_time_served.push(0);
        }

        if (
          tmpWaitTimemissed[day] !== undefined &&
          tmpWaitTimemissed[day].length > 0
        ) {
          let waitTimeSum = tmpWaitTimemissed[day].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgWtTimeMissedTime = Math.ceil(
            waitTimeSum / tmpWaitTimemissed[day].length
          );
          let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
          series_wait_time_missed.push(avgInMinutes);
        } else {
          series_wait_time_missed.push(0);
        }

        if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
          /*let resRatings = tmpRatings[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgRatings = Math.ceil(resRatings / tmpRatings[day].length);
                    series_ratings.push(avgRatings); */
          series_ratings.push(tmpRatings[day].length);
        } else {
          series_ratings.push(0);
        }
      });
    } else if (
      this.state.date_range === "today" ||
      this.state.date_range === "yesterday"
    ) {
      let tmpdate;
      let tmpdate2 = new Date();
      if (this.state.date_range === "yesterday") {
        tmpdate = 23;
        tmpdate2.setDate(tmpdate2.getDate() - 1);
      } else {
        tmpdate = new Date();
        tmpdate = tmpdate.getHours();
      }

      for (var i = 0; i <= tmpdate; i++) {
        /* let tmpdate2;
                if(this.state.date_range === "yesterday") {
                    tmpdate2 = new Date();
                    tmpdate2.setDate(tmpdate2.getDate()-1);
                    tmpdate2.setHours(i);
                } else {
                    tmpdate2 = new Date();
                    tmpdate2.setHours(i);
                } */
        tmpdate2.setHours(i);
        let monthAddedOn = tmpdate2.getMonth() + 1;
        let yearAddedOn = tmpdate2.getFullYear();
        let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
        tmpCategories.push({ date: tmpdate2.getTime(), hour: createIndex });
      }

      tmpCategories.forEach((mydate) => {
        xaxis_categories.push(mydate.date);
        const hour = mydate.hour;
        if (tmpCurrServedChats[hour] !== undefined) {
          series_data_served.push(tmpCurrServedChats[hour].length);
        } else {
          series_data_served.push(0);
        }

        if (tmpCurrMissedChats[hour] !== undefined) {
          series_data_missed.push(tmpCurrMissedChats[hour].length);
        } else {
          series_data_missed.push(0);
        }

        if (
          tmpResponseTime[hour] !== undefined &&
          tmpResponseTime[hour].length > 0
        ) {
          let resTimeSum = tmpResponseTime[hour].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgInMinutes = 0;
          if (resTimeSum > 0) {
            let avgResponseTime = Math.ceil(
              resTimeSum / tmpResponseTime[hour].length
            );
            avgInMinutes = Math.ceil(avgResponseTime / 60);
          }
          series_response_time.push(avgInMinutes);
        } else {
          series_response_time.push(0);
        }

        if (
          tmpChatDuration[hour] !== undefined &&
          tmpChatDuration[hour].length > 0
        ) {
          let chatDurSum = tmpChatDuration[hour].reduce(function (all, item) {
            all += item;
            return all;
          }, 0);
          let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[hour].length);
          let avgInMinutes = Math.ceil(avgChatDur / 60);
          series_chat_duration.push(avgInMinutes);
        } else {
          series_chat_duration.push(0);
        }

        if (
          tmpWaitTimeServed[hour] !== undefined &&
          tmpWaitTimeServed[hour].length > 0
        ) {
          let reswtTimeSum = tmpWaitTimeServed[hour].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          let avgWtTimeServedTime = Math.ceil(
            reswtTimeSum / tmpWaitTimeServed[hour].length
          );
          let avgInMinutes = Math.ceil(avgWtTimeServedTime / 60);
          series_wait_time_served.push(avgInMinutes);
        } else {
          series_wait_time_served.push(0);
        }

        if (
          tmpWaitTimemissed[hour] !== undefined &&
          tmpWaitTimemissed[hour].length > 0
        ) {
          let waitTimeSum = tmpWaitTimemissed[hour].reduce(function (
            all,
            item
          ) {
            all += item;
            return all;
          },
          0);
          let avgWtTimeMissedTime = Math.ceil(
            waitTimeSum / tmpWaitTimemissed[hour].length
          );
          let avgInMinutes = Math.ceil(avgWtTimeMissedTime / 60);
          series_wait_time_missed.push(avgInMinutes);
        } else {
          series_wait_time_missed.push(0);
        }

        if (tmpRatings[hour] !== undefined && tmpRatings[hour].length > 0) {
          /*let resRatings = tmpRatings[hour].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgRatings = Math.ceil(resRatings / tmpRatings[hour].length);
                    series_ratings.push(avgRatings);*/
          series_ratings.push(tmpRatings[hour].length);
        } else {
          series_ratings.push(0);
        }
      });
    }

    /* For charting end  */
    series = [
      { name: "Chats completed", color: "#77c66d", data: series_data_served },
      { name: "Chats missed", color: "#eb867d", data: series_data_missed },
    ];
    options.xaxis = {
      type: xaxis_type, ////"datetime",
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };
    chatTiming_series = [
      { name: "Response Time", color: "#7CC771", data: series_response_time },
      { name: "Chat Duration", color: "#EBBB58", data: series_chat_duration },
      {
        name: "Wait Time (Completed)",
        color: "#71B2E3",
        data: series_wait_time_served,
      },
      {
        name: "Wait Time (Missed)",
        color: "#E3706A",
        data: series_wait_time_missed,
      },
    ];
    chatTiming_options.xaxis = {
      type: xaxis_type,
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };

    satisfaction_options.xaxis = {
      type: xaxis_type,
      categories: xaxis_categories,
      labels: {
        formatter: this.formatXAxis,
      },
    };

    satisfaction_series = [{ name: "Chats rated", data: series_ratings }];
    response_series = [
      {
        name: "Number of Chats",
        data: [
          response_lessthan15,
          response_inbtw15_30,
          response_inbtw31_45,
          response_inbtw46_60,
          response_greaterthan60,
        ],
      },
    ];
    waittimeserved_series = [
      {
        name: "Number of Chats",
        data: [
          wtime_served_lessthan15,
          wtime_served_inbtw15_30,
          wtime_served_inbtw31_45,
          wtime_served_inbtw46_60,
          wtime_served_greaterthan60,
        ],
      },
    ];
    chatduration_series = [
      {
        name: "Number of Chats",
        data: [
          duration_lessthan10,
          duration_inbtw10_30,
          duration_inbtw31_60,
          duration_inbtw1h_15,
          duration_greaterthan15,
        ],
      },
    ];
    waittimemissed_series = [
      {
        name: "Number of Chats",
        data: [
          mtime_served_lessthan15,
          mtime_served_inbtw15_30,
          mtime_served_inbtw31_45,
          mtime_served_inbtw46_60,
          mtime_served_greaterthan60,
        ],
      },
    ];

    /* NPS start */

    /* for previous period ratings start */
    previouschats.forEach((single_chat) => {
      let rating = single_chat.rating === null ? 0 : single_chat.rating;
      if (rating > 0) {
        tmpPrevRatings.push(rating);
      }
    });

    let tmpPrevRatedChatsObj = tmpPrevRatings.reduce(
      (all, item) => {
        all["good"] += item;
        return all;
      },
      { good: 0 }
    );

    /* for previous period ratings End */

    let allratedChats = tmpServedRatings.concat(tmpMissedRatings);
    let tmpRatedChatsArray = allratedChats.reduce(
      (all, item) => {
        all["good"] += item;
        return all;
      },
      { good: 0 }
    );
    let totalRatedPoints = allratedChats.length * 5;
    var goodPercentage = Math.ceil(
      (tmpRatedChatsArray.good / totalRatedPoints) * 100
    );
    if (isNaN(goodPercentage)) {
      goodPercentage = 0;
    }
    var badPercentage = 100 - goodPercentage;
    nps_series = [goodPercentage, badPercentage];

    var goodPrevPercentage = 0;
    let allPrevRatedChats = tmpPrevRatings.length;
    if (allPrevRatedChats > 0) {
      let totalPrevRatedPoints = allPrevRatedChats * 5;
      goodPrevPercentage = Math.ceil(
        (tmpPrevRatedChatsObj.good / totalPrevRatedPoints) * 100
      );
      if (isNaN(goodPrevPercentage)) {
        goodPrevPercentage = 0;
      }
    }

    /* NPS end */

    /* Get percentage start */

    let sumCurrChatTotal = currentchats.length;
    let sumPrevChatTotal = previouschats.length;
    let sumPrevChatServed = bothPrevData.served.length;
    let sumPrevChatMissed = bothPrevData.missed.length;
    let sumCurrChatServed = bothCurrData.served.length;
    let sumCurrChatMissed = bothCurrData.missed.length;

    let tooltip_total_chats = "";
    let tooltip_served_chats = "";
    let tooltip_missed_chats = "";
    let tooltip_nps_rating = "";
    let npscurrentAndPrev = "";
    let percentage_total_chats = 0;
    let percentage_served_chats = 0;
    let percantage_missed_chats = 0;
    if (goodPercentage === goodPrevPercentage) {
      npscurrentAndPrev = "equal";
      tooltip_nps_rating =
        "Previous period satisfaction " + goodPrevPercentage + "%.";
    } else if (goodPercentage > goodPrevPercentage) {
      npscurrentAndPrev = "up";
      let diffpr = goodPercentage - goodPrevPercentage;
      diffpr = diffpr.toFixed(2);
      tooltip_nps_rating =
        "Previous period satisfaction " +
        goodPrevPercentage +
        "%. Increase of " +
        diffpr +
        "%";
    } else {
      npscurrentAndPrev = "down";
      let diffpr = goodPrevPercentage - goodPercentage;
      diffpr = diffpr.toFixed(2);
      tooltip_nps_rating =
        "Previous period satisfaction " +
        goodPrevPercentage +
        "%. Decrease of " +
        diffpr +
        "%";
    }

    if (sumCurrChatTotal === sumPrevChatTotal) {
      tooltip_total_chats = "Total chat in previous period are same.";
    } else if (sumCurrChatTotal > sumPrevChatTotal) {
      if (sumPrevChatTotal === 0) {
        tooltip_total_chats =
          "Total chat in previous period " + sumPrevChatTotal;
      } else {
        let diff = sumCurrChatTotal - sumPrevChatTotal;
        let percentage = (diff * 100) / sumPrevChatTotal;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percentage_total_chats = percentage;
        tooltip_total_chats =
          "Total chat in previous period " +
          sumPrevChatTotal +
          " Increased of " +
          percentage +
          "%";
      }
    } else {
      let diff = sumPrevChatTotal - sumCurrChatTotal;
      let percentage = (diff * 100) / sumPrevChatTotal;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percentage_total_chats = percentage;
      tooltip_total_chats =
        "Total chat in previous period " +
        sumPrevChatTotal +
        " Decreased of " +
        percentage +
        "%";
    }

    if (sumPrevChatServed === sumCurrChatServed) {
      tooltip_served_chats = "Completed chat in previous period are same.";
    } else if (sumCurrChatServed > sumPrevChatServed) {
      if (sumPrevChatServed > 0) {
        let diff = sumCurrChatServed - sumPrevChatServed;
        let percentage = (diff * 100) / sumPrevChatServed;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percentage_served_chats = percentage;
        tooltip_served_chats =
          "Completed chat in previous period " +
          sumPrevChatServed +
          " Increased of " +
          percentage +
          "%";
      } else {
        tooltip_served_chats =
          "Completed chat in previous period " + sumPrevChatServed;
      }
    } else {
      let diff = sumPrevChatServed - sumCurrChatServed;
      let percentage = (diff * 100) / sumPrevChatServed;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percentage_served_chats = percentage;
      tooltip_served_chats =
        "Completed chat in previous period " +
        sumPrevChatServed +
        " Decreased of " +
        percentage +
        "%";
    }

    if (sumCurrChatMissed === sumPrevChatMissed) {
      tooltip_missed_chats = "Missed chat in previous period are same.";
    } else if (sumCurrChatMissed > sumPrevChatMissed) {
      if (sumPrevChatMissed > 0) {
        let diff = sumCurrChatMissed - sumPrevChatMissed;
        let percentage = (diff * 100) / sumPrevChatMissed;
        percentage = percentage.toFixed(2);
        if (isNaN(percentage)) {
          percentage = 0;
        }
        percantage_missed_chats = percentage;
        tooltip_missed_chats =
          "Missed chat in previous period " +
          sumPrevChatMissed +
          " Increased of " +
          percentage +
          "%";
      } else {
        tooltip_missed_chats =
          "Missed chat in previous period " + sumPrevChatMissed;
      }
    } else {
      let diff = sumPrevChatMissed - sumCurrChatMissed;
      let percentage = (diff * 100) / sumPrevChatMissed;
      percentage = percentage.toFixed(2);
      if (isNaN(percentage)) {
        percentage = 0;
      }
      percantage_missed_chats = percentage;
      tooltip_missed_chats =
        "Missed chat in previous period " +
        sumPrevChatMissed +
        " Decreased of " +
        percentage +
        "%";
    }

    let chatRatedPercentage = (allratedChats.length * 100) / sumCurrChatTotal;
    chatRatedPercentage = chatRatedPercentage.toFixed(2);
    if (isNaN(chatRatedPercentage)) {
      chatRatedPercentage = 0;
    }

    /* Get percentage end  */
    this.setState(
      {
        chats_total: sumCurrChatTotal,
        chats_served: sumCurrChatServed,
        chats_missed: sumCurrChatMissed,
        prev_chats_total: sumPrevChatTotal,
        prev_chats_served: sumPrevChatServed,
        prev_chats_missed: sumPrevChatMissed,
        tooltip_total_chats: tooltip_total_chats,
        tooltip_served_chats: tooltip_served_chats,
        tooltip_missed_chats: tooltip_missed_chats,
        tooltip_nps_rating: tooltip_nps_rating,
        series: series,
        chatTiming_series: chatTiming_series,
        response_series: response_series,
        waittimeserved_series: waittimeserved_series,
        chatduration_series: chatduration_series,
        waittimemissed_series: waittimemissed_series,
        satisfaction_series: satisfaction_series,
        nps_series: nps_series,
        total_rated_chats: allratedChats.length,
        showSatisfaction: showSatisfaction,
        chatRatedPercentage: chatRatedPercentage,
        percentage_total_chats,
        percentage_served_chats,
        percantage_missed_chats,
      },
      () => {
        this.setState({
          options: options,
          chatTiming_options: chatTiming_options,
          satisfaction_options: satisfaction_options,
          npscurrentAndPrev: npscurrentAndPrev,
          nps_options: nps_options,
          loader: false,
        });
      }
    );
  };

  sideMenuePhone = () => {
    try {
      document.getElementById("sidebarnavChild").classList.toggle("sideMenue");
    } catch (err) {}
  };

  hideDummyData = () => {
    this.setState({
      showDummyData: false,
      loader: true,
    });
    this.getDomainsList();
    this.getchats();
  };

  render() {
    let dateShow = "";
    if (this.state.date_range === "custom") {
      dateShow = "manage-icon";
    }

    return (
      <>
        <NotificationContainer />
        <ReactTooltip place={"bottom"} effect={"solid"} multiline={true} />

        <div
          className="loading"
          style={{ display: this.state.loader ? "block" : "none" }}
        >
          Loading&#8230;
        </div>
        <LeftPanel />
        <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
          <h3 className="heding-text">Analytics</h3>
          <div className="ml-auto"></div>
          <div className="mobile-menu" onClick={this.sideMenuePhone}>
            <svg
              id="open-menu"
              xmlns="http://www.w3.org/2000/svg"
              width="26.073"
              height="18.83"
              viewBox="0 0 26.073 18.83"
            >
              <path
                id="Path_2519"
                data-name="Path 2519"
                d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
                transform="translate(0 -114.339)"
              />
              <path
                id="Path_2520"
                data-name="Path 2520"
                d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
              />
              <path
                id="Path_2521"
                data-name="Path 2521"
                d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
                transform="translate(0 -228.674)"
              />
            </svg>
          </div>
        </header>
      {this.state.payment_status?  <div className="page-wrapper sidebar-collapse" data-mobile-height="">
          <div className="main-wrapper container-fluid">
            <div className="chatform-Preview">
              <div className="container-fluid">
                {/* Filters Start  */}
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                    <div className="pb-4">
                      <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Conversation Overview</h3>
                        {this.state.showDummyData === true ? (
                          <p
                            style={{
                              width: "100%",
                              textAlign: "center",
                              marginBottom: "15px",
                            }}
                          >
                            <span
                              style={{
                                padding: "9px 11px",
                                background: "#ffffff",
                                borderRadius: "15px",
                                border: "1px solid #ffad1e",
                                fontSize: "13px",
                              }}
                            >
                              <span
                                style={{
                                  margin: "10px",
                                  borderRadius: "50%",
                                  border: "2px solid #ffad1e",
                                  padding: "2px 8px",
                                  textAlign: "center",
                                }}
                              >
                                i
                              </span>{" "}
                              This is a sample report.
                            </span>
                          </p>
                        ) : null}
                      </div>
                    </div>

                    <div className="bg-white left-widgetsetings-box k-flex align-items-center px-5 py-5 mob-analytics">
                      <div className="k-flex align-items-center label-style">
                        {/* <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150 mob-w-100">
                                                        <InputLabel id="ddomain-outlined-label">Widget</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="demo-simple-select-outlined-label" id="domain" name="domain" value={this.state.domain} onChange={(e) => this.changeFilterHandle(e)} label="Widget">
                                                            <MenuItem value="all">All</MenuItem>
                                                            {this.state.domain_list.map((domn, indx) => {
                                                                return <MenuItem key={indx} value={domn}>{domn}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div> */}
                        {/* <span>
                                                    <select className="customArrow" name="domain" id="domain" onChange={(e) => this.changeFilterHandle(e)}>
                                                        <option value="">All</option>
                                                        {this.state.domain_list.map((domn, indx) => {
                                                            return <option key={indx} value={domn}>{domn}</option>
                                                        })}
                                                    </select>
                                                </span> */}
                      </div>

                      <div className="k-flex align-items-center ml-auto label-style">
                        <div
                          className={`k-flex position-relative mob-view ${dateShow}`}
                        >
                          <div className="material-select ml-5">
                            <FormControl
                              variant="outlined"
                              className="material-outlined w-150 mob-w-100"
                            >
                              <InputLabel id="date_range-outlined-label">
                                Date Range
                              </InputLabel>
                              <Select
                                MenuProps={{ disableScrollLock: true }}
                                labelId="date_range-outlined-label"
                                id="date_range"
                                name="date_range"
                                value={this.state.date_range}
                                onChange={(e) => this.changeFilterHandle(e)}
                                label="Date Range"
                              >
                                <MenuItem value="today">Today</MenuItem>
                                <MenuItem value="yesterday">Yesterday</MenuItem>
                                <MenuItem value="7_days">Last 7 days</MenuItem>
                                <MenuItem value="14_days">
                                  Last 14 days
                                </MenuItem>
                                <MenuItem value="30_days">
                                  Last 30 days
                                </MenuItem>
                                <MenuItem value="custom">Custom</MenuItem>
                              </Select>
                            </FormControl>
                          </div>

                          {/* <label className="pl-3 pr-3 mb-0 text-14 pt-3">Date range</label>
                                                    <span className="w-150 drop-view">
                                                        <select className="customArrow" name="date_range" id="date_range" onChange={(e) => this.changeFilterHandle(e)}>
                                                            <option value="today">Today</option>
                                                            <option value="yesterday">Yesterday</option>
                                                            <option value="7_days">Last 7 days</option>
                                                            <option value="14_days">Last 14 days</option>
                                                            <option value="30_days">Last 30 days</option>
                                                            <option value="custom">Custom</option>
                                                        </select>
                                                    </span> */}
                          {this.state.date_range === "custom" ? (
                            <span
                              className="pl-3 pr-3 mb-0 text-14 cursor-pointer pt-3 date-icon"
                              onClick={this.showHideDatePicker}
                            >
                              <svg
                                id="calendar"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 58.382 58.382"
                              >
                                <g id="Group_4585" data-name="Group 4585">
                                  <g id="Group_4584" data-name="Group 4584">
                                    <path
                                      id="Path_2553"
                                      data-name="Path 2553"
                                      d="M51.541,4.561H48.8V0H44.243V4.561h-30.1V0H9.578V4.561H6.842A6.849,6.849,0,0,0,0,11.4V51.541a6.849,6.849,0,0,0,6.842,6.842h44.7a6.849,6.849,0,0,0,6.842-6.842V11.4A6.849,6.849,0,0,0,51.541,4.561Zm2.281,46.98a2.283,2.283,0,0,1-2.281,2.281H6.842a2.283,2.283,0,0,1-2.281-2.281v-30.1h49.26Zm0-34.665H4.561V11.4A2.283,2.283,0,0,1,6.842,9.122H9.578v4.561h4.561V9.122h30.1v4.561H48.8V9.122h2.737A2.283,2.283,0,0,1,53.821,11.4Z"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4587"
                                  data-name="Group 4587"
                                  transform="translate(8.74 26.218)"
                                >
                                  <g
                                    id="Group_4586"
                                    data-name="Group 4586"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1782"
                                      data-name="Rectangle 1782"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4589"
                                  data-name="Group 4589"
                                  transform="translate(17.843 26.218)"
                                >
                                  <g
                                    id="Group_4588"
                                    data-name="Group 4588"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1783"
                                      data-name="Rectangle 1783"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4591"
                                  data-name="Group 4591"
                                  transform="translate(26.947 26.218)"
                                >
                                  <g
                                    id="Group_4590"
                                    data-name="Group 4590"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1784"
                                      data-name="Rectangle 1784"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4593"
                                  data-name="Group 4593"
                                  transform="translate(36.05 26.218)"
                                >
                                  <g
                                    id="Group_4592"
                                    data-name="Group 4592"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1785"
                                      data-name="Rectangle 1785"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4595"
                                  data-name="Group 4595"
                                  transform="translate(45.154 26.218)"
                                >
                                  <g
                                    id="Group_4594"
                                    data-name="Group 4594"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1786"
                                      data-name="Rectangle 1786"
                                      width="4.734"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4597"
                                  data-name="Group 4597"
                                  transform="translate(8.74 35.322)"
                                >
                                  <g
                                    id="Group_4596"
                                    data-name="Group 4596"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1787"
                                      data-name="Rectangle 1787"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4599"
                                  data-name="Group 4599"
                                  transform="translate(17.843 35.322)"
                                >
                                  <g
                                    id="Group_4598"
                                    data-name="Group 4598"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1788"
                                      data-name="Rectangle 1788"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4601"
                                  data-name="Group 4601"
                                  transform="translate(26.947 35.322)"
                                >
                                  <g
                                    id="Group_4600"
                                    data-name="Group 4600"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1789"
                                      data-name="Rectangle 1789"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4603"
                                  data-name="Group 4603"
                                  transform="translate(36.05 35.322)"
                                >
                                  <g
                                    id="Group_4602"
                                    data-name="Group 4602"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1790"
                                      data-name="Rectangle 1790"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4605"
                                  data-name="Group 4605"
                                  transform="translate(8.74 44.426)"
                                >
                                  <g
                                    id="Group_4604"
                                    data-name="Group 4604"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1791"
                                      data-name="Rectangle 1791"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4607"
                                  data-name="Group 4607"
                                  transform="translate(17.843 44.426)"
                                >
                                  <g
                                    id="Group_4606"
                                    data-name="Group 4606"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1792"
                                      data-name="Rectangle 1792"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4609"
                                  data-name="Group 4609"
                                  transform="translate(26.947 44.426)"
                                >
                                  <g
                                    id="Group_4608"
                                    data-name="Group 4608"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1793"
                                      data-name="Rectangle 1793"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4611"
                                  data-name="Group 4611"
                                  transform="translate(36.05 44.426)"
                                >
                                  <g
                                    id="Group_4610"
                                    data-name="Group 4610"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1794"
                                      data-name="Rectangle 1794"
                                      width="4.37"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                                <g
                                  id="Group_4613"
                                  data-name="Group 4613"
                                  transform="translate(45.154 35.322)"
                                >
                                  <g
                                    id="Group_4612"
                                    data-name="Group 4612"
                                    transform="translate(0 0)"
                                  >
                                    <rect
                                      id="Rectangle_1795"
                                      data-name="Rectangle 1795"
                                      width="4.734"
                                      height="4.734"
                                    />
                                  </g>
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          <span
                            className="analytics-datepicker"
                            style={{
                              display: this.state.showDateRange
                                ? "block"
                                : "none",
                            }}
                          >
                            <DateRangePicker
                              staticRanges={[]}
                              inputRanges={[]}
                              onChange={this.onDatesSelect}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={2}
                              ranges={[
                                {
                                  startDate: this.state.fill_start_date,
                                  endDate: this.state.fill_end_date,
                                  key: "selection",
                                },
                              ]}
                              direction="horizontal"
                            />
                            <div className="py-5 px-5 text-right">
                              <button
                                className="btnBlueSettings mr-3"
                                onClick={this.applyDateRange}
                              >
                                Apply
                              </button>
                              <button
                                className="btnBorderSettings mr-3"
                                onClick={this.cancelDateRange}
                              >
                                Cancel
                              </button>
                            </div>
                          </span>
                        </div>

                        <div className="material-select ml-5 mobViewDrop">
                          <FormControl
                            variant="outlined"
                            className="material-outlined w-150 mob-w-100"
                          >
                            <InputLabel id="chart_base-outlined-label">
                              Chart Base
                            </InputLabel>
                            <Select
                              MenuProps={{ disableScrollLock: true }}
                              labelId="chart_base-outlined-label"
                              id="chart_base"
                              name="chart_base"
                              value={this.state.chart_base}
                              onChange={(e) => this.changeFilterHandle(e)}
                              label="Date range"
                            >
                              {this.state.date_range === "today" ||
                              this.state.date_range === "yesterday" ? (
                                <MenuItem
                                  value="hourly"
                                  selected={
                                    this.state.chart_base === "hourly"
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  Hourly
                                </MenuItem>
                              ) : null}
                              {this.state.date_range === "7_days" ||
                              this.state.date_range === "14_days" ||
                              this.state.date_range === "30_days" ||
                              this.state.date_range === "custom" ? (
                                <MenuItem
                                  value="daily"
                                  selected={
                                    this.state.chart_base === "daily"
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  Daily
                                </MenuItem>
                              ) : null}
                              {this.state.date_range === "14_days" ||
                              this.state.date_range === "30_days" ||
                              (this.state.date_range === "custom" &&
                                this.state.numberOfDays > 14) ? (
                                <MenuItem
                                  value="weekly"
                                  selected={
                                    this.state.chart_base === "weekly"
                                      ? "selected"
                                      : ""
                                  }
                                >
                                  Weekly
                                </MenuItem>
                              ) : null}
                            </Select>
                          </FormControl>
                        </div>
                        <div className="material-select ml-5 mobViewDrop">
                          <FormControl
                            variant="outlined"
                            className="material-outlined w-150 mob-w-100"
                          >
                            <InputLabel id="chart_base-outlined-label">
                              Country
                            </InputLabel>
                            <Tooltip
                              title={
                                !this.state.enabled_country_filter
                                  ? "Country"
                                  : "It will show only last 7 days data please select proper date range"
                              }
                            >
                              <Select
                                MenuProps={{ disableScrollLock: true }}
                                labelId="chart_base-outlined-label"
                                id="chart_base"
                                name="country"
                                disabled={this.state.enabled_country_filter}
                                value={this.state.country}
                                onChange={(e) => this.changeCountryHandle(e)}
                                label="Country"
                              >
                                <MenuItem value="all" key="All">
                                  All
                                </MenuItem>
                                {Object.entries(countryListAlpha2).map(
                                  ([key, val]) => (
                                    <MenuItem value={key} key={key}>
                                      {val}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </Tooltip>
                          </FormControl>
                        </div>
                        {/* <span className="w-150 drop-value">
                                                    <select className="customArrow" name="chart_base" id="chart_base" onChange={(e) => this.changeFilterHandle(e)}>
                                                        {this.state.date_range === "today" || this.state.date_range === "yesterday" ? (<option value="hourly" selected={this.state.chart_base === "hourly" ? "selected" : ""}>Hourly</option>) : (null)}
                                                        {(this.state.date_range === "7_days" || this.state.date_range === "14_days" || this.state.date_range === "30_days" || this.state.date_range === "custom") ? (<option value="daily" selected={this.state.chart_base === "daily" ? "selected" : ""}>Daily</option>) : (null)}
                                                        {(this.state.date_range === "14_days" || this.state.date_range === "30_days" || (this.state.date_range === "custom" && this.state.numberOfDays > 14)) ? (<option value="weekly" selected={this.state.chart_base === "weekly" ? "selected" : ""}>Weekly</option>) : (null)}
                                                    </select>
                                                </span> */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Filters End  */}

                <div className="row">
                  <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 my-2">
                    <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                      <div className="chatReport-total-chats a">
                        <span className="title-total-chat">
                          Total chats{" "}
                          <img
                            alt=""
                            src={require("../../assets/img/icon/info.png")}
                            data-tip={this.state.tooltip_total_chats}
                          />
                        </span>
                        <div className="k-flex align-items-center online-time">
                          <p className="numberReport">
                            {this.state.chats_total}
                          </p>
                          {this.state.chats_total >
                          this.state.prev_chats_total ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1400"
                                  data-name="Group 1400"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,932.248v16.228"
                                    transform="translate(9522.094 -931.785)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,937.252l5.4-5.4,5.4,5.4"
                                    transform="translate(9521.327 -931.85)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_total <
                          this.state.prev_chats_total ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1401"
                                  data-name="Group 1401"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,948.476V932.248"
                                    transform="translate(9522.094 -932.248)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,931.85l5.4,5.4,5.4-5.4"
                                    transform="translate(9521.327 -920.561)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_total ===
                          this.state.prev_chats_total ? (
                            <span>--</span>
                          ) : (
                            <span style={{ marginLeft: 4, color: "#000" }}>
                              <b>
                                (
                                {parseFloat(
                                  this.state.percentage_total_chats
                                ).toFixed(2)}
                                %)
                              </b>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 my-2">
                    <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                      <div className="chatReport-total-chats">
                        <span className="title-total-chat">
                          Completed chats{" "}
                          <img
                            alt=""
                            src={require("../../assets/img/icon/info.png")}
                            data-tip={this.state.tooltip_served_chats}
                          />
                        </span>
                        <div className="k-flex align-items-center online-time">
                          <p className="numberReport">
                            {this.state.chats_served}
                          </p>
                          {this.state.chats_served >
                          this.state.prev_chats_served ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1400"
                                  data-name="Group 1400"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,932.248v16.228"
                                    transform="translate(9522.094 -931.785)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,937.252l5.4-5.4,5.4,5.4"
                                    transform="translate(9521.327 -931.85)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_served <
                          this.state.prev_chats_served ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1401"
                                  data-name="Group 1401"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,948.476V932.248"
                                    transform="translate(9522.094 -932.248)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,931.85l5.4,5.4,5.4-5.4"
                                    transform="translate(9521.327 -920.561)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_served ===
                          this.state.prev_chats_served ? (
                            <span>--</span>
                          ) : (
                            <span style={{ marginLeft: 4, color: "#000" }}>
                              <b>
                                (
                                {parseFloat(
                                  this.state.percentage_served_chats
                                ).toFixed(2)}
                                %)
                              </b>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 my-2">
                    <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                      <div className="chatReport-total-chats">
                        <span className="title-total-chat">
                          Missed chats{" "}
                          <img
                            alt=""
                            src={require("../../assets/img/icon/info.png")}
                            data-tip={this.state.tooltip_missed_chats}
                          />
                        </span>
                        <div className="k-flex align-items-center online-time">
                          <p className="numberReport">
                            {this.state.chats_missed}
                          </p>
                          {this.state.chats_missed >
                          this.state.prev_chats_missed ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1400"
                                  data-name="Group 1400"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,932.248v16.228"
                                    transform="translate(9522.094 -931.785)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,937.252l5.4-5.4,5.4,5.4"
                                    transform="translate(9521.327 -931.85)"
                                    fill="none"
                                    stroke="#77c66d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_missed <
                          this.state.prev_chats_missed ? (
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="13.631"
                                height="18.691"
                                viewBox="0 0 13.631 18.691"
                              >
                                <g
                                  id="Group_1401"
                                  data-name="Group 1401"
                                  transform="translate(1.414 1)"
                                >
                                  <path
                                    id="Path_1786"
                                    data-name="Path 1786"
                                    d="M-9516.588,948.476V932.248"
                                    transform="translate(9522.094 -932.248)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeWidth="2"
                                  />
                                  <path
                                    id="Path_1787"
                                    data-name="Path 1787"
                                    d="M-9521.327,931.85l5.4,5.4,5.4-5.4"
                                    transform="translate(9521.327 -920.561)"
                                    fill="none"
                                    stroke="#eb867d"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                  />
                                </g>
                              </svg>
                            </span>
                          ) : null}
                          {this.state.chats_missed ===
                          this.state.prev_chats_missed ? (
                            <span>--</span>
                          ) : (
                            <span style={{ marginLeft: 4, color: "#000" }}>
                              <b>
                                (
                                {parseFloat(
                                  this.state.percantage_missed_chats
                                ).toFixed(2)}
                                %)
                              </b>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-4">
                    <div className="bg-white left-widgetsetings-box">
                      <div id="chart" className="py-5 px-5">
                        <div className="chartTitle">
                          <h5>Chat Count</h5>
                        </div>
                        <div className="chart_center_M">
                          <ApexCharts
                            options={this.state.options}
                            series={this.state.series}
                            type="bar"
                            height={350}
                          />
                          {/* <div style={{ textAlign: "center" }}><img src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p></div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-4">
                    <div className="bg-white left-widgetsetings-box">
                      <div id="chart2" className="py-5 px-5">
                        <div className="chartTitle">
                          <h5>Chat Timings</h5>
                        </div>
                        <div className="chart_center_M">
                          <ApexCharts
                            options={this.state.chatTiming_options}
                            series={this.state.chatTiming_series}
                            type="line"
                            height={350}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 my-2">
                    <div className="bg-white left-widgetsetings-box py-5 px-5">
                      <ApexCharts
                        options={this.state.response_options}
                        series={this.state.response_series}
                        type="bar"
                        height={350}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "normal",
                          color: "#77d5ca",
                        }}
                      >
                        Response Time
                      </p>
                      {/* <div style={{ textAlign: "center" }}><img className="noDatafound" src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p><p style={{ textAlign: "center", fontWeight: "normal", color: '#77d5ca' }}>Response Time</p></div> */}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 my-2">
                    <div className="bg-white left-widgetsetings-box py-5 px-5">
                      <ApexCharts
                        options={this.state.chatduration_options}
                        series={this.state.chatduration_series}
                        type="bar"
                        height={350}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "normal",
                          color: "#f0c66b",
                        }}
                      >
                        Chat Duration
                      </p>
                      {/*  <div style={{ textAlign: "center" }}><img className="noDatafound" src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p><p style={{ textAlign: "center", fontWeight: "normal", color: '#f0c66b' }}>Chat Duration</p></div> */}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 my-2">
                    <div className="bg-white left-widgetsetings-box py-5 px-5">
                      <ApexCharts
                        options={this.state.waittimeserved_options}
                        series={this.state.waittimeserved_series}
                        type="bar"
                        height={350}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "normal",
                          color: "#82c1e9",
                        }}
                      >
                        Wait Time (Completed)
                      </p>
                      {/* <div style={{ textAlign: "center" }}><img className="noDatafound" src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p><p style={{ textAlign: "center", fontWeight: "normal", color: '#82c1e9' }}>Wait Time (Served)</p></div> */}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-6 col-lg-3 my-2">
                    <div className="bg-white left-widgetsetings-box py-5 px-5">
                      <ApexCharts
                        options={this.state.waittimemissed_options}
                        series={this.state.waittimemissed_series}
                        type="bar"
                        height={350}
                      />
                      <p
                        style={{
                          textAlign: "center",
                          fontWeight: "normal",
                          color: "#8dce83",
                        }}
                      >
                        Wait Time (Missed)
                      </p>
                      {/* <div style={{ textAlign: "center" }}><img className="noDatafound" src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p><p style={{ textAlign: "center", fontWeight: "normal", color: '#8dce83' }}>Wait Time (Missed)</p></div> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-6 col-sm-12 col-md-12 col-lg-6 my-4">
                    <div className="bg-white left-widgetsetings-box">
                      <div id="chart3" className="py-5 px-5">
                        <ApexCharts
                          options={this.state.satisfaction_options}
                          series={this.state.satisfaction_series}
                          type="line"
                          height={350}
                        />
                        {/* <div className="chartTitle"><h5>Satisfaction</h5></div><div style={{ textAlign: "center" }}><img src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p></div> */}
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-12 col-md-12 col-lg-6 my-4">
                    <div className="bg-white left-widgetsetings-box cc">
                      <div id="chart4" className="py-5 px-5">
                        <h5>CSAT Score</h5>
                        <div className="chartCss">
                          <ApexCharts
                            options={this.state.nps_options}
                            series={this.state.nps_series}
                            type="donut"
                            width={400}
                            height={330}
                          />
                        </div>
                        <div className="nps-satis">
                          <div
                            data-tip={this.state.tooltip_nps_rating}
                            className="k-flex justify-content-center"
                          >
                            <p className="numberReport">
                              {this.state.total_rated_chats}{" "}
                              {/*this.state.nps_series[0] */}
                            </p>
                            <p className="iconTop">
                              {this.state.npscurrentAndPrev === "up" ? (
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.631"
                                    height="18.691"
                                    viewBox="0 0 13.631 18.691"
                                  >
                                    <g
                                      id="Group_1400"
                                      data-name="Group 1400"
                                      transform="translate(1.414 1)"
                                    >
                                      <path
                                        id="Path_1786"
                                        data-name="Path 1786"
                                        d="M-9516.588,932.248v16.228"
                                        transform="translate(9522.094 -931.785)"
                                        fill="none"
                                        stroke="#77c66d"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        id="Path_1787"
                                        data-name="Path 1787"
                                        d="M-9521.327,937.252l5.4-5.4,5.4,5.4"
                                        transform="translate(9521.327 -931.85)"
                                        fill="none"
                                        stroke="#77c66d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              ) : null}
                              {this.state.npscurrentAndPrev === "down" ? (
                                <span>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.631"
                                    height="18.691"
                                    viewBox="0 0 13.631 18.691"
                                  >
                                    <g
                                      id="Group_1401"
                                      data-name="Group 1401"
                                      transform="translate(1.414 1)"
                                    >
                                      <path
                                        id="Path_1786"
                                        data-name="Path 1786"
                                        d="M-9516.588,948.476V932.248"
                                        transform="translate(9522.094 -932.248)"
                                        fill="none"
                                        stroke="#eb867d"
                                        strokeLinecap="round"
                                        strokeWidth="2"
                                      />
                                      <path
                                        id="Path_1787"
                                        data-name="Path 1787"
                                        d="M-9521.327,931.85l5.4,5.4,5.4-5.4"
                                        transform="translate(9521.327 -920.561)"
                                        fill="none"
                                        stroke="#eb867d"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              ) : null}
                              {this.state.npscurrentAndPrev === "equal" ? (
                                <span>--</span>
                              ) : null}
                            </p>
                          </div>
                          <p className="title-total-chat">
                            Chats rated ({this.state.chatRatedPercentage + "%"})
                            {/* this.state.total_rated_chats */}
                          </p>
                        </div>

                        {/* <div className="chartTitle"><h5>NPS</h5></div><div style={{ textAlign: "center" }}><img src={require("../../assets/images/noRecordes.svg")} /><p className="noRecrdDatazfound my-5 black-text">No data found for the selected filter options.</p></div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> : <PopUp />}
      </>
    );
  }
}

export default Analytics;
