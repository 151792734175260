import {commonLoginApi} from "../config/siteConfig.js";
import {qs} from 'qs';
const TOKEN_KEY = 'jwt';
var Buffer = require("buffer/").Buffer;

export const login = () => {
    //localStorage.setItem(TOKEN_KEY, 'TestLogin');
}

export const logout = () => {
    //localStorage.removeItem(TOKEN_KEY);
}

export const isLogin = () => {
   /* if (localStorage.getItem('accessToken') && localStorage.getItem('agent') ) {
        return true;
    }*/
    var loginStatus = true 
if ( localStorage.getItem('agent') ) {
       loginStatus = true;
    }
    return loginStatus;
}



/**
 * getting access token 
 * for common login api 
 * */
export const getApiAccessToken = async (requestType) => {
 


const encodeFormData = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
}
let params = {grant_type: 'password',username: 'livepedroetb',password: 'livepassword'}
if( localStorage.getItem('api_refresh_token') )
{
    params = {grant_type: 'refresh_token',refresh_token: localStorage.getItem('api_refresh_token')};
}
    

    const headerOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8','Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0'},
                body: encodeFormData(params)
            };
    return new Promise((resolve, reject) => {
    fetch(commonLoginApi+'oauth/token',headerOptions).then((response) => response.json())
                .then((responseJson) => {
                    if( responseJson.code == 401)
                  {
                       resolve();
                  }
                  else if( responseJson.code == 400 )
                  {
                      localStorage.removeItem('api_access_token');
                    localStorage.removeItem('api_refresh_token');
                    localStorage.removeItem('api_access_token_expire');
                    localStorage.removeItem('api_refresh_token_expire');
                      resolve();
                  }
                  else
                  {
                    localStorage.setItem('api_access_token',responseJson.accessToken);
                    localStorage.setItem('api_refresh_token',responseJson.refreshToken);
                    //localStorage.setItem('api_access_token_expire',responseJson.accessTokenExpiresAt);
                    localStorage.setItem('api_refresh_token_expire',responseJson.refreshTokenExpiresAt);
                    resolve();
                  }
                }).catch((error) => {
                    reject();
              console.error(error);
              console.log('nnnnnnnnnnnnnn');
                });
       });
 
}

function strToHex(str) {
    var hex = '';
    for (var i=0; i<str.length; i++) {
        hex += str.charCodeAt(i).toString(16);
    }
    return hex;
  }
  
  function hexToStr(hex) {
    var hex = hex.toString();//force conversion
    var str = '';
    for (var i = 0; (i < hex.length && hex.substr(i, 2) !== '00'); i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
  }
  
  function substr_replace (str, replace, start, length) {
  
    if (start < 0) { // start position in str
        start = start + str.length;
    }
    length = length !== undefined ? length : str.length;
    if (length < 0) {
        length = length + str.length - start;
    }
    return str.slice(0, start) + replace.substr(0, length) + replace.slice(length) + str.slice(start + length);
  }
  
  export function encryptDecrypt(action,string)
  {     
   let key = 'a2dc2a99e649f147bcabc5a99bea7d96';
    if( action == 'encrypt' ) {
  
            const buff = Buffer.from(string, 'utf-8');
            const base64 = buff.toString('base64');
        var string = base64;
           var offset =  Math.floor((Math.random() * string.length) + 1);
  
      var newstr = substr_replace(string, key, offset , 0);
      return strToHex(newstr);
    }
    else if( action == 'decrypt' ){
            string = hexToStr(string);
            if(string.search(key)>=0)
             {              
                newstr = string.replace(key,"");
                const buff = Buffer.from(newstr, 'base64');
                const str = buff.toString('utf-8');
          return str
      }
      else
      {
        return false;
      }                     
    }
    }
