import React from 'react'
import {Prompt} from 'react-router-dom'
//import { withRouter } from "react-router-dom";
// import {CustomModal} from './CustomModal'
export class RouteLeavingGuard extends React.Component {
    constructor(props) {
        super();
        this.state = {
            modalVisible: false,
            lastLocation: null,
            confirmedNavigation: false,
          }
    }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = () => this.setState({
   modalVisible: false
 })
 handleBlockedNavigation = (nextLocation) => {
   const {confirmedNavigation} = this.state
   const {shouldBlockNavigation} = this.props
  //  console.log(!confirmedNavigation);
  //  console.log(shouldBlockNavigation(nextLocation));
   if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
       this.showModal(nextLocation)
       return false
   }
   
   return true
 }
 handleConfirmNavigationClick = () => {
   const {navigate} = this.props
   const {lastLocation} = this.state
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true
      }, () => {
         // Navigate to the previous blocked location with your navigate function     
         navigate(lastLocation.pathname)
      })
   }
 }
 render() {
   const {when} = this.props
   //const {modalVisible, lastLocation} = this.state
   return (
     <>
        <Prompt
           when={when}
           message={this.handleBlockedNavigation}/>
        {/* <CustomModal
           visible={modalVisible}
           onCancel={this.closeModal}
           onConfirm={this.handleConfirmNavigationClick}/> */}
           <div className="popupShadow" style={{display:this.state.modalVisible ? 'block':'none'}}></div>
            <div className="popupInstallLivechat popupLivechat" style={{display:this.state.modalVisible ? 'block':'none'}}>

              <div className="popupHeader py-4 px-5 k-flex align-items-center">
                    <h6 className="card-title mb-0 white-text">Confirm</h6>
                    <span className="close-popup ml-auto" onClick={this.closeModal}>
                        <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                            <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                            <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                            </g>
                        </svg>
                    </span>
                </div>
                
                <div className="popupBody pb-5 px-5 text-center">
                    <div className="py-5 feilds-instruction">
                        <h6 className="card-title mb-0">Changes made are not saved. Do you wish to continue without saving data?</h6> 
                    </div>
                    <div>
                        
                        <button className="btnBlue  py-2 px-7" onClick={this.handleConfirmNavigationClick}>Yes</button>
                        &nbsp;
                        <button className="btn  py-2 px-7" onClick={this.closeModal}>No</button>
                    </div>
                </div>
            </div>
     </>
   )
 }
}
export default RouteLeavingGuard