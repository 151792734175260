import React, { memo } from 'react';
import { areEqual, FixedSizeList } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";

const ListComponent = ({ items, centerClientLoading, visitor_status, allSelectedIdes, type, closeRightSection }) => {
    const Row = memo(({ data, index, style }) => {
        // console.log("Visitor Status------->>",data,index);
        const item = data[index];
        if (visitor_status === "ongoing" && items.length > 0) {
            // console.log("outgoing- chat------");
            return (
                <div className={item.agent_read_count ? "card-body d-flex align-items-center cursor-pointer conversationLC conversationCount" : "card-body d-flex align-items-center cursor-pointer conversationLC"} count={item.agent_read_count} data-time-date={item.timevalue} style={{ ...style }} onClick={(event) => centerClientLoading(item, false, event)} id={item.id} >
                    {item.visitor_type === "chat" ? (<>
                        {(item.agent_end === "VISITOR_END_CHAT" && item.isChatend === 1) ?
                            (<div
                                style={(item.profile_pic && item.profile_pic !== "" && item.bg_color !== "fb-insta") ? { backgroundImage: 'url(' + item.profile_pic + ')' } : null} id={"ps_" + item.id} className={item.bg_color ? (allSelectedIdes.indexOf(item.id) !== -1) ? "avatar-ongoing avatar avatar-offline mr-3 " + item.bg_color + " psOngoing" : "avatar-ongoing avatar avatar-offline mr-3 " + item.bg_color : (allSelectedIdes.indexOf(item.id) !== -1) ? "avatar-ongoing avatar avatar-offline mr-3 psOngoing" : "avatar-ongoing avatar avatar-offline mr-3"}>
                                {(item.client_name.indexOf("#Visitor") === -1 || item.client_company_name) ? (<>
                                    {(item.bg_color !== "fb-messanger" && item.bg_color !== "twitter-dm" && item.bg_color !== "fb-insta" && item.bg_color !== "telegram" && item.bg_color !== "whatsapp" &&item.bg_color !== "metaFB") ? item.client_short : (null)}
                                </>) : (<span className={"flag-icon-round-" + item.country.toLowerCase().replace(/ /gi, "-") + " visitor-flags"}>
                                    <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                                </span>)}
                                {item.current_pageUrl === "Telegram" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={"https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"} /> */}
                                </span>}
                                {item.current_pageUrl === "Whatsapp" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                                </span>}
                                {item.current_pageUrl === "metaFB" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                                </span>}

                                {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /> */}
                                </span>}
                                {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                                </span>}
                                {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                            </div>) : (<div style={(item.profile_pic && item.profile_pic !== "") && item.bg_color !== "fb-insta" ? { backgroundImage: 'url(' + item.profile_pic + ')' } : null} id={"ps_" + item.id} className={item.bg_color ? "avatar-ongoing avatar avatar-" + item.client_status + " mr-3 " + item.bg_color : "avatar-ongoing avatar avatar-" + item.client_status + " mr-3"}>
                                {(item.client_name.indexOf("#Visitor") === -1 || item.client_company_name) ? (<>
                                    {(item.bg_color !== "fb-messanger" && item.bg_color !== "twitter-dm" && item.bg_color !== "fb-insta" && item.bg_color !== "telegram" && item.bg_color !== "whatsapp") ? item.client_short : (null)}
                                </>) : (<span className={"flag-icon-round-" + item.country.toLowerCase().replace(/ /gi, "-") + " visitor-flags"}>
                                    <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                                </span>)}
                                {item.current_pageUrl === "Telegram" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={"https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"} /> */}
                                </span>}
                                {item.current_pageUrl === "Whatsapp" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                                </span>}
                                {item.current_pageUrl === "metaFB" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                                </span>}
                                {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /> */}
                                </span>}
                                {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                    {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                                </span>}
                                {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                            </div>)}
                        <div className="media-body overflow-hidden" msg={item.latest_msg ? item.latest_msg.replace("</small><br/>Your call will be recorded for quality purpose.", " ") : item.latest_msg} >
                            {(item?.client_company_name && (item?.client_name === "" || item?.client_name.indexOf("#Visitor") !== -1)) ?
                                (<h6 className="text-truncate mb-0 mr-auto premiumuser">
                                    {item?.client_company_name}
                                </h6>) : (<h6 className=
                                    {
                                        (item?.bg_color === "paid-client" && item?.plan_name !== "")
                                            ? `text-truncate mb-0 mr-auto paid-customers`
                                            :
                                            ((item?.bg_color === "trial-client" && item?.plan_name !== "")
                                                ? 'text-truncate mb-0 mr-auto trial-previous-customers' : "text-truncate mb-0 mr-auto ")
                                    }
                                    paid={(item?.bg_color === "paid-client" && item?.plan_name !== "")
                                        ? "Paid" : (null)}
                                    trial={(item?.bg_color === "trial-client" && item?.plan_name !== "")
                                        ? "Trial"
                                        : null}>
                                    {item?.client_short_name}
                                </h6>)}
                            {(item.getUserPlan && item.getUserPlan.status !== 'failure' && JSON.stringify(item.getUserPlan.purchasing) !== '[]' && (item.plan_name === "" || item.plan_name === undefined)) ?
                                JSON.stringify(item.getUserPlan.purchasing).includes("yes") ?
                                    (<span className="premium-customers plan-customers">PAID</span>) : (<span className="gold-customers plan-customers">TRIAL</span>) :
                                (null)}
                            {(item.bg_color === "agent-voip-call") ? (<span className="silver-customers plan-customers">Internal</span>) : (null)}

                        </div>
                    </>) : (null)}

                    {item.visitor_type === "voip" ? (<>
                        {(item.agent_end === "VISITOR_END_CHAT" && item.isChatend === 1) ? (<div id={"ps_" + item.id} className={item.bg_color ? (allSelectedIdes.indexOf(item.id) !== -1) ? "avatar-ongoing avatar avatar-offline mr-3 " + item.bg_color + " psOngoing" : "avatar-ongoing avatar avatar-offline mr-3 " + item.bg_color : (allSelectedIdes.indexOf(item.id) !== -1) ? "avatar-ongoing avatar avatar-offline mr-3 psOngoing" : "avatar-ongoing avatar avatar-offline mr-3"}>
                            {(item.client_name.indexOf("#Visitor") === -1 || item.client_company_name) ? (<span>
                                {item.client_short}
                            </span>) : (<span className={"flag-icon-round-" + item.country.toLowerCase().replace(/ /gi, "-") + " visitor-flags"}>
                                <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                            </span>)}
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /></span>}
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                            </span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>) : (<div id={"ps_" + item.id} className={item.bg_color ? "avatar-ongoing avatar avatar-" + item.client_status + " mr-3 " + item.bg_color : "avatar-ongoing avatar avatar-" + item.client_status + " mr-3"}>
                            {(item.client_name.indexOf("#Visitor") === -1 || item.client_company_name) ? (<span>
                                {item.client_short}
                            </span>) : (<span className={"flag-icon-round-" + item.country.toLowerCase().replace(/ /gi, "-") + " visitor-flags"}>
                                <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                            </span>)}
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /></span>}
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                            </span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>)}
                        <div className="media-body overflow-hidden">
                            <div className="d-flex align-items-center mb-1">
                                {(item.client_company_name && (item.client_name === "" || item.client_name.indexOf("#Visitor") !== -1)) ? (<h6 className="text-truncate mb-0 mr-auto premiumuser">
                                    <span className="premium pakage-icon">&nbsp;</span>
                                    {item.client_company_name}
                                </h6>) : (<h6 className="text-truncate mb-0 mr-auto">
                                    {item.client_short_name}
                                </h6>)}
                                {/* For Calling icons start */}
                                <div className="hideallcallicons" style={{ display: "none", position: 'absolute', top: '17px', right: '10px' }} id={"call_starting_" + item.id}>
                                    <span onClick={() => closeRightSection()} className="calling-circle-left accept_from_ong accept-green mr-2 vibrate_calling_ani">
                                        <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="9.996" height="9.996" viewBox="0 0 13.996 13.996">
                                            <g id="Group_2755" data-name="Group 2755" transform="translate(6.998 3.732)">
                                                <g id="Group_2754" data-name="Group 2754">
                                                    <path id="Path_2242" data-name="Path 2242" d="M240.467,128a.467.467,0,1,0,0,.933,1.868,1.868,0,0,1,1.866,1.866.467.467,0,0,0,.933,0A2.8,2.8,0,0,0,240.467,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2757" data-name="Group 2757" transform="translate(6.998 1.866)">
                                                <g id="Group_2756" data-name="Group 2756">
                                                    <path id="Path_2243" data-name="Path 2243" d="M240.467,64a.467.467,0,0,0,0,.933,3.736,3.736,0,0,1,3.732,3.732.467.467,0,1,0,.933,0A4.671,4.671,0,0,0,240.467,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2759" data-name="Group 2759" transform="translate(6.998)">
                                                <g id="Group_2758" data-name="Group 2758">
                                                    <path id="Path_2244" data-name="Path 2244" d="M240.467,0a.467.467,0,1,0,0,.933,5.6,5.6,0,0,1,5.6,5.6.467.467,0,1,0,.933,0A6.539,6.539,0,0,0,240.467,0Z" transform="translate(-240 0)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2761" data-name="Group 2761" transform="translate(0 0.933)">
                                                <g id="Group_2760" data-name="Group 2760">
                                                    <path id="Path_2245" data-name="Path 2245" d="M12.6,41.331a8.66,8.66,0,0,1-3.835-.876.458.458,0,0,0-.357-.021.471.471,0,0,0-.268.238L7.4,42.189a11.735,11.735,0,0,1-4.527-4.527l1.517-.735a.466.466,0,0,0,.216-.625,8.66,8.66,0,0,1-.876-3.835A.467.467,0,0,0,3.266,32H.467A.467.467,0,0,0,0,32.467a12.611,12.611,0,0,0,12.6,12.6.467.467,0,0,0,.467-.467V41.8A.467.467,0,0,0,12.6,41.331Z" transform="translate(0 -32)" fill="#fff" />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="calling-circle-left reject_from_ong reject-red dontcallvissetmethod">
                                        <svg className="dontcallvissetmethod" xmlns="http://www.w3.org/2000/svg" width="14.048" height="14.048" viewBox="0 0 20.048 20.048">
                                            <g className="dontcallvissetmethod" id="telephone" transform="translate(6.186 4.052)">
                                                <g className="dontcallvissetmethod" id="Group_2761" data-name="Group 2761" transform="translate(13.862 5.972) rotate(135)">
                                                    <g className="dontcallvissetmethod" id="Group_2760" data-name="Group 2760">
                                                        <path className="dontcallvissetmethod" id="Path_2245" data-name="Path 2245" d="M13.67,10.126a9.4,9.4,0,0,1-4.162-.951.5.5,0,0,0-.388-.023.511.511,0,0,0-.291.258l-.8,1.647A12.734,12.734,0,0,1,3.121,6.144l1.646-.8A.506.506,0,0,0,5,4.668,9.4,9.4,0,0,1,4.05.506.506.506,0,0,0,3.544,0H.506A.506.506,0,0,0,0,.506a13.685,13.685,0,0,0,13.67,13.67.506.506,0,0,0,.506-.506V10.632A.506.506,0,0,0,13.67,10.126Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <div className="hideallcallicons" style={{ display: "none", position: 'absolute', top: '17px', right: '10px' }} id={"call_inprogress_" + item.id}>
                                    <span className="calling-circle-left mute_from_ong mute-black mr-2">
                                        <svg id="voice" xmlns="http://www.w3.org/2000/svg" width="8.731" height="16.063" viewBox="0 0 11.731 19.063">
                                            <g id="Group_2898" data-name="Group 2898" transform="translate(0)">
                                                <path id="Path_2297" data-name="Path 2297" d="M34.475,14.454a3.408,3.408,0,0,0,3.4-3.4V3.4a3.4,3.4,0,0,0-6.808,0V11.05A3.408,3.408,0,0,0,34.475,14.454Z" transform="translate(-28.609)" fill="#fff" />
                                                <path id="Path_2298" data-name="Path 2298" d="M29.925,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-8.8,0V46.109a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V47.5a5.949,5.949,0,0,0,5.132,5.819V54.3H20.693a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H25.187v-.982A5.949,5.949,0,0,0,30.319,47.5V46.109A.394.394,0,0,0,29.925,45.715Z" transform="translate(-18.588 -36.7)" fill="#fff" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span style={{ display: "none" }} className="calling-circle-left unmute_from_ong mute-black mr-2">
                                        <svg id="mute-microphone_copy" data-name="mute-microphone copy" xmlns="http://www.w3.org/2000/svg" width="11.48" height="15.063" viewBox="0 0 15.48 19.063">
                                            <g id="Group_2897" data-name="Group 2897" transform="translate(0)">
                                                <path id="Path_2294" data-name="Path 2294" d="M43.955,45.851v-2.5l-5.316,5.316a3.4,3.4,0,0,0,5.316-2.814Z" transform="translate(-32.81 -34.801)" fill="#fff" />
                                                <path id="Path_2295" data-name="Path 2295" d="M36.89,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-6.876,3.634l-1.034,1.034a5.763,5.763,0,0,0,2.778,1.151V54.3H27.658a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H32.152v-.982A5.949,5.949,0,0,0,37.284,47.5V46.109A.394.394,0,0,0,36.89,45.715Z" transform="translate(-23.679 -36.7)" fill="#fff" />
                                                <path id="Path_2296" data-name="Path 2296" d="M24.245,1.517a1.085,1.085,0,0,0-1.534,0L20.226,4V3.4a3.4,3.4,0,1,0-6.808,0V10.81l-.9.9a4.384,4.384,0,0,1-.1-.912V9.409a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V10.8a5.686,5.686,0,0,0,.4,2.077L9.4,14.828a1.085,1.085,0,0,0,1.534,1.534L24.244,3.051A1.084,1.084,0,0,0,24.245,1.517Z" transform="translate(-9.082)" fill="#fff" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="calling-circle-left reject_from_ong reject-red dontcallvissetmethod">
                                        <svg className="dontcallvissetmethod" xmlns="http://www.w3.org/2000/svg" width="14.048" height="14.048" viewBox="0 0 20.048 20.048">
                                            <g className="dontcallvissetmethod" id="telephone" transform="translate(6.186 4.052)">
                                                <g className="dontcallvissetmethod" id="Group_2761" data-name="Group 2761" transform="translate(13.862 5.972) rotate(135)">
                                                    <g className="dontcallvissetmethod" id="Group_2760" data-name="Group 2760">
                                                        <path className="dontcallvissetmethod" id="Path_2245" data-name="Path 2245" d="M13.67,10.126a9.4,9.4,0,0,1-4.162-.951.5.5,0,0,0-.388-.023.511.511,0,0,0-.291.258l-.8,1.647A12.734,12.734,0,0,1,3.121,6.144l1.646-.8A.506.506,0,0,0,5,4.668,9.4,9.4,0,0,1,4.05.506.506.506,0,0,0,3.544,0H.506A.506.506,0,0,0,0,.506a13.685,13.685,0,0,0,13.67,13.67.506.506,0,0,0,.506-.506V10.632A.506.506,0,0,0,13.67,10.126Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                {/* For Calling icons end */}
                                {/* <p id={"lastchattime_" + item.id} className="small text-white text-nowrap ml-4 timevalue showallhtmlspan">{item.timevalue}</p> */}
                            </div>
                            {(item.bg_color === "paid-client" && item.plan_name !== "") ? (<span className="premium-customers plan-customers">{item.plan_name + " Paid"}</span>) : (null)}
                            {(item.bg_color === "trial-client" && item.plan_name !== "") ? (<span className="gold-customers plan-customers">{item.plan_name + " Trial"}</span>) : (null)}
                            {(item.getUserPlan && item.getUserPlan.status !== 'failure' && JSON.stringify(item.getUserPlan.purchasing) !== '[]' && (item.plan_name === "" || item.plan_name === undefined)) ?
                                JSON.stringify(item.getUserPlan.purchasing).includes("yes") ?
                                    (<span className="premium-customers plan-customers">PAID</span>) : (<span className="gold-customers plan-customers">TRIAL</span>) :
                                (null)}
                            {(item.bg_color === "agent-voip-call") ? (<span className="silver-customers plan-customers">Internal</span>) : (null)}
                            <div className="text-truncate">
                                {item.latest_msg ? item.latest_msg.replace("</small><br/>Your call will be recorded for quality purpose.", " ") : item.latest_msg}
                            </div>
                        </div>
                    </>) : (null)}

                    {item.visitor_type === "call" ? (<>
                        {(item.agent_end === "VISITOR_END_CHAT" && item.isChatend === 1) ? (<div id={"ps_" + item.id} className="avatar-ongoing avatar avatar-offline mr-3 visitorcolor-bg-call">
                            <span className="phonecall-icon-round visitor-phone-icon"></span>
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /></span>}
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>) : (<div id={"ps_" + item.id} className={"avatar-ongoing avatar avatar-" + item.client_status + " mr-3 visitorcolor-bg-call"}>
                            <span className="phonecall-icon-round visitor-phone-icon"></span>
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span>}
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers"><img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /></span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>)}
                        <div className="media-body overflow-hidden">
                            <div className="d-flex align-items-center mb-1">
                                <h6 className="text-truncate mb-0 mr-auto">
                                    {item.client_short_name}
                                </h6>
                                <div className="hideallcallicons" style={{ display: "none", position: 'absolute', top: '17px', right: '10px' }} id={"call_starting_" + item.id}>
                                    <span onClick={() => closeRightSection()} className="calling-circle-left accept_from_ong accept-green mr-2 vibrate_calling_ani">
                                        <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="9.996" height="9.996" viewBox="0 0 13.996 13.996">
                                            <g id="Group_2755" data-name="Group 2755" transform="translate(6.998 3.732)">
                                                <g id="Group_2754" data-name="Group 2754">
                                                    <path id="Path_2242" data-name="Path 2242" d="M240.467,128a.467.467,0,1,0,0,.933,1.868,1.868,0,0,1,1.866,1.866.467.467,0,0,0,.933,0A2.8,2.8,0,0,0,240.467,128Z" transform="translate(-240 -128)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2757" data-name="Group 2757" transform="translate(6.998 1.866)">
                                                <g id="Group_2756" data-name="Group 2756">
                                                    <path id="Path_2243" data-name="Path 2243" d="M240.467,64a.467.467,0,0,0,0,.933,3.736,3.736,0,0,1,3.732,3.732.467.467,0,1,0,.933,0A4.671,4.671,0,0,0,240.467,64Z" transform="translate(-240 -64)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2759" data-name="Group 2759" transform="translate(6.998)">
                                                <g id="Group_2758" data-name="Group 2758">
                                                    <path id="Path_2244" data-name="Path 2244" d="M240.467,0a.467.467,0,1,0,0,.933,5.6,5.6,0,0,1,5.6,5.6.467.467,0,1,0,.933,0A6.539,6.539,0,0,0,240.467,0Z" transform="translate(-240 0)" fill="#fff" />
                                                </g>
                                            </g>
                                            <g id="Group_2761" data-name="Group 2761" transform="translate(0 0.933)">
                                                <g id="Group_2760" data-name="Group 2760">
                                                    <path id="Path_2245" data-name="Path 2245" d="M12.6,41.331a8.66,8.66,0,0,1-3.835-.876.458.458,0,0,0-.357-.021.471.471,0,0,0-.268.238L7.4,42.189a11.735,11.735,0,0,1-4.527-4.527l1.517-.735a.466.466,0,0,0,.216-.625,8.66,8.66,0,0,1-.876-3.835A.467.467,0,0,0,3.266,32H.467A.467.467,0,0,0,0,32.467a12.611,12.611,0,0,0,12.6,12.6.467.467,0,0,0,.467-.467V41.8A.467.467,0,0,0,12.6,41.331Z" transform="translate(0 -32)" fill="#fff" />
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="calling-circle-left reject_from_ong reject-red dontcallvissetmethod">
                                        <svg className="dontcallvissetmethod" xmlns="http://www.w3.org/2000/svg" width="14.048" height="14.048" viewBox="0 0 20.048 20.048">
                                            <g className="dontcallvissetmethod" id="telephone" transform="translate(6.186 4.052)">
                                                <g className="dontcallvissetmethod" id="Group_2761" data-name="Group 2761" transform="translate(13.862 5.972) rotate(135)">
                                                    <g className="dontcallvissetmethod" id="Group_2760" data-name="Group 2760">
                                                        <path className="dontcallvissetmethod" id="Path_2245" data-name="Path 2245" d="M13.67,10.126a9.4,9.4,0,0,1-4.162-.951.5.5,0,0,0-.388-.023.511.511,0,0,0-.291.258l-.8,1.647A12.734,12.734,0,0,1,3.121,6.144l1.646-.8A.506.506,0,0,0,5,4.668,9.4,9.4,0,0,1,4.05.506.506.506,0,0,0,3.544,0H.506A.506.506,0,0,0,0,.506a13.685,13.685,0,0,0,13.67,13.67.506.506,0,0,0,.506-.506V10.632A.506.506,0,0,0,13.67,10.126Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <div className="hideallcallicons" style={{ display: "none", position: 'absolute', top: '17px', right: '10px' }} id={"call_inprogress_" + item.id}>
                                    <span className="calling-circle-left mute_from_ong mute-black mr-2">
                                        <svg id="voice" xmlns="http://www.w3.org/2000/svg" width="8.731" height="16.063" viewBox="0 0 11.731 19.063">
                                            <g id="Group_2898" data-name="Group 2898" transform="translate(0)">
                                                <path id="Path_2297" data-name="Path 2297" d="M34.475,14.454a3.408,3.408,0,0,0,3.4-3.4V3.4a3.4,3.4,0,0,0-6.808,0V11.05A3.408,3.408,0,0,0,34.475,14.454Z" transform="translate(-28.609)" fill="#fff" />
                                                <path id="Path_2298" data-name="Path 2298" d="M29.925,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-8.8,0V46.109a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V47.5a5.949,5.949,0,0,0,5.132,5.819V54.3H20.693a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H25.187v-.982A5.949,5.949,0,0,0,30.319,47.5V46.109A.394.394,0,0,0,29.925,45.715Z" transform="translate(-18.588 -36.7)" fill="#fff" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span style={{ display: "none" }} className="calling-circle-left unmute_from_ong mute-black mr-2">
                                        <svg id="mute-microphone_copy" data-name="mute-microphone copy" xmlns="http://www.w3.org/2000/svg" width="11.48" height="15.063" viewBox="0 0 15.48 19.063">
                                            <g id="Group_2897" data-name="Group 2897" transform="translate(0)">
                                                <path id="Path_2294" data-name="Path 2294" d="M43.955,45.851v-2.5l-5.316,5.316a3.4,3.4,0,0,0,5.316-2.814Z" transform="translate(-32.81 -34.801)" fill="#fff" />
                                                <path id="Path_2295" data-name="Path 2295" d="M36.89,45.715h-.678a.4.4,0,0,0-.394.394V47.5a4.4,4.4,0,0,1-6.876,3.634l-1.034,1.034a5.763,5.763,0,0,0,2.778,1.151V54.3H27.658a.4.4,0,0,0-.394.394v.678a.4.4,0,0,0,.394.394h7.521a.4.4,0,0,0,.394-.394v-.678a.4.4,0,0,0-.394-.394H32.152v-.982A5.949,5.949,0,0,0,37.284,47.5V46.109A.394.394,0,0,0,36.89,45.715Z" transform="translate(-23.679 -36.7)" fill="#fff" />
                                                <path id="Path_2296" data-name="Path 2296" d="M24.245,1.517a1.085,1.085,0,0,0-1.534,0L20.226,4V3.4a3.4,3.4,0,1,0-6.808,0V10.81l-.9.9a4.384,4.384,0,0,1-.1-.912V9.409a.4.4,0,0,0-.394-.394h-.678a.4.4,0,0,0-.394.394V10.8a5.686,5.686,0,0,0,.4,2.077L9.4,14.828a1.085,1.085,0,0,0,1.534,1.534L24.244,3.051A1.084,1.084,0,0,0,24.245,1.517Z" transform="translate(-9.082)" fill="#fff" />
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="calling-circle-left reject_from_ong reject-red dontcallvissetmethod">
                                        <svg className="dontcallvissetmethod" xmlns="http://www.w3.org/2000/svg" width="14.048" height="14.048" viewBox="0 0 20.048 20.048">
                                            <g className="dontcallvissetmethod" id="telephone" transform="translate(6.186 4.052)">
                                                <g className="dontcallvissetmethod" id="Group_2761" data-name="Group 2761" transform="translate(13.862 5.972) rotate(135)">
                                                    <g className="dontcallvissetmethod" id="Group_2760" data-name="Group 2760">
                                                        <path className="dontcallvissetmethod" id="Path_2245" data-name="Path 2245" d="M13.67,10.126a9.4,9.4,0,0,1-4.162-.951.5.5,0,0,0-.388-.023.511.511,0,0,0-.291.258l-.8,1.647A12.734,12.734,0,0,1,3.121,6.144l1.646-.8A.506.506,0,0,0,5,4.668,9.4,9.4,0,0,1,4.05.506.506.506,0,0,0,3.544,0H.506A.506.506,0,0,0,0,.506a13.685,13.685,0,0,0,13.67,13.67.506.506,0,0,0,.506-.506V10.632A.506.506,0,0,0,13.67,10.126Z" fill="#fff" />
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                {/* <p id={"lastchattime_" + item.id} className="small text-white text-nowrap ml-4 timevalue showallhtmlspan">{item.timevalue}</p> */}
                            </div>
                            {(item.bg_color === "paid-client" && item.plan_name !== "") ? (<span className="premium-customers plan-customers">{item.plan_name + " Paid"}</span>) : (null)}
                            {(item.bg_color === "trial-client" && item.plan_name !== "") ? (<span className="gold-customers plan-customers">{item.plan_name + " Trial"}</span>) : (null)}
                            {(item.getUserPlan && item.getUserPlan.status !== 'failure' && JSON.stringify(item.getUserPlan.purchasing) !== '[]' && (item.plan_name === "" || item.plan_name === undefined)) ?
                                JSON.stringify(item.getUserPlan.purchasing).includes("yes") ?
                                    (<span className="premium-customers plan-customers">PAID</span>) : (<span className="gold-customers plan-customers">TRIAL</span>) :
                                (null)}
                            {(item.bg_color === "agent-voip-call") ? (<span className="silver-customers plan-customers">Internal</span>) : (null)}
                            <div className="text-truncate">
                                {item.latest_msg ? item.latest_msg.replace("</small><br/>Your call will be recorded for quality purpose.", " ") : item.latest_msg}
                            </div>
                        </div>
                    </>) : (null)}

                    {item.visitor_type === "sms" ? (<>
                        {(item.agent_end === "VISITOR_END_CHAT" && item.isChatend === 1) ? (<div id={"ps_" + item.id} className={item?.bg_color ? "avatar-ongoing avatar avatar-offline mr-3 " + item.bg_color : "avatar-ongoing avatar avatar-offline mr-3 visitorcolor-bg-call"}>
                           {item.start_url ==="incoming_whatsapp" ? null: <span className="phonesms-icon-round visitor-phone-icon"></span>}
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /> */}
                            </span>}
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                            </span>}

                            {item.bg_color === "whatsapp" && <span className="facebook-customers social-customers">

                            </span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>) : (<div id={"ps_" + item.id} className={item?.bg_color ? "avatar-ongoing avatar avatar-" + item.client_status + " mr-3 " + item.bg_color : "avatar-ongoing avatar avatar-" + item.client_status + " mr-3 visitorcolor-bg-call"}>
                        {item.start_url ==="incoming_whatsapp" ? null: <span className="phonesms-icon-round visitor-phone-icon"></span>}
                            {item.bg_color === "fb-messanger" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /> */}
                            </span>}
                            {item.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                                {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                            </span>}
                            {item.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                        </div>)}
                        <div className="media-body overflow-hidden">
                            <div className="d-flex align-items-center mb-1">
                                <h6 className="text-truncate mb-0 mr-auto">
                                    {item.client_short_name}
                                </h6>
                            </div>
                            {(item.bg_color === "paid-client" && item.plan_name !== "") ? (<span className="premium-customers plan-customers">{item.plan_name + " Paid"}</span>) : (null)}
                            {(item.bg_color === "trial-client" && item.plan_name !== "") ? (<span className="gold-customers plan-customers">{item.plan_name + " Trial"}</span>) : (null)}
                            {(item.getUserPlan && item.getUserPlan.status !== 'failure' && JSON.stringify(item.getUserPlan.purchasing) !== '[]' && (item.plan_name === "" || item.plan_name === undefined)) ?
                                JSON.stringify(item.getUserPlan.purchasing).includes("yes") ?
                                    (<span className="premium-customers plan-customers">PAID</span>) : (<span className="gold-customers plan-customers">TRIAL</span>) :
                                (null)}
                            {(item.bg_color === "agent-voip-call") ? (<span className="silver-customers plan-customers">Internal</span>) : (null)}

                            <div className="text-truncate">
                                {item.latest_msg ? item.latest_msg.replace("</small><br/>Your call will be recorded for quality purpose.", " ") : item.latest_msg}
                            </div>
                        </div>
                    </>) : (null)}


                </div>
            )
        } else if (visitor_status === "incoming" && items.length > 0) {
            return (
                <div key={item.client_id} className={item.agent_read_count ? "card-body d-flex align-items-center conversationLC cursor-pointer conversationCount " : "card-body d-flex align-items-center conversationLC cursor-pointer"} count={item.agent_read_count} data-time-date={item?.timevalue} style={{ ...style }} onClick={() => centerClientLoading(data[index])} id={item.id}>
                    <div style={(item?.profile_pic && item?.profile_pic !== "" && item.bg_color !== "fb-insta") ? { backgroundImage: 'url(' + item?.profile_pic + ')' } : null} className={item?.bg_color ? "avatar mr-3  " + item?.bg_color : "avatar mr-3 "}>
                        {(item?.client_name?.indexOf("#Visitor") === -1 || item?.client_company_name) ?
                            <>
                                {item?.bg_color !== "fb-messanger" && item?.bg_color !== "twitter-dm" && item?.bg_color !== "fb-insta" && item?.bg_color !== "telegram" && item?.bg_color !== "whatsapp" ? item?.client_short : (null)}</>
                            : (<span className={"flag-icon-round-" + item?.country.toLowerCase().replace(/ /gi, "-") + " visitor-flags"}>
                            </span>)}
                        {item.current_pageUrl === "Telegram" && <span className="facebook-customers social-customers">
                            {/* <img alt="Sent via facebook messanger" src={"https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg"} /> */}
                        </span>}
                        {item.current_pageUrl === "Whatsapp" && <span className="facebook-customers social-customers">
                            {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                        </span>}
                        {item.current_pageUrl === "metaFB" && <span className="facebook-customers social-customers">
                            {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/whatsapp.svg")} /> */}
                        </span>}
                        {item?.bg_color === "fb-messanger" && <span className="facebook-customers social-customers">
                            {/* <img alt="Sent via facebook messanger" src={require("../../../../assets/images/livechat/fb2.svg")} /> */}
                        </span>}
                        {item?.bg_color === "fb-insta" && <span className="facebook-customers social-customers">
                            {/* <img alt="Sent via instagram messanger" src={require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /> */}
                        </span>}
                        {item?.bg_color === "twitter-dm" && <span className="twitter-customers social-customers"><svg viewBox="0 0 24 24" aria-hidden="true" className="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span>}
                    </div>
                    <div className="media-body overflow-hidden" msg={item?.latest_msg ? item?.latest_msg.replace("</small><br/>Your call will be recorded for quality purpose.", " ") : item?.latest_msg} >
                        {(item?.client_company_name && (item?.client_name === "" || item?.client_name.indexOf("#Visitor") !== -1)) ?
                            (<h6 className="text-truncate mb-0 mr-auto premiumuser">
                                {item?.client_company_name}
                            </h6>) :
                            (<h6 className=
                                {
                                    (item?.bg_color === "paid-client" && item?.plan_name !== "")
                                        ? `text-truncate mb-0 mr-auto paid-customers`
                                        :
                                        ((item?.bg_color === "trial-client" && item?.plan_name !== "")
                                            ? 'text-truncate mb-0 mr-auto trial-previous-customers' : "text-truncate mb-0 mr-auto ")
                                }
                                paid={(item?.bg_color === "paid-client" && item?.plan_name !== "")
                                    ? "Paid" : (null)}
                                trial={(item?.bg_color === "trial-client" && item?.plan_name !== "")
                                    ? "Trial"
                                    : null}>
                                {item?.client_short_name}
                            </h6>)}

                        {(item?.getUserPlan && item?.getUserPlan.status !== 'failure' && JSON.stringify(item?.getUserPlan.purchasing) !== '[]' && (item?.plan_name === "" || item?.plan_name === undefined)) ?
                            JSON.stringify(item?.getUserPlan.purchasing).includes("yes") ?
                                (<span className="premium-customers plan-customers">PAID</span>) : (<span className="gold-customers plan-customers">TRIAL</span>) :
                            (null)}

                    </div>
                </div>
            );
        } else if (visitor_status === "new" && items.length > 0) {

            if (type === "premium") {
                return (
                    <li id={item.id} key={index + 1} style={{ ...style }} onClick={() => centerClientLoading(item)} className="px-4">
                        <div className="listing-right-panel-information py-4">
                            <div className="k-flex align-items-center">
                                {item.client_company_name && item.client_company_name !== 'Appy Pie' ? (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto premiumuser badge-icon">
                                    <span className="premium pakage-icon">&nbsp;</span>
                                    {item.client_company_name}
                                    {item.triggers_complete && item.triggers_complete === 1 && (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><g><path d="M400.268,175.599c-1.399-3.004-4.412-4.932-7.731-4.932h-101.12l99.797-157.568c1.664-2.628,1.766-5.956,0.265-8.678 C389.977,1.69,387.109,0,384.003,0H247.47c-3.234,0-6.187,1.826-7.637,4.719l-128,256c-1.323,2.637-1.178,5.777,0.375,8.294 c1.562,2.517,4.301,4.053,7.262,4.053h87.748l-95.616,227.089c-1.63,3.883-0.179,8.388,3.413,10.59 c1.382,0.845,2.918,1.254,4.446,1.254c2.449,0,4.864-1.05,6.537-3.029l273.067-324.267 C401.206,182.161,401.667,178.611,400.268,175.599z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>)}
                                </h6>) : (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto badge-icon">
                                    {item.client_company_name === 'Appy Pie' ? item.client_company_name : item.client_name}
                                    {item.triggers_complete && item.triggers_complete === 1 && (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><g><path d="M400.268,175.599c-1.399-3.004-4.412-4.932-7.731-4.932h-101.12l99.797-157.568c1.664-2.628,1.766-5.956,0.265-8.678 C389.977,1.69,387.109,0,384.003,0H247.47c-3.234,0-6.187,1.826-7.637,4.719l-128,256c-1.323,2.637-1.178,5.777,0.375,8.294 c1.562,2.517,4.301,4.053,7.262,4.053h87.748l-95.616,227.089c-1.63,3.883-0.179,8.388,3.413,10.59 c1.382,0.845,2.918,1.254,4.446,1.254c2.449,0,4.864-1.05,6.537-3.029l273.067-324.267 C401.206,182.161,401.667,178.611,400.268,175.599z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>)}
                                </h6>)}
                                <ul className="maps-all pl-2">
                                    <li className={"flag-icon-" + item.country.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                        <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                                    </li>
                                    <li className={item.operating.toLowerCase().replace(" ", "-") + " os all-flas"}>
                                        <span className="titleShowHover"><h6><strong>OS:</strong> {item.operating} </h6></span>
                                    </li>
                                    <li className={item.browser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                        <span className="titleShowHover"><h6><strong>Browser:</strong> {item.browser} </h6></span>
                                    </li>
                                </ul>
                            </div>
                            <p className="small mb-0 text-screen1 name-chat-header">{item.current_pageUrl}</p>
                        </div>
                    </li>
                )
            }
            else if (type === 'normal') {
                return (
                    <li id={item.id} key={index + 1} style={{ ...style }} onClick={() => centerClientLoading(item)} className="px-4">
                        <div className="listing-right-panel-information py-4">
                            <div className="k-flex align-items-center">
                                {item.client_company_name && item.client_company_name !== 'Appy Pie' ? (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto premiumuser badge-icon">
                                    <span className="premium pakage-icon">&nbsp;</span>
                                    {item.client_company_name}
                                    {item.triggers_complete && item.triggers_complete === 1 && (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><g><path d="M400.268,175.599c-1.399-3.004-4.412-4.932-7.731-4.932h-101.12l99.797-157.568c1.664-2.628,1.766-5.956,0.265-8.678 C389.977,1.69,387.109,0,384.003,0H247.47c-3.234,0-6.187,1.826-7.637,4.719l-128,256c-1.323,2.637-1.178,5.777,0.375,8.294 c1.562,2.517,4.301,4.053,7.262,4.053h87.748l-95.616,227.089c-1.63,3.883-0.179,8.388,3.413,10.59 c1.382,0.845,2.918,1.254,4.446,1.254c2.449,0,4.864-1.05,6.537-3.029l273.067-324.267 C401.206,182.161,401.667,178.611,400.268,175.599z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>)}
                                </h6>) : (<h6 className="visitore_heding_size text-heading-screen1 mb-0 mr-auto badge-icon">
                                    {item.client_company_name === 'Appy Pie' ? item.client_company_name : item.client_name}
                                    {item.triggers_complete && item.triggers_complete === 1 && (<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512"><g><g><path d="M400.268,175.599c-1.399-3.004-4.412-4.932-7.731-4.932h-101.12l99.797-157.568c1.664-2.628,1.766-5.956,0.265-8.678 C389.977,1.69,387.109,0,384.003,0H247.47c-3.234,0-6.187,1.826-7.637,4.719l-128,256c-1.323,2.637-1.178,5.777,0.375,8.294 c1.562,2.517,4.301,4.053,7.262,4.053h87.748l-95.616,227.089c-1.63,3.883-0.179,8.388,3.413,10.59 c1.382,0.845,2.918,1.254,4.446,1.254c2.449,0,4.864-1.05,6.537-3.029l273.067-324.267 C401.206,182.161,401.667,178.611,400.268,175.599z" /> </g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> <g> </g> </svg>)}
                                </h6>)}
                                <ul className="maps-all pl-2">
                                    <li className={"flag-icon-" + item.country.toLowerCase().replace(/ /gi, "-") + " flag all-flas"}>
                                        <span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (item.geoDetails.country_name) !== 'undefined') ? item.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof item.geoDetails.country_ip !== 'undefined') ? item.geoDetails.country_ip : ""}</h6></span>
                                    </li>
                                    <li className={item.operating.toLowerCase().replace(" ", "-") + " os all-flas"}>
                                        <span className="titleShowHover"><h6><strong>OS:</strong> {item.operating} </h6></span>
                                    </li>
                                    <li className={item.browser.toLowerCase().replace(" ", "-") + " browser all-flas"}>
                                        <span className="titleShowHover"><h6><strong>Browser:</strong> {item.browser} </h6></span>
                                    </li>
                                </ul>
                            </div>
                            <p className="small mb-0 text-screen1 name-chat-header">{item.current_pageUrl}</p>
                        </div>
                    </li>
                )
            }
            else return null
        }
        else return null;
    }, areEqual);


    return (
        <AutoSizer>
            {({ height, width }) => (
                <FixedSizeList
                    height={height}
                    itemData={items}
                    width={width}
                    itemCount={items.length}
                    itemSize={78}
                >
                    {/* {console.log(items.length)} */}
                    {Row}
                </FixedSizeList>
            )}
        </AutoSizer>
    )
};


export default ListComponent;