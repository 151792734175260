import React from 'react';
// import db from '../../config/firebase';
import rolesList from "../../localization/roles.json";
import firebaseServices from '../../firebase';
let cookieEnabled = navigator.cookieEnabled;
if (!cookieEnabled){ 
    document.cookie = "testcookie";
    cookieEnabled = document.cookie.indexOf("testcookie")!=-1;
}
if (cookieEnabled) {
    let userDetails = JSON.parse(localStorage.getItem('agent'));
    var role;
    if (userDetails !== undefined && userDetails !== null) {
        role = userDetails.role;
    }
}

function getRoles() {
    let userDetails=JSON.parse(localStorage.getItem('agent'));
    if(userDetails===null || userDetails===undefined || userDetails==='' || typeof userDetails===undefined) {
        window.location.href = "/login";
    }
    var cPath = window.location.pathname.split('/');
    if(userDetails.ownerIdentifire !== cPath[1]) {
        let newPath='/'+userDetails.ownerIdentifire;
        if(cPath[2] !== undefined && cPath[2] !== '') {
            newPath += '/'+cPath[2];
        }
        window.location.href = newPath;
    }
    let promise1 = new Promise((res,rej) => {
        // firebaseServices.db.collection('agent-roles').doc(role).get().then((docs) => {
        //     if(docs.exists) {
        //         res(docs.data());
        //     } else {
        //         res(false);
        //     }
        // })
        res(false);
    })

    let promise2 = new Promise((Resolve,Reject) => {
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').doc(role).get().then((docs) => {
            if(docs.exists) {
                Resolve(docs.data());
            } else {
                Resolve(false);
            }
        })
    })

    Promise.all([promise1,promise2]).then((result) => {
        if(result[0]!==false) {
            let name = result[0]['name'];
            let path = window.location.pathname.split('/');
            let currPath = path[2];
            // if((currPath!==undefined && currPath!=="" && currPath!=="banned-users") && name==='Agent') { commented for ticket 1328
            if((currPath!==undefined && currPath!=="") && name==='Agent') {
                let pageExist = false;
                let defaultRoles = rolesList['default'][name]['Others'];
                Object.keys(defaultRoles).map((key) => {
                    if(defaultRoles[key]===currPath) {
                        pageExist=true
                    }
                })
                if(pageExist===false) {
                    window.location.href="/"+userDetails.ownerIdentifire;
                }
            }
        } else {
            let roleType = result[1].type;
            if(roleType === 'default') {
                let name = result[1]['name'];
                let path = window.location.pathname.split('/');
                let currPath = path[2];
                // if((currPath!==undefined && currPath!=="" && currPath!=="banned-users" && currPath!=="my-notifications") && name==='Agent') { commented for ticket 1328
                if((currPath!==undefined && currPath!=="" && currPath!=="my-notifications" && currPath!=="canned-text") && name==='Agent') {
                    let pageExist = false;
                    let defaultRoles = rolesList['default'][name]['Others'];
                    Object.keys(defaultRoles).map((key) => {
                        if(defaultRoles[key]===currPath || defaultRoles[key].includes(currPath)) {
                            pageExist=true
                        }
                    })
                    if(pageExist===false) {
                        window.location.href="/"+userDetails.ownerIdentifire;
                    }
                }
            } else {
                let path = window.location.pathname.split('/');
                let currPath = path[2];
                if(currPath!=='' && currPath!==undefined) {
                    let access = result[1].access;
                    let currPage = '';
                    let pageExist = false;
                    let customRole = rolesList['custom'];
                    Object.keys(customRole).map((key) => {
                        Object.keys(customRole[key]).map(k => {
                            if(customRole[key][k].indexOf(currPath) > -1){
                                currPage = k;
                            }
                        })
                    })
                    if(currPage!=='') {
                        Object.keys(access).map(j => {
                            Object.keys(access[j]).map(m => {
                                if(access[j][m].indexOf(currPage) > -1){
                                        pageExist=true;
                                }
                            })
                        })
                    }
                    
                    if(pageExist===false) {
                        window.location.href="/"+userDetails.ownerIdentifire;
                    }
                }
            }
        }
    })
}

const RolesAccess = (props) => {
    role = props.agentDetails.role;
    if(role!==undefined && typeof role!==undefined) {
        getRoles();
        props.changeRoleAccess();
    }
    return <></>;
};

export default RolesAccess;
