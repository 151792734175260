import React from 'react';
import { NavLink } from 'react-router-dom';
// import db from '../../config/firebase';
import { getDateInUTC } from  '../livechat/components/Comman';
import moment from "moment-timezone";
import { isMobileDevice } from '../livechat/components/Comman';
import { toast } from "react-toastify";
class LeftPanel extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        console.log(this.agent)
        this.state = {
            payment_status: false,
            ownerSnapshot: "",
            showCannedResp : false
        }
    }

    componentDidMount() {
        
    }

    mobileRemovC = () => {
        if (isMobileDevice()) {
            var mobMenuD = document.getElementById("respMobiMenu");
            mobMenuD.classList.remove("mobileAddCNew");
            //Responsive Main site
            var respMain = document.getElementById("maintoproutediv");
            respMain.classList.remove("responMaineDes");
        }

    }

    render () {
        let showLeftPanel = localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent' ? false : true;
        return (
            <div className="sidebarnavChild" id="sidebarnavChild">
                <div className="tab-content h-100" role="tablist">
                    <div className="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar"> 
                            <div className="left-Menu-notlivechat py-2 drop-scroll">
                                <ul>
                                    <li><NavLink to={"/"+this.agent.ownerIdentifire+"/data-management"} activeClassName="active"> Data Management</NavLink></li>
                                </ul>  
                            </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;
