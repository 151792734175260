import React from 'react';
// import db, {storageConnetion} from "../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Resizer from 'react-image-file-resizer';
import Reactselect from 'react-select'
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig";
import { editAgentInAws, editOwnerInAws, getAgentFromId, activityLog } from "../../services/service";
//import { addAgentsInList } from "../livechat/components/Dblovefield";

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import firebaseServices from '../../firebase';
/* For Dropdown End */

var timezones = require('../../localization/timezones.json');
const dateFormat="X";

class EditAgent extends React.Component {
    constructor(props) {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {} ;
        this.accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        this.timezoneOptions = [{value:"", label: "Select Timezone"}];
        timezones.map((values) => {
            this.timezoneOptions.push({
                value: values, label: values
            })
        })
        this.state = {
            showEditAgent:'',
            first_name:'',
            last_name:'',
            alias:'',
            department:[],
            role:'',
            timezone:'',
            status:0,
            first_name_error:'',
            last_name_error:'',
            alias_error:'',
            department_error:'',
            ownerId:'',
            profile_pic:'',
            profile_pic_avatar:'',
            role_error:'',
            timezone_error:'',
            departmentList:[],
            roleList:[],
            agentId:'',
            loader:false,
            pic_ref:'',
            base64file:'',
            selectedTimezone:{},
            roleType:'',
            email:'',
            oldFileName:'',
            oldThumbnailName:'',
            file_error:''
        }
    }

    componentDidMount() {
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showEditAgent:this.props.sendAgentStatus,
            ownerId:userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('departments').get().then((docs) => {
            docs.forEach((doc) => {
                var i=0;
                var departmentList=[];
                docs.forEach((doc)=>{
                    departmentList[i] = doc.data();
                    departmentList[i]['id']=doc.id;
                    i++;
                });
                this.setState({
                    departmentList:departmentList
                })
            })
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').get().then((data) => {
            var i=0;
            var roleList=[];
            data.forEach((dat) => {
                roleList[i] = dat.data();
                roleList[i]['id']=dat.id;
                i++;
            })
            this.setState({
                roleList:roleList
            })
        })
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showEditAgent !== props.sendAgentStatus) {
          return {
            showEditAgent: props.sendAgentStatus
          }
        }
        return null
    }

    hideAddAgent = () => {
        this.props.getAgentStatus(false);
    }

    changehandle = (e) => {
        let target = e.target;
        let values;
        if(target.name==="department") {
            values = [].filter.call(target.options, o => o.selected).map(o => o.value);
        } else {
            values = target.value;
        }
        this.setState({
            [target.name]:values
        })
        let errorName = target.name+'_error';
        if(target.name==='first_name' && errorName!=='' && values==='') {
            this.setState({
                [errorName]:'First name should not be empty'
            })
        } else if(errorName!=='' && values==='' && target.name!=='alias' && target.name!=='last_name') {
            this.setState({
                [errorName]:'Plese select a '+target.name
            })
        } else {
            this.setState({
                [errorName]:''
            })
        }
    }

    handleSubmit = (e) => { 
        e.preventDefault();
        let validate = this.validation();
        if(validate===true) {
            this.setState({
                loader:true
            })
            if(this.state.alias!=='') {
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("alias", "==", this.state.alias).where("deletetime","==",0).get().then((docs) => {
                    console.log(docs);
                    let OldData=[];
                    docs.forEach((data) => {
                        OldData.push(data.id);
                    })
                    if(docs.empty===true || OldData.indexOf(this.state.agentId) === 0) {
                            this.setState({
                                loader:true
                            })
                            var filesData1 = document.getElementById("profile_picture3");
                            var filesData = filesData1.files;
                            if(this.state.roleType==='Owner') {
                                let file='';
                                let fileName=''
                                if (filesData.length > 0) {
                                    file = filesData[0];
                                    fileName = file.name;
                                }
                                this.editOwner(file, fileName)
                            } else {
                                let file='';
                                let fileName=''
                                if (filesData.length > 0) {
                                    file = filesData[0];
                                    fileName = file.name;
                                }
                                this.editAgent(file, fileName);
                            }  
                    } else {
                        this.generateAlias();
                        this.setState({
                            loader:false
                        })
                    }
                }).catch((err) => {
                    this.setState({
                        loader:false
                    })
                    toast.error("Something went wrong! Please try again after some time");
                })
                return false;
            } else {
                this.setState({
                    loader:true
                })
                const filesData1 = document.getElementById("profile_picture3");
                var filesData = filesData1.files;
                if(this.state.roleType==='Owner') {
                    let profile_image = '';
                    if (filesData.length > 0) {
                        profile_image = this.imageUploads(filesData[0]);
                    } else {
                        this.editOwner(this.state.profile_pic_orig,this.state.profile_pic,this.state.pic_ref);
                    } 
                } else {
                    let file='';
                    let fileName=''
                    if (filesData.length > 0) {
                        file = filesData[0];
                        fileName = file.name;
                    }
                    this.editAgent(file, fileName);
                }
                return false;
            }
        }
    }

    generateAlias() {
        let randId = this.makeid(5);
        //let newAlias = this.state.alias+randId;
        this.setState({
            alias_error:'Alias is not available'
        })
    }

    async imageUploads(file) {
        const ref = firebaseServices.storageConnection;
        let nameAgent = this.state.first_name + ' ' + this.state.last_name;
        let userName = nameAgent.split(' ').join('_');
        let fileExt = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : 'png';
        const name = +new Date() + "-" + userName +'.'+fileExt;
        const metadata = {
          contentType: file.type
        };
        //var _this = this
        let promise1 = new Promise(async(res,rej) => {
            const task = ref.child('profiles').child(name).put(file, metadata);
            await task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url => {
                res(url);
                if(this.state.pic_ref!== '') {
                    ref.child('profiles').child(this.state.pic_ref).delete();
                }
            })
            .catch(console.error);
        })
        let promise2 = new Promise(async(resolve,reject) => {
            Resizer.imageFileResizer(
                file,
                64,
                64,
                'PNG',
                100,
                0,
                async uri => {
                    const task = ref.child('profiles').child('avatar').child(name).put(uri, metadata);
                    await task
                    .then(snapshot => snapshot.ref.getDownloadURL())
                    .then(url => {
                        resolve(url);
                        if(this.state.pic_ref!== '') {
                            ref.child('profiles').child('avatar').child(this.state.pic_ref).delete();
                        }
                    })
                    .catch(console.error);
                        },
                    'blob'
            );
        })

        Promise.all([promise1,promise2]).then(result => {
            this.editOwner(result[0],result[1],name);
        })
    }

    editOwner = (file,fileName) => {
        let name = this.state.first_name + ' ' + this.state.last_name;
        let alias = this.state.alias ? this.state.alias : DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
        let userDetails = localStorage.getItem('agent') ?  JSON.parse(localStorage.getItem('agent')) : false;
        editOwnerInAws(alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, userDetails.oldFileName, userDetails.oldThumbnailName).then((docs) => {
            if(docs.status===200) {
                let updatedon = this.timeZoneFormat(Date.now());
                let responseData = docs.response;
                userDetails.oldFileName = responseData.userdata.pic;
                userDetails.oldThumbnailName = responseData.userdata.thumbnail;
                userDetails.first_name = responseData.userdata.first_name
                userDetails.last_name = responseData.userdata.last_name
                userDetails.profile_img = responseData.userdata.pic && responseData.userdata.pic!=='notexist' ? responseData.userdata.imageurl + '/' + responseData.userdata.pic : '';
                userDetails.profile_img_orig = responseData.userdata.pic && responseData.userdata.pic!=='notexist' ? responseData.userdata.imageurl + '/' + responseData.userdata.pic : '';
                userDetails.name = responseData.userdata.first_name + ' ' + responseData.userdata.last_name
                userDetails.timezone = this.state.timezone
                userDetails.alias = this.state.alias;
                localStorage.setItem('agent', JSON.stringify(userDetails));
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                    updatedon:updatedon,
                    departments:this.state.department, 
                    role:this.state.role,
                    timezone:this.state.timezone,
                    name:name,
                    alias:alias,
                    oldFileName:responseData.userdata.pic,
                    oldThumbnailName:responseData.userdata.thumbnail
                }).then((ref) => {
                    toast.success("Agent has been updated successfully!");
                    // this.getAgentValue();
                    this.props.getAgentStatus(false);
                    this.setState({
                        loader:false,
                        base64file:''
                    })
                }).catch((error) => {
                    console.error("Error adding document: ", error);
                    this.setState({
                        loader:false
                    })
                });
                
                activityLog('Agent updated');
            } else {
                this.setState({
                    loader:false
                })
                toast.error("Something went wrong! Pleasse try again after some time");
            }
        });
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    editAgent(file,fileName){
        let updatedon = this.timeZoneFormat(Date.now());
        // this.props.getAgentStatus(false);
        // toast.success("Agent has been updated successfully.");
        let name = this.state.first_name + ' ' + this.state.last_name;
        let alias = this.state.alias ? this.state.alias : DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
        editAgentInAws(alias, this.state.first_name , this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName)
        .then(async (results) => {
            if(results.status === 200) {
                let agentDetails = await getAgentFromId(this.state.agentId);
                if(agentDetails.status === 200) {
                    let thumbnail = agentDetails.data.thumbnail;
                    let imageulr = agentDetails.data.imageurl;
                    let pic = agentDetails.data.pic;
                    let agentDet = { sub: this.state.agentId, preferred_username : this.state.agentId, first_name: this.state.first_name, last_name:this.state.last_name, alias: this.state.alias, pic:pic, imageurl: imageulr, thumbnail:thumbnail, timezone:this.state.timezone, email: this.state.email};
                    // addAgentsInList(agentDet);
                }
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                    updatedon:updatedon,
                    departments:this.state.department, 
                    role:this.state.role,
                    timezone:this.state.timezone,
                    name:name,
                    alias:alias
                }).then((ref) => {
                    toast.success("Agent has been updated successfully!");
                    // this.getAgentValue();
                    this.props.getAgentStatus(false);
                    this.setState({
                        loader:false,
                        base64file:''
                    })
                }).catch((error) => {
                    console.error("Error adding document: ", error);
                    this.setState({
                        loader:false
                    })
                });
                
                activityLog('Agent updated');
            } else {
                toast.error("Something went wrong! Please try again after some time");
                this.setState({
                    loader:false
                })
            }
        })
    }

    makeid = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    validation() {
        let first_name_error='';
        let department_error='';
        let role_error='';
        let timezone_error='';
        let returnValue = true;
        if(this.state.first_name==''){
            returnValue = false;
            first_name_error = 'First name should not be empty';
        } else {
            first_name_error = ''
        }

        if(this.state.department.length==0){
            returnValue = false;
            department_error = 'Plese select a department';
        } else {
            department_error='';
        }

        if(this.state.role==''){
            returnValue = false;
            role_error='Plese select a role';
        } else {
            role_error='';
        }

        if(this.state.timezone==''){
            returnValue =false;
            timezone_error='Plese select a timezone';
        } else {
            timezone_error='';
        }

        this.setState({
            first_name_error : first_name_error,
            department_error : department_error,
            role_error : role_error,
            timezone_error : timezone_error
        })
        return returnValue;

    }

    componentDidUpdate() {
        if(this.state.agentId !== this.props.agentId) {
            this.setState({
                agentId:this.props.agentId,
                loader:true
            }, () => {
                this.getAgentValue();
            })
        }
    }

    getAgentValue = () => {
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).get().then((docs) => {
            let Data=docs.data();
            this.setState({
                first_name:this.props.agentDetails.firstName,
                last_name:this.props.agentDetails.lastName !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.props.agentDetails.lastName : '',
                alias:this.props.agentDetails.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.props.agentDetails.alias : '',
                email:this.props.agentDetails.email,
                oldFileName:this.props.agentDetails.pic,
                oldThumbnailName:this.props.agentDetails.thumbnail,
                profile_pic:this.props.agentDetails.thumbnail && this.props.agentDetails.thumbnail !== 'notexist' ? this.props.agentDetails.imageurl + '/' + this.props.agentDetails.thumbnail : '',
                department:Data.departments,
                role:Data.role,
                timezone:this.props.agentDetails.timezone,
                pic_ref:Data.pic_ref,
                profile_pic_orig:this.props.agentDetails.pic && this.props.agentDetails.pic !== 'notexist' ? this.props.agentDetails.imageurl + '/' + this.props.agentDetails.pic : '',
                loader:false,
                selectedTimezone: {value:this.props.agentDetails.timezone, label: this.props.agentDetails.timezone},
                roleType:Data.roleType
            })
        })
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.hideAddAgent();
    }

    handleFile = async (files) => {
        this.setState({ 'uploadFileError': '' })
        const tempFile = files[0];
        if(tempFile) {
            var fileExtension = tempFile.name.substring(
                tempFile.name.lastIndexOf('.') + 1).toLowerCase();

            if(fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'svg' || fileExtension === 'jpeg' || fileExtension === 'gif') {
                Resizer.imageFileResizer(
                    tempFile,
                    64,
                    64,
                    'PNG',
                    100,
                    0,
                    uri => {
                        this.setState({
                            base64file:uri,
                            file_error:''
                        })
                    },
                    'base64'
                );
            } else {
                this.setState({
                    file_error : 'Please upload valid image',
                    base64file:''
                })
            }
        } else {
            this.setState({
                file_error : '',
                base64file:''
            })
        }
    }

    selectTimezone = (e) => {
        if(e.value==='') {
            this.setState({
                timezone_error: 'Plese select a timezone'
            })
        } else {
            this.setState({
                timezone_error:'',
            })
        }

        this.setState({
            timezone:e.value,
            selectedTimezone:e
        })
    }

    render() {
        /*let timeZoneArray =['UTC +14','UTC +13','UTC +12:45','UTC +12','UTC +11','UTC +10:30','UTC +10','UTC +9:30','UTC +9','UTC +8:45','UTC +8','UTC +7','UTC +6:30','UTC +6','UTC +5:45','UTC +5:30','UTC +5','UTC +4:30','UTC +4','UTC +3','UTC +2','UTC +1','UTC +0','UTC -1','UTC -2','UTC -2:30','UTC -3','UTC -4','UTC -5','UTC -6','UTC -7','UTC -8','UTC -9','UTC -9:30','UTC -10','UTC -11','UTC -12'];
        let timezoneList = timezones.map((value, index) => {
            return <option key={index} value={value}>{value}</option>
        }) */
        let departmentList = this.state.departmentList.map((value,index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        })
        let rolesList = this.state.roleList.map((value, index) => {
            if(value.name !== 'Owner') {
                return <option key={index} value={value.id}>{value.name}</option>
            }
        })
        let oldProfilePic='';
        if(this.state.profile_pic != '') {
            oldProfilePic=<img src={this.state.profile_pic} />
        }

        return(
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showEditAgent ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Edit Agent</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideAddAgent}>
                                        <img alt="" src={ require('../../assets/img/icon/right-panel-crose.svg') } />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                                <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4"> 
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="first_name" label="First Name*" variant="outlined" name="first_name" value={this.state.first_name} onChange={this.changehandle} autoComplete="off" />

                                        {/* <label className="mb-1 small text-screen1">First name</label>
                                        <input type="text" placeholder="First name*" className="w-100" name="first_name" value={this.state.first_name} onChange={this.changehandle} autoComplete="off" /> */}
                                        {this.state.first_name_error ? (<p className="error">{this.state.first_name_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4"> 
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="last_name" label="Last Name" variant="outlined" name="last_name" value={this.state.last_name} onChange={this.changehandle} autoComplete="off" />
                                        {/* <label className="mb-1 small text-screen1">Last name</label>
                                        <input type="text" placeholder="Last name" className="w-100" name="last_name" value={this.state.last_name!==DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? this.state.last_name : ''} onChange={this.changehandle} autoComplete="off" /> */}
                                        {this.state.last_name_error ? (<p className="error">{this.state.last_name_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="alias" label="Alias" variant="outlined" name="alias" value={this.state.alias} onChange={this.changehandle} autoComplete="off" />
                                       {/*  <label className="mb-1 small text-screen1">Alias</label>
                                        <input type="text" placeholder="Alias" className="w-100" name="alias" value={this.state.alias} onChange={this.changehandle} autoComplete="off" /> */}
                                        {this.state.alias_error ? (<p className="error">{this.state.alias_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <input type="file" accept="image/*" id="profile_picture3" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} style={{display : "none"}} />
                                        {this.state.base64file==='' && oldProfilePic!=='' ? (<div className="avatar avatar-sm d-xl-inline-block mt-2"><span>{oldProfilePic}</span></div>) : null }
                                        {this.state.base64file!=='' ? (<div className="avatar avatar-sm d-xl-inline-block mt-2"><span><img alt="" src={this.state.base64file} /></span></div>) : null}

                                        <Button variant="contained" onClick={() => { document.getElementById("profile_picture3").click() }} color="default" startIcon={<CloudUploadIcon />}>Upload Profile Picture</Button>

                                        {/* <label className="mb-1 small text-screen1">Profile picture</label>
                                        <span className="agent-rightpanel-feilds position-relative" style={{height: '40px'}}>
                                        <input type="file" accept="image/*" className="w-100" id="profile_picture3" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} /> 
                                            <span className="add-profile-img-icon"> Add Profile picture<img alt="" src={ require('../../assets/img/icon/upload.svg') } /></span>
                                        </span> */}
                                        {this.state.file_error ? (<p className="error">{this.state.file_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <FormControl className="MuiFormControl-root MuiTextField-root form-control w-100 mb-2">
                                            <InputLabel shrink htmlFor="select-multiple-native">Team</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true}} labelId="select-multiple-native" id="select-multiple-department" label="Department" name="department" value={this.state.department ? this.state.department : ""} onChange={this.changehandle}
                                            inputProps={{
                                                id: 'select-multiple-native',
                                            }}
                                            multiple
                                            native
                                            >
                                                {departmentList}
                                            </Select>
                                        </FormControl>
                                        {/* <label className="mb-1 small text-screen1">Team</label>
                                        <select name="department" multiple className="custom-select cstm-addagent" value={this.state.department} onChange={this.changehandle}>
                                            {departmentList}
                                        </select> */}
                                        {this.state.department_error ? (<p className="error">{this.state.department_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="material-select">
                                        {this.state.roleType === 'Owner' ? (<FormControl variant="outlined" className="material-outlined w-100">
                                            <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                                            <Select labelId="demo-simple-select-outlined-label" id="role" name="role" value={this.state.role?this.state.role:""} onChange={this.changehandle} label="Role" disabled={true}>
                                                <MenuItem value="">Select Role</MenuItem>
                                            </Select>
                                        </FormControl>) : (<FormControl variant="outlined" className="material-outlined w-100">
                                            <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true}} value={this.state.role?this.state.role:""} labelId="demo-simple-select-outlined-label" id="role" name="role" onChange={this.changehandle} label="Role">
                                                <MenuItem value="">Select Role</MenuItem>
                                                {this.state.roleList.map((value, index) => {
                                                    if(value.name !== 'Owner') {
                                                        return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>)}    
                                        {/* <label className="mb-1 small text-screen1">Role</label>
                                        { this.state.roleType === 'Owner' ? (<select name="role" className="customArrow" disabled={true}>
                                            <option value="">Select Role</option>
                                        </select>) : (<select name="role" className="customArrow" value={this.state.role} onChange={this.changehandle}>
                                            <option value="">Select Role</option>
                                            {rolesList}
                                        </select>) } */}
                                        { this.state.role_error ? (<p className="error">{this.state.role_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Timezone</label>
                                        <Reactselect name="timezone" options={this.timezoneOptions} onChange={this.selectTimezone} value={this.state.selectedTimezone} />
                                        { this.state.timezone_error ? (<p className="error">{this.state.timezone_error}</p>) : (null) }
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Update</button>
                                        <button className="btnWhite" onClick={this.cancelForm}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default EditAgent;