import React from 'react';
import { Route } from 'react-router-dom';
import SidebarNavigation from "./sidebarNavigation";
import Authenticate from "./Authenticate";
import { updateAgentLastActivityTime, changeAgentStatus } from '../modules/organization/Model/DbModel';
import {getSessionCheck}  from "../config/SignupLogin";

const PrivateRoute = ({component: Component, ...rest}) => {
    getSessionCheck();
    updateAgentLastActivityTime();
    changeAgentStatus(); 
    return (
        <>
        <Authenticate {...rest} />
        <Route {...rest} render={props => (
            localStorage.getItem('agent') ?
                (<><SidebarNavigation /><Component {...props} /></>)
            : (null)
        )} />
        </>
    );
};

export default PrivateRoute;
