import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import moment from "moment-timezone";
import { FB_APP_ID, DOMAIN_NAME, API_VERSION, MAIL_FUNCTION_URL, GATEWAY_URL,FUNCTION_API_KEY } from "../../../src/config/siteConfig";
import firebaseServices from '../../firebase';

class FBMessenger extends React.Component {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    pagesSnapshot = null;
    constructor() {
        super();
        this.state = {
            pageList: [],
            page_token: {},
            loader: true
        }
    }

    /* Private functions start */

    getFbPages = () => {
        this.pagesSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('fb_pages').onSnapshot(async (querySnapshot) => {
            let pageList = [];
            let pagetoken = {};
            querySnapshot.forEach((doc) => {
                const { addedon, page_category, page_name, page_token, recipient_id, subscribe } = doc.data();
                pagetoken[recipient_id] = page_token;
                let addDate = moment(addedon).format("DD MMM YYYY hh:mm A");
                pageList.push({
                    key: doc.id,
                    addedon: addDate,
                    page_category,
                    page_name,
                    page_token,
                    recipient_id,
                    subscribe
                });
            });

            if (Object.keys(pagetoken).length > 0) {
                var loggedInAgent = this.agent;
                loggedInAgent.fb_page_token = pagetoken;
                this.agent = loggedInAgent;
                localStorage.setItem('agent', JSON.stringify(loggedInAgent));
            }

            this.setState({ page_token: pagetoken, pageList: pageList }, () => {
                this.setState({ loader: false });
            });
        });
    }

    /* Private functions end */

    componentDidMount = () => {
        this.getFbPages();
    }

    componentWillUnmount() {
        try {
            this.pagesSnapshot();
        } catch (errrr) { }
    }

    installMessenger = () => {
        let domain_name = DOMAIN_NAME;
        if (window.location.hostname === "localhost") {
            domain_name = "http://localhost:3000";
        }
        let cbUrl = domain_name + "/cb-fb-messenger/pagesetup";
        let state22 = Math.random().toString(36).substring(7);
        let params = `scrollbars=no,resizable=yes,status=no,location=no,toolbar=no,menubar=no,width=900,height=500,left=100,top=100`;
        window.open("https://www.facebook.com/v" + API_VERSION + "/dialog/oauth?response_type=code&client_id=" + FB_APP_ID + "&redirect_uri=" + cbUrl + "&state=" + state22 + "&scope=pages_messaging,pages_show_list,public_profile,email,pages_read_engagement,pages_manage_metadata", "Facebook Messenger", params);
    }

    connectDisconnectPage = (pageDetail) => {
        if(pageDetail.subscribe) {
            this.disconnectPage(pageDetail);
        } else {
            this.connectPage(pageDetail);
        }
    };

    disconnectPage = (pageDetail) => {
        this.setState({ loader: true });
        let recipientId = pageDetail.recipient_id;
        let pageToken = pageDetail.page_token ? encodeURIComponent(pageDetail.page_token) : "";
        let body22 = [`ownerId=${this.agent.ownerId}&pageId=${recipientId}&pageToken=${pageToken}`];
        //fetch(MAIL_FUNCTION_URL + "/facebook/disconnect", {
        fetch(GATEWAY_URL + "/disconnect", {
            method: "post",
            body: body22,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            this.setState({ loader: false });
        });
    }

    connectPage = (pageDetail) => {
        this.setState({ loader: true });
        let pageToken = pageDetail.page_token ? encodeURIComponent(pageDetail.page_token) : "";
        let body22 = [`ownerId=${this.agent.ownerId}&pageId=${pageDetail.recipient_id}&pageToken=${pageToken}`];
        //fetch(MAIL_FUNCTION_URL + "/facebook/connect", {
        fetch(GATEWAY_URL + "/connect", {
            method: "post",
            body: body22,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            this.setState({ loader: false });
        });
    }

    deletePage = () => {
        this.setState({ loader: true });
        let body22 = [`ownerId=${this.agent.ownerId}`];
        fetch(GATEWAY_URL + "/delete", {
            method: "post",
            body: body22,
            headers: {
                "X-API-Key" : FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            if (response.status === "success") {
                let agent22 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
                agent22.fb_page_token = {};
                this.agent = agent22;
                localStorage.setItem('agent', JSON.stringify(agent22));
                this.setState({ loader: false });
            } else {
                this.setState({ loader: false });
            }
        });
    }

    render() {
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                    <h3 className="heding-text">Settings</h3>
                    <div className="text-center k-flex align-items-center ml-auto">&nbsp;</div>
                    <div className="mobile-menu" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                        <div className="left-widgetsetings-box mb-foter-Fixed">
                                            <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                                <div className="fb-connect">
                                                    <div className="k-flex align-items-center">
                                                        <div className="fb-details">
                                                            <h2>facebook</h2>
                                                            <p>Reply to Facebook messages right from your inbox</p>
                                                            <div>
                                                                <span className="link-type"><img src={require('../../assets/img/icon/book.svg')} />User Guide</span>
                                                            </div>
                                                        </div>

                                                        <div className="fb-btn">
                                                            {this.state.pageList.length > 0 ? (<button onClick={this.deletePage}><span><img src={require('../../assets/img/icon/check-mark.svg')} /></span>Disconnect</button>) : (<button onClick={this.installMessenger}><span><img src={require('../../assets/img/icon/link-symbol.svg')} /></span>Connect</button>)}
                                                        </div>
                                                    </div>
                                                </div>

                                                {this.state.pageList.length > 0 ? (
                                                    <table className="table mt-10 fb-tbl-view">
                                                        <thead>
                                                            <tr>
                                                                <th>Page Name</th>
                                                                <th>Page Category</th>
                                                                <th>Page Id</th>
                                                                <th>Added Date</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.pageList.map((pageDet, indx) => {
                                                                return <tr key={"keyy_"+indx}>
                                                                    <td>{pageDet.page_name}</td>
                                                                    <td>{pageDet.page_category}</td>
                                                                    <td>{pageDet.recipient_id}</td>
                                                                    <td>{pageDet.addedon}</td>
                                                                    <td>
                                                                        <div className="custom-switch">
                                                                            <input type="checkbox" checked={pageDet.subscribe ? "checked" : ""} onChange={() => this.connectDisconnectPage(pageDet)} className="custom-control-input" id={"switch_"+pageDet.recipient_id}  name="connectdisconnect" />
                                                                            <label className="custom-control-label cursor-pointer" htmlFor={"switch_"+pageDet.recipient_id}></label>
                                                                            <span>{pageDet.subscribe ? "Connected" : "Not Connect"}</span>
                                                                        </div>
                                                                    </td>
                                                                    {/* <td key={"subscribe_" + indx}>{pageDet.subscribe ? "Yes" : "No"}</td>
                                                                    {pageDet.subscribe ? (null) : (<li key={"connectpage_" + indx}><button name="connectpage" onClick={() => this.connectPage(pageDet)}>Connect</button></li>)}
                                                                    {pageDet.subscribe ? (<li key={"disconnectpage_" + indx}><button name="disconnectpage" onClick={() => this.disconnectPage(pageDet)}>Disconnect</button></li>) : (null)} */}
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                ) : (null)}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

export default FBMessenger;