
var singleimage = null;
var widgetAvatarImg1 = null;
var widgetImages2 = null;
if(process.env.REACT_APP_STAGE === "dev") {

    widgetAvatarImg1 = [
        {
            wtimage: 'avatar-1',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-1.png?alt=media&token=ad588667-1cdd-4e46-be71-5719a198707d',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-1.png?alt=media&token=ad588667-1cdd-4e46-be71-5719a198707d'
        },
        {
            wtimage: 'avatar-2',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-2.png?alt=media&token=809a9698-5201-44d0-ab36-d029a5682d49',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-2.png?alt=media&token=809a9698-5201-44d0-ab36-d029a5682d49'
        },
        {
            wtimage: 'avatar-3',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-3.png?alt=media&token=ec06f98d-df66-4ced-86fc-c523fd0dd4d5',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-3.png?alt=media&token=ec06f98d-df66-4ced-86fc-c523fd0dd4d5',
        }
    ]
    singleimage = {
        wtimage: 'comments.svg',
        fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
        blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
    }

    widgetImages2 = [
        {
            wtimage: 'comments.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
        },
        {
            wtimage: 'chat.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat.svg?alt=media&token=0ad054ad-51c9-43d0-8123-d935bb258f99',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fchat.svg?alt=media&token=608f0af9-1858-4503-a8c0-e378cc441ee9'
        },
        {
            wtimage: 'chat-bubbles-with-ellipsis.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=d1f7804c-ac78-409d-8680-439f79704ed0',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=da698ce7-e71a-416f-9c0e-404cfa8c88dc'
        },
        {
            wtimage: 'comment.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomment.svg?alt=media&token=ee031555-eb5d-4537-9580-ef93d2aa4f11',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomment.svg?alt=media&token=6dd76c0d-a4f3-4d59-a740-7f9456cd35c1',
        }
    ]

}else{
    widgetAvatarImg1 = [
        {
            wtimage: 'avatar-1',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-1.png?alt=media&token=ad588667-1cdd-4e46-be71-5719a198707d',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-1.png?alt=media&token=ad588667-1cdd-4e46-be71-5719a198707d'
        },
        {
            wtimage: 'avatar-2',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-2.png?alt=media&token=809a9698-5201-44d0-ab36-d029a5682d49',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-2.png?alt=media&token=809a9698-5201-44d0-ab36-d029a5682d49'
        },
        {
            wtimage: 'avatar-3',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-3.png?alt=media&token=ec06f98d-df66-4ced-86fc-c523fd0dd4d5',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Favatar-3.png?alt=media&token=ec06f98d-df66-4ced-86fc-c523fd0dd4d5',
        }
    ]
    singleimage = {
        wtimage: 'comments.svg',
        fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
        blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
    }

    widgetImages2 =  [
        {
            wtimage: 'comments.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
        },
        {
            wtimage: 'chat.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat.svg?alt=media&token=0ad054ad-51c9-43d0-8123-d935bb258f99',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fchat.svg?alt=media&token=608f0af9-1858-4503-a8c0-e378cc441ee9'
        },
        {
            wtimage: 'chat-bubbles-with-ellipsis.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=d1f7804c-ac78-409d-8680-439f79704ed0',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=da698ce7-e71a-416f-9c0e-404cfa8c88dc'
        },
        {
            wtimage: 'comment.svg',
            fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomment.svg?alt=media&token=ee031555-eb5d-4537-9580-ef93d2aa4f11',
            blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fcomment.svg?alt=media&token=6dd76c0d-a4f3-4d59-a740-7f9456cd35c1',
        }
    ]
}


export const PROFILE_FOLDER = 'profiles';
export const PROFILE_FOLDER_AVATAR = 'avatar';

export const widgetImages =  widgetImages2
export const widgetImage =  singleimage
export const widgetAvatar =  widgetAvatarImg1