import React from 'react';
import qs from 'query-string';
import { MAIL_FUNCTION_URL, ACCOUNT_ID, ACCOUNT_BOT_ID } from '../../config/siteConfig';

class Paymentsuccess extends React.Component {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};

    constructor() {
        super();
        var agentData = JSON.parse(localStorage.getItem('agent'));
        this.state = {
            ownerIdentifire: agentData.ownerIdentifire
        };
    }

    componentDidMount = () => {
        const urlValues = qs.parse(window.location.search);
        let plan_type = urlValues.ptype ? urlValues.ptype : "";
        //let current_plan_id = urlValues.cpId ? urlValues.cpId : "";
        let isTrial="";      
        if(plan_type === "paid") {
            isTrial = "no";
        } else if(plan_type === "trial") {
            isTrial = "yes";
        }

        var user_email = "";
        if(this.agent.ownerEmail) {
            user_email = this.agent.ownerEmail;
        } else if(this.agent.notification_email && this.agent.notification_email.length > 0 && this.agent.notification_email[0]) {
            user_email = this.agent.notification_email[0];
        }

        try {
            var visitorId = "";
            var variableName = "ClIENTID_" + ACCOUNT_BOT_ID + "=";
            var documntCookie = unescape(document.cookie);
            var decodedCookie = decodeURIComponent(documntCookie);
            var ca = decodedCookie.split(';');

            for (var kp = 0; kp < ca.length; kp++) {
                var c = ca[kp];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(variableName) === 0) {
                    visitorId = c.substring(variableName.length, c.length);
                }
            }

            if (ACCOUNT_ID && visitorId) {
                let query = this.agent.name+ " has subscribed Premium plan for his Livechat app. Below are the subscription details- Company name: "+ this.agent.company_name +", Account id: "+this.agent.ownerId+", Subscription Plan: Premium";
                let clientName = encodeURIComponent(this.agent.name);
                let email = encodeURIComponent(user_email);
                let phone = "";
                let planName = encodeURIComponent("Premium");
                let appName = encodeURIComponent(this.agent.company_name);
                let appId = encodeURIComponent(this.agent.ownerId);
                let client_query = encodeURIComponent(query);
                var apiData = ["companyId=" + ACCOUNT_ID + "&visitorId=" + visitorId + "&name=" + clientName + "&email=" + email + "&phone=" + phone + "&client_query=" + client_query + "&planName=" + planName + "&appName=" + appName + "&appId=" + appId + "&isTrial=" + isTrial];
                fetch(MAIL_FUNCTION_URL + "/autoStartVisitorChat", {
                    method: "POST",
                    body: apiData,
                    headers: { "Content-Type": "application/x-www-form-urlencoded" }
                }).then((resp) => { });
            }
        } catch (err) { }
    }

    render() {
        return (<>
                <div class="payment main-center-para">
                    <div class="main-center-data-img">
                        <img src={require("../../assets/images/congrat-page.svg")} />
                    </div>
                    <div class="para-center-data">
                        <h3 className="text-black card-title mb-1 mt-4">Congratulations</h3>
                        <p className="text-black">You’ve successfully subscribed to Premium Plan of Appy Pie Livechat.</p>
                    </div>
                    <div class="main-Datacenter-btn mt-5">
                        <a className="btnBlueSettings hoverA" href={"/" + this.state.ownerIdentifire + "/subscription"} >Go to subscription section</a>
                    </div>
                </div>
            </>)
    }
}

export default Paymentsuccess;
