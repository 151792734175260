import React from 'react';
import { MAIL_FUNCTION_URL,DOMAIN_NAME,GATEWAY_URL,FUNCTION_API_KEY } from "../../config/siteConfig";
import qs from 'query-string';

class CbFBMessenger extends React.Component {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    constructor() {
        super();
        this.state = {
            loader : true,
            error:""
        }
    }

    componentDidMount = () => {
        let params = qs.parse(this.props.location.search);
        if (params.code) {
            let domain_name = DOMAIN_NAME;
            if(window.location.hostname === "localhost") {
                domain_name = "https://localhost:3000";
            }
            let cbUrl = encodeURIComponent(domain_name+"/cb-fb-messenger/pagesetup");
            let body22 = [`ownerId=${encodeURIComponent(this.agent.ownerId)}&code=${params.code}&cbUrl=${cbUrl}`];    
            //fetch(MAIL_FUNCTION_URL + "/facebook/verifyuser", {
            fetch( GATEWAY_URL + "/verifyuser", {
                method: "post",
                body: body22,
                headers: {
                    "X-API-Key" : FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if(response.status === "success") {
                    window.close();
                } else {
                    this.setState({ loader : false, error : "Something went wrong!"});
                }
            });
        } else {
            this.setState({ loader : false, error : params.error_message ? params.error_message : "Something went wrong!"});
        }
    }

    closeWindow = () => {
        window.close();
    }

    render() {
        return (<><div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>{this.state.error ? (<><div className="error">{this.state.error}</div><input type="button" onClick={this.closeWindow}>Close window</input></>) : (null)}
        </>)
    }
}

export default CbFBMessenger;