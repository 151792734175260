import React from 'react';
import { MAIL_FUNCTION_URL, ALGOLIA_APP_ID, ALGOLIA_KEY, GATEWAY_URL, FUNCTION_API_KEY } from '../../../config/siteConfig';
import * as algoliasearch from "algoliasearch";
import Pagination from "react-js-pagination";
import { checkPhoneCall } from '../../livechat/components/Twilio';
import { activityLog, checkWidgetStatus, elasticSearchBannedUsers, getESBannedUsers } from '../../../services/service';
import dummyData from '../../../localization/dummy-data.json';
// import db from '../../../config/firebase';
import LeftPanel from '../views/LeftPanel';

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PopUp from '../../../layout/components/popup';
import { getOwnerSnapShot } from '../../../services/payment';
import firebaseServices from '../../../firebase';
/* For Dropdown End */

class BannedUsers extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            usersList: [],
            limit: 100,
            currentPage: 1,
            searchBy: 0,
            textSearch: '',
            hideButton: true,
            boxText: '',
            recordFound: true,
            totalRecords: 0,
            totalPages: 0,
            loader: false,
            widgetStatus: false,
            showDummyData: false,
            payment_status: true
        }
    }

    async componentDidMount() {
        this.setState({
            loader: true
        });

        let widgetStatus = await checkWidgetStatus();
        this.setState({
            widgetStatus: widgetStatus
        })
        if (widgetStatus === true) {
            this.getUsersList();
        } else {
            this.getDummyData();
        }
        checkPhoneCall();
        if(this.agent.ownerId){
            getOwnerSnapShot(this.agent.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        activityLog("Opened banned users listing page");
    }

    checkWidgetStatus = () => {
        return new Promise((resolve, reject) => {
            if (this.agent.ownerId) {
                let widgetStatus = false;
                firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then((docs) => {
                    if (docs.exists) {
                        let _data = docs.data();
                        widgetStatus = _data.widget_status ? _data.widget_status : false;
                    }
                    resolve(widgetStatus);
                }).catch((err) => {
                    console.log('error at line 48 >>> ', err);
                    resolve(false);
                })
            } else {
                resolve(false);
            }
        })
    }

    ValidateIPaddress = (ipaddress) => {
        if (/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(ipaddress)) {
            return true;
        }
        return (false)
    }

    getDummyData = () => {
        let bannedUsersData = dummyData.bannedusers;
        let filteredData = [];
        let filteredData_1 = [];
        var searchWords = this.state.textSearch.trim();
        if (searchWords) {
            bannedUsersData.forEach((arr) => {
                if (arr.name.indexOf(searchWords) > -1 || arr.IPv4.indexOf(searchWords) > -1 || arr.note.indexOf(searchWords) > -1) {
                    filteredData.push(arr);
                }
            })
        } else {
            filteredData = bannedUsersData;
        }

        let searchBy = parseInt(this.state.searchBy);
        if (searchBy === 1) {
            filteredData.forEach((arr1) => {
                if (arr1.is_ip_block === true) {
                    filteredData_1.push(arr1);
                }
            })
        } else if (searchBy === 2) {
            filteredData.forEach((arr1) => {
                if (arr1.is_ip_block === false) {
                    filteredData_1.push(arr1);
                }
            })
        } else {
            filteredData_1 = filteredData;
        }

        this.setState({
            loader: false,
            usersList: filteredData_1,
            totalRecords: filteredData_1.length,
            showDummyData: true
        })
    }

    getUsersList = async () => {
        var searchWords = this.state.textSearch.trim();
        //const index = 'livechat_bannedusers_'+this.agent.ownerId;
        const records = await elasticSearchBannedUsers(this.agent.ownerId, this.state.currentPage, this.state.limit, searchWords, parseInt(this.state.searchBy));
        if (records.status === 200) {
            var totalRecords = records.data.totalRecords;
            var totalPages = Math.ceil(totalRecords / this.state.limit);
            var allUsers = records.data.data;
            this.setState({
                totalRecords: totalRecords,
                totalPages: totalPages,
                usersList: allUsers,
                loader: false
            })
        } else {
            this.setState({
                loader: false
            })
        }
    }

    getUsersList_OLD = () => {
        //let startAt = (this.state.currentPage-1)*this.state.limit;
        //console.log('startAt >> ' + startAt);
        var searchWords = this.state.textSearch.trim();
        var filterObj = {
            page: this.state.currentPage - 1,
            hitsPerPage: this.state.limit
        };
        var client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_KEY);
        var index = client.initIndex('LIVECHAT_BANNEDUSERS_' + this.agent.ownerId);
        var stsFilter = '';
        if (parseInt(this.state.searchBy) === 1) {
            stsFilter = "is_ip_block=" + 1
        } else if (parseInt(this.state.searchBy) === 2) {
            stsFilter = "is_ip_block=" + 0
        }

        // if(this.state.textSearch.trim()!=='') {
        //     let verifyIp = this.ValidateIPaddress(this.state.textSearch.trim());
        //     if(verifyIp) {
        //         stsFilter = "is_ip_block="+1
        //     }
        // }
        if (stsFilter !== "") {
            filterObj.filters = stsFilter;
        }
        try {
            index.search(searchWords, filterObj).then(data => {
                console.log(data);
                var totalRecords = data.nbHits;
                var totalPages = data.nbPages;
                var allUsers = [];
                data.hits.forEach(vData => {
                    allUsers.push(vData);
                });
                this.setState({
                    totalRecords: totalRecords,
                    totalPages: totalPages,
                    usersList: allUsers,
                    loader: false
                })
            }).catch(err => {
                this.setState({
                    loader: false
                })
            });
        } catch (err) {
            this.setState({
                loader: false
            })
        }

    }

    loadMore = () => {
        this.setState({ currentPage: this.state.currentPage + 1 }, () => {
            this.getUsersList();
        });
    }

    changehandle = (e) => {
        this.setState({
            boxText: e.target.value
        })
    }

    deleteBanUser = (docId, objectId) => {
        this.setState({
            loader: true
        })
        var body = [
            `ownerId=${this.agent.ownerId}&docId=${docId}&objectId=${objectId}`
        ];
        //fetch(MAIL_FUNCTION_URL+'/getbannedUsers/deleteUser', {
        fetch(GATEWAY_URL + '/deleteUser', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then(response => response.json())
            .then(response => {
                document.getElementById(docId).style.display = "none";
                var totalRecords = this.state.totalRecords - 1;
                if (parseInt(totalRecords) === 0) {
                    this.setState({
                        totalRecords: 0
                    })
                }
                this.setState({
                    loader: false,
                    totalRecords: totalRecords
                })
                activityLog("deleted ban user record");
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    changeSearch = (e) => {
        this.setState({
            textSearch: this.state.boxText,
            usersList: [],
            currentPage: 1,
            loader: true
        }, () => {
            if (this.state.showDummyData === false && this.state.widgetStatus === true) {
                this.getUsersList();
            } else {
                this.getDummyData();
            }
        })
    }

    changeSearchBy = (e) => {
        this.setState({
            searchBy: e.target.value,
            usersList: [],
            currentPage: 1,
            loader: true
        }, () => {
            if (this.state.showDummyData === false && this.state.widgetStatus === true) {
                this.getUsersList();
            } else {
                this.getDummyData();
            }
        })
    }

    handlePageChange = (pageno) => {
        this.setState({ currentPage: pageno }, () => {
            this.getUsersList();
        });
    }

    searchInput = (e) => {
        if (e.which === 13) {
            this.changeSearch();
        }
    }
    userFilterMobile = () => {
        var responsiveTbaleHeaderFilter = document.getElementById('resposnive-table-class');
        responsiveTbaleHeaderFilter.classList.toggle('responsiveTbaleHeaderFilter')
    }
    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    hideDummyData = () => {
        this.setState({
            showDummyData: false,
            loader: true
        });
        this.getUsersList();
    }

    encryptData = (data) => {
        let name = '';
        let narrayData = data.split(' ');
        if (narrayData.length === 1) {
            name = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
        } else if (narrayData.length === 3) {
            name = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[1].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[2].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
        } else {
            name = narrayData[0].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*') + " " + narrayData[1].replace(/./g, (c, i) => i == 0 ? c.toUpperCase() : '*');
        }
        return name;
    }

    render() {
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                    <h3 className="heding-text">Banned Users</h3>
                    <div className="mobile-menu ml-auto d-none" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                {this.state.payment_status ?   <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="main-heading main-heading-title py-5 mob-mt-4">
                            {/* <h3 className="heding-text">Banned Users</h3> */}
                            {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '0px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                        </div>
                        <div className="body-section bg-white px-5 py-5">
                            <div className="agent-section b" id="resposnive-table-class">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mobile-menu text-right mb-5">
                                            <button className="responsive-btn " onClick={this.userFilterMobile}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="20.899" viewBox="0 0 22.674 20.899">
                                                    <path id="Path_1489" data-name="Path 1489" d="M22.608,10.077a.687.687,0,0,0-.622-.394H.687a.687.687,0,0,0-.53,1.124l8.188,9.931V29.9a.687.687,0,0,0,.992.616l4.6-2.28a.687.687,0,0,0,.382-.615l.009-6.878,8.188-9.931A.687.687,0,0,0,22.608,10.077Zm-9.5,9.977a.687.687,0,0,0-.157.436l-.009,6.7-3.226,1.6v-8.3a.687.687,0,0,0-.157-.437l-7.418-9H20.529Z" transform="translate(0 -9.683)" fill="#A2ABD1" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="agent-table-header table-header row mb-4 user-table-main-header banned-fltr">

                                            <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                                <TextField className="form-control" id="outlined-basic" label="Search" variant="outlined" name="searchbox" value={this.state.searchbox} onChange={this.changehandle} onKeyPress={this.searchInput} />
                                                <div className="input-group-append">
                                                    <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit" onClick={this.changeSearch}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                            <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>

                                            {/* <div className="input-group pr-0 user-table-live">
                                                <input type="text" className="form-control form-control-lg" placeholder="Search" name="searchbox" value={this.state.searchbox} onChange={this.changehandle} onKeyPress={this.searchInput} aria-label="Search for messages or users..." />
                                                <div className="input-group-append">
                                                    <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit" onClick={this.changeSearch}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                            <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div> */}
                                            <div className="aside-agent k-flex align-items-center justify-content-end">
                                                <div className="aside-agent k-flex align-items-center">
                                                    <div className="material-select">
                                                        <FormControl variant="outlined" className="material-outlined w-150">
                                                            <InputLabel id="status-select-outlined-label">Search By</InputLabel>
                                                            <Select MenuProps={{ disableScrollLock: true}} labelId="status-select-outlined-label" id="status-select-outlined" name="status" value={this.state.searchBy?this.state.searchBy:"0"} onChange={this.changeSearchBy} label="Search By">
                                                                <MenuItem value="0">Both</MenuItem>
                                                                <MenuItem value="1">IP</MenuItem>
                                                                <MenuItem value="2">Visitor</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                    {/* <h5 className="heding-text text-truncate mr-4">Search By</h5>
                                                    <div className="wrapperInfo">
                                                        <select className="customArrow" name="status" value={this.state.searchBy} onChange={this.changeSearchBy}>
                                                            <option value="0">Both</option>
                                                            <option value="1">IP</option>
                                                            <option value="2">Visitor</option>
                                                        </select>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="agent-table">
                                            <table className="table table-borderless mb-0 liveCgatUserTable">
                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                    <tr>
                                                        <th>Name or IP</th>
                                                        <th>Reason</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="outline-1-black border-radius-table">
                                                    {this.state.usersList.map((v, i) => {
                                                        let ipName = v.is_ip_block ? v.IPv4 : v.name;
                                                        return <tr id={v.docId} key={i}>
                                                            <td>{ipName}</td>
                                                            <td>{v.note}</td>
                                                            <td><span style={{ cursor: 'pointer' }} onClick={() => { this.deleteBanUser(v.docId, v.clietnId) }}>Delete</span></td>
                                                        </tr>
                                                    })}
                                                    {this.state.totalRecords === 0 ? (<tr><td colSpan="3">No Record Found</td></tr>) : (null)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {this.state.totalPages > 1 ? (<div className="pagingnation">
                                        <div className="pagingnation-list">
                                            <Pagination
                                                hideDisabled={false}
                                                innerClass="nav"
                                                activeClass="selected"
                                                firstPageText='First'
                                                lastPageText='Last'
                                                disabledClass="disabled"
                                                activePage={this.state.currentPage}
                                                itemsCountPerPage={this.state.limit}
                                                totalItemsCount={this.state.totalRecords}
                                                pageRangeDisplayed={4}
                                                onChange={this.handlePageChange.bind(this)}
                                            />
                                        </div>
                                    </div>) : (null)}
                                </div>
                            </div>
                        </div>

                    </div>
                </div> : <PopUp />}
            </>
        )
    }
}

export default BannedUsers;