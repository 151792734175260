import React, { Component } from 'react';
// import firebase from "../../../../config/firebase";
import Notification from 'react-web-notification';
import ChatContext from '../../components/ChatContext';
import { INCOMING_AUDIO, POP_AUDIO, ICON_BELL,  NO_REPLY_EMAIL } from '../../../../config/siteConfig';
import { getTimeFromDate, validateEmail, getDateInUTC, nameIntials } from '../../components/Comman';
import { setCurrentChat, getCallStatus } from '../../components/Twilio';
import firebaseServices from '../../../../firebase';

//const dateFormat = "X";

class Chatnotification extends Component {

  static contextType = ChatContext;  // this.context will work when it defined as is

  notifSnapShot = null;

  constructor(props,context) {

    super(props);
    this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    const body = 'Hello' + new Date();
    const tag = Date.now();
    const icon = ICON_BELL;

    this.incomingSound = INCOMING_AUDIO;
    this.newMsgSound = POP_AUDIO;

    this.playSound = new Audio(this.incomingSound);
    this.playSoundMsg = new Audio(this.newMsgSound  );

    const options = {
      tag: tag,
      body: body,
      icon: icon,
      lang: 'en',
      dir: 'ltr',
      sound: this.incomingSound //'./sound.mp3'
    };

    this.state = {
      title: '',
      options: options,
      ignore: true,
      notificationArray: [],
      lastM: [],
      Noti_List: [],
      settings: {},
      notificationData : {},
      agentNotifications : {}
    };
  }

  /* Date functions Start */

  getMinutesBackDateInUTC = async (minute) => {
    let serverTimestamp = await getDateInUTC();
    var todayUTCDate = eval(serverTimestamp) - eval(minute * 60000);
    todayUTCDate = parseInt(todayUTCDate);
    return todayUTCDate;
  }

  /* Date functions End */
  componentWillMount = async () => {
    firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('settings').doc("NOTIFICATIONS_SOUND").get().then(async result => {
      if (result.exists) {
        var setting = result.data();
        /* Agent Notification Start */
        let myNotification = {
            myIncDesktopNot: (this.agent.incoming_desktop_notification !== "undefined" && typeof this.agent.incoming_desktop_notification !== "undefined") ? this.agent.incoming_desktop_notification : true,
            myIncPlaySound: (this.agent.incoming_play_sound !== "undefined" && typeof this.agent.incoming_play_sound !== "undefined") ? this.agent.incoming_play_sound : true,
            myMsgDesktopNot: (this.agent.new_message_desktop_notification !== "undefined" && typeof this.agent.new_message_desktop_notification !== "undefined") ? this.agent.new_message_desktop_notification : true,
            myMsgPlaySound: (this.agent.new_message_play_sound !== "undefined" && typeof this.agent.new_message_play_sound !== "undefined") ? this.agent.new_message_play_sound : true
        };
        /* Agent Notification End   */
        this.setState({ settings: setting, ignore: !setting.incoming_desktop_notification, agentNotifications : myNotification });
        const currentDate_server = await this.getMinutesBackDateInUTC(0.5);
        this.notifSnapShot = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('Notifications').where('addedon', '>', currentDate_server).orderBy('addedon', 'desc').limit(30).onSnapshot((querySnap) => {
          //const notificationArray = this.state.notificationArray;
          querySnap.docChanges().forEach((change) => {

            if (change.type === "added") {
              var {
                addedon,
                msg,
                agents,
                chatId,
                msg_type,
                client_email,
                client_id,
                client_name,
                incoming,
                agent_end,
              } = change.doc.data();
              if(!agents){
                return
              }
              console.log("agents", agents)
              /* Check team start */
              const vistSnap = change.doc.data();
              if (incoming === 1 && this.agent.departments.length > 0 && vistSnap.hasOwnProperty('team_id') && vistSnap.team_id && this.agent.departments.indexOf(vistSnap.team_id) === -1) {
                return false;
              }
              /* Check team end */

              var lastNotificationData = localStorage.getItem("lastNotification") ? JSON.parse(localStorage.getItem("lastNotification")): {};
              if(Object.keys(lastNotificationData).length > 0) {
                if(lastNotificationData.notiFId === change.doc.id || addedon <= parseInt(lastNotificationData.addedon)) {
                  return false;
                }
              }

              client_id = chatId; // it will be removed when Previous chat enabled/implemented. now chatId and clientId are same.
              if (client_name === null || typeof client_name === undefined || client_name === undefined) {
                client_name = 'CLIENT';
              }
              var title = '';
              var body = msg;
              msg_type = parseInt(msg_type);
              if (msg_type === 2) {
                body = "Image";
              } 
              if (msg_type === 3) {
                body = "Attachment";
              }
              if (msg_type === 4) {
                body = "Video";
              }

              var tag = chatId + "-" + body + '-' + change.doc.id;
              var client_short = nameIntials(client_name);
              var client_short_name = client_name;
              
              if (client_name.length > 20) {
                client_short_name = client_name.substring(0, 20) + '...';
              }
              var dataObj = { addedon:addedon, notiFId : change.doc.id };
              if (incoming === 1) {
                title = 'New Incoming Chat';
                this.sendNotification(tag, title, body, chatId, client_email, client_id, client_name, client_short, client_short_name, incoming, agent_end, msg_type, dataObj);
                this.deleteNotification(change.doc.id);
              } else {
                title = 'Message from Ongoing Chat';
                if (agents.includes(this.agent.agentId)) {
                  this.sendNotification(tag, title, body, chatId, client_email, client_id, client_name, client_short, client_short_name, incoming, agent_end, msg_type, dataObj);
                  this.deleteNotification(change.doc.id);
                }
              }
            }
          });
        });
      }
    });
  }

  sendNotification = (tag, title, body, chatId, client_email, client_id, client_name, client_short, client_short_name, incoming1, agent_end1, msg_type, notifData) => {
    let isCallStarted = localStorage.getItem('isCallStarted') ? (localStorage.getItem('isCallStarted').toLowerCase() === 'true') : false;
    if(incoming1 === 1 && msg_type === 6 && agent_end1 !== "VISITOR_END_CHAT") {
      if (this.state.settings.incoming_desktop_notification && this.state.agentNotifications.myIncDesktopNot) {

        var data = {
          client_email: client_email,
          client_id: client_id,
          client_name: client_name,
          chat_id: chatId,
          client_short: client_short,
          client_short_name: client_short_name,
          msg: body,
          incoming: incoming1,
          agent_end: agent_end1,
        };

        const options = {
          tag: tag,
          body: body,
          icon: ICON_BELL,
          lang: 'en',
          dir: 'ltr',
          data: data,
          sound: this.incomingSound //'./sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
        };

        this.setState({
          notificationData :notifData,
          title: title,
          options: options
        });

        if (this.state.settings.incoming_play_sound && this.state.agentNotifications.myIncPlaySound && !isCallStarted) {
          if (window.chrome) {
            this.playSound.play();
          } else {
            setTimeout(() => {
              try {
                document.getElementById('sound').play();
              } catch (err) { }
            }, 100);
          }
        }

      } else if(this.state.settings.incoming_play_sound && this.state.agentNotifications.myIncPlaySound && !isCallStarted) {
        if (window.chrome) {
            this.playSound.play();
        } else {
          try {
            document.getElementById('sound').play();
            return;
          } catch (err) {}
        }
      }
    } else {
        if (this.state.settings.new_message_desktop_notification && this.state.agentNotifications.myMsgDesktopNot) {
            var data2 = {
              client_email: client_email,
              client_id: client_id,
              client_name: client_name,
              chat_id: chatId,
              client_short: client_short,
              client_short_name: client_short_name,
              msg: body,
              incoming: incoming1,
              agent_end: agent_end1,
            };

            const options = {
              tag: tag,
              body: body,
              icon: ICON_BELL,
              lang: 'en',
              dir: 'ltr',
              data: data2,
              sound: this.newMsgSound //'./sound.mp3'  // no browsers supported https://developer.mozilla.org/en/docs/Web/API/notification/sound#Browser_compatibility
            };

            this.setState({
              notificationData :notifData,
              title: title,
              options: options
            });
            
          if (this.state.settings.new_message_play_sound && this.state.agentNotifications.myMsgPlaySound && !isCallStarted) {
            if (window.chrome) {
              this.playSoundMsg.play();
            } else {
              setTimeout(() => {
                try {
                  document.getElementById('message_sound').play();
                  return;
                } catch (err) {
                }
              }, 100);
            }
          }
        } else if(this.state.settings.new_message_play_sound && this.state.agentNotifications.myMsgPlaySound && !isCallStarted) {
          if (window.chrome) {
              this.playSoundMsg.play();
          } else {
            try {
              document.getElementById('message_sound').play();
              return;
            } catch (err) {}
          } 
      }
    } 
  }

  handlePermissionGranted = (e) => {
    console.log("-------------handlePermissionGranted----------------");
    this.setState({
      ignore: false
    });
  }

  handlePermissionDenied = (e) => {
    console.log("-------------handlePermissionDenied----------------");
    this.setState({
      ignore: true
    });
  }

  handleNotSupported = (e) => {
    console.log("-------------handleNotSupported----------------");
    this.setState({
      ignore: true
    });
  }

  handleNotificationOnClick = (e) => {

    var tmpData = e.target.data ? e.target.data : false;
    let client_id;
    let tmpMsg;

    if(tmpData) {
      client_id = tmpData.client_id;
      tmpMsg = tmpData.msg;
    } else {
      var tmptag = e.target.tag?e.target.tag:false;
      var tmpTagMsg = e.target.body?e.target.body:"";
      if(tmptag) {
          var res = tmptag.split("-");
          var tmpclientId = res[0] ? res[0] : false;
          if(tmpclientId) {
            client_id = tmpclientId;
            tmpMsg = tmpTagMsg;
          }
      }
    }

    var organization_alias = this.agent.ownerIdentifire?this.agent.ownerIdentifire:this.agent.organization_alias;
    organization_alias = organization_alias?organization_alias.trim():"";
    if(window.location.pathname !== '/'+organization_alias) {
        window.focus();
        window.location.href = '/'+organization_alias;
        return false;
    }

    if (tmpMsg === 'Chat Ended from client') {
      return;
    }

    try {
      if (this.context.liveChatDocId === client_id) {
        window.focus();
        return;
      } else {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("visitors").doc(client_id).get().then((doc) => {
          if (doc.exists) {
            var _doc = doc.data();
            _doc.id = doc.id;

            _doc.client_short = nameIntials(_doc.client_name);
            var client_short_name = _doc.client_name;
            if (client_short_name.length > 20) {
              client_short_name = client_short_name.substring(0, 20) + '...';
            }
            _doc.client_short_name = client_short_name;
            _doc.timevalue = getTimeFromDate(_doc.updatedon);

            if (!validateEmail(_doc.client_email)) {
              _doc.client_email = NO_REPLY_EMAIL;
            }

            if (!validateEmail(_doc.visitor_email)) {
              _doc.visitor_email = NO_REPLY_EMAIL;
            }

            /* for count badges */
            if (_doc.agent_ids.includes(this.agent.agentId) && (_doc.incoming === 2 || _doc.visitor_status === "ongoing")) {
              const agent_read_count = _doc[this.agent.agentId] ? _doc[this.agent.agentId] : "";
              _doc.agent_read_count = (agent_read_count > 0) ? agent_read_count : "";
            }
            /* for count badges */

            this.centerClientLoading(_doc);
            /* Go to desired windo by clicking on notification */
            window.focus();
          }
        });
      }
    } catch (err) {}
  }

  centerClientLoading = (visitor) => {
    
    let key = visitor.id;
    let country = visitor.country;
    let browser = visitor.browser;
    let operating = visitor.operating;

    if (this.context.liveChatDocId === key) {
      return;
    }

    try {
      setCurrentChat(visitor);
    } catch (errrr) { }

    var arrayToSet = [];

    var Obj1 = {
      key: "currentVisitor",
      value: visitor
    }

    var Obj2 = {
      key: "midHeadercountry",
      value: country
    }

    var Obj3 = {
      key: "midHeaderbrowser",
      value: browser
    }

    var Obj4 = {
      key: "midHeaderoperating",
      value: operating
    }

    var Obj5 = null;
    if (visitor.agent_end === "VISITOR_END_CHAT") {
      Obj5 = {
        key: "chatPickStatusTransaction",
        value: { key: key, status: "close", Text: "Close window", msgToShow: visitor.message_to_show }
      }
    } else {
      if(visitor.visitor_status === "ongoing") {
        if (visitor.agent_ids.includes(this.agent.agentId)) {
          Obj5 = {
            key: "chatPickStatusTransaction",
            value: { key: key, status: "chatting", Text: "Continue", msgToShow: null }
          }
        } else {
          Obj5 = {
            key: "chatPickStatusTransaction",
            value: { key: key, status: "close", Text: "Close window", msgToShow: "Chat has been picked by another agent" }
          }
        } 
      } else {
        Obj5 = {
          key: "chatPickStatusTransaction",
          value: { key: key, status: "start", Text: "Start Chat", msgToShow: null }
        }
      }
    }

    var Obj6 = {
      key: "showMidHeader",
      value: true
    }

    var Obj7 = {
      key: "liveChatDocId",
      value: key
    }
    var Obj8 = {
      key: "showVisitorPaths",
      value: false
    }

    var Obj9 = {
      key: "rightSectionItemToShow",
      value: "visitorslist"
    }

    var Obj11 = {
      key: "isCloseBtnClicked",
      value: ""
    }
    var Obj12 = {
      key: "showPastChat",
      value: false
    }

    arrayToSet.push(Obj12);
    arrayToSet.push(Obj11);
    arrayToSet.push(Obj1);
    arrayToSet.push(Obj2);
    arrayToSet.push(Obj3);
    arrayToSet.push(Obj4);
    arrayToSet.push(Obj5);
    arrayToSet.push(Obj6);
    arrayToSet.push(Obj7);
    arrayToSet.push(Obj8);
    arrayToSet.push(Obj9);

    try {
      this.context.updateGlobalData("array", arrayToSet);
      if(document.getElementById("shadowMasking").style.display !== "none") {
          document.getElementById("shadowMasking").style.display = "none";
      }
    } catch(err) {}

    //// Get Chat Data and add it in chatdetail array ///////	
  }

  handleNotificationOnError = (e) => {
    console.log("handleNotificationOnError===============");
  }

  handleNotificationOnClose = (e) => {
    console.log("handleNotificationOnClose===============");
  }

  handleNotificationOnShow = (e) =>  {
    try {
      var lastNotificationData = localStorage.getItem("lastNotification") ? JSON.parse(localStorage.getItem("lastNotification")): {};
      var lastNotiFId= this.state.notificationData ? this.state.notificationData.notiFId : "";
      if(lastNotificationData.notiFId !== lastNotiFId) {
          localStorage.setItem("lastNotification", JSON.stringify(this.state.notificationData));
      }
    } catch (err) {}
  }

  /* Unmount all mounted data start */

  componentWillUnmount = () => {
    try {
      this.notifSnapShot();
    } catch (errr) {}
  }

  /* Unmount all mounted data end */

  /* 
  Start: Delete chat notification 
  This will delete chat notification, once notificatyion will deliver to agaent.
  Time durationg setted 30 second.
  */

  deleteNotification = (notId) => {
    try {
      if(notId) {
        setTimeout(() => {
          firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('Notifications').doc(notId).delete().then(res => {}).catch(errrr => {
            console.log("------------ deleteNotification collection  errrr   ---------------");
            console.log(errrr);
          });
        }, 30000);
      }
    } catch(errr) {
      console.log("------------ deleteNotification  errr   ---------------");
      console.log(errr);
    }
  }
  
  /* END: Delete chat notification */

  render() {
    return (
      <div>
         <audio id='sound' preload='auto'>
          <source src={this.incomingSound} type='audio/mpeg' />
          <embed hidden={false} autostart='false' loop={false} src={this.incomingSound} />
        </audio>

        <audio id='message_sound' preload='auto'>
          <source src={this.newMsgSound} type='audio/mpeg' />
          <embed hidden={false} autostart='false' loop={false} src={this.newMsgSound} />
        </audio>

        <Notification
          ignore={false}
          disableActiveWindow={false}
          notSupported={this.handleNotSupported.bind(this)}
          onPermissionGranted={this.handlePermissionGranted.bind(this)}
          onPermissionDenied={this.handlePermissionDenied.bind(this)}
          onShow={this.handleNotificationOnShow.bind(this)}
          onClick={this.handleNotificationOnClick.bind(this)}
          onClose={this.handleNotificationOnClose.bind(this)}
          onError={this.handleNotificationOnError.bind(this)}
          timeout={10000}
          askAgain={false}
          title={this.state.title}
          options={this.state.options}
        />
      </div>
    );
  }
}

export default Chatnotification;
