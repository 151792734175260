export const VISITOR_MESSAGE_TEMPLATE = '<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="message-content-new bg-white box-shadow-chat"></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>';

export const AGENT_MESSAGE_TEMPLATE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="bg-primary text-white message-content-new"></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>';

export const AGENT_ATTACHMENT_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="" /></a></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const VISITOR_ATTACHMENT_MESSAGE = '<div class="message" id="LIVE1596461701890547077"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>';

export const FORM_DATA_TEMPLATE = '<div class="message"><div class="message-body message-message-first"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content message-message-first2"><div class="message-content-new bg-white box-shadow-chat question-sections-first"><div class="liveChat-Message"></div></div></div></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>';

export const THIRD_MESSAGE_TEMPLATE = '<div class="message-divider my-5 mx-lg-4"><div class="row align-items-center"><div class="center-width"><small class="text-muted"></small><p class="date-agent"></p></div></div></div>';

export const AGENT_DOCS_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white"><div class="media"><div class="msgfilesNew"><a class="docattach" href="" src="" download="" target="_blank"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName"></span></span></a></div></div></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" src=""></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const VISITOR_DOCS_MESSAGE = '<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a class="docattach" href="" src="" download="" target="_blank"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName"></span></span></a></div></div></div></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>';

export const AGENT_VIDEO_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" src=""></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const VISITOR_VIDEO_MESSAGE = '<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>';

export const VISITOR_PLAY_VIDEO_MESSAGE = '<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="msgvideo"><div class="videoFrame msg-img-hover position-relative" style="width: 100%; height: 100%;"><div class="hoverDoenalod"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="whichFile"><a target="_blank" class="doenlaod-img-icon downloadattach"><svg id="Group_2848" data-name="Group 2848" xmlns="http://www.w3.org/2000/svg" width="10.049" height="11.5" viewBox="0 0 10.049 11.5"><path id="Path_2319" data-name="Path 2319" d="M42.335,6.375a.24.24,0,0,0-.221-.148H39.489V.24A.24.24,0,0,0,39.25,0H35.418a.24.24,0,0,0-.24.24V6.227H32.544a.239.239,0,0,0-.17.409l4.778,4.794a.24.24,0,0,0,.339,0l4.792-4.794A.239.239,0,0,0,42.335,6.375Z" transform="translate(-32.304)"/></svg></a></span></div><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>';

export const AGENT_SMS_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><img alt="Sent via sms" width="32px" src=${require("../../../../assets/images/livechat/sent_via_sms.svg")} /></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const VISITOR_SMS_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via sms" width="32px" src=${require("../../../../assets/images/livechat/sent_via_sms.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const AGENT_CALL_TEMPLATE = `<div class="message-divider my-5 mx-lg-4"><div class="row align-items-center"><div class="col"><hr></div><div class="col-auto"><img alt="" width="13px" src=${require("../../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted"></small><p class="date-agent"></p></div><div class="col"><hr></div></div></div>`;

export const VISITOR_CALL_TEMPLATE = `<div class="message-divider my-5 mx-lg-4"><div class="row align-items-center"><div class="col"><hr></div><div class="col-auto"><img alt="" width="13px" src=${require("../../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted"></small><p class="date-agent"></p></div><div class="col"><hr></div></div></div>`;

export const AGENT_CALL_RECORDING_TEMPLATE = `<div class="message-divider my-5 mx-lg-4"><div class="row align-items-center"><div class="col"><hr></div><div class="col-auto"><img alt="" width="13px" src=${require("../../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted"></small><p class="date-agent"></p></div><div class="col"><hr></div></div><div class="row align-items-center justify-content-center mt-4"><div class="call-record-audio"></div></div></div>`;

export const VISITOR_CALL_RECORDING_TEMPLATE = `<div class="message-divider my-5 mx-lg-4"><div class="row align-items-center"><div class="col"><hr></div><div class="col-auto"><img alt="" width="13px" src=${require("../../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted"></small><p class="date-agent"></p></div><div class="col"><hr></div></div><div class="row align-items-center justify-content-center mt-4"><div class="call-record-audio"></div></div></div>`;

export const AGENT_VOIP_MESSAGE_TEMPLATE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="bg-primary text-white message-content-new"></div><div class="mt-0"><small class="opacity-65"></small></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>';

export const AGENT_VOIP_ATTACHMENT_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="" /></a></div></div></div><div class="mt-0"><small class="opacity-65"></small></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const AGENT_WHATSAPP_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><img alt="Sent via whatsapp" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const VISITOR_WHATSAPP_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via whatsapp" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const AGENT_VOIP_VIDEO_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div><div class="mt-0"><small class="opacity-65"></small></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const AGENT_VOIP_DOCS_MESSAGE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white"><div class="media"><div class="msgfilesNew"><a class="docattach" href="" src="" download="" target="_blank"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName"></span></span></a></div></div></div></div></div><div class="mt-0"><small class="opacity-65"></small></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>';

export const AGENT_FB_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><img alt="Sent via facebook messanger" width="32px" src=${require("../../../../assets/images/livechat/fb2.svg")} /></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const AGENT_INSTA_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const VISITOR_FB_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via facebook messanger" width="32px" src=${require("../../../../assets/images/livechat/fb2.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_INSTA_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_FB_ATTACHMENT_MESSAGE = `<div class="message" id="LIVE1596461701890547077"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div><span class="sendbysms"><img alt="Sent via facebook messanger" width="32px" src=${require("../../../../assets/images/livechat/fb2.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_FB_VIDEO_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video><span class="sendbysms"><img alt="Sent via facebook messanger" width="32px" src=${require("../../../../assets/images/livechat/fb2.svg")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const AGENT_TWITTER_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const VISITOR_TWITTER_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_TWITTER_ATTACHMENT_MESSAGE = `<div class="message" id="LIVE1596461701890547077"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div><span class="sendbysms"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_TWITTER_VIDEO_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="msgvideo"><div class="videoFrame twitter-frame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video><span class="sendbysms"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const AGENT_ZOOM_TEMPLATE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-flexend"><div class="message-content joinZoom"><h6>Zoom Meeting</h6><div class="message-content-zoom"></div><div class="k-flex align-items-center"><div class="bg-primary text-white message-content-new"><a class="join-meeting-link" href="" download="" target="_blank"><img alt="" src=${require("../../../../assets/images/zoom-icon.svg")} /> JOIN MEETING</a></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const AGENT_INSTA_DELETED_TEMPLATE = `<div class="message message-right" style="display:none;"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span><div class="bg-primary text-white message-content-new"></div></div><div class="mt-0"><small class="opacity-65"></small><img class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block"><span class="avshort"></span></div></div>`;

export const VISITOR_INSTA_ATTACHMENT_MESSAGE = `<div class="message" id="LIVE1596461701890547077"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_INSTA_VIDEO_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div style="display: flex;
align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const VISITOR_INSTA_STORY_REPLY = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;"></small></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
// `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span><div class="mt-0"><small class="story_reply"></small></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const VISITOR_INSTA_MESSAGE_REPLY_TEMPLATE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;"></small></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat"></div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const TRIGGER_MESSAGE_TEMPLATE = '<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center  justify-content-flexend text-right"><div class="message-content"><div class="bg-triggers text-white message-content-new"></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></span></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-lg-block bg-triggers"><span class="avshort-triggers">#T</span><span class="avshort" style="display:none;"></span></div></div>';

export const VISITOR_INSTA_DOCS_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><audio controls><source class="instaaudio" src='' type='audio/ogg'><source class="instaaudio" src='' type='audio/mpeg'>Your browser does not support the audio element.</audio></div></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const VISITOR_WHATSAPP_ATTACHMENT_MESSAGE = `<div class="message" style="display:none;"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;

export const VISITOR_WHATSAPP_DOCS_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><audio controls><source class="instaaudio" src='' type='audio/ogg'><source class="instaaudio" src='' type='audio/mpeg'>Your browser does not support the audio element.</audio></div></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const VISITOR_WHATSAPP_VIDEO_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div style="display: flex;
align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const VISITOR_WHATSAPP_MESSAGE = `<div class="message"><div class="avatar avatar-sm mr-4 mr-lg-5" data-chat-sidebar-toggle="#chat-1-user-profile"><span class="avshort"></span></div><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a class="docattach" href="" src="" download="" target="_blank"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName"></span></span></a></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span></div></div></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;

export const AGENT_WHATSAPP_ATTACHMENT_MESSAGE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-flexend text-right"><div class="message-content"><div class="form-row py-3"><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>`;

export const AGENT_WHATSAPP_VIDEO_MESSAGE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-flexend text-right"><div class="message-content"><div class="form-row py-3"><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span><div class="col"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>`;

export const AGENT_WHATSAPP_MESSAGE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-flexend text-right"><div class="message-content text-white"><div class="form-row py-3"><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/whatsapp.svg")} /></span><div class="col"><div class="media"><div class="msgfilesNew"><a class="docattach" href="" src="" download="" target="_blank"><span class="filesNameView"></span><span class="fileSizeView"></span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName"></span></span></a></div></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>`;

export const AGENT_INSTA_ATTACHMENT_MESSAGE = `<div class="message message-right"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center justify-content-flexend text-right"><div class="message-content"><div class="form-row py-3"><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../assets/images/livechat/56px-Instagram_icon.png")} /></span><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src=""></a></div></div></div><div class="mt-0"><small class="opacity-65"></small><img alt="" class="elshow checkedMsgIcon tickimg" width="18" height="18" style="margin-left: 5px, width: 14px" src="" /></div></div></div></div></div><div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span class="avshort"></span></div></div>`;