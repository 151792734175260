import React from 'react';
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import {mailUrl} from "../../config/mailFunctions.js";
import { activityLog, getAllAwsAgents, getOwnerDetails } from "../../services/service";
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from "../../config/siteConfig.js";
import firebaseServices from '../../firebase';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class EditDepartment extends React.Component {
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            showEditDepartment:'',
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            old_name:'',
            old_description:'',
            agentList:[],
            agents:[],
            ownerId:'',
            loader:false,
            selectAgents:[],
            ownerEmail:'',
            company_name:'',
            agentChange:false,
            otherDepartments:[],
            oldAgentsName:[],
            newAgentsName:[],
            agentsWithoutOwner:[],
            disableUpdateBtn: false
        }
    }

    async componentDidMount() {
        console.log(44);
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showEditDepartment:this.props.sendDepartmentStatus,
            ownerId:userDetails.ownerId,
            loader: true
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then((owners) => {
            if(owners.exists) {
                this.setState({
                    ownerEmail:owners.data().email,
                    company_name:owners.data().company_name
                })
            }
        });

        let allAgentss = await getAllAwsAgents();
        let ownerDetails = await getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
        let ownerDetailsData = {}
        if (ownerDetails.status === 200) {
            ownerDetailsData = ownerDetails.data;
        }
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').where('deletetime','==',0).get().then((docs) => {
            var agents = [];
            var owner = [];
            var i = 0;
            let currenTime = new Date().getTime();
            docs.forEach((doc) => {
                if (doc.data().roleType === 'Owner') {
                    let ownerData = doc.data();
                    if (ownerDetails.status === 200) {
                        ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                        ownerData.last_name = ownerDetailsData.last_name ? ownerDetailsData.last_name : '';
                        ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                        ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                        ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                        ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                        ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                        ownerData.alias = ownerDetailsData.alias ? ownerDetailsData.alias : '';
                        ownerData.profile_pic = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : ''
                    }
                    owner[0] = ownerData;
                    owner[0]['id'] = doc.id;
                } else {
                    let agentData = doc.data();
                    delete agentData.alias;
                    agents[i] = agentData;
                    agents[i]['id'] = doc.id;
                    i++;
                }
            })
            let merged = [];
            for (let i = 0; i < allAgentss.length; i++) {
                merged.push({
                    ...allAgentss[i],
                    ...(agents.find((itmInner) => itmInner.id == allAgentss[i].subid))
                }
                );
            }
            merged.push(owner[0]);
            // docs.forEach((doc)=>{
            //     agents[i] = doc.data();
            //     agents[i]['id']=doc.id;
            //     /*const roleType = doc.data().roleType ? doc.data().roleType : "agent";
            //     if(roleType !== "Owner") {
            //         agentsWithoutOwner.push({ name : doc.data().name, id : doc.id });
            //     } */
            //     if(doc.data().status === 1) {
            //         agentsWithoutOwner.push({ name : doc.data().name, id : doc.id });
            //     }
            //     i++;
            // });
            let agentsWithoutOwner = [];
            merged.forEach((mAgent) => {
                console.log('email_verified', mAgent.email_verified);
                if(mAgent.status === 1 && mAgent.email_verified) {
                    let agentName = mAgent.last_name && mAgent.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? mAgent.first_name + ' ' + mAgent.last_name : mAgent.first_name;
                    agentsWithoutOwner.push({ name : agentName, id : mAgent.id });
                }
            })
            this.setState({
                agentsWithoutOwner : agentsWithoutOwner,
                agents:merged,
                loader: false
            })
        });

        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('departments').orderBy('addedon').get().then((docs) => {
            let data=[];
            let i=0;
            docs.forEach((doc) => {
                if(doc.id !== this.props.departmentId) {
                    data[i]=doc.data();
                    data[i]['id']=doc.id;
                    i++;   
                } else {
                    this.setState({
                        depName:doc.data().name
                    })
                }
            })
            this.setState({
                otherDepartments:data
            })
        })
    }

    handleChange = (e) => {
        let target = e.target;
        if(target.name === "name") {
            if((target.value).trim() !== "") {
                this.setState({
                    disableUpdateBtn : false
                });
            } else {
                this.setState({
                    disableUpdateBtn : true
                });
            }
        }
        let values = target.value;
        this.setState({
            [target.name]:values
        });
    }

    /* Added By AG Start */

    handleAgentChange = (e, agents) => {
        let agentIdes = [];
        let agentNames = [];
        agents.forEach(agent => {
            agentIdes.push(agent.id);
            agentNames.push(agent.name);
        });
        this.setState({
            agentChange : true,
            newAgentsName: agentNames,
            agentList : agentIdes
        });
    }

    /* Added By AG End */

    hideEditDepartment = (e) => {
        this.props.getEditDepartmentStatus(false);
        this.setState({
            departmentId:'',
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[],
            agentChange:false
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validation();
        if(validate===true){
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').where('name','==',this.state.name).get().then(async (data) => {
                let OldData=[];
                data.forEach((doc) => {
                    OldData.push(doc.id);
                })
                if(data.empty===true || OldData.indexOf(this.state.departmentId) === 0) {
                    this.setState({
                        loader:true
                    });
                    if(this.state.agentChange) {
                        await this.updateDepartments();
                    }
                    let updatedon = Date.now();
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').doc(this.state.departmentId).update({updatedon:updatedon,name:this.state.name,description:this.state.description}).then((ref)=> {
                        this.sendEmail();
                        /* if(this.state.agentChange) {
                            this.updateDepartments();
                        } */
                        if(!this.state.agentChange) {
                            //this.hideEditDepartment();
                            this.setState({
                                loader:false
                            })
                            toast.success("Team has been updated successfully.");
                        }
                        this.setState({
                            name:'',
                            name_error:'',
                            description:'',
                            description_error:'',
                            agentList:[]
                        });
                        /* Added By AG Start */
                        this.hideEditDepartment();
                        /* Added By AG End */
                    }).catch((err) => {
                        console.log(err);
                    })
                } else {
                    this.setState({
                        name_error:'This Team has been already added'
                    })
                }
            })
            return false;
            
        }
    }

    updateDepartments = () => {
        return new Promise( resolve => {
            let difference = this.state.selectAgents.filter(x => !this.state.agentList.includes(x));
            if(difference.length > 0) {
                this.deleteDepartment(difference);
            }
            if(this.state.agentList.length > 0) {
                this.state.agentList.forEach((agentId) => {
                    let oldDepartment = this.getDepartment(agentId);
                    if(oldDepartment.indexOf(this.state.departmentId) === -1) {
                        oldDepartment.push(this.state.departmentId);
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({departments:oldDepartment}).then((ref) => {
                            if(difference.length===0) {
                                //this.hideEditDepartment();
                                this.setState({
                                    loader:false
                                })
                                toast.success("Team has been updated successfully.");
                                activityLog("Updated team");
                            }
                            resolve(true);
                        }).catch((err) => {
                            console.log(err);
                            resolve(false);
                        });
                    } else {
                        if(difference.length===0) {
                            //this.hideEditDepartment();
                            this.setState({
                                loader:false
                            });
                            activityLog("Updated team");
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }
                });
            } else {
                resolve(false);
            }
        });
    }

    deleteDepartment = (agents) => {
        agents.forEach(agentId => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").doc(agentId).get().then((doc) => {
                if(doc.exists) {
                    let data = doc.data();
                    let index = data.departments.indexOf(this.state.departmentId);
                    data.departments.splice(index,1);
                    let newarray = [...new Set([...data.departments])]; 
                    if(newarray.length===0){
                        newarray.push(this.state.otherDepartments[0].id);
                    }
                    firebaseServices.db.collection("users").doc(this.state.ownerId).collection("agents").doc(agentId).update({departments:newarray}).then((ref) => {
                        //this.hideEditDepartment();
                        this.setState({
                            loader:false
                        })
                        toast.success("Team has been updated successfully.");
                    }).catch((error) => {
                        console.log(error);
                    })
                }
            })
        });
    }

    getDepartment = (agentId) => {
        let agentValue;
        this.state.agents.forEach((value,index) => {
            if(value.id===agentId) {
                agentValue = value.departments;
            }
        })
        return agentValue;
    }

    validation = () => {
        let returnValue=true;
        if(this.state.name===''){
            returnValue = false;
            this.setState({
                name_error:'Team Name should not be empty'
            })
        } else {
            this.setState({
                name_error:''
            })
        }
        return returnValue;

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showEditDepartment !== props.sendDepartmentStatus) {
          return {
            showEditDepartment: props.sendDepartmentStatus
          }
        }
        return null
    }

    getDepartmentData = () => {
        let promise1 = new Promise((res,rej) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("departments","array-contains",this.props.departmentId).get().then((agDocs) => {
                if(!agDocs.empty) {
                    let selectedAgentsId=[];
                    let selectedAgentsName=[]
                    agDocs.forEach(agents => {
                        selectedAgentsId.push(agents.id);
                        selectedAgentsName.push(agents.data().name);
                    })
                    res({'id':selectedAgentsId,'name':selectedAgentsName});
                } else {
                    res({'id':[],'name':[]});
                }
            })
        })
        let promise2 = new Promise((Resolve,Reject) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').doc(this.props.departmentId).get().then((docs) => {
                if(docs.exists) {
                    let depData=docs.data();
                    Resolve(depData);
                } else {
                    Reject();
                }
            })
        })

        Promise.all([promise1,promise2]).then(results => {
            this.setState({
                name:results[1].name,
                description:results[1].description,
                old_name:results[1].name,
                old_description:results[1].description,
                selectAgents:results[0].id,
                oldAgentsName:results[0].name,
                agentList:results[0].id,
                newAgentsName:results[0].name
            })
        }).catch(err => {
            this.setState({
                loader:false
            })
        })
    }

    componentDidUpdate(prev_props){
        if(this.props.departmentId!=='' && this.props.departmentId !== this.state.departmentId) {
            this.setState({
                departmentId:this.props.departmentId,
                loader:true
            }, () => {
                this.getDepartmentData();
                activityLog("Opened edit department page");
            })
        }
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideEditDepartment();
    }

    sendEmail = () => {
        let ownerEmail = this.agent.ownerEmail ?  this.agent.ownerEmail : this.state.ownerEmail;
        var body = [
                `email=${ownerEmail.toLowerCase()}&&old_name=${this.state.old_name}&&name=${this.state.name}&&type=department_updated&&description=${this.state.description}&&old_description=${this.state.old_description}&&old_agents=${this.state.oldAgentsName.toString()}&&agents=${this.state.newAgentsName.toString()}&&company_name=${this.state.company_name}`
              ];
              fetch(GATEWAY_URL+'/sendEmail', {
                method: "post",
                body: body,
                headers: { "X-API-Key" : FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
              })
                .then()
                .then(response => {
                //   console.log(response);
                })
                .catch(e => {
                  console.log(e, "ERROR");
                });
    }

    render() {
        let selectedAgents = [];
        this.state.agentsWithoutOwner.map(v => {
            if (this.state.agentList.indexOf(v.id) !== -1) {
                selectedAgents.push(v);
            }
        });
       /*  let agents = this.state.agents.map((value,index) => {
            return <option key={index} value={value.id} id={value.name}>{value.name}</option>
        }) */
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showEditDepartment ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Edit Team</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideEditDepartment}>
                                        <img alt="" src={ require('../../assets/img/icon/right-panel-crose.svg') } />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" maxLength="40" id="outlined-basic" label="Team Name*" variant="outlined" name="name" value={this.state.name} onChange={this.handleChange} onKeyUp={this.validation} autoComplete="off" />
                                        {/* <label className="mb-1 small text-screen1">Name</label>
                                        <input type="text" placeholder="Enter Team*" maxLength="40" className="w-100" name="name" onChange={this.handleChange} value={this.state.name}  autoComplete="off" onKeyUp={this.validation} /> */}
                                        <p className="error">{this.state.name_error}</p>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" rows={4} id="outlined-multiline" label="Team Description" variant="outlined" name="description" onChange={this.handleChange} value={this.state.description}  multiline />
                                        {/* <label className="mb-1 small text-screen1">Description</label>
                                        <textarea type="text" placeholder="Description" className="w-100" name="description"  onChange={this.handleChange} value={this.state.description}></textarea> */}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4 mt-2">
                                    <div className="input-group material-select">

                                        <Autocomplete
                                            multiple
                                            limitTags={2}
                                            options={this.state.agentsWithoutOwner}
                                            value={selectedAgents}
                                            open={true}
                                            //disableCloseOnSelect
                                            onChange={(e, option) => this.handleAgentChange(e, option)}
                                            getOptionLabel={(option) => option.name}
                                            renderOption={(option, { selected }) => (
                                                <><Checkbox icon={icon} name="agentList" value={option.id} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />{option.name}</>
                                            )}
                                            style={{ width: 500 }}
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Select Agent(s)" placeholder="Search Agent" />
                                            )}
                                        />

                                        {/* <FormControl className="MuiFormControl-root MuiTextField-root form-control w-100 mb-2">
                                            <InputLabel shrink htmlFor="select-multiple-native">Agents</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true}}
                                            multiple
                                            native
                                            labelId="select-multiple-native"
                                            id="select-multiple-agentList"
                                            label="Agents"
                                            name="agentList"
                                            value={this.state.agentList}
                                            onChange={this.handleChange}
                                            inputProps={{
                                                id: 'select-multiple-native',
                                            }}
                                            >
                                            {agents}
                                            </Select>
                                        </FormControl> */}
                                        {/* <label className="mb-1 small text-screen1">Select Agent</label>
                                        <select className="customArrow" name="agentList" value={this.state.agentList} onChange={this.handleChange} multiple>
                                            {agents}
                                        </select> */}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4 mt-5 posBottom">
                                    <div className="wrapperInfo">
                                        <button className= { this.state.disableUpdateBtn ? "mr-4 btnBlue btn-disable" : "mr-4 btnBlue"} disabled={this.state.disableUpdateBtn}>Update</button>
                                        <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default EditDepartment;