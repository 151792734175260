import React, { Component } from 'react';
import { NotificationContainer, NotificationManager } from "react-notifications";
// import db from '../../config/firebase';
import { mailUrl, FUNCTION_API_KEY } from '../../config/siteConfig';
import { getDynamicUrl } from './Model/DbModel'
import ChatContext from './../livechat/components/ChatContext';
import firebaseServices from '../../firebase';

class WidgetInstallationComponent extends Component {

    static contextType = ChatContext;  // this.context will work when it defined as is
    constructor(props, context) {
        super(props)
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            openEmailBox: false,
            emailUser: '',
            developerMessage: ' We are going to use Appy Pie Livechat on our website and I want you to install the widget code, enclosed in this email, on our website.',
            onButton: false,
            guideSnapShot:'',
            livechat_guide: true,
            livechat_step:0
        }
    }

    /**
     * component Did mount
     */
    componentDidMount() {

        let userDetails = JSON.parse(localStorage.getItem('agent'));
        const uid = userDetails.ownerId
        const BotID = userDetails.agentId
        const dynamicUrl = getDynamicUrl();
        const linkUrl = `id="appyWidget" src="${dynamicUrl}/widget/build.js?cid=${uid}-${BotID}"`;

        this.setState({ loader: true, linkUrl: linkUrl });
        let ownerSnapshot = firebaseServices.db.collection('users').doc(`${uid}`)
            .onSnapshot(userDocumentSnapshot => {
                if (userDocumentSnapshot.exists) {
                    // if (userDocumentSnapshot.data().widget_status) {
                        // this.setState({ widgetVarify: true });
                        // this.context.updateGlobalData('widgetVerify', true);
                        var arrayToSet = [];
                        if (userDocumentSnapshot.data().widget_status) {
                            var Obj1 = {
                                key: "widgetVerify",
                                value: true
                            }
                            arrayToSet.push(Obj1);
                        }
                        const launchedLiveChat = userDocumentSnapshot.data().havelaunchedLiveChat ? userDocumentSnapshot.data().havelaunchedLiveChat : false;
                        if (launchedLiveChat === false) {
                            var Obj2 = {
                                key: "launchedLiveChat",
                                value: false
                            }
                            arrayToSet.push(Obj2);
                        }
                        if(arrayToSet.length > 0) {
                            this.context.updateGlobalData("array", arrayToSet);
                        }
                        ownerSnapshot();
                        // NotificationManager.success("", 'Successfull install Widegt ', 5000);
                        // setTimeout(()=>{
                        //     this.props.history.push("/widget-success")
                        // },5000)
                    // }
                }
                this.setState({ loader: false });
            })
        // .catch(err => {
        //     this.setState({ loader: false });
        // })

        // this.state.guideSnapShot = firebaseServices.db.collection('users').doc(`${uid}`).collection('onboarding_guide').doc('livechat')
        //     .onSnapshot(tourSnaphot => {
        //         if (tourSnaphot.exists) {
        //             let tourSnaphotData = tourSnaphot.data();
        //             if(tourSnaphotData.status === true || tourSnaphotData.step > 1) {
        //                 this.state.guideSnapShot();
        //                 this.setState({ loader: false });
        //             } else {
        //                 this.setState({ 
        //                     loader: false, 
        //                     livechat_guide: tourSnaphotData.status,
        //                     livechat_step: tourSnaphotData.step
        //                 });
        //             }
        //         }
                
        // })
    }

    /**
     * verify Widget
     */
    varifyWidget = () => {
        // this.props.history.push("/widgetsetting"); // TODO: need to change this page to setting page when developed
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(this.context.livechat_guide === false && this.context.livechat_step === 1) {
            this.guidenextStep();
        }
        // this.props.history.push("/" + userDetails.ownerIdentifire + '/install-widget'); // TODO: need to change this page to setting page when developed
        window.location = "/" + userDetails.ownerIdentifire + '/install-widget';
        return;
    }

    /**
     * Onchange handler 
     */
    onChangeHandler = (e) => {
        this.setState({ emailUser: e.target.value })
    }

    // tex area Development
    handleDeveloperMessage = (e) => {
        this.setState({ developerMessage: e.target.value })
    }

    /**
    * Validate Email
    */
    validateEmail = (email) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase());
    }

    /**
     *  Send Email For Developer 
     */
    openPopupBoxForEmail = () => {
        this.setState({ openEmailBox: !this.state.openEmailBox })
    }
    /**
     * 
     */
    closeEmailBox = () => {
        this.setState({ openEmailBox: !this.state.openEmailBox })
    }

    /**
     *  Send Email For Developer 
     */
    sendEmailDeveloper = () => {

        const { emailUser, developerMessage, linkUrl } = this.state;
        const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        const ownerEmail = userDetails.ownerEmail ? userDetails.ownerEmail : userDetails.email;
        // console.log(emailUser, linkUrl)
        if (!emailUser) {
            NotificationManager.error("", 'Please provide email to send mail', 5000);
            return;
        }

        // console.log(this.validateEmail(emailUser));
        if (!this.validateEmail(emailUser)) {
            NotificationManager.error("", 'Please provide valid email', 5000);
            return
        }

        this.setState({ loader: true });
        // var body = [`to=${emailUser.toLowerCase()}&&data=${data}`];
        var body = [`email=${emailUser.toLowerCase()}&type=widget_setup&URL=${linkUrl}&instruction=${developerMessage}&ownername=${userDetails.company_name}&ownerEmail=${ownerEmail}`];
        // fetch(`${mailUrl}/mailit`, {
        fetch(`${mailUrl}`, {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then((response) => {
                this.setState({ loader: false, emailUser: '' });
                // sucess login Ny Common LoginAPI  
                NotificationManager.success("", 'Sent Widget code Successfully', 5000);

            }).catch((e) => {
                this.setState({ loader: false });
            });
    }

    mouseOnButton = () => {
        this.setState({
            onButton: true
        })
    }

    mouseLeftbutton = () => {
        this.setState({
            onButton: false
        })
    }

    endGuideTour = () => {
        this.props.endGuideTour("livechat", "livechat_guide");
        // firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("livechat").update({
        //     status:true
        // })
        // this.context.updateGlobalData('livechat_guide', true);
    }

    guidenextStep = () => {
        this.props.guidenextStep("livechat", 2, "livechat_step");
        // firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc("livechat").update({
        //     step:2
        // })
        // this.setState({
        //     livechat_step:2
        // });
    }

    render() {
        var backgroundColor = this.context.launchedLiveChat === true ? '#0984f6' : "#eaeaea";
        var textColor = this.context.launchedLiveChat === true ? '#fff' : "#868686";
        // var backgroundColor =  "#eaeaea";
        // var textColor = '#868686' ;
        // backgroundColor = this.state.onButton ? (backgroundColor === '#eaeaea' ? '#0984f6' : backgroundColor) : backgroundColor;
        // textColor = this.state.onButton ? (textColor === '#868686' ? '#fff' : textColor) : textColor;

        let showGuide = ((this.props.context.incoming_guide === false && this.props.context.incoming_guide_step < 3) && this.props.context.incomingChatIds.length > 0) ? false : true;
        return (

            <React.Fragment>

                { this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
                <div className="overlay-guide" style={{display: this.props.context.livechat_guide === false && this.props.context.livechat_step === 1 && showGuide === true ? 'block' : 'none' }}></div>

                <div className="chat-body position-relative">
                    <NotificationContainer />
                    <div className="verificationCodeSection">
                        <div className="verificationCodeSection bg-white">
                            <div className="custmize-your-widget custimize-livechat pos-fixed">
                                <div className="install-chat">
                                    <div className="chat-screen text-center pb-5">
                                        <img src={require('./../../assets/images/instal-chat.svg')} />
                                    </div>
                                    <div className="chat-screen-details text-center mt-5">
                                        <h4 className="card-title mb-1"><strong> Want to start chatting with your visitors?</strong> </h4>
                                        <p className={this.props.context.livechat_guide === false && this.props.context.livechat_step === 1 && showGuide === true ? "card-body-text position-top" : "card-body-text"}>
                                            <button className="btnOrangeSettings my-4 mx-4" onClick={() => this.varifyWidget()} style={{ background: backgroundColor, color: textColor, border: 'none', fontWeight: 'bold' }} onMouseEnter={this.mouseOnButton} onMouseLeave={this.mouseLeftbutton}>Install widget</button>
                                            
                                            {this.props.context.livechat_guide === false && this.props.context.livechat_step === 1 && showGuide === true ? (<div className="tour_guide">
                                                <p className="title">Step 2 of 4</p>
                                                <p className="text-center">Click on this button to go to install widget screen.</p>
                                                <button className="btn-next" onClick={this.guidenextStep}>Next</button><button className="end-btn" onClick={this.endGuideTour}>End tour</button></div>) : (null) }
                                        {this.props.context.launchedLiveChat === true ? (null) : (<>or <a href="#" className="link-style" onClick={() => this.openPopupBoxForEmail()} >email instructions</a> to developer.</>)} </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>


                    {this.state.openEmailBox &&

                        (<div className="popupShadow" style={{ display: 'block' }}>

                            <div className="popupInstallLivechat popupLivechat" >
                                <div className="popupHeader py-4 px-5 k-flex align-items-center">
                                    <h6 className="card-title mb-0 white-text">Send installation instructions to your developer</h6>
                                    <span className="close-popup ml-auto" onClick={() => this.closeEmailBox()} >
                                        <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                                            <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                                            <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                                <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                                <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                                            </g>
                                        </svg>
                                    </span>
                                </div>
                                <div className="popupBody pb-5 px-5">
                                    <div className="py-5 feilds-instruction">
                                        <h6 className="card-title mb-0">Enter developer's email address</h6>
                                        <input name="email" value={this.state.emailUser} placeholder="Enter email address" onChange={(e) => this.onChangeHandler(e)} />
                                    </div>
                                    <div className="pb-5 feilds-instruction">
                                        <h6 className="card-title mb-0">Add a message for your developer's (Optional)</h6>
                                        <textarea name="developerMessage" rows="3" onChange={(e) => this.handleDeveloperMessage(e)} value={this.state.developerMessage}></textarea>
                                        {/* <h6 className="card-title mb-0">Intuction</h6> */}
                                    </div>
                                    <div>
                                        <button onClick={() => this.sendEmailDeveloper()} className="btnBlueSettings">Send instructions </button>
                                            &nbsp;
                                            <button className="btnWhiteSettings mr-2" onClick={() => this.closeEmailBox()}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </div>)

                    }
                </div>
            </React.Fragment>
        );
    }
}

export default WidgetInstallationComponent;