import React from 'react';
import LeftPanel from '../views/LeftPanel';
// import db from '../../../config/firebase';
import { toast } from 'react-toastify';
import ReactTooltip from "react-tooltip";
import moment from "moment-timezone";
import { getDateInUTC, getClientGeoDetail } from '../../livechat/components/Comman';
import { getCountries, getAvailablePhoneNumbers, buyNumber, releaseNumber } from '../../livechat/components/Twilio';

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../../firebase';
/* For Dropdown End */

const US_STATES = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
};

let region_list = [];
region_list.push({ region_code: "all", region_name: "Select state" });
for (const key in US_STATES) {
    if (US_STATES.hasOwnProperty(key)) {
        const c = US_STATES[key];
        var temp = {};
        temp['region_code'] = key;
        temp['region_name'] = c.trim();
        region_list.push(temp);
    }
}

class CallTextSettings extends React.Component {
    constructor() {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if (this.Agent.roleType !== 'Owner') {
            window.location.href = "/" + this.Agent.ownerIdentifire;
        }
        this.state = {
            loader: false,
            voip_call: true,
            phone_call: true,
            enable_call_text: 1,
            ownerSnapshot: "",
            countryList: [],
            numberList: [],
            mnumberList: [],
            showConfirmWindow: false,
            selectedNumber: false,
            selectedCountry: "US",
            selectedRegion: "all",
            selectedSType: "all",
            search_text: "",
            error_message: null,
            geoDetail: {},
            showMangeNumber: false,
            showConfirmReleaseWindow: false,
            selectedNumberArray: [],
            disabledButton: false,
            selectedNumberCount: 0
        }
    }

    async componentDidMount() {
        this.getcallTextSettings();
        if (this.Agent.ownerId) {
            this.state.ownerSnapshot = firebaseServices.db.collection('users').doc(this.Agent.ownerId).onSnapshot(async (docs) => {
                if (docs.exists) {
                    let _data = docs.data();
                    let paymentStatus = false;
                    if (_data.payment_status === 'complete') {
                        paymentStatus = true;
                    } else {
                        let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
                        if (nextBillingDate) {
                            let serverTimeStamp = await getDateInUTC();
                            let splitDate = nextBillingDate.split('-');
                            let splitDay = parseInt(splitDate[2]);
                            var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                            paymentDate = paymentDate + 86400000;
                            if (paymentDate < serverTimeStamp) {
                                paymentStatus = false;
                            } else {
                                paymentStatus = true;
                            }
                        } else {
                            paymentStatus = false;
                        }
                    }
                    if (paymentStatus === false) {
                        window.location.href = "/" + this.Agent.ownerIdentifire;
                    }
                } else {

                }
            })
            if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0) {
                return false;
            }
            this.setState({
                loader: true
            });
            this.getPhoneNumbers();
            let allCountries = await getCountries();
            let geodetail = await getClientGeoDetail();
            console.log('geodetail >>>> ', geodetail);
            var srchData = null;
            if (geodetail) {
                srchData = { countryCode: geodetail.country_code, regionCode: geodetail.region_code };
            }
            let allNumbers = await getAvailablePhoneNumbers(srchData);
            this.setState({
                loader: false,
                countryList: (allCountries === "error") ? [] : allCountries,
                numberList: (allNumbers === "error") ? [] : allNumbers,
                geoDetail: geodetail ? geodetail : {}
            });
        }
    }

    getAgentDetail = (agentId) => {
        return new Promise(resolve => {
            firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('agents').doc(agentId).get().then(agent => {
                if (agent.exists) {
                    var _data = agent.data();
                    resolve(_data.name);
                } else {
                    resolve("");
                }
            }).catch(errrr => {
                resolve("");
            });
        });
    }

    getPhoneNumbers = () => {
        this.setState({
            loader: true
        })
        var allPhones = [];
        firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('phone_numbers').orderBy('addedon', 'desc').get().then(async (phonenumbers) => {
            for (const index222 in phonenumbers.docs) {
                const change22 = phonenumbers.docs[index222];
                let vist = change22.data();
                vist.id = change22.id;
                /* get agent name start */
                if (vist.agent_id) {
                    vist.agent_name = await this.getAgentDetail(vist.agent_id);
                }
                /* get agent name end */
                allPhones.push(vist);
            }
            this.setState({ mnumberList: allPhones, loader: false });
        });
    }

    componentWillUnmount() {
        this.state.ownerSnapshot();
    }

    getcallTextSettings = () => {
        this.setState({
            loader: true
        })
        if (this.Agent.ownerId) {
            firebaseServices.db.collection('users').doc(this.Agent.ownerId).get().then((docs) => {
                if (docs.exists) {
                    let _data = docs.data();
                    this.setState({
                        voip_call: _data.voip_call === 0 ? false : true,
                        phone_call: _data.phone_call === 0 ? false : true,
                        loader: false,
                        disabledButton: _data.phone_call === 0 ? true : false
                    })
                } else {
                    this.setState({
                        voip_call: true,
                        phone_call: true,
                        loader: false
                    })
                }
            }).catch(() => {
                toast.error("Something went wrong, Please try after some time!");
            })
        }
    }

    handleChange = (e) => {
        let target = e.target;
        if (target.type === "checkbox") {
            this.setState({
                [target.name]: target.checked
            }, () => {
                this.saveForm();
            })
        } else {
            this.setState({
                [target.name]: target.value
            })
        }
    }

    handleEnabling = (e) => {
        let target = e.target;
        if (target.value === "1") {
            this.setState({
                enable_call_text: target.value,
                voip_call: true,
                phone_call: true,
                disabledButton: false
            }, () => {
                this.saveForm();
            })
        } else if (target.value === "0") {
            this.setState({
                enable_call_text: 0,
                voip_call: false,
                phone_call: false,
                disabledButton: true
            }, () => {
                this.saveForm();
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.saveForm();
    }

    saveForm = () => {
        this.setState({
            loader: true
        })
        firebaseServices.db.collection('users').doc(this.Agent.ownerId).update({
            phone_call: this.state.phone_call === true ? 1 : 0,
            voip_call: this.state.voip_call === true ? 1 : 0,
            widget_voip_call: this.state.voip_call === true ? 1 : 0,
        }).then((res) => {
            let userDetails = localStorage.getItem("agent") ? JSON.parse(localStorage.getItem("agent")) : false;
            if (userDetails) {
                userDetails.phone_call = this.state.phone_call === true ? 1 : 0;
                userDetails.voip_call = this.state.voip_call === true ? 1 : 0;
                userDetails.widget_voip_call = this.state.voip_call === true ? 1 : 0;
                localStorage.setItem("agent", JSON.stringify(userDetails));
            }
            this.setState({
                loader: false
            })
            toast.success("Call and text settings have been updated successfully!");
        }).catch(() => {
            toast.error("Something went wrong, please try again after some time!");
        })
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.getcallTextSettings();
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    confirmBuy = (numberObj) => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0) {
            return false;
        }
        if (this.state.showConfirmWindow) {
            this.cancelBuy();
        } else {
            this.setState({
                showConfirmWindow: true,
                selectedNumber: numberObj
            });
        }
    }

    cancelBuy = () => {
        this.setState({
            showConfirmWindow: false,
            selectedNumber: false
        });
    }

    buyNow = async () => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0 || !this.state.selectedNumber) {
            return false;
        }
        this.setState({
            loader: true
        });
        const selNumberObj = this.state.selectedNumber;
        var res = await buyNumber(selNumberObj);
        if (res.status === "success") {
            let phoneData = res.data;
            if (phoneData.sid && phoneData.phoneNumber) {
                if (phoneData.phoneNumber === "+15005550006") { // testing number
                    phoneData.phoneNumber = '+1' + Math.ceil(Math.random() * 9999999999);
                }
                var serverTimestamp = await getDateInUTC();
                var docId = "PN" + serverTimestamp + Math.ceil(Math.random() * 999999);
                firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('phone_numbers').doc(docId).set({
                    addedon: serverTimestamp,
                    country_iso: selNumberObj.isoCountry,
                    phone: phoneData.phoneNumber,
                    capabilities: phoneData.capabilities,
                    friendlyName: phoneData.friendlyName,
                    sid: phoneData.sid,
                    locality: selNumberObj.locality,
                    postal_code: selNumberObj.postalCode,
                    region: selNumberObj.region,
                    agent_id: 0,
                    org_id: this.Agent.ownerId
                });
                this.setState({
                    loader: false,
                    showConfirmWindow: false,
                    selectedNumber: false,
                    showMangeNumber: true
                });
                toast.success("Purchased numbers successfully!");
                this.getPhoneNumbers();
                // return this.props.history.push("/"+this.Agent.ownerIdentifire+"/phone-numbers");
            } else {
                this.setState({
                    loader: false,
                    showConfirmWindow: false,
                    selectedNumber: false,
                    error_message: "Something went wrong!"
                });
                toast.error("Something went wrong!");
            }
        } else {
            this.setState({
                loader: false,
                showConfirmWindow: false,
                selectedNumber: false,
                error_message: res.data
            });
            toast.error(res.data);
        }
    }

    /* Search functions start */

    changeSearchType = (e) => {
        this.setState({
            selectedSType: e.target.value
        });
    }

    changeCountry = (e) => {
        this.setState({
            selectedCountry: e.target.value
        });
    }

    changeRegion = (e) => {
        this.setState({
            selectedRegion: e.target.value
        });
    }

    changeNumberHandle = (e) => {
        this.setState({
            search_text: e.target.value
        })
    }

    searchNumbers = async () => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0 || this.state.disabledButton) {
            return false;
        }
        this.setState({
            loader: true
        });
        let srchObject = {
            countryCode: this.state.selectedCountry,
            searchType: (this.state.selectedSType !== "all") ? this.state.selectedSType : "",
            search_text: this.state.search_text,
            regionCode: (this.state.selectedRegion !== "all") ? this.state.selectedRegion : ""
        }
        let allNumbers = await getAvailablePhoneNumbers(srchObject);
        this.setState({
            loader: false,
            numberList: (allNumbers === "error") ? [] : allNumbers
        });
    }

    deleteConfirm = (numberObj) => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0) {
            return false;
        }
        if (this.state.showConfirmReleaseWindow) {
            this.cancelDelete();
        } else {
            this.setState({
                showConfirmReleaseWindow: true,
                selectedNumber: numberObj
            });
        }
    }

    cancelDelete = () => {
        this.setState({
            showConfirmReleaseWindow: false,
            selectedNumber: false
        });
    }

    deleteNumber = async () => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0) {
            return false;
        }
        this.setState({
            loader: true
        });
        const numData = this.state.selectedNumber;
        var res = await releaseNumber(numData.sid);
        if (res.status === "success") {
            firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('phone_numbers').doc(numData.id).delete();
            this.getPhoneNumbers();
            this.setState({
                showConfirmReleaseWindow: false,
                selectedNumber: false
            });
        } else {
            this.setState({
                loader: false,
                showConfirmReleaseWindow: false,
                selectedNumber: false,
                error_message: res.data
            });
            toast.error(res.data);
        }
    }
    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    handleBuyCheck = (e, v) => {
        let numbersArray = this.state.selectedNumberArray;
        let selectedNumberCount = this.state.selectedNumberCount;
        if (e.target.checked === true) {
            numbersArray[v.phoneNumber] = v;
            selectedNumberCount = selectedNumberCount+1;
        } else {
            delete numbersArray[v.phoneNumber];
            selectedNumberCount = selectedNumberCount-1;
        }
        this.setState({
            selectedNumberArray: numbersArray,
            selectedNumberCount: selectedNumberCount
        }, () => {
            // console.log(this.state.selectedNumberArray);
        })
    }

    getAllSelected = async () => {
        if (this.Agent.roleType !== "Owner" || Object.keys(this.state.selectedNumberArray).length === 0) {
            return false;
        }
        // console.log('-----498---', Object.keys(this.state.selectedNumberArray).length);
        let selectArraylength = Object.keys(this.state.selectedNumberArray).length;
        let k = 0;
        let selectedNumberArray = this.state.selectedNumberArray;
        let error = false;
        Object.keys(selectedNumberArray).forEach(async (val, i) => {
            console.log(selectedNumberArray[val], i);
            this.setState({
                loader: true
            });
            var selNumberObj = selectedNumberArray[val];
            var res = await buyNumber(selNumberObj);
            if (res.status === "success") {
                let phoneData = res.data;
                if (phoneData.sid && phoneData.phoneNumber) {
                    if (phoneData.phoneNumber === "+15005550006") { // testing number
                        phoneData.phoneNumber = '+1' + Math.ceil(Math.random() * 9999999999);
                    }
                    var serverTimestamp = await getDateInUTC();
                    var docId = "PN" + serverTimestamp + Math.ceil(Math.random() * 999999);
                    firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('phone_numbers').doc(docId).set({
                        addedon: serverTimestamp,
                        country_iso: selNumberObj.isoCountry,
                        phone: phoneData.phoneNumber,
                        capabilities: phoneData.capabilities,
                        friendlyName: phoneData.friendlyName,
                        sid: phoneData.sid,
                        locality: selNumberObj.locality,
                        postal_code: selNumberObj.postalCode,
                        region: selNumberObj.region,
                        agent_id: 0,
                        org_id: this.Agent.ownerId
                    });
                    // return this.props.history.push("/"+this.Agent.ownerIdentifire+"/phone-numbers");
                } else {
                    // this.setState({
                    //     loader: false,
                    //     showConfirmWindow: false,
                    //     selectedNumber : false,
                    //     error_message : "Something went wrong!"
                    // });
                    // toast.error("Something went wrong!");
                    error = true;
                }
                k++;
                if (selectArraylength === k) {
                    this.setState({
                        loader: false,
                        showConfirmWindow: false,
                        selectedNumber: false,
                        error_message: "Something went wrong!"
                    });
                    if (error) {
                        toast.error("Something went wrong!");
                    } else {
                        toast.success("Purchased numbers successfully!");
                        this.getPhoneNumbers();
                        this.setState({
                            showMangeNumber: true
                        })
                    }
                }
            } else {
                // this.setState({
                //     loader: false,
                //     showConfirmWindow: false,
                //     selectedNumber : false,
                //     error_message : res.data
                // });
                // toast.error(res.data);
                k++;
                if (selectArraylength === k) {
                    this.setState({
                        loader: false,
                        showConfirmWindow: false,
                        selectedNumber: false,
                        error_message: res.data
                    });
                    toast.error(res.data);
                }
            }
        })
    }

    canceledAll = () => {
        var allChecks = document.getElementsByClassName('inputChecks');
        for (var i = 0; i < allChecks.length; i++) {
            allChecks[i].checked = false
        }
        this.setState({
            selectedNumberArray: []
        })
    }

    resetForm = () => {
        this.setState({
            selectedCountry: "US",
            selectedRegion: "all",
            selectedSType: "all",
        })
    }

    render() {
        var selRegion = this.state.selectedRegion ? this.state.selectedRegion : (this.state.geoDetail.region_code ? this.state.geoDetail.region_code : "");
        return (
            <>
                <ReactTooltip />
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                    <h3 className="heding-text">Settings</h3>
                    <div className="text-center k-flex align-items-center ml-auto">
                        <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                        <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.cancelForm}>Cancel</button>
                    </div>
                    <div className="mobile-menu" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                {this.state.showConfirmWindow ? (<div className="popupInstallLivechat popupLivechat" >
                    <div className="popupBody pb-5 px-5 text-center">
                        <div className="py-5 feilds-instruction">
                            <span className="congratilation-circle my-2">
                                <img alt="" src={require('../../../assets/img/icon/info.png')} />
                            </span>
                        </div>
                        <div className="pb-5 feilds-instruction">
                            <h6 className="card-title mb-0">Are you sure? You want to add this number.</h6>
                        </div>
                        <div>
                            <button className="btnBlue" onClick={this.buyNow}>Add Now</button> &nbsp;&nbsp;
                            <button className="btnBlue" onClick={this.cancelBuy}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}

                <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                        <div className="mx-8 mob-mx-4">
                                            <div className="main-heading main-heading-title py-5 k-flex">
                                                <h3 className="heding-text pr-5 pt-2">Call and text</h3>
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-100">
                                                        <InputLabel id="outlined-label_enable_call_text">Status</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true}} labelId="outlined-label_enable_call_text" value={this.state.enable_call_text} onChange={this.handleEnabling} id="enable_call_text" name="enable_call_text" label="Status">
                                                            <MenuItem value="1">Enable calling and texting</MenuItem>
                                                            <MenuItem value="0">Disable calling and texting</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/*<span className="info-icon w-200">
                                                     <select className="customArrow" name="enable_call_text" value={this.state.enable_call_text} onChange={this.handleEnabling}>
                                                        <option value="1">Enable calling and texting</option>
                                                        <option value="0">Disable calling and texting</option>
                                                    </select> 
                                                </span> */}
                                            </div>
                                        </div>
                                        <div className="bg-white left-widgetsetings-box mb-foter-Fixed py-8 full-width">
                                            <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                                <form onSubmit={this.handleSubmit}>

                                                    <div className="mx-8 py-2 px-5">
                                                        <div className="settings-feld form-feilds k-flex align-items-center mb-0">
                                                            <div className="material-switch pull-right mt-3 mr-4">
                                                                <input id="voip_call" type="checkbox" name="voip_call" onChange={this.handleChange} value={this.state.voip_call} checked={this.state.voip_call} />
                                                                <label htmlFor="voip_call" className="label-default" />
                                                            </div>
                                                            <h5 className="card-title mb-0">Voip calling</h5>
                                                        </div>
                                                    </div>
                                                    <div className="mx-8 py-2 px-5">
                                                        <div className="settings-feld form-feilds k-flex align-items-center mb-0">
                                                            <div className="material-switch pull-right mt-3 mr-4">
                                                                <input id="phone_call" type="checkbox" name="phone_call" onChange={this.handleChange} value={this.state.phone_call} checked={this.state.phone_call} />
                                                                <label htmlFor="phone_call" className="label-default" />
                                                            </div>
                                                            <h5 className="card-title mb-0">Call and text via phone number</h5>
                                                        </div>
                                                    </div>
                                                    {/* <div className="chat-feilds py-6 px-8 btn-chat-feilds px-5 setting-footer-fixed">
                                                        <div className="text-center k-flex align-items-center">
                                                            <button className="btnBlueSettings mr-2">Save changes</button>
                                                            <button className="btnWhiteSettings mr-2" onClick={this.cancelForm}>Cancel</button>
                                                        </div>
                                                    </div> */}
                                                </form>
                                            </div>
                                            <div className="tab-view mt-8">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                                    <div className="cstm-tab">
                                                        <input type="radio" id="tab1" name="tab" onClick={() => { this.setState({ showMangeNumber: false }) }} checked={!this.state.showMangeNumber} />
                                                        <label htmlFor="tab1"> Buy a number</label>
                                                        <input type="radio" id="tab2" name="tab" onClick={() => { this.setState({ showMangeNumber: true }) }} checked={this.state.showMangeNumber} />
                                                        <label htmlFor="tab2"> Manage numbers</label>
                                                        {!this.state.showMangeNumber ? (<><button className="btnWhiteSettings mr-2" onClick={this.canceledAll}>Cancel</button>
                                                            <button className="btnBlueSettings mr-2" onClick={this.getAllSelected}>Get all selected</button></>) : (null)}
                                                    </div>
                                                    <div className="content-container mt-5">
                                                        {this.state.showMangeNumber ? (<div className="content" id="c2">
                                                            {this.state.showConfirmReleaseWindow ? (<div className="popupInstallLivechat popupLivechat" >
                                                                <div className="popupBody pb-5 px-5 text-center">
                                                                    <div className="py-5 feilds-instruction">
                                                                        <span className="congratilation-circle my-2">
                                                                            <img alt="" src={require('../../../assets/img/icon/info.png')} />
                                                                        </span>
                                                                    </div>
                                                                    <div className="pb-5 feilds-instruction">
                                                                        <h6 className="card-title mb-0">Are you sure? You want to release this number.</h6>
                                                                    </div>
                                                                    <div>
                                                                        <button className="btnBlue" onClick={this.deleteNumber}>Release</button> &nbsp;&nbsp;
                                                                    <button className="btnBlue" onClick={this.cancelDelete}>Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>) : (null)}
                                                            <div className="agent-table mobile-agent-table mobile-tranparent mt-8">
                                                                <table className="table table-borderless mb-0 agentslistTbl mob-tbl-view call-text-table">
                                                                    <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                                        <tr>
                                                                            <th>Number</th>
                                                                            <th>Type</th>
                                                                            <th>Assigned to</th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className="outline-1-black border-radius-table">
                                                                        {this.state.mnumberList.map((v, i) => {
                                                                            return <tr id={v.id} key={i}>
                                                                                <td>{v.phone}</td>
                                                                                <td>{v.country_iso}</td>
                                                                                <td>{v.agent_name ? v.agent_name : "N/A"}</td>
                                                                                <td>
                                                                                    <a href="javascript:void(0)" className="mr-2 py-2 px-6 underline" onClick={() => { this.deleteConfirm(v) }}>Release number</a>
                                                                                </td>
                                                                            </tr>
                                                                        })}
                                                                        {this.state.mnumberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>) : (<div className="content" id="c1">
                                                            <p>{this.state.selectedNumberCount} of {this.state.numberList.length} phone numbers selected</p>
                                                            <div className="main-heading main-heading-title py-5 k-flex">
                                                                    <span className="info-icon w-150 mx-4">
                                                                        <div className="material-select">
                                                                            <FormControl variant="outlined" className="material-outlined w-100">
                                                                                <InputLabel id="outlined-label_country_code">Country</InputLabel>
                                                                                <Select MenuProps={{ disableScrollLock: true }} labelId="outlined-label_country_code" disabled={this.state.disabledButton} value={this.state.selectedCountry} onChange={this.changeCountry} id="country_code" name="country_code" label="Country">
                                                                                    {this.state.countryList.map((cVal, idx) => {
                                                                                        return <MenuItem key={cVal.countryCode} value={cVal.countryCode}>{cVal.country}</MenuItem>
                                                                                    })}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                        {/* <select className="customArrow" name="country_code" disabled={this.state.disabledButton} value={this.state.selectedCountry} onChange={this.changeCountry}>
                                                                        {this.state.countryList.map((cVal, idx) => {
                                                                            return <option key={cVal.countryCode} value={cVal.countryCode}>{cVal.country}</option>
                                                                        })}
                                                                    </select> */}
                                                                    </span>
                                                                
                                                                <span className="info-icon w-150 mx-4">
                                                                        <div className="material-select">
                                                                            <FormControl variant="outlined" className="material-outlined w-100">
                                                                                <InputLabel id="outlined-label_region">Region</InputLabel>
                                                                                <Select MenuProps={{ disableScrollLock: true }} labelId="outlined-label_region" disabled={this.state.disabledButton} value={selRegion} onChange={this.changeRegion} id="region" name="region" label="Region">
                                                                                    {region_list.map((cVal, idx) => {
                                                                                        return <MenuItem key={cVal.region_code} value={cVal.region_code}>{cVal.region_name}</MenuItem>
                                                                                    })}
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                    {/* <select className="customArrow" id="region" name="region" disabled={this.state.disabledButton} value={selRegion} onChange={this.changeRegion}>
                                                                        {region_list.map((cVal, idx) => {
                                                                            return <option key={cVal.region_code} value={cVal.region_code}>{cVal.region_name}</option>
                                                                        })}
                                                                    </select> */}
                                                                </span>
                                                                <span className="info-icon w-150 mx-4">
                                                                        <div className="material-select">
                                                                            <FormControl variant="outlined" className="material-outlined w-100">
                                                                                <InputLabel id="outlined-label_search_type">Type</InputLabel>
                                                                                <Select MenuProps={{ disableScrollLock: true }} labelId="outlined-label_search_type" disabled={this.state.disabledButton} value={this.state.selectedSType} onChange={this.changeSearchType} id="search_type" name="search_type" label="Type">
                                                                                    <MenuItem value="all">Select location</MenuItem>
                                                                                    <MenuItem value="phone">Number</MenuItem>
                                                                                    <MenuItem value="locality">Locality</MenuItem>
                                                                                </Select>
                                                                            </FormControl>
                                                                        </div>
                                                                    {/* <select className="customArrow" name="search_type" disabled={this.state.disabledButton} value={this.state.selectedSType} onChange={this.changeSearchType}>
                                                                        <option value="">Select location</option>
                                                                        <option value="phone">Number</option>
                                                                        <option value="locality">Locality</option>
                                                                    </select> */}
                                                                </span>
                                                                <span className="info-icon">
                                                                    <img alt="" src={require('../../../../src/assets/img/icon/info.png')} data-tip="Select state and location of which you wish to get the number" />
                                                                </span>
                                                                <span className="info-icon mx-10">
                                                                    <button className="btnBlueSettings mr-2" onClick={this.searchNumbers} disabled={this.state.disabledButton}>Search</button>
                                                                    <button className="btnWhiteSettings mr-2" onClick={this.resetForm}>Reset</button>
                                                                </span>
                                                            </div>
                                                            <table className="table table-borderless mb-0 agentslistTbl mob-tbl-view call-text-table">
                                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                                    <tr>
                                                                        <th></th>
                                                                        <th>Number</th>
                                                                        <th>Type</th>
                                                                        <th>Assigned to</th>
                                                                        <th>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="outline-1-black border-radius-table">
                                                                    {this.state.numberList.map((v, i) => {
                                                                        var capblty = "";
                                                                        if (v.capabilities) {
                                                                            if (v.capabilities.MMS) {
                                                                                capblty += "MMS,"
                                                                            }
                                                                            if (v.capabilities.SMS) {
                                                                                capblty += "SMS,"
                                                                            }
                                                                            if (v.capabilities.voice) {
                                                                                capblty += "Voice"
                                                                            }
                                                                        }
                                                                        return <tr id={v.phoneNumber} key={i}>
                                                                            <td><input type="checkbox" className="inputChecks" value={v} onChange={(e) => this.handleBuyCheck(e, v)} /></td>
                                                                            <td>{v.phoneNumber}</td>
                                                                            <td>{v.locality + ", " + v.region}</td>
                                                                            <td>{capblty}</td>
                                                                            <td><a href="javascript:void(0)" className="mr-2 py-2 px-6" onClick={() => this.confirmBuy(v)}>Get number</a></td>
                                                                        </tr>
                                                                    })}
                                                                    {this.state.numberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                                </tbody>
                                                            </table>
                                                        </div>)}


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {this.state.showMangeNumber ? (<div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8 mb-11">
                                    <div className="body-section bg-white px-5 py-5">
                                        <div className="agent-section " id="resposnive-table-class">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="agent-table-header table-header row mb-4">
                                                    <div className="col-xs-12 col-sm-12 col-md-10 col-lg-10">
                                                        <div className="aside-agent k-flex align-items-center justify-content-end">
                                                            <div className="aside-agent k-flex align-items-center mr-5">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.error_message ? (<div className="error">{this.state.error_message}</div>) : (null)}
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="agent-table">
                                                    <table className="table table-borderless mb-0 liveCgatUserTable">
                                                        <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                            <tr>
                                                                <th>Phone Number</th>
                                                                <th>Country</th>
                                                                <th>Agent</th>
                                                                <th>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="outline-1-black border-radius-table">
                                                            {this.state.numberList.map((v, i) => {
                                                                return <tr id={v.id} key={i}>
                                                                    <td>{v.phone}</td>
                                                                    <td>{v.country_iso}</td>
                                                                    <td>{v.agent_name ? v.agent_name : "N/A"}</td>
                                                                    <td>
                                                                        <button className="btnBlueSettings mr-2 py-2 px-6" onClick={() => { this.deleteConfirm(v) }}>Release</button>
                                                                    </td>
                                                                </tr>
                                                            })}
                                                            {this.state.numberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>) : (<><div className="main-heading main-heading-title py-5"></div>
                        <div className="body-section bg-white px-5 py-5 mob-mt-4">
                            <div className="agent-section " id="resposnive-table-class">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mobile-menu text-right mb-5">
                                            <button className="responsive-btn " onClick={this.userFilterMobile}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="20.899" viewBox="0 0 22.674 20.899">
                                                    <path id="Path_1489" data-name="Path 1489" d="M22.608,10.077a.687.687,0,0,0-.622-.394H.687a.687.687,0,0,0-.53,1.124l8.188,9.931V29.9a.687.687,0,0,0,.992.616l4.6-2.28a.687.687,0,0,0,.382-.615l.009-6.878,8.188-9.931A.687.687,0,0,0,22.608,10.077Zm-9.5,9.977a.687.687,0,0,0-.157.436l-.009,6.7-3.226,1.6v-8.3a.687.687,0,0,0-.157-.437l-7.418-9H20.529Z" transform="translate(0 -9.683)" fill="#A2ABD1" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="agent-table-header table-header row mb-8 user-table-main-header input-border phone-fltr">

                                            <div className="input-group pr-0 user-table-live">
                                                <div className="wrapperInfo">
                                                    <select className="customArrow" name="country_code" value={this.state.selectedCountry} onChange={this.changeCountry}>
                                                        {this.state.countryList.map((cVal, idx) => {
                                                            return <option key={cVal.countryCode} value={cVal.countryCode}>{cVal.country}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="input-group pr-0 user-table-live">
                                                <div className="wrapperInfo">
                                                    <select className="customArrow" id="region" name="region" value={selRegion} onChange={this.changeRegion}>
                                                        {region_list.map((cVal, idx) => {
                                                            return <option key={cVal.region_code} value={cVal.region_code}>{cVal.region_name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="input-group pr-0 user-table-live">
                                                <div className="wrapperInfo">
                                                    <select className="customArrow" name="search_type" value={this.state.selectedSType} onChange={this.changeSearchType}>
                                                         <option value="phone">Number</option>
                                                         <option value="locality">Locality</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="input-group pr-0 user-table-live">
                                                <input type="text" className="form-control form-control-lg" placeholder="Optional" name="search_text" value={this.state.search_text} onChange={this.changeNumberHandle} />
                                            </div>
                                            
                                            <div className="srch-btn">
                                                <div className="input-group-append mt-2">
                                                    <button className="btnBlueSettings mr-2 py-2 px-6" onClick={this.searchNumbers}>Search</button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.error_message ? (<div className="error">{this.state.error_message}</div>) : (null)}

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="agent-table">
                                            <table className="table table-borderless mb-0 liveCgatUserTable">
                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                    <tr>
                                                        <th>PHONE NUMBER</th>
                                                        <th>COUNTRY</th>
                                                        <th>CAPABILITIES</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="outline-1-black border-radius-table">
                                                    {this.state.numberList.map((v, i) => {
                                                        var capblty = "";
                                                        if(v.capabilities) {
                                                            if(v.capabilities.MMS) {
                                                                capblty += "MMS,"
                                                            }
                                                            if(v.capabilities.SMS) {
                                                                capblty += "SMS,"
                                                            }
                                                            if(v.capabilities.voice) {
                                                                capblty += "Voice"
                                                            }
                                                        }
                                                        return <tr id={v.phoneNumber} key={i}>
                                                            <td>{v.phoneNumber}</td>
                                                            <td>{v.locality + ", " + v.region}</td>
                                                            <td>{capblty}</td>
                                                            <td><button className="btnBlueSettings mr-2 py-2 px-6" onClick={() => this.confirmBuy(v)}>Add</button></td>
                                                        </tr>
                                                    })}
                                                    {this.state.numberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </>)} */}
                </div>
            </>
        )
    }

}

export default CallTextSettings;