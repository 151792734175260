import React, { Component } from 'react';
import { ChromePicker } from "react-color";
import './css/cums.css'
import { DOMAIN_NAME, COMMON_LOGIN_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
///import { mailUrl } from "../../config/mailFunctions.js";
import { validation } from "./../../config/SignupLogin";
import {
    createNewUser,
    checkCompanyAliasAndCreateUnique,
    upDateUserProfileWithData,
    updateAgentAndChatBotdata,
    updateSessioninCollection,
    getOwnerUserDataForLogin,
    updateAgentData
} from './Model/DbModel'
import {
    isUserExistWithEmail,
    login
} from "./../../config/SignupLogin";
import { checkIsOwnerLogin, checkInitialOwnerWithCompanyNameAndAgentName, cleanupString, getUTCTimeDate } from "./OnboardHelper";

import { NotificationContainer, NotificationManager } from "react-notifications";

import { widgetImage, widgetImages } from "./badgeIconConfig";

import { updateCompanyDetails } from "../../services/service";

const publicIp = require("public-ip");

class ComapanyWidgetInitialSetup extends Component {

    constructor(props) {
        super(props)

        this.state = {
            loader: false,
            company_name: '',
            isColorFromPicker: false,
            isColorFromPanel: true,
            isActiveColorPicker: false,
            rgbColor: { r: 17, g: 16, b: 16, a: 1 },
            bgColor: this.RGBAToHexA({ r: 17, g: 16, b: 16, a: 1 }),
            widgetImage: widgetImage,
            widgetImages: widgetImages,
            IPv4: ""
        }
    }

    // outerwidgetImages1 =  [
    //     {
    //         wtimage: 'comments.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
    //     },
    //     {
    //         wtimage: 'chat.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat.svg?alt=media&token=0ad054ad-51c9-43d0-8123-d935bb258f99',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fchat.svg?alt=media&token=608f0af9-1858-4503-a8c0-e378cc441ee9'
    //     },
    //     {
    //         wtimage: 'chat-bubbles-with-ellipsis.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=d1f7804c-ac78-409d-8680-439f79704ed0',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=da698ce7-e71a-416f-9c0e-404cfa8c88dc'
    //     },
    //     {
    //         wtimage: 'comment.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomment.svg?alt=media&token=ee031555-eb5d-4537-9580-ef93d2aa4f11',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/livechat-production.appspot.com/o/chat_icon%2Fcomment.svg?alt=media&token=6dd76c0d-a4f3-4d59-a740-7f9456cd35c1',
    //     }
    // ]


    // outerwidgetImages2 = [
    //     {
    //         wtimage: 'comments.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomments.svg?alt=media&token=3f4cf82a-02c7-42dd-ae93-60fe0b52bb57'
    //     },
    //     {
    //         wtimage: 'chat.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat.svg?alt=media&token=0ad054ad-51c9-43d0-8123-d935bb258f99',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fchat.svg?alt=media&token=608f0af9-1858-4503-a8c0-e378cc441ee9'
    //     },
    //     {
    //         wtimage: 'chat-bubbles-with-ellipsis.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=d1f7804c-ac78-409d-8680-439f79704ed0',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fchat-bubbles-with-ellipsis.svg?alt=media&token=da698ce7-e71a-416f-9c0e-404cfa8c88dc'
    //     },
    //     {
    //         wtimage: 'comment.svg',
    //         fbwtimage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomment.svg?alt=media&token=ee031555-eb5d-4537-9580-ef93d2aa4f11',
    //         blackImage: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fcomment.svg?alt=media&token=6dd76c0d-a4f3-4d59-a740-7f9456cd35c1',
    //     }
    // ]



    /**
     *  componentDidMount
     */
    componentDidMount = async () => {

        this.setState({ loader: true });

        const loggerInUSer = await checkInitialOwnerWithCompanyNameAndAgentName()
        if (loggerInUSer === 'OWNER') {
            const userInfo = await checkIsOwnerLogin();

            if (userInfo && userInfo?.company_name && userInfo?.organization_alias) {
                localStorage.removeItem('companyName');
                window.location.href = '/'
                return
            }
        } else if (loggerInUSer === 'AGENT') {
            window.location.href = '/'
            return;
        }
        else if (loggerInUSer == 'COMMONLOGIN') {
            /* start of code added by ruchi for common login*/
            var userCommonLoginData = JSON.parse(localStorage.getItem('userdata'));
            const IsEmail = await isUserExistWithEmail(userCommonLoginData.email);
            if (IsEmail) {
                localStorage.setItem("accessToken", userCommonLoginData.access_token);
                //updateSessioninCollection(userCommonLoginData.access_token, userCommonLoginData.email);
                let euserId = IsEmail[0].id
                const getloginData = await getOwnerUserDataForLogin(euserId, userCommonLoginData.email);
                await updateSessioninCollection(userCommonLoginData.access_token, userCommonLoginData.email);
                getloginData.agent_name = getloginData.name;
                getloginData.realmId = userCommonLoginData._id ? userCommonLoginData._id : '';
                localStorage.setItem('agent', JSON.stringify(getloginData));
                const agentId = getloginData.agentId;
                const ownerId = getloginData.ownerId;
                const newAgentData = {
                    last_login: getUTCTimeDate(),
                    online_status: 1
                }
                await updateAgentData(ownerId, agentId, newAgentData)
                //window.location = '/'
                const getUserLogin = localStorage.getItem('redirectPage');
                this.setState({ loader: false });
                if (getUserLogin) {
                    localStorage.removeItem('redirectPage');
                    this.props.history.push(getUserLogin);
                    return
                } else {
                    window.location = '/'
                    // this.props.history.push("/");
                    return
                }
                //return;
            }
            else {

            }
            /* end of code added by ruchi for common login*/
        }

        // return
        let company_name = localStorage.getItem('companyName');
        /**
         * company_name is false mean user not follow initial on-bording step 
         */
        if (!company_name) {
            window.location = '/company-info'
            return
        }

        this.setState({ company_name: company_name, loader: false })

        publicIp.v4().then((ip4) => {
            this.setState({
                IPv4: ip4
            })
        });
    }


    /**
     *  var color 
     */
    color = [
        { r: 17, g: 16, b: 16, a: 1 },
        { r: 230, g: 53, b: 33, a: 1 },
        { r: 255, g: 206, b: 0, a: 1 },
        { r: 140, g: 127, b: 190, a: 1 },
        { r: 13, g: 132, b: 246, a: 1 },
        { r: 74, g: 183, b: 115, a: 1 }
    ];

    /**
     * change Chat images
     */
    changeChatImage = (item) => {
        this.setState({ widgetImage: item });
    }

    /**
     * convert ggb to hex code
     * @param {object} color 
     */
    RGBAToHexA(color) {
        var r = color.r.toString(16);
        var g = color.g.toString(16);
        var b = color.b.toString(16);
        var a = Math.round(color.a * 255).toString(16);

        if (r.length === 1) r = "0" + r;
        if (g.length === 1) g = "0" + g;
        if (b.length === 1) b = "0" + b;
        if (a.length === 1) a = "0" + a;
        return "#" + r + g + b + a;
    }

    /**
     * Choose back ground color 
     * 
     * @param {object}  Color Hex code Object
     */
    chooseBackGroundColor = (item) => {
        const bgColor = this.RGBAToHexA(item);
        this.setState({ bgColor, 'rgbColor': item, isColorFromPanel: true, isColorFromPicker: false, isActiveColorPicker: false })
    }

    /**
     * Background 
     */
    onChangeColorPicker = (backgrouns, color) => {

        const bgColor = this.RGBAToHexA(color.rgb);
        this.setState({ bgColor, isColorFromPicker: true, isColorFromPanel: false })

    }

    /**
     * Valiadte And Move Next Page Of Registarion 
     */
    nextRegistrationPage = async () => {

        const rgbColor = this.state.rgbColor;
        const bgColor = this.state.bgColor;
        const widgetImage = this.state.widgetImage
        localStorage.setItem('settingrgbColor', rgbColor)
        localStorage.setItem('settingbgColor', bgColor)
        localStorage.setItem('clientBackgroundColor', bgColor)
        // localStorage.setItem('settingChatIcon',widgetImage)
        localStorage.setItem('chatBlackImage', widgetImage.blackImage)
        localStorage.setItem('whiteImage', widgetImage.wtimage)
        localStorage.setItem('fbwtimage', widgetImage.fbwtimage)
        localStorage.setItem('sendButtonClr', bgColor);
        localStorage.removeItem("windowFocusOut");

        this.setState({ loader: true });
        // if user login the signup and send to 5th step
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        var userCommonLoginData = JSON.parse(localStorage.getItem('userdata'));
        let getUpdateData = localStorage.getItem('updataAfterLogin') ? JSON.parse(localStorage.getItem('updataAfterLogin')) : false;
        
        if (userDetails && userDetails.ownerId) {
            var res = await validation(userDetails.ownerId);

            const uid = userDetails.ownerId;
            const chatid = userDetails.agentId
            // return ''
            if (res.valid) {
                // Save data in db
                const company_name = localStorage.getItem('companyName');
                if (company_name) {
                    // const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
                    const company_namewithoutSPace = cleanupString(company_name)
                    const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
                    let neworgAlias = company_namewithoutSPace;
                    if (uniqueResponce) {
                        let randValu = Math.floor(Math.random() * 10000);
                        neworgAlias = company_namewithoutSPace + randValu;
                    } else {
                        neworgAlias = company_namewithoutSPace
                    }
                    const update_data = {
                        company_name: company_name,
                        organization_alias: neworgAlias
                    }


                    let updasdasdasd = await updateCompanyDetails(uid, userDetails.email, neworgAlias, company_name);
                    await upDateUserProfileWithData(uid, update_data)
                    // insert editedBots
                    const customChatbot = {
                        'customisation.widgetImage': widgetImage.fbwtimage, // save firebase store this image Display when first bot reply
                        'customisation.chatBotAvtar': widgetImage.fbwtimage, // save firbase store White Image
                        'customisation.widgetBackgroundColor': bgColor,
                        'customisation.headerClr': bgColor,
                        'customisation.botButtonColor': bgColor,
                        'customisation.clientBubbleClr': bgColor,
                        'customisation.headerName': company_name,
                        'customisation.clientBackgroundColor': bgColor,
                        'customisation.sendButtonClr': bgColor
                    }


                    // update localcstorage setting data and send to 5th step
                    await updateAgentAndChatBotdata(uid, chatid, customChatbot);
                    localStorage.removeItem("password");
                    // localStorage.removeItem("OwnerName");
                    localStorage.removeItem('settingrgbColor')
                    localStorage.removeItem('settingbgColor')
                    localStorage.removeItem('clientBackgroundColor')
                    localStorage.removeItem('settingChatIcon')
                    localStorage.removeItem('chatBlackImage')
                    localStorage.removeItem('whiteImage')
                    localStorage.removeItem('fbwtimage')
                    localStorage.removeItem("_email");
                    localStorage.removeItem("companyName");
                    localStorage.removeItem("sendButtonClr");                                        

                    userDetails.company_name = company_name
                    userDetails.organization_alias = neworgAlias
                    userDetails.ownerIdentifire = neworgAlias;
                    userDetails.agent_name = userDetails.name;
                    // new setup agent in local storage

                    localStorage.setItem('agent', JSON.stringify(userDetails));

                    window.location = '/' + neworgAlias;
                    this.setState({ loader: false });
                    return
                } else {
                    this.setState({ loader: false });
                }
            } else {

                // not a vlid user 
                // localStorage.removeItem('agents')
                // localStorage.removeItem('ref', widgetImage.blackImage)
                // this.props.history.push("/company-registration");
                // this.setState({ loader: false });

                if (userDetails !== null && userDetails !== undefined) {
                    window.location.href = "/";
                    return true
                } else {
                    this.props.history.push("/company-registration");
                }

            }
        }
        else if (userCommonLoginData && userCommonLoginData.email) {
            console.log('----348-----');
            setTimeout(async () => {
                console.log('----350-----');
                return false;
                const accessToken = userCommonLoginData.access_token;
                var email = userCommonLoginData.email;
                localStorage.setItem("accessToken", accessToken);
                const companyName = localStorage.getItem('companyName');
                const settingrgbColor = localStorage.getItem('settingrgbColor')
                const settingbgColor = localStorage.getItem('settingbgColor')
                const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
                const settingChatIcon = localStorage.getItem('settingChatIcon')
                const chatBlackImage = localStorage.getItem('chatBlackImage')
                const whiteImage = localStorage.getItem('whiteImage')
                const fbwtimage = localStorage.getItem('fbwtimage')

                let neworgAlias = '';
                const company_name = companyName
                if (company_name) {
                    // const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
                    const company_namewithoutSPace = cleanupString(company_name)
                    const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
                    if (uniqueResponce) {
                        let randValu = Math.floor(Math.random() * 10000);
                        neworgAlias = company_namewithoutSPace + randValu;
                    } else {
                        neworgAlias = company_namewithoutSPace
                    }
                }

                // initil Data to create User
                const updateData = {
                    companyName: companyName ? companyName : '',
                    organization_alias: neworgAlias ? neworgAlias : '',
                    settingrgbColor: settingrgbColor ? settingrgbColor : '',
                    settingbgColor: settingbgColor ? settingbgColor : '',
                    clientBackgroundColor: clientBackgroundColor ? clientBackgroundColor : '',
                    settingChatIcon: settingChatIcon ? settingChatIcon : '',
                    email: email ? email : '',
                    whiteImage: whiteImage ? whiteImage : '',
                    chatBlackImage: chatBlackImage ? chatBlackImage : '',
                    fbwtimage: fbwtimage ? fbwtimage : '',
                    //	password: password,
                    accessToken: accessToken ? accessToken : '',
                    sendButtonClr: settingbgColor ? settingbgColor : ''
                }
                // two field or mandatory
                //  Email : accessToken
                // UserDocID get User table Doc ID 
                console.log('updateData>>>', updateData);

                const UserDocID = await createNewUser(updateData, 1)
                return false;

                if (UserDocID) {
                    // TODO: login User here In system
                    // Add User DataENtry Through ThisAPi 
                    localStorage.removeItem("password");
                    // localStorage.removeItem("OwnerName");
                    localStorage.removeItem('settingrgbColor')
                    localStorage.removeItem('settingbgColor')
                    localStorage.removeItem('clientBackgroundColor')
                    localStorage.removeItem('settingChatIcon')
                    localStorage.removeItem('chatBlackImage')
                    localStorage.removeItem('whiteImage')
                    localStorage.removeItem('fbwtimage')
                    localStorage.removeItem("_email");
                    localStorage.removeItem("companyName");
                    updateSessioninCollection(accessToken, email);
                    const getloginData = await getOwnerUserDataForLogin(UserDocID, email);
                    delete getloginData.password
                    // valid user
                    let chatURL = DOMAIN_NAME + '/' + neworgAlias;
                    var body = [`email=${email.toLowerCase()}&&type=welcome_email&&data=${"data"}&&req_ip=${this.state.IPv4}&&chatURL=${chatURL}`];
                    this.sendEmail(body);
                    getloginData.agent_name = getloginData.name;
                    localStorage.setItem('agent', JSON.stringify(getloginData));
                    this.props.history.push("/agent-setup");
                    this.setState({ loader: false });

                }
            }, 600);
        }
        else if (getUpdateData) {
            const company_name = localStorage.getItem('companyName');
            if (company_name) {
                // const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
                const company_namewithoutSPace = cleanupString(company_name)
                const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
                let neworgAlias = company_namewithoutSPace;
                if (uniqueResponce) {
                    let randValu = Math.floor(Math.random() * 10000);
                    neworgAlias = company_namewithoutSPace + randValu;
                } else {
                    neworgAlias = company_namewithoutSPace
                }
                const update_data = {
                    company_name: company_name,
                    organization_alias: neworgAlias
                }
                getUpdateData.companyName = company_name;
                getUpdateData.organization_alias = neworgAlias;
                const updateData = getUpdateData;
                const UserDocID = await createNewUser(updateData, 2)
                if (UserDocID) {
                    let chatURL = DOMAIN_NAME + '/' + neworgAlias;
                    var body = [`email=${getUpdateData.email.toLowerCase()}&type=welcome_email&data=${"data"}&req_ip=${this.state.IPv4}&&chatURL=${chatURL}`];
                    this.sendEmail(body);
                    const customChatbot = {
                        'customisation.widgetImage': widgetImage.fbwtimage, // save firebase store this image Display when first bot reply
                        'customisation.chatBotAvtar': widgetImage.fbwtimage, // save firbase store White Image
                        'customisation.widgetBackgroundColor': bgColor,
                        'customisation.headerClr': bgColor,
                        'customisation.botButtonColor': bgColor,
                        'customisation.clientBubbleClr': bgColor,
                        'customisation.headerName': company_name,
                        'customisation.clientBackgroundColor': bgColor,
                        'customisation.sendButtonClr': bgColor
                    }

                    const getloginData = await getOwnerUserDataForLogin(UserDocID, updateData.email);
                    // update localcstorage setting data and send to 5th step
                    await updateAgentAndChatBotdata(getloginData.ownerId, getloginData.agentId, customChatbot);

                    await updateSessioninCollection(updateData.accessToken, updateData.email);

                    console.log('--------getloginData >>> ', getloginData);
                    delete getloginData.password
                    // valid user
                    getloginData.ownerEmail = getloginData.email;
                    getloginData.agent_name = getloginData.name;
                    localStorage.setItem('agent', JSON.stringify(getloginData));
                    localStorage.removeItem("password");
                    localStorage.removeItem('settingrgbColor')
                    localStorage.removeItem('settingbgColor')
                    localStorage.removeItem('clientBackgroundColor')
                    localStorage.removeItem('settingChatIcon')
                    localStorage.removeItem('chatBlackImage')
                    localStorage.removeItem('whiteImage')
                    localStorage.removeItem('fbwtimage')
                    localStorage.removeItem("_email");
                    localStorage.removeItem("companyName");
                    localStorage.removeItem("updataAfterLogin");
                    this.props.history.push('/agent-setup');
                    // const getUserLogin = localStorage.getItem('redirectPage');
                    // if (getUserLogin) {
                    //     this.props.history.push(getUserLogin);
                    //     return
                    // } else {
                    //     window.location.href = '/agent-setup';
                    //     return
                    // }
                } else {

                    // this.props.history.push("/company-login");// TODO: redirect 30 
                    NotificationManager.error("", "Opps SomeThing Wrong please Contact To administartor.", 3000);
                }

                // await upDateUserProfileWithData(uid,update_data)
                // insert editedBots
                // localStorage.removeItem("password");
                // // localStorage.removeItem("OwnerName");
                // localStorage.removeItem('settingrgbColor')
                // localStorage.removeItem('settingbgColor')
                // localStorage.removeItem('clientBackgroundColor')
                // localStorage.removeItem('settingChatIcon')
                // localStorage.removeItem('chatBlackImage')
                // localStorage.removeItem('whiteImage')
                // localStorage.removeItem('fbwtimage')
                // localStorage.removeItem("_email");
                // localStorage.removeItem("companyName");
                // localStorage.removeItem("sendButtonClr");

                // userDetails.company_name = company_name
                // userDetails.organization_alias = neworgAlias
                // userDetails.ownerIdentifire = neworgAlias;
                // // new setup agent in local storage

                // localStorage.setItem('agent',JSON.stringify(userDetails));

                // window.location = '/'+neworgAlias;
                // this.setState({ loader: false });
                // return
            }
        } else {

            //  this.props.history.push("/company-registration");
            window.location.href = COMMON_LOGIN_URL + "/register?frompage=" + DOMAIN_NAME + "/common-session&website=" + DOMAIN_NAME;
            this.setState({ loader: false });
        }


        if (localStorage.getItem("skipSignUp") === "true") {
            this.props.history.push("/company-verification");
        } else {
            // this.props.history.push("/company-registration");
            this.props.history.push("/common-session");
            // this.props.history.push("/company-registration"); // commented by dharmesh for ticket 879
        }

    }

    previewSlide() {
        var element = document.getElementById("widgetShow");
        element.classList.toggle("show");
    }

    sendEmail = (body) => {
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key" : FUNCTION_API_KEY,"Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    sendEmail = (body) => {
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key" : FUNCTION_API_KEY,"Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

                <NotificationContainer />

                <div className="customize-widget">
                    <div className="container ps-relative">
                        <div className="getstarted-header">
                            <div className="logo-section">
                                <img alt="" src={require('./../../assets/images/appypie-livechat.svg')} />
                            </div>
                        </div>
                        <div className="custmize-your-widget mt-8">
                            <div className="custmize-your-widget-heading custmize-heading text-center pb-6">
                                <h1 className="card-title">Customize your widget</h1>
                                <p className="text-mutede"><strong>Pro tip : </strong>We suggest you to match your widget's design to your website to create a unified experience.</p>
                            </div>
                            <div className="custome-widget-bot k-flex align-items-center justify-content-center mb-6 position-relative mt-10">
                                <div className="widget-settings pl-11">
                                    <div className="widget-badge widget-sec pb-8">
                                        <h4 className="card-title mb-0">Widget badge style</h4>

                                        {/* {   make it dynmic } */}
                                        <ul className="pt-3">
                                            {
                                                this.state.widgetImages.map((item, i) =>
                                                (

                                                    (<li key={i}
                                                        className={
                                                            this.state.widgetImage.blackImage === item.blackImage
                                                                ? "active"
                                                                : ""
                                                        }
                                                        onClick={() => this.changeChatImage(item)} >
                                                        {/* <span><img alt="" src={`${item.blackImage}`} alt="" /></span> */}
                                                        <span><img src={require(`./../../assets/images/widget/${item.wtimage}`)} alt="" /></span>


                                                    </li>)

                                                )
                                                )
                                            }
                                        </ul>

                                    </div>
                                    <div className="widget-theme widget-sec">
                                        <h4 className="card-title mb-0">Theme color</h4>
                                        <ul className="pt-3">

                                            {
                                                this.color.map((item, i) => {

                                                    return (<li key={i}
                                                        className={
                                                            (this.state.bgColor === this.RGBAToHexA(item)) && this.state.isColorFromPanel ? 'active' : ''
                                                        }
                                                        onClick={() => this.chooseBackGroundColor(item)}
                                                    >
                                                        <span style={{ backgroundColor: this.RGBAToHexA(item) }}> </span>

                                                    </li>)

                                                })
                                            }


                                            {
                                                this.state.isColorFromPicker &&
                                                (<li className='active'  >
                                                    <span style={{ backgroundColor: this.state.bgColor }} />
                                                </li>)
                                            }

                                            <li onClick={() => { this.setState({ isActiveColorPicker: !this.state.isActiveColorPicker }) }}>
                                                {/*<span style={{ background: 'rgb(162,58,180)', background: 'radial-gradient(circle, rgba(162,58,180,1) 15%, rgba(29,63,253,1) 37%, rgba(82,252,69,1) 92%)' }} />  */}
                                                <span style={{ background: 'radial-gradient(circle, rgba(162,58,180,1) 15%, rgba(29,63,253,1) 37%, rgba(82,252,69,1) 92%)' }} />
                                            </li>

                                            {this.state.isActiveColorPicker && (
                                                <div className="color-picker-palette" style={{
                                                    position: 'absolute',
                                                    zIndex: '100'
                                                }}>
                                                    <div
                                                        className="color-picker-cover" style={{
                                                            position: 'fixed',
                                                            top: '0px',
                                                            left: '0px',
                                                            bottom: '0px',
                                                            right: '0px'
                                                        }}
                                                        onClick={() => {


                                                            this.setState({

                                                                isActiveColorPicker: false
                                                            })
                                                        }
                                                        }
                                                    />
                                                    <ChromePicker
                                                        color={this.state.bgColor}
                                                        onChange={color =>
                                                            this.onChangeColorPicker("headerBackground", color)
                                                        }
                                                    />
                                                </div>
                                            )
                                            }
                                        </ul>
                                    </div>
                                    <div className="next-btn text-left pt-8 pb-5 mob-view">
                                        <button className="btnBlueNext w-200 preview-btn" onClick={() => this.previewSlide()}>Preview</button>
                                        <button className="btnBlueNext w-200" onClick={() => this.nextRegistrationPage()} >Next</button>
                                    </div>
                                </div>
                                <span className="bodress-widgets  ml-5 mr-5" />
                                <div className="widget-phone mob-hide" id="widgetShow">
                                    <a className="close-icon" onClick={() => this.previewSlide()} style={{ background: this.state.bgColor }}>X</a>
                                    <div className="widget-screen k-flex align-items-flex-end">
                                        <div className="screen position-relative">
                                            <div className="screen-header px-5 py-3" style={{ background: this.state.bgColor }} >
                                                <p>
                                                    {this.state.company_name ? this.state.company_name : 'Acme'}
                                                </p>
                                            </div>
                                            <div className="screen-body">
                                                <div className="body-chat">
                                                    <div className="recived k-flex align-items-flex-end pt-3">

                                                        {/* <span className="user">AS</span> */}
                                                        <span className="user" style={{ backgroundColor: this.state.bgColor }} >
                                                            <img alt="" src={require('./../../assets/images/wt_chat_icon/comments.svg')} style={{ width: '18px' }} />
                                                        </span>


                                                        <div className="message-box">
                                                            <div className="message message-line">
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sent k-flex align-items-flex-end justify-content-end pt-3" >
                                                        <div className="message-box">
                                                            <div className="message message-line" style={{ background: this.state.bgColor }} >
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                        <span className="user"><img alt="" src={require('./../../assets/images/avtare-user.svg')} /></span>
                                                    </div>
                                                    <div className="recived k-flex align-items-flex-end pt-3">
                                                        <span className="user" style={{ backgroundColor: this.state.bgColor }} ><img alt="" src={require('./../../assets/images/wt_chat_icon/comments.svg')} style={{ width: '18px' }} /> </span>
                                                        <div className="message-box">
                                                            <div className="message message-line">
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                                        <div className="message-box">
                                                            <div className="message message-line" style={{ background: this.state.bgColor }} >
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                        <span className="user"><img alt="" src={require('./../../assets/images/avtare-user.svg')} /></span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="screen-footer">
                                                <div className="scr-footer-1 k-flex align-items-center py-2 px-3 border-top">
                                                    <input type="text" readOnly placeholder="Type here to chat..." />
                                                    <span className="send-btn" style={{ background: this.state.bgColor }} >
                                                        <img alt="" src={require('./../../assets/images/widget/send2.svg')} />
                                                    </span>
                                                </div>
                                                <div className="powerby">
                                                    <p>
                                                        <span className="powerdBySnapyIcon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                                                <g data-name="Layer 2">
                                                                    <g data-name="Layer 1">
                                                                        <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                                                        <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{ width: '22%', position: 'relative', top: '-2px' }} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets ml-4" style={{ background: this.state.bgColor }} >
                                            <img alt="" src={require(`./../../assets/images/wt_chat_icon/${this.state.widgetImage.wtimage}`)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="footerStepbyStep" style={{ display: 'none' }}>
                            <div className="container">
                                <div className="catfeacture-section-2" style={{ display: 'block' }}>
                                    <h4 className="clicntLogo-heading">Join <strong>7 million +</strong> other businesses:</h4>
                                    <ul>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/accenture.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/assurant.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/decathlon.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/deloitte.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/escorts.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/loreal.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/mitie.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/nike.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/pinnacle.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/sodexo.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/shangri.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/southwest.png')} /></li>
                                        <li><img alt="" src={require('./../../assets/images/footer-logo/thehomedepot.png')} /></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ComapanyWidgetInitialSetup;
