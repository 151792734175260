import React from 'react';
import LeftPanel from './views/LeftPanel';
import AddDepartment from './AddDepartment';
import EditDepartment from "./EditDepartment";
import DeleteDepartment from './DeleteDepartment'
// import db from "../../config/firebase";
import { activityLog } from "../../services/service"

import TextField from '@material-ui/core/TextField';
import firebaseServices from '../../firebase';

class Department extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showAddDepartment:false,
            departments:[],
            searchbox:'',
            showEditDepartment:false,
            departmentId:'',
            ownerId:'',
            loader:true,
            delDepartmentid:'',
            showDeleteDepartment:'',
            departmentEmpty:false,
            depSnapShot:'',
            allAgents:[]
        }
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.value
        })
    }

    changeSearch = (e) => {
        if(this.state.departmentEmpty===false){
            var query=firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments');
            this.setState({
                loader:true
            })
            query.get().then((docs) => {
                var i=0;
                var departments=[];
                var m = 0;
                docs.forEach((doc)=>{
                    if (new RegExp(this.state.searchbox, "gi").test(doc.data().name)) {
                        departments[i]=doc.data();
                        departments[i]['id']=doc.id;
                        let agentCount = this.state.allAgents.filter((agent) => agent.departments.indexOf(doc.id) > -1);
                        departments[i]['agentCount'] = agentCount.length ? agentCount.length : 0
                        i++;
                    }
                });
                this.setState({
                    departments:departments,
                    loader:false
                })
            });
        }
    }

    showDepartment = () => {
        this.setState({
            showAddDepartment:true
        })
    }

    sendStatus = (status) => {
        this.setState({
            showAddDepartment:status
        }, () => {
            this.getDepartmentList(this.state.ownerId);
        })
    }

    sendEditStatus = (status) => {
        this.setState({
            showEditDepartment:status,
            departmentId:''
        }, () => {
            this.getDepartmentList(this.state.ownerId);
        })
    }

    sendDeleteStatus = (status) => {
        this.setState({
            showDeleteDepartment:status,
            delDepartmentid:''
        })
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId,
            loader:true
        })
        this.getDepartmentList(userDetails.ownerId);
        activityLog("Opened department page");
    }

    componentWillUnmount() {
        // this.state.depSnapShot();
    }

    getDepartmentList = (ownerId) => {
        let promise1 = new Promise((res1,rej1) => {
            firebaseServices.db.collection('users').doc(ownerId).collection('departments').orderBy('addedon').get().then(async (docs) => {
                let roles = [];
                let i = 0;
                if(!docs.empty) {
                    docs.forEach((doc) => {
                        roles[i] = doc.data();
                        roles[i]['id'] = doc.id;
                        i++;
                    })
                }
                res1(roles);
            }).catch((err) => {
                console.log('error at line 132', err);
                res1([]);
            });
        })

        let promise2 = new Promise((res2,rej2) => {
            firebaseServices.db.collection('users').doc(ownerId).collection('agents').where('deletetime','==',0).get().then((data) => {
                let agents = [];
                let k=0;
                if(!data.empty) {
                    data.forEach((agent) => {
                        agents[k] = agent.data();
                        agents[k]['id'] = agent.id;
                        k++;
                    })
                }
                res2(agents);
            }).catch((err) => {
                res2([]);
            })
        })

        Promise.all([promise1,promise2]).then((results) => {
            const departments = results[0];
            const agents = results[1];
            let allDepartments = departments.map((department) => {
                let agentCount = agents.filter((agent) => agent.departments.indexOf(department.id)> -1 );
                department.agentCount = agentCount.length;
                return department;
            })
            this.setState({
                departments:allDepartments,
                loader:false,
                allAgents: agents,
                departmentEmpty:false,
            })
        })

        return false;


        this.state.depSnapShot = firebaseServices.db.collection('users').doc(ownerId).collection('departments').orderBy('addedon').onSnapshot(async (docs) => {
            //var i=0;
            var departments=[];
            if(docs.empty===false) {
                for (const index2 in docs.docs) {
                    const doc = docs.docs[index2];
                    const res = await this.getCountAgentsInDept(ownerId, doc.id);
                    let docData = doc.data();
                    docData.id = doc.id;
                    docData.agentCount = res;
                    departments.push(docData);
                    /* new Promise((res,rej) => {
                        firebaseServices.db.collection('users').doc(ownerId).collection('agents').where("deletetime","==",0).where("departments","array-contains",doc.id).get().then((data) => {
                            let count=0;
                            data.forEach((dat) => {
                                count++;
                            })
                            res(count);
                        })
                    }).then((res) => {
                        departments[i]=doc.data();
                        departments[i]['id']=doc.id;
                        departments[i]['agentCount']=res;
                        i++;
                        this.setState({
                            departments:departments,
                            loader:false,
                            departmentEmpty:false,
                        })
                    }); */
                }
                this.setState({
                    departments:departments,
                    loader:false,
                    departmentEmpty:false,
                })
            } else {
                this.setState({
                    loader:false,
                    departmentEmpty:true,
                    departments:departments
                })
            }
        })
    }

    getCountAgentsInDept = (ownerId, deptId) => {
        return new Promise((res,rej) => {
            firebaseServices.db.collection('users').doc(ownerId).collection('agents').where("deletetime","==",0).where("departments","array-contains",deptId).get().then((data) => {
                res(data.size);
            });
        });
    }

    deleteData = (depId,depName) => {
        this.setState({
            delDepartmentid:depId,
            showDeleteDepartment:true
        })
    }

    editData = (depId) => {
        this.setState({
            departmentId:depId,
            showEditDepartment:true
        })
    }

    searchInput = (e) => {
        if(e.which===13) {
            this.changeSearch();
        }
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        var departmentEmpty;
        if(this.state.departmentEmpty===true || this.state.departments.length === 0) {
            departmentEmpty=<tr><td colSpan="4"><p className="small text-screen1">No Team is present in your account</p></td></tr>
        }
        var departmentList = this.state.departments.map((value, index) => {
            return <tr key={index}>
            <td><p className="small text-screen1">{value.name}</p></td>
            <td><p className="small text-screen1 word-break-all">{value.description}</p></td>
            <td><p className="small text-screen1">{value.agentCount}</p></td>
            <td>
                {value.name!=='All agents' ? (<div className="nav-item list-inline-item d-xl-block">
                    <span className="text-muted px-2" style={{cursor:'pointer'}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img alt="" src={ require('../../assets/img/icon/table-more-vertical.svg') } />
                    </span>
                    <div className="dropdown-menu dropdown-menu-right">
                        <span className="dropdown-item d-flex align-items-center edit-agent-clik" style={{cursor:'pointer'}} onClick={() =>this.editData(value.id)} >
                            Edit
                        </span>
                        <span className="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}} onClick={() => this.deleteData(value.id,value.name)}>
                            Delete
                        </span>
                    </div>
                </div>) : (null)}
            </td>
        </tr> 
        })
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">User Management</h3>
                <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Teams</h3>
                    </div>
                    <div className="body-section bg-white px-5 py-5 mobile-tranparent">
                        <div className="agent-section" id="resposnive-table-class">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table-header row mb-4 user-table-main-header mobShow">
                                        <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                            <TextField className="form-control form-control-lg" id="outlined-basic" label="Search" variant="outlined" name="searchbox" value={this.state.searchbox} onChange={this.handleChange} onKeyPress={this.searchInput} />
                                            {/* <input type="text" className="form-control form-control-lg" placeholder="Search" name="searchbox" onKeyPress={this.searchInput} onChange={this.handleChange} aria-label="Search for messages or users..." /> */}
                                            <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" onClick={this.changeSearch} type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="fltr-view">
                                            <div className="aside-agent k-flex align-items-center justify-content-end">
                                                <div className="aside-agent department-btn usr-mm-btn">
                                                    <button className="btnBlueSettings add-agent-clik" onClick={this.showDepartment}>+ <span>Add Team</span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table mobile-agent-table mobile-tranparent">
                                        <table className="table table-borderless mb-0 departmentUsertable mob-tbl-view liveCgatUserTable agentslistTbl ">
                                            <thead className="bg-darksecound white-text table-head-border-radius border-radius-table ">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Agents</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="outline-1-black border-radius-table">
                                                {departmentEmpty}
                                                {departmentList}
                                            </tbody>
                                        </table>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {this.state.showAddDepartment && <AddDepartment sendDepartmentStatus={this.state.showAddDepartment} getDepartmentStatus={this.sendStatus} />}
            {this.state.showEditDepartment && <EditDepartment departmentId={this.state.departmentId} sendDepartmentStatus={this.state.showEditDepartment} getEditDepartmentStatus={this.sendEditStatus} />}
            <DeleteDepartment departmentId={this.state.delDepartmentid} sendDepartmentStatus={this.state.showDeleteDepartment} getDeleteDepartmentStatus={this.sendDeleteStatus} />
            </>
        )
    }
}

export default Department;