import React from 'react';
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import { GATEWAY_URL,FUNCTION_API_KEY } from "../../config/siteConfig";
import { activityLog } from "../../services/service";
import firebaseServices from '../../firebase';

class deleteDepartment extends React.Component {

    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {}
        this.state = {
            loader: false,
            ownerId: '',
            otherDepartments: [],
            showDeleteDepartment: false,
            departmentList: [],
            depName: '',
            departmentId: '',
            ownerEmail: '',
            company_name: '',
            departmentName: '',
            departmentDescription: ''
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId: userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).get().then((owners) => {
            if (owners.exists) {
                this.setState({
                    ownerEmail: owners.data().email,
                    company_name: owners.data().company_name
                })
            }
        })
    }

    componentDidUpdate(prev_props) {
        if (this.props.departmentId !== this.state.departmentId) {
            this.setState({
                departmentId: this.props.departmentId
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').orderBy('addedon').get().then((docs) => {
                let data = [];
                let i = 0;
                docs.forEach((doc) => {
                    if (doc.id !== this.props.departmentId) {
                        data[i] = doc.data();
                        data[i]['id'] = doc.id;
                        i++;
                    } else {
                        this.setState({
                            depName: doc.data().name,
                            departmentDescription: doc.data().description
                        })
                    }
                })
                this.setState({
                    otherDepartments: data
                })
            })

            activityLog("Opened delete departent page");
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showDeleteDepartment !== props.sendDepartmentStatus) {
            return {
                showDeleteDepartment: props.sendDepartmentStatus
            }
        }
        return null
    }

    handleChange = (e) => {
        let target = e.target;
        let values = [].filter.call(target.options, o => o.selected).map(o => o.value);
        this.setState({
            [target.name]: values
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            loader: true
        })
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").where("departments", "array-contains", this.props.departmentId).get().then((docs) => {
            docs.forEach((doc) => {
                let data = doc.data();
                let index = data.departments.indexOf(this.props.departmentId);
                data.departments.splice(index, 1);
                let newarray = [...new Set([...data.departments, ...this.state.departmentList])];
                if (newarray.length === 0) {
                    newarray.push(this.state.otherDepartments[0].id);
                }
                firebaseServices.db.collection("users").doc(this.state.ownerId).collection("agents").doc(doc.id).update({ departments: newarray }).then((ref) => {

                }).catch((error) => {
                    console.log(error);
                })
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').doc(this.props.departmentId).delete().then((ref) => {
                this.hideDeleteDepartment();
                this.sendEmail();
                this.setState({
                    loader: false
                })
                toast.success("Team has been deleted successfully!");
                activityLog("Deleted team");
            }).catch((err)=> {
                console.log(err);
            })
        })
    }

    hideDeleteDepartment = (e) => {
        this.setState({
            showDeleteDepartment: false,
            otherDepartments: []
        })
        this.props.getDeleteDepartmentStatus(false);
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideDeleteDepartment();
    }

    sendEmail = () => {
        let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : this.state.ownerEmail;
        var body = [
            `email=${ownerEmail.toLowerCase()}&&name=${this.state.depName}&&type=department_deleted&&description=${this.state.departmentDescription}&&company_name=${this.state.company_name}`
        ];
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }


    render() {
        let departmentList = this.state.otherDepartments.map((value, index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        })
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <div className="rightPanelSection add-agent" style={{ display: this.state.showDeleteDepartment ? 'block' : 'none' }}>
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="right-panel-header k-flex align-items-center justify-content-center">
                                <h4 className="header-title heding-text text-truncate mr-auto white-text">Confirm</h4>
                                <ul className="heder-icon">
                                    <li className="nav-item list-inline-item closeing-r">
                                        <span className="heder-icon-img" onClick={this.hideDeleteDepartment}>
                                            <img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} />
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="bodyRightPanel">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-feilds px-5 py-4">
                                            <h3>Do you wish to delete the Team</h3>
                                            <p>Note: Agents are added to this team, So select one or multiple teams to which you wish to add the agents to.</p>
                                            <div className="wrapperInfo">
                                                <label className="mb-1 small text-screen1">Select Agent</label>
                                                <select className="customArrow" name="departmentList" value={this.state.departmentList} onChange={this.handleChange} multiple>
                                                    {departmentList}
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-feilds px-5 py-4">
                                            <div className="wrapperInfo">
                                                <button className="mr-2 btnBlue">Delete</button>
                                                <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default deleteDepartment;