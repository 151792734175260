import React from "react";
// import db from "../../config/firebase";
import { getAgentFromId, getOwnerDetails, ownerLogoutCommon, getOwnerAfterLogin } from "../../services/service";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig";
import firebaseServices from "../../firebase";
const moment = require("moment-timezone");

class DeactivatedNavigation extends React.Component {
  constructor(props) {
    super(props);
    this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    this.state = {
      profile_display: false,
      logout_status: false,
      currentUrl: '',
      showEditProfile: false,
      agentProfilePic: '',
      agentName: '',
      agentEmail: '',
      agentId: '',
      ownerId: '',
      agentStatus: '',
      roles: [],
      OtherRoles: [],
      role: '',
      userManagementPage: 'agents',
      modalVisible: false,
      logoutModel: false,
      loader: false
    }
  }


  showProfile = () => {
    let newStatus = this.state.profile_display === false ? true : false;
    this.setState({
      profile_display: newStatus,
      showEditProfile: false
    })
  }

  componentDidUpdate() {
    if (this.state.currentUrl !== window.location.pathname) {
      this.setState({
        profile_display: false,
        currentUrl: window.location.pathname,
        showEditProfile: false
      })
    }
  }

  componentDidMount() {
    let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    if (userDetails) {
      this.setState({
        agentId: userDetails.agentId,
        ownerId: userDetails.ownerId
      }, () => {
        console.log(this.state.agentId, this.state.ownerId);
        this.getAgentProfile();
      })
    }
  }

  getAgentProfile = () => {
    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).get().then((docs) => {
      if (docs.exists) {
        let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        let data = docs.data();
        if (data.roleType === 'Owner') {
          getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire).then((results) => {
            // getOwnerAfterLogin().then((results) => {
            if (results.status === 200) {
              let profile_img = '';
              if (results.data.thumbnail && results.data.thumbnail !== '' && results.data.thumbnail !== "notexist" && results.data.thumbnail !== 'NOT_EXIST') {
                let currentTime = new Date().getTime();
                profile_img = results.data.imageurl + '/' + results.data.thumbnail + '?v=' + currentTime
              }
              this.setState({
                agentEmail: results.data.email,
                agentName: results.data.first_name + ' ' + (results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : ''),
                agentProfilePic: profile_img,
              })
            } else if (userDetails) {
              this.setState({
                agentEmail: userDetails.email,
                agentName: userDetails.first_name + ' ' + (userDetails.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetails.last_name : ''),
                agentProfilePic: userDetails.profile_img,
              })
            } else {
              this.setState({
                agentEmail: data.email,
                agentName: data.name,
                agentProfilePic: data.profile_img,
              })
            }
          })
        } else {
          getAgentFromId(this.state.agentId).then((results) => {
            if (results.status === 200) {
              let profile_img = '';
              if (results.data.pic && results.data.pic !== '' && results.data.pic !== "notexist" && results.data.pic !== 'NOT_EXIST') {
                profile_img = results.data.imageurl + '/' + results.data.pic
              }
              this.setState({
                agentEmail: results.data.email,
                agentName: results.data.first_name + ' ' + (results.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? results.data.last_name : ''),
                agentProfilePic: profile_img,
              })
            } else if (userDetails) {
              this.setState({
                agentEmail: userDetails.email,
                agentName: userDetails.first_name + ' ' + (userDetails.last_name ? userDetails.last_name : ''),
                agentProfilePic: userDetails.profile_img,
              })
            } else {
              this.setState({
                agentEmail: data.email,
                agentName: data.name,
                agentProfilePic: data.profile_img,
              })
            }
          })
        }
        this.setState({
          agentStatus: data.online_status
        })
      }
    })
  }

  hideEditProfile = (status) => {
    try {
      document.getElementById("shadowMasking").style.display = "none";
    } catch (err) { }

    this.setState({
      showEditProfile: false
    })
  }


  hideProfileScreen = () => {
    try {
      document.getElementById("shadowMasking").style.display = "none";
    } catch (err) { }
    this.setState({
      profile_display: false,
      showEditProfile: false
    })
  }

  /**
   * for logout common login
   * api call unset session data for all the product
   * added by Ruchi Kapil
   * */
  logoutAppypieProduct = async () => {
    this.setState({
      loader: true
    })
    await ownerLogoutCommon();
    // var dataReturn = await logoutCommonLogin(localStorage.getItem('useragent_id'));
  }

  /**
  *  For hide show logout option 
  *  In case of Owner
  * */
  showHideDiv = () => {
    var loginStatus = true;
    if (this.state.logout_status === true) {
      loginStatus = false;
    }
    this.setState({
      logout_status: loginStatus
    });

  }

  /* For logout confirmation start */

  confirmLogout = () => this.setState({
    modalVisible: true
  });
  confirmAllLogout = () => this.setState({
    logoutModel: true
  });


  closeModal = () => this.setState({
    modalVisible: false,
    logoutModel: false
  });

  logOutMe = () => {
    if (this.agent.roleType === 'Owner') {
      localStorage.setItem('logoutowner', true);
    }
    window.location.href = "/" + this.agent.ownerIdentifire + "/logout";
    return false;
  }

  replaceImageWithText(e) {
    e.target.onError = null;
    e.target.outerHTML = (this.agent.agent_name.trim().split(" ")[0][0] + (this.agent.agent_name.trim().split(" ")[1] ? this.agent.agent_name.trim().split(" ")[1][0] : this.agent.agent_name.trim().split(" ")[0][1])).toUpperCase();
  }

  render() {
    let userManagement = '';
    let chatHistory = '';
    let settings = '';
    let updateStatus = '';
    let myProfile = '';
    let logoutItem = '';
    /* added by ruchi */
    if (this.agent.roleType === 'Owner') {
      logoutItem = <li className="nav-item px-6 pt-5 cursor-pointer position-relative" onClick={this.showHideDiv}>
        <span className="nav-link position-relative p-0 py-4 mob-none"></span>
        <span><img src={require('../../assets/images/left-sidebar/logoutShoutDown.svg')} /></span>
        <div className="logout-class deactivate-nav" id="logout-class" style={{ 'display': this.state.logout_status === true ? 'block' : 'none' }}> <ul>
          <span className="profile-crose" onClick={this.hideProfileScreen}><img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} /></span>
          <li><span style={{ cursor: "pointer" }} onClick={this.confirmAllLogout} title="Logout">Logout</span></li>
          {/* <li><span style={{cursor:"pointer"}} onClick={this.confirmLogout} className={window.location.pathname === '/logout' ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Logout" >Logout from Appy Pie Livechat</span></li>
            <li><span style={{cursor:"pointer"}}  onClick={this.confirmAllLogout}    title="Logout">Logout from all Appy Pie products</span></li> */}
        </ul></div>
      </li>;
    }
    else {
      logoutItem = <li className="nav-item ">
        <span style={{ cursor: "pointer" }} onClick={this.confirmLogout} className={window.location.pathname === '/logout' ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Logout">
          <span><img src={require('../../assets/images/left-sidebar/logoutShoutDown.svg')} /></span>
        </span>

      </li>;
    }
    /* For logout confirmation end */


    if (this.state.roles.indexOf('Users Management') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      let activeClass = 'nav-link position-relative p-0 py-xl-4';
      if (window.location.pathname.split('/')[2] === 'roles') {
        activeClass = activeClass + " activeNavigation";
      }
      let allUrls = ["/" + this.agent.ownerIdentifire + "/agents", "/" + this.agent.ownerIdentifire + "/departments", "/" + this.agent.ownerIdentifire + "/roles", "/" + this.agent.ownerIdentifire + "/add-roles"];
      if (allUrls.indexOf(window.location.pathname) >= 0) {
        activeClass = activeClass + " activeNavigation";
      }
      userManagement = <li className="nav-item ">
        <a className={activeClass} title="Agents" role="tab" href={"/" + this.agent.ownerIdentifire + "/" + this.state.userManagementPage}>
          {/* <Link className={activeClass} to={"/"+this.agent.ownerIdentifire+"/agents"} title="Agents"> */}
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.734" height="25.246" viewBox="0 0 29.734 25.246">
              <g id="Group_2627" data-name="Group 2627" transform="translate(0.5 0.553)">
                <path id="Path_1747" data-name="Path 1747" d="M17.338,10.5a5.221,5.221,0,0,1,2.43,3.879,4.212,4.212,0,1,0-2.43-3.879Zm-2.761,8.628a4.214,4.214,0,1,0-4.215-4.214,4.214,4.214,0,0,0,4.215,4.214Zm1.787.287H12.79a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.068.3.094a24.559,24.559,0,0,0,7.336,1.183,15.06,15.06,0,0,0,6.4-1.2l.281-.142h.03V24.809a5.4,5.4,0,0,0-5.388-5.4Zm6.972-4.35H19.789a5.193,5.193,0,0,1-1.6,3.617,6.411,6.411,0,0,1,4.579,6.135v1.347a14.519,14.519,0,0,0,5.655-1.188l.281-.143h.03V20.458a5.4,5.4,0,0,0-5.4-5.4ZM7.184,14.776a4.187,4.187,0,0,0,2.242-.65A5.215,5.215,0,0,1,11.385,10.8c0-.079.012-.157.012-.237a4.213,4.213,0,1,0-4.213,4.213Zm3.784,3.9a5.2,5.2,0,0,1-1.6-3.6c-.132-.01-.262-.02-.4-.02H5.4a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.067.3.1A25.3,25.3,0,0,0,6.387,26.14V24.815a6.412,6.412,0,0,1,4.581-6.135Z" transform="translate(0 -6.348)" fill="none" stroke="#fff" strokeWidth="1" />
              </g>
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.734" height="24.191" viewBox="0 0 28.734 24.191">
              <path id="Path_1747" data-name="Path 1747" d="M17.338,10.5a5.221,5.221,0,0,1,2.43,3.879,4.212,4.212,0,1,0-2.43-3.879Zm-2.761,8.628a4.214,4.214,0,1,0-4.215-4.214,4.214,4.214,0,0,0,4.215,4.214Zm1.787.287H12.79a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.068.3.094a24.559,24.559,0,0,0,7.336,1.183,15.06,15.06,0,0,0,6.4-1.2l.281-.142h.03V24.809a5.4,5.4,0,0,0-5.388-5.4Zm6.972-4.35H19.789a5.193,5.193,0,0,1-1.6,3.617,6.411,6.411,0,0,1,4.579,6.135v1.347a14.519,14.519,0,0,0,5.655-1.188l.281-.143h.03V20.458a5.4,5.4,0,0,0-5.4-5.4ZM7.184,14.776a4.187,4.187,0,0,0,2.242-.65A5.215,5.215,0,0,1,11.385,10.8c0-.079.012-.157.012-.237a4.213,4.213,0,1,0-4.213,4.213Zm3.784,3.9a5.2,5.2,0,0,1-1.6-3.6c-.132-.01-.262-.02-.4-.02H5.4a5.4,5.4,0,0,0-5.4,5.4v4.373l.011.067.3.1A25.3,25.3,0,0,0,6.387,26.14V24.815a6.412,6.412,0,0,1,4.581-6.135Z" transform="translate(0 -6.35)" fill="#fff" />
            </svg>
          </span>
          {/* </Link> */}
        </a>
      </li>
    }
    if (this.state.OtherRoles.indexOf('Conversation history') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      chatHistory = <li className="nav-item ">
        <a className={window.location.pathname === "/" + this.agent.ownerIdentifire + "/chathistory" ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Conversation history" role="tab" href={"/" + this.agent.ownerIdentifire + "/chathistory"}>
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.725" height="28.719" viewBox="0 0 28.725 28.719">
              <g id="clock" transform="translate(-0.007 -0.01)">
                <path id="Path_2196" data-name="Path 2196" d="M25.185,14.426h-.2A12.558,12.558,0,1,0,14.426,24.987v2.9a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.546-3.546v-5.41A3.551,3.551,0,0,0,25.185,14.426ZM12.563,23.442a10.872,10.872,0,1,1,10.719-9.016h-5.31a3.545,3.545,0,0,0-3.546,3.546v5.31a10.966,10.966,0,0,1-1.863.16Zm14.485-.06a1.865,1.865,0,0,1-1.863,1.863H17.972a.841.841,0,0,0-.467.141l-1.4.931V17.972a1.862,1.862,0,0,1,1.863-1.863h7.213a1.865,1.865,0,0,1,1.863,1.863Zm0,0" fill="#fff" />
                <path id="Path_2197" data-name="Path 2197" d="M327.583,321.332h-5.41a.842.842,0,0,0,0,1.684h5.41a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -303.3)" fill="#fff" />
                <path id="Path_2198" data-name="Path 2198" d="M324.879,385.6h-2.7a.842.842,0,1,0,0,1.684h2.7a.842.842,0,1,0,0-1.684Zm0,0" transform="translate(-303.3 -363.963)" fill="#fff" />
                <path id="Path_2199" data-name="Path 2199" d="M118.72,64.266a.842.842,0,0,0-.842.842V72.38H113.31a.842.842,0,1,0,0,1.683h5.41a.842.842,0,0,0,.842-.842V65.107a.842.842,0,0,0-.842-.841Zm0,0" transform="translate(-106.157 -60.659)" fill="#fff" />
              </g>
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.736" height="28.732" viewBox="0 0 28.736 28.732">
              <g id="clock_1_" data-name="clock (1)" transform="translate(0.003 0.002)">
                <path id="Path_2204" data-name="Path 2204" d="M25.121,12.742v-.18A12.562,12.562,0,1,0,12.56,25.123h.182V17.972a5.236,5.236,0,0,1,5.23-5.23ZM13.4,12.563a.842.842,0,0,1-.842.842h-5.4a.842.842,0,1,1,0-1.683h4.568V4.448a.842.842,0,1,1,1.683,0Zm0,0" fill="#fff" />
                <path id="Path_2205" data-name="Path 2205" d="M267.826,257.066h-7.213a3.544,3.544,0,0,0-3.546,3.546v9.918a.842.842,0,0,0,1.309.7l2.493-1.662h6.958a3.551,3.551,0,0,0,3.547-3.547v-5.41a3.551,3.551,0,0,0-3.548-3.545Zm-4.448,8.9h-1.863a.842.842,0,0,1,0-1.684h1.863a.842.842,0,1,1,0,1.684Zm3.547-3.607h-5.41a.842.842,0,0,1,0-1.683h5.41a.842.842,0,1,1,0,1.683Zm0,0" transform="translate(-242.641 -242.641)" fill="#fff" />
              </g>
            </svg>
          </span>
        </a>
      </li>
    }

    if (this.state.OtherRoles.indexOf('Settings') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      let activeClass = 'nav-link position-relative p-0 py-xl-4';
      let allUrls = ["/" + this.agent.ownerIdentifire + "/idle-time", "/" + this.agent.ownerIdentifire + "/install-widget", "/" + this.agent.ownerIdentifire + "/chatsettingform", "/" + this.agent.ownerIdentifire + "/widgetsetting", "/" + this.agent.ownerIdentifire + "/gdpr-consent", "/" + this.agent.ownerIdentifire + "/notifications", "/" + 
      // this.agent.ownerIdentifire + "/previous-chat", "/" + 
      this.agent.ownerIdentifire + "/subscriptions"];
      if (allUrls.indexOf(window.location.pathname) >= 0) {
        activeClass = activeClass + " activeNavigation";
      }
      settings = <li className="nav-item ">
        <a className={activeClass} title="Settings" role="tab" href={"/" + this.agent.ownerIdentifire + "/chatsettingform"}>
          {/* <Link className={activeClass} to={"/"+this.agent.ownerIdentifire+"/idle-time"} title="Settings"> */}
          <span className="before-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29.002" viewBox="0 0 29.002 29.002">
              <path id="Union_1" data-name="Union 1" d="M12.161,29a.468.468,0,0,1-.467-.468v-2.2A12.045,12.045,0,0,1,8.117,24.85L6.562,26.407a.481.481,0,0,1-.661,0L2.593,23.1a.468.468,0,0,1,0-.662L4.15,20.883a12.049,12.049,0,0,1-1.484-3.577H.468A.468.468,0,0,1,0,16.839V12.161a.468.468,0,0,1,.468-.467h2.2a12.045,12.045,0,0,1,1.48-3.577L2.593,6.562a.467.467,0,0,1,0-.661L5.9,2.593a.467.467,0,0,1,.661,0L8.118,4.15a12.016,12.016,0,0,1,3.576-1.484V.468A.468.468,0,0,1,12.161,0h4.678a.468.468,0,0,1,.467.468v2.2A12.029,12.029,0,0,1,20.882,4.15L22.437,2.6a.468.468,0,0,1,.662,0L26.4,5.9a.467.467,0,0,1,0,.661L24.85,8.117a12.049,12.049,0,0,1,1.484,3.577h2.2a.468.468,0,0,1,.468.467v4.678a.468.468,0,0,1-.468.467h-2.2a12.046,12.046,0,0,1-1.482,3.575l1.555,1.555a.467.467,0,0,1,0,.661L23.1,26.4a.468.468,0,0,1-.662,0l-1.555-1.55a12.049,12.049,0,0,1-3.577,1.484v2.2a.468.468,0,0,1-.467.468ZM8.312,23.864A11.108,11.108,0,0,0,12.255,25.5a.468.468,0,0,1,.374.459v2.1h3.742v-2.1a.468.468,0,0,1,.374-.459,11.108,11.108,0,0,0,3.943-1.637.468.468,0,0,1,.59.059l1.491,1.491,2.646-2.646-1.491-1.491a.468.468,0,0,1-.059-.59A11.108,11.108,0,0,0,25.5,16.745a.468.468,0,0,1,.459-.374h2.1V12.629h-2.1a.468.468,0,0,1-.459-.374,11.108,11.108,0,0,0-1.637-3.943.468.468,0,0,1,.059-.59l1.491-1.491L22.768,3.586,21.277,5.077a.469.469,0,0,1-.59.059A11.106,11.106,0,0,0,16.745,3.5a.468.468,0,0,1-.374-.459V.935H12.629v2.1a.468.468,0,0,1-.375.458A11.106,11.106,0,0,0,8.312,5.135a.468.468,0,0,1-.59-.059L6.231,3.585,3.585,6.231,5.077,7.722a.469.469,0,0,1,.059.59A11.108,11.108,0,0,0,3.5,12.255a.468.468,0,0,1-.459.374H.935v3.742h2.1a.468.468,0,0,1,.458.375A11.108,11.108,0,0,0,5.13,20.689a.468.468,0,0,1-.059.589L3.585,22.769l2.646,2.645,1.491-1.491A.467.467,0,0,1,8.312,23.864ZM7.966,14.5A6.534,6.534,0,1,1,14.5,21.034,6.534,6.534,0,0,1,7.966,14.5Zm1.188,0A5.346,5.346,0,1,0,14.5,9.154,5.346,5.346,0,0,0,9.154,14.5Z" fill="#fff" />
            </svg>
          </span>
          <span className="after-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="29.002" height="29" viewBox="0 0 29.002 29">
              <path id="Union_2" data-name="Union 2" d="M16.838,29H12.162a.469.469,0,0,1-.468-.468v-2.2A12.029,12.029,0,0,1,8.118,24.85L6.563,26.408a.482.482,0,0,1-.662,0L2.593,23.1a.469.469,0,0,1,0-.662L4.15,20.883a12.076,12.076,0,0,1-1.484-3.577H.468A.469.469,0,0,1,0,16.838V12.163a.469.469,0,0,1,.468-.468h2.2a11.987,11.987,0,0,1,1.48-3.577L2.593,6.563a.466.466,0,0,1-.137-.333.46.46,0,0,1,.137-.33L5.9,2.593a.467.467,0,0,1,.662,0L8.118,4.15a12.048,12.048,0,0,1,3.576-1.484V.468A.469.469,0,0,1,12.162,0h4.676a.469.469,0,0,1,.468.468v2.2A12,12,0,0,1,20.883,4.15L22.438,2.6a.467.467,0,0,1,.662,0l3.3,3.3a.46.46,0,0,1,.137.329.466.466,0,0,1-.137.333L24.85,8.118a12.022,12.022,0,0,1,1.484,3.577h2.2a.469.469,0,0,1,.468.468v4.676a.469.469,0,0,1-.468.468h-2.2a12.027,12.027,0,0,1-1.482,3.574l1.555,1.555a.469.469,0,0,1,0,.662L23.1,26.406a.467.467,0,0,1-.662,0l-1.555-1.555a12.064,12.064,0,0,1-3.577,1.484v2.2a.469.469,0,0,1-.468.465ZM14.5,7.965A6.534,6.534,0,1,0,21.033,14.5,6.534,6.534,0,0,0,14.5,7.965Z" fill="#fff" />
            </svg>
          </span>
          {/* </Link> */}
        </a>
      </li>
    }

    if (this.state.OtherRoles.indexOf('Change Status') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      updateStatus = <div className="profile-status py-3 px-4 k-flex align-items-center border-bottom">
        <p className="card-body-text">Status</p>
        <div className="wrapperInfo ml-auto" style={{ 'width': '150px' }}>
          <select name="agentStatus" className="customArrow" value={this.state.agentStatus} onChange={this.updateStatus}>
            <option value="1">Online</option>
            <option value="2">Offline</option>
          </select>
        </div>
      </div>
    }

    let onlineImg = '';
    if (this.state.agentStatus === 1) {
      onlineImg = 'online.png';
    } else {
      onlineImg = 'offline.png';
    }

    if (this.state.OtherRoles.indexOf('Manage Own Profile') > -1 || this.state.role === 'Admin' || this.state.role === 'Owner') {
      myProfile = <div className="my-profile-list py-3 px-4 text-left" onClick={this.showEditProfile}>
        <p className="card-body-text cursor-pointer">My Profile</p>
      </div>
    }

    let layoutClass = 'navigation navbar navbar-light justify-content-center py-7 navigation-not-liveChat ';
    let isLiveChatScreen = false;
    var organization_alias = this.agent.ownerIdentifire ? this.agent.ownerIdentifire : this.agent.organization_alias;
    organization_alias = organization_alias ? organization_alias.trim() : "";

    if (window.location.pathname === '/' + organization_alias) {
      isLiveChatScreen = true;
      layoutClass = 'navigation navbar navbar-light justify-content-center py-xl-7 navigation-visible-overflow';
    }

    let profile_img = '';
    if (this.state.agentProfilePic !== '' && this.state.agentProfilePic !== undefined) {
      profile_img = <img src={this.state.agentProfilePic} className="avatar-img" alt="" onError={(e) => this.replaceImageWithText(e)} />;
    } else {
      let names = this.state.agentName.trim().split(" ");
      if (names.length > 1) {
        profile_img = (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase();
      } else {
        profile_img = (names[0].substring(0, 2)).toUpperCase();
      }
    }
    return (
      <>
        {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}
        <div className={layoutClass}>
          <span className="left-menu-title-logo mb-6 cursorPointer"><img alt="" src={require('../../assets/images/avatars/LC.svg')} /></span>
          <ul className="nav navbar-nav flex-xl-column flex-grow-1 justify-content-center py-3 py-lg-0" role="tablist">



            <li style={{ display: "none" }} className="nav-item d-none d-xl-block flex-xl-grow-1">
              {/* <a className="nav-link position-relative p-0 py-xl-4" data-toggle="tab" style={{cursor:'pointer'}}
              title="Demos" role="tab">
              <span><img alt="" src={require('../../assets/images/left-sidebar/2.svg')} /></span>
            </a> */}
            </li>

            <li className="nav-item position-relative profile-icon">
              <a href="#" className={this.state.profile_display === true ? "nav-link position-relative p-0 py-xl-4 active" : "nav-link position-relative p-0 py-xl-4 "} style={{ cursor: 'pointer' }} data-toggle="tab" role="tab" onClick={this.showProfile}>
                <span className="user-profile-nav" id={this.state.agentProfilePic}>{profile_img}</span>
                <img alt="" src={(require(`../../assets/img/icon/${onlineImg}`))} style={{ position: 'absolute', minWidth: 10, minHeight: 10, width: 10, height: 10, bottom: 2, right: 15 }} />
              </a>
              <div className="profile-nav" style={{ 'display': this.state.profile_display === true ? 'block' : 'none' }}>
                <span className="profile-crose" onClick={this.hideProfileScreen}><img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} /></span>
                <div className="my-profile-section-rightP py-3 px-4 border-bottom">
                  <div className="media text-center text-xl-left profile-click">
                    <div className="avatar avatar-sm d-none d-xl-inline-block mr-4"><span>{profile_img}</span><img alt="" src={(require(`../../assets/img/icon/${onlineImg}`))} style={{ position: 'relative', minWidth: 10, minHeight: 10, width: 10, height: 10, top: -8, left: 27 }} /></div>
                    <div className="media-body align-self-center text-truncate text-left">
                      <h5 className="heding-text text-truncate mb-n3 text-truncate">{this.state.agentName}</h5>
                      <small className="text-muted text-truncate">{this.state.agentEmail}</small>
                      {this.agent.phone_number ? (<><br /><span><svg xmlns="http://www.w3.org/2000/svg" width="15.099" height="15.2" viewBox="0 0 48.099 48.2">
                        <g id="phone-call" transform="translate(-0.539)">
                          <g id="Group_4584" data-name="Group 4584" transform="translate(0.539 0)">
                            <path id="Path_2553" data-name="Path 2553" d="M47.376,35.374,40.65,28.647A4.474,4.474,0,0,0,33.2,30.329a4.578,4.578,0,0,1-5.285,2.883c-4.8-1.2-11.291-7.447-12.492-12.492a4.354,4.354,0,0,1,2.883-5.285A4.474,4.474,0,0,0,19.99,7.988L13.264,1.261a4.8,4.8,0,0,0-6.486,0L2.213,5.826c-4.564,4.8.48,17.537,11.771,28.828S38.007,51.229,42.812,46.424l4.564-4.564A4.8,4.8,0,0,0,47.376,35.374Z" transform="translate(-0.539 0)" fill="#aab0b7" />
                          </g>
                        </g>
                      </svg></span><small className="text-muted text-truncate ml-2">{this.agent.phone_number}</small></>) : (null)}
                    </div>
                  </div>
                </div>
                {updateStatus}
                {myProfile}
              </div>
            </li>


            {settings}
            {logoutItem}
            { /*<li className="nav-item ">
           
            <span style={{cursor:"pointer"}} onClick={this.confirmLogout} className={window.location.pathname === '/logout' ? "nav-link position-relative p-0 py-xl-4 activeNavigation" : "nav-link position-relative p-0 py-xl-4"} title="Logout">
              <span><img src={require('../../assets/images/left-sidebar/logoutShoutDown.svg')}  /></span>
            </span>

          </li> */ }      </ul>
        </div>

        {/* For logout confirmation start */}
        <div className="popupShadow" style={{ display: this.state.modalVisible ? 'block' : 'none' }}></div>
        <div className="popupInstallLivechat popupLivechat" style={{ display: this.state.modalVisible ? 'block' : 'none' }}>
          <div className="popupHeader py-4 px-5 k-flex align-items-center">
            <h6 className="card-title mb-0 white-text">Confirm</h6>
            <span className="close-popup ml-auto" onClick={this.closeModal}>
              <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                  <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                  <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                </g>
              </svg>
            </span>
          </div>

          <div className="popupBody pb-5 px-5 text-center">
            <div className="py-5 feilds-instruction">
              <h6 className="card-title mb-0">Seems like you're trying to logout. Do you wish to continue?</h6>
              <h6 className="card-title mb-0">Note: Once you logout all your inactive chats will be removed.</h6>
            </div>
            <div>
              <button className="btnBlue  py-2 px-7" onClick={this.logOutMe}>Yes</button>&nbsp;
              <button className="btn  py-2 px-7" onClick={this.closeModal}>No</button>
            </div>
          </div>
        </div>
        {/* For logout confirmation end */}

        {/* For logout confirmation for all product logout start */}
        <div className="popupShadow" style={{ display: this.state.logoutModel ? 'block' : 'none' }}></div>
        <div className="popupInstallLivechat popupLivechat" style={{ display: this.state.logoutModel ? 'block' : 'none' }}>
          <div className="popupHeader py-4 px-5 k-flex align-items-center">
            <h6 className="card-title mb-0 white-text">Confirm</h6>
            <span className="close-popup ml-auto" onClick={this.closeModal}>
              <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                  <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                  <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                </g>
              </svg>
            </span>
          </div>

          <div className="popupBody pb-5 px-5 text-center">
            <div className="py-5 feilds-instruction">
              <h6 className="card-title mb-0">Seems like you're trying to logout. Do you wish to continue?</h6>
              <h6 className="card-title mb-0">Note: Once you logout all your inactive chats will be removed.</h6>
            </div>
            <div>
              <button className="btnBlue  py-2 px-7" onClick={this.logoutAppypieProduct}>Yes</button>&nbsp;
              <button className="btn  py-2 px-7" onClick={this.closeModal}>No</button>
            </div>
          </div>
        </div>
        {/* For logout confirmation for all product logout */}

        {/* For Incoming Call alert start */}

        <div id="incomingCallPopup" className="popupInstallLivechat popupLivechat callingPopup" style={{ display: 'none' }}>
          <div className="CallingPopupHeader py-5 px-5">
            <h5 id="phone_call_heading" className="card-title mb-0 white-text"></h5>

            <span className="collapse-call-popup" onClick={this.showHidePopupContent} id="showHidePopupContent"><img alt="" src={require('../../assets/images/livechat/down.svg')} /></span>
            <span className="collapse-call-popup" style={{ display: "none" }} onClick={this.showHidePopupContent} id="hideHidePopupContent"><img alt="" src={require('../../assets/images/livechat/left.svg')} /></span>

            <span id="rejectCloseIncCall" className="close-call-popup">
              <svg xmlns="http://www.w3.org/2000/svg" width="10.143" height="10.142" viewBox="0 0 14.143 14.142">
                <g id="Group_1639" data-name="Group 1639" transform="translate(-5.303 7.071) rotate(-45)">
                  <rect id="Rectangle_1271" data-name="Rectangle 1271" width="2.5" height="17.499" rx="1.25" transform="translate(7.5)" fill="#000" />
                  <rect id="Rectangle_1272" data-name="Rectangle 1272" width="2.5" height="17.499" rx="1.25" transform="translate(17.499 7.5) rotate(90)" fill="#000" />
                </g>
              </svg>
            </span>
          </div>

          <div id="incpopupcontent" style={{ display: "block" }}>
            <div className="popupBody pt-5 px-5 text-center">
              <div className="user-caling-display my-5 py-2">
                <div className="call-animation">
                  <img className="img-circle" src={require('../../assets/images/boy.png')} alt="" />
                </div>
              </div>
              <div className="py-5 feilds-instruction">
                <h4 id="fromNumberIncCall" className="card-title mb-0">Calling...</h4>
                {/*<p className="card-body-text">Lorem Ipsum</p> */}
              </div>
            </div>

            <div className="footer-calling-popup text-center pb-5 mb-5">
              <button style={{ display: "none" }} id="acceptIncCall" className="btn-calling btn-accept">
                <span className="mr-3">
                  <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="22.94" height="22.94" viewBox="0 0 31.94 31.94">
                    <g id="Group_4586" data-name="Group 4586" transform="translate(15.97 8.517)">
                      <g id="Group_4585" data-name="Group 4585">
                        <path id="Path_2554" data-name="Path 2554" d="M241.065,128a1.065,1.065,0,0,0,0,2.129,4.263,4.263,0,0,1,4.259,4.259,1.065,1.065,0,1,0,2.129,0A6.4,6.4,0,0,0,241.065,128Z" transform="translate(-240 -128)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4588" data-name="Group 4588" transform="translate(15.97 4.259)">
                      <g id="Group_4587" data-name="Group 4587">
                        <path id="Path_2555" data-name="Path 2555" d="M241.065,64a1.065,1.065,0,1,0,0,2.129,8.526,8.526,0,0,1,8.517,8.517,1.065,1.065,0,0,0,2.129,0A10.659,10.659,0,0,0,241.065,64Z" transform="translate(-240 -64)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4590" data-name="Group 4590" transform="translate(15.97)">
                      <g id="Group_4589" data-name="Group 4589">
                        <path id="Path_2556" data-name="Path 2556" d="M241.065,0a1.065,1.065,0,0,0,0,2.129,12.789,12.789,0,0,1,12.776,12.776,1.065,1.065,0,1,0,2.129,0A14.923,14.923,0,0,0,241.065,0Z" transform="translate(-240 0)" fill="#fff" />
                      </g>
                    </g>
                    <g id="Group_4592" data-name="Group 4592" transform="translate(0 2.129)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>
                </span>Accept</button>
              <button style={{ display: "none" }} id="rejectIncCall" className="btn-calling btn-reject ml-3">
                <span className="mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                    <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>

                </span>Reject</button>

              <button style={{ display: "none" }} id="hangupIncCall" className="btn-calling btn-reject">
                <span className="mr-3">
                  <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                    <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                      <g id="Group_4591" data-name="Group 4591">
                        <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                      </g>
                    </g>
                  </svg>

                </span>HangUp</button>
              <button style={{ display: "none" }} id="unmuteIncCall" className="btn-calling btn-mute  ml-3">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24.467" height="28.877" viewBox="0 0 32.467 33.877">
                    <g id="Group_4599" data-name="Group 4599" transform="translate(13554 -336)">
                      <g id="no-sound" transform="translate(-13554 336)">
                        <g id="Group_4594" data-name="Group 4594" transform="translate(9.882 0.001)">
                          <g id="Group_4593" data-name="Group 4593">
                            <path id="Path_2558" data-name="Path 2558" d="M167.772.008h-2.823a4.946,4.946,0,0,0-4.941,4.941V19.063a4.873,4.873,0,0,0,.185,1.266L172.712,7.241V4.949A4.946,4.946,0,0,0,167.772.008Z" transform="translate(-160.008 -0.008)" fill="#fff" />
                          </g>
                        </g>
                        <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                          <g id="Group_4595" data-name="Group 4595">
                            <path id="Path_2559" data-name="Path 2559" d="M25.482,24H28.3a4.946,4.946,0,0,0,4.941-4.941V11.318L42.93,1.193A.706.706,0,0,0,41.91.217L10.855,32.684a.706.706,0,0,0,1.021.975l8.066-8.433a6.257,6.257,0,0,0,4.129,1.592h2.117v5.646H21.247a.706.706,0,1,0,0,1.412H32.54a.706.706,0,1,0,0-1.412H27.6V26.819h2.117a6.36,6.36,0,0,0,6.352-6.352V13.409a.706.706,0,0,0-1.412,0v7.058a4.946,4.946,0,0,1-4.941,4.941H24.07a4.844,4.844,0,0,1-3.158-1.2l1.379-1.441A4.843,4.843,0,0,0,25.482,24Z" transform="translate(-10.659 0)" fill="#fff" />
                          </g>
                        </g>
                        <g id="Group_4598" data-name="Group 4598" transform="translate(7.059 12.704)">
                          <g id="Group_4597" data-name="Group 4597">
                            <path id="Path_2560" data-name="Path 2560" d="M118.753,199.751v-7.058a.706.706,0,1,0-1.412,0v7.058a6.31,6.31,0,0,0,.5,2.475l1.11-1.16A4.869,4.869,0,0,1,118.753,199.751Z" transform="translate(-117.341 -191.987)" fill="#fff" />
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </span></button>
              <button style={{ display: "none" }} id="muteIncCall" className="btn-calling btn-mute  ml-3">
                <span><img alt="Mute" src={require('../../assets/images/umute.svg')} /></span>
              </button>
            </div>
          </div>
        </div>

        {/* For Incoming Call alert end */}

      </>
    );
  }
}

export default DeactivatedNavigation