import React from 'react';
import LeftPanel from "./views/LeftPanel";
import ReactTooltip from "react-tooltip";
import { FUNCTION_API_KEY } from '../../config/siteConfig';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { DateRangePicker } from 'react-date-range';
import moment from 'moment-timezone';
import { toast } from 'react-toastify';
// import db from '../../config/firebase';

const DATE_FORMATES = { miliseconds: "x", hour: 'HH A', day: 'DD MMM', hours_start: "ddd, MMM DD YYYY hh:00 A", hours_end: "hh:00 A", day_start: "ddd, MMM DD YYYY" };

class TotalUsers extends React.Component {


  agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};


  constructor() {
    super();
    this.state = {
      loader: true,
      applyCustom: false,
      showDateRange: false,
      date_range: "today",
      start_date: "",
      end_date: "",
      totalUniqueVisitorsCount: 0
    }
  }

  /* Private functions start */


  componentDidMount() {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Set the time to 00:00:00
    const startOfDay = today.getTime(); // Convert to Unix timestamp in seconds
    const endOfDay = new Date(today);
    endOfDay.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
    const endOfDayTimestamp = endOfDay.getTime()
    console.log('Start of today (timestamp):', startOfDay);
    console.log('End of today (timestamp):', endOfDayTimestamp);
    this.getTotalUniqueVisitorsCount(startOfDay, endOfDayTimestamp);
    
  }

 

  showHideDatePicker = () => {
    this.setState({ showDateRange: !this.state.showDateRange, applyCustom: false });
  }

  onDatesSelect = (ranges) => {
    console.log("ranges", ranges.selection);
    this.setState({ start_date: ranges.selection.startDate, end_date: ranges.selection.endDate });
  }


  applyDateRange = () => {
    this.setState({loader: true})
    let tmpstDate = this.state.start_date;
    console.log("tmpstDate", tmpstDate)
    let stDate = moment(tmpstDate).toDate().getTime();
    let tmpedDate = this.state.end_date;
    tmpedDate.setHours(23, 59, 59, 59);
    let edDate = moment(tmpedDate).toDate().getTime();
    console.log("stDate", stDate,parseInt(stDate), "edDate", this.state.end_date)
    let diffDate = edDate - stDate;
    diffDate = Math.ceil(diffDate / (1000 * 3600 * 24));
    if (diffDate > 92) {
      toast.error("You can select date within 3 months only!");
      return false;
    } else {
      this.setState({ showDateRange: false, start_date: stDate, end_date: edDate, numberOfDays: diffDate, applyCustom: true }, () => {
        this.getTotalUniqueVisitorsCount(parseInt(stDate), parseInt(edDate));
      });
    }
  }

  changeFilterHandle = (e) => {
    console.log("e.target.value", e.target.value, e.target.name)
    if (e.target.name === "date_range" && e.target.value === "custom") {
      this.setState({ [e.target.name]: e.target.value, showDateRange: true, applyCustom: false });
      return false;
    } else {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Set the time to 00:00:00
      const startOfDay = today.getTime(); // Convert to Unix timestamp in seconds
      const endOfDay = new Date(today);
      endOfDay.setHours(23, 59, 59, 999); // Set the time to 23:59:59.999
      const endOfDayTimestamp = endOfDay.getTime()
      console.log('Start of today (timestamp):', startOfDay);
      console.log('End of today (timestamp):', endOfDayTimestamp);
      this.setState({ showDateRange: false, applyCustom: false, [e.target.name]: e.target.value }, () => {
        this.getTotalUniqueVisitorsCount(startOfDay, endOfDayTimestamp);
      });
    }


  }

  getTotalUniqueVisitorsCount = async (start, end) => {
    console.log("start", start)
    console.log("end--", end)
    // fetch data from api
      fetch(`https://us-central1-livechat-production.cloudfunctions.net/analytics/get-unique-visitors?start=${parseInt(start)}&end=${parseInt(end)}`, {
        method: 'GET',
        headers: {
          "X-API-Key": FUNCTION_API_KEY,
          "Content-Type": "application/x-www-form-urlencoded",
          "Accept": "application/json",
          "Origin": window.location.origin
        }
      }).then(response => response.json()).then((data) => {
        console.log("data", data)
        if (data.data) {

          this.setState({ totalUniqueVisitorsCount: data.data , loader:false});
      
        } else {
          this.setState({ totalUniqueVisitorsCount: 0 });
        }
      }).catch((err) => {
        this.setState({ totalUniqueVisitorsCount: 0 });
      });
    
  }
  cancelDateRange = () => {
    this.setState({ showDateRange: false, applyCustom: false });
  }

  render() {

    let dateShow = "";
    if (this.state.date_range === "custom") {
      dateShow = "manage-icon";
    }
    const today = moment().toDate(); 

    return (<>
      <ReactTooltip
        place={'bottom'}
        effect={'solid'}
        multiline={true} />
      {/* <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div> */}
      <LeftPanel />
      <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
        <h3 className="heding-text">Analytics</h3>
        <div className="ml-auto"></div>
        <div className="mobile-menu">
          <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
        </div>
      </header>
      <div className="page-wrapper sidebar-collapse" data-mobile-height="">
        <div className="main-wrapper container-fluid">
          <div className="chatform-Preview">
            <div className="container-fluid">
              {/* Filters Start  */}
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                  <div className="main-heading main-heading-title">
                    <h3 className="heding-text mb-3">Total Unique Visitors</h3>
                    {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '10px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                  </div>


                </div>

              </div>

              <div className='row'>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                  <div className="k-flex align-items-center ml-auto label-style">
                    <div className={`k-flex position-relative mob-view ${dateShow}`}>
                      <div className="material-select">
                        <FormControl variant="outlined" className="material-outlined w-150">
                          <InputLabel id="date_range-outlined-label">Date range</InputLabel>
                          <Select MenuProps={{ disableScrollLock: true }} labelId="date_range-outlined-label" id="date_range" name="date_range" value={this.state.date_range ? this.state.date_range : "today"} onChange={(e) => this.changeFilterHandle(e)} label="Date range">
                            <MenuItem value="today">Today</MenuItem>
                            {/* <MenuItem value="7_days">Last 7 days</MenuItem>
                            <MenuItem value="14_days">Last 14 days</MenuItem>
                            <MenuItem value="30_days">Last 30 days</MenuItem> */}
                            <MenuItem value="custom">Custom</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      {this.state.date_range === "custom" ? (<span className="pl-3 pr-3 mb-0 text-14 cursor-pointer pt-3 date-icon" onClick={this.showHideDatePicker}>
                        <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 58.382 58.382">
                          <g id="Group_4585" data-name="Group 4585">
                            <g id="Group_4584" data-name="Group 4584">
                              <path id="Path_2553" data-name="Path 2553" d="M51.541,4.561H48.8V0H44.243V4.561h-30.1V0H9.578V4.561H6.842A6.849,6.849,0,0,0,0,11.4V51.541a6.849,6.849,0,0,0,6.842,6.842h44.7a6.849,6.849,0,0,0,6.842-6.842V11.4A6.849,6.849,0,0,0,51.541,4.561Zm2.281,46.98a2.283,2.283,0,0,1-2.281,2.281H6.842a2.283,2.283,0,0,1-2.281-2.281v-30.1h49.26Zm0-34.665H4.561V11.4A2.283,2.283,0,0,1,6.842,9.122H9.578v4.561h4.561V9.122h30.1v4.561H48.8V9.122h2.737A2.283,2.283,0,0,1,53.821,11.4Z" />
                            </g>
                          </g>
                          <g id="Group_4587" data-name="Group 4587" transform="translate(8.74 26.218)">
                            <g id="Group_4586" data-name="Group 4586" transform="translate(0 0)">
                              <rect id="Rectangle_1782" data-name="Rectangle 1782" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4589" data-name="Group 4589" transform="translate(17.843 26.218)">
                            <g id="Group_4588" data-name="Group 4588" transform="translate(0 0)">
                              <rect id="Rectangle_1783" data-name="Rectangle 1783" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4591" data-name="Group 4591" transform="translate(26.947 26.218)">
                            <g id="Group_4590" data-name="Group 4590" transform="translate(0 0)">
                              <rect id="Rectangle_1784" data-name="Rectangle 1784" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4593" data-name="Group 4593" transform="translate(36.05 26.218)">
                            <g id="Group_4592" data-name="Group 4592" transform="translate(0 0)">
                              <rect id="Rectangle_1785" data-name="Rectangle 1785" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4595" data-name="Group 4595" transform="translate(45.154 26.218)">
                            <g id="Group_4594" data-name="Group 4594" transform="translate(0 0)">
                              <rect id="Rectangle_1786" data-name="Rectangle 1786" width="4.734" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4597" data-name="Group 4597" transform="translate(8.74 35.322)">
                            <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                              <rect id="Rectangle_1787" data-name="Rectangle 1787" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4599" data-name="Group 4599" transform="translate(17.843 35.322)">
                            <g id="Group_4598" data-name="Group 4598" transform="translate(0 0)">
                              <rect id="Rectangle_1788" data-name="Rectangle 1788" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4601" data-name="Group 4601" transform="translate(26.947 35.322)">
                            <g id="Group_4600" data-name="Group 4600" transform="translate(0 0)">
                              <rect id="Rectangle_1789" data-name="Rectangle 1789" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4603" data-name="Group 4603" transform="translate(36.05 35.322)">
                            <g id="Group_4602" data-name="Group 4602" transform="translate(0 0)">
                              <rect id="Rectangle_1790" data-name="Rectangle 1790" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4605" data-name="Group 4605" transform="translate(8.74 44.426)">
                            <g id="Group_4604" data-name="Group 4604" transform="translate(0 0)">
                              <rect id="Rectangle_1791" data-name="Rectangle 1791" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4607" data-name="Group 4607" transform="translate(17.843 44.426)">
                            <g id="Group_4606" data-name="Group 4606" transform="translate(0 0)">
                              <rect id="Rectangle_1792" data-name="Rectangle 1792" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4609" data-name="Group 4609" transform="translate(26.947 44.426)">
                            <g id="Group_4608" data-name="Group 4608" transform="translate(0 0)">
                              <rect id="Rectangle_1793" data-name="Rectangle 1793" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4611" data-name="Group 4611" transform="translate(36.05 44.426)">
                            <g id="Group_4610" data-name="Group 4610" transform="translate(0 0)">
                              <rect id="Rectangle_1794" data-name="Rectangle 1794" width="4.37" height="4.734" />
                            </g>
                          </g>
                          <g id="Group_4613" data-name="Group 4613" transform="translate(45.154 35.322)">
                            <g id="Group_4612" data-name="Group 4612" transform="translate(0 0)">
                              <rect id="Rectangle_1795" data-name="Rectangle 1795" width="4.734" height="4.734" />
                            </g>
                          </g>
                        </svg>

                      </span>) : (null)}
                      <span className="visitor-datepicker cstm-right" style={{ display: this.state.showDateRange ? "block" : "none" }}>
                        <DateRangePicker
                          staticRanges={[]}
                          inputRanges={[]}
                          onChange={this.onDatesSelect}
                          showSelectionPreview={true}
                          moveRangeOnFirstSelection={false}
                          months={2}
                          ranges={[{
                            startDate: this.state.start_date,
                            endDate: this.state.end_date,
                            key: 'selection'
                          }]}
                          direction="horizontal"
                          maxDate={today}
                        />
                        <div className="py-5 px-5 text-right">
                          <button className="btnBlueSettings mr-3" onClick={this.applyDateRange}>Apply</button>
                          <button className="btnBorderSettings mr-3" onClick={this.cancelDateRange}>Cancel</button>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* Filters End  */}

              <div className="row">
                <div className="col-xs-3 col-sm-12 col-md-6 col-lg-3 mb-5 pr-0 mob-res">
                  <div className="bg-white left-widgetsetings-box chatReportCol cstm-box-time">
                    <span className="title-total-chat analytics-title-size">Total Unique Visitors&nbsp;
                      <span className="info-icon">
                        <img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Total number of new visitors in the selected date range" />
                      </span>
                    </span>
                    <div className="chatReport-total-chats k-flex align-items-center pt-8">
                      <div className="online-time mob-pd-0">
                        <p className="numberReport">
                          {this.state.loader ? (<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>) : 
                          <span className="big">{this.state.totalUniqueVisitorsCount}</span> }
                          </p>
                      </div>
                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </>
    )
  }
}

export default TotalUsers;