import React from 'react';
import { toast } from 'react-toastify';
import LeftPanel from "./views/LeftPanel";
import ApexCharts from "react-apexcharts";
import moment from 'moment-timezone';
// import db from '../../config/firebase';
import { MAIL_FUNCTION_URL, DEFAULT_COMMONLOGIN_BLANK_KEYWORD, FUNCTION_API_KEY, GATEWAY_URL } from '../../config/siteConfig';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { getOwnerDetails, getAllAwsAgents, checkWidgetStatus, getOwnerAfterLogin } from "../../services/service";
import IndividualAgent from "./individualAgent";
import dummyData from "../../localization/dummy-data.json";
import { NotificationContainer, NotificationManager } from "react-notifications";

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import qs from 'query-string';
import firebaseServices from '../../firebase';

const DATE_FORMATES = { miliseconds: "x", hour: 'HH A', day: 'DD MMM', hours_start: "ddd, MMM DD YYYY hh:00 A", hours_end: "hh:00 A", day_start: "ddd, MMM DD YYYY" };
class AgentsNewleaderboard extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.userRole = localStorage.getItem('userRole') ? localStorage.getItem('userRole') : 'Agent';
        this.state = {
            loader: true,
            currentchats: [],
            agentList: [],
            agentListArray: [],
            domain_list: [],
            date_range: "today",
            domain: "",
            start_date: "",
            end_date: "",
            fill_start_date: addDays(new Date(), -7),
            fill_end_date: new Date(),
            numberOfDays: 0,
            showDateRange: false,
            applyCustom: false,
            agentId: "online",
            sortedAgent: [],
            agentActivity: {},
            sort_by: "completed",
            sort_order: "DESC",
            showDummyData: false,
            data_type: 0
        }
    }

    getDomainsList = () => {
        return false;
        /* firebaseServices.db.collection('reports').doc('widget').collection('installation').doc(this.agent.ownerId).collection('domains').get().then((domains) => {
            if (!domains.empty) {
                let allDomains = [];
                domains.forEach((domain) => {
                    allDomains.push(domain.data().domain);
                })
                this.setState({
                    domain_list: allDomains
                });
            }
        }); */
    }

    getAgentsList = async () => {
        let ownerDetail = await getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
        // let ownerDetail = await getOwnerAfterLogin();
        let ownerDetailsData = {}
        if (ownerDetail.status === 200) {
            ownerDetailsData = ownerDetail.data;
        }
        let allAgentss = await getAllAwsAgents();
        var agents = [];
        var owner = [];
        var i = 0;
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').where("deletetime", "==", 0).get().then((agentsData) => {
            if (!agentsData.empty) {
                agentsData.forEach((doc) => {
                    if (doc.data().roleType === 'Owner') {
                        let ownerData = doc.data();
                        if (ownerDetail.status === 200) {
                            ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                            ownerData.last_name = ownerDetailsData.last_name ? ownerDetailsData.last_name : '';
                            ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                            ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                            ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                            ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                            ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                            ownerData.alias = ownerDetailsData.alias ? ownerDetailsData.alias : '';
                            ownerData.profile_pic = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail : ''
                        }
                        owner[0] = ownerData;
                        owner[0]['id'] = doc.id;
                    } else {
                        let agentData = doc.data();
                        delete agentData.alias;
                        agents[i] = agentData;
                        agents[i]['id'] = doc.id;
                        i++;
                    }
                });
            }
            let merged = [];
            for (let j = 0; j < allAgentss.length; j++) {
                merged.push({
                    ...allAgentss[j],
                    ...(agents.find((itmInner) => itmInner.id == allAgentss[j].preferred_username))
                });
            }
            merged.push(owner[0]);
            let agentsObj = {};
            let sortedAgent = [];
            let agentListArray = [];
            merged.forEach(agtData => {
                if (agtData.status && agtData.status === 1) {
                    let fname = (agtData.first_name).trim();
                    fname = (fname !== "") ? fname[0].toUpperCase() + fname.slice(1) : "";
                    let lname = (agtData.last_name).trim();
                    lname = (lname !== "") ? lname[0].toUpperCase() + lname.slice(1) : "";
                    agtData.first_name = fname;
                    agtData.last_name = lname;
                    if (fname && lname && lname !== "NA") {
                        agtData.name = fname + " " + lname;
                    } else {
                        agtData.name = fname;
                    }
                    sortedAgent.push(agtData.id);
                    agentListArray.push(agtData);
                    agentsObj[agtData.id] = agtData;
                }
            });

            /* Sort agents by name */
            agentListArray.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
            console.log(agentListArray, "---------agentListArray---------");
            this.setState({
                agentList: agentsObj,
                sortedAgent: sortedAgent,
                agentListArray: agentListArray
            }, () => {
                this.getchats();
            });
        });
    }

    secondsToHms = (d) => {
        d = Number(d);
        if (d === 0) {
            return "0s";
        }

        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 0 ? h + "h " : "";
        var mDisplay = m > 0 ? m + "m " : "";
        var sDisplay = s > 0 ? s + "s " : "";
        return hDisplay + mDisplay + sDisplay;
    }

    sortData = (field) => {
        this.setState({ loader: true });
        let sortOrder = (this.state.sort_order === "DESC") ? "ASC" : "DESC";
        let tmpAgentActivity = this.state.agentActivity;
        let tmpSortedAgents = Object.assign([], this.state.sortedAgent);
        let tmpAgentListObj = this.state.agentList;

        if (field === "name") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].name.localeCompare(tmpAgentListObj[a].name);
                } else {
                    return tmpAgentListObj[a].name.localeCompare(tmpAgentListObj[b].name);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "name", sort_order: sortOrder, loader: false });
        } else if (field === "alias") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].alias.localeCompare(tmpAgentListObj[a].alias);
                } else {
                    return tmpAgentListObj[a].alias.localeCompare(tmpAgentListObj[b].alias);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "alias", sort_order: sortOrder, loader: false });
        } else if (field === "email") {
            let sortedAgents = Object.keys(tmpAgentListObj).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentListObj[b].email.localeCompare(tmpAgentListObj[a].email);
                } else {
                    return tmpAgentListObj[a].email.localeCompare(tmpAgentListObj[b].email);
                }
            });
            this.setState({ sortedAgent: sortedAgents, sort_by: "email", sort_order: sortOrder, loader: false });
        } else if (field === "satisfaction") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].satisfaction - tmpAgentActivity[a].satisfaction;
                } else {
                    return tmpAgentActivity[a].satisfaction - tmpAgentActivity[b].satisfaction;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "satisfaction", sort_order: sortOrder, loader: false });
        } else if (field === "feedback") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].feedbacks_received - tmpAgentActivity[a].feedbacks_received;
                } else {
                    return tmpAgentActivity[a].feedbacks_received - tmpAgentActivity[b].feedbacks_received;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "feedback", sort_order: sortOrder, loader: false });
        } else if (field === "response") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].responseTime - tmpAgentActivity[a].responseTime;
                } else {
                    return tmpAgentActivity[a].responseTime - tmpAgentActivity[b].responseTime;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "response", sort_order: sortOrder, loader: false });
        } else if (field === "duration") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].duration - tmpAgentActivity[a].duration;
                } else {
                    return tmpAgentActivity[a].duration - tmpAgentActivity[b].duration;
                }
            });

            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });

            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }
            this.setState({ sortedAgent: allSortedAgts, sort_by: "duration", sort_order: sortOrder, loader: false });
        } else if (field === "initiated") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].initiated_chats - tmpAgentActivity[a].initiated_chats;
                } else {
                    return tmpAgentActivity[a].initiated_chats - tmpAgentActivity[b].initiated_chats;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }

            this.setState({ sortedAgent: allSortedAgts, sort_by: "initiated", sort_order: sortOrder, loader: false });
        } else if (field === "served") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].served_chats - tmpAgentActivity[a].served_chats;
                } else {
                    return tmpAgentActivity[a].served_chats - tmpAgentActivity[b].served_chats;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }

            this.setState({ sortedAgent: allSortedAgts, sort_by: "served", sort_order: sortOrder, loader: false });
        } else if (field === "picked") {
            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].picked_chats - tmpAgentActivity[a].picked_chats;
                } else {
                    return tmpAgentActivity[a].picked_chats - tmpAgentActivity[b].picked_chats;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }

            this.setState({ sortedAgent: allSortedAgts, sort_by: "picked", sort_order: sortOrder, loader: false });
        } else {

            let sortedAgents = Object.keys(tmpAgentActivity).sort((a, b) => {
                if (sortOrder === "DESC") {
                    return tmpAgentActivity[b].complete_chats - tmpAgentActivity[a].complete_chats;
                } else {
                    return tmpAgentActivity[a].complete_chats - tmpAgentActivity[b].complete_chats;
                }
            });
            sortedAgents.forEach(sorAgtId => {
                let index = tmpSortedAgents.indexOf(sorAgtId);
                if (index >= 0) {
                    tmpSortedAgents.splice(index, 1);
                }
            });
            let allSortedAgts;
            if (sortOrder === "DESC") {
                allSortedAgts = sortedAgents.concat(tmpSortedAgents);
            } else {
                allSortedAgts = tmpSortedAgents.concat(sortedAgents);
            }

            this.setState({ sortedAgent: allSortedAgts, sort_by: "completed", sort_order: sortOrder, loader: false });
        }
    }

    async componentDidMount() {
        const urlValues = qs.parse(window.location.search);
        const data_type = urlValues.data_type ? parseInt(urlValues.data_type) : 0;
        const orgTimezone = this.agent.org_timezone ? this.agent.org_timezone : "";
        if (data_type !== 2 && (data_type === 1 || orgTimezone !== "")) {
            this.setState({ data_type: 1 });
        }

        let widgetStatus = await checkWidgetStatus();
        this.setState({
            widgetStatus: widgetStatus
        })
        if (widgetStatus === true) {
            this.getAgentsList();
            this.getDomainsList();
        } else {
            this.loadDummyData();
        }
    }

    loadDummyData = () => {
        let agentDummyData = dummyData.AllAgents;
        var sortedAgent = [];
        var allAgents = [];
        var agentActivity = [];
        agentDummyData.forEach((agent) => {
            allAgents[agent.id] = agent;
            sortedAgent.push(agent.id);
            agentActivity[agent.id] = {
                agentId: agent.id,
                complete_chats: agent.completed,
                satisfaction: agent.satisfaction,
                feedbacks_received: agent.feedback_received,
                feedback_received_agent: agent.feedback_received_agent,
                responseTime: agent.response_time,
                duration: agent.duration
            };
        })
        this.setState({
            sortedAgent: sortedAgent,
            agentList: allAgents,
            agentActivity: agentActivity,
            loader: false,
            showDummyData: true
        })
    }

    changeFilterHandle = (e) => {
        if (this.state.widgetStatus === false && this.state.showDummyData === true) {
            NotificationManager.error("", 'Filter will not work for sample report', 5000);
            return;
        }
        if (e.target.name === "date_range" && e.target.value === "custom") {
            this.setState({ [e.target.name]: e.target.value, chart_base: "daily", showDateRange: true, applyCustom: false });
            return false;
        } else {
            this.setState({ showDateRange: false, applyCustom: false });
        }
        var chartBaseVal = "";
        if (e.target.name === "domain") {
            chartBaseVal = "domain";
        } else if (e.target.name === "agentId") {
            chartBaseVal = "agentId";
        }

        if (chartBaseVal !== "" && chartBaseVal === "domain") {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.getchats();
            });
        } else if (chartBaseVal !== "" && chartBaseVal === "agentId") {
            this.setState({ [e.target.name]: e.target.value });
        } else {
            this.setState({ [e.target.name]: e.target.value }, () => {
                this.getchats();
            });
        }
    }

    onDatesSelect = (ranges) => {
        this.setState({ fill_start_date: ranges.selection.startDate, fill_end_date: ranges.selection.endDate });
    }

    applyDateRange = () => {
        let tmpstDate = this.state.fill_start_date;
        let stDate = moment(tmpstDate).format(DATE_FORMATES.miliseconds);
        let tmpedDate = this.state.fill_end_date;
        tmpedDate.setHours(23, 59, 0, 0);
        let edDate = moment(tmpedDate).format(DATE_FORMATES.miliseconds);

        let diffDate = edDate - stDate;
        diffDate = Math.ceil(diffDate / (1000 * 3600 * 24));
        if (diffDate > 92) {
            toast.error("You can select date within 3 months only!");
            return false;
        } else {
            this.setState({ showDateRange: false, start_date: stDate, end_date: edDate, numberOfDays: diffDate, applyCustom: true }, () => {
                this.getchats();
            });
        }
    }

    cancelDateRange = () => {
        this.setState({ showDateRange: false, applyCustom: false });
    }

    showHideDatePicker = () => {
        this.setState({ showDateRange: !this.state.showDateRange, applyCustom: false });
    }

    loadLBChats = () => {
        var currentchats = this.state.currentchats;
        let allAgentIdes = this.state.sortedAgent;
        let agentChats = {};
        currentchats.forEach(chatData => {
            let chatObj = {
                chat_duration: chatData.chat_duration,
                chats_rated: chatData.chats_rated,
                init_chats: chatData.init_chats,
                ratings_sum: chatData.ratings_sum,
                response_time: chatData.response_time,
                served_chats: chatData.served_chats,
                picked_chats: chatData.picked_chats ? chatData.picked_chats : 0
            };

            if (agentChats[chatData.agent_id] === undefined) {
                agentChats[chatData.agent_id] = [];
            }
            agentChats[chatData.agent_id].push(chatObj);
        });

        let agentActivity = {};
        if (Object.keys(agentChats).length > 0) {
            for (var key in agentChats) {
                let agtdata = agentChats[key];
                let totalcompchats = 0;
                let tmp_chat_duration = 0; // Average
                let tmp_response_time = 0;   // Average
                let tmp_rating = 0;
                let tmp_feedback_received = 0; // Count chat 
                let total_served_chats = 0;   // total served chats
                let total_initiated_chats = 0;   // total initiated chats
                let total_picked_chats = 0;   // total initiated chats
                agtdata.forEach(reptData => {
                    tmp_chat_duration += reptData.chat_duration;
                    tmp_response_time += reptData.response_time;
                    tmp_rating += reptData.ratings_sum;
                    tmp_feedback_received += reptData.chats_rated;
                    total_initiated_chats += reptData.init_chats;
                    total_served_chats += reptData.served_chats;
                    total_picked_chats += reptData.picked_chats;
                });

                totalcompchats = total_served_chats + total_initiated_chats;

                /* Remove agent if find here */
                let index = allAgentIdes.indexOf(key);
                allAgentIdes.splice(index, 1);
                /* Remove agent if find here */

                //let ratPercentage = tmp_rating / (tmp_feedback_received * 100);
                let ratPercentage = Math.ceil((tmp_rating / tmp_feedback_received) * 100);

                agentActivity[key] = {
                    agentId: key,
                    complete_chats: totalcompchats,
                    satisfaction: tmp_rating === 0 ? 0 : ratPercentage,
                    feedbacks_received: tmp_feedback_received,
                    responseTime: tmp_response_time / totalcompchats,
                    duration: tmp_chat_duration / totalcompchats,
                    served_chats: total_served_chats,
                    initiated_chats: total_initiated_chats,
                    picked_chats: total_picked_chats
                };
            }
        }

        function compareData(a, b) {
            return agentActivity[b].complete_chats - agentActivity[a].complete_chats;
        }

        let sortedAgents = Object.keys(agentActivity).sort(compareData);
        let allSortedAgts = sortedAgents.concat(allAgentIdes);

        this.setState({ agentActivity: agentActivity }, () => {
            this.setState({ sortedAgent: allSortedAgts, loader: false });
        });
    }

    getchats = () => {
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        this.setState({ loader: true });
console.log(this.state.data_type, "---------this.state.data_type---------");
        if (this.state.data_type === 1) {
            let body = [`ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&domain=${encodeURIComponent((this.state.domain && this.state.domain !== "all") ? this.state.domain : "")}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&currentDate=${todayDate.getTime()}&org_timezone=${encodeURIComponent(this.agent.org_timezone ? this.agent.org_timezone : "")}`];
            fetch(MAIL_FUNCTION_URL+"/analytics/getLBNewInfo", {
            // fetch(GATEWAY_URL + "/getLBInfo", {
                method: "POST",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    console.log(response.data, "---------response.data---------");
                    this.setState({ currentchats: response.data }, () => {
                        this.loadLBChats();
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        } else {
            let body = [`ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&domain=${encodeURIComponent((this.state.domain && this.state.domain !== "all") ? this.state.domain : "")}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&currentDate=${todayDate.getTime()}`];
            //fetch(MAIL_FUNCTION_URL + "/analytics/getAgentChats", {
            fetch(GATEWAY_URL + "/getAgentChats", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    this.setState({ currentchats: response.data }, () => {
                        this.loadChats();
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        }
    }

    loadChats = () => {
        var currentchats = this.state.currentchats;
        let allAgentIdes = this.state.sortedAgent;
        let agentChats = {};
        let agentChats2 = {};
        currentchats.forEach(chatData => {
            var agent_ides = chatData.agent_ides;
            let agtIdesArr = agent_ides.split(",");
            const picked_by = chatData.picked_by ? chatData.picked_by : "";
            let chatObj = {
                chat_duration: chatData.chat_duration,
                rating: chatData.rating,
                response_time: chatData.response_time,
                wait_time: chatData.wait_time,
                status: chatData.status,
                picked_by: picked_by,
                picked_chat: 0
            };

            /* For picked count start */
            let findAgent = "";
            if (picked_by) {
                if (agtIdesArr.indexOf(picked_by) !== -1) {
                    findAgent = "yes";
                } else {
                    findAgent = "no";
                }
            }
            /* For picked count end */

            if (agtIdesArr.length > 1) {
                agtIdesArr.forEach(agtId => {
                    if (agentChats[agtId] === undefined) {
                        agentChats[agtId] = [];
                    }
                    if (findAgent === "yes" && agtId === picked_by) {
                        chatObj.picked_chat = 1;
                    }
                    agentChats[agtId].push(chatObj);
                });
            } else {
                if (agentChats[agent_ides] === undefined) {
                    agentChats[agent_ides] = [];
                }

                if (findAgent === "yes" && agent_ides === picked_by) {
                    chatObj.picked_chat = 1;
                }
                agentChats[agent_ides].push(chatObj);
            }

            if (findAgent === "no") {
                if (agentChats2[picked_by] === undefined) {
                    agentChats2[picked_by] = [];
                }
                agentChats2[picked_by].push(1);
            }
        });
        let agentActivity = {};
        if (Object.keys(agentChats).length > 0) {
            for (var key in agentChats) {
                let agtdata = agentChats[key];
                let totalcompchats = agtdata.length;
                let tmp_chat_duration = 0; // Average
                let tmp_rating = 0;
                let tmp_feedback_received = 0; // Count chat 
                let tmp_response_time = 0;   // Average
                let total_served_chats = 0;   // total served chats
                let total_initiated_chats = 0;   // total initiated chats
                let total_picked_chat = 0;   // total picked chats
                agtdata.forEach(reptData => {
                    let chat_duration = reptData.chat_duration === null ? 0 : reptData.chat_duration;
                    let rating = reptData.rating === null ? 0 : reptData.rating;
                    let response_time = reptData.response_time === null ? 0 : reptData.response_time;
                    tmp_chat_duration += chat_duration;
                    tmp_response_time += response_time;
                    total_picked_chat += reptData.picked_chat;
                    if (rating > 0) {
                        tmp_rating += rating;
                        tmp_feedback_received++;
                    }

                    if (reptData.status === "initiated") {
                        total_initiated_chats += 1;
                    } else {
                        total_served_chats += 1;
                    }
                });

                /* Remove agent if find here */
                let index = allAgentIdes.indexOf(key);
                allAgentIdes.splice(index, 1);
                /* Remove agent if find here */

                //let ratPercentage = tmp_rating / (tmp_feedback_received * 100);
                let ratPercentage = Math.ceil((tmp_rating /tmp_feedback_received ) * 100);


                if (Object.keys(agentChats2).length > 0 && agentChats2[key] && agentChats2[key].length > 0) {
                    total_picked_chat += agentChats2[key].length;
                }

                agentActivity[key] = {
                    agentId: key,
                    complete_chats: totalcompchats,
                    satisfaction: tmp_rating === 0 ? 0 : ratPercentage,
                    feedbacks_received: tmp_feedback_received,
                    responseTime: tmp_response_time / totalcompchats,
                    duration: tmp_chat_duration / totalcompchats,
                    served_chats: total_served_chats,
                    initiated_chats: total_initiated_chats,
                    picked_chats: total_picked_chat
                };
            }
        }

        function compareData(a, b) {
            return agentActivity[b].complete_chats - agentActivity[a].complete_chats;
        }

        let sortedAgents = Object.keys(agentActivity).sort(compareData);
        let allSortedAgts = sortedAgents.concat(allAgentIdes);

        this.setState({ agentActivity: agentActivity }, () => {
            this.setState({ sortedAgent: allSortedAgts, loader: false });
        });
    }

    sideMenuePhone = () => {
        try {
            document.getElementById('sidebarnavChild').classList.toggle('sideMenue');
        } catch (err) { }
    }

    hideDummyData = () => {
        this.setState({
            showDummyData: false,
            loader: true
        })
        this.getAgentsList();
        this.getDomainsList();
    }

    render() {
        var list = this.state.sortedAgent.map((agtId, key) => {
            var value = this.state.agentList[agtId] ? this.state.agentList[agtId] : {};
            let agentName = value.first_name ? value.first_name + ' ' + (value.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value.last_name : '') : (value.name ? value.name : '');
            let agentAlias = value.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value.alias : '---';
            try {
                if (agentName.length > 40) {
                    agentName = agentName.substring(0, 35) + '....';
                }
                if (agentAlias.length > 40) {
                    agentAlias = agentAlias.substring(0, 35) + '....';
                }
            } catch (rer) { }
            let isOwner = (value.roleType && value.roleType === "Owner") ? true : false;
            var activity = this.state.agentActivity[agtId] ? this.state.agentActivity[agtId] : {};

            if (this.userRole === 'Owner' || this.userRole === 'Admin') {
                if (this.state.agentId && this.state.agentId !== "all" && this.state.agentId !== "online") {
                    if (this.state.agentId === agtId) {
                        return <tr key={key}>
                            <td><p className="small text-screen1">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /> </span>) : (null)}</p></td>
                            <td><p className="small text-screen1">{agentAlias}</p></td>
                            <td><p className="small text-screen1">{value.email}</p></td>
                            <td><p className="small text-screen1">{activity.initiated_chats ? activity.initiated_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.served_chats ? activity.served_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.picked_chats ? activity.picked_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.complete_chats ? activity.complete_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.satisfaction ? (activity.satisfaction).toFixed(0) + "%" : 0}</p></td>
                            <td><p className="small text-screen1">{activity.feedbacks_received ? activity.feedbacks_received : 0}</p></td>
                            <td><p className="small text-screen1">{activity.responseTime ? this.secondsToHms(activity.responseTime) : 0}</p></td>
                            <td><p className="small text-screen1">{activity.duration ? this.secondsToHms(activity.duration) : 0}</p></td>
                        </tr>
                    }
                } else if (this.state.agentId && this.state.agentId === "online") {
                    if (activity.complete_chats > 0) {
                        return <tr key={key}>
                            <td><p className="small text-screen1">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /> </span>) : (null)}</p></td>
                            <td><p className="small text-screen1">{agentAlias}</p></td>
                            <td><p className="small text-screen1">{value.email}</p></td>
                            <td><p className="small text-screen1">{activity.initiated_chats ? activity.initiated_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.served_chats ? activity.served_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.picked_chats ? activity.picked_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.complete_chats ? activity.complete_chats : 0}</p></td>
                            <td><p className="small text-screen1">{activity.satisfaction ? (activity.satisfaction).toFixed(0) + "%" : 0}</p></td>
                            <td><p className="small text-screen1">{activity.feedbacks_received ? activity.feedbacks_received : 0}</p></td>
                            <td><p className="small text-screen1">{activity.responseTime ? this.secondsToHms(activity.responseTime) : 0}</p></td>
                            <td><p className="small text-screen1">{activity.duration ? this.secondsToHms(activity.duration) : 0}</p></td>
                        </tr>
                    }
                } else {
                    return <tr key={key}>
                        <td><p className="small text-screen1">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /> </span>) : (null)}</p></td>
                        <td><p className="small text-screen1">{agentAlias}</p></td>
                        <td><p className="small text-screen1">{value.email}</p></td>
                        <td><p className="small text-screen1">{activity.initiated_chats ? activity.initiated_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.served_chats ? activity.served_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.picked_chats ? activity.picked_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.complete_chats ? activity.complete_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.satisfaction ? (activity.satisfaction).toFixed(0) + "%" : 0}</p></td>
                        <td><p className="small text-screen1">{activity.feedbacks_received ? activity.feedbacks_received : 0}</p></td>
                        <td><p className="small text-screen1">{activity.responseTime ? this.secondsToHms(activity.responseTime) : 0}</p></td>
                        <td><p className="small text-screen1">{activity.duration ? this.secondsToHms(activity.duration) : 0}</p></td>
                    </tr>
                }
            } else {
                if (this.agent.agentId === agtId) {
                    return <tr key={key}>
                        <td><p className="small text-screen1">{agentName} {isOwner ? (<span className="company-owner icon-owner"><img src={require("../../assets/images/crown.svg")} /> </span>) : (null)}</p></td>
                        <td><p className="small text-screen1">{agentAlias}</p></td>
                        <td><p className="small text-screen1">{value.email}</p></td>
                        <td><p className="small text-screen1">{activity.initiated_chats ? activity.initiated_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.served_chats ? activity.served_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.picked_chats ? activity.picked_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.complete_chats ? activity.complete_chats : 0}</p></td>
                        <td><p className="small text-screen1">{activity.satisfaction ? (activity.satisfaction).toFixed(0) + "%" : 0}</p></td>
                        <td><p className="small text-screen1">{activity.feedbacks_received ? activity.feedbacks_received : 0}</p></td>
                        <td><p className="small text-screen1">{activity.responseTime ? this.secondsToHms(activity.responseTime) : 0}</p></td>
                        <td><p className="small text-screen1">{activity.duration ? this.secondsToHms(activity.duration) : 0}</p></td>
                    </tr>
                }
            }
        });

        let dateShow = "";
        if (this.state.date_range === "custom") {
            dateShow = "manage-icon";
        }
        return (<>
            <NotificationContainer />
            <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <LeftPanel />
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Analytics</h3>
                <div className="ml-auto"></div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">

                            {/* Filters Start  */}
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-5">
                                    <div className="pb-4">
                                        <div className="main-heading main-heading-title py-5">
                                            <h3 className="heding-text">Agents Leaderboard</h3>
                                            {this.state.showDummyData === true ? (<p style={{ width: '100%', textAlign: 'center', marginBottom: '15px' }}><span style={{ padding: '9px 11px', background: '#ffffff', borderRadius: '15px', border: '1px solid #ffad1e', fontSize: '13px' }}><span style={{ margin: '10px', borderRadius: '50%', border: '2px solid #ffad1e', padding: '2px 8px', textAlign: 'center' }}>i</span> This is a sample report.</span></p>) : (null)}
                                        </div>
                                    </div>

                                    <div className="bg-white left-widgetsetings-box k-flex align-items-center px-5 py-5 mob-analytics">
                                        <div className="k-flex align-items-center label-style">
                                            {/* <div className="material-select">
                                                <FormControl variant="outlined" className="material-outlined w-150">
                                                    <InputLabel id="ddomain-outlined-label">Widget</InputLabel>
                                                    <Select MenuProps={{ disableScrollLock: true}} labelId="domain-outlined-label" id="domain" name="domain" value={this.state.domain?this.state.domain:"all"} onChange={(e) => this.changeFilterHandle(e)} label="Widget">
                                                        <MenuItem value="all">All</MenuItem>
                                                        {this.state.domain_list.map((domn, indx) => {
                                                            return <MenuItem key={indx} value={domn}>{domn}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </div> */}

                                            {/* <label className="pl-3 pr-3 mb-0 text-14">Widget</label>
                                            <span>
                                                <select className="customArrow" name="domain" id="domain" onChange={(e) => this.changeFilterHandle(e)}>
                                                    <option value="">All</option>
                                                    {this.state.domain_list.map((domn, indx) => {
                                                        return <option key={indx} value={domn}>{domn}</option>
                                                    })}
                                                </select>
                                            </span> */}
                                        </div>

                                        <div className="k-flex align-items-center label-style">
                                            <div className="material-select ml-5">
                                                <FormControl variant="outlined" className="material-outlined w-150">
                                                    <InputLabel id="agentId-outlined-label">Agent</InputLabel>
                                                    {(this.userRole === 'Owner' || this.userRole === 'Admin') ? (<Select MenuProps={{ disableScrollLock: true }} labelId="agentId-outlined-label" id="agentId" name="agentId" value={this.state.agentId ? this.state.agentId : "online"} onChange={(e) => this.changeFilterHandle(e)} label="Agent">
                                                        <MenuItem value="all">All Agents</MenuItem>
                                                        <MenuItem value="online">Online Agents</MenuItem>
                                                        {this.state.agentListArray.map((agtttt, indx) => {
                                                            let agentName = agtttt.first_name ? agtttt.first_name + ' ' + (agtttt.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agtttt.last_name : '') : (agtttt.name ? agtttt.name : '');
                                                            if (agentName.length > 40) {
                                                                agentName = agentName.substring(0, 35) + '....';
                                                            }
                                                            return <MenuItem key={indx} value={agtttt.id}>{agentName}</MenuItem>
                                                        })}
                                                    </Select>) : (<Select MenuProps={{ disableScrollLock: true }} labelId="agentId-outlined-label" id="agentId" name="agentId" value={this.agent.agentId} onChange={(e) => this.changeFilterHandle(e)} label="Agent">
                                                        {this.state.agentListArray.map((agtttt, indx) => {
                                                            if (this.agent.agentId === agtttt.id) {
                                                                let agentName = agtttt.first_name ? agtttt.first_name + ' ' + (agtttt.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agtttt.last_name : '') : (agtttt.name ? agtttt.name : '');
                                                                if (agentName.length > 40) {
                                                                    agentName = agentName.substring(0, 35) + '....';
                                                                }
                                                                return <MenuItem key={indx} value={agtttt.id}>{agentName}</MenuItem>
                                                            }
                                                        })}
                                                    </Select>
                                                    )}
                                                </FormControl>
                                            </div>

                                            {/* <label className="pl-3 pr-3 mb-0 text-14">Agent</label>
                                            <span>
                                                <select className="customArrow" name="agentId" id="agentId" onChange={(e) => this.changeFilterHandle(e)}>
                                                    <option value="online" key="online" selected={this.state.agentId === "online" ? "selected" : ""}>Online Agents</option>
                                                    <option value="" selected={this.state.agentId === "" ? "selected" : ""}>All Agents</option>
                                                    {this.state.agentListArray.map((agtttt, indx) => {
                                                        let agentName = agtttt.first_name ? agtttt.first_name + ' ' + (agtttt.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agtttt.last_name : '') : (agtttt.name ? agtttt.name : '');
                                                        if (agentName.length > 40) {
                                                            agentName = agentName.substring(0, 35) + '....';
                                                        }
                                                        return <option selected={this.state.agentId === agtttt.id ? "selected" : ""} key={indx} value={agtttt.id}>{agentName}</option>
                                                    })}
                                                </select>
                                            </span> */}
                                        </div>

                                        <div className="k-flex align-items-center ml-auto label-style">
                                            <div className={`k-flex position-relative mob-view ${dateShow}`}>
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="date_range-outlined-label">Date range</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true }} labelId="date_range-outlined-label" id="date_range" name="date_range" value={this.state.date_range ? this.state.date_range : "today"} onChange={(e) => this.changeFilterHandle(e)} label="Date range">
                                                            <MenuItem value="today">Today</MenuItem>
                                                            <MenuItem value="7_days">Last 7 days</MenuItem>
                                                            <MenuItem value="14_days">Last 14 days</MenuItem>
                                                            <MenuItem value="30_days">Last 30 days</MenuItem>
                                                            <MenuItem value="custom">Custom</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>

                                                {/* <label className="pl-3 pr-3 mb-0 text-14 pt-3">Date range</label>
                                                <span className="w-150 drop-view">
                                                    <select className="customArrow" name="date_range" id="date_range" onChange={(e) => this.changeFilterHandle(e)}>
                                                        <option value="today">Today</option>
                                                        <option value="7_days">Last 7 days</option>
                                                        <option value="14_days">Last 14 days</option>
                                                        <option value="30_days">Last 30 days</option>
                                                        <option value="custom">Custom</option>
                                                    </select>
                                                </span> */}
                                                {this.state.date_range === "custom" ? (<span className="pl-3 pr-3 mb-0 text-14 cursor-pointer pt-3 date-icon" onClick={this.showHideDatePicker}>
                                                    <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 58.382 58.382">
                                                        <g id="Group_4585" data-name="Group 4585">
                                                            <g id="Group_4584" data-name="Group 4584">
                                                                <path id="Path_2553" data-name="Path 2553" d="M51.541,4.561H48.8V0H44.243V4.561h-30.1V0H9.578V4.561H6.842A6.849,6.849,0,0,0,0,11.4V51.541a6.849,6.849,0,0,0,6.842,6.842h44.7a6.849,6.849,0,0,0,6.842-6.842V11.4A6.849,6.849,0,0,0,51.541,4.561Zm2.281,46.98a2.283,2.283,0,0,1-2.281,2.281H6.842a2.283,2.283,0,0,1-2.281-2.281v-30.1h49.26Zm0-34.665H4.561V11.4A2.283,2.283,0,0,1,6.842,9.122H9.578v4.561h4.561V9.122h30.1v4.561H48.8V9.122h2.737A2.283,2.283,0,0,1,53.821,11.4Z" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4587" data-name="Group 4587" transform="translate(8.74 26.218)">
                                                            <g id="Group_4586" data-name="Group 4586" transform="translate(0 0)">
                                                                <rect id="Rectangle_1782" data-name="Rectangle 1782" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4589" data-name="Group 4589" transform="translate(17.843 26.218)">
                                                            <g id="Group_4588" data-name="Group 4588" transform="translate(0 0)">
                                                                <rect id="Rectangle_1783" data-name="Rectangle 1783" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4591" data-name="Group 4591" transform="translate(26.947 26.218)">
                                                            <g id="Group_4590" data-name="Group 4590" transform="translate(0 0)">
                                                                <rect id="Rectangle_1784" data-name="Rectangle 1784" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4593" data-name="Group 4593" transform="translate(36.05 26.218)">
                                                            <g id="Group_4592" data-name="Group 4592" transform="translate(0 0)">
                                                                <rect id="Rectangle_1785" data-name="Rectangle 1785" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4595" data-name="Group 4595" transform="translate(45.154 26.218)">
                                                            <g id="Group_4594" data-name="Group 4594" transform="translate(0 0)">
                                                                <rect id="Rectangle_1786" data-name="Rectangle 1786" width="4.734" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4597" data-name="Group 4597" transform="translate(8.74 35.322)">
                                                            <g id="Group_4596" data-name="Group 4596" transform="translate(0 0)">
                                                                <rect id="Rectangle_1787" data-name="Rectangle 1787" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4599" data-name="Group 4599" transform="translate(17.843 35.322)">
                                                            <g id="Group_4598" data-name="Group 4598" transform="translate(0 0)">
                                                                <rect id="Rectangle_1788" data-name="Rectangle 1788" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4601" data-name="Group 4601" transform="translate(26.947 35.322)">
                                                            <g id="Group_4600" data-name="Group 4600" transform="translate(0 0)">
                                                                <rect id="Rectangle_1789" data-name="Rectangle 1789" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4603" data-name="Group 4603" transform="translate(36.05 35.322)">
                                                            <g id="Group_4602" data-name="Group 4602" transform="translate(0 0)">
                                                                <rect id="Rectangle_1790" data-name="Rectangle 1790" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4605" data-name="Group 4605" transform="translate(8.74 44.426)">
                                                            <g id="Group_4604" data-name="Group 4604" transform="translate(0 0)">
                                                                <rect id="Rectangle_1791" data-name="Rectangle 1791" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4607" data-name="Group 4607" transform="translate(17.843 44.426)">
                                                            <g id="Group_4606" data-name="Group 4606" transform="translate(0 0)">
                                                                <rect id="Rectangle_1792" data-name="Rectangle 1792" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4609" data-name="Group 4609" transform="translate(26.947 44.426)">
                                                            <g id="Group_4608" data-name="Group 4608" transform="translate(0 0)">
                                                                <rect id="Rectangle_1793" data-name="Rectangle 1793" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4611" data-name="Group 4611" transform="translate(36.05 44.426)">
                                                            <g id="Group_4610" data-name="Group 4610" transform="translate(0 0)">
                                                                <rect id="Rectangle_1794" data-name="Rectangle 1794" width="4.37" height="4.734" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_4613" data-name="Group 4613" transform="translate(45.154 35.322)">
                                                            <g id="Group_4612" data-name="Group 4612" transform="translate(0 0)">
                                                                <rect id="Rectangle_1795" data-name="Rectangle 1795" width="4.734" height="4.734" />
                                                            </g>
                                                        </g>
                                                    </svg>

                                                </span>) : (null)}
                                                <span className="analytics-datepicker" style={{ display: this.state.showDateRange ? "block" : "none" }}>
                                                    <DateRangePicker
                                                        staticRanges={[]}
                                                        inputRanges={[]}
                                                        onChange={this.onDatesSelect}
                                                        showSelectionPreview={true}
                                                        moveRangeOnFirstSelection={false}
                                                        months={2}
                                                        ranges={[{
                                                            startDate: this.state.fill_start_date,
                                                            endDate: this.state.fill_end_date,
                                                            key: 'selection'
                                                        }]}
                                                        direction="horizontal"
                                                    />
                                                    <div className="py-5 px-5 text-right">
                                                        <button className="btnBlueSettings mr-3" onClick={this.applyDateRange}>Apply</button>
                                                        <button className="btnBorderSettings mr-3" onClick={this.cancelDateRange}>Cancel</button>
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Filters End  */}

                            <div className="row">

                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-4">
                                    <div className="bg-white left-widgetsetings-box">
                                        <div id="chart" className="py-5 px-5">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="chartTitle cstm-drop">
                                                        <h5>Activity Breakdown</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="agent-table mobile-agent-table mobile-tranparent">
                                                        <table className="table table-borderless mb-0 liveCgatUserTable agentslistTbl mob-tbl-view">
                                                            <thead className="bg-darksecound white-text table-head-border-radius border-radius-table bg-lightother">
                                                                <tr>
                                                                    <th>Agent <span className="customArrow2" onClick={() => this.sortData("name")}></span></th>
                                                                    <th>Alias <span className="customArrow2" onClick={() => this.sortData("alias")}></span></th>
                                                                    <th>Email <span className="customArrow2" onClick={() => this.sortData("email")}></span></th>
                                                                    <th>Initiated Chats <span className="customArrow2" onClick={() => this.sortData("initiated")}></span></th>
                                                                    <th>Served Chats <span className="customArrow2" onClick={() => this.sortData("served")}></span></th>
                                                                    <th>Picked Chats <span className="customArrow2" onClick={() => this.sortData("picked")}></span></th>
                                                                    <th>Completed <span className="customArrow2 show-sort" onClick={() => this.sortData("completed")}></span></th>
                                                                    <th>Satisfaction <span className="customArrow2" onClick={() => this.sortData("satisfaction")}></span></th>
                                                                    <th>Feedbacks received <span className="customArrow2" onClick={() => this.sortData("feedback")}></span></th>
                                                                    <th>Response time <span className="customArrow2" onClick={() => this.sortData("response")}></span></th>
                                                                    <th>Duration <span className="customArrow2" onClick={() => this.sortData("duration")}></span></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="outline-1-black border-radius-table">
                                                                {list}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default AgentsNewleaderboard;