import React from 'react';
import { NavLink } from 'react-router-dom';

class LeftPanel extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.userRole = localStorage.getItem('userRole') ? localStorage.getItem('userRole') : 'Agent';
        this.state = {}
    }

    render() {
        return (
            <div className="sidebarnavChild" id="sidebarnavChild">
                <div className="tab-content h-100" role="tablist">
                    <div className="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar">
                                <div className="left-Menu-notlivechat py-2">
                                    <ul>
                                        {(this.userRole !== 'Agent') ? (<><li><NavLink to={"/" + this.agent.ownerIdentifire + "/chatanalytics"} activeClassName="active">Overview</NavLink></li>
                                           {this.agent.ownerId==="159523784363947423" ? <li><NavLink to={"/" + this.agent.ownerIdentifire + "/totalusers"} activeClassName="active">Total Users</NavLink></li> : null}
                                            <li><NavLink to={"/" + this.agent.ownerIdentifire + "/analytics"} activeClassName="active">Conversation</NavLink></li></>) : (null)}
                                        <li><NavLink to={"/" + this.agent.ownerIdentifire + "/agentsleaderboard"} activeClassName="active">Leaderboard</NavLink></li>
                                         {/* <li><NavLink to={"/" + this.agent.ownerIdentifire + "/agentsleaderboardnew"} activeClassName="active">New Leaderboard</NavLink></li>  */}
                                        <li><NavLink to={"/" + this.agent.ownerIdentifire + "/agentsreport"} activeClassName="active">Activity</NavLink></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;
