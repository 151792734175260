import React from 'react';
///import {mailUrl} from '../../config/mailFunctions';
import { GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';

class Unsubscribe extends React.Component {
    
    constructor() {
        super();
    }

    componentDidMount() {
        let token = this.props.match.params.email;
        //fetch(mailUrl+"/unsubscribe", {
        fetch(GATEWAY_URL + "/unsubscribe", {
            method: "post",
            body: [`token=${token}`],
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
        }).then(resp => {
            // console.log(resp);
        }).catch(err => {
            console.log(err);
        })
    }

    render() {
        return (
            <div className="bg-gradient-primary">
                <div className="container">
                    <div className="row justify-content-center login-content">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            <p>The email was sent to you because you created an account on Appy Pie Livechat. However as per your request we've unsubscribed you from all emails. In case you have any other query then feel free to email us at support@appypiechat.com</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Unsubscribe;