import React from 'react';
import LeftPanel from '../views/LeftPanel';
// import db from '../../../config/firebase';
import { getDateInUTC, getClientGeoDetail } from '../../livechat/components/Comman';
import { getCountries, getAvailablePhoneNumbers, buyNumber } from '../../livechat/components/Twilio';
import { activityLog } from '../../../services/service';

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../../firebase';
/* For Dropdown End */

const US_STATES = {
    'AL': 'Alabama',
    'AK': 'Alaska',
    'AS': 'American Samoa',
    'AZ': 'Arizona',
    'AR': 'Arkansas',
    'CA': 'California',
    'CO': 'Colorado',
    'CT': 'Connecticut',
    'DE': 'Delaware',
    'DC': 'District Of Columbia',
    'FM': 'Federated States Of Micronesia',
    'FL': 'Florida',
    'GA': 'Georgia',
    'GU': 'Guam',
    'HI': 'Hawaii',
    'ID': 'Idaho',
    'IL': 'Illinois',
    'IN': 'Indiana',
    'IA': 'Iowa',
    'KS': 'Kansas',
    'KY': 'Kentucky',
    'LA': 'Louisiana',
    'ME': 'Maine',
    'MH': 'Marshall Islands',
    'MD': 'Maryland',
    'MA': 'Massachusetts',
    'MI': 'Michigan',
    'MN': 'Minnesota',
    'MS': 'Mississippi',
    'MO': 'Missouri',
    'MT': 'Montana',
    'NE': 'Nebraska',
    'NV': 'Nevada',
    'NH': 'New Hampshire',
    'NJ': 'New Jersey',
    'NM': 'New Mexico',
    'NY': 'New York',
    'NC': 'North Carolina',
    'ND': 'North Dakota',
    'MP': 'Northern Mariana Islands',
    'OH': 'Ohio',
    'OK': 'Oklahoma',
    'OR': 'Oregon',
    'PW': 'Palau',
    'PA': 'Pennsylvania',
    'PR': 'Puerto Rico',
    'RI': 'Rhode Island',
    'SC': 'South Carolina',
    'SD': 'South Dakota',
    'TN': 'Tennessee',
    'TX': 'Texas',
    'UT': 'Utah',
    'VT': 'Vermont',
    'VI': 'Virgin Islands',
    'VA': 'Virginia',
    'WA': 'Washington',
    'WV': 'West Virginia',
    'WI': 'Wisconsin',
    'WY': 'Wyoming'
};

let region_list = [];
region_list.push({region_code :"", region_name : "--- Any ---"});
for (const key in US_STATES) {
	if (US_STATES.hasOwnProperty(key)) {
		const c = US_STATES[key];
		var temp = {};
		temp['region_code'] = key;
		temp['region_name'] = c.trim();
		region_list.push(temp);
	}
}

class PhoneNumbers extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if(this.agent.roleType!=='Owner') {
            window.location.href = "/"+this.agent.ownerIdentifire;
        }
        this.state = {
            loader: false,
            countryList : [],
            numberList: [],
            showConfirmWindow:false,
            selectedNumber: false,
            selectedCountry: "US",
            selectedRegion:"",
            selectedSType: "phone",
            search_text: "",
            error_message : null,
            geoDetail : {}
        }
    }

    componentDidMount = async () => {
        if(this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        this.setState({
            loader: true
        });
        let allCountries = await getCountries();
        let geodetail = await getClientGeoDetail();
        var srchData = null;
        if(geodetail) {
            srchData = { countryCode : geodetail.country_code, regionCode : geodetail.region_code };
        }
        let allNumbers = await getAvailablePhoneNumbers(srchData);
        this.setState({
            loader: false,
            countryList : (allCountries === "error") ? [] : allCountries,
            numberList: (allNumbers === "error") ? [] : allNumbers,
            geoDetail: geodetail ? geodetail : {}
        });
        activityLog('Opened phone numbers settings');
    }

    confirmBuy = (numberObj) => {
        if(this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        if(this.state.showConfirmWindow) {
            this.cancelBuy();
        } else {
            this.setState({
                showConfirmWindow: true,
                selectedNumber : numberObj
            });
        }
    }

    cancelBuy = () => {
        this.setState({
            showConfirmWindow: false,
            selectedNumber : false
        });
    }

    buyNow = async () => {
        if(this.agent.roleType !== "Owner" || this.agent.phone_call === 0 || !this.state.selectedNumber) {
            return false;
        }
        this.setState({
            loader: true
        });
        const selNumberObj = this.state.selectedNumber;
        var res = await buyNumber(selNumberObj);
        if(res.status === "success") {
            let phoneData = res.data;
            if (phoneData.sid && phoneData.phoneNumber) {
                if(phoneData.phoneNumber === "+15005550006") { // testing number
                    phoneData.phoneNumber = '+1'+ Math.ceil(Math.random() * 9999999999);
                }
                var serverTimestamp = await getDateInUTC();
                var docId = "PN" + serverTimestamp + Math.ceil(Math.random() * 999999);
                firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('phone_numbers').doc(docId).set({
                    addedon: serverTimestamp,
                    country_iso: selNumberObj.isoCountry,
                    phone: phoneData.phoneNumber,
                    capabilities: phoneData.capabilities,
                    friendlyName: phoneData.friendlyName,
                    sid: phoneData.sid,
                    locality : selNumberObj.locality,
                    postal_code: selNumberObj.postalCode,
                    region: selNumberObj.region,
                    agent_id : 0,
                    org_id : this.agent.ownerId
                });
                return this.props.history.push("/"+this.agent.ownerIdentifire+"/phone-numbers");
            } else {
                this.setState({
                    loader: false,
                    showConfirmWindow: false,
                    selectedNumber : false,
                    error_message : "Something went wrong!"
                });
            }
        } else {
            this.setState({
                loader: false,
                showConfirmWindow: false,
                selectedNumber : false,
                error_message : res.data
            });
        }
    }

    /* Search functions start */

    changeSearchType = (e) => {
        this.setState({
            selectedSType: e.target.value
        });
    }

    changeCountry = (e) => {
        this.setState({
            selectedCountry: e.target.value
        });
    }

    changeRegion = (e) => {
        this.setState({
            selectedRegion: e.target.value
        });
    }

    changeNumberHandle = (e) => {
        this.setState({
            search_text: e.target.value
        })
    }

    searchNumbers = async () => {
        if(this.agent.roleType !== "Owner" || this.agent.phone_call === 0) {
            return false;
        }
        this.setState({
            loader: true
        });
        let srchObject = {
            countryCode : this.state.selectedCountry,
            searchType : this.state.selectedSType,
            search_text : this.state.search_text,
            regionCode : this.state.selectedRegion
        }
        let allNumbers = await getAvailablePhoneNumbers(srchObject);
        this.setState({
            loader: false,
            numberList: (allNumbers === "error") ? [] : allNumbers
        });
    }

    /* Search functions end */
    userFilterMobile = () => {
        var responsiveTbaleHeaderFilter = document.getElementById('resposnive-table-class');
        responsiveTbaleHeaderFilter.classList.toggle('responsiveTbaleHeaderFilter')
    }
    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        var selRegion = this.state.selectedRegion? this.state.selectedRegion : (Object.keys(this.state.geoDetail).length > 0) ? this.state.geoDetail.region_code : "";
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                {this.state.showConfirmWindow ? (<div className="popupInstallLivechat popupLivechat" >
                    <div className="popupBody pb-5 px-5 text-center">
                        <div className="py-5 feilds-instruction">
                            <span className="congratilation-circle my-2">
                                <img alt="" src={require('../../../assets/img/icon/info.png')} />
                            </span>
                        </div>
                        <div className="pb-5 feilds-instruction">
                            <h6 className="card-title mb-0">Are you sure? You want to add this number.</h6>
                        </div>
                        <div>
                            <button className="btnBlue" onClick={this.buyNow}>Add Now</button> &nbsp;&nbsp;
                            <button className="btnBlue" onClick={this.cancelBuy}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                    <h3 className="heding-text">Buy a Number</h3>
                    <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="main-heading main-heading-title py-5"></div>
                        <div className="body-section bg-white px-5 py-5 mob-mt-4">
                            <div className="agent-section " id="resposnive-table-class">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="mobile-menu text-right mb-5">
                                            <button className="responsive-btn " onClick={this.userFilterMobile}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="20.899" viewBox="0 0 22.674 20.899">
                                                    <path id="Path_1489" data-name="Path 1489" d="M22.608,10.077a.687.687,0,0,0-.622-.394H.687a.687.687,0,0,0-.53,1.124l8.188,9.931V29.9a.687.687,0,0,0,.992.616l4.6-2.28a.687.687,0,0,0,.382-.615l.009-6.878,8.188-9.931A.687.687,0,0,0,22.608,10.077Zm-9.5,9.977a.687.687,0,0,0-.157.436l-.009,6.7-3.226,1.6v-8.3a.687.687,0,0,0-.157-.437l-7.418-9H20.529Z" transform="translate(0 -9.683)" fill="#A2ABD1" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="agent-table-header table-header row mb-8 user-table-main-header input-border phone-fltr">

                                            <div className="pr-0 user-table-live mr-5">
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="country_code-select-outlined-label">Country</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true}} labelId="country_code-select-outlined-label" id="country_code-select-outlined" name="country_code" value={this.state.selectedCountry?this.state.selectedCountry:""} onChange={this.changeCountry} label="Country">
                                                            <MenuItem value="">Country</MenuItem>
                                                            {this.state.countryList.map((cVal, idx) => {
                                                                return <MenuItem key={cVal.countryCode} value={cVal.countryCode}>{cVal.country}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div className="wrapperInfo">
                                                    <select className="customArrow" name="country_code" value={this.state.selectedCountry} onChange={this.changeCountry}>
                                                        {this.state.countryList.map((cVal, idx) => {
                                                            return <option key={cVal.countryCode} value={cVal.countryCode}>{cVal.country}</option>
                                                        })}
                                                    </select>
                                                </div> */}
                                            </div>

                                            <div className="pr-0 user-table-live mr-5">
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="region-select-outlined-label">Region</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true}} labelId="region-select-outlined-label" id="region" name="region" value={selRegion} onChange={this.changeRegion} label="Region">
                                                            {region_list.map((cVal, idx) => {
                                                                return <MenuItem key={cVal.region_code} value={cVal.region_code}>{cVal.region_name}</MenuItem>
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div className="wrapperInfo">
                                                    <select className="customArrow" id="region" name="region" value={selRegion} onChange={this.changeRegion}>
                                                        {region_list.map((cVal, idx) => {
                                                            return <option key={cVal.region_code} value={cVal.region_code}>{cVal.region_name}</option>
                                                        })}
                                                    </select>
                                                </div> */}
                                            </div>

                                            <div className="pr-0 user-table-live mr-5">
                                                <div className="material-select">
                                                    <FormControl variant="outlined" className="material-outlined w-150">
                                                        <InputLabel id="search_type-select-outlined-label">Search Type</InputLabel>
                                                        <Select MenuProps={{ disableScrollLock: true}} labelId="search_type-select-outlined-label" id="search_type" name="search_type" value={this.state.selectedSType} onChange={this.changeSearchType} label="Search Type">
                                                            <MenuItem value="phone">Number</MenuItem>
                                                            <MenuItem value="locality">Locality</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                {/* <div className="wrapperInfo">
                                                    <select className="customArrow" name="search_type" value={this.state.selectedSType} onChange={this.changeSearchType}>
                                                         <option value="phone">Number</option>
                                                         <option value="locality">Locality</option>
                                                    </select>
                                                </div> */}
                                            </div>

                                            <div className="input-group pr-0 user-table-live material-input mr-5">
                                                <TextField className="form-control w-100" id="search_text" label="Optional" variant="outlined" name="search_text" value={this.state.search_text} onChange={this.changeNumberHandle} />
                                                {/* <input type="text" className="form-control form-control-lg" placeholder="Optional" name="search_text" value={this.state.search_text} onChange={this.changeNumberHandle} /> */}
                                            </div>
                                            
                                            <div className="srch-btn">
                                                <div className="input-group-append">
                                                    <button className="btnBlueSettings" onClick={this.searchNumbers}>Search</button> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {this.state.error_message ? (<div className="error">{this.state.error_message}</div>) : (null)}

                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="agent-table">
                                            <table className="table table-borderless mb-0 liveCgatUserTable">
                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                    <tr>
                                                        <th>PHONE NUMBER</th>
                                                        <th>COUNTRY</th>
                                                        <th>CAPABILITIES</th>
                                                        <th>&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="outline-1-black border-radius-table">
                                                    {this.state.numberList.map((v, i) => {
                                                        var capblty = "";
                                                        if(v.capabilities) {
                                                            if(v.capabilities.MMS) {
                                                                capblty += "MMS,"
                                                            }
                                                            if(v.capabilities.SMS) {
                                                                capblty += "SMS,"
                                                            }
                                                            if(v.capabilities.voice) {
                                                                capblty += "Voice"
                                                            }
                                                        }
                                                        return <tr id={v.phoneNumber} key={i}>
                                                            <td>{v.phoneNumber}</td>
                                                            <td>{v.locality + ", " + v.region}</td>
                                                            <td>{capblty}</td>
                                                            <td><button className="btnBlueSettings mr-2 py-2 px-6" onClick={() => this.confirmBuy(v)}>Add</button></td>
                                                        </tr>
                                                    })}
                                                    {this.state.numberList.length === 0 ? (<tr><td colSpan="4">No Record Found</td></tr>) : (null)}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default PhoneNumbers;