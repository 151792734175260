import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import TextField from '@material-ui/core/TextField';
import { addCannedText, getCannedText, getAllAwsAgents, getOwnerDetails, deleteCannedText, editCannedText } from '../../services/service';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from '../../config/siteConfig';
import Pagination from "react-js-pagination";
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';
import firebaseServices from '../../firebase';

const dateFormat = "X";


class CannedText extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        // commented for 1494
        // if(this.agent.canned_response !== 1) {
        //     const orgnizationAlias = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
        //     window.location.href = "/"+orgnizationAlias;
        // }
        this.state = {
            loader: true,
            canned_text: '',
            canned_hashtag: '',
            applicable_on: 1,
            cannedList: [],
            departments: [],
            agentRole: '',
            agentRoleType: '',
            allAgents: [],
            currPage: 1,
            searchText: '',
            ownerData: {},
            deleteId: '',
            showDeletePoup: false,
            displayEditId: '',
            totalRecords: 0,
            totalPages: 0,
            limit: 20,
            canned_text_error: '',
            canned_hashtag_error: '',
            applicable_on_error: '',
            agentId: '',
            agentDateFormat: '',
            canned_text_guide: true,
            canned_text_guide_step: 0,
            payment_status: true
        }
    }

    async componentDidMount() {
        await this.getAgentRole();
        let agentCheck = true;
        if ((this.state.agentRole === 'Owner' || this.state.agentRole === 'Admin') && this.state.agentRoleType === 'default') {
            agentCheck = false;
        }
        const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
        const agentId = this.agent.agentId ? this.agent.agentId : false;
        let promise1 = new Promise((res, rej) => {
            getCannedText(ownerId, agentId, agentCheck, '', 1, this.state.limit).then((resp) => {
                res(resp);
            })
        })

        if(ownerId) {
            getOwnerSnapShot(ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        let promise2 = new Promise((res, rej) => {
            getAllAwsAgents().then((resp) => {
                res(resp);
            })
        })

        const companyIdentifier = this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire;
        let promise3 = new Promise((res, rej) => {
            getOwnerDetails(companyIdentifier).then((resp) => {
                res(resp);
            })
        })

        Promise.all([promise1, promise2, promise3]).then((results) => {
            const cannedResp = results[0] ? results[0] : [];
            const agentResp = results[1] ? results[1] : [];
            const ownerData = results[2] ? results[2] : [];
            let data = [];
            let totalRecords = 0;
            let totalPages = 0;
            console.log(cannedResp);
            if (cannedResp.status === 200) {
                data = cannedResp.data;
                totalRecords = cannedResp.totalRecords;
                totalPages = Math.ceil(totalRecords / this.state.limit);
            }

            this.setState({
                cannedList: data,
                agentData: agentResp,
                ownerData: ownerData,
                loader: false,
                totalRecords,
                totalPages,
                agentId,
                agentDateFormat: this.agent.timezone ? this.agent.timezone : ''
            })
        })
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.getCannedTextGuide(userDetails);
    }

    componentDidUpdate() {

    }

    getAgentRole = () => {
        return new Promise((res, rej) => {
            const userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
            const ownerId = userDetails.ownerId ? userDetails.ownerId : false;
            const roleId = userDetails.role ? userDetails.role : false;
            if (ownerId && roleId) {
                firebaseServices.db.collection('users').doc(ownerId).collection('roles').doc(roleId).get().then((docs) => {
                    if (docs.exists) {
                        const roleData = docs.data();
                        this.setState({
                            agentRole: roleData.name,
                            agentRoleType: roleData.type ? roleData.type : ''
                        }, () => {
                            res(true);
                        })
                    } else {
                        res(false);
                    }
                }).catch((err) => {
                    console.log('error at line 53', err);
                    res(false);
                })
            } else {
                res(false);
            }
        })
    }

    applyFilter = () => {
        this.setState({
            currPage: 1
        }, () => {
            this.getValues();
        })
    }

    getValues = async () => {
        this.setState({
            loader: true
        })
        let agentCheck = true;
        if ((this.state.agentRole === 'Owner' || this.state.agentRole === 'Admin') && this.state.agentRoleType === 'default') {
            agentCheck = false;
        }
        const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
        const agentId = this.agent.agentId ? this.agent.agentId : false;
        const searchText = this.state.searchText ? this.state.searchText : '';
        const currentPage = this.state.currPage ? this.state.currPage : 1;
        const cannedTextList = await getCannedText(ownerId, agentId, agentCheck, searchText, currentPage, this.state.limit);
        let data = [];
        let totalRecords = 0;
        let totalPages = 0;
        if (cannedTextList.status === 200) {
            data = cannedTextList.data;
            totalRecords = cannedTextList.totalRecords;
            totalPages = Math.ceil(totalRecords / this.state.limit);
        }
        this.setState({
            cannedList: data,
            loader: false,
            totalPages,
            totalRecords
        })
    }


    handleChange = (e) => {
        let target = e.target;
        let errorState = target.name + '_error';
        this.setState({
            [target.name]: target.value,
            [errorState]: ''
        })
    }

    // Date Functions Start
    timeZoneFormat = (date) => {
        date = Math.floor(date / 1000);
        var todayUTCDate;
        if (this.state.agentDateFormat === '' || this.state.agentDateFormat === undefined) {
            todayUTCDate = moment.tz(date, "X", 'UTC').tz(moment.tz.guess(true)).format('DD/MM/YYYY hh:mm A');
        } else {
            todayUTCDate = moment.tz(date, "X", 'UTC').tz(this.state.agentDateFormat).format('DD/MM/YYYY hh:mm A');
        }
        // todayUTCDate = parseInt(todayUTCDate);
        return todayUTCDate;
    }
    // Date Functions End

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    checkSpecialChar = (string) => {
        var iChars = "!@#$%^&*()+=-[]\\\';,./{}|\":<>?";
        for (var i = 0; i < string.length; i++) {
            if (iChars.indexOf(string.charAt(i)) != -1) {
                return false;
            }
        }
        return true;
    }

    validateForm = () => {
        let canned_text_error = '';
        let canned_hashtag_error = '';
        let returnValue = true;

        const { canned_text, canned_hashtag } = this.state;
        if (canned_text.trim() === '') {
            returnValue = false;
            canned_text_error = 'Please enter Canned Text'
        }
        if (canned_hashtag.trim() === '') {
            returnValue = false;
            canned_hashtag_error = 'Please enter Canned Shortcut'
        } else if (!this.checkSpecialChar(canned_hashtag.trim())) {
            returnValue = false;
            canned_hashtag_error = "Your shortcut has special characters. These are not allowed. Please remove them and try again."
        }
        this.setState({
            canned_text_error,
            canned_hashtag_error
        })

        return returnValue;
    }

    addText = async () => {
        const valid = this.validateForm();
        if (valid) {
            this.setState({
                loader: true
            })
            let departments;
            const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
            const agentId = this.agent.agentId ? this.agent.agentId : false;
            const userType = this.agent.roleType === 'Owner' ? 'owner' : 'agent';
            const cannedResponse = await addCannedText(ownerId, agentId, this.state.canned_text, this.state.canned_hashtag, this.state.applicable_on, userType);
            if (cannedResponse.success === true) {
                setTimeout(() => {
                    this.getValues();
                    this.setState({
                        canned_text: '',
                        canned_hashtag: '',
                        applicable_on: 1,
                        departments: departments
                    })
                    toast.success('Canned text has been added successfully!');
                }, 1000)
            } else {
                this.setState({
                    loader: false
                })
                toast.error('Something went wrong, Please try again after some time');
            }
        }
    }

    getAgentDepartment = () => {
        return new Promise((res, rej) => {
            const agentId = this.agent.agentId ? this.agent.agentId : false;
            const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
            if (agentId && ownerId) {
                firebaseServices.db.collection("users").doc(ownerId).collection('agents').doc(agentId).get().then((docs) => {
                    if (docs.exists) {
                        const agentData = docs.data();
                        const departments = agentData.departments;
                        res(departments);
                    } else {
                        res([]);
                    }
                }).catch((err) => {
                    console.log('error at 104', err);
                    res([]);
                })
            } else {
                res([]);
            }
        })
    }

    deleteText = (id) => {
        this.setState({
            deleteId: id,
            showDeletePoup: true
        })
    }

    processDelete = () => {
        this.setState({
            loader: true
        })
        const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
        const docId = this.state.deleteId ? this.state.deleteId : false;
        deleteCannedText(ownerId, docId).then((resp) => {
            let objects = {
                loader: false,
                deleteId: '',
                showDeletePoup: false
            }
            setTimeout(() => {
                this.getValues();
                this.setState(objects);
                toast.success('Canned text has been deleted!');
            }, 1000)
        }).catch((err) => {
            console.log(err);
            this.setState({
                loader: false
            })
            toast.error('Something went wrong, please try again after some time!');
        })
    }

    showEditDiv = (canned) => {
        const docId = canned.objectId;
        this.setState({
            ['text_' + docId]: canned.canned_text,
            ['applicable_on_' + docId]: canned.applicable_on,
            ['hashtag_' + docId]: canned.canned_hashtag,
            displayEditId: docId
        })
    }

    editText = ({ objectId, addedon }) => {
        const cannedText = this.state['text_' + objectId] ? this.state['text_' + objectId] : '';
        const cannedHashtag = this.state['hashtag_' + objectId] ? this.state['hashtag_' + objectId] : ''
        let error = false;
        let objects = {};
        if (cannedText === '') {
            objects['text_' + objectId + '_error'] = 'Please enter Canned Text';
            error = true
        }
        if (cannedHashtag === '') {
            objects['hashtag_' + objectId + '_error'] = 'Please enter Canned Shortcut';
            error = true
        } else if (!this.checkSpecialChar(cannedHashtag.trim())) {
            objects['hashtag_' + objectId + '_error'] = "Your shortcut has special characters. These are not allowed. Please remove them and try again.";
            error = true;
        }

        if (error === true) {
            this.setState(objects);
        } else {
            this.setState({
                loader: true
            })
            const applicableOn = this.state['applicable_on_' + objectId];
            const ownerId = this.agent.ownerId ? this.agent.ownerId : false;
            const agentId = this.agent.agentId ? this.agent.agentId : false;
            const userType = this.agent.roleType === 'Owner' ? 'owner' : 'agent';
            editCannedText(ownerId, agentId, cannedText, cannedHashtag, applicableOn, userType, objectId, addedon).then((result) => {
                // console.log(result);
                // if(result.success === true) {
                let objects = {
                    loader: false,
                    displayEditId: ''
                }
                setTimeout(() => {
                    this.getValues();
                    this.setState(objects);
                    toast.success('Canned text has been updated!');
                }, 1500)
                // }
            }).catch((err) => {
                this.setState({
                    loader: false
                })
                toast.error('Something went wrong, please try again after some time!');
                console.log(err);
            })
        }
    }

    handlePageChange = (pageno) => {
        this.setState({ currPage: pageno }, () => {
            this.getValues();
        });
    }

    endGuideTour = (docId, context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
            status: true
        })
        this.setState({
            [context]: true
        })
    }

    guidenextStep = (docId, step, context) => {
        firebaseServices.db.collection("users").doc(this.agent.ownerId).collection("onboarding_guide").doc(docId).update({
            step: step,
        })
        this.setState({
            [context]: step
        })
    }

    getCannedTextGuide = (agent2) => {
        firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("canned_text").get().then((getResult) => {
            // console.log('getResult>>>  ', getResult);
            if (getResult.exists) {
                let _dataResult = getResult.data();
                console.log('_dataResult >>> ', _dataResult);
                this.setState({
                    canned_text_guide: _dataResult.status,
                    canned_text_guide_step: _dataResult.step ? _dataResult.step : 0
                });
            }
            else {
                console.log('_dataResult >>> Doesnt exists, created');
                firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("canned_text").set({
                    status: false,
                    step: 0
                });
                this.setState({
                    canned_text_guide: false,
                    canned_text_guide_step: 0
                });
            }
        })
    }

    render() {
        let mainClass = "page-wrapper sidebar-collapse";
        if (localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent') {
            // mainClass = "page-wrapper";
        }

        let showLeftPanel = localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent' ? true : true;
        let onBoardingFlagStep5Counter = 0;
        const addBtnClass = this.state.canned_text && this.state.canned_hashtag ? 'btnBlue' : 'btnRestore';

        return (
            <>
                <ReactTooltip />
                {this.state.canned_text_guide === false && this.state.canned_text_guide_step < 6 && this.state.payment_status? (<div className="overlay-guide"></div>) : (null)}
                {showLeftPanel === true ? (<LeftPanel />) : (null)}
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                    <h3 className="heding-text">Settings</h3>
                    <div className="text-center k-flex align-items-center ml-auto">
                        <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                        <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.getValues}>Cancel</button>
                    </div>
                    <div className="mobile-menu" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
             {this.state.payment_status?   <div className={mainClass} data-mobile-height=""> {/* page-wrapper sidebar-collapse */}
                    <div className="main-wrapper container-fluid">
                        <div className="chatform-Preview">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                        <div className="main-heading main-heading-title py-5">
                                            <h3 className="heding-text">Canned Text <span className="info-icon">
                                                <img src={require('../../assets/img/icon/info.png')} data-tip="Canned text is a section where all your pre built messages are stored, to be sent out as instant responses to your visitor's queries" />
                                            </span></h3>
                                            {/* <p className="small">Type / (forwardslash) to open up texts that are visible to 'Everyone' and \ (backwardslash) for texts that are visible to only you i.e 'me' on your chat section to open up the canned texts.</p> */}
                                            <p className="small">Type / (forward slash) on your chat section to open up the canned text messages</p>
                                        </div>
                                        <div className="bg-white left-widgetsetings-box mb-foter-Fixed">
                                            <div className="setting-section idle-section pb-1">
                                                <div className="mx-8 mob-mx-4 py-5 addTextArea">
                                                    <div className="d-flex">
                                                        <div className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 0) ? "addArea step1 position-top" : "addArea step1"}>
                                                            <label className="upper-div">Text<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                            {this.state.canned_text_guide === false && this.state.canned_text_guide_step === 0 ? (<div className="tour_guide">
                                                                {this.state.cannedList.length > 0 ? (<p className="title">Step 1 of 6</p>) : (<p className="title">Step 1 of 5</p>)}
                                                                <p className="text-center">Write your text here.</p>
                                                                <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 1, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                            </div>) : (null)}
                                                            <div className="input-group material-input mb-5">
                                                                <TextField className="form-control w-100" rows={3} label="Add text here" name="canned_text" variant="outlined" onChange={this.handleChange} value={this.state.canned_text} multiline />
                                                                {this.state.canned_text_error ? (<p className="error">{this.state.canned_text_error}</p>) : (null)}
                                                            </div>
                                                        </div>
                                                        <div className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 1) ? "addArea step2 position-top" : "addArea step2"}>
                                                            <label className="upper-div">Shortcut<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                            {this.state.canned_text_guide === false && this.state.canned_text_guide_step === 1 ? (<div className="tour_guide">
                                                                {this.state.cannedList.length > 0 ? (<p className="title">Step 2 of 6</p>) : (<p className="title">Step 2 of 5</p>)}
                                                                <p className="text-center">Write your shortcut here.</p>
                                                                <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 2, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                            </div>) : (null)}
                                                            <div className="input-group material-input mb-5">
                                                                <TextField className="form-control w-100" label="Shortcut" name="canned_hashtag" variant="outlined" value={this.state.canned_hashtag} onChange={this.handleChange} />
                                                                {this.state.canned_hashtag_error ? (<p className="error">{this.state.canned_hashtag_error}</p>) : (null)}
                                                            </div>
                                                        </div>
                                                        <div className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 2) ? "addArea step3 position-top" : "addArea step3"}>
                                                            <label className="upper-div">Applicable on<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                            {this.state.canned_text_guide === false && this.state.canned_text_guide_step === 2 ? (<div className="tour_guide">
                                                                {this.state.cannedList.length > 0 ? (<p className="title">Step 3 of 6</p>) : (<p className="title">Step 3 of 5</p>)}
                                                                <p className="text-center">Select the agents for which this canned text will be available for.</p>
                                                                <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 3, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                            </div>) : (null)}
                                                            <div className="d-flex mb-5">
                                                                <div className="settings-feld k-flex align-items-center my-5">
                                                                    <span className="main-checkbox-all">
                                                                        <label className="containerRadio containerafterchecked">
                                                                            <input type="radio" value="0" name="show_canned_chat" checked={this.state.applicable_on === 0 ? true : false} onChange={() => { this.setState({ applicable_on: 0 }) }} />
                                                                            <span className="radioCheckmark"></span>
                                                                        </label>
                                                                    </span>
                                                                    <h6 className="card-title mb-0 pr-3">Everyone</h6>
                                                                </div>
                                                                <div className="settings-feld k-flex align-items-center my-4">
                                                                    <span className="main-checkbox-all">
                                                                        <label className="containerRadio containerafterchecked">
                                                                            <input type="radio" value="1" name="show_canned_chat" checked={this.state.applicable_on === 1 ? true : false} onChange={() => { this.setState({ applicable_on: 1 }) }} />
                                                                            <span className="radioCheckmark"></span>
                                                                        </label>
                                                                    </span>
                                                                    <h6 className="card-title mb-0 pr-3">Me</h6>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <button className={addBtnClass} onClick={this.addText}>Add text</button>
                                                    <div className="input-group mt-8">
                                                        <div className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 3) ? "search-feilds-history k-flex align-items-center mob-drop no-border position-top" : "search-feilds-history k-flex align-items-center mob-drop no-border"}>
                                                            {this.state.canned_text_guide === false && this.state.canned_text_guide_step === 3 ? (<div className="tour_guide">
                                                                {this.state.cannedList.length > 0 ? (<p className="title">Step 4 of 6</p>) : (<p className="title">Step 4 of 5</p>)}
                                                                <p className="text-center">You can search for your canned text here.</p>
                                                                <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 4, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                            </div>) : (null)}
                                                            <div className="select-search-drpdown w-100P position-relative input-group visitore-search material-input upper-div">
                                                                <TextField className="form-control" id="searchvisitor" label="Search" variant="outlined" name="searchText" onChange={this.handleChange} />
                                                                <div className="input-group-append agent">
                                                                    <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="button" onClick={this.applyFilter}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                                            <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#fff" />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="agent-table mt-8">
                                                        <table className="table table-borderless mb-0 liveCgatUserTable mob-tbl-view position-relative">
                                                            <thead className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4
                                                                && this.state.cannedList.length === 0) ?
                                                                "bg-darksecound white-text table-head-border-radius border-radius-table position-top" :
                                                                "bg-darksecound white-text table-head-border-radius border-radius-table"}>
                                                                <tr>
                                                                    <th>Text</th>
                                                                    <th>Shortcut</th>
                                                                    <th width="150">Applicable on </th>
                                                                    <th width="150">Created by</th>
                                                                    <th>Date</th>
                                                                    <th width="100">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.cannedList.length > 0 ? (null) : (<tr>
                                                                    <td>
                                                                        No record Found
                                                                        {this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 ? (
                                                                            <div className="tour_guide">
                                                                                <p className="title">Step 5 of 5</p>
                                                                                <p className="text-center">You can view and edit your canned response here.</p>
                                                                                <button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                                            </div>) : (null)}
                                                                    </td>
                                                                </tr>)}
                                                                {this.state.cannedList.map((canned, index) => {
                                                                    let applicableOn = '';
                                                                    const trId = 'list_' + canned.objectId;
                                                                    if (parseInt(canned.applicable_on) === 1) {
                                                                        applicableOn = 'Me'
                                                                    } else {
                                                                        applicableOn = 'Everyone'
                                                                    }
                                                                    const agent = this.state.agentData.filter((agents) => agents.subid === canned.agentId);
                                                                    let createBy = '';
                                                                    if (agent.length > 0) {
                                                                        createBy = agent[0].alias && agent[0].alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agent[0].alias : (agent[0].last_name && agent[0].last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agent[0].first_name + ' ' + agent[0].last_name : agent[0].first_name);
                                                                    } else {
                                                                        const ownerData = this.state.ownerData;
                                                                        createBy = ownerData && ownerData.data && ownerData.data.alias && ownerData.data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerData.data.alias : ownerData.data.last_name && ownerData.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerData.data.first_name + ' ' + ownerData.data.last_name : ownerData.data.first_name
                                                                    }
                                                                    const edithash = this.state['hashtag_' + canned.objectId] !== undefined ? this.state['hashtag_' + canned.objectId] : canned.canned_hashtag;

                                                                    const editText = typeof this.state['text_' + canned.objectId] !== undefined ? this.state['text_' + canned.objectId] : canned.canned_text;
                                                                    const editAvailableOn = typeof this.state['applicable_on_' + canned.objectId] !== undefined ? this.state['applicable_on_' + canned.objectId] : canned.applicable_on;
                                                                    const editTextError = this.state['text_' + canned.objectId + '_error'] ? this.state['text_' + canned.objectId + '_error'] : '';
                                                                    const editHashError = this.state['hashtag_' + canned.objectId + '_error'] ? this.state['hashtag_' + canned.objectId + '_error'] : '';
                                                                    const addedDate = canned.addedon ? this.timeZoneFormat(canned.addedon) : '-';
                                                                    onBoardingFlagStep5Counter += 1;
                                                                    var tour_guidePOS = "";
                                                                    if (this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 && onBoardingFlagStep5Counter === 1) {
                                                                        tour_guidePOS = 'first'
                                                                    }
                                                                    // else if (this.state.canned_text_guide === false && this.state.canned_text_guide_step === 5 && onBoardingFlagStep5Counter === 1) {
                                                                    //     console.log('testing data2', tour_guidePOS)
                                                                    //     tour_guidePOS = 'second'
                                                                    // }
                                                                    return <React.Fragment key={index}>
                                                                        <tr id={trId} className={this.state.displayEditId === canned.objectId ? 'd-none' : ''}>
                                                                            <td>{canned.canned_text}</td>
                                                                            <td>{canned.canned_hashtag}</td>
                                                                            <td>{applicableOn}</td>
                                                                            <td>{createBy}</td>
                                                                            <td>{addedDate}</td>
                                                                            <td className={`${tour_guidePOS}`}>
                                                                                {this.state.agentId === canned.agentId ? (<span className={(this.state.canned_text_guide === false && (this.state.canned_text_guide_step === 4 || this.state.canned_text_guide_step === 5) && onBoardingFlagStep5Counter === 1) ? "d-flex position-top posED" : "d-flex"}>
                                                                                    {(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 && onBoardingFlagStep5Counter === 1) ? (
                                                                                        <div className="tour_guide">
                                                                                            <p className="title">Step 5 of 6</p>
                                                                                            <p className="text-center">You can edit your canned response here.</p>
                                                                                            <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 5, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                                                        </div>) : ((this.state.canned_text_guide === false && this.state.canned_text_guide_step === 5 && onBoardingFlagStep5Counter === 1) ? (
                                                                                            <div className="tour_guide">
                                                                                                <p className="title">Step 6 of 6</p>
                                                                                                <p className="text-center">You can delete your canned response here.</p>
                                                                                                <button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                                                            </div>) : (null))}
                                                                                    <img className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 && onBoardingFlagStep5Counter === 1) ? "mr-5 cursor-pointer upper-div" : "mr-5 cursor-pointer"} src={require("../../assets/img/icon/editNew.svg")} onClick={() => this.showEditDiv(canned)} />
                                                                                    <img className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 5 && onBoardingFlagStep5Counter === 1) ? "cursor-pointer upper-div" : "cursor-pointer"} src={require("../../assets/img/icon/deleteNew.svg")} onClick={() => this.deleteText(canned.objectId)} />
                                                                                </span>) : (
                                                                                    <>
                                                                                        {(this.state.canned_text_guide === false && (this.state.canned_text_guide_step === 4 || this.state.canned_text_guide_step === 5) && onBoardingFlagStep5Counter === 1) ?
                                                                                            (<>
                                                                                                <span className={(this.state.canned_text_guide === false && (this.state.canned_text_guide_step === 4 || this.state.canned_text_guide_step === 5) && onBoardingFlagStep5Counter === 1) ? "d-flex position-top posED" : "d-flex"}>
                                                                                                    {(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 && onBoardingFlagStep5Counter === 1) ? (
                                                                                                        <div className="tour_guide">
                                                                                                            <p className="title">Step 5 of 6</p>
                                                                                                            <p className="text-center">You can edit your canned response here.</p>
                                                                                                            <button className="btn-next" onClick={() => { this.guidenextStep('canned_text', 5, 'canned_text_guide_step') }}>Next</button><button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                                                                        </div>) : ((this.state.canned_text_guide === false && this.state.canned_text_guide_step === 5 && onBoardingFlagStep5Counter === 1) ? (
                                                                                                            <div className="tour_guide">
                                                                                                                <p className="title">Step 6 of 6</p>
                                                                                                                <p className="text-center">You can delete your canned response here.</p>
                                                                                                                <button className="end-btn" onClick={() => { this.endGuideTour('canned_text', 'canned_text_guide') }}>End tour</button>
                                                                                                            </div>) : (null))}
                                                                                                </span>
                                                                                            </>)
                                                                                            : (null)}
                                                                                        <img className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 4 && onBoardingFlagStep5Counter === 1) ? "mr-5 cursor-pointer upper-div no-event" : "mr-5 cursor-pointer no-event"} src={require("../../assets/img/icon/editNew.svg")} />
                                                                                        <img className={(this.state.canned_text_guide === false && this.state.canned_text_guide_step === 5 && onBoardingFlagStep5Counter === 1) ? "cursor-pointer upper-div no-event" : "cursor-pointer no-event"} src={require("../../assets/img/icon/deleteNew.svg")} />
                                                                                    </>
                                                                                )}
                                                                            </td>
                                                                        </tr>
                                                                        <tr id={`edit_${canned.objectId}`} className={this.state.displayEditId === canned.objectId ? '' : 'd-none'}>
                                                                            <td>
                                                                                <div className="addArea w-100">
                                                                                    <label>Text<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                                                    <div className="input-group material-input mb-5">
                                                                                        <TextField className="form-control w-100" id="" name={`text_${canned.objectId}`} rows={2} label="Add text here" variant="outlined" onChange={this.handleChange} value={editText} multiline />
                                                                                        {editTextError ? (<p className="error">{editTextError}</p>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                                <button className="btnBlue " onClick={() => { this.editText(canned) }}>Edit text</button>&nbsp;
                                                                                <button className="btnRestore " onClick={() => { this.setState({ displayEditId: '' }) }}>Cancel</button>
                                                                            </td>
                                                                            <td>
                                                                                <div className="addArea w-100">
                                                                                    <label>Shortcut<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                                                    <div className="input-group material-input mb-5">
                                                                                        <TextField className="form-control w-100" id="" label="Shortcut" variant="outlined" name={`hashtag_${canned.objectId}`} value={edithash} onChange={this.handleChange} />
                                                                                        {editHashError ? (<p className="error">{editHashError}</p>) : (null)}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td colSpan="2">
                                                                                <div className="addArea w-100">
                                                                                    <label>Applicable on<span style={{ color: '#DD6A6A' }}>*</span></label>
                                                                                    <div className="d-flex mb-5">
                                                                                        <div className="settings-feld k-flex align-items-center my-5">
                                                                                            <span className="main-checkbox-all">
                                                                                                <label className="containerRadio containerafterchecked">
                                                                                                    <input type="radio" value="0" name={`applicable_on_${canned.objectId}`} checked={editAvailableOn == 0 ? true : false} onChange={() => {
                                                                                                        this.setState({
                                                                                                            ['applicable_on_' + canned.objectId]: 0
                                                                                                        })
                                                                                                    }} />
                                                                                                    <span className="radioCheckmark"></span>
                                                                                                </label>
                                                                                            </span>
                                                                                            <h6 className="card-title mb-0 pr-3">Everyone</h6>
                                                                                        </div>
                                                                                        <div className="settings-feld k-flex align-items-center my-4">
                                                                                            <span className="main-checkbox-all">
                                                                                                <label className="containerRadio containerafterchecked">
                                                                                                    <input type="radio" value="1" name={`applicable_on_${canned.objectId}`} checked={editAvailableOn == 1 ? true : false} onChange={() => {
                                                                                                        this.setState({
                                                                                                            ['applicable_on_' + canned.objectId]: 1
                                                                                                        })
                                                                                                    }} />
                                                                                                    <span className="radioCheckmark"></span>
                                                                                                </label>
                                                                                            </span>
                                                                                            <h6 className="card-title mb-0 pr-3">Me</h6>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                }
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.totalPages > 1 ? (<div className="pagingnation">
                                    <div className="pagingnation-list">
                                        <Pagination
                                            hideDisabled={false}
                                            innerClass="nav"
                                            activeClass="selected"
                                            firstPageText='First'
                                            lastPageText='Last'
                                            disabledClass="disabled"
                                            activePage={this.state.currPage}
                                            itemsCountPerPage={this.state.limit}
                                            totalItemsCount={this.state.totalRecords}
                                            pageRangeDisplayed={4}
                                            onChange={this.handlePageChange.bind(this)}
                                        />
                                    </div>
                                </div>) : (null)}
                            </div>
                        </div>
                    </div>
                </div> : <PopUp />}


                {/* delete popup */}

                {this.state.showDeletePoup ? (<div className="cannedPop">
                    <div className="CPopupBody">
                        <span className="heder-icon-img" onClick={() => { this.setState({ deleteId: '', showDeletePoup: false }) }}>
                            <img src={require("../../assets/img/icon/right-panel-crose.svg")} />
                        </span>
                        <h6 className="my-5 text-center">Are you sure you want to delete this shortcut?</h6>
                        <div className="d-flex justify-content-center">
                            <button className="btnBlueSettings del mr-2" onClick={() => this.processDelete()}>Delete</button>
                            <button className="btnBorderSettings ml-2" onClick={() => { this.setState({ deleteId: '', showDeletePoup: false }) }}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}



                {/* Hashtag popup */}

                {/* <div className="cannedPop">
                    <div className="CPopupBody hashtagPop">
                        <div className="hashtagHeader">
                            <span>Everyone</span>
                        </div>
                        <ul>
                            <li><span className="hashtagText"> #hi</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #greet</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #repeat </span><span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #find </span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #what</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #product</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #escalate</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #hi</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #greet</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #repeat </span><span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #find </span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #what</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #product</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #escalate</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #hi</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #greet</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #repeat </span><span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #find </span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #what</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #product</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                            <li><span className="hashtagText"> #escalate</span> <span><img src={require("../../assets/img/icon/downarrow.svg")} /></span></li>
                        </ul>
                        <div className="hashtagFooter">
                            <span className="cursor-pointer">+ Add new text</span>
                        </div>
                    </div>
                </div> */}

            </>
        )
    }
}

export default CannedText;