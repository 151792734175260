import React from 'react';
import { withRouter } from "react-router-dom";
// import db from "../config/firebase";
import { toast } from "react-toastify";
//import AgentIdle from './components/agentIdle';
import RolesAccess from './components/rolesAccess';
import VerifyjwToken from './components/verifyToken';
import { getDateInUTC } from "../modules/livechat/components/Comman";
import { testRealTimeFn } from "../modules/organization/Model/DbModel";
import {getSessionCheck}  from "../config/SignupLogin";
import {sendAgentResetCode, ownerLogoutCommon } from "../services/service";
import { getOwnerSnapShot } from "../services/payment";
import { COMMON_LOGIN_URL, DOMAIN_NAME } from '../config/siteConfig';
import firebaseServices from '../firebase';

class Authenticate extends React.Component {
    constructor() {
        super();
        this.showRoleAcess = true;
        this.verifyToken = false;          // Set false due to check speed issue on 20/09/2021
        this.haveshowSuspend = false;
        this.haveshowndeleted = false;
        this.agentIdleCheck = true;
        this.loginStatus = 1;
        this.inactiveCheck = true;
        this.timeout = 20 * 60;
        this.logCheck = true;
        this.idleCheck = true;
        this.inActiveTime = 0;
        this.blurTime = 0;
        this.userInactive = false;
        this.blurs = '';
        this.last_activity_time = 0;
        this.teamIds = [];
        this.state = {
            ownerId: '',
            timeout: '',
            login_status: 1,
            inactiveCheck: '',
            agentDetails: {},
            newStatus: '',
            role: '',
            showChangeRole: false,
            lastactivity: true,
            currentUrl: '',
            showForgetPassword:false,
            forget_email:'',
            forget_otp:'',
            forget_otp_error:false,
            forget_email_error:false,
            forget_new_password:'',
            forget_new_password:false
        }
    }

    componentDidUpdate() {
        /*let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails===null || userDetails===undefined) {
            window.location.href="/login";
        }*/
        if (this.state.currentUrl !== window.location.pathname) {
            this.showRoleAcess = true;
            ///this.verifyToken = true;  // Commented due to check speed issue on 20/09/2021
        }
    }

    getOwner = async (ownerIdentifire) => {
        return new Promise(resolve => {
            firebaseServices.db.collection("users").where("organization_alias", "==", ownerIdentifire).limit(1).get().then(users => {
                var _data = null;
                if (users.size >= 1) {
                    _data = users.docs[0].data();
                }
                resolve(_data);
            });
        }).then(_data => {
            return _data;
        });
    }


    async componentDidMount() {
        this.setState({
            currentUrl: window.location.pathname
        });
        /*check common login user session exist or not added by ruchi */
        let userDetails_1 = localStorage.getItem('agent');
        userDetails_1 = JSON.parse(userDetails_1);
        if(userDetails_1 && !userDetails_1.globalLogin) {
		    try {
                
                // setTimeout(async () => {
                //     if(localStorage.getItem('login_type') == null ||  localStorage.getItem('login_type') == undefined ||  localStorage.getItem('login_type') == 'undefined'  )
				// 	{
				// 		if(localStorage.getItem('agent'))
				// 		{
				// 			var agentData =JSON.parse(localStorage.getItem('agent'));						
				// 			localStorage.setItem("login_type",agentData.roleType);
				// 		}	
				// 	}					
                //    await getSessionCheck();
                // }, 3000)

				setInterval(async () => {
					
					if(localStorage.getItem('login_type') == null ||  localStorage.getItem('login_type') == undefined ||  localStorage.getItem('login_type') == 'undefined'  )
					{
						if(localStorage.getItem('agent'))
						{
							var agentData =JSON.parse(localStorage.getItem('agent'));						
							localStorage.setItem("login_type",agentData.roleType);
						}	
					}					
                    await getSessionCheck();
                    
				}, 120000);
			  } catch(e) {
				console.log(e);
            }
        }
		/*end of code check common login user session exist or not added by ruchi */
		
        ///// Check owner identiire exist or not ///////
        let ownerIdentifire = (Object.keys(this.props).length > 0) ? this.props.computedMatch.params.ownerIden : "";
        ownerIdentifire = ownerIdentifire.trim();
        var userData = await this.getOwner(ownerIdentifire);
        if (userData) {

            let userDetails = JSON.parse(localStorage.getItem('agent'));
            if (userDetails === null || userDetails === undefined) {
                localStorage.setItem('redirect_to', window.location.href);
                window.location.href = "/" + ownerIdentifire + "/login";
                return false;
            }
            this.setState({
                ownerId: userDetails.ownerId,
                agentId: userDetails.agentId
            }, () => {
                console.log("testRealtime", this.state.ownerId);
                testRealTimeFn();
            });

            // let forcePassword = localStorage.getItem('force_password') ? localStorage.getItem('force_password') : false;
            // if(forcePassword) {
            //     this.setState({
            //         showForcePasswordBox:true
            //     })
            // }

            getOwnerSnapShot(userDetails.ownerId, async(results) => {
                if(results.status === 200) {
                    let _ownerData = results.data;
                    if(_ownerData.deactivated===true) {
                        if(userDetails.roleType==='Owner') {
                            window.location.href = "/"+userDetails.ownerIdentifire+"/account-deactivated";
                            // await ownerLogoutCommon();
                        } else {
                            window.location.href = "/"+userDetails.ownerIdentifire+"/account-deactivated";
                            // localStorage.removeItem('agent');
                            // localStorage.removeItem('accessToken');
                            // localStorage.removeItem('agentData');
                            // localStorage.removeItem('common_redirect');
                            // localStorage.removeItem('current_login');
                            // window.location.href = "/"+userDetails.ownerIdentifire+"/login";
                        }
                        
                    } else if(_ownerData.deleted===true) {
                        if(userDetails.roleType==='Owner') {
                            await ownerLogoutCommon(true);
                        } else {
                            // localStorage.removeItem('agent');
                            // localStorage.removeItem('accessToken');
                            // localStorage.removeItem('agentData');
                            // localStorage.removeItem('common_redirect');
                            // localStorage.removeItem('current_login');
                            window.location.href = "/"+userDetails.ownerIdentifire+"/logout";
                        }
                    } else if(_ownerData.paymentStatus === false && userDetails.roleType !== 'Owner') {
                        // localStorage.removeItem('agent');
                        // localStorage.removeItem('accessToken');
                        // localStorage.removeItem('agentData');
                        // localStorage.removeItem('common_redirect');
                        // localStorage.removeItem('current_login');
                        toast.error("Your account has been suspended, Please contact admin!");
                        setTimeout(function () {
                            window.location.href = "/"+userDetails.ownerIdentifire+"/logout";
                        }, 2000)
                    }
                }
            })
            

            firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("agents").doc(userDetails.agentId).onSnapshot((docs) => {
                if (docs.exists) {
                    let userData = docs.data();
                    console.log(this.teamIds);
                    if(this.teamIds.length === 0) {
                        this.teamIds = userData.departments;
                    } else {
                        const userTeam = userData.departments ? userData.departments : [];
                        
                        let newTeam = false;
                        userTeam.forEach((team) => {
                            if(this.teamIds.indexOf(team) === -1) {
                                newTeam = true;
                            }
                        })
                        if(newTeam === true) {
                            toast.success("You have been added to a new team. Please sign out and sign in again for new changes to appear");
                        }
                        this.teamIds = [...userData.departments];
                    }
                    
                    if (userDetails.role !== userData.role) {
                        userDetails.role = userData.role;
                        console.log('userDetails >>> ', userDetails);
                        localStorage.setItem('agent', JSON.stringify(userDetails));
                        this.setState({
                            showChangeRole: true
                        })
                    }

                    if (this.last_activity_time !== userData.last_activity_time) {
                        this.inActiveTime = 0;
                        this.blurTime = 0;
                    }
                    this.last_activity_time = userData.last_activity_time;
                    //let ownerId = userDetails.ownerId;
                    if (this.loginStatus === 0 && userData.online_status === 1) {
                        this.inActiveTime = 0;
                        this.blurTime = 0;
                    }
                    this.loginStatus = userData.online_status;
                    let agentDetails = { "ownerid": userDetails.ownerId, "agentId": userDetails.agentId, 'login_status': userData.online_status, 'role': userData.role };
                    this.setState({
                        agentDetails: agentDetails
                    })
                    this.setState({
                        agentOldStatus: userData.status,
                        agentOldDelete: userData.deletetime
                    })
                    if (userData.status === 0) {
                        // localStorage.removeItem('agent');
                        // localStorage.removeItem('accessToken');
                        // localStorage.removeItem('common_redirect');
                        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').doc(userDetails.agentId).update({ online_status: 0 }).then(() => {
                            if (this.haveshowSuspend === false) {
                                toast.error("Your account has been suspended, Please contact admin!");
                                this.haveshowSuspend = true;
                            }
                            setTimeout(function () {
                                window.location.href = "/" + userDetails.ownerIdentifire + "/logout";
                            }, 2000);
                        })
                    }
                    if (userData.deletetime > 0) {
                        localStorage.removeItem('agent');
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('common_redirect');
                        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').doc(userDetails.agentId).update({ online_status: 0 }).then(() => {
                            if (this.haveshowndeleted === false) {
                                toast.error("Your account has been deleted, Please contact admin!");
                                this.haveshowndeleted = true;
                            }
                            setTimeout(function () {
                                window.location.href = "/" + userDetails.ownerIdentifire + "/login";
                            }, 2000);
                        });
                    }
                }
            });

            firebaseServices.db.collection("users").doc(userDetails.ownerId).collection("settings").doc("IDLETIME").get().then((setting) => {
                if (setting.exists) {
                    let settingData = setting.data();
                    this.timeout = parseInt(settingData.agent_inactive_min * 60);
                    this.inactiveCheck = settingData.agent_inactive_check;
                    this.newStatus = settingData.agent_inactive_status;
                    this.setState({
                        timeout: settingData.agent_inactive_min,
                        inactiveCheck: settingData.agent_inactive_check,
                        newStatus: settingData.agent_inactive_status
                    })
                }
            });

        } else {
            let agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
            let commonUserDetails = localStorage.getItem('userdata') ? JSON.parse(localStorage.getItem('userdata')) : false;
            
            console.log("-------------  agent  ---------------");
            console.log(agent);

            console.log("-------------  commonUserDetails  ---------------");
            console.log(commonUserDetails);
            let organization_alias = agent.ownerIdentifire ? agent.ownerIdentifire : agent.organization_alias;
            organization_alias = organization_alias ? organization_alias.trim() : "";
            
            if(agent) {
                window.location.href = '/' + organization_alias;
            } else if(commonUserDetails) {
                window.location.href = '/company-info';
            } else {
                window.location.href = COMMON_LOGIN_URL  + "/login?frompage="+ encodeURIComponent(DOMAIN_NAME+'/common-session') +"&website="+ encodeURIComponent(DOMAIN_NAME);
            }
            // window.location.href = "/login";
           /*changed by ruchi kapil*/
          // window.location.href = "/common-session";
            return false;
        }

        this.addEListeners();

        ///// Check owner identiire exist or not ///////
        /**
         * add last activity time on each page when agent is Visit 
        */
        // updateAgentLastActivityTime(userDetails.ownerId,userDetails.agentId);
    }

    resetTimer = (e) => {
        // console.log(e);
        this.userInactive = false;
        this.goActive();
        this.blurTime = 0;
        clearInterval(this.blurs);
        this.inActiveTime = 0;
    }

    goActive = async () => {
        if (this.loginStatus === 0 && this.timeout > 0 && this.logCheck === true && this.state.ownerId !== '' && this.state.ownerId !== undefined && this.state.agentId !== '' && this.state.agentId !== undefined) {
            console.log('Active');
            testRealTimeFn();
            console.log('Active111');
            this.logCheck = false;
            let activeTime = await getDateInUTC();
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                online_status: 1,
                last_activity_time: activeTime
            }).then((docs) => {
                this.loginStatus = 1;
                // console.log('Active');
            }).catch((err) => {
                console.log(err);
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).collection('logs').doc(activeTime.toString()).set({
                online_status: 1, time: activeTime, ref: 'active'
            }).then(() => {
                this.logCheck = true
            })
        }
    }

    onFocusFn = () => {
        this.userInactive = false;
        this.resetTimer();
    }

    startTimer = () => {
        setInterval(() => {
            if (this.userInactive === false && this.inactiveCheck === true && this.loginStatus === 1) {
                this.inActiveTime++;
                // console.log('inActiveTime >>> ' + this.inActiveTime);
                if (this.inActiveTime === this.timeout) {
                   this.goInactive();
                    this.inActiveTime = 0;
                }
            }
        }, 1000);
    }

    goInactive = async () => {
        if (this.idleCheck === true) {
            this.idleCheck = false;
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).update({
                online_status: 0
            }).then((res) => {
                this.loginStatus = 0;
                console.log(res);
            }).catch((err) => {
                console.log(err);
            })
            let addedTime = await getDateInUTC();
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).collection('logs').doc(addedTime.toString()).set({
                online_status: 0, time: addedTime, ref: 'idle'
            }).then(() => {
                this.idleCheck = true
            })
        }
    }

    addEListeners = () => {
        this.startTimer();
        window.addEventListener("mousemove", this.resetTimer, false);
        window.addEventListener("mousedown", this.resetTimer, false);
        window.addEventListener("keypress", this.resetTimer, false);
        window.addEventListener("DOMMouseScroll", this.resetTimer, false);
        window.addEventListener("mousewheel", this.resetTimer, false);
        window.addEventListener("touchmove", this.resetTimer, false);
        window.addEventListener("MSPointerMove", this.resetTimer, false);
        // window.addEventListener("beforeunload", this.beforeUnloadFn, false);
        window.addEventListener('focus', this.onFocusFn, false);
        window.addEventListener('blur', this.onBlurFn, false);
    }

    changeRoleAccess = () => {
        this.showRoleAcess = false;
    }

    refreshPage = (e) => {
        window.location.reload();
    }

    changeVerifyToken = () => {
        this.verifyToken = false;
    }

    changeAgentIdle = () => {
        this.agentIdleCheck = false;
    }

    onChangeHandler = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.value,
            new_password_error:false,
            old_password_error:false
        })
    }

    closeForgetPasswordBox = () => {
        this.setState({
            showForgetPassword:false
        })
    }

    sendForgetLink = async () => {
        if(this.state.forget_email==='') {
            document.getElementById("forget_email").focus();
            this.setState({
                forget_email_error:true
            })
            return false;
        } else {
            var forgetPasswordResponse = await sendAgentResetCode(this.state.forget_email);
            console.log(forgetPasswordResponse);
            if(forgetPasswordResponse.status===200) {
                toast.success("Please check your email");
            } else if(forgetPasswordResponse.status===400) {
                toast.error(forgetPasswordResponse.response.message);
            } else {
                toast.error("Something went wrong! Please try again after some time");
            }
        }
    }

    updateForgetNewPassword = async() => {
        if(this.state.forget_email==='') {
            document.getElementById("forget_email").focus();
            this.setState({
                forget_email_error:true
            })
            return false;
        }
        
    }

    render() {
        return (<>
            {/* {this.agentIdleCheck ? <AgentIdle agentDetails={this.state.agentDetails} timeout={this.state.timeout} inactiveCheck={this.state.inactiveCheck} newStatus={this.state.newStatus} changeAgentIdle={this.changeAgentIdle} /> : (null) } */}
            {this.showRoleAcess ? <RolesAccess agentDetails={this.state.agentDetails} timeout={this.state.timeout} inactiveCheck={this.state.inactiveCheck} newStatus={this.state.newStatus} changeRoleAccess={this.changeRoleAccess} /> : (null)}
            {this.verifyToken ? <VerifyjwToken changeVerifyToken={this.changeVerifyToken} /> : (null)}

            {/* To show Masking div start from here */}
            <div id="shadowMasking" className="popupShadow" style={{ display: this.state.showChangeRole || this.state.showForcePasswordBox || this.state.showForgetPassword ? 'block' : 'none'}}></div>
            {/* To show Masking div start end here */}

            {this.state.showChangeRole ? (<div className="popupInstallLivechat popupLivechat" >
                <div className="popupBody pb-5 px-5 text-center">
                    <div className="py-5 feilds-instruction">
                        <span className="congratilation-circle my-2">
                            <img alt="" src={require('../assets/img/icon/info.png')} />
                        </span>
                    </div>
                    <div className="pb-5 feilds-instruction">
                        <h6 className="card-title mb-0">There has been a change in your credentials please click on refresh so that changes reflect at your end.</h6>
                    </div>
                    <div>
                        <button className="btnBlue" onClick={this.refreshPage}>Refresh</button>
                    </div>
                </div>
            </div>) : (null)}

            {/* Forget password start here */}
            { this.state.showForgetPassword ? (<div className="popupInstallLivechat popupLivechat">
                <div className="popupHeader py-4 px-5 k-flex align-items-center">
                    <h6 className="card-title mb-0 white-text">Forget Password</h6>
                    <span className="close-popup ml-auto" onClick={() => this.closeForgetPasswordBox()} >
                        <svg id="Group_1587" data-name="Group 1587" xmlns="http://www.w3.org/2000/svg" width="16.41" height="16.41" viewBox="0 0 16.41 16.41">
                            <path id="Path_1769" data-name="Path 1769" d="M8.205,0A8.205,8.205,0,1,1,0,8.205,8.205,8.205,0,0,1,8.205,0Z" fill="#fff" />
                            <g id="Group_1479" data-name="Group 1479" transform="translate(2.388 8.158) rotate(-45)">
                                <rect id="Rectangle_42" data-name="Rectangle 42" width="1.36" height="8.16" rx="0.68" transform="translate(3.4 0)" fill="#407adc" />
                                <rect id="Rectangle_43" data-name="Rectangle 43" width="1.36" height="8.16" rx="0.68" transform="translate(8.16 3.4) rotate(90)" fill="#407adc" />
                            </g>
                        </svg>
                    </span>
                </div>
                <div className="popupBody pb-5 px-5">
                    <div className="py-5 feilds-instruction">
                        <h6 className="card-title mb-0">Forget Password</h6>
                        <input type="email" name="forget_email" id="forget_email" value={this.state.forget_email} onChange={(e) => this.setState({ forget_email : e.target.value, forget_email_error:false }) } style={{ border: this.state.forget_email_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} />
                        <button onClick={this.sendForgetLink}>Send</button>
                    </div>
                    <div className="pb-5 feilds-instruction">
                        <h6 className="card-title mb-0">OTP</h6>
                        <input type="OTP" placeholder="Enter OTP" id="forget_otp" name="forget_otp" onChange={(e) => this.onChangeHandler(e)} value={this.state.forget_otp} style={{ border: this.state.forget_otp_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} /> 
                    </div>
                    <div className="pb-5 feilds-instruction">
                        <h6 className="card-title mb-0">Enter New password</h6>
                        <input type="password" placeholder="*********" id="forget_new_password" name="forget_new_password" onChange={(e) => this.onChangeHandler(e)} value={this.state.forget_new_password} style={{ border: this.state.forget_new_password_error ? '2px solid red' : '1px solid rgba(0, 0, 0, 0.17)' }} /> 
                    </div>
                    <div>
                        <button onClick={() => this.updateForgetNewPassword()} className="btnBlueSettings">Reset Password </button>
                        &nbsp;
                        <button className="btnWhiteSettings mr-2" onClick={() => this.closeForgetPasswordBox()}>Cancel</button>
                    </div>
                </div>
            </div>) : (null) }
            {/* Forget password end here */}
        </>
            // <WindowFocusHandler ownerId={this.state.ownerId} timeout={this.state.timeout} />
        );
    }
}

export default withRouter(Authenticate);
