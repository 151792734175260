import React from 'react';
// import db, { fb } from "../../config/firebase";
import AddAgent from './addAgents';
import LeftPanel from './views/LeftPanel';
import { toast } from "react-toastify";
import EditAgent from "./EditAgent";
import DeleteAgent from './DeleteAgent';
import SuspendAgent from './SuspendAgent';
import moment from 'moment-timezone';
import AgentProfile from './views/AgentProfile';
import AddAgentInLivechat from "./views/addInLivechat";
import { getOwnerDetails, getAllAwsAgents, activityLog, resendAgentInvitationLink, getOwnerAfterLogin } from "./../../services/service";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from "../../config/siteConfig";
import { jwtEncrypt } from '../organization/Model/DbModel';
import { getOwnerSnapShot } from '../../services/payment';
import { checkPhoneCall } from '../livechat/components/Twilio';
import { getDateInUTC } from "../livechat/components/Comman";

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../firebase';
/* For Dropdown End */

// const firebase = require("firebase");
const NOTEXISTKEYWORD = 'notexist';

var md5 = require("md5");
const PasswordLength = 40;


class Agents extends React.Component {
    
    constructor() {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        this.state = {
            showAddAgent: false,
            agents: [],
            owners: [],
            ownerId: '',
            searchbox: '',
            department: '',
            role: '',
            status: '',
            departmentList: [],
            roleList: [],
            showEditAgent: false,
            agentId: '',
            agentDateFormat: '',
            loader: true,
            delagentId: '',
            showDeleteAgent: false,
            showSuspendAgent: false,
            susAgentStatus: '',
            agentEmpty: false,
            agentsnapShot: '',
            viewProfile: '',
            showAgentProfile: '',
            EditagentId: '',
            viewProfileStatus: '',
            searchEmpty: false,
            filterMobile: false,
            editAgentDetails: {},
            agentInLivechatId: '',
            AddAgentInLivechatStatus: false,
            livechatAgentDetails: {},
            awsAgents: [],
            dbAwsAgents: [],
            ownerDetails: {},
            maxAgentAllowed: 1,
            totalAgent: 0,
            showMaxAgentPopup: false,
            paymentStatus: false,
            suspendEmail: '',
            viewProfileData: {},
            getAgent: false,
            getOwner: false,
            allPhoneNumbers: [],
            editAgeNumberId: "",
            editAgeNumber: "",
            editAgentDetail: {},
            showConfirmWindow: false
        }
    }
    
    changehandle = (e) => {
        let target = e.target;
        let values = target.value;
        this.setState({
            [target.name]: values
        }, () => {
            if (target.name !== 'searchbox' && this.state.agentEmpty === false) {
                this.applyFilter();
            }
        })
    }

    changeSearch = () => {
        if (this.state.agentEmpty === false) {
            this.applyFilter();
        }
    }

    async applyFilter(showLoader = true) {
        this.setState({
            loader: showLoader
        })
        var query = firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("deletetime", "==", 0);
        query.get().then(async (docs) => {
            var i = 0;
            var agents = [];
            var owner = [];
            if (!docs.empty) {
                docs.forEach((doc) => {
                    if (doc.data().roleType === 'Owner') {
                        let ownerDetails = this.state.ownerDetails;
                        let ownerData = doc.data();
                        ownerData.first_name = ownerDetails.first_name;
                        ownerData.last_name = ownerDetails.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerDetails.last_name : '';
                        ownerData.pic = ownerDetails.pic;
                        ownerData.thumbnail = ownerDetails.thumbnail;
                        ownerData.imageurl = ownerDetails.imageurl;
                        ownerData.timezone = ownerDetails.time_zone;
                        ownerData.email = ownerDetails.email;
                        ownerData.alias = ownerDetails.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? ownerDetails.alias : '';
                        owner[0] = ownerData;
                        owner[0]['id'] = doc.id;
                    } else {
                        let agentData = doc.data();
                        delete agentData.alias;
                        agents[i] = agentData;
                        agents[i]['id'] = doc.id;
                        i++;
                    }
                });

                var awsAgent = this.state.awsAgents;
                let merged = [];
                for (let i = 0; i < awsAgent.length; i++) {
                    merged.push({
                        ...awsAgent[i],
                        ...(agents.find((itmInner) => itmInner.id === awsAgent[i].subid))
                    }
                    );
                }
                merged.push(owner[0]);
                var filteredData = [];
                if (this.state.searchbox.trim() !== '') {
                    let searchboxValue = this.state.searchbox.trim();
                    searchboxValue = searchboxValue.replace(/\\/g, "");
                    merged.forEach((agent) => {
                        if (new RegExp(searchboxValue, "gi").test(agent.first_name) || new RegExp(searchboxValue, "gi").test(agent.last_name) || new RegExp(searchboxValue, "gi").test(agent.alias) || new RegExp(searchboxValue, "gi").test(agent.email)) {
                            filteredData.push(agent);
                        }
                    })
                } else {
                    filteredData = merged;
                }

                var filteredData1 = [];
                if (this.state.status !== '' && this.state.status !== 'all') {
                    filteredData.forEach((agent) => {
                        if (agent.status === parseInt(this.state.status)) {
                            filteredData1.push(agent)
                        }
                    })
                } else {
                    filteredData1 = filteredData
                }

                var filteredData2 = [];
                if (this.state.role !== '' && this.state.role !== 'all') {
                    filteredData1.forEach((agent) => {
                        if (agent.role === this.state.role) {
                            filteredData2.push(agent)
                        }
                    })
                } else {
                    filteredData2 = filteredData1
                }

                var filteredData3 = [];
                if (this.state.department !== '' && this.state.department !== 'all') {
                    filteredData2.forEach((agent) => {
                        let departmensts = agent.departments;
                        if (departmensts) {
                            if (departmensts.indexOf(this.state.department) > -1) {
                                filteredData3.push(agent)
                            }
                        }
                    })
                } else {
                    filteredData3 = filteredData2
                }

                if (filteredData3.length === 0) {
                    this.setState({
                        agents: [],
                        loader: false,
                        searchEmpty: true
                    })
                } else {
                    this.getAllList(filteredData3);
                }
            } else {
                this.setState({
                    agents: [],
                    loader: false,
                    searchEmpty: true
                })
            }
        });
    }

    componentWillUnmount() {
        this.state.agentsnapShot();
    }

    getAllList = (agents) => {
        let arrLength = agents.length;
        var allAgents = [];
        let m = 1;
        agents.forEach((agent, k) => {
            if (agent) {
                let promise1 = new Promise((res, rej) => {
                    if (!agent.departments || agent.departments.length === 0 || agent.departments.length === undefined) {
                        res('');
                    } else {
                        var filteredDepartMents = agent.departments.filter(function (el) {
                            return el !== "" && el !== null;
                        });
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').where(firebaseServices.fb.firestore.FieldPath.documentId(), 'in', filteredDepartMents).get().then((depData) => {
                            let depArray = [];
                            if (depData.empty === false) {
                                depData.forEach((deps) => {
                                    depArray.push(deps.data().name);
                                })
                            }
                            res(depArray);
                        });
                    }
                })

                let promise2 = new Promise((Resolve, Reject) => {
                    let rolDa = '';
                    Resolve(rolDa);
                })

                let promise3 = new Promise((res3, rej3) => {
                    if (agent.role) {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').where(firebaseServices.fb.firestore.FieldPath.documentId(), '==', agent.role).get().then((defRoles) => {
                            let defrol = '';
                            if (defRoles.empty === false) {
                                defRoles.forEach((defrole) => {
                                    defrol = defrole.data().name;
                                })
                            }
                            res3(defrol);
                        })
                    } else {
                        res3('');
                    }
                })
                Promise.all([promise1, promise2, promise3]).then(res => {
                    allAgents[k] = agent;
                    allAgents[k]['role'] = res[1] === '' ? res[2] : res[1];
                    allAgents[k]['departments'] = res[0];
                    if (arrLength === m) {
                        allAgents.sort(function (x, y) {
                            let a = x.name ? x.name.toUpperCase() : '',
                                b = y.name ? y.name.toUpperCase() : '';
                            return a === b ? 0 : a > b ? 1 : -1;
                        });
                        allAgents.sort(function (x, y) {
                            let a = x.online_status === 2 ? 0 : x.online_status,
                                b = y.online_status === 2 ? 0 : y.online_status;
                            return a === b ? 0 : a < b ? 1 : -1;
                        });
                        this.setState({
                            agents: allAgents,
                            loader: false
                        })
                    }
                    m++;
                })
            }
        })
    }

    getAgentList = async (ownerId) => {
        let ownerDetails = await getOwnerDetails(this.Agent.organization_alias ? this.Agent.organization_alias : this.Agent.ownerIdentifire);
        // let ownerDetails = await getOwnerAfterLogin();
        this.state.agentsnapShot = firebaseServices.db.collection('users').doc(ownerId).collection('agents').where("deletetime", "==", 0).onSnapshot(async (docs) => {
            if (this.state.getOwner === true) {
                ownerDetails = await getOwnerDetails(this.Agent.organization_alias ? this.Agent.organization_alias : this.Agent.ownerIdentifire);;
            }
            var agents = [];
            var owner = [];
            var i = 0;
            if (this.state.searchbox.trim() !== '' || (this.state.status !== '' && this.state.status !== 'all') || (this.state.role !== '' && this.state.role !== 'all') || (this.state.department !== '' && this.state.department !== 'all')) {
                this.applyFilter(false);
                return;
            }
            let ownerDetailsData = {}
            if (ownerDetails.status === 200) {
                ownerDetailsData = ownerDetails.data;
                this.setState({
                    ownerDetails: ownerDetailsData
                })
            }
            if (docs.empty) {
                this.setState({
                    loader: false,
                })
            } else {
                this.setState({
                    agentEmpty: false
                })
            }
            let totalAgent = 0;
            let currenTime = new Date().getTime();
            docs.forEach((doc) => {
                if (doc.data().roleType === 'Owner') {
                    let ownerData = doc.data();
                    if (ownerDetails.status === 200) {
                        ownerData.first_name = ownerDetailsData.first_name ? ownerDetailsData.first_name : '';
                        ownerData.last_name = ownerDetailsData.last_name ? ownerDetailsData.last_name : '';
                        ownerData.pic = ownerDetailsData.pic ? ownerDetailsData.pic : '';
                        ownerData.thumbnail = ownerDetailsData.thumbnail ? ownerDetailsData.thumbnail : '';
                        ownerData.imageurl = ownerDetailsData.imageurl ? ownerDetailsData.imageurl : '';
                        ownerData.timezone = ownerDetailsData.time_zone ? ownerDetailsData.time_zone : '';
                        ownerData.email = ownerDetailsData.email ? ownerDetailsData.email : '';
                        ownerData.alias = ownerDetailsData.alias ? ownerDetailsData.alias : '';
                        ownerData.profile_pic = ownerDetailsData.thumbnail && ownerDetailsData.thumbnail !== 'notexist' ? ownerDetailsData.imageurl + '/' + ownerDetailsData.thumbnail + '?v=' + currenTime : ''
                    }
                    owner[0] = ownerData;
                    owner[0]['id'] = doc.id;
                } else {
                    let agentData = doc.data();
                    delete agentData.alias;
                    agents[i] = agentData;
                    agents[i]['id'] = doc.id;
                    i++;
                }
            })
            var awsAgent = this.state.awsAgents;
            if (this.state.getAgent === true) {
                let allAgentss = await getAllAwsAgents();
                awsAgent = allAgentss;
                this.setState({
                    awsAgents: allAgentss,
                    getAgent: false
                })
            }
            let merged = [];
            for (let i = 0; i < awsAgent.length; i++) {
                merged.push({
                    ...awsAgent[i],
                    ...(agents.find((itmInner) => itmInner.id == awsAgent[i].subid))
                }
                );
            }
            merged.push(owner[0]);
            if (merged.length > 0) {
                merged.forEach((mAgent) => {
                    if (mAgent && mAgent.status === 1) {
                        totalAgent++;
                    }
                })
            }

            this.setState({
                dbAwsAgents: merged,
                totalAgent: totalAgent
            });

            this.getAllList(merged);
        });
    }

    checkSubscriptionList = () => {
        if (this.Agent.ownerId) {
            getOwnerSnapShot(this.Agent.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        maxAgentAllowed: _data.no_of_agents ? parseInt(_data.no_of_agents) : 1,
                        paymentStatus: _data.paymentStatus ? _data.paymentStatus : false
                    })
                } else {
                    this.setState({
                        maxAgentAllowed: 1,
                        paymentStatus: false
                    })
                }
            })
        }
    }

    async componentDidMount() {
        let allAgentss = await getAllAwsAgents();
        const userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            awsAgents: allAgentss,
            ownerId: userDetails.ownerId,
            agentDateFormat: userDetails.timezone
        }, async () => {
            await this.getAgentList(userDetails.ownerId);
        })

        this.checkSubscriptionList();
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('departments').get().then((docs) => {
            docs.forEach((doc) => {
                var i = 0;
                var departmentList = [];
                docs.forEach((doc) => {
                    departmentList[i] = doc.data();
                    departmentList[i]['id'] = doc.id;
                    i++;
                });
                this.setState({
                    departmentList: departmentList
                })
            })
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').get().then((data) => {
            var i = 0;
            var roleList = [];
            data.forEach(dat => {
                roleList[i] = dat.data();
                roleList[i]['id'] = dat.id;
                i++;
            })
            this.setState({
                roleList: roleList
            })
        })

        checkPhoneCall();
        this.getAllPhoneNumbers();

        activityLog('Opened agent listing page');
    }

    showAgent = () => {
        this.setState({
            showAddAgent: true,
            getAgent: true
        })
    }

    sendStatus = async (status) => {
        this.setState({
            showAddAgent: status,
            getOwner: false,
            getAgent: false
        })
    }

    sendEditStatus = async (status) => {
        this.setState({
            showEditAgent: status,
            getOwner: false,
            getAgent: false
        })
    }

    sendDeleteStatus = async (status) => {
        this.setState({
            showDeleteAgent: status,
            delagentId: '',
            getOwner: false,
            getAgent: false
        })
    }

    sendSuspendStatus = async (status) => {
        this.setState({
            showSuspendAgent: status,
            susAgentId: '',
            suspendEmail: '',
            getOwner: false,
            getAgent: false
        })
    }

    suspendUser = (useremail, userId, status, agentName, agentAlias) => {
        if ((!this.state.paymentStatus || this.state.totalAgent >= this.state.maxAgentAllowed) && status === 0) {
            this.showAgentMax();
        } else {
            this.setState({
                susAgentId: userId,
                susAgentStatus: status,
                showSuspendAgent: true,
                suspendEmail: useremail,
                getAgent: true,
                suspendAgentName: agentName,
                suspendAgentAlias: agentAlias
            })
        }
    }

    deleteUser = (userId) => {
        this.setState({
            delagentId: userId,
            showDeleteAgent: true,
            getAgent: true
        })
    }

    editAgent = (agentId, agentEmail, firstName, lastName, agentAlias, timezone, pic, thumbnail, imageurl, userType = 'agent') => {
        let getType = userType === 'owner' ? 'getOwner' : 'getAgent';
        this.setState({
            showEditAgent: true,
            EditagentId: agentId,
            editAgentDetails: { email: agentEmail, firstName: firstName, lastName: lastName, alias: agentAlias, pic: pic, thumbnail: thumbnail, timezone: timezone, imageurl: imageurl },
            [getType]: true
        })
    }

    changePassword = (agentId, agentEmail, name) => {
        let pwd = this.makeid(12);
        let password = md5(pwd);
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").doc(agentId).update({ password: password }).then((ref) => {
            this.sendEmail(agentEmail, pwd, name);
            toast.success("Password has been changed successfully! Please ask agent to check email");
        }).catch((err) => {
            console.log(err);
        })
    }

    makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    resendINvite_OLD = (agentId, agentEmail, name) => {
        let pwd = this.makeid(12);
        let password = md5(pwd);
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").doc(agentId).update({ password: password }).then((ref) => {
            this.sendEmail(agentEmail, pwd, name);
            toast.success("Re-inviatation has been sent successfully! Please ask agent to check email");
        }).catch((err) => {
            console.log(err);
        })
    }

    generatePassword = () => {
        var length = PasswordLength,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    resendINvite = async (agentEmail, first_name, last_name, temporaryPwd) => {
        this.setState({
            loader: true
        })
        let newPwd = '';
        if (temporaryPwd) {
            newPwd = temporaryPwd;
        } else {
            newPwd = await this.generatePassword();
            newPwd = md5(newPwd);
        }
        let time = await getDateInUTC();
        let tokenDetails = {
            code: newPwd,
            email: agentEmail,
            time: time
        }
        let token = await jwtEncrypt(tokenDetails);
        let organizationsAlias = this.Agent.organization_alias ? this.Agent.organization_alias : this.Agent.ownerIdentifire;
        let verificationLink = window.location.origin + "/" + organizationsAlias + "/agent-verification/" + token;
        let linkSent = await resendAgentInvitationLink(organizationsAlias, this.Agent.company_name, first_name, last_name, agentEmail, verificationLink);
        if (linkSent.status === 200) {
            toast.success("Verification link has been sent to agent successfully!");
        } else {
            toast.error("Something went wrong, Please try again after some time!");
        }
        this.setState({
            loader: false
        })
    }

    sendEmail = (email, pwd, name) => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let loginUrl = window.location.origin + "/" + userDetails.ownerIdentifire + "/login";
        var body = [
            `email=${email.toLowerCase()}&pass=${pwd}&link=${loginUrl}&type=resend_invite&ownerId=${this.state.ownerId}&name=${name}`
        ];
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    openProfile = (agentId, firstName, lastName, alias, email, role, departments, pic, thumbnail, url) => {
        this.setState({
            viewProfile: agentId,
            showAgentProfile: true,
            viewProfileStatus: true,
            viewProfileData: { firstName, lastName, alias, email, role, departments, pic, thumbnail, url }
        })
    }

    deleteAgentprofile = () => {
        this.setState({
            viewProfile: '',
            showAgentProfile: false,
            viewProfileData: {}
        })
    }

    // Date Functions Start
    timeZoneFormat = (date) => {
        //var todayDate = new Date().getTime();
        //todayDate = Math.floor(todayDate / 1000);
        var todayUTCDate;
        if (this.state.agentDateFormat === '' || this.state.agentDateFormat === undefined) {
            todayUTCDate = moment.tz(date, "X", 'UTC').tz(moment.tz.guess(true)).format('DD/MM/YYYY hh:mm A');
        } else {
            todayUTCDate = moment.tz(date, "X", 'UTC').tz(this.state.agentDateFormat).format('DD/MM/YYYY hh:mm A');
        }
        // todayUTCDate = parseInt(todayUTCDate);
        return todayUTCDate;
    }
    // Date Functions End

    searchInput = (e) => {
        if (e.which === 13) {
            this.changeSearch();
        }
    }

    userFilterMobile = () => {
        var responsiveTbaleHeaderFilter = document.getElementById('resposnive-table-class');
        responsiveTbaleHeaderFilter.classList.toggle('responsiveTbaleHeaderFilter')
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    addinLivechat = (id, email, firstName, lastName, alias, timezone) => {
        console.log('id >>> ', id);
        this.setState({
            agentInLivechatId: id,
            AddAgentInLivechatStatus: true,
            livechatAgentDetails: { email: email, firstName: firstName, lastName: lastName, alias: alias, timezone: timezone }
        })
    }

    showAgentMax = () => {
        this.setState({
            showMaxAgentPopup: true
        })
    }

    hideaddLivechatProfile = (status = false) => {
        this.setState({
            agentInLivechatId: '',
            AddAgentInLivechatStatus: status,
            livechatAgentDetails: {}
        })
    }

    opensubscriptionPage = () => {
        let organizationAlias = this.Agent.ownerIdentifire ? this.Agent.ownerIdentifire : this.Agent.organization_alias;
        window.location.href = "/" + organizationAlias + "/subscription";
    }

    /* Get all phone numbers start */

    getAllPhoneNumbers = () => {
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').where("agent_id", "==", 0).get().then(docs => {
            var allnumbers = [];
            if (!docs.empty) {
                docs.forEach(doc => {
                    let phoneData = doc.data();
                    delete phoneData.addedon;
                    delete phoneData.postal_code;
                    //delete phoneData.friendlyName;
                    delete phoneData.capabilities;
                    delete phoneData.locality;
                    delete phoneData.sid;
                    delete phoneData.region;
                    delete phoneData.country_iso;
                    phoneData.id = doc.id;
                    allnumbers.push(phoneData);
                });
            }
            this.setState({ allPhoneNumbers: allnumbers });
        });
    }

    assignNumberToAgent = (e, agent_id) => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0 || e.target.value === "") {
            return false;
        }
        this.setState({
            loader: true
        });

        if (e.target.value === "add_number") {
            this.props.history.push("/" + this.Agent.ownerIdentifire + "/buy-number");
            return false;
        }

        let editAgeNumberId = this.state.editAgeNumberId ? this.state.editAgeNumberId : "";
        let editAgeNumber = this.state.editAgeNumber ? this.state.editAgeNumber : "";
        let phoneData2 = e.target.value ? JSON.parse(e.target.value) : {};
        if (editAgeNumberId === agent_id && editAgeNumber !== "") {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').where("phone", "==", editAgeNumber).get().then(docsnums => {
                if (!docsnums.empty) {
                    docsnums.forEach(docsnu => {
                        if (docsnu.data().phone === editAgeNumber && docsnu.data().agent_id === agent_id) {
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agent_id).update({ phone_number: phoneData2.phone, calling_status: 1 });
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').doc(phoneData2.id).update({ agent_id: agent_id, updatedon: Date.now() }).then(ress => {
                                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').doc(docsnu.id).update({ agent_id: 0, updatedon: Date.now() }).then(resss => {
                                    this.getAllPhoneNumbers();
                                    this.setState({
                                        loader: false,
                                        editAgeNumberId: "",
                                        editAgeNumber: ""
                                    });
                                });
                            });
                        }
                    });
                }
            });
        } else {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agent_id).update({ phone_number: phoneData2.phone, calling_status: 1 });
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').doc(phoneData2.id).update({ agent_id: agent_id, updatedon: Date.now() }).then(ress => {
                this.getAllPhoneNumbers();
                this.setState({
                    loader: false,
                    editAgeNumberId: ""
                });
            });
        }
    }

    editPhoneNumber = (agent) => {
        let phone_number = agent.phone_number ? agent.phone_number : "";
        let agentId = agent.id ? agent.id : false;
        if (agentId) {
            this.setState({
                editAgeNumberId: agentId,
                editAgeNumber: phone_number,
            });
        }
    }

    deleteConfirmPhoneNumber = (agent) => {
        if (this.Agent.roleType !== "Owner" || this.Agent.phone_call === 0) {
            return false;
        }

        if (this.state.showConfirmWindow) {
            this.cancelDelete();
        } else {
            this.setState({
                editAgentDetail: agent,
                showConfirmWindow: true
            });
        }
    }

    cancelDelete = () => {
        this.setState({
            showConfirmWindow: false,
            editAgentDetail: {}
        });
    }

    deletePhoneNumber = () => {
        let agent = this.state.editAgentDetail;
        let shouldUpdateAgent = agent.fromSuspend ? false : true;
        this.setState({
            loader: true
        });
        let phone_number = agent.phone_number ? agent.phone_number : "";
        let agentId = agent.id ? agent.id : false;
        if (agentId) {
            var isFound = false;
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').where("phone", "==", phone_number).get().then(docsnums => {
                if (!docsnums.empty) {
                    docsnums.forEach(docsnu => {
                        if (docsnu.data().phone === phone_number) {
                            isFound = true;
                            if (shouldUpdateAgent) {
                                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({ phone_number: "" });
                            }
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('phone_numbers').doc(docsnu.id).update({ agent_id: 0, updatedon: Date.now() }).then(ress => {
                                this.getAllPhoneNumbers();
                                this.setState({
                                    loader: false,
                                    editAgeNumberId: "",
                                    editAgeNumber: "",
                                    showConfirmWindow: false,
                                    editAgentDetail: {}
                                });
                            });
                        }
                    });
                }

                if (!isFound) {
                    this.setState({
                        loader: false,
                        editAgeNumberId: "",
                        editAgeNumber: "",
                        showConfirmWindow: false,
                        editAgentDetail: {}
                    });
                }
            });
        } else {
            this.setState({
                loader: false,
                editAgeNumberId: "",
                editAgeNumber: "",
                showConfirmWindow: false,
                editAgentDetail: {}
            });
        }
    }

    cancelEditNumber = () => {
        this.setState({
            editAgeNumberId: "",
            editAgeNumber: ""
        });
    }

    removePhoneNumber = (status, agentId, phone) => {
        if (status === 0) {
            this.setState({
                editAgeNumberId: agentId,
                editAgeNumber: phone,
                editAgentDetail: { id: agentId, phone_number: phone, fromSuspend: true }
            }, () => {
                this.deletePhoneNumber();
            });
        }
    }

    /* Get all phone numbers end */

    render() {
        var agentEmpty;
        if (this.state.agentEmpty === true && this.state.agents.length === 0 && this.state.owners.length === 0) {
            agentEmpty = <tr><td colSpan="8">No agents are present in your account</td></tr>
        }
        else if (this.state.searchEmpty === true && this.state.agents.length === 0 && this.state.owners.length === 0) {
            agentEmpty = <tr><td colSpan="8">No agents are present for this criteria</td></tr>
        }

        var list = this.state.agents.map((value, key) => {
            let status = value.status == 1 ? 'Active' : 'Suspended';
            let statusLabel = value.status == 1 ? 'Suspend' : 'Activate';
            status = value.departments ? (this.state.paymentStatus || value.roleType === 'Owner' ? status : 'Suspended') : 'Pending';

            let profile_img = '';
            let formatted = 'Never Login';
            if (value.last_activity_time !== '' && value.last_activity_time !== undefined) {
                let lastLogin = new Date(value.last_activity_time);
                formatted = this.timeZoneFormat(lastLogin);
            } else if (value.last_login !== '' && value.last_login !== undefined) {
                let lastLogin = new Date(value.last_login);
                formatted = this.timeZoneFormat(lastLogin);
            }
            if (formatted === 'Invalid date') {
                formatted = 'Never Login';
            }

            if (value.pic && value.pic !== '' && value.pic !== 'notexist') {
                let thumbnailURL = value.imageurl + '/' + value.pic;
                profile_img = <img src={thumbnailURL} className="avatar-img" alt="" />;
            } else {
                let name = value.last_name && value.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value.first_name + ' ' + value.last_name : value.first_name;
                name = name ? name : value.name;
                let names = name ? name.trim().split(" ") : [];
                if (names.length > 1) {
                    profile_img = (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase();
                } else {
                    profile_img = names[0] ? (names[0].substring(0, 2)).toUpperCase() : "";
                }
            }

            let onlineImg = '';
            if (value.online_status === 1 && value.status === 1) {
                onlineImg = 'online.png';
            } else {
                onlineImg = 'offline.png';
            }

            let agentName = value.first_name ? value.first_name + ' ' + (value.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value.last_name : '') : (value.name ? value.name : '');
            if (agentName.length > 40) {
                agentName = agentName.substring(0, 35) + '....';
            }
            let agentAlias = value.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? value.alias : '---';
            if (agentAlias.length > 40) {
                agentAlias = agentAlias.substring(0, 35) + '....';
            }
            return <tr key={key}>
                {value.departments || value.role ? (<><td >
                    <div className="media text-center text-xl-left profile-click">
                        <div className="avatar avatar-sm d-xl-inline-block mr-4">
                            <span>{profile_img}</span>
                            <span><img alt="" src={(require(`../../assets/img/icon/${onlineImg}`))} style={{ position: 'absolute', minWidth: 10, minHeight: 10, width: 10, height: 10, bottom: 2, right: 2 }} /></span>
                        </div>
                        <div className="media-body align-self-center text-truncate text-left">
                            <h5 className="heding-text text-truncate mb-n3" style={{ cursor: 'pointer' }} onClick={() => this.openProfile(value.id, value.first_name, value.last_name, value.alias, value.email, value.role, value.departments.toString(), value.pic, value.thumbnail, value.imageurl)}>{agentName} </h5>
                            <small className="text-muted">{value.email}</small>
                        </div>
                    </div>
                </td>
                    <td>
                        <div className="small text-screen1 k-flex align-items-center agentnumNumbDiv aa">
                            {(this.Agent.roleType === "Owner" && this.Agent.phone_call === 1 && status === 'Active') ? (<>{value.phone_number && value.phone_number !== NOTEXISTKEYWORD ? (<>
                                {this.state.editAgeNumberId === value.id ? (<><div className="material-select">
                                    <FormControl variant="outlined" className="material-outlined w-150">
                                        <InputLabel id="demo-simple-select-outlined-label">Phone Number</InputLabel>
                                        <Select MenuProps={{ disableScrollLock: true}} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" value="" name="addPhone" onChange={(e) => this.assignNumberToAgent(e, value.id)} label="Phone Number">
                                            {this.state.allPhoneNumbers.map((phoneNum, indx) => {
                                                return <MenuItem key={indx} value={JSON.stringify(phoneNum)}>{phoneNum.phone + " (" + phoneNum.friendlyName + ")"}</MenuItem>
                                            })}
                                            <MenuItem value="add_number">Add new number</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div onClick={this.cancelEditNumber} className="deleteagentnumber ml-4 cursor-pointer"> <svg xmlns="http://www.w3.org/2000/svg" width="8.904" height="8.904" viewBox="0 0 8.904 8.904">
                                    <g id="cancel" transform="translate(0 -0.001)">
                                        <g id="Group_4584" data-name="Group 4584" transform="translate(0 0.001)">
                                            <path id="Path_2557" data-name="Path 2557" d="M5.115,4.453,8.767.8A.469.469,0,0,0,8.1.138L4.452,3.79.8.138A.469.469,0,0,0,.137.8L3.789,4.453.137,8.105A.469.469,0,0,0,.8,8.768L4.452,5.116,8.1,8.768a.469.469,0,0,0,.663-.663Z" transform="translate(0 -0.001)" />
                                        </g>
                                    </g>
                                </svg></div></>) : (<><span>{value.phone_number}</span><div className="agentnumIcons k-flex align-items-center"><div onClick={() => this.editPhoneNumber(value)} className="editagentnumber ml-4 cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="15.141" height="15.064" viewBox="0 0 15.141 15.064">
                                    <g id="edit-black" transform="translate(0 -0.001)">
                                        <path id="Path_942" data-name="Path 942" d="M13.975,47.159a.377.377,0,0,0-.377.377v3.348a1.132,1.132,0,0,1-1.131,1.131H1.886A1.132,1.132,0,0,1,.754,50.884V41.057a1.133,1.133,0,0,1,1.131-1.131H5.234a.377.377,0,1,0,0-.754H1.886A1.888,1.888,0,0,0,0,41.057v9.827A1.888,1.888,0,0,0,1.886,52.77H12.467a1.888,1.888,0,0,0,1.886-1.886V47.536a.377.377,0,0,0-.378-.377Zm0,0" transform="translate(0 -37.705)" fill="#fff" />
                                        <path id="Path_943" data-name="Path 943" d="M118.313.758a1.7,1.7,0,0,0-2.4,0l-6.728,6.728a.377.377,0,0,0-.1.166l-.885,3.194a.377.377,0,0,0,.464.464l3.194-.885a.377.377,0,0,0,.166-.1L118.755,3.6a1.7,1.7,0,0,0,0-2.4Zm-8.306,6.973,5.506-5.506L117.289,4l-5.506,5.506Zm-.355.712,1.419,1.419-1.962.544Zm8.57-5.375-.4.4-1.776-1.776.4-.4a.943.943,0,0,1,1.333,0l.443.443a.944.944,0,0,1,0,1.332Zm0,0" transform="translate(-104.11 -0.261)" />
                                    </g>
                                </svg></div><div onClick={() => this.deleteConfirmPhoneNumber(value)} className="deleteagentnumber ml-4 cursor-pointer"><svg xmlns="http://www.w3.org/2000/svg" width="13.168" height="19.756" viewBox="0 0 24.168 29.756">
                                    <g id="delete" transform="translate(0.004 0.001)">
                                        <path id="Path_2553" data-name="Path 2553" d="M223.1,154.7a.7.7,0,0,0-.7.7V168.57a.7.7,0,0,0,1.394,0V155.4A.7.7,0,0,0,223.1,154.7Zm0,0" transform="translate(-206.904 -143.924)" />
                                        <path id="Path_2554" data-name="Path 2554" d="M105.1,154.7a.7.7,0,0,0-.7.7V168.57a.7.7,0,1,0,1.394,0V155.4A.7.7,0,0,0,105.1,154.7Zm0,0" transform="translate(-97.126 -143.924)" />
                                        <path id="Path_2555" data-name="Path 2555" d="M1.976,8.857V26.026A3.845,3.845,0,0,0,3,28.678a3.432,3.432,0,0,0,2.49,1.077H18.673a3.431,3.431,0,0,0,2.49-1.077,3.845,3.845,0,0,0,1.022-2.652V8.857A2.662,2.662,0,0,0,21.5,3.622H17.934V2.751A2.737,2.737,0,0,0,15.174,0H8.986a2.737,2.737,0,0,0-2.76,2.753v.871H2.659a2.662,2.662,0,0,0-.683,5.235Zm16.7,19.5H5.488A2.207,2.207,0,0,1,3.37,26.026V8.918H20.791V26.026A2.207,2.207,0,0,1,18.673,28.361ZM7.62,2.751A1.343,1.343,0,0,1,8.986,1.392h6.188A1.343,1.343,0,0,1,16.54,2.751v.871H7.62ZM2.659,5.016H21.5a1.254,1.254,0,1,1,0,2.509H2.659a1.254,1.254,0,1,1,0-2.509Zm0,0" transform="translate(0 0)" />
                                        <path id="Path_2556" data-name="Path 2556" d="M164.1,154.7a.7.7,0,0,0-.7.7V168.57a.7.7,0,0,0,1.394,0V155.4A.7.7,0,0,0,164.1,154.7Zm0,0" transform="translate(-152.015 -143.924)" />
                                    </g>
                                </svg>  </div></div></>)}</>) :
                                (<div className="material-select">
                                    <FormControl variant="outlined" className="material-outlined w-150">
                                        <InputLabel id="demo-simple-select-outlined-label">Phone Number</InputLabel>
                                        <Select MenuProps={{ disableScrollLock: true}} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" name="addPhone" onChange={(e) => this.assignNumberToAgent(e, value.id)} value="" label="Phone Number">
                                            {this.state.allPhoneNumbers.map((phoneNum, indx) => {
                                                return <MenuItem key={indx} value={JSON.stringify(phoneNum)}>{phoneNum.phone + " (" + phoneNum.friendlyName + ")"}</MenuItem>
                                            })}
                                            <MenuItem value="add_number">Add new number</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>)}</>) : (value.phone_number && value.phone_number !== NOTEXISTKEYWORD ? value.phone_number : "---")}
                        </div>
                    </td>
                    <td><p className="small text-screen1">{agentAlias}</p></td>
                    <td><p className="small text-screen1" style={{wordBreak:"break-word"}}>{value.departments.toString()}</p></td>
                    <td><p className="small text-screen1">{value.role}</p></td>
                    <td><p className="small text-screen1">{status}</p></td>
                    <td><p className="small text-screen1">{formatted}</p></td>
                    <td>
                        {(value.role !== 'Owner' || this.Agent.roleType === 'Owner') ?
                            <div className="nav-item list-inline-item d-xl-block" >
                                <span className="text-muted px-2" style={{ cursor: 'pointer' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img alt="" src={require('../../assets/img/icon/table-more-vertical.svg')} />
                                </span>
                                {value.role !== 'Owner' ? (<div className="dropdown-menu dropdown-menu-right" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(1175px, 151px, 0px)' }}>
                                    {value.departments && this.state.paymentStatus ? (<><span className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} onClick={() => this.editAgent(value.id, value.email, value.first_name, value.last_name, value.alias, value.timezone, value.pic, value.thumbnail, value.imageurl)}>
                                        Edit
                                    </span>
                                        {/* <span className="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}} onClick={() => this.deleteUser(value.id)}>
                        Delete
                    </span> */}
                                        <span className="dropdown-item d-flex align-items-center suspend" style={{ cursor: 'pointer' }} onClick={() => this.suspendUser(value.email, value.id, value.status, agentName, agentAlias)}>
                                            {statusLabel}
                                        </span>
                                        {value.email_verified ? (null) : (<span className="dropdown-item d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => this.resendINvite(value.email, value.first_name, value.last_name, value.temporary)}>
                                            Re-Send Invite
                                        </span>)}
                                        {/* <span className="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}} onClick={() => this.resendINvite(value.id, value.email,value.name)}>
                        Re-Send Invite
                    </span> */}
                                    </>) : (value.departments ? (<span className="dropdown-item d-flex align-items-center" style={{ cursor: 'pointer' }} onClick={() => this.deleteUser(value.id)}>
                                        Delete
                                    </span>) : (null))}
                                </div>) : (<div className="dropdown-menu dropdown-menu-right" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(1175px, 151px, 0px)' }}>
                                    <span className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} onClick={() => this.editAgent(value.id, value.email, value.first_name, value.last_name, value.alias, value.timezone, value.pic, value.thumbnail, value.imageurl, 'owner')}>
                                        Edit
                                    </span>
                                </div>)}

                            </div>
                            : ''}
                    </td></>) : (null)}

            </tr>
        })
        /* let departmentList = this.state.departmentList.map((value, index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        })
        let rolesList = this.state.roleList.map((value, index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        }) */
        return (
            <>
                {this.state.showConfirmWindow ? (<div className="popupInstallLivechat popupLivechat" >
                    <div className="popupBody pb-5 px-5 text-center">
                        <div className="py-5 feilds-instruction">
                            <span className="congratilation-circle my-2">
                                <img alt="" src={require('../../assets/img/icon/info.png')} />
                            </span>
                        </div>
                        <div className="pb-5 feilds-instruction">
                            <h6 className="card-title mb-0">Are you sure? You want to delete.</h6>
                        </div>
                        <div>
                            <button className="btnBlue" onClick={this.deletePhoneNumber}>Delete</button> &nbsp;&nbsp;
                            <button className="btnBlue" onClick={this.cancelDelete}>Cancel</button>
                        </div>
                    </div>
                </div>) : (null)}
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                    <h3 className="heding-text">User Management</h3>
                    <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                        <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                    </div>
                </header>
                <LeftPanel />
                <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                    <div className="main-wrapper container-fluid">
                        <div className="main-heading main-heading-title py-5">
                            <h3 className="heding-text">Agents {this.state.paymentStatus ? (<>({this.state.totalAgent} of {this.state.maxAgentAllowed} added)</>) : (null)}</h3>
                        </div>
                        <div className="body-section bg-white px-5 py-5 mobile-tranparent">
                            <div className="agent-section " id="resposnive-table-class">
                                <div className="row">
                                    <div className="aside-agent mobile-display usr-mm-btn">
                                        {!this.state.paymentStatus || this.state.totalAgent >= this.state.maxAgentAllowed ? (<button className="btnBlueSettings add-agent-clik" onClick={this.showAgentMax}>+ <span> Add Agent</span></button>) : (<button className="btnBlueSettings add-agent-clik bb" onClick={this.showAgent}>+ <span> Add Agent</span></button>)}
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 filterMobile-bg">
                                        <div className="mobile-menu text-right">
                                            <button className="responsive-btn " onClick={this.userFilterMobile}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.674" height="20.899" viewBox="0 0 22.674 20.899">
                                                    <path id="Path_1489" data-name="Path 1489" d="M22.608,10.077a.687.687,0,0,0-.622-.394H.687a.687.687,0,0,0-.53,1.124l8.188,9.931V29.9a.687.687,0,0,0,.992.616l4.6-2.28a.687.687,0,0,0,.382-.615l.009-6.878,8.188-9.931A.687.687,0,0,0,22.608,10.077Zm-9.5,9.977a.687.687,0,0,0-.157.436l-.009,6.7-3.226,1.6v-8.3a.687.687,0,0,0-.157-.437l-7.418-9H20.529Z" transform="translate(0 -9.683)" fill="#A2ABD1" />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="agent-table-header table-header row mb-4 user-table-main-header">
                                            <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                                <TextField className="form-control" id="outlined-basic" label="Search" variant="outlined" name="searchbox" value={this.state.searchbox} onChange={this.changehandle} onKeyPress={this.searchInput} />
                                                {/* <input type="text" className="form-control form-control-lg" placeholder="Search" name="searchbox" value={this.state.searchbox} onChange={this.changehandle} onKeyPress={this.searchInput} aria-label="Search for messages or users..." /> */}
                                                <div className="input-group-append">
                                                    <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit" onClick={this.changeSearch}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                            <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="fltr-view">
                                                <div className="aside-agent k-flex align-items-center justify-content-end">

                                                    <div className="aside-agent k-flex align-items-center mr-5 material-select">
                                                        <FormControl variant="outlined" className="material-outlined">
                                                            <InputLabel id="status-outlined-label">Status</InputLabel>
                                                            <Select MenuProps={{ disableScrollLock: true}} labelId="status-outlined-label" id="status-outlined" name="status" value={this.state.status?this.state.status:"all"} onChange={this.changehandle} label="Status">
                                                                <MenuItem value="all">All</MenuItem>
                                                                <MenuItem value="1">Active</MenuItem>
                                                                <MenuItem value="0">Suspended</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                        {/* <h5 className="heding-text text-truncate mr-4">Status</h5>
                                                        <div className="wrapperInfo">
                                                            <select className="customArrow" name="status" onChange={this.changehandle}>
                                                                <option value="">Select Status</option>
                                                                <option value="1">Active</option>
                                                                <option value="0">Suspended</option>
                                                            </select>
                                                        </div> */}
                                                    </div>

                                                    <div className="aside-agent k-flex align-items-center mr-5 material-select">
                                                        <FormControl variant="outlined" className="material-outlined">
                                                            <InputLabel id="demo-simple-select-outlined-label">Roles</InputLabel>
                                                            <Select MenuProps={{ disableScrollLock: true}} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" name="role" value={this.state.role?this.state.role:"all"} onChange={this.changehandle} label="Role">
                                                                <MenuItem value="all">All</MenuItem>
                                                                {this.state.roleList.map((value, index) => {
                                                                    return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    <div className="aside-agent k-flex align-items-center mr-5 material-select">
                                                        <FormControl variant="outlined" className="material-outlined w-150">
                                                            <InputLabel id="demo-simple-select-outlined-label">Departments</InputLabel>
                                                            <Select MenuProps={{ disableScrollLock: true}} labelId="demo-simple-select-outlined-label" id="demo-simple-select-outlined" name="department" onChange={this.changehandle} value={this.state.department?this.state.department:"all"} label="Departments">
                                                                <MenuItem value="all">All</MenuItem>
                                                                {this.state.departmentList.map((value, index) => {
                                                                    return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                                                                })}
                                                            </Select>
                                                        </FormControl>
                                                    </div>

                                                    {/* <div className="aside-agent k-flex align-items-center mr-5">
                                                        <h5 className="heding-text text-truncate mr-4">Roles</h5>
                                                        <div className="wrapperInfo">
                                                            <select className="customArrow" name="role" onChange={this.changehandle}>
                                                                <option value="">Select Role</option>
                                                                {rolesList}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="aside-agent k-flex align-items-center mr-5">
                                                        <h5 className="heding-text text-truncate mr-4">Departments</h5>
                                                        <div className="wrapperInfo">
                                                            <select className="customArrow" name="department" onChange={this.changehandle}>
                                                                <option value="">Select Team</option>
                                                                {departmentList}
                                                            </select>
                                                        </div>
                                                    </div> */}
                                                    <div className="aside-agent mobile-hide">
                                                        {!this.state.paymentStatus || this.state.totalAgent >= this.state.maxAgentAllowed ? (<button className="btnBlueSettings add-agent-clik" onClick={this.showAgentMax}>+ <span> Add Agent</span></button>) : (<button className="btnBlueSettings add-agent-clik" onClick={this.showAgent}>+ <span> Add Agent</span></button>)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="agent-table mobile-agent-table mobile-tranparent">
                                            <table className="table table-borderless mb-0 liveCgatUserTable agentslistTbl mob-tbl-view">
                                                <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                    <tr>
                                                        <th>Name & Email</th>
                                                        <th className="nowrap-text">Phone Number</th>
                                                        <th>Alias</th>
                                                        <th>Team</th>
                                                        <th>Role</th>
                                                        <th>Status</th>
                                                        <th className="nowrap-text" width="150">Last Activity Time</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="outline-1-black border-radius-table">
                                                    {agentEmpty}
                                                    {list}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {this.state.showMaxAgentPopup ? (<div id="shadowMasking" className="popupShadow" style={{ display: this.state.showMaxAgentPopup ? 'block' : 'none' }}></div>) : (null)}
                {this.state.showMaxAgentPopup ? (<div className="popupInstallLivechat popupLivechat revoke-consent">
                    <div className="popupBody py-5 px-5 my-5 mx-4">
                        <span className="heder-icon-img icon-rightpanel-icon" onClick={() => { this.setState({ showMaxAgentPopup: false }) }} style={{ position: 'absolute', right: 10, top: 10 }}>
                            <img style={{ width: 14 }} src={require('../../assets/img/icon/right-panel-crose.svg')} />
                        </span>
                        <div className="feilds-instruction text-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="45.888" height="42.874" viewBox="0 0 25.888 22.874">
                                <path id="Path_2269" data-name="Path 2269" d="M25.659,48.768,14.412,29.285a1.7,1.7,0,0,0-2.936,0L.229,48.768A1.7,1.7,0,0,0,1.7,51.312H24.191A1.7,1.7,0,0,0,25.659,48.768ZM12.96,35.284a1.285,1.285,0,0,1,1.283,1.32L14.03,44a1.076,1.076,0,0,1-2.152,0l-.207-7.4A1.29,1.29,0,0,1,12.96,35.284Zm-.016,13.383a1.336,1.336,0,1,1,1.336-1.336A1.337,1.337,0,0,1,12.944,48.667Z" transform="translate(0 -28.438)" fill="#dd6a6a" />
                            </svg>
                        </div>
                        <div className="deleteOTPfeilds py-5">
                            <p className="card-body-text text-black py-5">You've reached the upper limit of number of agents you can add in your current subscription</p>
                            <p className="card-body-text text-black pb-5">To add more agents update your subscription</p>
                        </div>
                        <div className="text-center">
                            <button className="btnBlueSettings mr-2" onClick={this.opensubscriptionPage}>Manage subscription</button>
                        </div>
                    </div>
                </div>) : (null)}
                <AddAgent sendAgentStatus={this.state.showAddAgent} getAgentStatus={this.sendStatus} />
                <EditAgent agentId={this.state.EditagentId} sendAgentStatus={this.state.showEditAgent} getAgentStatus={this.sendEditStatus} agentDetails={this.state.editAgentDetails} />
                <DeleteAgent agentId={this.state.delagentId} sendAgentStatus={this.state.showDeleteAgent} getAgentStatus={this.sendDeleteStatus} />
                <SuspendAgent agentId={this.state.susAgentId} agentStatus={this.state.susAgentStatus} sendAgentStatus={this.state.showSuspendAgent} getAgentStatus={this.sendSuspendStatus} suspendEmail={this.state.suspendEmail} removePhoneNumber={this.removePhoneNumber} suspendAgentName={this.state.suspendAgentName} suspendAgentAlias={this.state.suspendAgentAlias} />
                <AgentProfile agentId={this.state.viewProfile} deleteAgentProfile={this.deleteAgentprofile} agentStatus={this.state.viewProfileStatus} showProfile={this.state.showAgentProfile} viewProfileData={this.state.viewProfileData} />
                <AddAgentInLivechat agentId={this.state.agentInLivechatId} agentStatus={this.state.AddAgentInLivechatStatus} hideProfile={this.hideaddLivechatProfile} livechatAgentDetails={this.state.livechatAgentDetails} />
            </>
        )
    }
}

export default Agents;
