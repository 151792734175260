import React from 'react';
import LeftPanel from './views/LeftPanel';
import rolesList from '../../localization/roles.json';
import moment from 'moment-timezone';
import { toast } from "react-toastify";
// import db from '../../config/firebase';
import { activityLog } from "../../services/service";
import TextField from '@material-ui/core/TextField';
import firebaseServices from '../../firebase';

const dateFormat="X";
var allRoles = [];
var newRoles = [];
var ObjectArray = [];

class Addroledes extends React.Component{
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            roles:'',
            name:'',
            description:'',
            ownerId:'',
            roleId:'',
            agents:[],
            name_error:'',
            description_error:'',
            loader:false,
            agentList:[],
            role_error:''
        }
    }

    componentDidMount() {
        this.setState({
            roleId:this.props.match.params.id,
            loader:true
        })
        let ownerDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:ownerDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(ownerDetails.ownerId).collection('roles').doc(this.props.match.params.id).get().then((docs) => {
            this.setState({
                loader:false
            })
            if(docs.exists===false) {
               // window.location.href="/roles";
                window.location.href= "/"+ownerDetails.ownerIdentifire+"/roles";
            } else {
                let data = docs.data();
                this.setState({
                    roles:data.access,
                    name:data.name,
                    description:data.description
                })
                let myObj = data.access;
                let array = [];
                Object.keys(myObj).map((key) =>{
                    let values = Object.keys(myObj[key]).map((k) => {
                        return myObj[key][k]
                    })
                    array[key] = values;
                } 
                );
                newRoles = array;
                ObjectArray = {...array};
            }
        })
        // firebaseServices.db.collection('users').doc(ownerDetails.ownerId).collection('agents').where('role','==',this.props.match.params.id).get().then((docs) => {
        //     if(!docs.empty) {
        //         let agentList=[];
        //         docs.forEach(())
        //     }
        // })
        firebaseServices.db.collection('users').doc(ownerDetails.ownerId).collection('agents').where('deletetime','==',0).get().then((docs) => {
            var i=0;
            var agents=[];
            let agentList=[];
            docs.forEach((doc)=>{
                agents[i] = doc.data();
                agents[i]['id']=doc.id;
                if(doc.data().role===this.props.match.params.id) {
                    agentList.push(doc.id);
                }
                i++;
            });
            this.setState({
                agents:agents,
                agentList:agentList
            })
        });

        activityLog("opened edit role page");
    }

    handleChange = (e) => {
        let target = e.target;
        if(target.type==='checkbox') {
            let parentkey = target.getAttribute('item');
            if(parentkey in newRoles === false) {
                allRoles = [];
            } else {
                allRoles = newRoles[parentkey];
            }
            if(target.checked===true) {
                let value = target.value;
                allRoles.push(value);
                newRoles[parentkey]=allRoles;
            } else {
                var index = allRoles.indexOf(target.value);
                if (index !== -1) allRoles.splice(index, 1);
            }
            ObjectArray[parentkey] = {...allRoles};
            if(this.ObjectLength(ObjectArray[parentkey])===0) {
                delete ObjectArray[parentkey];
            }
            this.setState({
                roles:{...ObjectArray},
                role_error:''
            })
        } else if(target.name==="agentList") {
            let values = [].filter.call(target.options, o => o.selected).map(o => o.value);
            this.setState({
                [target.name]:values,
                agentChange:true
            })
        } else {
            this.setState({
                [target.name]:target.value
            })
        }

    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validate();
        if(validate===true){
            this.setState({
                loader:true
            })
            firebaseServices.db.collection('agent-roles').where('name','==',this.state.name.trim()).get().then((data) => {
                let defRoles = '';
                data.forEach((doc) => {
                    defRoles = doc.id;
                })
                firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').where('name','==',this.state.name.trim()).get().then((agentRoles) => {
                    let agentRole='';
                     agentRoles.forEach((agRol) => {
                        agentRole = agRol.id;
                     })
                    if((data.empty===true || agentRole === '' || agentRole === this.state.roleId) && (agentRoles.empty===true || defRoles==='' || defRoles === this.state.roleId)) {
                        let updatedon = this.timeZoneFormat(Date.now());
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').doc(this.state.roleId).update({updatedon:updatedon,name:this.state.name.trim(),description:this.state.description.trim(),access:this.state.roles}).then((ref)=> {
                            // if(this.state.agentChange) {
                            //     this.updateRoles(this.state.roleId);
                            // }
                            this.setState({
                                // name:'',
                                name_error:'',
                                // description:'',
                                description_error:'',
                                loader:false
                            }, () => {
                                // this.props.history.push("/"+this.agent.ownerIdentifire+"/roles");
                                toast.success('Role Has been Updated Sucessfully!');
                                activityLog("");
                            })
                        }).catch((err) => {
                            console.log(err);
                        })
                    } else {
                        this.setState({
                            name_error:'This Role is already added',
                            loader:false
                        })
                    }
                })
            })
            return false;
            
        }
    }

    updateRoles = (roleId) => {
        this.state.agentList.forEach((agentId) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({role:roleId}).then((ref) => {
                // console.log(ref);
            }).catch((err) => {
                console.log(err);
            })
        })
    }

    validate = () => {
        let returnValue = true;
        if(this.state.name.trim()==='') {
            returnValue=false;
            this.setState({
                name_error:'This Field can not be blank'
            })
        } else {
            this.setState({
                name_error:''
            })
        }
        if(this.ObjectLength(this.state.roles)===0) {
            returnValue=false;
            this.setState({
                role_error:'Please select at-least an option'
            })
        } else {
            this.setState({
                role_error:''
            })
        }
        return returnValue;
    }

    ObjectLength = (obj) => {
        var size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.props.history.push("/"+this.agent.ownerIdentifire+"/roles");
    }


    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render(){
        let rolesHtml='';
        let customRoles = rolesList['custom'];
        rolesHtml = Object.keys(customRoles).map((key,index) => {
            let options = Object.keys(customRoles[key]).map((value,ind) => {
                let checked=false;
                if(this.state.roles!=='') {
                    let cArray = this.state.roles[key];
                    if(cArray !== undefined) {
                        if(Object.values(cArray).indexOf(value) > -1) {
                            checked=true;
                        }
                    }
                    
                }
                
                return <div className="col-xs-12 col-md-4" key={ind}>
                <div className=" k-flex align-items-center py-2">
                    <div className="settings-feld form-feilds k-flex align-items-center">
                        <span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
                            <input type="checkbox" name="all_roles[]" value={value} checked={checked} onChange={this.handleChange} item={key} /><span className="checkmark" /></label></span>
                        <h4 className="heding-text">{value}</h4>
                    </div>
                </div>
            </div>
            })
            return <div className="row pb-5" key={index}>       
            <div className="col-xs-12 col-md-12">
                <h4 className="heding-text mb-0">{key}</h4>
            </div>
            {options}
        </div>
        })

        /*let agents = this.state.agents.map((value,index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        }) */
        
        return(
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">User Management</h3>
                <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Agents</h3>
                    </div>
                    <div className="body-section bg-white px-5 py-5">
                        <div className="agent-section">
                        <form onSubmit={this.handleSubmit}>
                            <div className="category-header k-flex align-items-center">
                                <div className="categoryHeaderLeft">
                                    <ul>
                                        <li>
                                            <h4 className="card-title mb-0">Edit Role</h4>
                                        </li>
                                    </ul>
                                </div>
                                <div className="categoryHeaderRight ml-auto">
                                    <ul>
                                        <li><button className="btnBlue">Update</button></li>
                                        <li><button className="btnWhite" onClick={this.cancelForm}>Cancel</button></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="add-role-forms pb-5 mb-11">
                                <div className="row">
                                    <div className="col-xs-12 col-md-6">
                                        <div className="addRoleFormsSide">
                                            <div className="form-feilds pt-5">
                                                <div className="input-group material-input">
                                                    <TextField className="form-control w-100" id="outlined-basic" maxLength="40" label="Name" variant="outlined" value={this.state.name} name="name" onKeyUp={this.validate} onChange={this.handleChange} />
                                                    {/* <p className="card-body-text">Name</p>
                                                    <input type="text" placeholder="Support team Head" maxLength="40" onKeyUp={this.validate} value={this.state.name} onChange={this.handleChange} className="w-100" name="name"/> */}
                                                    <p className="error">{this.state.name_error}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xs-12 col-md-6">
                                        <div className="addRoleFormsSide">
                                            <div className="form-feilds pt-5">
                                                <div className="input-group material-input">
                                                    <TextField className="form-control w-100" rows={4} id="description-outlined-multiline" label="Description" variant="outlined" name="description" onChange={this.handleChange} value={this.state.description}  multiline />
                                                    {/* <p className="card-body-text">Description</p>
                                                    <textarea type="text" placeholder="This role is support team head." value={this.state.description} className="w-100 py-4" onChange={this.handleChange} name="description" rows={4} /> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-xs-12 col-md-6">
                                        <div className="addRoleFormsSide">
                                            <div className="form-feilds pt-5">
                                                <div className="wrapperInfo text-12-select-agent">
                                                    <p className="card-body-text">Select Agents</p>
                                                    <select className="custom-select" name="agentList" value={this.state.agentList} onChange={this.handleChange} multiple>
                                                        {agents}
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div className="add-role-forms borderTop py-5">
                                {rolesHtml}
                                <p className="error">{this.state.role_error}</p>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }
}

export default Addroledes;