import React from 'react';
// import db from '../../config/firebase';
import { MAIL_FUNCTION_URL, GATEWAY_URL,FUNCTION_API_KEY } from '../../config/siteConfig';
import firebaseServices from '../../firebase';
var i = 0;

function verifyjwt () {
    let userDetails = localStorage.getItem('agent');
    userDetails = JSON.parse(userDetails);
    if(userDetails) {
        if(!userDetails.globalLogin) {
            let details = localStorage.getItem('accessToken') ? localStorage.getItem('accessToken') : (userDetails ? userDetails.token : null);
            if(userDetails!==null && details!==null) {
                let body = [`token=${details}&uid=${userDetails.ownerId}&type=${userDetails.type}&agentId=${userDetails.agentId}`];
                //fetch(MAIL_FUNCTION_URL+"/loginApi/verify", {
                fetch(GATEWAY_URL+"/verify", {
                    method:"post",
                    body:body,
                    headers : { "X-API-Key" : FUNCTION_API_KEY, "Content-type": "application/x-www-form-urlencoded" }
                })
                .then(response => {
                    if(response.status!== 404 && response.status!==500) {
                        return response.json();
                    } else {
                        return false;
                    }
                }).then(response => {
                    if(response.valid===true) {
                    } else {
                        i++;
                        if(i===4) {
                            window.location.href=  "/"+userDetails.ownerIdentifire+"/logout";
                        } else {
                            verifyjwt();
                        }
                    }
                })
            }
        }
    } else {
        localStorage.removeItem('agent');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('agentData');
        localStorage.removeItem('common_redirect');
        localStorage.removeItem('current_login');
        localStorage.removeItem('userdata');
        localStorage.removeItem('loggingout');
        localStorage.removeItem('companyName');
        window.location.href=  "/";
    }
}

function logout(userDetails){
    var ownerId = userDetails.ownerId;
    var agentId = userDetails.agentId
    firebaseServices.db.collection("users").doc(ownerId).collection("agents").doc(agentId).update({online_status:0}).then((ref) => {
        localStorage.removeItem('agent');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('agentData');
        localStorage.removeItem('common_redirect');
        localStorage.removeItem('current_login');
        window.location.href = "/"+userDetails.ownerIdentifire+"/login";
        return false;
    })
}

const VeriftJwToken = (props) => {
    let details = localStorage.getItem('accessToken');
    let userDetails = localStorage.getItem('agent');
    userDetails = JSON.parse(userDetails);
    if(userDetails!==null && userDetails!==undefined && typeof userDetails!==undefined && details!==null && details!==undefined && typeof details!==undefined) {
        setInterval(() => {
            verifyjwt();
        }, 120000)
        props.changeVerifyToken();
    }
    return <></>;
};

export default VeriftJwToken;