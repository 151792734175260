/**
 * 
 * @description agent inital setup page
 * @file  AgentProfileComponent.js
 * @author Bineet Kumar Chaubey <bineet@appypiellp.com>
 * 
 */
import React, { Component } from 'react';
import './css/cums.css'
// import './css/mfirebaseServices.db.min.css'
// import { fb } from "../../config/firebase";
import Reactselect from 'react-select';
import Resizer from 'react-image-file-resizer';
import { validation } from "./../../config/SignupLogin";
import { updateAgentAndChatBotdata, updateAgentData } from './Model/DbModel'
// import db from '../../config/firebase';
import { PROFILE_FOLDER, PROFILE_FOLDER_AVATAR } from './badgeIconConfig'
import { editOwnerInAws } from './../../services/service';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from "../../config/siteConfig";
import firebaseServices from '../../firebase';
import { removeHtmlTags } from '../livechat/components/Comman';
var timezones = require('../../localization/timezones.json');
class AgentProfileComponent extends Component {

    timezoneOptions = [{ value: "", label: "Select Timezone" }];

    constructor(props) {
        super(props);
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};

        timezones.map((values) => {
            this.timezoneOptions.push({
                value: values, label: values
            });
        });

        this.state = {
            isImageUplode: false,
            fileUploadBase64: '',
            clientBackgroundColor: '#000',
            company_name: '',
            agentName: 'Harry Potter',
            agentNameError: '',
            welcomeMessage: "Hello! Welcome to Acme. My name is Agent, How may I help you today?",
            tempFile: '',
            uploadFileError: '',
            loader: false,
            shortName: '',
            chatIconImageURL: 'https://firebasestorage.googleapis.com/v0/b/reacttest-29b23.appspot.com/o/chat_icon%2Fwhite_icon%2Fcomments.svg?alt=media&token=666ddd0d-10e8-4728-ada3-ef193cb9e0b5',
            headerClr: '#000',
            botButtonColor: '#000',
            clientBubbleClr: '#000',
            widgetBackgroundColor: '#000',
            firbaseImageURL: '',
            firbaseImageName: '',
            storageRefImg: '',
            haveEditedWelcome: false,
            welcomeError: '',
            timezone_error: "",
            selectedTimezone: { value: this.Agent.org_timezone?this.Agent.org_timezone:"", label: this.Agent.org_timezone?this.Agent.org_timezone:"Select Timezone" },
            timezone: this.Agent.org_timezone?this.Agent.org_timezone:""
        }
    }

    /**
     * Component Did mount 
     */
    componentDidMount = async () => {

        this.setState({ loader: true });
        // let userDetails=JSON.parse(localStorage.getItem('agent'));
        let userDetails = JSON.parse(localStorage.getItem('agent'));

        if (userDetails && userDetails.ownerId) {
            var res = await validation(userDetails.ownerId);
            if (res.valid) {
                this.setState({ loader: false });

            } else {
                this.setState({ loader: false });
                //this.props.history.push("/login");
                window.location = '/login'
                return
            }
        } else {
            this.setState({ loader: false });
            // this.props.history.push("/login");
            window.location = '/login'
            return
        }


        const uid = userDetails.ownerId
        const BotID = userDetails.agentId
        const company_name = userDetails.company_name
        this.setState({ company_name: company_name, loader: true });

        firebaseServices.db.collection('users').doc(`${uid}`).collection('agents').doc(`${BotID}`).get()
            .then(agentDocumentsnapshot => {
                if (agentDocumentsnapshot.exists) {
                    let name = agentDocumentsnapshot.data().name
                    let profile_img = agentDocumentsnapshot.data().profile_img
                    let storageRefImg = agentDocumentsnapshot.data().profile_fbs_ref
                    let profile_pic_name = agentDocumentsnapshot.data().pic_ref
                    this.setState({
                        agentName: name,
                        firbaseImageURL: profile_img,
                        firbaseImageName: profile_pic_name,
                        storageRefImg: storageRefImg,
                        loader: false
                    })
                }

            }).catch(err => {
                console.log(err);
            })

        firebaseServices.db.collection('users').doc(`${uid}`).collection('chatbots').doc(`${BotID}`).collection('editedBots').doc('template').get()
            .then(agentDocumentsnapshot => {
                if (agentDocumentsnapshot.exists) {
                    // let name = agentDocumentsnapshot.data().name
                    let chatIconImageURL = agentDocumentsnapshot.data().customisation.widgetImage
                    let clientBubbleClr = agentDocumentsnapshot.data().customisation.clientBubbleClr
                    let botButtonColor = agentDocumentsnapshot.data().customisation.botButtonColor
                    let headerClr = agentDocumentsnapshot.data().customisation.headerClr
                    let widgetBackgroundColor = agentDocumentsnapshot.data().customisation.widgetBackgroundColor
                    let headerName = agentDocumentsnapshot.data().customisation.headerName
                    let clientBackgroundColor = agentDocumentsnapshot.data().customisation.clientBackgroundColor

                    let dataWidgetSetup = agentDocumentsnapshot.data()
                    let welcomeMessage = dataWidgetSetup['0'].data.text

                    /**
                     * headerClr color in chat bot is place in two location
                     * one in header of chat bot and 2nd is in send button in chatbot
                     */



                    // then this
                    // set dynamic Welcome Message By company Name
                    const agentName = this.state.agentName ? this.state.agentName : 'Agent';
                    let defaultMessage = `Hello! Welcome to ${headerName}. My name is ${agentName}, How may I help you today?`;

                    this.setState({
                        chatIconImageURL: chatIconImageURL,
                        clientBubbleClr: clientBubbleClr,
                        botButtonColor: botButtonColor,
                        headerClr: headerClr,
                        widgetBackgroundColor: widgetBackgroundColor,
                        welcomeMessage: welcomeMessage ? welcomeMessage : defaultMessage,
                        clientBackgroundColor: clientBackgroundColor,
                        company_name: headerName,


                    })
                }
            }).catch(err => {
                console.log(err);
            })
    }

    /**
     * Handler Agent Handler Name 
     */
    hendleAgentName = (e) => {
        let companyName = this.state.company_name ? this.state.company_name : 'Acme';
        let welcomeMsg = this.state.haveEditedWelcome ? this.state.welcomeMessage : `Hello! Welcome to ${companyName}. My name is ${e.target.value}, How may I help you today?`
        //remove html tag from string
        welcomeMsg = removeHtmlTags(welcomeMsg);
        companyName = removeHtmlTags(companyName);
        console.log("-------agentprofilecomponent.js-------", welcomeMsg, companyName);
        this.setState({ agentName: e.target.value, agentNameError: '', welcomeMessage: welcomeMsg })
    }

    /**
     * Handler Welcome Input box on change hendler  
     */
    hendleagentWelcomeValue = (e) => {
        const haveEditedWelcome = e.target.value.trim() === this.state.welcomeMessage ? false : true;
        this.setState({ welcomeMessage: e.target.value, haveEditedWelcome: haveEditedWelcome })
    }

    /**
     * Image Upload Button Handler 
     */
    fileUploadButton = () => {
        document.getElementById('fileButton').click();
        document.getElementById('fileButton').onchange = () => {

            this.setState({ fileUploadState: document.getElementById('fileButton').value });
        }
    }

    /**
     * handle Files when Upload 
     * @param file upload File Instance
     */
    handleFile = async (files) => {

        this.setState({ 'uploadFileError': '' })
        const tempFile = files[0];
        if (tempFile.size >= 5 * 1024 * 1024) {
            // alert("JPG images of maximum 5MB");

            this.setState({ 'uploadFileError': 'Files Should be 5 MB having JPEG and PNG format only' })

            setTimeout(() => {
                this.setState({ 'uploadFileError': '' })
            }, 5000);
            return;
        }

        const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (!validImageTypes.includes(tempFile.type)) {
            // invalid file type code goes here.

            this.setState({ 'uploadFileError': 'Files Should be 5 MB having JPEG and PNG format only' })
            setTimeout(() => {
                this.setState({ 'uploadFileError': '' })
            }, 5000);
            return;
        }

        const base64Name = await this.toBase64(files[0])

        this.setState({ fileUploadBase64: base64Name, isImageUplode: true, tempFile: tempFile }, () => {

        })
    }

    /**
     * Delete Image From Temp File
     * 
     */
    deleteSelectedImage = () => {
        document.getElementById('fileButton').value = ''
        this.setState({ fileUploadBase64: '', isImageUplode: false })
    }

    /**
     * Delete Image On firebase storage
     */
    deleteFireBaseStorageImage = async () => {
        this.setState({ loader: true })
        const agent = localStorage.getItem('agent');
        const parsedata = JSON.parse(agent);
        const userID = parsedata.ownerId;
        const chatID = parsedata.agentId;

        const agentInitialData = {
            profile_img: '',
            profile_fbs_ref: '',
            profile_img_orig: '',
            pic_ref: '',
        }

        const returnData = await updateAgentData(userID, chatID, agentInitialData);
        if (returnData) {
            // Create a reference to the file to delete
            // var desertRef = fb.storage().refFromURL(this.state.firbaseImageURL)
            // PROFILE_FOLDER, PROFILE_FOLDER_AVATAR
            var desertRef = firebaseServices.fb.storage().ref(`${PROFILE_FOLDER}/${this.state.firbaseImageName}`).delete()
            var desertAvatarRef = firebaseServices.fb.storage().ref(`${PROFILE_FOLDER}/${PROFILE_FOLDER_AVATAR}/${this.state.firbaseImageName}`).delete();
            // Delete the file
            Promise.all([desertAvatarRef, desertRef]).then((result) => {
                // File deleted successfully

                // storageRefImg
                this.setState({ firbaseImageURL: '', firbaseImageName: '', fileUploadBase64: '', isImageUplode: false, loader: false })

            }).catch((error) => {
                console.log(error, 'error');
                this.setState({ loader: false })
            });
        } else {

            this.setState({ loader: false })
        }
    }

    /**
     *  Get file Base64 Encription Code
     */
    toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    /**
     *  Git Client First name And Last name
     */
    getClientFistAndLastName = () => {
        const nameTochange = this.state.agentName ? this.state.agentName : 'Harry Potter';
        const countlength = nameTochange.trim().split(' ').length;

        if (countlength === 1) {
            return nameTochange.trim().substring(0, 2).toUpperCase();
        } else {
            // var name = 'Foo Bar 1Name too Long';
            var initials = nameTochange.match(/\b\w/g) || [];
            initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();

            return initials;
        }
    }

    /**
     * Save data in table
     */
    validateAndUplodeAndsave = async () => {
        const { tempFile, agentName, welcomeMessage, timezone } = this.state
        let agent_name = removeHtmlTags(agentName);
        let wel_msg = removeHtmlTags(welcomeMessage);
        this.setState({ agentName: agent_name, welcomeMessage: wel_msg })
        console.log('tempFile >>>> ', tempFile, agentName, welcomeMessage);
        if (!agentName || !agentName.trim()) {
            this.setState({ agentNameError: 'Please enter agent name' });
            return false
        }

        if (!welcomeMessage || !welcomeMessage.trim()) {
            this.setState({ welcomeError: 'Please enter welcome message' });
            return false
        }

        if (!timezone || !timezone.trim()) {
            this.setState({ timezone_error: 'Plese select a timezone' });
            return false;
        }

        const agent = localStorage.getItem('agent');
        const parsedata = JSON.parse(agent);

        this.setState({ loader: true })
        // let  imageUrl = '';
        let imageObject = {
            imageUrl: '',
            imageRef: ''
        };

        let file = '';
        let fileName = '';
        if (tempFile) {
            file = tempFile;
            fileName = tempFile.name;
        }

        let firstName = '';
        let lastName = '';
        if (agentName.split(' ').length > 1) {
            firstName = agentName.split(' ').slice(0, -1).join(' ');
            lastName = agentName.split(' ').slice(-1).join(' ');
        } else {
            firstName = agentName;
            lastName = DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
        }
        var editOwner = await editOwnerInAws(firstName, firstName, lastName, this.Agent.email, this.Agent.timezone, file, fileName, '', '');
        console.log('editOwnerInAws >>>> ', editOwner);


        // const datatoSave = {
        //     imageUrl : imageUrl,
        //     client_name: agentName,
        //     wel_msg: welcomeMessage
        // } 
        const fdata = {
            '0.data.text': welcomeMessage,
            'online.welcomemessage.text': welcomeMessage
        }

        const userID = parsedata.ownerId;
        const chatID = parsedata.agentId;
        await updateAgentAndChatBotdata(userID, chatID, fdata);

        /* Update timezone for owner start */
        if(!this.Agent.org_timezone) {
            parsedata.org_timezone = timezone;  // set in localstorage so will not show twice.
            firebaseServices.db.collection('users').doc(`${userID}`).update({ org_timezone: timezone });
        }
        /* Update timezone for owner end */

        const shortName = this.getClientFistAndLastName();
        let agentInitialData = '';
        if (imageObject.agentAvatarImage && imageObject.imageRef) {
            agentInitialData = {
                agent_short: shortName,
                profile_img: imageObject.agentAvatarImage,
                profile_img_orig: imageObject.imageUrl,
                profile_fbs_ref: imageObject.imageRef,
                pic_ref: imageObject.filename,
                login_status: 1,
                name: agentName.trim(),
            }
        } else {
            agentInitialData = {
                agent_short: shortName,
                login_status: 1,
                name: agentName.trim(),
            }
        }
        // let agent = localStorage.getItem('agent');
        parsedata.name = agentName.trim();
        parsedata.agent_short = shortName
        parsedata.agent_name = agentName.trim();
        localStorage.setItem('agent', JSON.stringify(parsedata));
        const returnData = await updateAgentData(userID, chatID, agentInitialData);
        if (returnData) {
            this.setState({ loader: false })
            // this.props.history.push("/widget-setup");
            let userDetails = JSON.parse(localStorage.getItem('agent'));
            // window.location.href = '/' + userDetails.organization_alias + '/upgrade-account';
            window.location.href = '/' + userDetails.organization_alias 
        } else {
            this.setState({ loader: false })
        }
    }

    previewSlide() {
        var element = document.getElementById("agentWidget");
        element.classList.toggle("show");
    }

    /**
     * Upload Agent Logo
     */
    uploadAgentLogo = async (file) => {
        const storageRef = firebaseServices.fb.storage().ref();
        // req.attach(file.name, file);
        // const generateDafaya = 11
        const uniqId = Date.now().toString()
        const fileName = file.name;
        const extension = fileName.substring(fileName.lastIndexOf('.') + 1);
        const newFileName = `client_img_${uniqId}.${extension}`;
        const metadata = {
            contentType: file.type
        }
        // // PROFILE_FOLDER, PROFILE_FOLDER_AVATAR
        var uploadTask = storageRef.child(`${PROFILE_FOLDER}/${newFileName}`).put(file, metadata);
        // this.setState({uploading:true})

        let promise2 = new Promise(async (resolve, reject) => {
            Resizer.imageFileResizer(
                file,
                64,
                64,
                'PNG',
                100,
                0,
                async uri => {
                    const task = storageRef.child(`${PROFILE_FOLDER}`).child(`${PROFILE_FOLDER_AVATAR}`).child(newFileName).put(uri, metadata);
                    await task
                        .then(snapshot => snapshot.ref.getDownloadURL())
                        .then(url => {
                            resolve(url);
                        })
                        .catch(error => {
                            console.log(error.message);
                            reject(error)
                        });
                },
                'blob'
            );
        })



        // return uploadTask
        //     .then(async snapshot => {
        //         // console.log(snapshot.ref.toString())
        //         // return snapshot.ref.getDownloadURL();
        //         const downloadurl = await snapshot.ref.getDownloadURL();
        //         const imageObject = {
        //             imageRef: snapshot.ref.toString(),
        //             imageUrl: downloadurl,
        //         }
        //         // console.log('............')
        //         // console.log(downloadurl); 
        //         return imageObject

        //     })
        //     .catch(err => {
        //         console.log(err);
        //         return false
        //     })


        return Promise.all([uploadTask, promise2]).then(async (result1) => {
            const downloadurl = await result1[0].ref.getDownloadURL();
            const imageObject = {
                imageRef: result1[0].ref.toString(),
                imageUrl: downloadurl,
                agentAvatarImage: result1[1],
                filename: newFileName
            }
            // console.log('............')
            // console.log(downloadurl); 
            return imageObject
        }).catch(err => {
            console.log(err.message);

        })

    }


    selectTimezone = (e) => {

        if(this.Agent.org_timezone && this.Agent.org_timezone !== "") {
            this.setState({ timezone_error: 'It can not change now.' });
            return false;
        }

        if (e.value === '') {
            this.setState({ timezone_error: 'Plese select a timezone' });
        } else {
            this.setState({ timezone_error: '' });
        }

        this.setState({
            timezone: e.value,
            selectedTimezone: e
        });
    }

    render() {
        return (
            <React.Fragment>
                {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

                <div className="customize-widget">
                    <div className="container ps-relative">
                        <div className="getstarted-header">
                            <div className="logo-section">
                                <img alt="" src={require('./../../assets/images/appypie-livechat.svg')} />
                            </div>
                        </div>
                        <div className="custmize-your-widget ">
                            <div className="custmize-your-widget-heading custmize-heading text-center py-9">
                                <h1 className="card-title">Create an agent profile</h1>
                                <p className="card-body-text">It'll help your visitors and customers to know you better</p>
                            </div>
                            <div className="custome-widget-bot k-flex align-items-center justify-content-center pb-5 position-relative">
                                <div className="widget-settings ">
                                    <div className="widget-badge widget-sec pb-8 setupt-name widget-name">
                                        <h4 className="card-title mb-0">Agent Name * </h4>
                                        <div className="md-form md-outline">
                                            <input name="email" placeholder="Harry Potter" maxLength="155" id="input-char-counter1" type="text" value={this.state.agentName} className="form-control" onChange={(e) => this.hendleAgentName(e)} />
                                            {/* <label htmlFor="input-char-counter1" className="active">Name</label> */}
                                            {this.state.agentNameError && <span style={{ color: 'red' }} >{this.state.agentNameError}</span>}
                                        </div>

                                    </div>
                                    <div className="widget-theme widget-sec pb-8">
                                        <h4 className="card-title mb-0">Agent Profile Picture</h4>
                                        <p className="card-body-text text-lightgray py-2 light-gray-text font-ligts-support">Support files of 5 MB having JPEG and PNG format only</p>



                                        <input id="fileButton" type="file" hidden onChange={(e) => this.handleFile(e.target.files)} />


                                        {

                                            !this.state.firbaseImageURL ? (


                                                !this.state.isImageUplode ?

                                                    (
                                                        <>
                                                            <button className="btnUploadImage btn-radius uploadagentprofile py-3 px-7 mt-3" onClick={this.fileUploadButton} style={{ width: 'auto' }}>

                                                                <span>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="19.085" height="18.351" viewBox="0 0 19.085 18.351">
                                                                        <g id="arrows" transform="translate(0 -9.136)">
                                                                            <g id="Group_1917" data-name="Group 1917" transform="translate(0 9.136)">
                                                                                <path id="Path_1859" data-name="Path 1859" d="M18.764,320.094a1.061,1.061,0,0,0-.78-.321h-4.9a2.263,2.263,0,0,1-.809,1.055,2.1,2.1,0,0,1-1.267.413H8.074a2.1,2.1,0,0,1-1.267-.413A2.264,2.264,0,0,1,6,319.773H1.1a1.062,1.062,0,0,0-.78.321,1.061,1.061,0,0,0-.321.78v3.67a1.061,1.061,0,0,0,.321.78,1.062,1.062,0,0,0,.78.321H17.984a1.1,1.1,0,0,0,1.1-1.1v-3.67A1.061,1.061,0,0,0,18.764,320.094Zm-4.3,3.865a.72.72,0,1,1,.218-.516A.706.706,0,0,1,14.463,323.959Zm2.936,0a.72.72,0,1,1,.218-.516A.705.705,0,0,1,17.4,323.959Z" transform="translate(0 -307.294)" fill="#969696" />
                                                                                <path id="Path_1860" data-name="Path 1860" d="M91.815,15.743h2.936v5.138a.744.744,0,0,0,.734.734h2.936a.744.744,0,0,0,.734-.734V15.743h2.936a.685.685,0,0,0,.677-.459.652.652,0,0,0-.161-.791L97.469,9.354a.72.72,0,0,0-1.032,0L91.3,14.492a.652.652,0,0,0-.161.791A.686.686,0,0,0,91.815,15.743Z" transform="translate(-87.41 -9.136)" fill="#969696" />
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </span>&nbsp;Upload image</button>
                                                            {this.state.uploadFileError && <span className="Loginwarning d-block" style={{ color: 'red' }}> {this.state.uploadFileError} </span>}
                                                        </>

                                                    )

                                                    :
                                                    (
                                                        <div>
                                                            <img alt="" height="40px" src={this.state.fileUploadBase64} />
                                                            <span onClick={() => this.deleteSelectedImage()} className="del-photo-user">
                                                                <svg id="Group_5303" data-name="Group 5303" xmlns="http://www.w3.org/2000/svg" width="35.714" height="45.918" viewBox="0 0 35.714 45.918">
                                                                    <g id="Group_5302" data-name="Group 5302">
                                                                        <path id="Path_2867" data-name="Path 2867" d="M64,115.945a5.1,5.1,0,0,0,5.1,5.1H89.51a5.1,5.1,0,0,0,5.1-5.1V85.333H64Z" transform="translate(-61.449 -75.129)" fill="#ea7676" />
                                                                        <path id="Path_2868" data-name="Path 2868" d="M69.453,2.551,66.9,0H54.147L51.6,2.551H42.667v5.1H78.381v-5.1Z" transform="translate(-42.667)" fill="#ea7676" />
                                                                    </g>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    )
                                            ) : (

                                                <div>
                                                    <img alt="" height="40px" src={this.state.firbaseImageURL} />
                                                    <span onClick={() => this.deleteFireBaseStorageImage()} className="del-photo-user">
                                                        <svg id="Group_5303" data-name="Group 5303" xmlns="http://www.w3.org/2000/svg" width="35.714" height="45.918" viewBox="0 0 35.714 45.918">
                                                            <g id="Group_5302" data-name="Group 5302">
                                                                <path id="Path_2867" data-name="Path 2867" d="M64,115.945a5.1,5.1,0,0,0,5.1,5.1H89.51a5.1,5.1,0,0,0,5.1-5.1V85.333H64Z" transform="translate(-61.449 -75.129)" fill="#ea7676" />
                                                                <path id="Path_2868" data-name="Path 2868" d="M69.453,2.551,66.9,0H54.147L51.6,2.551H42.667v5.1H78.381v-5.1Z" transform="translate(-42.667)" fill="#ea7676" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>

                                            )
                                        }

                                    </div>
                                    <div className="widget-badge widget-sec widget-message ">
                                        <h4 className="card-title mb-0 pb-2">Enter welcome message * </h4>
                                        <div className="md-form md-outline"><textarea name="email" rows="4" onChange={(e) => this.hendleagentWelcomeValue(e)} id="input-char-counter1" type="text" className="form-control" value={this.state.welcomeMessage} />
                                            {this.state.welcomeError && <span style={{ color: 'red' }} >{this.state.welcomeError}</span>}
                                        </div>
                                    </div>

                                    <div className="widget-badge widget-sec widget-message mt-5 time_zone d-flex flex-wrap align-items-center">
                                        <h6 className="card-title mb-0 pb-2 mr-3">Please select your timezone  </h6>
                                        <div>
                                            <Reactselect name="timezone" options={this.timezoneOptions} onChange={this.selectTimezone} value={this.state.selectedTimezone} />
                                            {this.state.timezone_error && (<p className="error">{this.state.timezone_error}</p>)}
                                        </div>
                                        <p>* timezone can't be changed later</p>
                                    </div>


                                    <div className="widget-badge widget-sec widget-message pt-8 mob-view">
                                        <button className="btnBlueNext w-200 preview-btn" onClick={() => this.previewSlide()}>Preview</button>
                                        <button className="btnBlueNext w-170" onClick={() => this.validateAndUplodeAndsave()} >Next</button>
                                    </div>


                                    {/* <div className="next-btn text-center py-8">
                                        <button className="btnBlueNext w-170" onClick={() => this.validateAndUplodeAndsave()} >Next</button>
                                    </div> */}

                                </div>
                                <span className="bodress-widgets  ml-5 mr-5" />
                                <div className="widget-phone mob-hide agentWidget" id="agentWidget">
                                    <a className="close-icon" onClick={() => this.previewSlide()} style={{ background: this.state.headerClr }}>X</a>
                                    <div className="widget-screen k-flex align-items-flex-end">
                                        <div className="screen position-relative">
                                            <div className="screen-header px-5 py-3" style={{ background: this.state.headerClr }}>
                                                <p>
                                                    {this.state.company_name ? this.state.company_name : 'Acme'}
                                                </p>
                                            </div>
                                            <div className="screen-body">
                                                <div className="body-chat">
                                                    <div className="recived k-flex align-items-flex-end pt-3">


                                                        {!this.state.firbaseImageURL ? (

                                                            this.state.isImageUplode ?
                                                                <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }}>
                                                                    <img alt="" src={this.state.fileUploadBase64} />
                                                                </span>

                                                                :
                                                                <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }}>
                                                                    {this.getClientFistAndLastName()}
                                                                </span>


                                                        ) : (
                                                            <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }}>
                                                                <img alt="" height="40px" src={this.state.firbaseImageURL} />
                                                            </span>
                                                        )
                                                        }

                                                        <div className="message-box text-left">
                                                            <span className="chat-user-Name">{this.state.agentName ? this.state.agentName : 'Harry Potter'}</span>
                                                            <div className="message"> {this.state.welcomeMessage} </div>
                                                        </div>
                                                    </div>
                                                    <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                                        <div className="message-box">
                                                            <div className="message message-line" style={{ background: this.state.clientBubbleClr }}>
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                        {/* <span className="user"><img alt="" src={require('./../../assets/images/boy.png')} /></span> */}
                                                        <span className="user"><img alt="" src={require('./../../assets/images/avtare-user.svg')} /></span>
                                                    </div>
                                                    <div className="recived k-flex align-items-flex-end pt-3">
                                                        {/* <span className="user"><img alt="" src={this.state.fileUploadBase64} /></span> */}


                                                        {!this.state.firbaseImageURL ? (

                                                            this.state.isImageUplode ?
                                                                <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }}>
                                                                    <img alt="" src={this.state.fileUploadBase64} />
                                                                </span>

                                                                :
                                                                <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }} >
                                                                    {this.getClientFistAndLastName()}
                                                                </span>


                                                        ) : (
                                                            <span className="user" style={{ backgroundColor: this.state.clientBackgroundColor }}>
                                                                <img alt="" height="40px" src={this.state.firbaseImageURL} />
                                                            </span>
                                                        )

                                                        }




                                                        <div className="message-box">
                                                            <span className="chat-user-Name">{this.state.agentName ? this.state.agentName : 'Harry Potter'} </span>
                                                            <div className="message message-line">
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                                                        <div className="message-box">
                                                            <div className="message message-line" style={{ background: this.state.clientBubbleClr }}>
                                                                <span className="chat-Line" style={{ width: '130px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '100px' }}>&nbsp;</span>
                                                                <span className="chat-Line" style={{ width: '70px' }}>&nbsp;</span>
                                                            </div>
                                                        </div>
                                                        {/* <span className="user"><img alt="" src={require('./../../assets/images/boy.png')} /></span> */}
                                                        <span className="user"><img alt="" src={require('./../../assets/images/avtare-user.svg')} /></span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="screen-footer">
                                                <div className="scr-footer-1 k-flex align-items-center py-2 px-3 border-top">
                                                    <input type="text" readOnly placeholder="Type here to chat..." />
                                                    <span className="send-btn" style={{ background: this.state.headerClr }}>
                                                        <img alt="" src={require('./../../assets/images/widget/send2.svg')} />
                                                    </span>
                                                </div>
                                                <div className="powerby">
                                                    <p>
                                                        <span className="powerdBySnapyIcon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                                                <g data-name="Layer 2">
                                                                    <g data-name="Layer 1">
                                                                        <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                                                        <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                        Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{ width: '22%', position: 'relative', top: '-2px' }} />
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="widgets ml-4" style={{ background: this.state.widgetBackgroundColor }}>
                                            {/* <img alt="" src={require('./../../assets/images/widget/checked1.svg')} /> */}
                                            <img alt="" src={this.state.chatIconImageURL} />

                                        </div>


                                    </div>
                                </div>
                            </div>
                            {/* <div className="next-btn text-center py-8">
                                <button className="btnBlueNext w-170" onClick={() => this.validateAndUplodeAndsave()} >Next</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default AgentProfileComponent;