/**
 * @description On bording/SignUP/login functionality Helper function  Configuration file
 * 
 * @author Bineet Kumar Chaubey <bineet@appypiellp.com>
 * @version 1.0
 * @package
 * @todo - need to Remove Inwanted/Unused code
 * 
 */

// import firebase, { firebaseAuth, googleProvider } from "./firebase";
import { request } from "graphql-request";
import { commonLoginApi, TEST_LOGIN_TOKEN, MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from "../config/siteConfig.js";
import { getApiAccessToken, addUserInCommonLogin } from "../layout/utils.js"

import { getUserSession, getAgentData, updateCompanyDetails } from "../services/service";
import firebaseServices from "../firebase";

const moment = require("moment-timezone");
var md5 = require("md5");

/**
 * 
 * @param {string} email 
 */
export const getConvertPassCed = (email) => {
	return md5(email);
}

/**
 * Registration method
 * @description This Api Return Status Code 201 For New Email, And Retrun 402 If EMail Already Exists 
 * 
 * 
 * @param email string
 * @param password Password
 * @returns Object
 */
export const registration = async (email, password) => {
	const FEED_QUERY = `mutation log($email:String!,$password:String!)
		{
			commonSignUp(username:$email,password:$password,websitefrom:"chat.appypie.com") {
				data
				status
				msg
			}
		}`;
	var varb = {
		email: email,
		password: password
	};
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varb)
			.then(res => {
				resolve({
					status: res.commonSignUp.status,
					message: res.commonSignUp.msg,
					data: res.commonSignUp.data
				});
			})
			.catch(err => {
				resolve({
					status: err.response.data.commonSignUp.status,
					message: err.response.data.commonSignUp.msg
				});
			});
	});
};

/**
 * @description Handle Confirmation code Send data to Serevr For Verify code 
 * 
 * @param email String
 * @param code Number
 * @returns object  
 */
export const confirmationCode = async (email, code) => {
	// console.log(email, code);
	const FEED_QUERY = `query log($email:String!,$code:String!)
		{
			confirmSignUp(username:$email,confirmationCode:$code)
			{
				data
				status
				msg
			}
		}`;
	var varibles = {
		email: email,
		code: code
	};
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varibles)
			.then(res => {
				resolve({
					status: res.confirmSignUp.status,
					message: res.confirmSignUp.msg
				});
			})
			.catch(err => {
				// console.log(err, "error");
				reject({
					status: 500,
					message: err.message
				});
			});
	});
};

// Login method

/**
 * 
 * @param {string} email 
 * @param {String} password 
 */
export const login = async (email, password) => {
	// var response = "";
	const FEED_QUERY = `query log($email:String!,$password:String!){
                    login(username:$email,password:$password)
                    {
                        data
                        status
                        msg
                    }
	            }`;
	var varibles = {
		email: email,
		password: password
	};


	// var _res = await new Promise((resolve, reject) => {
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varibles)
			.then(async res => {
				resolve({
					status: res.login.status,
					message: res.login.msg,
					data: res.login.data
				});
			})
			.catch(err => {
				// console.log(err, "error");
				resolve({
					status: err.data.login.status,
					message: err.data.login.msg
				});
			});
	});



	// if (_res.status == 200) {
	// 	var _data = JSON.parse(_res.data);
	// 	localStorage.setItem("accessToken", _data.accessToken.jwtToken);
	// 	response = await login_collection(
	// 		email,
	// 		password,
	// 		_data.accessToken.jwtToken
	// 	);
	// } else {
	// 	console.log(_res.message);
	// 	response = _res.message;
	// }


	// return response;
};

const updateSession = async (token, email) => {
	await firebaseServices.db
		.collection("main_user")
		.doc(email)
		.update({ session: token });
};

const gettingSessionId = async (uid) => {

	return firebaseServices.db
		.collection("users")
		.doc(uid)
		.get()
		.then(doc => {
			return doc.data();
		}).catch(err => {
			return false
		}
		)

};

export const validation = async (uid) => {
	var res = {
		valid: true,
		tablealias: null,
		agent_id: null
	};
	var token = localStorage.getItem("accessToken");
	// console.log(token);
	if (!token) {
		return {
			valid: false,
			tablealias: "",
			agent_id: ""
		};
	}
	var data = await gettingSessionId(uid);
	// console.log(data);
	// console.log('verify data');
	// return
	var session = data?.token;
	if (token === session && token !== null) {
		res = {
			valid: true,
			tablealias: data.tablealias,
			agent_id: data.agent_id
		};
	} else {
		// window.location.href = "/login";
		res = {
			valid: false,
			tablealias: "",
			agent_id: ""
		};
	}
	return res;
};
/**
 * 
 * @description Send A new Code to User For varification
 * 
 * @param {string} email 
 */
export const resendConfirmationCode = email => {
	// console.log(email, "resendConfirmationCode");
	const FEED_QUERY = `query log($email:String!)
		{	
			resendConfirmationCode(username:$email) {
				data
				status
				msg
			}
		}`;
	var varibles = {
		email: email
	};
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varibles)
			.then(res => {
				resolve({
					status: res.resendConfirmationCode.status,
					message: res.resendConfirmationCode.msg
				});
			})
			.catch(err => {
				// console.log(err, "error in resending confirmation code");
				reject({
					status: 400,
					errorObject: err,
					message: err.message
				});
			});
	});
};

/* 
* @description Send A new Code to User  for Update new Password In case of forget 
* 
* @param {string} email 
*/
export const forgetPassword = email => {
	const FEED_QUERY = `query log($email:String!)
		{	
			forgotPassword(username:$email,websitefrom:"chat.appypie.com") {
				data
				status
				msg
			}
		}`;
	var varibles = {
		email: email
	};
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varibles)
			.then(res => {
				resolve({
					status: res.forgotPassword.status,
					message: res.forgotPassword.msg
				});
			})
			.catch(err => {
				console.log(err, "error in emailing forget password code");
			});
	});
};


/**
 * Upadate Password With NewPassword AND varification code 
 * @param {string} email 
 * @param {number|string} code 
 * @param {string} password 
 */
export const resetPassword = (email, code, password) => {
	const FEED_QUERY = `query log($email:String!,$code:String!,$password:String!)
		{	
			resetPasswordVerification(username:$email,verificationCode:$code,newPassword:$password)
			{
				data
				status
				msg
			}
		}`;
	var varibles = {
		email: email,
		code: code,
		password: password
	};
	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varibles)
			.then(res => {
				resolve({
					status: res.resetPasswordVerification.status,
					message: res.resetPasswordVerification.msg
				});
			})
			.catch(err => {
				console.log(err, "error in emailing forget password code");
				reject(err);
			});
	});
};


/**
 * Upadate Password With old password , NewPassword email 
  * @param {string} email 
  * @param {string} oldpassword 
  * @param {string} newPassword 
  */
export const UpdatePassword = (email, oldpassword, newPassword) => {
	const FEED_QUERY = `query log($email:String!,$oldpassword:String!,$newPassword:String!)
		{	
			forgotPasswordVerification(username:$email,oldPassword:$oldpassword,newPassword:$newPassword)
			{
				data
				status
				msg
			}
		}`;
	var variable = {
		email: email,
		oldpassword: oldpassword,
		newPassword: newPassword
	};

	return new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, variable)
			.then(res => {
				resolve({ res: res });
			})
			.catch(err => {
				console.log(err, "error in emailing Update Password code");
				reject(err);
			});
	});
};

export function loginWithGoogle() {
	return new Promise((resolve, req) => {
		firebaseServices.firebaseAuth()
			.signInWithPopup(firebaseServices.googleProvider)
			.then(function (result) {
				// This gives you a Google Access Token. You can use it to access the Google API.
				//var token = result.credential.accessToken;
				// The signed-in user info.
				//var user = result.user;
				// console.log("result ----->>> ", result.credential.accessToken);
				resolve({ result: result, error: false });
				// ...
			})
			.catch(function (error) {
				// Handle Errors here.
				// console.log(error, "gerror");

				//var errorCode = error.code;
				//var errorMessage = error.message;
				// The email of the user's account used.
				//var email = error.email;
				// The firebase.auth.AuthCredential type that was used.
				//var credential = error.credential;
				resolve({ result: "", error: true });
				// ...
			});
	});
	// return firebaseAuth().signInWithRedirect(googleProvider);
	//return authenticate(loginWithFirebase(googleProvider));
}


/**
 * @description - this funciton is unused Functions // initial made for Google -login With Common Login Api
 * @deprecated
 * @since  1.0
 * 
 * @param {string} username 
 * @param {string} pass 
 * @param {string} social_id 
 * @param {string} social_type 
 * @param {string} social_token 
 */
export const googleLogin = async (
	username,
	pass,
	social_id,
	social_type,
	social_token
) => {
	var password = md5(username);
	// console.log(
	// 	username,
	// 	password,
	// 	social_id,
	// 	social_type,
	// 	social_token,
	// 	"social"
	// );

	const FEED_QUERY = `mutation log($username:String!,$password:String!,$social_id:String!,$social_type:String!,$social_token:String!)
		{ commonSignUp(username:$username,password:$password,social_id:$social_id,social_type:$social_type,social_token:$social_token) {
				data
				status
				msg
			}
		}`;
	var varb = {
		username: username,
		password: password,
		social_id: social_id,
		social_type: social_type,
		social_token: social_token
	};

	await new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varb)
			.then(res => {
				// console.log(res, "response----------");
				resolve({
					status: res.commonSignUp.status,
					message: res.commonSignUp.msg
				});
			})
			.catch(err => {
				// console.log(err.response.data.commonSignUp.status, "error");
				resolve({
					status: err.response.data.commonSignUp.status,
					message: err.response.data.commonSignUp.msg
				});
			});
	});
	// var response = await loginDetails(_res, username, password);
	var response = await login(username, password);
	return response;
};


/**
 * 
 * Login With Social Media Google Auth APi
 * 
 * @param {string} username 
 * @param {string} pass 
 * @param {string} social_id 
 * @param {string} social_type 
 * @param {string} social_token 
 */
export const liveChatGoogleLogin = async (
	username,
	pass,
	social_id,
	social_type,
	social_token
) => {
	// var password = md5(username);	
	var password = pass;

	// console.log(
	// 	username,
	// 	password,
	// 	social_id,
	// 	social_type,
	// 	social_token,
	// 	"social"
	// );

	const FEED_QUERY = `mutation log($username:String!,$password:String!,$social_id:String!,$social_type:String!,$social_token:String!)
		{ commonSignUp(username:$username,password:$password,social_id:$social_id,social_type:$social_type,social_token:$social_token) {
				data
				status
				msg
			}
		}`;
	var varb = {
		username: username,
		password: password,
		social_id: social_id,
		social_type: social_type,
		social_token: social_token
	};
	const _res = await new Promise((resolve, reject) => {
		request("https://api.appypie.com/api/v1/common-auth", FEED_QUERY, varb)
			.then(res => {
				// console.log(res, "response----------");
				resolve({
					status: res.commonSignUp.status,
					message: res.commonSignUp.msg
				});
			})
			.catch(err => {
				// console.log(err.response.data.commonSignUp.status, "error");
				reject({
					status: err.response.data.commonSignUp.status,
					message: err.response.data.commonSignUp.msg
				});
			});
	});
	return _res;
	// var response = await loginDetails(_res, username, password);
	// var response = await login(username, password);
	// return response;
};


/**
 * @description Check is User Exit With Email 
 * @param {string} email 
 * @returns Boolen| Object
 */
export const isUserExistWithEmail = async email => {
	return firebaseServices.db
		.collection("users")
		.where('email', '==', email)
		.where('deleted', "==", false)
		.limit(1)
		.get()
		.then(docQuerySnapshot => {
			if (!docQuerySnapshot.empty) {
				// res = true;
				let UserObj = [];
				// localStorage.setItem("tablealias", doc.data().tablealias);
				// localStorage.setItem("email", email);
				// localStorage.setItem("agent_id", doc.data().agent_id);
				UserObj = docQuerySnapshot.docs.map(documnetQuerySnapshot => {
					return {
						id: documnetQuerySnapshot.id,
						...documnetQuerySnapshot.data()
					}
				})

				return UserObj;

			} else {
				return false;
			}
		}).catch(err => {
			return false;
		})
};

// firebasedb initial method
const isEmailExists = async email => {
	var res = false;
	await firebaseServices.db
		.collection("main_user")
		.doc(email.toLowerCase())
		.get()
		.then(doc => {
			if (doc.exists) {
				res = true;
				localStorage.setItem("tablealias", doc.data().tablealias);
				localStorage.setItem("email", email);
				localStorage.setItem("agent_id", doc.data().agent_id);
			} else {
				res = false;
			}
		});
	return res;
};

// adding new user
/**
 * not user 
 * @deprecated
 * @param {string} email 
 * @param {*} data 
 */
const addNewUser = async (email, data) => {
	var res = false;
	await firebaseServices.db
		.collection("main_user")
		.doc(email.toLowerCase())
		.set(data)
		.then(() => {
			res = "new_user";
		})
		.catch(error => {
			console.error("Error on adding main_user: ", error);
			res = false;
		});
	return res;
};

//Making tableAlias
const makingId = () => {
	var temp = Date.now();
	return temp.toString(36);
};

//Making name in Two UpperCase letters
const nameIntials = nameData => {
	var arrayData = nameData.split(" ");
	switch (arrayData.length) {
		case 1:
			return nameData.substr(0, 2).toUpperCase();
			break;
		default:
			return (
				arrayData[0].charAt(0).toUpperCase() +
				arrayData[arrayData.length - 1].charAt(0).toUpperCase()
			);
	}
};


/**
 * create new user collection
 * @deprecated 
 * @since 1.0
 * @param {string} tablealias 
 * @param {string} agent_id 
 * @param {string} data 
 */
const create_user_collection = (tablealias, agent_id, data) => {
	firebaseServices.db
		.collection("user_" + tablealias)
		.doc(agent_id)
		.set(data);
	firebaseServices.db
		.collection("user_" + tablealias)
		.doc(agent_id)
		.collection("chatbot")
		.doc("dashboard")
		.set({});

};

/**
 * @deprecated
 * @since 1.0
 * 
 * making user collection
 * @param {string} email 
 * @param {string} password 
 * @param {string} token 
 */
const login_collection = async (email, password, token) => {
	var response = "";
	var isEmail = await isEmailExists(email);
	// console.log(isEmail, "isEmail");
	if (isEmail) {
		//existing user
		response = "existing_user";
		localStorage.setItem("isLogin", "yes");
		updateSession(token, email); //update session for existing user
	} //new user
	else {
		const id = makingId();
		const addedon = new Date().getTime();
		const agent_id = "AGENT" + addedon;
		const bot_id = "BOTID" + addedon;
		var _name = email.substring(0, email.lastIndexOf("@"));
		var _password = md5(password);

		// add New user
		var main_user_data = {
			agent_company: id,
			agent_parent: null,
			password: _password,
			agent_id: agent_id,
			tablealias: id,
			isAdmin: true,
			date: new Date(),
			session: token
		};
		response = addNewUser(email, main_user_data);

		localStorage.setItem("tablealias", id);
		localStorage.setItem("email", email);
		localStorage.setItem("agent_id", agent_id);
		localStorage.setItem("parent_id", agent_id);
		localStorage.setItem("isAppointmentTableExists", false);
		localStorage.setItem("isLogin", "no");
		localStorage.setItem("BotID", bot_id);
		localStorage.removeItem("_email");
		var payment_data = {};
		// profile data
		var profile_data = {
			id: id,
			addedon: addedon,
			agent_id: agent_id,
			name: _name,
			alias: _name,
			agent_short: nameIntials(_name),
			profile_img: "",
			email: email,
			departments: ["Owner"],
			role: "Owner",
			role_id: "Owner",
			password: _password,
			timezone: "",
			parent_id: agent_id,
			logout: false
		};

		// setting data
		var setting_data = {
			chatbot_setting: {
				idle_time: {
					A_end_for_both_VA: false,
					A_inactive: false,
					A_inactive_time: "10",
					A_show: false,
					A_show_text: "",
					A_status_change_aftertime: 0,
					A_time: 10,
					V_end_for_both_VA: false,
					V_remove: false,
					V_time: 10,
					agent_not_reply: false,
					visitor_not_reply: false,
					A_inactive_status: 0
				},
				localization: {
					language: "English :en",
					timezone: "Pacific/Apia"
				},
				notification_sound: {
					incoming_chat_play: false,
					incoming_chat_show: false,
					new_msg_received_play: false,
					new_msg_received_show: false
				},
				pervious_show: false
			},
			livechat_setting: {
				GDPR: {
					ask_consent: 0,
					consent_button_text: "Yes,I agree",
					consent_content:
						"I agree for my personal data, provided via chat, to be processed by <your company name> for the purpose of providing support via chat. I agree for my personal data to be processed for the time <e.g. needed to carry out the service>. I understand the consent may be revoked by sending an email at <your business email/your data protection officers email>.",
					content_asked_by: "",
					privacy_policy_link: "",
					privacy_policy_link_text: ""
				}
			}
		};

		// integration data
		var integration_data = {
			all_integration: {
				appy_email: {
					description: "Manage your emails",
					inputs: ["Email id"],
					learnMore: "",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
					title: "Appy Email",
					type: "email"
				},
				appy_appointment: {
					description: "Manage your appointments",
					inputs: ["Email id"],
					learnMore: "",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
					title: "Appy Appointment",
					type: "appointments"
				},
				appy_agentTransfer: {
					description: "Manage all live agent transfers in Appy Pie Livechat",
					inputs: ["Email id"],
					learnMore: "",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/livechat.svg?alt=media&token=3fc5e993-5a07-428e-b009-32a057496c19",
					title: "Appy Livechat",
					type: "agent transfer"
				},
				zendesk: {
					description: "Manage all live agent transfers in zendesk chat",
					inputs: ["Account key"],
					learnMore: "https://api.zopim.com/web-sdk/#initializing-the-sdk",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Group%202339.svg?alt=media&token=2d50fd44-fe9d-4079-bdef-785fece6a667",
					title: "Zendesk Chat",
					type: "agent transfer"
				},
				smtp: {
					description: "Manage your emails via your SMTP",
					inputs: [
						"Host *",
						"Port *",
						"Email/UserName *",
						"Password *",
						"Sender Name",
						"Sender email"
					],
					learnMore: "https://www.smtp.com/smtp-email-relay-solutions/",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/Asset-2-smtp-mon.svg?alt=media&token=b4c24d7c-58c5-4bab-a619-ba63be222f4f",
					title: "SMTP",
					type: "email"
				},
				zoom: {
					description: "Manage all live agent transfers in zoom",
					inputs: [
						"Client id *",
						"Client secret *"
					],
					learnMore: "https://support.zoom.us/hc/en-us",
					logo:
						"https://firebasestorage.googleapis.com/v0/b/appycx-beta.appspot.com/o/zoom-1.svg?alt=media&token=c3611cdd-0014-44d6-833f-c84a5b05375a",
					title: "ZOOM",
					type: "agent transfer"
				}
			},
			installed_integration: {
				appy_email: {
					botId: [],
					data: { email: email },
					title: "Appy Email",
					type: "email"
				},
				appy_appointment: {
					botId: [],
					data: { email: email },
					title: "Appy Appointment",
					type: "appointments"
				},
				appy_agentTransfer: {
					botId: [],
					data: { email: email },
					title: "Appy Livechat",
					type: "agent transfer"
				}
			}
		};

		// role data
		var role_data = {
			Owner: {
				access_module: [],
				description: "",
				name: "Owner",
				role: "Owner",
				status: 1,
				updatedon: addedon,
				permissions: {
					usermanagement: {
						agentAccess: true,
						rolesAccess: true,
						departmentAccess: true
					},
					chatbot: {
						create: true,
						delete: true,
						status: true,
						edit: true
					},
					livechat: {
						initiateChat: true,
						viewPastchat: true,
						answereIncoming: true,
						viewVisitorPath: true
					},
					appointment: {
						appointment: true,
						addAgent: true,
						ownRooster: true,
						otherRooster: true
					},
					others: {
						analytics: true,
						intigeration: true,
						settings: true,
						status_change: true,
						mange_preference: true,
						manage_profile: true
					}
				}
			},
			Agent: {
				access_module: [],
				description: "",
				name: "Agent",
				role: "Agent",
				status: 1,
				updatedon: addedon,
				permissions: {
					usermanagement: {
						agentAccess: false,
						rolesAccess: false,
						departmentAccess: false
					},
					chatbot: {
						create: false,
						delete: false,
						status: false,
						edit: false
					},
					livechat: {
						initiateChat: true,
						viewPastchat: true,
						answereIncoming: true,
						viewVisitorPath: true
					},
					appointment: {
						appointment: false,
						addAgent: false,
						ownRooster: false,
						otherRooster: false
					},
					others: {
						analytics: true,
						intigeration: false,
						settings: false,
						status_change: true,
						mange_preference: true,
						manage_profile: true
					}
				}
			}
		};

		// appointment data
		var appointment_data = {};

		var new_user_data = {
			profile: profile_data,
			payment: payment_data,
			integration: integration_data,
			settings: setting_data,
			role: role_data,
			appointment: appointment_data
		};

		//ctreate new user collection
		create_user_collection(id, agent_id, new_user_data);

		//checking direct signup or login
		var categoryid = localStorage.getItem("Categoryid");
		if (categoryid === null || categoryid === "" || categoryid === undefined) {
			localStorage.setItem("Categoryid", 0);
			localStorage.setItem("BotName", "Inquiry");
		}
	}
	return response;
};


/**
 * for signupLoginCheck
 */
export const signupLoginCheck = async uuid => {
	//alert("aaaaaaaaaaaaaaa");
	if (localStorage.getItem('userdata') == null) {
		return 'sessionotexist';
	}
	else {
		return 'sessionexist';
	}
}

export const getSessionCheck = async (redirectPage = true, loginType = 'both') => {
	if (localStorage.getItem('api_access_token') == null) {
		await getApiAccessToken();
	}
	// else
	// {
		return new Promise((resolve,reject) => {
			var companyName = localStorage.getItem('companyName') ? localStorage.getItem('companyName') : false;
			if (companyName) {
				localStorage.removeItem('logoutowner');
			}
			var checkoutlogout = localStorage.getItem('logoutowner');
			var loggingout = localStorage.getItem('loggingout');

			if (loggingout === 'true') {
				resolve(false);
				return false;
			}
			const loggedInUser = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
			if (loggedInUser) {
				loginType = loggedInUser.roleType === 'Owner' ? 'owner' : 'agent';
			} else if(checkoutlogout && checkoutlogout === 'true' && companyName === false) {
				resolve(false);
				return false;
			}
			getUserSession(loginType).then((results) => {
				
				console.log(results, "results");
				if (!results || results.status_msg === 500) {
					resolve({ status: 'notapiexist' });
				} else if (results.status_msg == 401) {
					getApiAccessToken();
					resolve({ status: 'notapiexist' });
				} else {
					if (loggingout === 'true') {
						resolve(false);
						return false;
					}
					let resultUserData = results.userdata !== '' ? JSON.parse(results.userdata) : "";
					if (resultUserData == "") {
						if (localStorage.getItem("login_type") == 'Owner' && localStorage.getItem('current_login') === '2') {
							localStorage.removeItem("userdata");
							var agentData = JSON.parse(localStorage.getItem("agent"));
							localStorage.removeItem("login_type");
							localStorage.removeItem('companyName');
							localStorage.removeItem("app_access");
							localStorage.removeItem('current_login');
							localStorage.removeItem('common_redirect');
							try {
								window.location.href = '/' + agentData.ownerIdentifire + '/logout';
								return false;
							} catch (err) { }

						}
						else if (localStorage.getItem("login_type") == 'Owner') {
							localStorage.setItem('current_login', '2');
							localStorage.removeItem("userdata");
							var agentData = JSON.parse(localStorage.getItem("agent"));
							localStorage.removeItem("login_type");
							localStorage.removeItem('companyName');
							localStorage.removeItem("app_access");
							localStorage.removeItem('current_login');
							localStorage.removeItem('common_redirect');
							try {
								window.location.href = '/' + agentData.ownerIdentifire + '/logout';
								return false;
							} catch (err) { }
						}
						else {
							var agentLogin = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
							if(agentLogin) {
								window.location.href = '/' + agentLogin.ownerIdentifire + '/logout';
							} else {
								localStorage.removeItem("agent");
								localStorage.removeItem("userdata");
								localStorage.removeItem('redirectAgent');
							}
						}
						resolve({ status: 'notexist' });
					} else if (resultUserData.login_status === 'yes' && results.userdata !== '') {
						if (resultUserData.user_type === "agent") {
							if (resultUserData.login_status === "yes") {
								let userData_1 = JSON.parse(resultUserData.userdata);
								let getRedirectPage = localStorage.getItem('redirectAgent');
								let agentDetails = localStorage.getItem('agent');
								if (getRedirectPage === 'false' && agentDetails !== null) {
									resolve({ status: "done" });
								} else {
									getAgentData(userData_1.idToken.payload);
									resolve({ status: "done" });
								}
							}
						} else if (resultUserData.user_type === 'owner') {
							var loginData = JSON.parse(results.userdata);
							if (loginData.login_status === 'yes') {
								let loginUserData = loginData.userdata;
								localStorage.setItem('owner_app_access', JSON.stringify(loginUserData.apps_access));
								if (localStorage.getItem("agent") != null && localStorage.getItem("login_type") === 'Owner') {
									let agentValue = JSON.parse(localStorage.getItem('agent'));
									if (agentValue.email === loginUserData.email) {
										localStorage.setItem('current_login', '1');
										if (localStorage.getItem("app_access") == null) {
											let checkStatus = false;
											let appAccess = loginUserData.apps_access;
											appAccess.forEach(function (item, index) {
												if (item.product === 'livechat') {
													checkStatus = true;
												}
											});
											if (checkStatus === true) {
												localStorage.setItem("app_access", 'yes');
											}
											else {
												let organizationAlias = agentValue.organization_alias;
												let companyName = agentValue.company_name ? agentValue.company_name : organizationAlias;
												updateCompanyDetails(agentValue.ownerId, loginUserData.email, organizationAlias, companyName);
											}
										}
										resolve({ status: 'sessionexist' });
									}
									else {
										localStorage.removeItem("login_type");
										localStorage.removeItem('companyName');
										localStorage.removeItem("app_access");
										localStorage.removeItem('current_login');
										localStorage.removeItem('common_redirect');
										localStorage.removeItem('agent');
										localStorage.removeItem('accessToken');
										localStorage.removeItem('agentData');
										localStorage.removeItem('userdata');
										try {
											window.location.href = '/common-session';
											return false;
										} catch (err) { }
										resolve({ status: 'notexist' });
									}
								}
								else if (localStorage.getItem("agent") != null) {
									localStorage.removeItem('common_redirect');
									let agentDetailsLocal = localStorage.getItem('agent');
									if(window.location.pathname.includes('/login') && agentDetailsLocal.ownerIdentifire) {
										window.location.href = "/"+agentDetailsLocal.ownerIdentifire;
									}
								}
								else {
									
									localStorage.setItem('userdata', JSON.stringify(loginUserData));
									
									let userCommonLoginData = JSON.parse(localStorage.getItem('userdata'));
	
									if (localStorage.getItem('common_redirect') == null) {
										localStorage.setItem('common_redirect', 'done');
										window.location.href = '/common-session';
									} else if (localStorage.getItem('agent') === null) {
										localStorage.setItem('common_redirect', 'done');
										// window.location.href = '/common-session';
									}
									resolve({ status: 'sessionexist' });
								}
							}
						}
					} else {
						localStorage.removeItem("userdata");
						localStorage.removeItem('redirectAgent');
						localStorage.removeItem('agent');
						localStorage.removeItem('common_redirect');
					}
				}
			});
		})
	// }
}


/**
 * for checking common login session exist or not 
 */
export const getSessionCheck_OLD = async uuid => {
	var connectData = getCookie('APPYID');
	uuid = localStorage.getItem('useragent_id');
	if (localStorage.getItem('api_access_token') == null) {
		await getApiAccessToken();
	}
	else {
		return new Promise((resolve, reject) => {
			var companyName = localStorage.getItem('companyName') ? localStorage.getItem('companyName') : false;
			if (companyName) {
				localStorage.removeItem('logoutowner');
			}
			var checkoutlogout = localStorage.getItem('logoutowner');

			if (checkoutlogout && checkoutlogout === 'true' && companyName === false) {
				resolve(false);
				return false;
			}
			fetch(commonLoginApi + 'api/product/getsession', {
				method: 'POST',
				headers: {
					Accept: 'application/x-www-form-urlencoded',
					'Content-Type': 'application/json',
					"Authorization": "Bearer " + localStorage.getItem('api_access_token')
				},
				body: JSON.stringify({ file_name: uuid }),
			}).then((response) => response.json())
				.then((responseJson) => {

					if (responseJson.status == 401) {
						getApiAccessToken();
						//resolve('notapiexist');
						resolve({ status: 'notapiexist' });
					}
					else {

						if (responseJson.userdata == "") {
							if (localStorage.getItem("login_type") == 'Owner' && localStorage.getItem('current_login') === '2') {
								localStorage.removeItem("userdata");
								var agentData = JSON.parse(localStorage.getItem("agent"));
								// localStorage.removeItem("agent");							   
								localStorage.removeItem("login_type");
								localStorage.removeItem('companyName');
								localStorage.removeItem("app_access");
								localStorage.removeItem('current_login');
								localStorage.removeItem('common_redirect');
								// window.location.href = '/logout';
								window.location.href = '/' + agentData.ownerIdentifire + '/logout';

							}
							else if (localStorage.getItem("login_type") == 'Owner') {
								localStorage.setItem('current_login', '2');
							}
							else {
								getUserSession().then((results) => {
									if (results.userdata == "") {
										localStorage.removeItem("userdata");
										localStorage.removeItem('redirectAgent');
										localStorage.removeItem('agent');
										localStorage.removeItem('common_redirect');
									} else {
										let userData = JSON.parse(results.userdata);
										if (userData.login_status === "yes") {
											let userData_1 = JSON.parse(userData.userdata);
											let getRedirectPage = localStorage.getItem('redirectAgent');
											let agentDetails = localStorage.getItem('agent');
											if (getRedirectPage === 'false' && agentDetails !== null) {

											} else {
												getAgentData(userData_1.idToken.payload);
											}
										}
									}
								})
							}
							resolve({ status: 'notexist' });


						}
						else {
							var loginData = JSON.parse(responseJson.userdata);
							if (loginData.login_status === 'yes') {			//	console.log("6");
								let loginUserData = loginData.userdata;

								if (localStorage.getItem("agent") != null && localStorage.getItem("login_type") === 'Owner') {
									//	console.log(localStorage.getItem("login_type"));

									let agentValue = JSON.parse(localStorage.getItem('agent'));
									if (agentValue.email === loginUserData.email) {//console.log("7");
										localStorage.setItem('current_login', '1');
										if (localStorage.getItem("app_access") == null) {
											//console.log("8");
											let checkStatus = false;
											//console.log(checkStatus);
											let appAccess = loginUserData.apps_access;
											appAccess.forEach(function (item, index) {
												if (item.product === 'livechat') {
													checkStatus = true;
												}
											});
											if (checkStatus === true) {//console.log("9");
												localStorage.setItem("app_access", 'yes');
											}
											else {//console.log("10");
												let organizationAlias = agentValue.organization_alias;
												let companyName = agentValue.company_name ? agentValue.company_name : organizationAlias;
												updateCompanyDetails(agentValue.ownerId, loginUserData.email, organizationAlias, companyName);
											}
										}

										resolve({ status: 'sessionexist' });
									}
									else {
										//	console.log("11");
										localStorage.removeItem('current_login');
										localStorage.removeItem("userdata");
										localStorage.removeItem("app_access");
										var agentData = JSON.parse(localStorage.getItem("agent"));
										//localStorage.removeItem("agent");
										localStorage.removeItem("login_type");
										localStorage.removeItem('companyName');
										localStorage.removeItem('common_redirect');
										//window.location.href ='/'+agentData.ownerIdentifire+'/logout';

										resolve({ status: 'notexist' });
									}
								}
								else if (localStorage.getItem("agent") != null) {
									localStorage.removeItem('common_redirect');
									/*   var agentData = JSON.parse( localStorage.getItem("agent"));
									   localStorage.setItem('userdata',JSON.stringify(loginUserData));
									   localStorage.removeItem("app_access");
									   localStorage.removeItem('current_login');
									   localStorage.removeItem('common_redirect');
									   localStorage.removeItem("login_type");
									   console.log('agent case');
									   window.location.href ='/'+agentData.ownerIdentifire+'/logout';*/
								}
								else {			//console.log("12");	
									localStorage.setItem('userdata', JSON.stringify(loginUserData));
									let userCommonLoginData = JSON.parse(localStorage.getItem('userdata'));

									if (localStorage.getItem('common_redirect') == null) {
										localStorage.setItem('common_redirect', 'done');
										window.location.href = '/common-session';
									}
									resolve({ status: 'sessionexist' });
								}

							}
						}

					}
				})
				.catch((error) => {
					console.error(error);
				});
		});
	}

	//}

}



/**
 * for logout common login
 */
export const logoutCommonLogin = async uuid => {
	//if(localStorage.getItem("login_type") === 'Owner' || localStorage.getItem('login_type') == null )
	//	{
	//uuid = uuid + '_'+localStorage.getItem('is_private');
	uuid = localStorage.getItem('useragent_id');
	if (localStorage.getItem('useragent_id') == null) {
		var cname = 'APPYID';
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');
		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				var valueData = c.substring(name.length, c.length);
				if ((localStorage.getItem('useragent_id') == null || localStorage.getItem('useragent_id') == '') && cname == 'APPYID') {
					localStorage.setItem('useragent_id', valueData)

				}
			}
		}
	}
	uuid = localStorage.getItem('useragent_id');
	return new Promise((resolve, reject) => {
		fetch(commonLoginApi + 'api/product/setsession', {
			method: 'POST',
			headers: {
				Accept: 'application/x-www-form-urlencoded',
				'Content-Type': 'application/json',
				"Authorization": "Bearer " + localStorage.getItem('api_access_token')
			},
			body: JSON.stringify({ file_name: uuid, login_status: 'no' }),
		}).then((response) => response.json())
			.then((responseJson) => {

				if (responseJson.status == 401) {
					getApiAccessToken();

				}
				else {
					localStorage.removeItem("userdata");
					var agentData = JSON.parse(localStorage.getItem("agent"));
					// localStorage.removeItem("agent");							   
					localStorage.removeItem("login_type");
					localStorage.removeItem('companyName');
					localStorage.removeItem("app_access");
					localStorage.removeItem('current_login');
					localStorage.removeItem('common_redirect');
					// window.location.href = '/logout';
					window.location.href = '/' + agentData.ownerIdentifire + '/logout';

				}
			})
			.catch((error) => {
				console.error(error);
			});
	});

	//	}

}



/*
getServerDate = async() => {
	let cDate = moment().tz("UTC").format("x"); 
	return parseInt(cDate);
}


updateAgentLogin = async (ownerId,agentId,sJWT) => {
	let loginOn = await getServerDate();
	db.collection("users").doc(ownerId).collection('agents').doc(agentId).update({last_login:loginOn, online_status:1, _token:sJWT}).then((ref) => {
		if(ref || ref===undefined) {
		  return ref;
		} else {
		  throw new Error('Update failed');
		}
	}).catch((err)=>{
		console.log(err);
	})
  
	db.collection('users').doc(ownerId).collection('agents').doc(agentId).collection('logs').doc(loginOn.toString()).set({online_status:1,time:loginOn,ref:'login'}).then((docs) => {
		 return docs; 
	}).catch((err) => {
	  throw new Error(err)
	});
}
*/

function getCookie(cname) {
	if (window.location.hostname === "localhost") {
		localStorage.setItem('useragent_id', TEST_LOGIN_TOKEN);	
		return TEST_LOGIN_TOKEN;
	}
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			var valueData = c.substring(name.length, c.length);
			localStorage.setItem('useragent_id',valueData);		
			return valueData;
		}
	}
	return "";
}

/* For create custom token for security start */

export const logInWithCustomToken = async (ownerId, agentId) => {
	return new Promise( resolve  => {
		var body = [`ownerId=${ownerId}&agentId=${agentId}`];
        //fetch(MAIL_FUNCTION_URL + "/firebaseAuth/getdbaccesstoken", {
		fetch(GATEWAY_URL + "/getdbaccesstoken", {
            method: "POST",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json())
            .then(async response => {
                if(response.status === "success") {
					firebaseServices.firebaseAuth().signInWithCustomToken(response.token).then((userCredential) => {
						console.log("---------------    userCredential   ----------------");
						console.log(userCredential);
						//var user = userCredential.user;
						localStorage.setItem("dbper","grant")
						resolve(true);
					})
					.catch((error) => {
						///var errorCode = error.code;
						///var errorMessage = error.message;
						resolve(false);
					});
                } else {
					resolve(false);
				}
            })
            .catch(e => {
				resolve(false); 
			});
	});
}

export const logedOutCustomToken = () => {
	return new Promise(resolve => {
		firebaseServices.firebaseAuth().signOut().then(() => {
			// Sign-out successful.
			console.log("-----------  signOut  Success -----------");
			localStorage.removeItem("dbper")
			resolve(true);
		}).catch((error) => {
			// An error happened.
			console.log("-----------  signOut  Error -----------");
			resolve(false);
		});
	});
}