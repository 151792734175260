export const words =
    ['Hello! Welcome to Appy Pie, and thank you for visiting our site. How can we help you today?',
        'Hi, Thank you for visiting Appy Pie. Is there anything specific I can help you with?',
        'Hi, Are you looking to get your app live in google and apple stores? Please revert to message for instant support.',
        'Revert to message for instant support.',
        'What sort of issue you are facing?',
        'Please share your app BID.',
        'Share your app BID.', 
        'I am working on your app issue.',
        'Apple verification code',
        'How may I assist you today?',
        'Please wait for a moment.',
        'Wait for a moment.',
        'Please send an email to support@appypie.com.',
        'Send an email to support@appypie.com.',
        'Hello, welcome to Appypie.',
        'Nice to meet you.',
        'Dear Customer, thanks for Visiting Appy Pie. To help you improve the app, I am here to guide you. Please reply if you have any questions.',
        'Reply if you have any questions.',
        'Please share google developer access.',
        'Share google developer access.',
        'May I know why you want to cancel?',
        'Please email us at billing@appypie.com.',
        'Tell me about app concern.',
        'Sir I am here to help you with the app.',
        'Welcome to Appy Pie.'];
