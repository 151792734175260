import React from 'react';
// import db from "../../config/firebase";
import {toast} from "react-toastify";
import { activityLog } from "../../services/service";
import firebaseServices from '../../firebase';

class deleteRole extends React.Component {

    constructor(props) { 
        super();
        this.state = {
            loader:false,
            ownerId:'',
            otherRoles:[],
            showDeleteRole:false,
            roleList:'',
            roleName:'',
            roleId:'',
            defRole:[],
            role_error:''
        }
    }

    componentDidMount() {
        let userDetails=JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId
        })
    }

    componentDidUpdate(prev_props){
        if(this.props.roleId !==this.state.roleId) {
            this.setState({
                roleId:this.props.roleId
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').get().then((docs) => {
                let data=[];
                let i=0;
                docs.forEach((doc) => {
                    if(doc.id !== this.props.roleId) {
                        data[i]=doc.data();
                        data[i]['id']=doc.id;
                        i++;   
                    } else {
                        this.setState({
                            roleName:doc.data().name
                        })
                    }
                })
                this.setState({
                    otherRoles:data
                })
            })
            activityLog("Opened delete role page");
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showDeleteRole !== props.sendRoleStatus) { 
          return {
            showDeleteRole: props.sendRoleStatus
          }
        }
        return null
    }

    handleChange = (e) => {
        let target = e.target;
        let values = target.value;
        this.setState({
            [target.name]:values
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate=true;
        if(this.props.delagentCount>0) {
            validate=this.validateForm();
        }
        if(validate) {
            this.setState({
                loader:true
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where('role','==',this.state.roleId).get().then((res) => {
                if(res.empty===false) {
                    res.forEach((result) => {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(result.id).update({role:this.state.roleList});
                    })
                }
            }).catch((err) => {
                console.log(err);
            })
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').doc(this.state.roleId).delete().then((res) => {
                this.hideDeleteRole();
                this.setState({
                    loader:false
                })
                toast.success("Role has been deleted Successfully!");
                activityLog("deleted role");
            })
        }
    }

    validateForm = (e) => {
        let returnValue=true;
        if(this.state.roleList==='') {
            returnValue=false;
            this.setState({
                role_error:'Please Select a Role'
            })
        } else {
            this.setState({
                role_error:''
            })
        }
        return returnValue;
    }

    hideDeleteRole = (e) => {
        this.setState({
            showDeleteRole:false,
            otherRoles:[],
            defRole:[],
            role_error:''
        })
        this.props.getDeleteRoleStatus(false);
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideDeleteRole();
    }


    render() {
        let rolesList = this.state.otherRoles.map((value,index) => {
            if(value.name!=='Owner') {
                return <option key={index} value={value.id}>{value.name}</option>
            }
        })
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showDeleteRole ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Confirm</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideDeleteRole}>
                                        <img alt="" src={ require('../../assets/img/icon/right-panel-crose.svg') } />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <h4 className="card-title mb-0">Do you wish to delete the Role</h4>
                                    <p className="card-body-text">Note: Agents are added to this role, So select a role to which you wish to add the agents to.</p>
                                </div>
                                <div className="wrapperInfo px-5 py-4">
                                        <label className="mb-1 small text-screen1">Select Agent</label>
                                        <select className="customArrow" name="roleList" value={this.state.roleList} onChange={this.handleChange}>
                                            <option value="">Select Role</option>
                                            {rolesList}
                                        </select>
                                        <p className="error">{this.state.role_error}</p>
                                </div>
                                
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Delete</button>
                                        <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }

}

export default deleteRole;