/**
 * @class AgentVerification  
 * @description Component Class For Agent Varifyfication Process  
 * @version 1.0
 * @author Bineet kumar Chaubey<bineet@appypiellp.com>
 * @file AgentVerification.js
 * 
 */
import React, { Component } from "react";
import RawTooltip from "../../components/Tooltip/rawtooltip";
import TextField from "@material-ui/core/TextField";
//import { forgetPassword, resetPassword, validation } from "../../config/SignupLogin";
import data from "../../localization/login-registration.json";
import { checkIsUserOrOwnerLogin } from "../organization/OnboardHelper";
///import { mailUrl } from "../../config/mailFunctions.js";
import { NotificationContainer, NotificationManager } from "react-notifications";
// import db from '../../config/firebase';
import { checkVerificationCodeWithDB, sendAgentVerificationCode, getDynamicUrl, jwtVerify } from "../organization/Model/DbModel";
import { getDateInUTC } from "../livechat/components/Comman";
import { setNewPassword, getUserDetailsFromEmail } from "../../services/service";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD, GATEWAY_URL, FUNCTION_API_KEY } from "../../config/siteConfig";
import firebaseServices from "../../firebase";

const publicIp = require("public-ip");
const expiryHours = 48;


class AgentVerification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			emailError: "",
			verificationcode: "",
			verificationcodeError: "",
			newPassword: "",
			newRePassword: "",
			loader: false,
			showNewPasswordForm: true,
			newPasswordError: "",
			newRePasswordError: "",
			IPv4: "",
			agent_org_key: "",
			org_alias: '',
			company_name: '',
			upperCaseVerify: false,
			lowerCaseVerify: false,
			numericVerify: false,
			lengthVerify: false,
			specialCharacterVerify: false,
			startedTyping: false,
			expiryTime: '',
			jwToken: '',
			tokenExpireTime: '',
			linkExpireVal: false,
			linkExpireMsg: ''
		};
	}

	/**
	 * check if login or not
	 */
	componentDidMount = async () => {
		this.setState({ loader: true });
		await checkIsUserOrOwnerLogin();

		// this.setState({ loader: false });
		publicIp.v4().then((ip4) => {
			this.setState({
				IPv4: ip4
			})
		});
		this.verifyClientOrganization();
	}

	/**
	 * verify if organization exist or not
	 */
	verifyClientOrganization = () => {
		this.setState({
			loader: true
		});
		///// Check owner identiire exist or not ///////
		let ownerIdentifire = this.props.match.params.organization_name ? this.props.match.params.organization_name : "";
		let token = this.props.match.params.token ? this.props.match.params.token : "";
		ownerIdentifire = ownerIdentifire.trim();
		firebaseServices.db.collection("users").where("organization_alias", "==", ownerIdentifire).limit(1).get().then(async users => {
			if (users.size === 0) {
				window.location.href = "/login";
				return false;
			} else if (token === "") {
				window.location.href = "/login";
				return false;
			} else {
				var dToken = await jwtVerify(token);
				if (dToken === false) {
					this.setState({
						linkExpireVal: true,
						linkExpireMsg: 'Incorrect verification url',
						loader: false
					})
					return false;
				}
				let tokenExpireTime = dToken.time ? dToken.time + (expiryHours * 60 * 60 * 1000) : '';
				if (tokenExpireTime) {
					let currTime = await getDateInUTC();
					if (tokenExpireTime <= currTime) {
						this.setState({
							linkExpireVal: true,
							linkExpireMsg: 'Your verification link has been expired, Please ask admin to resend verification email.',
							loader: false
						})
						return false;
					}
				}
				const idKey = users.docs[0].id;
				const company_name = users.docs[0]?.data()?.company_name
				this.setState({ agent_org_key: idKey, loader: false, org_alias: ownerIdentifire, company_name: company_name, verificationcode: dToken.code, email: dToken.email, expiryTime: dToken.expiry, jwToken: token, loader: false, tokenExpireTime: dToken.time ? dToken.time + (expiryHours * 60 * 60 * 1000) : '' }, () => {
					console.log(tokenExpireTime);
				})
			}
		});
	}

	sendEmailverifyEmail = async () => {
		const { email, company_name, org_alias } = this.state;
		let userDetailsOrg = { ownerIdentifire: org_alias }
		let agentDetails = await getUserDetailsFromEmail(email, userDetailsOrg);
		let agentName = '';
		if (agentDetails.status === 200) {
			agentName = agentDetails.data.last_name && agentDetails.data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? agentDetails.data.first_name + ' ' + agentDetails.data.last_name : agentDetails.data.first_name;
		}
		let emailBody = [`email=${email.toLowerCase()}&type=verified_email_success&agent_name=${agentName}&company_name=${company_name}`];
		this.sendEmail(emailBody)
		let loginUrl = window.location.origin + "/" + org_alias + "/login";
		let emailBody1 = [`email=${email.toLowerCase()}&type=password_set_success&agent_name=${agentName}&company_name=${company_name}&url=${loginUrl}`];
		this.sendEmail(emailBody1)
		return true;
	}


	validateEmail = email => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};
	validatePassword = password => {
		this.setState({
			upperCaseVerify: false,
			lowerCaseVerify: false,
			numericVerify: false,
			lengthVerify: false,
			specialCharacterVerify: false,
			allVerify: false
		})
		var re = /^[A-Za-z\d@$#!%*?&]{8,16}$/;
		var re1 = /[a-z]/;
		var re2 = /[A-Z]/;
		var re3 = /\d/;
		var re4 = /[@$#!%*?&]/;
		if (re.test(password)) {
			this.setState({
				lengthVerify: true
			})
		}
		if (re1.test(password)) {
			this.setState({
				lowerCaseVerify: true
			})
		}
		if (re2.test(password)) {
			this.setState({
				upperCaseVerify: true
			})
		}
		if (re3.test(password)) {
			this.setState({
				numericVerify: true
			})
		}
		if (re4.test(password)) {
			this.setState({
				specialCharacterVerify: true
			})
		}
		if (re1.test(password) && re2.test(password) && re3.test(password) && re4.test(password)) {
			this.setState({
				allVerify: true
			})
		}

		if (re1.test(password) && re2.test(password) && re3.test(password) && re4.test(password) && re.test(password)) {
			return true;
		} else {
			return false;
		}

	};
	handleEmail = e => {
		if (!this.validateEmail(e.target.value)) {
			this.setState({
				emailError: 'Enter valid email address',
				email: e.target.value
			});
		} else {
			this.setState({
				email: e.target.value,
				emailError: ""
			});
		}
	};

	/**
	 * Handle password Filed 
	 */
	handlePassword = e => {
		this.setState({
			startedTyping: true
		})
		if (!this.validatePassword(e.target.value)) {
			this.setState({
				newPasswordError: 'Password not in accordance to prescribed format',
				newPassword: e.target.value
			});
		} else {
			this.setState({
				newPassword: e.target.value,
				newPasswordError: ""
			});
		}
	};

	/**
	 * Handle Re-type Password 
	 */
	handleRePassword = e => {
		if (!this.validatePassword(e.target.value)) {
			this.setState({
				newRePasswordError: 'Password not in accordance to prescribed format',
				newRePassword: e.target.value
			});
		} else {

			const {
				newPassword
			} = this.state
			const newRePassword = e.target.value
			if (newRePassword !== newPassword) {
				this.setState({
					newRePasswordError: "Passwords do not match",
					newRePassword: e.target.value
				})
				return
			}
			this.setState({
				newRePassword: e.target.value,
				newRePasswordError: ""
			});
		}
	}

	/**
	 * handle varification code filed
	 */
	handleVarificationCode = e => {
		this.setState({
			verificationcode: e.target.value,
			verificationcodeError: ""
		});
	};

	/**
	  * @description Handle to valifation code varification   and new password 
	  *  then new password and varification code  to update password 
	  */
	checkedVerificationCode = async () => {
		this.setState({ loader: true });
		const {
			email,
			verificationcode,
			agent_org_key,
			org_alias,
			expiryTime,
			jwToken
		} = this.state;

		// if (this.validateVerificationForm()) {
		const checkOtpDat = await checkVerificationCodeWithDB(agent_org_key, email, verificationcode, expiryTime, jwToken)
		this.setState({ loader: false });
		// if agent already verified then no need to furthor varified
		if (checkOtpDat.statusCode === 100 && checkOtpDat.varifyStatus) {
			const url = getDynamicUrl()
			NotificationManager.success("", 'Email is alredy verified', 5000);
			setTimeout(() => {
				window.location = `${url}/${org_alias}/login`;
			}, 2000);
			return
		}

		if (checkOtpDat.statusCode === 200) {
			this.setState({
				loader: false,
				agentID: checkOtpDat.agentID,
				showNewPasswordForm: true
			});
			let company_name = this.state.company_name;
			let agent_name = checkOtpDat?.agent_name

			let emailBody = [`email=${email.toLowerCase()}&type=verified_email_success&&agent_name=${agent_name}&&company_name=${company_name}`];
			this.sendEmail(emailBody)
			NotificationManager.success("", 'Verification link has been successfully verified', 5000);

		} else if (checkOtpDat.statusCode === 201 || checkOtpDat.statusCode === 202) {

			this.setState({
				loader: false,
				verificationcodeError: checkOtpDat.message,
				email: ""
			});
			NotificationManager.error("", checkOtpDat.message, 5000);
		} else {

			this.setState({
				loader: false,
				emailError: "The email is not associated with any agent's account.",
				email: ""
			});
			NotificationManager.error("", checkOtpDat.message, 5000);
		}
	};

	/**
	 * validate verification form data
	 */
	validateVerificationForm = () => {
		const {
			email,
			emailError,
			verificationcode,
		} = this.state;

		let isValid = true
		if (emailError) {
			isValid = false
		}
		if (email.trim() === '') {
			this.setState({ emailError: "Field cannot be left blank" })
			isValid = false
		}

		if (verificationcode === '') {
			this.setState({ verificationcodeError: "Field cannot be left blank" })
			isValid = false
		}

		if (isValid) {
			return true
		} else {
			return false
		}
	}

	/**
	 *  Resent varificationcode
	 */
	resentVerificationCode = async () => {
		const {
			email,
			emailError,
			agent_org_key,
			org_alias
		} = this.state;

		if (emailError) {
			return false
		}
		if (email.trim() === '') {
			this.setState({ emailError: "Field cannot be left blank" })
			return false
		}
		this.setState({ loader: true });

		/**
		 *  Resent a new Code
		 */
		const getResponce = await sendAgentVerificationCode(agent_org_key, email)
		if (getResponce.status === 100 && getResponce.varifyStatus === true) {
			const url = getDynamicUrl()
			NotificationManager.success("", 'Email is alredy verified', 5000);
			window.location.href = `${url}/${org_alias}/login`;
			return
		}
		if (getResponce.status === 200) {
			let name = getResponce?.agentName;
			let company_name = this.state.company_name
			let token = getResponce.token;

			let verificationLink = window.location.origin + "/" + this.state.org_alias + "/agent-verification/" + token;
			var body = [
				`email=${email}&verifyLink=${verificationLink}&type=resend_verification_code_new&agent_name=${name}&company_name=${company_name}`
			];

			this.sendEmail(body)
			this.setState({ loader: false });
			NotificationManager.success("", 'New Verification link has been sent to your registered email', 5000);
		} else {
			this.setState({ loader: false });
			NotificationManager.error("", getResponce.message, 5000);
		}
	}

	/**
	 * Update Agent New Password
	 */
	handleUpdateNewPassword = async () => {
		this.setState({ loader: true, startedTyping: true });
		const {
			email,
			newPassword,
			newRePassword,
			org_alias,
			verificationcode
		} = this.state;
		if (!this.validateBothPassword(newPassword, newRePassword)) {

			this.setState({ loader: false });
			return;
		} else {

			const resultData = await setNewPassword(verificationcode, newPassword, org_alias, email);
			if (resultData.status === 200) {
				await this.sendEmailverifyEmail();
				const url = getDynamicUrl()
				NotificationManager.success("", 'Password has been saved Successfully ', 5000);
				setTimeout(() => {
					window.location.href = `${url}/${org_alias}/login`;
					this.setState({ loader: false });
				}, 3000)
				return
			} else {
				NotificationManager.error("", "Something went wrong! Please try again after some time", 5000);
				this.setState({ loader: false });
			}
		}
	}
	/**
	 * validate BOth password before save 
	 */
	validateBothPassword = (newp, newrp) => {

		let validForm = true;
		if (newp.trim() === '') {
			validForm = false
			this.setState({ newPasswordError: 'Field cannot be left blank' })
		}

		if (newp.trim() === '') {
			this.setState({ newRePasswordError: 'Field cannot be left blank' })
			validForm = false
		}

		if (!this.validatePassword(newp)) {
			validForm = false
		}

		if (!this.validatePassword(newrp)) {
			validForm = false
		}

		if (newp !== newrp) {
			validForm = false
		}
		if (validForm) {
			return true
		} else {
			return false;
		}
	}

	/**
	 * When Agent click resent email varification code URL
	 */
	sendEmail = (body) => {
		fetch(`${GATEWAY_URL}/sendEmail`, {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
		})
			.then()
			.then(response => {
			})
			.catch(e => {
				console.log(e, "ERROR");
			});
	}

	render() {
		return (
			<div className="login-container loginBG">

				<NotificationContainer />

				{this.state.loader &&
					<div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>
				}
				<div className="loginPage">
					<div className="container">
						<div className="login-header">
							<img
								className="loginHeaderLogo"
								src={require("../../assets/images/appypie-livechat.svg")}
								alt="logo"
							/>
						</div>
					</div>

					{this.state.linkExpireVal ? (<div className="container">
						<div className="loginBody pt-5">
							<div className="login-description signup-description veryfy-your-mail-description">
								<h5>{this.state.linkExpireMsg}</h5>
							</div>
						</div>
					</div>) : (this.state.showNewPasswordForm ? (
						<div className="container">
							<div className="loginBody pt-5">
								<div className="login-description signup-description veryfy-your-mail-description">
									<h5>Set your password</h5>
									<p>
										Enter a password and then re-type it to confirm
									</p>
								</div>
								<div className="row login-body-set-pass">
									<div className="col-xs-12 col-md-7 loginFormOrlienparent">
										<div className="login-form-reset">

											<TextField
												error={this.state.newPasswordError === "" ? false : true}
												id="standard-password-input"
												label={data.hint.newPassword}
												type="password"
												margin="normal"
												onChange={this.handlePassword}
												onKeyPress={this.handlePassword}
												value={this.state.newPassword}
												autoComplete="current-password"
												required
											/>
											{this.state.newPasswordError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span style={{ color: 'initial' }}>{this.state.newPasswordError}</span></div> : <div className="Loginwarning" style={this.state.newPasswordError === "" ? { display: "block" } : { display: "none" }}></div>}

											<TextField
												error={this.state.newRePasswordError === "" ? false : true}
												id="standard-password-input"
												label={data.hint.newRePassword}
												type="password"
												margin="normal"
												onChange={this.handleRePassword}
												onKeyPress={this.handleRePassword}
												value={this.state.newRePassword}
												autoComplete="current-password"
												required
											/>

											{this.state.newRePasswordError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span style={{ color: 'initial' }}>{this.state.newRePasswordError}</span></div> : <div className="Loginwarning" style={this.state.newRePasswordError === "" ? { display: "block" } : { display: "none" }}></div>}

											<RawTooltip message={data.message} position={"right"}>
												<img src={require("../../assets/img/icon/infoIcon.svg")} alt="" />
											</RawTooltip>
											<div className="pt-8">
												<button className="btn-blue white-text btn-radius  px-10 py-4" onClick={this.handleUpdateNewPassword}>Next</button>
											</div>
										</div>
									</div>

									<div className="col-xs-12 col-md-5 login-social-pass-require pt-8">
										<div className="px-8">
											<p className="pass-reqir">Password Requirement</p>
											<ul className="pass-ver pl-8" style={{ 'listStyleType': 'disc' }}>
												<li style={{ color: this.state.startedTyping ? (this.state.lengthVerify ? 'green' : 'red') : (null) }}> Length between 8 and 16 characters</li>
												<li style={{ color: this.state.startedTyping ? (this.state.lowerCaseVerify && this.state.upperCaseVerify && this.state.specialCharacterVerify && this.state.numericVerify ? 'green' : 'initial') : (null) }}> Password must contain at least 1 character
													<ul className="pl-5" style={{ 'listStyleType': 'disc' }}>
														<li style={{ color: this.state.startedTyping ? (this.state.lowerCaseVerify ? 'green' : 'red') : (null) }}> in lower case (a-z) </li>
														<li style={{ color: this.state.startedTyping ? (this.state.upperCaseVerify ? 'green' : 'red') : (null) }}> in Upper case (A-Z) </li>
														<li style={{ color: this.state.startedTyping ? (this.state.specialCharacterVerify ? 'green' : 'red') : (null) }}> As spacial characters (!@#$%^&) </li>
														<li style={{ color: this.state.startedTyping ? (this.state.numericVerify ? 'green' : 'red') : (null) }}> As numeric  (0-9) </li>
													</ul>
												</li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					) : (this.state.verificationcodeError !== '' || this.state.emailError !== '' ? (<div className="container">
						<div className="loginBody pt-3">
							<div className="login-description signup-description veryfy-your-mail-description">
								<h5>{data.AgentVerificationTxt}</h5>
								<p>{data.AgentVerificationTxtDesc}</p>
							</div>
							<div className="veryfy-your-mail-body">
								<div className="veryfy-your-mail-form">
									<TextField
										error={this.state.emailError === "" ? false : true}
										id="standard-password-input"
										label={data.Agent.email}
										type="text"
										margin="normal"
										onChange={this.handleEmail}
										onKeyPress={this.handleEmail}
										value={this.state.email}
										required
									/>
									{this.state.emailError !== "" ? <div className="Loginwarning"><img src={require("../../assets/images/danger.png")} alt="" /><span style={{ color: 'initial' }}>{this.state.emailError}</span></div> : <div className="Loginwarning" style={this.state.emailError === "" ? { display: "block" } : { display: "none" }}></div>}
									<div className="text-center pt-8">
										<button className="btn-blue white-text btn-radius  px-10 py-4" onClick={this.resentVerificationCode}>
											Resend
										</button>
									</div>

								</div>
							</div>
						</div>
					</div>) : (null)
					))}
				</div>
			</div>
		);
	}
}
export default AgentVerification;
