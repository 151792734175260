import React, { Component } from 'react';
// import ReactDropzone from "react-dropzone";

import { checkIsOwnerLogin, checkInitialOwnerWithCompanyNameAndAgentName ,getUTCTimeDate, cleanupString} from "./OnboardHelper";
import {
	createNewUser,
	getOwnerUserDataForLogin,
	updateSessioninCollection,
    checkCompanyAliasAndCreateUnique,updateAgentData,
    getClientIpAndCountry
	
} from './Model/DbModel'

import {
	isUserExistWithEmail,
	login
} from "./../../config/SignupLogin";

import { updateCompanyDetails } from "../../services/service";
// import db from '../../config/firebase';
import firebaseServices from '../../firebase';
const DESIGN_CLOUD = "designcloud";
const CHATBOTNAME = "Chatbot";

class CompanyInfoComponentStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            compnayinfo: '',
            loader: false,
            companyName: '',    
            companyNameError: '',
        }
    }

    /**
     *  Change Handler 
     */
    txtOnChangeHandler = (e) => {
        e.preventDefault();
        
        // validateData
        this.setState({companyName: e.target.value,companyNameError:''});
    }

    /**
     * initial Component did mount
     */
    componentDidMount = async () => {

        this.setState({ loader: true });
        getClientIpAndCountry();
        const loggerInUSer = await  checkInitialOwnerWithCompanyNameAndAgentName();

        if(loggerInUSer==='OWNER'){
            const userInfo = await checkIsOwnerLogin();
            if(userInfo && userInfo?.company_name && userInfo?.organization_alias){
                localStorage.removeItem('companyName');
                window.location.href = '/'+userInfo.organization_alias;
                return;
            }
        } else if(loggerInUSer==='AGENT'){
            const userInfo = await checkIsOwnerLogin();
            window.location.href= '/'+userInfo.organization_alias;
            return;
        }
        else if(loggerInUSer== 'COMMONLOGIN')
        {
			/* start of code added by ruchi for common login*/
			var userCommonLoginData = JSON.parse(localStorage.getItem('userdata'));
			
			const IsEmail = await isUserExistWithEmail(userCommonLoginData.email);
			if (IsEmail) {
				localStorage.setItem("accessToken", userCommonLoginData.access_token);
				//updateSessioninCollection(userCommonLoginData.access_token, userCommonLoginData.email);
				let euserId = IsEmail[0].id
				const getloginData = await getOwnerUserDataForLogin(euserId, userCommonLoginData.email);				
                await updateSessioninCollection(userCommonLoginData.access_token, userCommonLoginData.email);	
                getloginData.agent_name = getloginData.name;	
                getloginData.realmId = userCommonLoginData._id ? userCommonLoginData._id : '';		
				localStorage.setItem('agent', JSON.stringify(getloginData));
				const agentId = getloginData.agentId;
				const ownerId = getloginData.ownerId;
				const newAgentData = {
						last_login: getUTCTimeDate(),
						online_status: 1
					}
				await updateAgentData(ownerId, agentId, newAgentData)
					//window.location = '/'
			    const getUserLogin = localStorage.getItem('redirectPage');
					this.setState({ loader: false });
					if (getUserLogin) {
						localStorage.removeItem('redirectPage');
						this.props.history.push(getUserLogin);
						return
					} else {
						window.location = '/'
						// this.props.history.push("/");
						return
					}
			}				
			else
			{
				
			}
			/* end of code added by  ruchi for common login*/
		}
        const companyName = localStorage.getItem('companyName')
        if(companyName){
            this.setState({companyName: companyName});
        }
        this.setState({ loader: false });

    }

    /**
     * Validation Function
     */
    validateData = () => {
        const{companyName} = this.state;
        
        if(!companyName) {
            
            this.setState({companyNameError: 'Please Fill  Company discription'});
            return false
        }

        if(!companyName.trim()) {
           
            this.setState({companyNameError: 'Please Fill  Company discription'});
            return false
        }
        
        return true;
    }

    nextPage = async(e) => {
        e.preventDefault();
        const{companyName} = this.state;
        if(this.validateData()) {
            this.setState({ loader: true });
            let userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
            if(userDetails && (userDetails.company_reference === DESIGN_CLOUD || userDetails.company_reference === CHATBOTNAME) && userDetails.ownerId && userDetails.email) {
                let ownerEmail = userDetails.ownerEmail ? userDetails.ownerEmail : userDetails.email;
                let neworgAlias = '';
                const company_namewithoutSPace = cleanupString(companyName)
                const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
                if (uniqueResponce) {
                    let randValu = Math.floor(Math.random() * 10000);
                    neworgAlias = company_namewithoutSPace + randValu;
                } else {
                    neworgAlias = company_namewithoutSPace
                }
                firebaseServices.db.collection('users').doc(userDetails.ownerId).update({
                    company_name:companyName.trim(),
                    organization_alias:neworgAlias
                }).then(async () => {
                    const updateProductData = await updateCompanyDetails(userDetails.ownerId, ownerEmail, neworgAlias, companyName.trim());
                    console.log('----updateProductData >>>', updateProductData);
                    console.log('localStorage.getItem()>>>', JSON.parse(localStorage.getItem('agent')));
                    userDetails.company_name = companyName.trim();
                    userDetails.organization_alias = neworgAlias;
                    userDetails.ownerIdentifire = neworgAlias;
                    localStorage.setItem('agent', JSON.stringify(userDetails));
                    window.location.href = "/"+neworgAlias;
                    return false;
                }).catch(() => {
                    this.setState({
                        loader:false,
                        companyNameError: 'Something went wrong, Please try again after some time'
                    })
                })
                return false;
            } else {
                // save data in localstorage and Move Next ;
                localStorage.setItem('companyName',companyName.trim())
                this.props.history.push("/customize-widget/"); 
            }

        }else{
            console.log('error');
        }
    }

    render() {
        return (

            <React.Fragment>
            {this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

            <div className="login-container loginBG">
                <div className="loginPage">
                    <div className="container">
                        <div className="login-header">
                            <img
                                className="loginHeaderLogo"
                                src={require("../../assets/images/appypie-livechat.svg")}
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className="container">
                        <div className="company-information company-information1">
                            <div className="login-description pb-10 mb-5">
                                <h5>Enter your Company name</h5>
                                <h4 style={{ textAlign:'center'  }}>(This can be changed later)</h4>
                            </div>
                            <form  method="post" action="" >
                                <div className="form-group">
                                    <label htmlFor="exampleInputCompany">Company name </label>
                                    <input type="text" autoComplete="off" className="form-control" onChange={(e) => this.txtOnChangeHandler(e)}  value={this.state.companyName} name="companyName" id="exampleInputCompany" aria-describedby="emailHelp" placeholder="Enter name" />
                                    
                                    {
                                    this.state.companyNameError &&  
                                    <span style={{color:'red'}}>Please enter company name</span>
                                    }
                                </div>
                                <div className="text-center py-8">
                                    <button type="submit" className="btn btn-primary px-10 w-200" style={{borderRadius: '100px'}} onClick={(e) => this.nextPage(e)} >Next</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className="footerStepbyStep loginFooterReview">
                        <div className="container">
                            <div className="catfeacture-section-2" style={{display: 'block'}}>
                            <h4 className="clicntLogo-heading">Join <strong>7 million +</strong> other businesses:</h4>
                            <ul>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/accenture.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/assurant.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/decathlon.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/deloitte.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/escorts.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/loreal.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/mitie.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/nike.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/pinnacle.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/sodexo.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/shangri.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/southwest.png')} /></li>
                                <li><img alt="" src={require('./../../assets/images/footer-logo/thehomedepot.png')} /></li>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </React.Fragment>
        );
    }
}

export default CompanyInfoComponentStep1;
