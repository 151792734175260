/**
 * @description Create A registaion page with AWS Acogniti Authentication Porces 
 * @version 1.0
 * @author Bineet Kumar Chaubey <bineet@appypiellp.com>
 * @file CompanyRegistaion.js
 */
import React, { Component } from 'react';
// import RawTooltip from "../Tooltip/rawtooltip";
// import { connect } from "react-redux";
import './Login.scss';
import { NotificationContainer, NotificationManager } from "react-notifications";
import TextField from "@material-ui/core/TextField";
import {
	registration,
	resendConfirmationCode,
	liveChatGoogleLogin,
	getConvertPassCed,
	loginWithGoogle,
	isUserExistWithEmail,
	login
} from "./../../config/SignupLogin";
// import db from './../../config/firebase';

import {
	createNewUser, updateSessioninCollection,
	getOwnerUserDataForLogin,
	updateAgentData,
	checkCompanyAliasAndCreateUnique
} from './Model/DbModel'
import { checkIsUserOrOwnerLogin, getUTCTimeDate, cleanupString } from './OnboardHelper';
import data from "../../localization/login-registration.json";
import { DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';

//import {mailUrl} from "../../config/mailFunctions.js";
const publicIp = require("public-ip");

const firebaseAuthKey = "firebaseAuthInProgress";
const appTokenKey = "appToken";

class CompanyRegistration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			nameEror: "",
			email: "",
			emailError: "",
			password: "",
			passwordError: "",
			loader: false,
			// showConfirmation: false,
			code: "",
			code1: "",
			code2: "",
			code3: "",
			code4: "",
			code5: "",
			code6: "",
			showWorng: false,
			show_hint: false,
			show_warning: false,
			checked_reg: true,
			loginError: "",
			IPv4: ""
		};
		this.input2 = React.createRef();
		this.input3 = React.createRef();
		this.input4 = React.createRef();
		this.input5 = React.createRef();
		this.input6 = React.createRef();
	}



	/**
	 * check if login or not
	 */
	componentDidMount = async () => {
		this.setState({ loader: true });
		/**
		 * on this component first check user login then redirect inner page
		 *  if user not loggedin and  company name not set on this page then user is comming from direct here then redirect 
		 *  to first step of on-boarding
		 */
		const islogin = await checkIsUserOrOwnerLogin();
		if (islogin) {
			// console.log('ssssssss');
			return
		} else {
			const companyName = localStorage.getItem('companyName');
			if (!companyName) {
				window.location = '/company-info'
				return
			}
		}
		this.setState({ loader: false });
		publicIp.v4().then((ip4) => {
			this.setState({
				IPv4: ip4
			})
		});
	}

	/**
	 * validate email
	 */
	validateEmail = (email) => {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	};

	/**
	 * validate password
	 */

	validatePassword = (password) => {
		var re = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$#!%*?&])[A-Za-z\d@$#!%*?&]{8,16}$/;
		return re.test(password);
	};

	handleName = (e) => {
		if (e.target.value === "") {
			this.setState({ nameEror: data.error.name_error, name: e.target.value });
		} else {
			if (e.key === "Enter") {
				this.signUpChatBot();
			}
			this.setState({ name: e.target.value, nameEror: "" });
		}
	};

	handleEmail = (e) => {
		var _email = e.target.value.trim();
		if (!this.validateEmail(_email)) {
			this.setState({
				emailError: data.error.email_error,
				email: _email,
			});
		} else {
			if (e.key === "Enter") {
				this.signUpChatBot();
			}
			this.setState({
				email: _email,
				emailError: "",
			});
		}
	};

	handlePassword = (e) => {
		if (!this.validatePassword(e.target.value)) {
			this.setState({
				passwordError: data.error.password_incorrect,
				password: e.target.value,
			});
		} else {
			if (e.key === "Enter") {
				this.signUpChatBot();
			}
			this.setState({
				password: e.target.value,
				passwordError: "",
			});
		}
	};

	/**
	 * 
	 * handle Actual SignUp Process On signup Button
	 */
	signUpChatBot = async () => {
		this.setState({ loader: true, loginError: "" });
		const {
			name,
			email,
			password,
			emailError,
			passwordError,
			nameEror,
		} = this.state;
		if (
			email === "" ||
			password === "" ||
			emailError !== "" ||
			passwordError !== ""
		) {
			//   if (name == "") {
			//     this.setState({ nameEror: "Required" });
			//   }
			if (email === "") {
				this.setState({ emailError: "Required field cannot be left blank." });
			}
			if (password === "") {
				this.setState({
					passwordError: "Required field cannot be left blank.",
				});
			}
			this.setState({ loader: false });
			return;
		} else {

			var res = await registration(email, password);
			res.status = parseInt(res.status);

			if (res.status === 201 && res.data !== "{}") {
				localStorage.setItem("_email", email);
				localStorage.setItem("password", password);
				localStorage.setItem("login", true);
				// localStorage.setItem("OwnerName", name);
				this.setState({
					name: "",
					email: "",
					password: "",
					loader: false,
					// showConfirmation: true
				});
				this.props.history.push("/company-verification");
			} else if (res.status === 402) {

				// user exits per not varified
				// {status: "402", message: "{"code":"UsernameExistsException",
				// "name":"UsernameExistsException","message":"User already exists"}", data: null}

				localStorage.setItem("_email", email);
				localStorage.setItem("password", password);
				this.handleResendConfirmation();
				this.setState({
					emailError: data.error.email_already_exist,
					password: "",
					loader: false,
					// showConfirmation: true
				});

				const companyName = localStorage.getItem('companyName');
				const settingrgbColor = localStorage.getItem('settingrgbColor')
				const settingbgColor = localStorage.getItem('settingbgColor')
				const clientBackgroundColor = localStorage.getItem('clientBackgroundColor')
				const chatBlackImage = localStorage.getItem('chatBlackImage')
				const whiteImage = localStorage.getItem('whiteImage')
				const fbwtimage = localStorage.getItem('fbwtimage')

				// console.log(settingrgbColor,settingbgColor,clientBackgroundColor,companyName,chatBlackImage,whiteImage,fbwtimage,"hello lokeh")
				if (settingrgbColor === null || settingbgColor === null || clientBackgroundColor === null || companyName === null || chatBlackImage === null || whiteImage === null || fbwtimage === null) {
					// console.log("inside if condition") 
					localStorage.removeItem('settingrgbColor')
					localStorage.removeItem('settingbgColor')
					localStorage.removeItem('clientBackgroundColor')
					localStorage.removeItem('chatBlackImage')
					localStorage.removeItem('whiteImage')
					localStorage.removeItem('fbwtimage')
					localStorage.setItem('skipSignUp', 'true')
					this.props.history.push("/");
				}
				else {
					this.props.history.push("/company-verification");
				}
				// Akash Code
				// localStorage.setItem("_email", email);
				// this.setState({ loader: false });

			} else if (res.status === 401) {
				// User Exist And Varify 
				// {status: "401", message: "{"code":"UsernameExistsException",
				// 	"name":"UsernameExistsException","message":"User already exists"}", data: null
				// }
				// User exist Send User to Login Page nad Ask For Login
				this.setState({
					loginError: 'The entered email is already registered with other Appy Pie products. You can use the same credentials in case to login in Livechat.',
					loader: false
				})
				// NotificationManager.error("", "This email address you have entered is already registered, Please login to...", 3000);
				// localStorage.setItem('redirectPage', '/agent-setup');
				// setTimeout(() => {
				// 	// console.log(data.error.user_already_exists);
				// 	this.props.history.push("/login");
				// }, 6000);

			} else {
				localStorage.setItem("_email", email);
				this.setState({
					loader: false,
					emailError: data.error.email_already_exist,
				});
				setTimeout(() => {
					// console.log(data.error.user_already_exists);
				}, 100);
			}
		}
	};

	/**
	 *  handle Reset code 
	 */
	handleResendConfirmation = async () => {
		this.setState({ loader: true });
		var res_resend = await resendConfirmationCode(
			localStorage.getItem("_email")
		);

		if (res_resend.status === 200) {
			this.setState({ loader: false });
		} else {

			this.setState({ loader: false });
		}
	};

	/**
	 * Google Signup Handler Method
	 * Need to signupwith Googgle
	 */
	handleGoogleLogin = async () => {
		var res = await loginWithGoogle();

		if (!res.error) {
			console.clear();

			localStorage.setItem(firebaseAuthKey, "1");
			var email = res.result.user.email;
			var uid = res.result.user.uid;
			var token = res.result.credential.accessToken;

			this.setState({ loader: true });

			const credential = getConvertPassCed(email);
			const IsEmail = await isUserExistWithEmail(email)


			if (!IsEmail) {
				// insert into AWS page 
				liveChatGoogleLogin(email, credential, uid, "google", token).then(Result => {
					/*
					 * both case or handle If User Already in common  login || OR New User 
					 * This will Fail if user have change his gmail email account Password on AWS common login Through 
					 * Reset password Featured,  and then Try to login With google 
					 *  
					 * console.log('checking');
					 * console.log('Result',Result);
					 * return ;
					 */



					// Login happen from Common API 
					// login With AWS confnito common and Create User IN Our System In Firebase Collection
					login(email, credential).then(async (loginRes) => {



						if (loginRes.status === '401') {
							/* Gmail Deafult password is not varify With Common Login API
							* it is possbility that User have Login This gmail With A custom password before By Username/ppassword Method
							*  Or User Have Changed His Google Default Password
							*/
							// NotificationContainer.error('','Please Use Email ans Passowrd Method to signup/login')
							NotificationManager.error("", "Please use email and passowrd method to signup/login", 5000);
							this.setState({ loader: false });
							return;
						}
						// now check Is User Exists


						var _data = JSON.parse(loginRes.data);
						const accessToken = _data.accessToken.jwtToken
						localStorage.setItem("accessToken", accessToken);

						const companyName = localStorage.getItem('companyName');
						const settingrgbColor = localStorage.getItem('settingrgbColor')
						const settingbgColor = localStorage.getItem('settingbgColor')
						const settingChatIcon = localStorage.getItem('settingChatIcon')
						const chatBlackImage = localStorage.getItem('chatBlackImage')
						const whiteImage = localStorage.getItem('whiteImage')
						const fbwtimage = localStorage.getItem('fbwtimage')
						// initil Data to create User

						let neworgAlias = '';
						const company_name = companyName
						if (company_name) {
							// const company_namewithoutSPace =  company_name.split(" ").join("").replace(/[^\w\s]/gi, '').toLowerCase();
							const company_namewithoutSPace = cleanupString(company_name)
							const uniqueResponce = await checkCompanyAliasAndCreateUnique(company_namewithoutSPace);
							if (uniqueResponce) {
								let randValu = Math.floor(Math.random() * 10000);
								neworgAlias = company_namewithoutSPace + randValu;
							} else {
								neworgAlias = company_namewithoutSPace
							}
						}

						const updateData = {
							companyName: companyName,
							organization_alias: neworgAlias,
							settingrgbColor: settingrgbColor,
							settingbgColor: settingbgColor,
							settingChatIcon: settingChatIcon,
							email: email,
							whiteImage: whiteImage,
							chatBlackImage: chatBlackImage,
							fbwtimage: fbwtimage,
							password: credential,
							accessToken: accessToken
						}

						const UserDocID = await createNewUser(updateData, 4)

						this.setState({ loader: true });
						if (UserDocID) {
							// Add User DataENtry Through ThisAPi 
							localStorage.removeItem("password");
							// localStorage.removeItem("OwnerName");
							localStorage.removeItem('settingrgbColor')
							localStorage.removeItem('settingbgColor')
							localStorage.removeItem('settingChatIcon')
							localStorage.removeItem('chatBlackImage')
							localStorage.removeItem('whiteImage')
							localStorage.removeItem('fbwtimage')
							localStorage.removeItem("_email");
							localStorage.removeItem("companyName");
							this.setState({
								email: "",
								password: "",
								loader: false,
							});
							updateSessioninCollection(accessToken, UserDocID);
							const getloginData = await getOwnerUserDataForLogin(UserDocID, email);

							delete getloginData.password
							// Valid user
							let chatURL = DOMAIN_NAME + '/' + neworgAlias;
							var body = [`email=${email.toLowerCase()}&type=welcome_email&data=${"data"}&req_ip=${this.state.IPv4}&chatURL=${chatURL}`];
							this.sendEmail(body);
							getloginData.agent_name = getloginData.name;
							localStorage.setItem('agent', JSON.stringify(getloginData));
							this.props.history.push("/agent-setup");

						} else {

							// this.props.history.push("/company-erropage");// TODO: redirect 30 
							this.setState({ loader: false });
						}

					}).catch(err => {
						console.log(err);
						this.setState({ loader: false });
					})

				}).catch(err => {
					console.log(err);
					this.setState({ loader: false });
				})

			} else {

				login(email, credential).then(async (loginRes) => {


					if (loginRes.status === '401') {
						/* Gmail Deafult password is not varify With Common Login API
						* it is possbility that User have Login This gmail With A custom password before By Username/ppassword Method
						*  Or User Have Changed His Google Default Password
						*/
						// NotificationContainer.error('','Please Use Email ans Passowrd Method to signup/login')
						NotificationManager.error("", "Please use email and passowrd method to signup/login", 5000);
						this.setState({ loader: false });
						return;
					}

					// Email is exits in our database And Login User Have Done
					// TODO: 
					this.setState({
						email: "",
						password: "",
						loader: false,
					});
					var _data = JSON.parse(loginRes.data);
					const accessToken = _data.accessToken.jwtToken
					localStorage.setItem("accessToken", accessToken);
					const userObject = IsEmail[0];
					const UserDocID = userObject.id
					updateSessioninCollection(accessToken, UserDocID);
					const getloginData = await getOwnerUserDataForLogin(UserDocID, email);

					delete getloginData.password
					// valid user
					getloginData.agent_name = getloginData.name;
					localStorage.setItem('agent', JSON.stringify(getloginData));

					const agentId = getloginData.agentId
					const ownerId = getloginData.ownerId
					const newAgentData = {
						last_login: getUTCTimeDate(),
						online_status: 1
					}
					await updateAgentData(ownerId, agentId, newAgentData)

					localStorage.removeItem("password");
					// localStorage.removeItem("OwnerName");
					localStorage.removeItem('settingrgbColor')
					localStorage.removeItem('settingbgColor')
					localStorage.removeItem('settingChatIcon')
					localStorage.removeItem('chatBlackImage')
					localStorage.removeItem('whiteImage')
					localStorage.removeItem('fbwtimage')
					localStorage.removeItem("_email");
					localStorage.removeItem("companyName");

					// this.props.history.push("/");
					window.location.href = '/'
					// TODO : update Tocken and  Redirect to Next Page
					return

				}).catch(err => {

					console.log(err.message);
					this.setState({ loader: false });
				})
			}



		}
	}

	sendEmail = (body) => {
		fetch(GATEWAY_URL + '/sendEmail', {
			method: "post",
			body: body,
			headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
		})
			.then()
			.then(response => {
				console.log(response);
			})
			.catch(e => {
				console.log(e, "ERROR");
			});
	}

	reactivate = () => {
		this.props.showPopupDeactivate(false);
	}

	render() {
		const Deactivatepopup = (
			<div>
				<div className="dashboard-chatbot-cover"></div>
				<div
					className="deleting-popup-container"
					style={{ top: "40%", left: "40%", width: "323px" }}
				>
					<div className="headerAddTimeOffdashboard">Confirm</div>
					<div style={{ padding: "16px" }}>
						<div style={{ color: "#626262", fontSize: "11px" }}>
							Your account is deactivated. Do you want to reactivate this
							account?
						</div>
						<div className="ETbuttons mt-4">
							<div className="ETSave" onClick={this.reactivate}>
								Yes
							</div>
							<div
								className="ETCancel"
								onClick={() => this.props.showPopupDeactivate(false)}
							>
								No
							</div>
						</div>
					</div>
				</div>
			</div>
		);

		return (
			<div className="login-container loginBG">
				<NotificationContainer />
				{this.state.loader && <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>}

				<div className="loginPage">
					{this.props.show_deactivate ? Deactivatepopup : null}
					<div className="container">
						<div className="login-header">
							<img
								className="loginHeaderLogo"
								src={require("../../assets/images/appypie-livechat.svg")}
								alt="logo"
							/>
						</div>
					</div>
					<div className="">
						<div className="loginBody pt-5">
							<div className="login-description signup-description">
								<h5>{data.description.h1}</h5>
								{/* <h5>{data.description.h2}</h5> */}
							</div>

							<div className="login-body">
								<div className="loginFormOrlienparent">
									<div className="login-form orLine">
										{this.state.loginError !== "" ? (<div className="Loginwarning">
											<img
												src={require("../../assets/images/danger.png")}
											/>
											<span>{this.state.loginError}</span>
										</div>) : (null)}

										<TextField
											error={this.state.emailError === "" ? false : true}
											id="standard-password-input"
											label={data.hint.email}
											type="text"
											margin="normal"
											onChange={this.handleEmail}
											onKeyPress={this.handleEmail}
											value={this.state.email}
											required
										/>
										{this.state.emailError !== "" ? (
											<div className="Loginwarning">
												<img alt=""
													src={require("../../assets/images/danger.png")}
												/>
												<span>{this.state.emailError}</span>
											</div>
										) : (
											<div
												className="Loginwarning"
												style={
													this.state.emailError === ""
														? { display: "block" }
														: { display: "none" }
												}
											></div>
										)}
										<div className="loginPassInfo">
											<TextField
												error={this.state.passwordError === "" ? false : true}
												id="standard-password-input"
												label={data.hint.password}
												type="password"
												margin="normal"
												onChange={this.handlePassword}
												onKeyPress={this.handlePassword}
												value={this.state.password}
												autoComplete="current-password"
												required
											/>
											<span
												className="logininfoIcon"
												onMouseEnter={() =>
													this.setState({ show_hint: true })
												}
												onMouseLeave={() =>
													this.setState({ show_hint: false })
												}
											>
												<img alt=""
													src={require("../../assets/img/icon/infoIcon.svg")}
												/>
												{this.state.show_hint ? (
													<span
														className="titleShowHover titleShowHoverWidth"
														style={
															this.state.show_hint
																? { display: "block" }
																: null
														}
													>
														{data.message}
													</span>
												) : null}
											</span>
										</div>
										{this.state.passwordError !== "" ? (
											<div className="Loginwarning">
												<img alt=""
													src={require("../../assets/images/danger.png")}
												/>
												<span>{this.state.passwordError}</span>
											</div>
										) : null}
										{/* <RawTooltip message={data.message} position={"right"}> */}

										{/* </RawTooltip> */}
										<div>
											<input
												type="checkbox"
												checked={this.state.checked_reg}
												onChange={(e) =>
													this.setState({ checked_reg: e.target.checked })
												} style={{ width: 'auto', display: 'inline-block' }} />
											<span className="checkbox-reg pl-1">
												{" "}
												By signing up, you agree to our{" "}
												<a
													href="https://www.appypie.com/terms-of-use"
													target="_blank"
												>
													T&C
												</a>{" "}
												our{" "}
												<a
													href="https://www.appypie.com/privacy-policy"
													target="_blank"
												>
													Privacy Policy.
												</a>{" "}
											</span>
										</div>
										{this.state.checked_reg ? (
											<div onClick={this.signUpChatBot}>
												<div className="loginSignupBTN">
													<div className="singnInText">

														{data.button.signup}
													</div>
												</div>
											</div>
										) : (
											<div>
												<div
													className="loginSignupBTN"
													style={{
														backgroundColor: "#6a6a6a",
														cursor: "auto",
													}}
												>
													<div className="singnInText">
														{data.button.signup}
													</div>
												</div>
											</div>
										)}
										<div className="forgotOrSignupLoginPage">
											{data.already}
											<a
												style={{ color: "#007bff" }}
												onClick={() => this.props.history.push("/login")}
											>
												<span className="login">{data.button.login}</span>
											</a>
										</div>
									</div>
								</div>
								<div className="login-social">
									<div className="socialBtnCenter">
										<div className="socialMediaBtnLink">
											<div
												className="socialLink"
												onClick={this.handleGoogleLogin}
											>
												<span className="gp">
													<img alt=""
														src={
															"https://d2wuvg8krwnvon.cloudfront.net/newui/images/g-normal.png"
														}
													/>
													{data.social.google}
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="termsAndConditionPrivacyPolicy">
								<p className="seureArea">
									<img alt="" src={require("../../../src/assets/images/lock2.svg")} />Secure Area
								</p>
							</div>
						</div>
					</div>
					<div className="container">
						<div className="login-footer">
							<div className="login-footer-1"></div>
							<div className="loginFooterReview registrationImgFilter">
								<div className="review-section">
									<div className="container">
										<div className="review-companies">
											<div className="reviewcompanieswrapper">
												<div className="reviewcompaniesimg">
													<a
														href="https://privacy.truste.com/privacy-seal/validation?rid=aaa1a089-4a08-4066-867e-29f8b4ebce47"
														target="_blank"
														rel="nofollow noopener"
													>
														<img alt=""
															src={require("../../../src/assets/images/login/login1.png")}
														/>
													</a>
												</div>
											</div>
											<div className="reviewcompanieswrapper">
												<div className="reviewcompaniesimg">
													<a
														target="_blank" rel="nofollow noopener">
														<img alt=""
															src={require("../../../src/assets/images/login/login2.png")}
														/>
													</a>
												</div>
											</div>
											<div className="reviewcompanieswrapper">
												<div className="reviewcompaniesimg">
													<a
														target="_blank" rel="nofollow noopener" >
														<img alt=""
															src={require("../../../src/assets/images/login/login3.png")}
														/>
													</a>
												</div>
											</div>
											<div className="reviewcompanieswrapper">
												<div className="reviewcompaniesimg">
													<a
														target="_blank"
														rel="nofollow noopener"
													>
														<img alt=""
															src={require("../../../src/assets/images/login/login4.png")}
														/>
													</a>
												</div>
											</div>
											<div className="reviewcompanieswrapper">
												<div className="reviewcompaniesimg">
													<a
														target="_blank"
														rel="nofollow noopener"
													>
														<img alt=""
															src={require("../../../src/assets/images/login/login5.png")}
														/>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default CompanyRegistration;