import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip"; 
import defaultValues from "../../localization/default-values.json";
import { checkPhoneCall } from '../livechat/components/Twilio';
import { activityLog } from '../../services/service';
import TextField from '@material-ui/core/TextField';
/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';
import firebaseServices from '../../firebase';
/* For Dropdown End */
const dateFormat="X";

class IdleTime extends React.Component {
    constructor(){
        super();
        this.state = {
            loader:false,
            ownerId:'',
            visitor_reply_check:'',
            visitor_reply_min:'',
            agent_reply_check:'',
            agent_reply_min:'',
            agent_inactive_check:'',
            agent_inactive_status:'',
            agent_inactive_min:'',
            agent_inactive_min_error:'',
            visitor_reply_min_error:'',
            agent_reply_min_error:'',
            trigger_message_time:'',
            trigger_message_check:'',
            trigger_message_time_error:'',
            trigger_message:'',
            trigger_message_error:'',
            trigger_message_repeat:'',
            trigger_message_repeat_error:'',
            trigger_message_interval:'',
            trigger_message_interval_error:'',
            trigger_message_end:'',
            trigger_message_end_error:'',
            payment_status:true
        }
    }

    handleChange = (e) => {
        let target = e.target;
        if(target.type==="checkbox") {
            this.setState({
                [target.name]:target.checked
            })
        } else {
            this.setState({
                [target.name]:target.value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.saveForm();
    }

    saveForm = () => {
        let validateForm = this.validateForm();
        if(validateForm) {
            this.setState({
                loader:true
            })
            let updatedon = this.timeZoneFormat(Date.now());
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('settings').doc("IDLETIME").update({
                updatedon:updatedon,
                visitor_reply_check:this.state.visitor_reply_check,
                visitor_reply_min:parseInt(this.state.visitor_reply_min),
                agent_reply_check:this.state.agent_reply_check,
                agent_reply_min:parseInt(this.state.agent_reply_min),
                agent_inactive_check:this.state.agent_inactive_check,
                agent_inactive_status:parseInt(this.state.agent_inactive_status),
                agent_inactive_min:parseInt(this.state.agent_inactive_min),
                trigger_message_check:this.state.trigger_message_check,
                trigger_message_repeat:parseInt(this.state.trigger_message_repeat),
                trigger_message:this.state.trigger_message,
                trigger_message_time:parseInt(this.state.trigger_message_time),
                trigger_message_interval:parseInt(this.state.trigger_message_interval),
                trigger_message_end:this.state.trigger_message_end
            }).then(() => {
                this.setState({
                    loader:false
                })
                toast.success('Idle Time details have been updated successfully!');
                activityLog('Saved timeout settings');
            }).catch((err) => {
                console.log(err);
            })
        }
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId,
            loader:true
        })
        this.getValues(userDetails.ownerId);
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        checkPhoneCall();
        activityLog('Opened timeout settings');
    }

    getValues = (ownerId) => {
        let promise = new Promise((resolve,reject) => {
            try {
                firebaseServices.db.collection('users').doc(ownerId).collection('settings').doc('IDLETIME').get().then((docs) => {
                    if(docs.exists) {
                        let data = docs.data();
                        resolve(data);
                    } else {
                        this.setState({
                            loader:false
                        })
                        
                    }
                }).catch((err) => {
                    reject(ownerId);
                })
            } catch (err) {
                console.log(err);
            }
        })

        promise.then((data) => {
            this.setState({
                visitor_reply_check:data.visitor_reply_check,
                visitor_reply_min:data.visitor_reply_min,
                agent_reply_check:data.agent_reply_check,
                agent_reply_min:data.agent_reply_min,
                agent_inactive_check:data.agent_inactive_check,
                agent_inactive_status:data.agent_inactive_status,
                agent_inactive_min:data.agent_inactive_min,
                trigger_message_check:data.trigger_message_check,
                trigger_message_repeat:data.trigger_message_repeat,
                trigger_message:data.trigger_message,
                trigger_message_time:data.trigger_message_time,
                trigger_message_interval:data.trigger_message_interval,
                trigger_message_interval_error:'',
                trigger_message_error:'',
                trigger_message_repeat_error:'',
                trigger_message_time_error:'',
                loader:false,
                trigger_message_end:data.trigger_message_end
            })
        }).catch((ownerId) => {
           this.getValues(ownerId);
        })
    }

    validateForm = (e) => {
        let returnValue = true;
        if(this.state.visitor_reply_check===true && this.state.visitor_reply_min==='') {
            returnValue=false;
            this.setState({
                visitor_reply_min_error:'Please Enter Minutes Value'
            })
        } else if(this.state.visitor_reply_check===true && (parseInt(this.state.visitor_reply_min)<10 || parseInt(this.state.visitor_reply_min)>180)) {
            returnValue=false;
            this.setState({
                visitor_reply_min_error:'Minutes must be between 10 and 180'
            })
        } else {
            this.setState({
                visitor_reply_min_error:''
            })
        }

        if(this.state.agent_reply_check===true && this.state.agent_reply_min==='') {
            returnValue=false;
            this.setState({
                agent_reply_min_error:'Please Enter Minutes Value'
            })
        } else if(this.state.agent_reply_check===true && (parseInt(this.state.agent_reply_min)<10 || parseInt(this.state.agent_reply_min)>180)) {
            returnValue=false;
            this.setState({
                agent_reply_min_error:'Minutes must be between 10 and 180'
            })
        } else {
            this.setState({
                agent_reply_min_error:''
            })
        }

        if(this.state.agent_inactive_check===true && this.state.agent_inactive_min==='') {
            returnValue=false;
            this.setState({
                agent_inactive_min_error:'Please Enter Minutes Value'
            })
        } else {
            this.setState({
                agent_inactive_min_error:''
            })
        }

        if(this.state.trigger_message_check===true && this.state.trigger_message_time==='') {
            returnValue=false;
            this.setState({
                trigger_message_time_error:'Please Enter Time here'
            })
        } else if(this.state.trigger_message_check===true && parseInt(this.state.trigger_message_time) < 30) {
            returnValue=false;
            this.setState({
                trigger_message_time_error:'Time must be greater than 30 Seconds'
            })
        } else {
            this.setState({
                trigger_message_time_error:''
            })
        }

        if(this.state.trigger_message_check===true && this.state.trigger_message==='') {
            returnValue=false;
            this.setState({
                trigger_message_error:'Please Enter message here'
            })
        } else if(this.state.trigger_message_check===true && this.state.trigger_message.trim().split(/\s+/).length > 50) {
            returnValue=false;
            this.setState({
                trigger_message_error:'Message must contain maximum 50 words'
            })
        } else {
            this.setState({
                trigger_message_error:''
            })
        }

        if(this.state.trigger_message_check===true && this.state.trigger_message_repeat==='') {
            returnValue=false;
            this.setState({
                trigger_message_repeat_error:'Please Select Value here'
            })
        } else if(this.state.trigger_message_check===true && (this.state.trigger_message_repeat<1 || this.state.trigger_message_repeat > 5)) {
            returnValue=false;
            this.setState({
                trigger_message_repeat_error:'Please Select Correct Value here'
            })
        } else {
            this.setState({
                trigger_message_repeat_error:''
            })
        }

        if(this.state.trigger_message_check===true && this.state.trigger_message_interval==='') {
            returnValue=false;
            this.setState({
                trigger_message_interval_error:'Please Enter Time interval here'
            })
        } else if(this.state.trigger_message_check===true && parseInt(this.state.trigger_message_interval) < 30) {
            returnValue=false;
            this.setState({
                trigger_message_interval_error:'Time must be greater than 30 Seconds'
            })
        } else {
            this.setState({
                trigger_message_interval_error:''
            })
        }

        if(this.state.trigger_message_check===true && this.state.trigger_message_end==='') {
            returnValue=false;
            this.setState({
                trigger_message_end_error:'Please Enter Trigger Message here'
            })
        } else if(this.state.trigger_message_check===true && this.state.trigger_message_end.trim().split(/\s+/).length > 50) {
            returnValue=false;
            this.setState({
                trigger_message_end_error:'Message must contain maximum 50 words'
            })
        } else {
            this.setState({
                trigger_message_end_error:''
            })
        }
        return returnValue;
    }

    restoreDefault = (e) => {
        e.preventDefault();
        this.setState({loader:true})
        let updatedon = this.timeZoneFormat(Date.now());
        let dfValues = {
            updatedon:updatedon,
            visitor_reply_check:defaultValues.timeoutSettings.visitor_reply_check,
            visitor_reply_min:defaultValues.timeoutSettings.visitor_reply_min,
            agent_reply_check:defaultValues.timeoutSettings.agent_reply_check,
            agent_reply_min:defaultValues.timeoutSettings.agent_reply_min,
            agent_inactive_check:defaultValues.timeoutSettings.agent_inactive_check,
            agent_inactive_status:defaultValues.timeoutSettings.agent_inactive_status,
            agent_inactive_min:defaultValues.timeoutSettings.agent_inactive_min,
            trigger_message_check:defaultValues.timeoutSettings.trigger_message_check,
            trigger_message_repeat:defaultValues.timeoutSettings.trigger_message_repeat,
            trigger_message:defaultValues.timeoutSettings.trigger_message,
            trigger_message_time:defaultValues.timeoutSettings.trigger_message_time,
            trigger_message_interval:defaultValues.timeoutSettings.trigger_message_interval,
            trigger_message_end:defaultValues.timeoutSettings.trigger_message_end
        };
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('settings').doc("IDLETIME").update(
            dfValues
        ).then(() => {
            this.getValues(this.state.ownerId);
            toast.success('Idle Time details have been updated successfully!');
            activityLog('Restored default timeout settings');
        }).catch((err) => {
            console.log(err);
        })
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.setState({
            loader:true
        })
        this.getValues(this.state.ownerId);
    }


    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render(){
        return (
            <>
            <ReactTooltip />
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.cancelForm}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
           {this.state.payment_status? <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    {/* <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Idle time</h3>
                    </div> */}

                    <div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                    <div className="bg-white left-widgetsetings-box mb-foter-Fixed">
                                        <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="mx-8 mob-mx-4">
                                                <div className="main-heading main-heading-title py-5">
                                                    <h3 className="heding-text">Timeout settings <span className="info-icon">
                                                        <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="Any changes made here won't be reflected at agent's end till they either refresh browser window or re-login. So it would be preferable if you make such changes when no agent is online." />
                                                    </span></h3>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-baseline mb-0">
                                                    {/* <span className="main-checkbox-all">
                                                        <label className="containerCheckBox containerafterchecked">
                                                            <input type="checkbox" name="visitor_reply_check" onChange={this.handleChange} value={this.state.visitor_reply_check} checked={this.state.visitor_reply_check} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </span> */}
                                                    <div className="material-switch pull-right mt-3 mr-4">
                                                    <input id="pre_chat_form" type="checkbox" name="visitor_reply_check" onChange={this.handleChange} value={this.state.visitor_reply_check} checked={this.state.visitor_reply_check} />
                                                        <label htmlFor="pre_chat_form" className="label-default" />
                                                    </div>
                                                    <h5 className="card-title mb-0">If visitor doesn't reply to ongoing chat for</h5>
                                                    <div className="input-group material-input mx-4 w-110">
                                                        <TextField className="form-control" label="Min" id="visitor_reply_min" type="number" name="visitor_reply_min" value={this.state.visitor_reply_min} onChange={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 10, max: 180 } }} variant="outlined" />
                                                        {/* <input type="number" min="10" max="180" placeholder="" className="w-100" name="visitor_reply_min" value={this.state.visitor_reply_min} onChange={this.handleChange} /> */}
                                                        <p className="error">{this.state.visitor_reply_min_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">minutes then end chat for both visitor and agent. 
                                                    <span className="info-icon">
                                                        &nbsp;<img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="Ongoing chat session will end for both visitor and agent. The visitor won't be able to continue it but will be able to start a new chat." />
                                                    </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-baseline mb-0 bb">
                                                    {/* <span className="main-checkbox-all">
                                                        <label className="containerCheckBox containerafterchecked">
                                                            <input type="checkbox" value="true" onChange={this.handleChange} checked={this.state.agent_reply_check} name="agent_reply_check" />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </span> */}
                                                    <div className="material-switch pull-right mt-3 mr-4">
                                                        <input id="pre_chat_form2" type="checkbox" value="true" onChange={this.handleChange} checked={this.state.agent_reply_check} name="agent_reply_check" />
                                                        <label htmlFor="pre_chat_form2" className="label-default" />
                                                    </div>
                                                    <h5 className="card-title mb-0">If agent doesn't reply to ongoing chat for</h5>
                                                    <div className="input-group material-input mx-4 w-110">
                                                        <TextField label="Min" id="agent_reply_min" type="number" className="form-control" name="agent_reply_min" value={this.state.agent_reply_min} onChange={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 10, max: 180 } }} variant="outlined" />
                                                        {/* <input type="number" min="10" max="180" placeholder="" className="w-100" name="agent_reply_min" value={this.state.agent_reply_min} onChange={this.handleChange} /> */}
                                                        <p className="error">{this.state.agent_reply_min_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">minutes then end chat for both visitor and agent.
                                                    <span className="info-icon">
                                                        &nbsp;<img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="Ongoing chat session will end for both visitor and agent. The visitor won't be able to continue it but will be able to start a new chat." />
                                                    </span>
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-baseline mb-5">
                                                    {/* <span className="main-checkbox-all">
                                                        <label className="containerCheckBox containerafterchecked">
                                                            <input type="checkbox" value="true" onChange={this.handleChange} checked={this.state.trigger_message_check} name="trigger_message_check" />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </span> */}
                                                    <div className="material-switch pull-right mt-3 mr-4">
                                                        <input id="pre_chat_form3" type="checkbox" value="true" onChange={this.handleChange} checked={this.state.trigger_message_check} name="trigger_message_check" />
                                                        <label htmlFor="pre_chat_form3" className="label-default" />
                                                    </div>
                                                    <h5 className="card-title mb-0">If agent doesn't reply to incoming chat for</h5>
                                                    <div className="input-group material-input mx-4 w-110">
                                                        <TextField label="Sec" id="trigger_message_time" type="number" className="form-control" name="trigger_message_time" value={this.state.trigger_message_time} onChange={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 30 } }} variant="outlined" />
                                                        {/* <input type="number" placeholder="" min="30" className="w-100" name="trigger_message_time" value={this.state.trigger_message_time} onChange={this.handleChange} /> */}
                                                        <p className="error">{this.state.trigger_message_time_error}</p>
                                                    </div>
                                                    {/* <h5 className="card-title mb-0">seconds then send trigger message </h5>
                                                    <h5 className="card-title mb-0">for</h5>
                                                    <div className="wrapperInfo mx-4">
                                                        <select name="trigger_message_repeat" className="customArrow" value={this.state.trigger_message_repeat} onChange={this.handleChange} style={{"maxWidth": "100px", "minWidth": "100px"}}>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </select>
                                                        <p className="error">{this.state.trigger_message_repeat_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">Times after every</h5> 
                                                    <div className="wrapperInfo mx-4">
                                                        <input type="number" min="1" placeholder="" className="w-100" name="trigger_message_interval" value={this.state.trigger_message_interval} onChange={this.handleChange} />
                                                        <p className="error">{this.state.trigger_message_interval_error}</p>
                                                    </div>*/}
                                                    <h5 className="card-title mb-0">seconds
                                                    <span className="info-icon">
                                                        &nbsp;&nbsp;<img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="Trigger messages will be sent and once all triggers have elapsed the incoming chat will end for both visitor and agent. The visitor won't be able to continue it but will be able to start a new chat." />
                                                    </span>
                                                    </h5>  
                                                </div>
                                                <div className="settings-feld form-feilds mb-5 px-8">
                                                    <div className="input-group material-input w-50">
                                                        <TextField className="form-control mb-3 mt-3" rows={4} id="trigger_message" label="Send Message" variant="outlined" value={this.state.trigger_message} name="trigger_message" onChange={this.handleChange}  multiline />
                                                        {/* <h5 className="card-title mb-0">Send Message</h5>
                                                        <textarea className="w-200" rows="4" name="trigger_message" value={this.state.trigger_message} onChange={this.handleChange} style={{width:"500px",fontFamily: 'HelveticaNeueLight', fontSize: '14px', resize:'none'}}></textarea> */}
                                                        <p className="error">{this.state.trigger_message_error}</p>
                                                    </div>
                                                </div>
                                                <div className="settings-feld form-feilds k-flex align-items-baseline mb-5 px-8">
                                                    <h5 className="card-title mb-0">for</h5>
                                                    <div className="input-group material-select mx-4 w-110">
                                                        <FormControl variant="outlined" className="material-outlined bg-white">
                                                            <InputLabel id="trigger_message_repeat-label">Times</InputLabel>
                                                            <Select MenuProps={{ disableScrollLock: true}} labelId="trigger_message_repeat-label" id="trigger_message_repeat" name="trigger_message_repeat" value={this.state.trigger_message_repeat?this.state.trigger_message_repeat:""} onChange={this.handleChange} label="Times">
                                                                <MenuItem value="1">1</MenuItem>
                                                                <MenuItem value="2">2</MenuItem>
                                                                <MenuItem value="3">3</MenuItem>
                                                                <MenuItem value="4">4</MenuItem>
                                                                <MenuItem value="5">5</MenuItem>
                                                            </Select>
                                                        </FormControl>

                                                        {/* <select name="trigger_message_repeat" className="customArrow" value={this.state.trigger_message_repeat} onChange={this.handleChange} style={{"maxWidth": "100px", "minWidth": "100px"}}>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </select> */}
                                                        <p className="error">{this.state.trigger_message_repeat_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">Times after every</h5> 
                                                    <div className="input-group material-input mx-4 w-110">
                                                        <TextField label="Sec" id="trigger_message_interval" type="number" className="form-control" name="trigger_message_interval" value={this.state.trigger_message_interval} onChange={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 30 } }} variant="outlined" />
                                                        {/* <input type="number" min="30" placeholder="" className="w-100" name="trigger_message_interval" value={this.state.trigger_message_interval} onChange={this.handleChange} /> */}
                                                        <p className="error">{this.state.trigger_message_interval_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">seconds</h5> 
                                                </div>
                                                <div className="settings-feld form-feilds mb-0 px-8">
                                                    <h5 className="card-title mb-0">and change chat's status to missed after last trigger has been sent. </h5>
                                                    <h5 className="card-title mb-0">and send message</h5>
                                                    <div className="input-group material-input w-50">
                                                        <TextField className="form-control mb-3 mt-3" rows={4} id="outlined-multiline" label="" variant="outlined" value={this.state.trigger_message_end} name="trigger_message_end" onChange={this.handleChange}  multiline />
                                                        {/* <textarea className="w-200" rows="4" name="trigger_message_end" value={this.state.trigger_message_end} onChange={this.handleChange} style={{width:"500px", fontFamily: 'HelveticaNeueLight', fontSize: '14px' , resize:'none'}}></textarea> */}
                                                        <p className="error">{this.state.trigger_message_end_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds mb-0">
                                                    <h5 className="card-title mb-0">and change chat's status to missed after last trigger has been sent. and send message</h5>
                                                    <div className="wrapperInfo">
                                                        <textarea className="w-200" name="trigger_message_end" value={this.state.trigger_message_end} onChange={this.handleChange} style={{width:"300px",resize:"none"}}></textarea>
                                                    <p className="error">{this.state.trigger_message_end_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds mb-0">
                                                    <div className="wrapperInfo">
                                                        <h5 className="card-title mb-0">Send Message</h5>
                                                        <textarea className="w-200" name="trigger_message" value={this.state.trigger_message} onChange={this.handleChange} style={{width:"300px",resize:"none"}}></textarea>
                                                            <p className="error">{this.state.trigger_message_error}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-0">
                                                    <h5 className="card-title mb-0">for</h5>
                                                    <div className="wrapperInfo mx-4">
                                                        <select name="trigger_message_repeat" className="customArrow" value={this.state.trigger_message_repeat} onChange={this.handleChange} style={{"maxWidth": "100px", "minWidth": "100px"}}>
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                        </select>
                                                        <p className="error">{this.state.trigger_message_repeat_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">Times after every</h5> 
                                                    <div className="wrapperInfo mx-4">
                                                        <input type="number" min="1" placeholder="" className="w-100" name="trigger_message_interval" value={this.state.trigger_message_interval} onChange={this.handleChange} />
                                                        <p className="error">{this.state.trigger_message_interval_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">seconds</h5> 
                                                </div>
                                            </div> */}
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-baseline mb-0 cc">
                                                    {/* <span className="main-checkbox-all">
                                                        <label className="containerCheckBox containerafterchecked">
                                                            <input type="checkbox" name="agent_inactive_check" value="true" checked={this.state.agent_inactive_check} onChange={this.handleChange} />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    </span> */}
                                                    <div className="material-switch pull-right mt-3 mr-4">
                                                        <input id="pre_chat_form4"  type="checkbox" name="agent_inactive_check" value="true" checked={this.state.agent_inactive_check} onChange={this.handleChange} />
                                                        <label htmlFor="pre_chat_form4" className="label-default" />
                                                    </div>
                                                    <h5 className="card-title mb-0">If agent remains inactive for</h5>
                                                    <div className="input-group material-input mx-4 w-110">
                                                        <TextField label="Min" id="agent_inactive_min" type="number" className="form-control" name="agent_inactive_min" value={this.state.agent_inactive_min} onChange={this.handleChange} InputLabelProps={{ shrink: true }} InputProps={{ inputProps: { min: 10 } }} variant="outlined" />
                                                        {/* <input type="number" min="10" placeholder="" className="w-100" name="agent_inactive_min" value={this.state.agent_inactive_min} onChange={this.handleChange} /> */}
                                                        <p className="error">{this.state.agent_inactive_min_error}</p>
                                                    </div>
                                                    <h5 className="card-title mb-0">minutes, then status will be Offline</h5>  
                                                </div>
                                                {/* <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                    <h6 className="card-title mb-0">Select status</h6>
                                                    <div className="wrapperInfo mx-4">
                                                        <select className="customArrow" name="agent_inactive_status" onChange={this.handleChange} value={this.state.agent_inactive_status} style={{"maxWidth": "100px", "minWidth": "100px"}}>
                                                            <option value="0">Offline</option>
                                                            <option value="2">Online</option>
                                                        </select>
                                                    </div>
                                                    <span className="info-icon">
                                                        <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="The status of agent will change to the selected status if the agent remains inactive for the mentioned time period." />
                                                    </span>
                                                </div> */}
                                            </div>
                                            <div className="chat-feilds py-6 px-8 btn-chat-feilds px-5 setting-footer-fixed bbb">
                                                <div className="text-center k-flex align-items-center">
                                                    <button className="btnBlueSettings mr-2">Save changes</button>
                                                    <button className="btnBorderSettings mr-2" onClick={this.cancelForm}>Cancel</button>
                                                    <button className="btnRestore ml-auto" onClick={this.restoreDefault}>Restore Default</button>
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    
                </div>
            </div> :<PopUp />}
        </>
        )
    }

}

export default IdleTime;