import React from 'react';
import LeftPanel from './views/LeftPanel';
import AddRoles from './AddRoles';
import EditRoles from './EditRole'
import DeleteRole from './DeleteRole';
// import db from "../../config/firebase";
import { getOwnerSnapShot } from "../../services/payment";
/* import { getDateInUTC } from "../livechat/components/Comman";
import moment from "moment-timezone"; */
import { activityLog } from "../../services/service";
import TextField from '@material-ui/core/TextField';
import firebaseServices from '../../firebase';

class Roles extends React.Component {
    
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            showAddRoles:false,
            roles:[],
            searchbox:'',
            editRoleId:'',
            showEditRoles:false,
            ownerId:'',
            loader:true,
            companyRoles:[],
            showDeleteRole:'',
            delRoleid:'',
            delagentCount:0,
            dRoleSnapshot:'',
            roleSnapShot:'',
            showAddRoleButton: false,
            allAgents : []
        }
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.value
        })
    }

    changeSearch = (e) => {
        this.setState({
            loader:true
        })
        // let roles=[];
        // let promise1 = new Promise((resolve,reject) => {
        //     var query=firebaseServices.db.collection('agent-roles');
        //     resolve(roles);
        // })

        let companyquery=firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles');
        companyquery.get().then((docs) => {
            let i=0;
            let newRoles=[];
            if(!docs.empty) {
                let roles = [];
                docs.forEach((doc)=>{
                    if (new RegExp(this.state.searchbox, "gi").test(doc.data().name)) {
                        roles[i] = doc.data();
                        roles[i]['id']=doc.id;
                        i++;
                    }
                });
                let allAgents = [...this.state.allAgents];
                newRoles = roles.map((role) => {
                    let agentCount = allAgents.filter((agent) => agent.role === role.id);
                    role.agentCount = agentCount.length;
                    return role;
                })
                console.log('newRoles >>>', newRoles);
            }
            this.setState({
                companyRoles:newRoles,
                loader:false
            })
        });
        
        // var promise2 = new Promise((Res,Rej) => {
        //     var companyquery=firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles');
        //     companyquery.get().then((docs) => {
        //         let i=0;
        //         let roles=[];
        //         docs.forEach((doc)=>{
        //             if (new RegExp(this.state.searchbox, "gi").test(doc.data().name)) {
        //                 roles[i] = doc.data();
        //                 roles[i]['id']=doc.id;
        //                 i++;
        //             }
        //         });
        //         Res(roles);
        //     });
        // })

        // Promise.all([promise1,promise2]).then((results) => {
        //     if(results[0].length===0 && results[1].length===0) {
        //         this.setState({
        //             loader:false
        //         })
        //     }
            
        //     if(results[0].length===0) {
        //         this.setState({
        //             roles:[]
        //         })
        //     } else {
        //         var pRoles = [];
        //         var i = 0;
        //         results[0].forEach((role) => {
        //             pRoles[i] = role;
        //             let agentCount=0;
        //             var m = i;
        //             firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where('deletetime','==',0).where("role","==",role.id).get().then((data) => {
        //                 let count=0;
        //                 data.forEach((dat) => {
        //                     count++;
        //                 })
        //                 agentCount=count;
        //                 pRoles[m]['agentCount']=agentCount;
        //                 this.setState({
        //                     roles:pRoles,
        //                     loader:false
        //                 })
        //             })
        //             i++;
        //         })
        //     }

        //     if(results[1].length===0) {
        //         this.setState({
        //             companyRoles:[]
        //         })
        //     } else {
        //         var pcRoles = [];
        //         var k = 0;
        //         results[1].forEach((role) => {
        //             pcRoles[k] = role;
        //             let agentCount=0;
        //             var n = k;
        //             firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where('deletetime','==',0).where("role","==",role.id).get().then((data) => {
        //                 let count=0;
        //                 data.forEach((dat) => {
        //                     count++;
        //                 })
        //                 agentCount=count;
        //                 pcRoles[n]['agentCount']=agentCount;
        //                 this.setState({
        //                     companyRoles:pcRoles,
        //                     loader:false
        //                 })
        //             })
        //             k++;
        //         })
        //     }
        // })
    }

    showRoles = () => {
        this.props.history.push("/"+this.agent.ownerIdentifire+'/add-roles');
    }

    sendStatus = (status) => {
        this.getRolesList(this.state.ownerId);
        this.setState({
            showDeleteRole:status,
            delRoleid:''
        })
    }

    getEditRole = (status) => {
        this.getRolesList(this.state.ownerId);
        this.setState({
            showEditRoles:status
        })
    }

    sendDeleteStatus = (status) => {
        this.getRolesList(this.state.ownerId);
        this.setState({
            showDeleteRole:status,
            delRoleid:''
        })
    }


    componentDidMount() {
        const userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId:userDetails.ownerId
        })
        this.getRolesList(userDetails.ownerId);
        this.checkSubscriptionList();
        activityLog("Opened role page");
    }

    checkSubscriptionList = () => {
        if(this.agent.ownerId) {
            getOwnerSnapShot(this.agent.ownerId, async (results) => {
                if(results.status===200) {
                    let _data = results.data;
                    this.setState({
                        showAddRoleButton : _data.paymentStatus
                    })
                } else {
                    this.setState({
                        showAddRoleButton:false
                    })
                }
            })
        }
    }
    
    getRolesList = (ownerId) => {

        let promise1 = new Promise((res1, rej1) => {
            firebaseServices.db.collection('users').doc(ownerId).collection('roles').orderBy('addedon').get().then((docs) => {
                let roles = [];
                let i=0;
                if(!docs.empty) {
                    docs.forEach((doc) => {
                        roles[i] = doc.data();
                        roles[i]['id'] = doc.id;
                        i++;
                    })
                }
                res1(roles);
            }).catch((err) => {
                console.log('error at 202', err);
                res1([]);
            })
        })

        let promise2 = new Promise((res2,rej2) => {
            firebaseServices.db.collection('users').doc(ownerId).collection('agents').where('deletetime','==',0).get().then((data) => {
                let agents = [];
                let k=0;
                if(!data.empty) {
                    data.forEach((agent) => {
                        agents[k] = agent.data();
                        agents[k]['id'] = agent.id;
                        k++;
                    })
                }
                res2(agents);
            }).catch((err) => {
                res2([]);
            })
        })

        Promise.all([promise1,promise2]).then((results) => {
            const roles = results[0];
            const agents = results[1];
            let allRoles = roles.map((role) => {
                let agentCount = agents.filter((agent) => agent.role === role.id);
                role.agentCount = agentCount.length;
                return role;
            })
            this.setState({
                allAgents : agents,
                companyRoles:allRoles,
                loader:false
            })
        })
        
        // this.state.roleSnapShot = firebaseServices.db.collection('users').doc(ownerId).collection('roles').orderBy('addedon').get().then((docs) => {
        //     if(!docs.empty) {
        //         var i=0;
        //         var roles=[];
        //         console.log(docs.size);
        //         docs.forEach((doc)=>{
        //             roles[i] = doc.data();
        //             roles[i]['id']=doc.id;
        //             let agentCount=0;
        //             var k =i;
        //             firebaseServices.db.collection('users').doc(ownerId).collection('agents').where('deletetime','==',0).where("role","==",roles[i].id).get().then((data) => {
        //                 let count=0;
        //                 data.forEach((dat) => {
        //                     count++;
        //                 })
        //                 agentCount=count;
        //                 roles[k]['agentCount']=agentCount;
        //                 this.setState({
        //                     companyRoles:roles,
        //                     loader:false
        //                 })
        //             })
        //             i++;
        //         });
        //         // this.state.roleSnapShot();
        //     }
        // });
    }

    deleteData = (depId,agentCount) => {
        this.setState({
            showDeleteRole:true,
            delRoleid:depId,
            delagentCount:agentCount
        })
    }

    editRole = (roleId) => {
        this.props.history.push('/'+this.agent.ownerIdentifire+'/roles/'+roleId);
    }

    searchInput = (e) => {
        if(e.which===13) {
            this.changeSearch();
        }
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }
    
    render() {

        var roleEmpty="";
        if(this.state.roles.length === 0 && this.state.companyRoles.length === 0) {
            roleEmpty=<tr><td colSpan="4"><p className="small text-screen1">No Role are present in your account</p></td></tr>
        }

        var roleList = this.state.roles.map((value, index) => {
            let deleteButton = (value.name!=='Admin' && value.name!=='Owner' && value.name!=='Agent') ? <a className="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}} onClick={() => this.deleteData(value.id)}>
            Delete
        </a> : '';
            return <tr key={index}>
            <td><p className="small text-screen1">{value.name}</p></td>
            <td><p className="small text-screen1">{value.description}</p></td>
            <td><p className="small text-screen1">{value.agentCount}</p></td>
            <td>
            </td>
        </tr> 
        })
        var ownerRolesList = this.state.companyRoles.map((value,index) => {
            return <tr key={index}>
            <td><p className="small text-screen1">{value.name}</p></td>
            <td><p className="small text-screen1">{value.description}</p></td>
            <td><p className="small text-screen1">{value.agentCount}</p></td>
            <td>
                {value.editable!==false ? (<div className="nav-item list-inline-item d-xl-block">
                    <a className="text-muted px-2" style={{cursor:'pointer'}} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src={ require('../../assets/img/icon/table-more-vertical.svg') } />
                    </a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{cursor:'pointer'}} onClick={()=>this.editRole(value.id)} >
                            Edit
                        </a>
                        <a className="dropdown-item d-flex align-items-center" style={{cursor:'pointer'}} onClick={() => this.deleteData(value.id,value.agentCount)}>
                            Delete
                        </a>                
                    </div>
                </div>) : (null) }
            </td>
        </tr> 
        })
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">User Management</h3>
                <div className="mobile-menu ml-auto" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
            <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                        <h3 className="heding-text">Roles</h3>
                    </div>
                    <div className="body-section bg-white px-5 py-5 mobile-tranparent">
                        <div className="agent-section" id="resposnive-table-class">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table-header table-header row mb-4 user-table-main-header">
                                        <div className="input-group pr-0 user-table-live search-bar material-input mb-5">
                                            <TextField className="form-control" id="outlined-basic" label="Search" variant="outlined" name="searchbox" value={this.state.searchbox} onChange={this.handleChange} onKeyPress={this.searchInput} />
                                            {/* <input type="text" className="form-control form-control-lg" placeholder="Search" name="searchbox" onKeyPress={this.searchInput} onChange={this.handleChange} aria-label="Search for messages or users..." /> */}
                                            <div className="input-group-append">
                                                <button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" onClick={this.changeSearch} type="submit">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
                                                        <path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="fltr-view">
                                            <div className="aside-agent k-flex align-items-center justify-content-end">
                                                <div className="aside-agent usr-mm-btn">
                                                    {this.state.showAddRoleButton ? (<button className="btnBlueSettings add-agent-clik" onClick={this.showRoles}>+ <span>Add Role</span></button>) : (null)}
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                </div> 
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="agent-table mobile-agent-table mobile-tranparent">
                                        <table className="table table-borderless mb-0 departmentUsertable mob-tbl-view liveCgatUserTable agentslistTbl">
                                            <thead className="bg-darksecound white-text table-head-border-radius border-radius-table">
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Agents</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody className="outline-1-black border-radius-table">
                                                {roleList}
                                                {ownerRolesList}
                                                {roleEmpty}
                                            </tbody>
                                        </table>
                                    </div> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AddRoles sendRoleStatus={this.state.showAddRoles} getRoleStatus={this.sendStatus} />
            <EditRoles editId={this.state.editRoleId} sendRoleStatus={this.state.showEditRoles} getEditRoleStatus={this.getEditRole} />
            <DeleteRole roleId={this.state.delRoleid} sendRoleStatus={this.state.showDeleteRole} getDeleteRoleStatus={this.sendDeleteStatus} delagentCount={this.state.delagentCount} />
            </>
        )
    }
}

export default Roles;