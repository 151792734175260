import React from 'react';
// import db from "../../config/firebase";
import {toast} from "react-toastify";
import firebaseServices from '../../firebase';

class AddRole extends React.Component {
    constructor(props) {
        super();
        this.state = {
            showAddRoles:'',
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[],
            agents:[],
            ownerId:'',
            loader:false
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showAddRoles:this.props.sendRoleStatus,
            ownerId:userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('agents').where('deletetime','==',0).get().then((docs) => {
            var i=0;
            var agents=[];
            docs.forEach((doc)=>{
                agents[i] = doc.data();
                agents[i]['id']=doc.id;
                i++;
            });
            this.setState({
                agents:agents
            })
        });
    }

    handleChange = (e) => {
        let target = e.target;
        let values;
        if(target.name==="agentList") {
            values = [].filter.call(target.options, o => o.selected).map(o => o.value);
        } else {
            values = target.value;
        }
        this.setState({
            [target.name]:values
        })
    }

    hideRole = (e) => {
        this.props.getRoleStatus(false);
        this.setState({
            name:'',
            name_error:'',
            description:'',
            description_error:'',
            agentList:[]
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validation();
        if(validate===true){
            let roleName = this.state.name.trim();
            firebaseServices.db.collection('agent-roles').where('name','==',roleName).get().then((data) => {
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').where('name','==',roleName).get().then((agentRoles) => {
                    if(data.empty===true && agentRoles.empty===true) {
                        this.setState({
                            loader:true
                        })
                        let addedon = Date.now();
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').add({addedon:addedon,name:roleName,description:this.state.description}).then((ref)=> {
                            if(this.state.agentList.length>0) {
                                this.updateRoles(ref.id);
                            }
                            this.props.getRoleStatus(false);
                            toast.success('Role Has been Added Sucessfully!');
                            this.setState({
                                name:'',
                                name_error:'',
                                description:'',
                                description_error:'',
                                agentList:[],
                                loader:false
                            })
                        }).catch((err) => {
                            console.log(err);
                        })
                    } else {
                        this.setState({
                            name_error:'This Role has been already added'
                        })
                    }
                })
            })
            return false;
            
        }
    }

    updateRoles = (roleId) => {
        this.state.agentList.forEach((agentId) => {
            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(agentId).update({role:roleId}).then((ref) => {
                console.log(ref);
            }).catch((err) => {
                console.log(err);
            })
        })
    }

    validation() {
        let name_error='';
        let description_error='';
        if(this.state.name===''){
            name_error = 'error';
            this.setState({
                name_error:'First Name should not be empty'
            })
        } else {
            name_error = '';
            this.setState({
                name_error:''
            })
        }
        if(this.state.description===''){
            description_error='error';
            this.setState({
                description_error:'Alias should not be empty'
            })
        } else {
            description_error='';
            this.setState({
                description_error:''
            })
        }

        if(name_error!=='' || description_error!=='') {
            return false;
        } else {
            return true;
        }

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showAddRoles !== props.sendRoleStatus) {
          return {
            showAddRoles: props.sendRoleStatus
          }
        }
        return null
    }

    closePanel = (e) => {
        e.preventDefault();
        this.hideRole();
    }

    render() {
        let agents = this.state.agents.map((value,index) => {
            return <option key={index} value={value.id}>{value.name} ({value.alias})</option>
        })
        return (
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showAddRoles ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Add Role</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideRole}>
                                        <img src={ require('../../assets/img/icon/right-panel-crose.svg') } alt="" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                            <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Name</label>
                                        <input type="text" placeholder="Enter Role*" className="w-100" name="name" onChange={this.handleChange} value={this.state.name} />
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Description</label>
                                        <textarea type="text" placeholder="Description" className="w-100" name="description"  onChange={this.handleChange} value={this.state.description}></textarea>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Select Agent</label>
                                        <select className="customArrow" name="agentList" value={this.state.agentList} onChange={this.handleChange} multiple>
                                            {agents}
                                        </select>
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlue">Add</button>
                                        <button className="btnWhite" onClick={this.closePanel}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

export default AddRole;