import React, { PureComponent ,lazy,Suspense} from 'react';
import LeftSection from './view/leftSection';
import RightSection from './view/rightSection';
import Midsection from './view/midle';
// import PastChat from './view/pastChat';
import '../../assets/css/flag-icon.min.scss';
import '../../assets/css/flag-round-icon.min.scss';
import { ChatProvider } from './components/ChatContext';
import globalData from './components/globaldata.json';
// import db from "../../config/firebase";
import { isMobileDevice, getDateInUTC } from './components/Comman';
import { getPaymentStatus } from '../../services/payment';
import { activityLog } from '../../services/service';
import ChatContext from './components/ChatContext';
// import ZoomConnect from './view/zoomConnect';
import Reactselect from 'react-select';
import { MAIL_FUNCTION_URL, GATEWAY_URL, FUNCTION_API_KEY } from '../../config/siteConfig';
import firebaseServices from '../../firebase';
const BanUser = lazy(() =>import('./view/banUser'));
const ZoomConnect = lazy(() =>import('./view/zoomConnect'));
const PastChat = lazy(() =>import('./view/pastChat'));
const timezones = require('../../localization/timezones.json');

class Livechat extends PureComponent {

  static contextType = ChatContext;
  ntwIntervalID = 0;
  dateIntervalID = 0;
  ownerSnapShot = null;
  timezoneOptions = [{ value: "", label: "Select Timezone" }];

  constructor(props, context) {
    super(props);

    timezones.map((values) => {
      this.timezoneOptions.push({
        value: values, label: values
      });
    });

    // Add a function to update global data value
    globalData.updateGlobalData = this.updateGlobalData;
    globalData.goneOffline = false;
    globalData.reloadApp = false;
    globalData.isMobileDevice = isMobileDevice();
    globalData.paymentDetails = {};
    globalData.websiteTitle = "Appypie Live Chat";
    globalData.setTimezone = false;
    globalData.timezone_error= "";
    this.state = Object.assign({}, globalData);
    window.onresize = () => {

      var w = document.documentElement.clientWidth;
      if(!globalData.isMobileDevice){
        if (w >= 1200) {
          try {
            document.getElementById('SideBarIO').classList.remove('IOLP');
            document.getElementById('new-Main-Chat').classList.remove('VRP');
          } catch (err) { }
        } else {
          try {
            document.getElementById('SideBarIO').classList.add('IOLP');
            document.getElementById('new-Main-Chat').classList.add('VRP');
          } catch (err) { }
        }
      }
      

      var windowHeight = document.documentElement.clientHeight;
      try {
        var heightToDecrease = 249; /////   217 = Search Bar + headings Pre, Normal visitors
        var right_premium_ul = document.getElementById('right_premium_ul').offsetHeight;
        //var right_normal_ul = document.getElementById('right_normal_ul').offsetHeight;

        var remainHeight = eval(windowHeight) - heightToDecrease;
        var totminusprem = eval(remainHeight) - eval(right_premium_ul) - 20;
        totminusprem = totminusprem + "px";
        //console.log("windowHeight : " + windowHeight + " remainHeight : " + remainHeight + " totminusprem : " + totminusprem);
        document.getElementById('right_normal_ul').style.height = totminusprem;
        //console.log("height : " + windowHeight + " , right_premium_ul : " + right_premium_ul + ", right_normal_ul : " + right_normal_ul + ", right_normal_client_ul : " +  document.getElementById('right_normal_ul').clientHeight);
      } catch (error) { }

    }

    this.ntwIntervalID = setInterval(async () => {
      var isOnline = await this.checkNetwork();
      console.log("--------------- isOnline >>>>> " + isOnline);
      if (isOnline === "error") {
        /// Do nothing
      } else {
        if (!isOnline) {
          var confirmOnline = await this.checkNetworkByUrl();
          if (!confirmOnline) {
            if (!this.state.goneOffline) {
              this.setState({ goneOffline: true, reloadApp: false });
            }
          } else {
            if (this.state.goneOffline) {
              this.setState({ goneOffline: false, reloadApp: true });
            }
          }
        } else {
          if (!isOnline) {
            if (!this.state.goneOffline) {
              this.setState({ goneOffline: true, reloadApp: false });
            }
          } else {
            if (this.state.goneOffline) {
              this.setState({ goneOffline: false, reloadApp: true });
            }
          }
        }
      }
    }, 5000);

    this.dateIntervalID = setInterval(async () => {
        await getDateInUTC();
    }, 300000); /// Update agentTimeZone in every 5  Minutes
    
  }

  componentDidMount() {
    var agent2 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    if(Object.keys(agent2).length > 0 && agent2.roleType && agent2.roleType === "Owner" && (!agent2.hasOwnProperty("org_timezone") || agent2.org_timezone === "")) {
      this.setState({ setTimezone : true, selectedTimezone: { value: agent2.org_timezone?agent2.org_timezone:"", label: agent2.org_timezone?agent2.org_timezone:"Select Timezone" }, timezone: agent2.org_timezone?agent2.org_timezone:"" })
    }

    this.showHideWidget(agent2.ownerId);

    /* Added By AG Start */
    
    this.ownerSnapShot = firebaseServices.db.collection('users').doc(agent2.ownerId).onSnapshot(async (docs) => {
      if(docs.exists) {
          let agent33 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
          let _data = docs.data();
          /* update tokens if added */
          if(!agent2.tdm_tokens && _data.tdm_tokens && Object.keys(_data.tdm_tokens).length > 0) {
            agent2.tdm_tokens = _data.tdm_tokens;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          if(!agent2.fb_page_token && _data.fb_page_token && Object.keys(_data.fb_page_token).length > 0) {
            agent2.fb_page_token = _data.fb_page_token;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          let updateCannedRes = false;
          if(agent2.canned_response !== _data.canned_response) {
            updateCannedRes = true;
          }

          if(!agent2.smart_compose && _data.smart_compose) {
            agent2.smart_compose = _data.smart_compose;
            if(updateCannedRes) {
              agent2.canned_response = _data.canned_response ? _data.canned_response : 0;
            }
            localStorage.setItem('agent', JSON.stringify(agent2));
          } else if(agent2.smart_compose && !_data.smart_compose) {
              try {
                delete agent2.smart_compose;
                if(updateCannedRes) {
                  agent2.canned_response = _data.canned_response ? _data.canned_response : 0;
                }
                localStorage.setItem('agent', JSON.stringify(agent2));
              } catch(errrrr){}
          } else if(agent2.smart_compose && _data.smart_compose && agent2.smart_compose !== _data.smart_compose) {
              agent2.smart_compose = _data.smart_compose;
              if(updateCannedRes) {
                agent2.canned_response = _data.canned_response ? _data.canned_response : 0;
              }
              localStorage.setItem('agent', JSON.stringify(agent2));
          } else if(updateCannedRes) {
            agent2.canned_response = _data.canned_response ? _data.canned_response : 0;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          if(agent33.zoom_status !== _data.zoom_status) {
            agent2.zoom_status = _data.zoom_status;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          if(agent33.instagrambusiness_status !== _data.instagrambusiness_status) {
            agent2.instagrambusiness_status = _data.instagrambusiness_status;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          if(agent33.facebookmessenger_status !== _data.facebookmessenger_status) {
            agent2.facebookmessenger_status = _data.facebookmessenger_status;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          if(agent33.whatsappbusinessmessenger_status !== _data.whatsappbusinessmessenger_status) {
            agent2.whatsappbusinessmessenger_status = _data.whatsappbusinessmessenger_status;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }
          
          if(_data.insta_pages_token && Object.keys(_data.insta_pages_token).length > 0) {
            agent2.insta_pages_token = _data.insta_pages_token;
            localStorage.setItem('agent', JSON.stringify(agent2));
          }

          /* update tokens if added */
          let paymentStatus = await getPaymentStatus(_data);
          _data.paymentStatus = paymentStatus;
          this.setState({
            paymentDetails : _data,
            zoom_status : _data.zoom_status
          });
      }
    });

    /* Added By AG End */

    /* Commented By AG Start */

    /* getOwnerSnapShot(agent2.ownerId, async(results) => {
      let _data = {}
      if(results.status === 200) {
        _data = results.data;
      } 
      this.setState({
        paymentDetails : _data
      })
    }); */

    /* Commented By AG End */

    this.incomingGuide(agent2);
    this.liveChatGuide(agent2);
    this.visitorsGuide(agent2);

    try {
      document.getElementById('maintoproutediv').classList.remove('layout-not-liveChat');
      document.getElementById('maintoproutediv').classList.add('layout');
    }catch(errr){}

    try {
      document.getElementById('mainPhoneCall').style.display = "none";
    } catch(errr){}

    activityLog('Opened livechat section');
  }

  liveChatGuide = (agent2) => {
    firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("livechat").get().then((getRsult) => {
      if(getRsult.exists) {
        let _dataResult = getRsult.data();
        var arrayToSet = [];
        var Obj1 = {
          key: "livechat_guide",
          value: _dataResult.status ? _dataResult.status : false
        }

        var Obj2 = {
          key: "livechat_step",
          value: _dataResult.step ? _dataResult.step : 0
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);

        this.updateGlobalData("array", arrayToSet);

      }
    })
  }

  incomingGuide = (agent2) => {
    firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("incoming").get().then((getRsult) => {
      if(getRsult.exists) {
        let _dataResult = getRsult.data();
        var arrayToSet = [];
        var Obj1 = {
          key: "incoming_guide",
          value: _dataResult.status ? _dataResult.status : false
        }

        var Obj2 = {
          key: "incoming_guide_step",
          value: _dataResult.step ? _dataResult.step : 0
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);

        this.updateGlobalData("array", arrayToSet);
      }
    })
  }

  visitorsGuide = (agent2) => {
    firebaseServices.db.collection("users").doc(agent2.ownerId).collection("onboarding_guide").doc("visitor").get().then((getRsult) => {
      if(getRsult.exists) {
        let _dataResult = getRsult.data();
        var arrayToSet = [];
        var Obj1 = {
          key: "visitors_guide",
          value: _dataResult.status ? _dataResult.status : false
        }

        var Obj2 = {
          key: "visitors_guide_step",
          value: _dataResult.step ? _dataResult.step : 0
        }

        arrayToSet.push(Obj1);
        arrayToSet.push(Obj2);

        this.updateGlobalData("array", arrayToSet);
      }
    })
  }

  // added by dharmesh to show and hide widget start here
  showHideWidget = (ownerId) => {
    firebaseServices.db.collection('users').doc(ownerId).get().then((docs) => {
      if(docs.exists) {
        let _data = docs.data();
        if(_data.widget_status === true) {
          try {
            document.getElementById("1234567890ANAS0987654321DHEERAJ09876543211234567890").style.display = "none";
          } catch (err) { }
        } else {
          try {
            document.getElementById("1234567890ANAS0987654321DHEERAJ09876543211234567890").style.display = "initial";
          } catch (err) { }
        }
      }
    })
  }
  // added by dharmesh to show and hide widget ends here

  checkNetwork = () => {
    return new Promise(function (resolve) {
      try {
        resolve(window.navigator.onLine);
      } catch(errr) {
        console.log("-------------  Error in window.navigator.onLine >>>>>>>>" + errr);
        resolve("error");
      }
    });
  }

  checkNetworkByUrl = () => {
    return new Promise(function (resolve) {
      var isOnline = function isOnline() {
        return resolve(true);
      };
      var isOffline = function isOffline() {
        return resolve(false);
      };
      var xhr = new XMLHttpRequest();
      xhr.onerror = isOffline;
      xhr.ontimeout = isOffline;
      xhr.onreadystatechange = function () {
        if (xhr.readyState === xhr.HEADERS_RECEIVED) {
          if (xhr.status) {
            isOnline();
          } else {
            isOffline();
          }
        }
      };
      //https://ipv4.icanhazip.com/
      xhr.open("HEAD", "https://chatbottest.appypie.com/");
      xhr.timeout = 4000;
      xhr.send();
    });
  }

  /////// Update global data function start ///////
  updateGlobalData = (action, value) => {
    switch (action) {
      case "array":
        var tempData = {};
        value.forEach(element => {
          tempData[element.key] = element.value;
        });
        this.setState(tempData);
        break;
      default:
        this.setState({ [action]: value });
    }
  }
  /////// Update global data function end ///////

  iolp = () => {
    try {
      document.getElementById('SideBarIO').classList.add('IOLP');
      document.getElementById('respMobiMenu').classList.remove('d-none');
    } catch (err) { }

  }

  vrp = () => {
    try {
      document.getElementById('SideBarIO').classList.remove('IOLP');
      document.getElementById('new-Main-Chat').classList.add('VRP');
      document.getElementById('new-Main-Chat').classList.remove('VRP2');
      document.getElementById('respMobiMenu').classList.remove('d-none');
    } catch (err) { }
  }

  showOnlineAgents = () => {
    try {
      document.getElementById('SideBarIO').classList.remove('IOLP');
      document.getElementById('new-Main-Chat').classList.add('VRP2');
      document.getElementById('new-Main-Chat').classList.remove('VRP3');
      document.getElementById('new-Main-Chat').classList.remove('VRP');
      document.getElementById('respMobiMenu').classList.remove('d-none');
    } catch (err) { }
    this.setState({ Mob_showOnlineAgents: true });
  }

  showPhoneDialer = () => {
    try {
      document.getElementById('SideBarIO').classList.remove('IOLP');
      document.getElementById('new-Main-Chat').classList.add('VRP3');
      document.getElementById('new-Main-Chat').classList.remove('VRP');
      document.getElementById('new-Main-Chat').classList.remove('VRP2');
      document.getElementById('respMobiMenu').classList.remove('d-none');
    } catch (err) { }
    this.setState({ Mob_showPhoneDialer : true });
  }

  /* Unmount all mounted data start */

  componentWillUnmount = () => {
    try {
      clearInterval(this.ntwIntervalID);
      clearInterval(this.dateIntervalID);
    } catch(errr){}

    try {
      this.ownerSnapShot();
    } catch(errr){}

    try {
      document.getElementById('maintoproutediv').classList.remove('layout');
      document.getElementById('maintoproutediv').classList.add('layout-not-liveChat');
      document.getElementById("1234567890ANAS0987654321DHEERAJ09876543211234567890").style.display = "initial";
    }catch(errr){}

  }

  /* Unmount all mounted data end */

  /* Update timezone for owner start */

  selectTimezone = (e) => {
    const agent2222 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    if (agent2222.org_timezone && agent2222.org_timezone !== "") {
      this.setState({ timezone_error: 'It can not change now.' });
      return false;
    }
    if (e.value === '') {
      this.setState({ timezone_error: 'Plese select a timezone' });
    } else {
      this.setState({ timezone_error: '' });
    }
    this.setState({
      timezone: e.value,
      selectedTimezone: e
    });
  }

  /**
     * Save data in table
  */
  saveTimezone = async () => {
    const { timezone } = this.state;
    if (!timezone || !timezone.trim()) {
      this.setState({ timezone_error: 'Plese select a timezone' });
      return false;
    }
    const agent2222 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
    if (!agent2222.org_timezone) {
      this.setState({ updateTimezone : true });
      firebaseServices.db.collection('users').doc(`${agent2222.ownerId}`).update({ org_timezone: timezone }).then(resss => {
        let todayDate = new Date();
        todayDate.setDate(todayDate.getDate() - 14);
        todayDate.setHours(0,0,0,0);
        let body = [`ownerId=${agent2222.ownerId}&from_date=${todayDate.getTime()}&org_timezone=${encodeURIComponent(timezone)}`];
        //fetch(MAIL_FUNCTION_URL + "/analytics/insertTimeZoneData", {
        fetch(GATEWAY_URL + "/insertTimeZoneData", {
          method: "post",
          body: body,
          headers: {
            "X-API-Key": FUNCTION_API_KEY,
            "Content-Type": "application/x-www-form-urlencoded",
            "Accept": "application/json",
            "Origin": window.location.origin
          }
        }).then(res => res.json())
          .then(response => { })
          .catch(e => { });
        agent2222.org_timezone = timezone;
        localStorage.setItem('agent', JSON.stringify(agent2222));
        this.setState({ updateTimezone: false, setTimezone : false });
      });
    }
  }

  closeTimezone = () => {
    this.setState({ setTimezone : false });
  }

  /* Update timezone for owner end */

  render() {
    return (
      <ChatProvider value={this.state}>
        <div className="liveChatHeadrMobil k-flex align-items-center" id="liveChatHeadrMobile">
          <h4 className="heding-text" onClick={this.iolp}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25.454" height="20.379" viewBox="0 0 30.454 25.379">
              <path id="arrow" d="M37.186,23.42H11.871l9.693-9.233a1.269,1.269,0,0,0-1.75-1.838L8.744,22.894a2.537,2.537,0,0,0,.022,3.611L19.814,37.028a1.269,1.269,0,1,0,1.75-1.837l-9.734-9.233H37.186a1.269,1.269,0,0,0,0-2.538Z" transform="translate(-8 -12)" />
            </svg>&nbsp;
                        Live Chat</h4>
          <div className="menu-rightPhone  ml-auto">
            <span className="nav-link text-muted px-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18"><g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)"><rect id="Rectangle_1688" data-name="Rectangle 1688" width="4" height="4" transform="translate(1378.075 219.72)" fill="#000"></rect><rect id="Rectangle_1689" data-name="Rectangle 1689" width="4" height="4" transform="translate(1378.075 226.72)" fill="#000"></rect><rect id="Rectangle_1690" data-name="Rectangle 1690" width="4" height="4" transform="translate(1378.075 233.72)" fill="#000"></rect></g></svg>
            </span>
            <div className="dropdown-menu">
              <p className="card-body-text mb-2" onClick={this.vrp}>Visitor </p>
              <p className="card-body-text mb-2" onClick={this.showOnlineAgents}>Online Agents</p>
              <p className="card-body-text" onClick={this.showPhoneDialer}>Show Dialer</p>
            </div>
          </div>
        </div>

        <div className="sidebar" id="SideBarIO">
          <div className="tab-content h-100" role="tablist">
            <div className="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
              <div className="d-flex flex-column h-100">
                <div className="overflow-hidden">
                  <LeftSection />
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div className="main main-visible" id="new-Main-Chat" data-mobile-height="">
          <div id="chat-1" className="chat dropzone-form-js">
            <Midsection />
            <RightSection props={this.props}/>
            <Suspense fallback={<div>Loading... </div>}> <PastChat /></Suspense>
            <Suspense fallback={<div>Loading... </div>}>
            <BanUser /></Suspense>
            <Suspense fallback={<div>Loading... </div>}> <ZoomConnect /></Suspense>
          </div>
        </div>

        {/* Timezone popup design */}
        {this.state.setTimezone && (<div className="trialPopup timezone-popup">
          <div className="trialPopupBody">
            <span className="closeBtn" onClick={this.closeTimezone}></span>
            <img src={require("../../assets/images/free_trial.svg")} />
            <h4 className="mb-3 mt-3">Set Default Timezone for your Location</h4>
            <p className="mb-3">This setting is your default Livechat resource location. It's where your
              Livechat data and your default Livechat Location wil be stored.
            </p>
            <p className="highlight">
              You can not change your default timezone after setting up now!
            </p>
            <div className="widget-message mt-5 mb-5 time_zone d-flex flex-wrap align-items-center">
              <h6 className="card-title mb-0 pb-2 mr-3">Please select your timezone  </h6>
              <div>
                <Reactselect name="timezone" options={this.timezoneOptions} onChange={this.selectTimezone} value={this.state.selectedTimezone} />
                {this.state.timezone_error && (<p className="error">{this.state.timezone_error}</p>)}
                <p className="error">{this.state.timezone_error}</p>
              </div>
            </div>
            {this.state.updateTimezone ? (<button className="btnBlueSettings">Updating...</button>) : (<button className="btnBlueSettings" onClick={this.saveTimezone}>Save</button>)}
          </div>
        </div>)}
      </ChatProvider>
    );
  }
}

export default React.memo(Livechat);