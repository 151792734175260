import React from 'react';
import EditProfile from './index';
// import db, { fb } from '../../config/firebase';
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD } from '../../config/siteConfig';
import { activityLog } from '../../services/service';
import firebaseServices from '../../firebase';
// const firebase = require("firebase");

class UpdateProfile extends React.Component {
    constructor(props) {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.run = true;
        this.state = {
            show_profile: false,
            showEditProfile: false,
            ownerId: '',
            agentId: '',
            status: '',
            name: '',
            profile_pic: '',
            alias: '',
            email: '',
            role: '',
            departments: '',
            chat_completed: '',
            response_time: '',
            chat_duration: '',
            satisfaction: '',
            negativeRating: '',
            closeSnapShot: '',
            loader: true,
            userConsent: false,
            chatbotId: ""
        }
    }

    getChatBotId = () => {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('chatbots').where('status', '==', 1).limit(1).get().then((docs) => {
            let chatbotId = "";
            if (!docs.empty) {
                docs.forEach((doc) => {
                    chatbotId = doc.id;
                })
            }
            this.setState({ chatbotId: chatbotId });
        });
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            ownerId: userDetails.ownerId,
            agentId: userDetails.agentId
        }, () => {
            this.getProfile();
        })
    }

    secondsToHms = (d) => {
        d = Number(d);
        var h = Math.floor(d / 3600);
        var m = Math.floor(d % 3600 / 60);
        var s = Math.floor(d % 3600 % 60);

        var hDisplay = h > 9 ? h : "0" + h;
        var mDisplay = m > 9 ? m : "0" + m;
        var sDisplay = s > 9 ? s : "0" + s;
        return hDisplay + ':' + mDisplay + ':' + sDisplay;
    }

    componentWillUnmount() {
        this.state.closeSnapShot();
    }

    componentDidUpdate() {
        if (this.props.showProfile === true && this.run === true) {
            this.run = false;
            this.getOwnerDetails();
            activityLog('Opened profile section');
        }
    }

    getOwnerDetails = () => {
        firebaseServices.db.collection('users').doc(this.state.ownerId).get().then((docs) => {
            if (docs.exists) {
                let _data = docs.data();
                this.setState({
                    userConsent: _data.consent === false ? false : true
                }, () => {
                    console.log(this.state.userConsent);
                });
            }
        })
    }


    getProfile = (e) => {
        this.state.closeSnapShot = firebaseServices.db.collection('users').doc(this.state.ownerId).collection("agents").doc(this.state.agentId).onSnapshot((docs) => {
            if (docs.exists) {
                let data = docs.data();
                let promise1 = new Promise((res, rej) => {
                    if (data.departments.length === 0 || data.departments.length === undefined) {
                        res('')
                    } else {
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('departments').where(firebaseServices.fb.firestore.FieldPath.documentId(), 'in', data.departments).get().then((depData) => {
                            let depArray = [];
                            if (depData.empty === false) {
                                depData.forEach((deps) => {
                                    depArray.push(deps.data().name);
                                })
                            }
                            res(depArray);
                        }).catch((err) => {
                            console.log(err);
                        });
                    }
                })
                // let promise2 = new Promise((Resolve,Reject) => {
                //     firebaseServices.db.collection('agent-roles').where(firebase.firestore.FieldPath.documentId(),'==',data.role).get().then((rolData) => {
                //         let rolDa='';
                //         if(rolData.empty===false) {
                //             rolData.forEach((deps) => {
                //                 rolDa = deps.data().name;
                //             })
                //         } 
                //         Resolve(rolDa);
                //     }).catch((err) => {
                //         console.log(err);
                //     });
                // })

                let promise3 = new Promise((res3, rej3) => {
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('roles').where(firebaseServices.fb.firestore.FieldPath.documentId(), '==', data.role).get().then((defRoles) => {
                        let defrol = '';
                        if (defRoles.empty === false) {
                            defRoles.forEach((defrole) => {
                                defrol = defrole.data().name;
                            })
                        }
                        res3(defrol);
                    }).catch((err) => {
                        console.log(err);
                    })
                })

                Promise.all([promise1, promise3]).then(res => {
                    let chatRateSuccess = 0;
                    let negativeRating = 0;
                    if (data.chat_satisfaction !== '' && data.chat_satisfaction !== undefined) {
                        let maxpoints = 5 * data.chat_ratings;
                        chatRateSuccess = Math.round((data.chat_satisfaction / maxpoints) * 100);
                        negativeRating = 100 - chatRateSuccess;
                    }
                    chatRateSuccess = isNaN(chatRateSuccess) ? 0 : chatRateSuccess;
                    negativeRating = isNaN(negativeRating) ? 0 : negativeRating;
                    let avgChatDuration = isNaN(data.avg_chat_duration) ? '00:00:00' : this.secondsToHms(Math.round(data.avg_chat_duration));
                    //let avgResponseTime = isNaN(data.avg_response_time) ? '00:00:00' : this.secondsToHms(Math.round(data.avg_response_time));
                    let avgResponseTime = isNaN(data.avg_first_response) ? '00:00:00' : this.secondsToHms(Math.round(data.avg_first_response));
                    let userDetailsLocal = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;

                    if (userDetailsLocal) {
                        let currentTime = new Date().getTime();
                        this.setState({
                            name: userDetailsLocal.first_name + ' ' + (userDetailsLocal.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetailsLocal.last_name : ''),
                            alias: userDetailsLocal.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? userDetailsLocal.alias : '',
                            email: userDetailsLocal.email,
                            agentProfilePic: userDetailsLocal.profile_img ? userDetailsLocal.profile_img + '?v=' + currentTime : ''
                        })
                    } else {
                        this.setState({
                            name: data.name,
                            alias: data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? data.alias : '',
                            email: data.email,
                            agentProfilePic: data.profile_img
                        })
                    }
                    let tmpRole = res[1];
                    this.setState({
                        status: data.online_status === 1 ? 'Online' : 'Offline',
                        role: tmpRole,
                        departments: res[0].toString(),
                        chat_completed: data.chat_completed === '' || data.chat_completed === undefined ? 0 : data.chat_completed,
                        chat_duration: avgChatDuration,
                        response_time: avgResponseTime,
                        satisfaction: chatRateSuccess,
                        negativeRating: negativeRating
                    }, () => {
                        if (tmpRole === "Owner" || tmpRole === "Admin") {
                            this.getChatBotId();
                            setTimeout(() => {
                                this.setState({ loader: false });
                            }, 1000);
                        } else {
                            this.setState({ loader: false });
                        }
                    })
                }).catch((err) => {
                    console.log('err>>>', err);
                });
                // userDet();
            }
        })
    }

    showEditProfile = (e) => {
        this.setState({
            showEditProfile: true
        })
    }

    hideEditprofile = (status) => {
        this.setState({
            showEditProfile: status
        })
    }

    hideShowProfile = (e) => {
        this.props.hideProfile(false);
    }

    static getDerivedStateFromProps(props, current_state) {
        if (props.showProfile === false) {
            return {
                showEditProfile: false
            }
        }
        return null
    }

    replaceImageWithText(e) {
        e.target.onError = null;
        e.target.outerHTML = (this.agent.agent_name.trim().split(" ")[0][0] + (this.agent.agent_name.trim().split(" ")[1] ? this.agent.agent_name.trim().split(" ")[1][0] : this.agent.agent_name.trim().split(" ")[0][1])).toUpperCase();
    }

    render() {
        let profile_img = '';
        if (this.state.agentProfilePic !== '' && this.state.agentProfilePic !== undefined) {
            profile_img = <img src={this.state.agentProfilePic} className="avatar-img" alt="" onError={(e) => this.replaceImageWithText(e)} />;
        } else {
            // let name = this.state.first_name + ' ' + this.state.last_name;
            let names = this.state.name.trim().split(" ");
            if (names.length > 1) {
                profile_img = (names[0].substring(0, 1) + names[1].substring(0, 1)).toUpperCase();
            } else {
                profile_img = (names[0].substring(0, 2)).toUpperCase();
            }
        }
        let agentStatus;
        agentStatus = this.props.agentStatus === 1 ? 'Online' : 'Offline'
        return (
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <div className="rightPanelSection profile-rightpanel b" style={{ display: this.props.showProfile ? 'block' : 'none' }}>
                    <div className="categoryRightPanel">
                        <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                            <div className="media text-center text-xl-left agent-profile-rightPanel k-flex align-items-center position-relative">
                                <div className="avatar avatar-sm d-none d-xl-inline-block mr-4">
                                    {/* <img src={require('../../assets/images/avatars/1.jpg')} className="avatar-img" alt="" /> */}
                                    <span className="user-profile-nav">{profile_img}</span>
                                </div>
                                <div className="media-body align-self-center text-truncate text-left">
                                    <h5 className="heding-text text-truncate mb-n2 white-text name-chat-header"><span>Status : </span>{agentStatus}</h5>
                                    <h5 className="heding-text text-truncate white-text name-chat-header"><span>Name : </span>{this.state.name}</h5>
                                    <p className="small card-body-text mb-n2 white-text name-chat-header">Alias: {this.state.alias}</p>
                                    <p className="small card-body-text white-text name-chat-header">Email: {this.state.email}</p>
                                    {this.agent.phone_number ? (<p className="small card-body-text white-text name-chat-header">Phone Number: {this.agent.phone_number}</p>) : (null)}
                                </div>
                                <span className="heder-icon-img icon-rightpanel-icon" onClick={this.showEditProfile} style={{ right: '30px' }}>
                                    <img alt="" src={require('../../assets/img/icon/edit.png')} style={{ height: '15px' }} />
                                </span>
                                <span className="heder-icon-img icon-rightpanel-icon" onClick={this.hideShowProfile}>
                                    <img alt="" src={require('../../assets/img/icon/right-panel-crose.svg')} />
                                </span>
                            </div>
                        </div>
                        <div className="bodyRightPanel scroll-4">
                            <div className="rightPanelMain">
                                <div className="agent-form py-5">
                                    <div className="form-feilds px-5 py-4">
                                        <div className="wrapperInfo">
                                            <p className="small card-body-text"><strong className="text-screen1">Role : </strong>{this.state.role}</p>
                                            <p className="small card-body-text"><strong className="text-screen1">Team : </strong>{this.state.departments}</p>
                                            {(this.state.role === "Owner" || this.state.role === "Admin") && this.state.chatbotId && (<><p className="small card-body-text"><strong className="text-screen1">Account Key : </strong>{this.agent.ownerId}</p>
                                                <p className="small card-body-text"><strong className="text-screen1">Bot Id : </strong>{this.state.chatbotId}</p></>)}
                                        </div>
                                    </div>
                                    {this.state.userConsent ? (<><div className="section-agent-profiles mb-2">
                                        <div className="form-feilds px-5 py-0 ">
                                            <h5 className="bg-lightgray header-title heding-text text-truncate mr-auto px-5 py-4">Chat Status</h5>
                                        </div>
                                        <div className="form-feilds px-5 py-4">
                                            <div className="chat-status k-flex align-items-center justify-content-center">
                                                <div className="chat-status-rating-col mr-2">
                                                    <div className="chat-sta-det">
                                                        <h4 className="heding-text text-screen1 text-truncate">{this.state.chat_completed}</h4>
                                                        <p className="small card-body-text text-screen1">Chat Completed</p>
                                                    </div>
                                                </div>
                                                <div className="chat-status-rating-col ml-2">
                                                    <div className="chat-sta-det">
                                                        <h4 className="heding-text text-screen1 text-truncate">{this.state.response_time}</h4>
                                                        <p className="small card-body-text text-screen1">Response Time</p>
                                                    </div>
                                                </div>
                                                <div className="chat-status-rating-col mr-2">
                                                    <div className="chat-sta-det">
                                                        <h4 className="heding-text text-screen1 text-truncate">{this.state.chat_duration}</h4>
                                                        <p className="small card-body-text text-screen1">Chat Duration</p>
                                                    </div>
                                                </div>
                                                <div className="chat-status-rating-col ml-2">
                                                    <div className="chat-sta-det">
                                                        <h4 className="heding-text text-screen1 text-truncate">{this.state.satisfaction}</h4>
                                                        <p className="small card-body-text text-screen1">Satisfaction</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        <div className="section-agent-profiles mb-2">
                                            <div className="form-feilds px-5 py-0 ">
                                                <h5 className="bg-lightgray header-title heding-text text-truncate mr-auto px-5 py-4">Chat ratings recived</h5>
                                            </div>
                                            <div className="form-feilds px-5 py-4">
                                                <div className="chat-status k-flex align-items-center justify-content-center">
                                                    <div className="chat-status-rating-col mr-2">
                                                        <img alt="" src={require('../../assets/img/icon/like.png')} />
                                                        <p className="small card-body-text text-screen1 pl-2">{this.state.satisfaction}%</p>
                                                    </div>
                                                    <div className="chat-status-rating-col ml-2">
                                                        <img alt="" src={require('../../assets/img/icon/dislike.png')} />
                                                        <p className="small card-body-text text-screen1 pl-2">{this.state.negativeRating}%</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div></>) : (<div className="section-agent-profiles mb-2">
                                            <div className="form-feilds px-5 py-0 ">
                                                {this.agent.roleType === "Owner" ? (<><h5 className="bg-lightgray revoke-profile-text heding-text mr-auto px-5 py-4">Consent to process data has been revoked. To see these stats please provide consent.</h5><a className="my-2 text-light-blue text-14" href={"/" + this.agent.ownerIdentifire + "/data-management"}>Go to data management section</a></>) : (<h5 className="bg-lightgray revoke-profile-text heding-text mr-auto px-5 py-4">Consent to process data has been revoked by owner. To see these stats please ask owner to provide consent.</h5>)}

                                            </div></div>)}

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.showEditProfile ? <EditProfile showEditProfile={this.state.showEditProfile} hideProfile={this.hideEditprofile} updateProfile={this.props.updateProfile} /> : (null)}
            </>
        )
    }

}

export default UpdateProfile;