import React from 'react';
// import db from "../../../config/firebase";
import OnlineDesktop from './online/Desktop';
import OnlineMobile from './online/Mobile';
import OfflineDesktop from './offline/Desktop';
import OfflineMobile from './offline/Mobile';
import FeedBackDesktop from './feedback/Desktop';
import FeedBackMobile from './feedback/Mobile';
import ChatBotPreview from './chatBot/index';
import moment from 'moment-timezone';
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import { getChatBotToken, getChatBots, activityLog } from "../../../services/service";
import { CHATBOTURL } from "../../../config/siteConfig";
import ReactTooltip from "react-tooltip";
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/plugins.pkgd.min.js";
import PopUp from '../../../layout/components/popup';

import FroalaEditor from 'react-froala-wysiwyg';
import ReactDragListView from 'react-drag-listview';

import TextField from '@material-ui/core/TextField';
/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import firebaseServices from '../../../firebase';
import { getOwnerSnapShot } from '../../../services/payment';
/* For Dropdown End */

const dateFormat = "X";
var defaultvalues = require('../../../localization/default-values.json');
var defaultValue = defaultvalues.chat_form;
const widgetLocalization = require('../../../localization/widget.json');
const siteConfig = require("../../../../src/config/siteConfig")

class Chatform extends React.Component {

	agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};

	constructor(props) {
		super();
		const onlineFormFields = [
			{
				title: 'Name',
				name: 'online_name_label',
				errorVal: 'online_name_label_error',
				checkbox: 'online_name_check',
				required: 'online_name_required',
				switchId: 'switch1',
				type: 'input',
				multiline: false,
				placeHoldertext: 'namePlaceHolderText',
				frontName: 'user_name'
			},
			{
				title: 'Email',
				name: 'online_email_label',
				errorVal: 'online_email_label_error',
				checkbox: 'online_email_check',
				required: 'online_email_required',
				switchId: 'switch2',
				type: 'input',
				multiline: false,
				placeHoldertext: 'emailPlaceHolderText',
				frontName: 'user_email'
			},
			{
				title: 'Contact',
				name: 'online_contact_label',
				errorVal: 'online_contact_label_error',
				checkbox: 'online_contact_check',
				required: 'online_contact_required',
				switchId: 'switch3',
				type: 'input',
				multiline: false,
				placeHoldertext: 'contactPlaceHolderText',
				frontName: 'check_phone'
			},
			{
				title: 'Team',
				name: 'online_team_label',
				errorVal: 'online_team_label_error',
				checkbox: 'online_team_check',
				required: 'online_team_required',
				switchId: 'switch4',
				type: 'select',
				multiline: false,
				placeHoldertext: 'teamPlaceHolderText',
				frontName: 'team_name'
			},
			{
				title: 'Query',
				name: 'online_query_label',
				errorVal: 'online_query_label_error',
				checkbox: 'online_query_check',
				required: 'online_query_required',
				switchId: 'switch5',
				type: 'input',
				multiline: true,
				placeHoldertext: 'queryPlaceholderText',
				frontName: 'user_query'
			}
		];

		const offlineFormFields = [
			{
				title: 'Name',
				name: 'offline_name_label',
				errorVal: 'offline_name_label_error',
				checkbox: 'offline_name_check',
				required: 'offline_name_required',
				switchId: 'switch1',
				type: 'input',
				multiline: false,
				placeHoldertext: 'namePlaceHolderText',
				frontName: 'user_name'
			},
			{
				title: 'Email',
				name: 'offline_email_label',
				errorVal: 'offline_email_label_error',
				checkbox: 'offline_email_check',
				required: 'offline_email_required',
				switchId: 'switch2',
				type: 'input',
				multiline: false,
				placeHoldertext: 'emailPlaceHolderText',
				frontName: 'user_email'
			},
			{
				title: 'Contact',
				name: 'offline_contact_label',
				errorVal: 'offline_contact_label_error',
				checkbox: 'offline_contact_check',
				required: 'offline_contact_required',
				switchId: 'switch3',
				type: 'input',
				multiline: false,
				placeHoldertext: 'contactPlaceHolderText',
				frontName: 'check_phone'
			},
			{
				title: 'Team',
				name: 'offline_team_label',
				errorVal: 'offline_team_label_error',
				checkbox: 'offline_team_check',
				required: 'offline_team_required',
				switchId: 'switch4',
				type: 'select',
				multiline: false,
				placeHoldertext: 'teamPlaceHolderText',
				frontName: 'team_name'
			},
			{
				title: 'Query',
				name: 'offline_query_label',
				errorVal: 'offline_query_label_error',
				checkbox: 'offline_query_check',
				required: 'offline_query_required',
				switchId: 'switch5',
				type: 'input',
				multiline: true,
				placeHoldertext: 'queryPlaceholderText',
				frontName: 'user_query'
			}
		];
		// for (let i = 1, len = 7; i < len; i++) {
		// data.push({
		//     title: `rows${i}`
		// });
		// }
		this.state = {
			loader: false,
			ownerId: '',
			current_view: 'desktop',
			current_preview: 'online',
			online_name_required: false,
			online_name_label: '',
			online_email_required: false,
			online_email_label: '',
			online_contact_required: false,
			online_contact_label: '',
			online_query_required: false,
			online_query_label: '',
			offline_name_required: false,
			offline_name_label: '',
			online_name_check: false,
			online_email_check: false,
			online_contact_check: false,
			online_query_check: false,
			offline_name_check: false,
			offline_email_check: false,
			offline_contact_check: false,
			offline_query_check: false,
			online_welcomemessage_check: false,
			offline_welcomemessage_check: false,
			online_welcomemessage_text: '',
			offline_welcomemessage_text: '',
			offline_email_required: false,
			offline_email_label: '',
			offline_query_required: false,
			offline_contact_label: '',
			offline_query_label: '',
			form_type: '',
			defaultChatBot: '',
			show_online_form: true,
			show_offline_form: false,
			pre_chat_form: true,
			post_chat_form: true,
			value_saved: true,
			online_welcomemessage_text_error: '',
			online_name_label_error: '',
			online_email_label_error: '',
			online_contact_label_error: '',
			online_query_label_error: '',
			offline_welcomemessage_text_error: '',
			offline_name_label_error: '',
			offline_email_label_error: '',
			offline_contact_label_error: '',
			offline_query_label_error: '',
			offline_system_response_error: '',
			thank_you_message_error: '',
			feedback_message_error: '',
			feedback_response_error: '',
			company_name: '',
			languages: [],
			defaultLanguage: 'en',
			chatbotToken: '',
			chatbotsList: {},
			chatbotAgentId: "",
			chatbotUserId: "",
			chatbotId: "",
			chatbotId_error: "",
			show_feedback_comment: false,
			feedback_comment_label: "Leave a comment (Optional)",
			team_selection: false,
			teams: [],
			onlineFormFields,
			offlineFormFields,
			contactPlaceHolderText: '+91-2345678564',
			online_team_required: false,
			online_team_label: 'Team',
			online_team_label_error: '',
			online_team_check: false,
			offline_team_required: false,
			offline_team_label: 'Team',
			offline_team_label_error: '',
			offline_team_check: false,
			online_introducetext: '',
			online_introducetext_check: false,
			online_introducetext_error: '',
			offline_introducetext: '',
			offline_introducetext_check: false,
			offline_introducetext_error: '',
			formData: {},
			chatbotToken: "",
			payment_status: true,
			multi_feedback_switch: false
		}
	}

	/* Get all teams Start */

	getTeamList = () => {
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('departments').orderBy('name', 'asc').get().then(async (docs) => {
			var teams = [];
			if (docs.size > 0) {
				docs.forEach(team => {
					let teamData = team.data();
					teamData.id = team.id;
					teams.push(teamData);
				});
			}
			this.setState({
				teams: teams
			});
		})
	}

	/* Get all teams End */

	componentDidMount() {

		this.getTeamList();

		let userDetails = JSON.parse(localStorage.getItem('agent'));
		this.setState({
			ownerId: userDetails.ownerId,
			loader: true,
			company_name: userDetails.company_name ? userDetails.company_name : "",
			languages: userDetails.languages ? userDetails.languages : []
		})

		if (userDetails.ownerId) {
			console.log(userDetails.ownerId, "-----op------");
			getOwnerSnapShot(userDetails.ownerId, async (results) => {
				if (results.status === 200) {
					let _data = results.data;
					console.log("---------data-------", _data)
					this.setState({
						payment_status: _data.payment_status !== undefined ? true : false
					})
				} else {
					this.setState({
						payment_status: false
					})
				}
			}
			)
		}



		// var defCompanyDet = firebaseServices.db.collection('users').doc(userDetails.ownerId).onSnapshot((data) => {
		//     if(data.exists) {
		//         let detData = data.data();
		//         this.setState({
		//             company_name:detData.company_name,
		//             languages : detData.languages ? detData.languages : []
		//         })
		//         defCompanyDet();
		//     }
		// })
		var defChat = firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('chatbots').where("status", '==', 1).onSnapshot((docs) => {
			if (!docs.empty) {
				let defaultChatBot = ''
				let chatbotToken = '';
				let formData = {};
				let multi_feedback_switch = false;
				docs.forEach((doc) => {
					defaultChatBot = doc.id
					chatbotToken = doc.data().chatbotToken ? doc.data().chatbotToken : "";
					formData = doc.data();
					multi_feedback_switch = doc.data().multi_feedback_switch ? doc.data().multi_feedback_switch : false;
				})
				this.setState({
					defaultChatBot: defaultChatBot,
					loader: false,
					formData: formData,
					chatbotToken: chatbotToken,
					multi_feedback_switch: multi_feedback_switch
				})
				this.getValues(userDetails.ownerId, defaultChatBot, formData, chatbotToken);
				defChat();
			}
		})

		activityLog("Opened chatform page");
	}

	getValues = (ownerId, defaultChatBot, formData, chatbotToken) => {
		this.setState({
			loader: true
		})
		let promise1 = new Promise((res, rej) => {
			let templateId = this.state.defaultLanguage === 'en' ? 'template' : 'template_' + this.state.defaultLanguage;
			var formDetails = firebaseServices.db.collection('users').doc(ownerId).collection('chatbots').doc(defaultChatBot).collection('editedBots').doc(templateId).onSnapshot((docs) => {
				let data;
				if (docs.exists === false || docs.data().online === undefined || docs.data().offline === undefined) {
					data = widgetLocalization[this.state.defaultLanguage] && widgetLocalization[this.state.defaultLanguage].chat_form ? widgetLocalization[this.state.defaultLanguage].chat_form : defaultValue;
				} else {
					data = docs.data();
				}
				res(data);
				formDetails();
			})
		})

		// let promise2 =new Promise((resolve,reject) => {
		//     var chatbotDetails = firebaseServices.db.collection('users').doc(ownerId).collection('chatbots').doc(defaultChatBot).onSnapshot((form) => {
		//         let data;
		//         if(form.exists) {
		//             data = form.data();
		//         }else {
		//             data = defaultValue;
		//         }
		//         resolve(data);
		//         chatbotDetails();
		//     })
		// })

		let promise3 = new Promise(async (resolve, reject) => {
			if (chatbotToken === "") {
				let getChatbotToken = await getChatBotToken(this.agent.ownerEmail);
				if (getChatbotToken.status === 200) {
					chatbotToken = getChatbotToken.data.apikey;
					firebaseServices.db.collection('users').doc(ownerId).collection('chatbots').doc(defaultChatBot).update({
						chatbotToken: chatbotToken
					})
				}
			}
			let getChatBotsList = await getChatBots(chatbotToken);
			let chatbotsListResp = {};
			if (getChatBotsList.status === 200) {
				chatbotsListResp = getChatBotsList.data;
			} else if (getChatBotsList.status === 401) {
				let getChatbotToken = await getChatBotToken(this.agent.ownerEmail);
				if (getChatbotToken.status === 200) {
					chatbotToken = getChatbotToken.data.apikey;
					getChatBotsList = await getChatBots(chatbotToken);
					if (getChatBotsList.status === 200) {
						chatbotsListResp = getChatBotsList.data;
					}
					firebaseServices.db.collection('users').doc(ownerId).collection('chatbots').doc(defaultChatBot).update({
						chatbotToken: chatbotToken
					})
				}
			}
			resolve(chatbotsListResp);
		})

		Promise.all([promise1, promise3]).then((res) => {
			let data = res[0];
			let templateData = formData;
			let chatBotListResp = res[1];
			let chatbotsList = {};
			let chatbotAgentId = "";
			let chatbotUserId = "";
			if (chatBotListResp.status === 200) {
				chatbotsList = chatBotListResp.res;
				chatbotAgentId = chatBotListResp.agent_id;
				chatbotUserId = chatBotListResp.user_id;
			}
			let chatbotId = "";
			if (templateData.chatbotId) {
				chatbotId = templateData.chatbotId
			}

			data.online.welcomemessage.text = data.online.welcomemessage.text.replace("---company_name---", this.state.company_name);
			if (data.feedback_message) {
				data.feedback_message = data.feedback_message.replace("---company_name---", this.state.company_name);
			}
			defaultValue.feedback_message = defaultValue.feedback_message.replace("---company_name---", this.state.company_name);

			/* For remove errors start */

			if (!data.online.hasOwnProperty("name") && Object.keys(data.online).length <= 1) {
				data.online.name = {};
				data.online.email = {};
				data.online.contact = {};
				data.online.query = {};
			}

			if (!data.offline.hasOwnProperty("name") && Object.keys(data.offline).length <= 2) {
				data.offline.name = {};
				data.offline.email = {};
				data.offline.contact = {};
				data.offline.query = {};
			}

			/* For remove errors end */
			this.setState({
				online_name_required: data.online.name.required,
				online_name_label: data.online.name.label,
				online_email_required: data.online.email.required,
				online_email_label: data.online.email.label,
				online_contact_required: data.online.contact.required,
				online_contact_label: data.online.contact.label,
				online_query_required: data.online.query.required,
				online_query_label: data.online.query.label,
				offline_name_required: data.offline.name.required,
				offline_name_label: data.offline.name.label,
				online_name_check: data.online.name.check,
				online_email_check: data.online.email.check,
				online_contact_check: data.online.contact.check,
				online_query_check: data.online.query.check,
				offline_name_check: data.offline.name.check,
				offline_email_check: data.offline.email.check,
				offline_contact_check: data.offline.contact.check,
				offline_query_check: data.offline.query.check,
				online_welcomemessage_check: data.online.welcomemessage.check,
				offline_welcomemessage_check: data.offline.welcomemessage.check,
				online_welcomemessage_text: data.online.welcomemessage.text,
				offline_welcomemessage_text: data.offline.welcomemessage.text,
				offline_email_required: data.offline.email.required,
				offline_email_label: data.offline.email.label,
				offline_query_required: data.offline.query.required,
				offline_query_label: data.offline.query.label,
				offline_system_response: data.offline.system_response.text,
				offline_system_response_check: data.offline.system_response.check,
				offline_contact_required: data.offline.contact.required,
				offline_contact_label: data.offline.contact.label,
				feedback_message: data.feedback_message ? data.feedback_message : defaultValue.feedback_message,
				feedback_response: data.feedback_response ? data.feedback_response : defaultValue.feedback_response,
				show_feedback_comment: data.show_feedback_comment ? data.show_feedback_comment : false,
				feedback_comment_label: data.feedback_comment_label ? data.feedback_comment_label : "Leave a comment (Optional)",
				thank_you_message: data.thank_you_message ? data.thank_you_message : defaultValue.thank_you_message,
				post_chat_response_type: templateData.post_chat_response_type,
				feedback_type: templateData.feedback_type,
				form_type: templateData.form_type,
				pre_chat_form: templateData.pre_chat_form || templateData.pre_chat_form === false ? templateData.pre_chat_form : true,
				post_chat_form: templateData.post_chat_form || templateData.post_chat_form === false ? templateData.post_chat_form : true,
				loader: false,
				intoroduceText: data.intoroduceText ? data.intoroduceText : (widgetLocalization[this.state.defaultLanguage].intoroduceText ? widgetLocalization[this.state.defaultLanguage].intoroduceText : widgetLocalization['en'].intoroduceText),
				online_introducetext: data.online.introducetext ? data.online.introducetext : (widgetLocalization[this.state.defaultLanguage].intoroduceText ? widgetLocalization[this.state.defaultLanguage].intoroduceText : widgetLocalization['en'].intoroduceText),
				offline_introducetext: data.offline.introducetext ? data.offline.introducetext : (widgetLocalization[this.state.defaultLanguage].intoroduceText ? widgetLocalization[this.state.defaultLanguage].intoroduceText : widgetLocalization['en'].intoroduceText),
				online_introducetext_check: data.online.introducetext_check ? data.online.introducetext_check : false,
				offline_introducetext_check: data.offline.introducetext_check ? data.offline.introducetext_check : false,
				startChatText: widgetLocalization[this.state.defaultLanguage].startChatText ? widgetLocalization[this.state.defaultLanguage].startChatText : widgetLocalization['en'].startChatText,
				namePlaceHolderText: widgetLocalization[this.state.defaultLanguage].namePlaceHolderText ? widgetLocalization[this.state.defaultLanguage].namePlaceHolderText : widgetLocalization['en'].namePlaceHolderText,
				emailPlaceHolderText: widgetLocalization[this.state.defaultLanguage].emailPlaceHolderText ? widgetLocalization[this.state.defaultLanguage].emailPlaceHolderText : widgetLocalization['en'].emailPlaceHolderText,
				queryPlaceholderText: widgetLocalization[this.state.defaultLanguage].queryPlaceholderText ? widgetLocalization[this.state.defaultLanguage].queryPlaceholderText : widgetLocalization['en'].queryPlaceholderText,
				startNewConversationText: widgetLocalization[this.state.defaultLanguage].startNewConversationText ? widgetLocalization[this.state.defaultLanguage].startNewConversationText : widgetLocalization['en'].startNewConversationText,
				changeLanguageText: widgetLocalization[this.state.defaultLanguage].changeLanguageText ? widgetLocalization[this.state.defaultLanguage].changeLanguageText : widgetLocalization['en'].changeLanguageText,
				soundText: widgetLocalization[this.state.defaultLanguage].soundText ? widgetLocalization[this.state.defaultLanguage].soundText : widgetLocalization['en'].soundText,
				endChatText: widgetLocalization[this.state.defaultLanguage].endChatText ? widgetLocalization[this.state.defaultLanguage].endChatText : widgetLocalization['en'].endChatText,
				ongoingConversationText: widgetLocalization[this.state.defaultLanguage].ongoingConversationText ? widgetLocalization[this.state.defaultLanguage].ongoingConversationText : widgetLocalization['en'].ongoingConversationText,
				typingAreaText: widgetLocalization[this.state.defaultLanguage].typingAreaText ? widgetLocalization[this.state.defaultLanguage].typingAreaText : widgetLocalization['en'].typingAreaText,
				sendMessageText: widgetLocalization[this.state.defaultLanguage].sendMessageText ? widgetLocalization[this.state.defaultLanguage].sendMessageText : widgetLocalization['en'].sendMessageText,
				chatbotsList: chatbotsList,
				chatbotAgentId: chatbotAgentId,
				chatbotUserId: chatbotUserId,
				chatbotId: chatbotId ? chatbotId : "",
				team_selection: templateData.team_selection ? templateData.team_selection : false,
				onlineFormFields: data.online.formFields ? data.online.formFields : this.state.onlineFormFields,
				offlineFormFields: data.offline.formFields ? data.offline.formFields : this.state.offlineFormFields,
				online_team_required: data.online.team && data.online.team.required ? data.online.team.required : false,
				online_team_label: data.online.team && data.online.team.label ? data.online.team.label : 'Team',
				online_team_check: data.online.team && data.online.team.check ? data.online.team.check : false,
				offline_team_required: data.online.team && data.offline.team.required ? data.offline.team.required : false,
				offline_team_label: data.online.team && data.offline.team.label ? data.offline.team.label : 'Team',
				offline_team_check: data.online.team && data.offline.team.check ? data.offline.team.check : false,
				multi_feedback_switch: templateData.multi_feedback_switch ? templateData.multi_feedback_switch : defaultValue.multi_feedback_switch
			})
		})
	}

	handleChange = (e) => {
		let target = e.target;
		let value = '';
		console.log("traget", e.target)
		if (target.type === 'checkbox') {
			value = target.checked;
		} else {
			value = target.value
			let errorName = target.name + '_error';
			if (value === '') {
				this.setState({
					[errorName]: 'This field should not be empty'
				})
			} else {
				this.setState({
					[errorName]: ''
				})
			}
		}
		if (target.name === 'post_chat_response_type') {
			if (value === 'post_chat_feedback') {
				this.setState({
					current_preview: 'feedback'
				})
			} else if (value === 'thank_you_message') {
				this.setState({
					current_preview: 'thank_you_message'
				})
			}
		}
		if (target.name === "chatbotId" && value === 'openChatBot') {
			window.open(
				CHATBOTURL,
				'_blank'
			);
		}
		else if (target.name === "multi_feedback_switch") {
			this.setState({ [target.name]: value })
		}
		else {
			this.setState({
				[target.name]: value,
				value_saved: false,
			})
		}
	}

	handleModelChange = (model) => {
		this.setState({
			online_welcomemessage_text: model,
		});
	};

	changePreview = (e) => {
		this.setState({
			current_preview: e.target.value
		})
	}

	changeView = (view) => {
		this.setState({
			current_view: view
		})
	}

	timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
	}


	handleSubmit = (e) => {
		e.preventDefault();
		this.saveForm();
	}

	validateForm = () => {
		let returnValue = true;
		let online_welcomemessage_text_error = '';
		let online_name_label_error = '';
		let online_email_label_error = '';
		let online_contact_label_error = '';
		let online_query_label_error = '';
		let offline_name_label_error = '';
		let offline_email_label_error = '';
		let offline_contact_label_error = '';
		let offline_query_label_error = '';
		let offline_welcomemessage_text_error = '';
		let offline_system_response_error = '';
		let thank_you_message_error = '';
		let feedback_message_error = '';
		let feedback_response_error = '';
		let chatbotId_error = '';
		let intoroduceText_error = '';
		let online_introducetext_error = '';
		let offline_introducetext_error = '';

		if (this.state.pre_chat_form && this.state.online_welcomemessage_text === '') {
			returnValue = false;
			online_welcomemessage_text_error = 'This field should not be empty!'
		} else if (this.state.pre_chat_form && this.state.online_welcomemessage_text.trim().split(/\s+/).length > 50) {
			returnValue = false;
			online_welcomemessage_text_error = 'This field can contain maximum 50 words'
		} else {
			online_welcomemessage_text_error = ''
		}

		if (this.state.pre_chat_form && this.state.online_name_label === '') {
			returnValue = false;
			online_name_label_error = 'This field should not be empty'
		} else {
			online_name_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.online_email_label === '') {
			returnValue = false;
			online_email_label_error = 'This field should not be empty'
		} else {
			online_email_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.online_contact_label === '') {
			returnValue = false;
			online_contact_label_error = 'This field should not be empty'
		} else {
			online_contact_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.online_query_label === '') {
			returnValue = false;
			online_query_label_error = 'This field should not be empty'
		} else {
			online_query_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_name_label === '') {
			returnValue = false;
			offline_name_label_error = 'This field should not be empty'
		} else {
			offline_name_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_email_label === '') {
			returnValue = false;
			offline_email_label_error = 'This field should not be empty'
		} else {
			offline_email_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_contact_label === '') {
			returnValue = false;
			offline_contact_label_error = 'This field should not be empty'
		} else {
			offline_contact_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_query_label === '') {
			returnValue = false;
			offline_query_label_error = 'This field should not be empty'
		} else {
			offline_query_label_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_welcomemessage_text === '') {
			returnValue = false;
			offline_welcomemessage_text_error = 'This field should not be empty!'
		} else if (this.state.pre_chat_form && this.state.offline_welcomemessage_text.trim().split(/\s+/).length > 50) {
			returnValue = false;
			offline_welcomemessage_text_error = 'This field can contain maximum 50 words'
		} else {
			offline_welcomemessage_text_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_system_response === '') {
			returnValue = false;
			offline_system_response_error = 'This field should not be empty!'
		} else if (this.state.offline_system_response.trim().split(/\s+/).length > 50) {
			returnValue = false;
			offline_system_response_error = 'This field can contain maximum 50 words'
		} else {
			offline_system_response_error = ''
		}

		if (this.state.post_chat_form && this.state.thank_you_message === '') {
			returnValue = false;
			thank_you_message_error = 'This field should not be empty!'
		} else if (this.state.post_chat_form && this.state.thank_you_message.trim().split(/\s+/).length > 50) {
			returnValue = false;
			thank_you_message_error = 'This field can contain maximum 50 words'
		} else {
			thank_you_message_error = ''
		}

		if (this.state.post_chat_form && this.state.feedback_message === '') {
			returnValue = false;
			feedback_message_error = 'This field should not be empty!'
		} else if (this.state.post_chat_form && this.state.feedback_message.trim().split(/\s+/).length > 50) {
			returnValue = false;
			feedback_message_error = 'This field can contain maximum 50 words'
		} else {
			feedback_message_error = ''
		}

		if (this.state.post_chat_form && this.state.feedback_response === '') {
			returnValue = false;
			feedback_response_error = 'This field should not be empty!'
		} else if (this.state.post_chat_form && this.state.feedback_response.trim().split(/\s+/).length > 50) {
			returnValue = false;
			feedback_response_error = 'This field can contain maximum 50 words'
		} else {
			feedback_response_error = ''
		}

		if (this.state.form_type === "2" && (this.state.chatbotId === "" || this.state.chatbotId === undefined)) {
			returnValue = false;
			chatbotId_error = "Please select a chatbot or create a new"
		} else {
			chatbotId_error = "";
		}

		// if (this.state.pre_chat_form && this.state.intoroduceText === '') {
		//     returnValue = false;
		//     intoroduceText_error = 'This field should not be empty'
		// } else {
		//     intoroduceText_error = ''
		// }

		if (this.state.pre_chat_form && this.state.online_introducetext === '') {
			returnValue = false;
			online_introducetext_error = 'This field should not be empty'
		} else {
			online_introducetext_error = ''
		}

		if (this.state.pre_chat_form && this.state.offline_introducetext === '') {
			returnValue = false;
			offline_introducetext_error = 'This field should not be empty'
		} else {
			offline_introducetext_error = ''
		}

		this.setState({
			online_welcomemessage_text_error,
			online_name_label_error,
			online_email_label_error,
			online_contact_label_error,
			online_query_label_error,
			offline_name_label_error,
			offline_email_label_error,
			offline_contact_label_error,
			offline_query_label_error,
			offline_welcomemessage_text_error,
			offline_system_response_error,
			thank_you_message_error,
			feedback_message_error,
			feedback_response_error,
			chatbotId_error,
			intoroduceText_error,
			online_introducetext_error,
			offline_introducetext_error
		})

		return returnValue;
	}

	saveForm = () => {
		let validate = this.validateForm();
		if (!validate) {
			return;
		}
		this.setState({
			loader: true
		})

		let updatedAt = this.timeZoneFormat(Date.now());

		let onlineData = {
			welcomemessage: {
				check: this.state.online_welcomemessage_check,
				text: this.state.online_welcomemessage_text
			},
			name: {
				check: this.state.online_name_check,
				required: this.state.online_name_required,
				label: this.state.online_name_label
			},
			email: {
				check: this.state.online_email_check,
				required: this.state.online_email_required,
				label: this.state.online_email_label
			},
			contact: {
				check: this.state.online_contact_check,
				required: this.state.online_contact_required,
				label: this.state.online_contact_label
			},
			query: {
				check: this.state.online_query_check,
				required: this.state.online_query_required,
				label: this.state.online_query_label
			},
			team: {
				check: this.state.online_team_check,
				required: this.state.online_team_required,
				label: this.state.online_team_label
			},
			formFields: this.state.onlineFormFields,
			introducetext: this.state.online_introducetext,
			introducetext_check: this.state.online_introducetext_check
		}

		let offlineData = {
			welcomemessage: {
				check: this.state.offline_welcomemessage_check,
				text: this.state.offline_welcomemessage_text
			},
			name: {
				check: this.state.offline_name_check,
				required: this.state.offline_name_required,
				label: this.state.offline_name_label
			},
			email: {
				check: this.state.offline_email_check,
				required: this.state.offline_email_required,
				label: this.state.online_email_label
			},
			contact: {
				check: this.state.online_contact_check,
				required: this.state.offline_contact_required,
				label: this.state.offline_contact_label
			},
			query: {
				check: this.state.offline_query_check,
				required: this.state.offline_query_required,
				label: this.state.offline_query_label
			},
			system_response: {
				check: this.state.offline_system_response_check,
				text: this.state.offline_system_response
			},
			team: {
				check: this.state.offline_team_check,
				required: this.state.offline_team_required,
				label: this.state.offline_team_label
			},
			formFields: this.state.offlineFormFields,
			introducetext: this.state.offline_introducetext,
			introducetext_check: this.state.offline_introducetext_check
		}

		let welcomeMessage = {
			UI: 'welcome',
			Validation: {
				doValidate: false,
				type: '',
			},
			action: 'SaveInquiry',
			continue: true,
			data: {
				text: this.state.online_welcomemessage_text,
			},
			offline_data: {
				text: this.state.offline_welcomemessage_text
			},
			input: '',
			next: 1,
			trigger: [],
		}
		let validation = {
			Validation: {
				doValidate: false,
				type: '',
			},
			action: '',
			continue: false,
			message: '',
			input: '',
			next: 0,
			trigger: [],
		};


		let updateChatForm = new Promise((res, rej) => {
			firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).update({
				post_chat_response_type: this.state.post_chat_response_type ? this.state.post_chat_response_type : '',
				thank_you_message: this.state.thank_you_message ? this.state.thank_you_message : '',
				feedback_message: this.state.feedback_message ? this.state.feedback_message : '',
				show_feedback_comment: this.state.show_feedback_comment ? this.state.show_feedback_comment : false,
				feedback_comment_label: this.state.feedback_comment_label ? this.state.feedback_comment_label : "",
				feedback_response: this.state.feedback_response ? this.state.feedback_response : '',
				feedback_type: this.state.feedback_type ? this.state.feedback_type : '',
				updatedAt: updatedAt,
				form_type: this.state.form_type,
				pre_chat_form: this.state.pre_chat_form || this.state.pre_chat_form === false ? this.state.pre_chat_form : true,
				post_chat_form: this.state.post_chat_form || this.state.post_chat_form === false ? this.state.post_chat_form : true,
				chatbotId: this.state.chatbotId,
				chatbotAgentId: this.state.chatbotAgentId,
				chatbotUserId: this.state.chatbotUserId,
				team_selection: this.state.team_selection,
				multi_feedback_switch: this.state.multi_feedback_switch ? this.state.multi_feedback_switch : false
			}).then(() => {
				res('done');
			});
		})

		let updateTemplate = new Promise((Resolve, reject) => {
			let templateId = this.state.defaultLanguage === 'en' ? 'template' : 'template_' + this.state.defaultLanguage;
			let updatedData = {
				online: onlineData,
				offline: offlineData,
				0: welcomeMessage,
				'00': validation,
				thank_you_message: this.state.thank_you_message,
				feedback_message: this.state.feedback_message,
				show_feedback_comment: this.state.show_feedback_comment ? this.state.show_feedback_comment : false,
				feedback_comment_label: this.state.feedback_comment_label ? this.state.feedback_comment_label : "",
				feedback_response: this.state.feedback_response,
				intoroduceText: this.state.intoroduceText ? this.state.intoroduceText : widgetLocalization[this.state.defaultLanguage].intoroduceText,
				startChatText: widgetLocalization[this.state.defaultLanguage].startChatText,
				namePlaceHolderText: widgetLocalization[this.state.defaultLanguage].namePlaceHolderText,
				emailPlaceHolderText: widgetLocalization[this.state.defaultLanguage].emailPlaceHolderText,
				queryPlaceholderText: widgetLocalization[this.state.defaultLanguage].queryPlaceholderText,
				startNewConversationText: widgetLocalization[this.state.defaultLanguage].startNewConversationText,
				changeLanguageText: widgetLocalization[this.state.defaultLanguage].changeLanguageText,
				soundText: widgetLocalization[this.state.defaultLanguage].soundText,
				endChatText: widgetLocalization[this.state.defaultLanguage].endChatText,
				ongoingConversationText: widgetLocalization[this.state.defaultLanguage].ongoingConversationText,
				typingAreaText: widgetLocalization[this.state.defaultLanguage].typingAreaText,
				startingText: widgetLocalization[this.state.defaultLanguage].startingText,
				starTingNewConvText: widgetLocalization[this.state.defaultLanguage].starTingNewConvText,
				sendMessageText: widgetLocalization[this.state.defaultLanguage].sendMessageText,
				showOriginalText: widgetLocalization[this.state.defaultLanguage].showOriginalText,
				defaultLanguageText: widgetLocalization[this.state.defaultLanguage].defaultLanguageText,
				localLanguageText: widgetLocalization[this.state.defaultLanguage].localLanguageText,
				saveButtonText: widgetLocalization[this.state.defaultLanguage].saveButtonText,
				cancelButtonText: widgetLocalization[this.state.defaultLanguage].cancelButtonText,
				downloadTranscriptText: widgetLocalization[this.state.defaultLanguage].downloadTranscriptText,
				multi_feedback_switch: this.state.multi_feedback_switch ? this.state.multi_feedback_switch : false
			}
			if (this.state.defaultLanguage === 'en') {
				firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).update(updatedData).then(() => {
					Resolve('done');
				}).catch((err) => {
					reject(err);
				})
			} else {
				firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).get().then((docs) => {
					if (docs.exists) {
						firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).update(updatedData).then(() => {
							Resolve('done');
						}).catch((err) => {
							reject(err);
						})
					}
					else {
						firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).set(updatedData).then(() => {
							Resolve('done');
						}).catch((err) => {
							reject(err);
						})
					}
				}).catch((err) => {
					reject(err);
				})
			}
		})

		Promise.all([updateChatForm, updateTemplate]).then(() => {
			this.setState({
				loader: false
			})
			toast.success("Chat survey has been updated successfully!");
			window.scrollTo(0, 0);
			activityLog("Updated chatform page");
		})
	}

	componentDidUpdate() {
		if (this.props.cancelForm) {
			this.props.updateValues();
			this.cancelValues();
		}

		if (this.props.submitForm) {
			this.saveForm();
			this.props.updateValues();
		}
	}

	cancelForm = (e) => {
		e.preventDefault();
		this.cancelValues();
	}

	cancelValues = () => {
		this.setState({
			online_welcomemessage_text_error: '',
			online_name_label_error: '',
			online_email_label_error: '',
			online_contact_label_error: '',
			online_query_label_error: '',
			offline_welcomemessage_text_error: '',
			offline_name_label_error: '',
			offline_email_label_error: '',
			offline_contact_label_error: '',
			offline_query_label_error: '',
			offline_system_response_error: '',
			thank_you_message_error: '',
			feedback_message_error: '',
			feedback_response_error: ''
		})
		this.componentDidMount();
		// this.getValues(this.state.ownerId, this.state.defaultChatBot);
	}

	restoreDefault = (e) => {
		e.preventDefault();
		this.setState({
			loader: true
		})
		let welcomeMessage = {
			UI: 'welcome',
			Validation: {
				doValidate: false,
				type: '',
			},
			action: 'SaveInquiry',
			continue: true,
			data: {
				text: `Hi! Welcome to ${this.state.company_name}, We're here to help you.`,
			},
			offline_data: {
				text: this.state.offline_welcomemessage_text
			},
			input: '',
			next: 1,
			trigger: [],
		}
		let validation = {
			Validation: {
				doValidate: false,
				type: '',
			},
			action: '',
			continue: false,
			message: '',
			input: '',
			next: 0,
			trigger: [],
		};

		let restoreValue = widgetLocalization[this.state.defaultLanguage] && widgetLocalization[this.state.defaultLanguage].chat_form ? widgetLocalization[this.state.defaultLanguage].chat_form : defaultValue;
		restoreValue.online.welcomemessage.text = restoreValue.online.welcomemessage.text.replace("---company_name---", this.state.company_name);
		restoreValue.feedback_message = restoreValue.feedback_message.replace("---company_name---", this.state.company_name);
		let updatedAt = this.timeZoneFormat(Date.now());
		let updateChatForm = new Promise((res, rej) => {
			firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).update({
				post_chat_response_type: restoreValue.post_chat_response_type, feedback_type: restoreValue.feedback_type, updatedAt: updatedAt, form_type: restoreValue.form_type, pre_chat_form: restoreValue.pre_chat_form, post_chat_form: restoreValue.post_chat_form, multi_feedback_switch: restoreValue.multi_feedback_switch
			}).then(() => {
				res('done');
			});
		})
		let updateTemplate = new Promise((Resolve, reject) => {
			let templateId = this.state.defaultLanguage === 'en' ? 'template' : 'template_' + this.state.defaultLanguage;
			let updatedData = {
				online: restoreValue.online,
				offline: restoreValue.offline,
				0: welcomeMessage,
				'00': validation,
				thank_you_message: restoreValue.thank_you_message,
				feedback_message: restoreValue.feedback_message,
				feedback_response: restoreValue.feedback_response,
				intoroduceText: widgetLocalization[this.state.defaultLanguage].intoroduceText,
				startChatText: widgetLocalization[this.state.defaultLanguage].startChatText,
				namePlaceHolderText: widgetLocalization[this.state.defaultLanguage].namePlaceHolderText,
				emailPlaceHolderText: widgetLocalization[this.state.defaultLanguage].emailPlaceHolderText,
				queryPlaceholderText: widgetLocalization[this.state.defaultLanguage].queryPlaceholderText,
				startNewConversationText: widgetLocalization[this.state.defaultLanguage].startNewConversationText,
				changeLanguageText: widgetLocalization[this.state.defaultLanguage].changeLanguageText,
				soundText: widgetLocalization[this.state.defaultLanguage].soundText,
				endChatText: widgetLocalization[this.state.defaultLanguage].endChatText,
				ongoingConversationText: widgetLocalization[this.state.defaultLanguage].ongoingConversationText,
				typingAreaText: widgetLocalization[this.state.defaultLanguage].typingAreaText,
				startingText: widgetLocalization[this.state.defaultLanguage].startingText,
				starTingNewConvText: widgetLocalization[this.state.defaultLanguage].starTingNewConvText,
				sendMessageText: widgetLocalization[this.state.defaultLanguage].sendMessageText,
				showOriginalText: widgetLocalization[this.state.defaultLanguage].showOriginalText,
				defaultLanguageText: widgetLocalization[this.state.defaultLanguage].defaultLanguageText,
				localLanguageText: widgetLocalization[this.state.defaultLanguage].localLanguageText,
				saveButtonText: widgetLocalization[this.state.defaultLanguage].saveButtonText,
				cancelButtonText: widgetLocalization[this.state.defaultLanguage].cancelButtonText,
				downloadTranscriptText: widgetLocalization[this.state.defaultLanguage].downloadTranscriptText
			}
			if (this.state.defaultLanguage === 'en') {
				firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).update(updatedData).then(() => {
					Resolve('done');
				})
			} else {
				firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).get().then((docs) => {
					if (docs.exists) {
						firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).update(updatedData).then(() => {
							Resolve('done');
						}).catch((err) => {
							reject(err);
						})
					}
					else {
						firebaseServices.db.collection('users').doc(this.state.ownerId).collection('chatbots').doc(this.state.defaultChatBot).collection('editedBots').doc(templateId).set(updatedData).then(() => {
							Resolve('done');
						}).catch((err) => {
							reject(err);
						})
					}
				}).catch((err) => {
					reject(err);
				})
			}
		})

		Promise.all([updateChatForm, updateTemplate]).then(() => {
			window.scrollTo(0, 0);
			toast.success("Chat survey has been updated successfully!");
			this.componentDidMount();
			activityLog("Restored chatform page");
		})
	}

	changeForm = (type) => {
		if (type === 2) {
			this.setState({
				show_offline_form: true,
				show_online_form: false,
				current_preview: 'offline'
			})
		} else {
			this.setState({
				show_offline_form: false,
				show_online_form: true,
				current_preview: 'online'
			})
		}
	}

	rightWidgetMobileHide = () => {
		document.getElementById('deskWidgetRightP').classList.toggle('d-none');
		document.getElementById('widgeHSIcon').classList.toggle('rotteIcon');
	}

	handleDefaultLanguage = (e) => {
		this.setState({
			loader: true,
			defaultLanguage: e.target.value
		}, () => {
			this.getValues(this.state.ownerId, this.state.defaultChatBot, this.state.formData, this.state.chatbotToken)
		})
	}

	render() {
		let disabledClass = this.state.pre_chat_form ? '' : 'disabled-checkbox';
		let disabledPostClass = this.state.post_chat_form ? '' : 'disabled-checkbox';

		const that = this;
		const dragProps = {
			onDragEnd(fromIndex, toIndex) {
				const onlineFormFields = [...that.state.onlineFormFields];
				const item = onlineFormFields.splice(fromIndex, 1)[0];
				onlineFormFields.splice(toIndex, 0, item);
				that.setState({ onlineFormFields });
			},
			nodeSelector: 'li',
			handleSelector: 'div',
			width: '100%'
		};

		const dragPropsOffline = {
			onDragEnd(fromIndex, toIndex) {
				const offlineFormFields = [...that.state.offlineFormFields];
				const item = offlineFormFields.splice(fromIndex, 1)[0];
				offlineFormFields.splice(toIndex, 0, item);
				that.setState({ offlineFormFields });
			},
			nodeSelector: 'li',
			handleSelector: 'div',
			width: '100%'
		}
		return (
			<>
				<ReactTooltip />
				<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
				{this.state.payment_status ? <div className="page-wrapper sidebar-collapse right-panel-widget-show" data-mobile-height="">
					<div className="main-wrapper container-fluid">
						<div className="chatform-Preview">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
										<div className="bg-white left-widgetsetings-box mb-foter-Fixed">
											<div className="chat-forms">
												<div className="perfect Feilds mx-8 mob-mx-4 mob-mr-4">
													<div className="main-heading main-heading-title py-5">
														<h3 className="heding-text">Chat Survey</h3>
														<h6 className="card-title mb-0">Qualify leads and prospects with</h6>
													</div>
													<div className="settings-form-feilds">
														<div className="settings-feld form-feilds k-flex align-items-center mb-4">
															<span className="main-checkbox-all"><label className="containerRadio containerafterchecked"><input type="radio" name="form_type" value="1" onChange={() => { this.setState({ form_type: "1" }) }} checked={this.state.form_type === "1" ? true : false} /><span className="radioCheckmark" /></label></span>
															<h4 className="heding-text mt-n3">Use Pre Chat Survey</h4>
															&nbsp;&nbsp;&nbsp;
															<span className="main-checkbox-all"><label className="containerRadio containerafterchecked"><input type="radio" name="form_type" value="1" onChange={() => { this.setState({ form_type: "2" }) }} checked={this.state.form_type === "2" ? true : false} /><span className="radioCheckmark" /></label></span>
															<h4 className="heding-text mt-n3">Use chatbot</h4>
														</div>
													</div>
												</div>
												{this.state.form_type === "2" ? (null) : (<div className="chat-forems-section">
													<form onSubmit={this.handleSubmit}>
														<div className="chat-feilds mx-8 py-5 mob-mx-4 mob-mr-4">
															<div className="settings-feld-post form-feilds k-flex align-items-center">
																<h5 className="card-title mb-0 mr-4">Pre chat survey</h5>
																<div className="material-switch pull-right mt-3">
																	<div className="backgrounds-color-tab background-color-prechat backgrounds-color-agent mr-2">
																		<span className={this.state.pre_chat_form === true ? 'enabled' : ''} onClick={() => { this.setState({ pre_chat_form: true }) }}>Enabled</span>
																		<span className={this.state.pre_chat_form !== true ? 'enabled' : ''} onClick={() => { this.setState({ pre_chat_form: false }) }}>Disabled</span>
																	</div>
																	{/* <input id="pre_chat_form" name="pre_chat_form" type="checkbox" checked={this.state.pre_chat_form === true ? true : false} onChange={this.handleChange} />
                                                                    <label htmlFor="pre_chat_form" className="label-default"></label> */}
																</div>
															</div>
															<div className="k-flex align-items-center mt-4">
																<div className="backgrounds-color-tab backgrounds-color-agent mr-2">
																	<span className={this.state.show_online_form ? 'active' : ''} onClick={() => this.changeForm(1)}>Agent Online</span>
																	<span className={this.state.show_offline_form ? 'active' : ''} onClick={() => this.changeForm(2)}>Agent Offline</span>
																</div>
																<div className="select-languages position-relative">
																	{this.state.languages.length > 0 ? (<><select className="customArrow cusArroLan" name="defaultLanguage" onChange={this.handleDefaultLanguage}>
																		{this.state.languages.map((value, key) => {
																			return <option value={value.lang} key={key}>{value.text}</option>
																		})}
																	</select><label className="sta-langu-label">Select Language</label></>) : (null)}
																</div>
															</div>
														</div>
														{this.state.show_online_form && (<div className="chat-feilds mx-8 py-5 borderBottom mob-mx-4 mob-mr-4">
															<div className="chat-feilds">
																<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledClass}`}>
																	<span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
																		<input type="checkbox" name="online_welcomemessage_check" checked={this.state.online_welcomemessage_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
																		<span className="checkmark" /></label></span>
																	<h6 className="card-title mb-0">Welcome Message</h6>
																</div>
																<div className="form-feilds">
																	<div className="input-group material-input mb-5">
																		{/* {this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="online_welcomemessage_text" onChange={this.handleChange} value={this.state.online_welcomemessage_text} readOnly={this.state.pre_chat_form ? false : true} multiline />) :
                                                                            (<TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="online_welcomemessage_text" onChange={this.handleChange} value={this.state.online_welcomemessage_text} readOnly={this.state.pre_chat_form ? false : true} multiline />)}
                                                                        {/* <textarea type="text" placeholder="Default Welcome Message" value={this.state.online_welcomemessage_text} className="w-100 py-3" name="online_welcomemessage_text" rows="4" onChange={this.handleChange} readOnly={this.state.pre_chat_form ? false : true}></textarea> */}
																		{/* <p className="error">{this.state.online_welcomemessage_text_error}</p>  */}
																		<FroalaEditor
																			tag="textarea"
																			config={{
																				placeholderText: "Default Welcome Message",
																				heightMin: 80,
																				heightMax: 100,
																				width: 1000,
																				fontSizeUnit: "pt",
																				attribution: false,
																				key: `${siteConfig.FROALAEDITORKEY}`,
																				direction: "ltr",
																				wordPasteKeepFormatting: true,
																				enter: FroalaEditor.ENTER_DIV,
																				enter: FroalaEditor.ENTER_BR,
																				tableStyles: {
																					"no-border": "No border"
																				},
																				useClasses: false,
																				toolbarSticky: false,
																				charCounterCount: false,
																				paragraphFormatSelection: true,
																				linkInsertButtons: [],
																				toolbarButtons: ["bold",
																					"italic",
																					"underline", "insertLink"],
																				linkList: [],

																			}}
																			model={this.state.online_welcomemessage_text}
																			onModelChange={this.handleModelChange}
																		/>
																	</div>
																</div>
															</div>
															{/* Commented for ticket 1425 */}
															{/* <div className="chat-feilds">
                                                                <div className="chat-feilds my-5">
                                                                    <div className={`settings-feld form-feilds k-flex align-items-center  mb-3 ${disabledClass}`}>
                                                                        <span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
                                                                            <input type="checkbox" name="online_introducetext_check" checked={this.state.online_introducetext_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                            <span className="checkmark" /></label>
                                                                        </span>
                                                                        <h6 className="card-title mb-0">Introduce Text</h6>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="online_introducetext" onChange={this.handleChange} value={this.state.online_introducetext} multiline />
                                                                            <p className="error">{this.state.online_introducetext_error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
															{/* Commented for ticket 1425 */}
															<div className="row" style={{ display: 'block' }}>
																<ReactDragListView {...dragProps}>
																	<ul style={{ listStyle: 'none' }}>
																		{this.state.onlineFormFields.map((item, index) => (
																			<li key={index} className="draggableList">
																				<div id="sortedId">
																					<div className="col-xs-12 col-sm-12 col-md-12 mt-5">
																						<div className=" k-flex align-items-center mb-2">
																							<div className={`settings-feld form-feilds k-flex align-items-center ${disabledClass}`}>
																								<span className="main-checkbox-all">
																									<label className="containerCheckBox containerafterchecked">
																										<input type="checkbox" name={item.checkbox} checked={this.state[item.checkbox] === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
																										<span className="checkmark" />
																									</label>
																								</span>
																								<h6 className="card-title mb-0">Get {item.title}</h6>
																							</div>
																							<div className="custom-control custom-control-right custom-switch ml-auto">
																								<input type="checkbox" className="custom-control-input" id={item.switchId} checked={this.state[item.required] === true ? true : false} name={item.required} onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
																								<label className="custom-control-label" htmlFor={item.switchId}>Mandatory</label>
																							</div>
																						</div>
																						<div className="form-feilds">
																							<div className="input-group material-input aa">
																								{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" inputProps={{ maxLength: 27 }} className="form-control w-100" id={item.name} variant="outlined" name={item.name} value={this.state[item.name]} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />) :
																									(<TextField inputProps={{ maxLength: 27 }} className="form-control w-100" id={item.name} variant="outlined" name={item.name} value={this.state[item.name]} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />)}
																								<p className="error">{this.state[item.errorVal]}</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			</li>
																		))}
																	</ul>
																</ReactDragListView>

																{/* <div className="col-xs-12 col-sm-6 col-md-6">
                                                                    <div className=" k-flex align-items-center mb-2">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="online_email_check" checked={this.state.online_email_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Email</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" id="switch2" checked={this.state.online_email_required === true ? true : false} name="online_email_required" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch2">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField inputProps={{ maxLength: 27 }} className="form-control w-100" id="online_email_label" variant="outlined" name="online_email_label" value={this.state.online_email_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />
                                                                            {/* <input type="text" placeholder="Enter Email" value={this.state.online_email_label} className="w-100" name="online_email_label" onChange={this.handleChange} rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.online_email_label_error}</p>
                                                                        </div>
                                                                    </div> 
                                                                </div> */}
																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-2">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="online_contact_check" checked={this.state.online_contact_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Contact Number</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" checked={this.state.online_contact_required === true ? true : false} id="switch3" name="online_contact_required" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch3">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField inputProps={{ maxLength: 27 }} className="form-control w-100" id="online_contact_label" variant="outlined" name="online_contact_label" value={this.state.online_contact_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />
                                                                            {/* <input type="text" placeholder="Enter Contact Number" value={this.state.online_contact_label} className="w-100" name="online_contact_label" onChange={this.handleChange} rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.online_contact_label_error}</p>
                                                                        </div>
                                                                    </div> 
                                                                </div> */}
																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-2">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="online_query_check" checked={this.state.online_query_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Query</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" checked={this.state.online_query_required === true ? true : false} id="switch4" name="online_query_required" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch4">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField inputProps={{ maxLength: 27 }} className="form-control w-100" id="online_query_label" variant="outlined" name="online_query_label" value={this.state.online_query_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />
                                                                            {/* <input type="text" placeholder="Get Query" value={this.state.online_query_label} className="w-100" name="online_query_label" onChange={this.handleChange} rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.online_query_label_error}</p>
                                                                        </div>
                                                                    </div> *
                                                                </div> */}
																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className="settings-feld-post form-feilds k-flex align-items-center">
                                                                        <h5 className="card-title mb-0 mr-4">Team Selection</h5>
                                                                        <div className="material-switch pull-right mt-3">
                                                                            <input id="team_selection" name="team_selection" checked={this.state.team_selection === true ? true : false} onChange={this.handleChange} type="checkbox" />
                                                                            <label htmlFor="team_selection" className="label-default"></label>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
															</div>
														</div>)}
														{this.state.show_offline_form && (<div className="chat-feilds mx-8 mob-mx-4 mob-mr-4 py-5 borderBottom">
															<div className="chat-feilds">
																<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledClass}`}>
																	<span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
																		<input type="checkbox" name="offline_welcomemessage_check" checked={this.state.offline_welcomemessage_check === true ? true : false} defaultValue="true" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
																		<span className="checkmark" /></label></span>
																	<h6 className="card-title mb-0">Welcome Message</h6>
																</div>
																<div className="form-feilds">
																	<div className="input-group material-input">
																		{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="offline_welcomemessage_text" onChange={this.handleChange} value={this.state.offline_welcomemessage_text} readOnly={this.state.pre_chat_form ? false : true} multiline />) :
																			(<TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="offline_welcomemessage_text" onChange={this.handleChange} value={this.state.offline_welcomemessage_text} readOnly={this.state.pre_chat_form ? false : true} multiline />)}
																		{/* <textarea type="text" placeholder="Default Welcome Message" className="w-100 py-3" value={this.state.offline_welcomemessage_text} name="offline_welcomemessage_text" rows="4" onChange={this.handleChange} readOnly={this.state.pre_chat_form ? false : true}></textarea> */}
																		<p className="error">{this.state.offline_welcomemessage_text_error}</p>
																	</div>
																</div>
															</div>
															{/* Commented for ticket 1425 */}
															{/* <div className="chat-feilds">
                                                                <div className="chat-feilds my-5">
                                                                    <div className={`settings-feld form-feilds k-flex align-items-center  mb-3 ${disabledClass}`}>
                                                                        <span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
                                                                            <input type="checkbox" name="offline_introducetext_check" checked={this.state.offline_introducetext_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                            <span className="checkmark" /></label>
                                                                        </span>
                                                                        <h6 className="card-title mb-0">Introduce Text</h6>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="offline_introducetext" onChange={this.handleChange} value={this.state.offline_introducetext} multiline />
                                                                            <p className="error">{this.state.offline_introducetext_error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
															{/* Commented for ticket 1425 */}
															<div className="row" style={{ display: 'block' }}>
																<ReactDragListView {...dragPropsOffline}>
																	<ul style={{ listStyle: 'none' }}>
																		{this.state.offlineFormFields.map((item, index) => (
																			<li key={index} className="draggableList">
																				<div id="sortedId">
																					<div className="col-xs-12 col-sm-12 col-md-12 mt-5">
																						<div className=" k-flex align-items-center mb-2">
																							<div className={`settings-feld form-feilds k-flex align-items-center ${disabledClass}`}>
																								<span className="main-checkbox-all">
																									<label className="containerCheckBox containerafterchecked">
																										<input type="checkbox" name={item.checkbox} checked={this.state[item.checkbox] === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
																										<span className="checkmark" />
																									</label>
																								</span>
																								<h6 className="card-title mb-0">Get {item.title}</h6>
																							</div>
																							<div className="custom-control custom-control-right custom-switch ml-auto">
																								<input type="checkbox" className="custom-control-input" id={item.switchId} checked={this.state[item.required] === true ? true : false} name={item.required} onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
																								<label className="custom-control-label" htmlFor={item.switchId}>Mandatory</label>
																							</div>
																						</div>
																						<div className="form-feilds">
																							<div className="input-group material-input aa">
																								{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" inputProps={{ maxLength: 27 }} className="form-control w-100" id={item.name} variant="outlined" name={item.name} value={this.state[item.name]} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />) :
																									(<TextField inputProps={{ maxLength: 27 }} className="form-control w-100" id={item.name} variant="outlined" name={item.name} value={this.state[item.name]} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />)}
																								<p className="error">{this.state[item.errorVal]}</p>
																							</div>
																						</div>
																					</div>
																				</div>
																			</li>
																		))}
																	</ul>
																</ReactDragListView>


																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-0">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center mb-2 ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="offline_name_check" checked={this.state.offline_name_check === true ? true : false} onChange={this.handleChange} value={this.state.offline_name_check} disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Name</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" onChange={this.handleChange} checked={this.state.offline_name_required === true ? true : false} id="switch5" name="offline_name_required" disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch5">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" id="offline_name_label" variant="outlined" name="offline_name_label" value={this.state.offline_name_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} />
                                                                            {/* <input type="text" placeholder="Get Name" value={this.state.offline_name_label} onChange={this.handleChange} className="w-100" name="offline_name_label" rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.offline_name_label_error}</p>
                                                                        </div>
                                                                    </div> 
                                                                </div> */}

																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-0">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center mb-2 ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="offline_email_check" checked={this.state.offline_email_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Email</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" id="switch6" checked={this.state.offline_email_required === true ? true : false} onChange={this.handleChange} name="offline_email_required" disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch6">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" id="offline_email_label" variant="outlined" name="offline_email_label" value={this.state.offline_email_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} /> */}
																{/* <input type="text" placeholder="Get Email" value={this.state.offline_email_label} onChange={this.handleChange} className="w-100" name="offline_email_label" rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.offline_email_label_error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div> */}

																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-0">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center mb-2 ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="offline_contact_check" checked={this.state.offline_contact_check === true ? true : false} defaultValue="true" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Contact Number</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" id="switch7" checked={this.state.offline_contact_required === true ? true : false} name="offline_contact_required" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch7">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" id="offline_contact_label" variant="outlined" name="offline_contact_label" value={this.state.offline_contact_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} /> */}
																{/*  <input type="text" placeholder="Get Contact Number" value={this.state.offline_contact_label} className="w-100" name="offline_contact_label" onChange={this.handleChange} rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.offline_contact_label_error}</p>
                                                                        </div>
                                                                    </div>

                                                                </div> */}
																{/* <div className="col-xs-12 col-sm-6 col-md-6 mt-5">
                                                                    <div className=" k-flex align-items-center mb-0">
                                                                        <div className={`settings-feld form-feilds k-flex align-items-center mb-2 ${disabledClass}`}>
                                                                            <span className="main-checkbox-all">
                                                                                <label className="containerCheckBox containerafterchecked">
                                                                                    <input type="checkbox" name="offline_query_check" checked={this.state.offline_query_check === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
                                                                                    <span className="checkmark" />
                                                                                </label>
                                                                            </span>
                                                                            <h6 className="card-title mb-0">Get Query</h6>
                                                                        </div>
                                                                        <div className="custom-control custom-control-right custom-switch ml-auto cursorPointer">
                                                                            <input type="checkbox" className="custom-control-input" id="switch8" checked={this.state.offline_query_required} onChange={this.handleChange} name="offline_query_required" disabled={this.state.pre_chat_form ? false : true} />
                                                                            <label className="custom-control-label" htmlFor="switch8">Mandatory</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-feilds">
                                                                        <div className="input-group material-input">
                                                                            <TextField className="form-control w-100" id="offline_query_label" variant="outlined" name="offline_query_label" value={this.state.offline_query_label} readOnly={this.state.pre_chat_form ? false : true} onChange={this.handleChange} /> */}
																{/* <input type="text" placeholder="Get Query" className="w-100" value={this.state.offline_query_label} onChange={this.handleChange} name="offline_query_label" rows="4" readOnly={this.state.pre_chat_form ? false : true} /> */}
																{/* <p className="error">{this.state.offline_query_label_error}</p>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
															</div>
															<div className="chat-feilds my-5">
																<div className={`settings-feld form-feilds k-flex align-items-center  mb-3 ${disabledClass}`}>
																	<span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
																		<input type="checkbox" name="offline_system_response_check" checked={this.state.offline_system_response_check === true ? true : false} defaultValue="true" onChange={this.handleChange} disabled={this.state.pre_chat_form ? false : true} />
																		<span className="checkmark" /></label></span>
																	<h6 className="card-title mb-0">System Response</h6>
																</div>
																<div className="form-feilds">
																	<div className="input-group material-input">
																		{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="offline_system_response" onChange={this.handleChange} value={this.state.offline_system_response} readOnly={this.state.pre_chat_form ? false : true} multiline />) :
																			(<TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="offline_system_response" onChange={this.handleChange} value={this.state.offline_system_response} readOnly={this.state.pre_chat_form ? false : true} multiline />)}
																		{/* <textarea type="text" placeholder="System Response" className="w-100 py-3" value={this.state.offline_system_response} name="offline_system_response" onChange={this.handleChange} rows="4" readOnly={this.state.pre_chat_form ? false : true} ></textarea> */}
																		<p className="error">{this.state.offline_system_response_error}</p>
																	</div>
																</div>
															</div>
														</div>)}

														<div className="chat-feilds mx-8 mob-mx-4 mob-mr-4 py-5">
															<div className="settings-feld-post form-feilds k-flex align-items-center">
																<h5 className="card-title mb-0 mr-4">Post chat survey</h5>
																<div className="material-switch pull-right mt-3">
																	<input id="post_chat_form" name="post_chat_form" checked={this.state.post_chat_form === true ? true : false} onChange={this.handleChange} type="checkbox" />
																	<label htmlFor="post_chat_form" className="label-default"></label>
																</div>
															</div>
														</div>

														<div className="chat-feilds mx-8 mob-mx-4 mob-mr-4 py-5 ">
															<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledPostClass}`}>
																<span className="main-checkbox-all"><label className="containerRadio containerafterchecked">
																	<input type="radio" name="post_chat_response_type" value="thank_you_message" checked={this.state.post_chat_response_type === 'thank_you_message' ? true : false} onChange={this.handleChange} disabled={this.state.post_chat_form ? false : true} />
																	<span className="radioCheckmark" /></label></span>
																<h4 className="heding-text mt-n3">Show thank you message</h4>
															</div>
															<div className="form-feilds">
																<div className="input-group material-input mt-5">
																	{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="thank_you_message" onChange={this.handleChange} value={this.state.thank_you_message} readOnly={this.state.post_chat_form ? false : true} multiline />) :
																		(<TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" name="thank_you_message" onChange={this.handleChange} value={this.state.thank_you_message} readOnly={this.state.post_chat_form ? false : true} multiline />)}
																	{/* <textarea type="text" placeholder="Name*" className="w-100 py-3" name="thank_you_message" value={this.state.thank_you_message} rows="4" onChange={this.handleChange} readOnly={this.state.post_chat_form ? false : true}></textarea> */}
																	<p className="error">{this.state.thank_you_message_error}</p>
																</div>
															</div>
														</div>
														<div className="chat-feilds mx-8 mob-mx-4 mob-mr-4 py-5 ">
															<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledPostClass}`}>
																<span className="main-checkbox-all"><label className="containerRadio containerafterchecked">
																	<input type="radio" name="post_chat_response_type" value="post_chat_feedback" checked={this.state.post_chat_response_type === 'post_chat_feedback' ? true : false} onChange={this.handleChange} disabled={this.state.post_chat_form ? false : true} />
																	<span className="radioCheckmark" /></label></span>
																<h4 className="heding-text mt-n3">Collect Feedback</h4>
															</div>
															<div className="form-feilds">
																<div className="input-group material-input mt-5">
																	{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" label="Feedback Message" name="feedback_message" onChange={this.handleChange} value={this.state.feedback_message ? this.state.feedback_message : ""} readOnly={this.state.post_chat_form ? false : true} multiline />) :
																		(<TextField className="form-control w-100" rows={4} id="outlined-multiline" variant="outlined" label="Feedback Message" name="feedback_message" onChange={this.handleChange} value={this.state.feedback_message ? this.state.feedback_message : ""} readOnly={this.state.post_chat_form ? false : true} multiline />)}
																	{/* <p className="card-body-text">Feedback Message</p>
                                                        <textarea type="text" placeholder="Name*" value={this.state.feedback_message} className="w-100 py-3" name="feedback_message" rows="4" onChange={this.handleChange} readOnly={this.state.post_chat_form ? false : true}></textarea> */}
																	<p className="error">{this.state.feedback_message_error}</p>
																</div>
															</div>
															<div className="form-feilds">
																<div className="wrapperInfo mt-4">
																	<label className="mb-1 small text-screen1 active">Feedback Type</label>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../../assets/img/icon/info.png')} data-tip="Once the visitor clicks on the feedback button, their feedback will be captured." />
																	</span>
																	<div className="material-select mt-2">
																		<FormControl variant="outlined" className="material-outlined w-100">
																			<InputLabel id="demo-simple-select-outlined-label">Feedback Type</InputLabel>
																			<Select MenuProps={{ disableScrollLock: true }} labelId="demo-simple-select-outlined-label" id="feedback_type" value={this.state.feedback_type ? this.state.feedback_type : ""} name="feedback_type" disabled={this.state.post_chat_form ? false : true} onChange={this.handleChange} label="Feedback Type">
																				<MenuItem value="0">Rating Scale</MenuItem>
																				<MenuItem value="1">Thumbs Up / Thumbs Down</MenuItem>
																			</Select>
																		</FormControl>
																	</div>
																	{/* <select type="text" placeholder="Get Query" value={this.state.feedback_type} className="w-100 customArrow " name="feedback_type" onChange={this.handleChange} rows="4" disabled={this.state.post_chat_form ? false : true}>
                                                            <option value="0">Rating Scale</option>
                                                            <option value="1">Thumbs Up/ Thumbs Down</option>
                                                        </select> */}
																	<div className='settings-feld-post form-feilds k-flex align-items-center mt-5'>
																		<h5 className='card-title mb-0 mr-4'>Collect different feedback for product and service.</h5>
																		<div className="material-switch pull-right mt-3">
																			<input id="multi_feedback_switch" name="multi_feedback_switch" checked={this.state.multi_feedback_switch === true ? true : false} onChange={this.handleChange} type="checkbox" />
																			<label htmlFor="multi_feedback_switch" className="label-default"></label>
																		</div>
																	</div>
																</div>
															</div>
															<div className="chat-feilds pt-5">
																<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledClass}`}>
																	<span className="main-checkbox-all"><label className="containerCheckBox containerafterchecked">
																		<input type="checkbox" name="show_feedback_comment" checked={this.state.show_feedback_comment === true ? true : false} onChange={this.handleChange} defaultValue="true" disabled={this.state.pre_chat_form ? false : true} />
																		<span className="checkmark" /></label></span>
																	<h6 className="card-title mb-0">Feedback Comment</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../../assets/img/icon/info.png')} data-tip="Once the visitor clicks on the feedback button, the comment box will open with a Submit button. On pressing the Submit button, the feedback comment will be captured." />
																	</span>
																</div>
																<div className="form-feilds">
																	<div className="input-group material-input">
																		{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="feedback_comment_label" variant="outlined" name="feedback_comment_label" onChange={this.handleChange} value={this.state.feedback_comment_label} readOnly={this.state.post_chat_form ? false : true} multiline />) :
																			(<TextField className="form-control w-100" rows={4} id="feedback_comment_label" variant="outlined" name="feedback_comment_label" onChange={this.handleChange} value={this.state.feedback_comment_label} readOnly={this.state.post_chat_form ? false : true} multiline />)}
																		{/* <textarea type="text" placeholder={this.state.feedback_comment_label} value={this.state.feedback_comment_label} className="w-100 py-3" name="feedback_comment_label" rows="4" onChange={this.handleChange} readOnly={this.state.pre_chat_form ? false : true}></textarea> */}
																		<p className="error">{this.state.feedback_comment_label_error}</p>
																	</div>
																</div>
															</div>

															<div className="form-feilds pt-5">
																<div className="input-group material-input">
																	{this.state.defaultLanguage === 'ar' ? (<TextField dir="rtl" lang="ar" className="form-control w-100" rows={4} id="feedback_response" variant="outlined" name="feedback_response" onChange={this.handleChange} value={this.state.feedback_response} readOnly={this.state.post_chat_form ? false : true} multiline />) :
																		(<TextField className="form-control w-100" rows={4} id="feedback_response" variant="outlined" name="feedback_response" onChange={this.handleChange} value={this.state.feedback_response} readOnly={this.state.post_chat_form ? false : true} multiline />)}
																	{/* <p className="card-body-text">Feedback Response</p>
                                                        <textarea type="text" placeholder="Name*" value={this.state.feedback_response} className="w-100 py-3" name="feedback_response" rows="4" onChange={this.handleChange} readOnly={this.state.post_chat_form ? false : true}></textarea> */}
																	<p className="error">{this.state.feedback_response_error}</p>
																</div>
															</div>
														</div>
														<div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed-RightPreview">
															<div className=" text-center k-flex align-items-center">
																<button className="btnBlueSettings">Save changes</button>&nbsp;&nbsp;
																<button className="btnBorderSettings" onClick={this.cancelForm}>Cancel</button>&nbsp;&nbsp;
																<button className="btnRestore ml-auto" onClick={this.restoreDefault}>Restore Default</button>
															</div>
														</div>
													</form>
												</div>)}

												{/* <div className="perfect Feilds mx-8 mob-mx-4" style={{ paddingBottom: this.state.form_type === "2" ? "0px" : "8px" }}>
                                                    <div className="settings-form-feilds">
                                                        <div className="settings-feld form-feilds k-flex align-items-center mb-4 mt-6">
                                                            <span className="main-checkbox-all"><label className="containerRadio containerafterchecked"><input type="radio" name="form_type" value="1" onChange={() => { this.setState({ form_type: "2" }) }} checked={this.state.form_type === "2" ? true : false} /><span className="radioCheckmark" /></label></span>
                                                            <h4 className="heding-text mt-n3">Use chatbot</h4>
                                                        </div>
                                                    </div>
                                                </div> */}
												{this.state.form_type === "2" ? (<div className="chat-forems-section">
													<form onSubmit={this.handleSubmit}>
														<div className="chat-feilds mx-8 py-5 borderBottom mob-mx-4">
															<div className="chat-feilds">
																<div className={`settings-feld form-feilds k-flex align-items-center mb-3 ${disabledClass}`}>
																	<h6 className="card-title mb-0">Select your chatbot</h6>
																</div>
																<div className="form-feilds">
																	<div className="material-select">
																		<FormControl variant="outlined" className="material-outlined w-100">
																			<InputLabel id="demo-simple-select-outlined-label">Chatbot</InputLabel>
																			<Select MenuProps={{ disableScrollLock: true }} labelId="demo-simple-select-outlined-label" id="chatbotId" name="chatbotId" onChange={this.handleChange} value={this.state.chatbotId ? this.state.chatbotId : ""} label="Chatbot">
																				<MenuItem value="">Select</MenuItem>
																				{Object.keys(this.state.chatbotsList).length > 0 ? this.state.chatbotsList.map((val, i) => {
																					if (parseInt(val.botType) === 2) {
																						return <MenuItem key={i} value={val.id}>{val.name}</MenuItem>
																					}
																				}) : (null)}
																				<MenuItem value="openChatBot">+ Create new chatbot for livechat</MenuItem>
																			</Select>
																		</FormControl>
																		{/* <select type="text" placeholder="Get Query" value={this.state.chatbotId} className="w-100 customArrow " name="chatbotId" onChange={this.handleChange} rows="4" >
                                                            <option value="">Select</option>
                                                            {Object.keys(this.state.chatbotsList).length > 0  ? this.state.chatbotsList.map((val, i) => {
                                                                return <option key={i} value={val.id}>{val.name}</option>
                                                            }) : (null)}
                                                            <option value="openChatBot">+ Create new chatbot for livechat</option>
                                                        </select> */}
																		<p className="error">{this.state.chatbotId_error}</p>
																	</div>
																</div>
															</div>
														</div>
														<div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed-RightPreview">
															<div className=" text-center k-flex align-items-center">
																<button className="btnBlueSettings">Save changes</button>&nbsp;&nbsp;
																<button className="btnBorderSettings" onClick={this.cancelForm}>Cancel</button>&nbsp;&nbsp;
																<button className="btnRestore ml-auto" onClick={this.restoreDefault}>Restore Default</button>
															</div>
														</div>
													</form> </div>) : (null)
												}
											</div>
										</div>
									</div>
									<span className="widgetDeskDesignRight" onClick={this.rightWidgetMobileHide} id="widgeHSIcon">
										<svg className="tide-icon " width="8" height="8" viewBox="0 0 14 24"><path d="M12.967 24c-0.241 0-0.482-0.086-0.674-0.263l-11.962-10.994c-0.206-0.189-0.324-0.457-0.324-0.737s0.118-0.547 0.324-0.737l11.962-10.994c0.407-0.373 1.038-0.345 1.409 0.061s0.344 1.039-0.061 1.412l-11.161 10.257 11.161 10.258c0.406 0.372 0.433 1.005 0.061 1.412-0.197 0.215-0.466 0.323-0.736 0.323z"></path></svg>
									</span>
									<div className="widget-seetings-right" id="deskWidgetRightP">
										<div className="bg-white">
											<div className="chatforms-rightPanel-Phone w-100P position-relative">
												<div className="chat-form-widget bg-black px-5 py-4">
													<div className="k-flex align-items-center">
														<h4 className="heding-text white-text">Preview</h4>
														{this.state.form_type === "2" ? (null) : (<div className="form-feilds mr-auto ml-4">
															<div className="material-select color-change">
																<FormControl variant="outlined" className="material-outlined w-150">
																	<InputLabel id="demo-simple-select-outlined-label">Chatbot</InputLabel>
																	<Select MenuProps={{ disableScrollLock: true }} labelId="demo-simple-select-outlined-label" id="current_preview" name="current_preview" value={this.state.current_preview ? this.state.current_preview : ""} onChange={this.changePreview} label="Chatbot">
																		<MenuItem value="online">When Online</MenuItem>
																		<MenuItem value="offline">When Offline</MenuItem>
																		<MenuItem value="thank_you_message">Thank You Message</MenuItem>
																		<MenuItem value="feedback">Feedback</MenuItem>
																	</Select>
																</FormControl>
																{/* <select type="text" placeholder="Get Query" onChange={this.changePreview} className="customArrow" name="current_preview" value={this.state.current_preview} style={{width: '150px', height: '30px', borderRadius: '4px'}}>
                                                            <option value="online">When Online</option>
                                                            <option value="offline">When Offline</option>
                                                            <option value="thank_you_message">Thank You Message</option>
                                                            <option value="feedback">Feedback</option>
                                                        </select> */}
															</div>
														</div>)}
													</div>
												</div>
												<div className="mixListingPreview">
													<ul>
														<li className={this.state.current_view === 'desktop' ? 'actives' : ''} onClick={() => this.changeView('desktop')} type="desktop"><span className="device desktop-device"><img src="https://chatbot.appypie.com/dist/img/public/img/desktop-monitor.svg" alt="" /></span></li>
														<li className={this.state.current_view === 'mobile' ? 'actives' : ''} onClick={() => this.changeView('mobile')} type="mobile"><span className="device phone-device"><img src="https://chatbot.appypie.com/dist/img/public/img/smartphone.svg" alt="" /></span></li>
													</ul>
												</div>
												<ChatBotPreview view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<OnlineDesktop view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<OnlineMobile view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<OfflineDesktop view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<OfflineMobile view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<FeedBackDesktop view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
												<FeedBackMobile view={this.state.current_view} preview={this.state.current_preview} values={this.state} />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div> : <PopUp />}
			</>
		);
	}
}

export default withRouter(Chatform);