import React from 'react';
import LeftPanel from '../views/LeftPanel';
// import db from '../../../config/firebase';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip"; 
import { activityLog } from '../../../services/service';
import { getOwnerSnapShot } from '../../../services/payment';
import PopUp from '../../../layout/components/popup';
import firebaseServices from '../../../firebase';
const dateFormat="X";

class EmailPreference extends React.Component {
    constructor(){
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        if(this.Agent.roleType!=='Owner') {
            window.location.href = "/"+this.Agent.ownerIdentifire;
        }
        this.state = {
            loader:false,
            incoming_picked_email:false,
            payment_status:true
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        this.emailPrefrence();
        activityLog('Opened email preference settings');
    }

    emailPrefrence = () => {
        this.setState({
            loader:true
        })
        if(this.Agent.ownerId) {
            firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('settings').doc('EMAILPREFERENCE').get().then((docs) => {
                if(docs.exists) {
                    let _data = docs.data();
                    this.setState({
                        incoming_picked_email:_data.incoming_picked_email===true ? true : false,
                        loader:false
                    })
                } else {
                    this.setState({
                        incoming_picked_email:false,
                        loader:false
                    })
                }
            }).catch(() => {
                toast.error("Something went wrong, Please try after some time!");
            })
        }
    }

    handleChange = (e) => {
        let target = e.target;
        if(target.type==="checkbox") {
            this.setState({
                [target.name]:target.checked
            })
        } else {
            this.setState({
                [target.name]:target.value
            })
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.saveForm();
    }

    saveForm = () => {
        this.setState({
            loader:true
        })
        firebaseServices.db.collection('users').doc(this.Agent.ownerId).collection('settings').doc('EMAILPREFERENCE').set({
            incoming_picked_email:this.state.incoming_picked_email
        }).then((res) => {
            this.setState({
                loader:false
            })
            toast.success("Email Preferences have been updated successfully!");
            activityLog('Saved email preference settings');
        }).catch(() => {
            toast.error("Something went wrong, please try again after some time!");
        })
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.emailPrefrence();
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }
    
    render(){
        return (
            <>
            <ReactTooltip />
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.cancelForm}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
            {this.state.payment_status ? <div className="page-wrapper sidebar-collapse" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                    <div className="bg-white left-widgetsetings-box mb-foter-Fixed">
                                        <div className="setting-section idle-section all-setting-bg-section-feilds pb-1">
                                        <form onSubmit={this.handleSubmit}>
                                            <div className="mx-8 mob-mx-4">
                                                <div className="main-heading main-heading-title py-5">
                                                    <h3 className="heding-text">Email Preference <span className="info-icon">
                                                        <img src={ require('../../../assets/img/icon/info.png') } data-tip="Any changes made here won't be reflected at agent's end till they either refresh browser window or re-login. So it would be preferable if you make such changes when no agent is online." />
                                                    </span></h3>
                                                </div>
                                            </div>
                                            <div className="settings-form-feilds mx-8 py-4 px-5">
                                                <div className="settings-feld form-feilds k-flex align-items-center mb-0">
                                                    <div className="material-switch pull-right mt-3 mr-4">
                                                    <input id="pre_chat_form" type="checkbox" name="incoming_picked_email" onChange={this.handleChange} value={this.state.incoming_picked_email} checked={this.state.incoming_picked_email} />
                                                        <label htmlFor="pre_chat_form" className="label-default" />
                                                    </div>
                                                    <h5 className="card-title mb-0">Send an email to owner when agent picked an incoming chat.</h5>
                                                    {/* <span className="info-icon">
                                                        &nbsp;<img src={ require('../../../assets/img/icon/info.png') } data-tip="Ongoing chat session will end for both visitor and agent. The visitor won't be able to continue it but will be able to start a new chat." />
                                                    </span> */}
                                                </div>
                                            </div>
                                            <div className="chat-feilds py-6 px-8 btn-chat-feilds px-5 setting-footer-fixed aaaa">
                                                <div className="text-center k-flex align-items-center">
                                                    <button className="btnBlueSettings mr-2">Save changes</button>
                                                    <button className="btnBorderSettings mr-2" onClick={this.cancelForm}>Cancel</button>
                                                    {/* <button className="btnRestore ml-auto" onClick={this.restoreDefault}>Restore Default</button> */}
                                                </div>
                                            </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    
                </div>
            </div> : <PopUp />}
        </>
        )
    }

}

export default EmailPreference;