import React from 'react';
const CHATBOTURL = "https://chatbot.appypie.com"

class chatBotPreview extends React.Component {
    constructor(props) {
        super();
        this.state = {
            show:true
        }
    }

    render () {
        let currentScreen = '';
        if(this.props.values.form_type === "2" && this.props.values.chatbotId !== "" && this.props.values.chatbotId!== "openChatBot") {
            let namePlaceholder=this.props.values.online_name_label;
            if(this.props.values.online_name_required===true){
                namePlaceholder += ' *';
            }
            let emailPlaceholder=this.props.values.online_email_label;
            if(this.props.values.online_email_required===true){
                emailPlaceholder += ' *';
            }
            let contactPlaceholder=this.props.values.online_contact_label;
            if(this.props.values.online_contact_required===true){
                contactPlaceholder += ' *';
            }
            let queryPlaceholder=this.props.values.online_query_label;
            if(this.props.values.online_query_required===true){
                queryPlaceholder += ' *';
            }
            let iframeUrl = CHATBOTURL+"/mobilebot.html?cid="+this.props.values.chatbotUserId+"&botid="+this.props.values.chatbotId+"&agent="+this.props.values.chatbotAgentId+"&output=embed&preview=true";
            currentScreen = <div className="screen position-relative mr-auto screen-chatsetting" id="chatbotformpreview">

            <iframe 
              width="100%" 
              height="500px" 
              src={iframeUrl}
              frameBorder="0" 
              allow="autoplay; encrypted-media" 
              className="video__iframe"
            />
            
        </div>
        }   

        return (
            <>
            {currentScreen}
            </>
        )
    }
}

export default chatBotPreview;