import React, { PureComponent } from 'react';
// import db from '../../../../config/firebase';
import ChatContext from '../../components/ChatContext';
import firebaseServices from '../../../../firebase';

class WidgetInstallationInfo extends PureComponent {

    static contextType = ChatContext;  // this.context will work when it defined as is
    constructor(props, context) {
        super(props);
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {}
    }

    /**
     * component Did mount
     */
    componentDidMount() {
        const uid = this.agent.ownerId
        firebaseServices.db.collection('users').doc(`${uid}`).get()
            .then(userDocumentSnapshot => {
                if (userDocumentSnapshot.exists) {
                    if (userDocumentSnapshot.data().widget_status) {
                        this.context.updateGlobalData('widgetVerify', true);
                    }
                }
                try {
                    document.getElementById("widgetinstallinfoLoader").style.display = "none";
                } catch (eee) { }

            }).catch(err => {
                try {
                    document.getElementById("widgetinstallinfoLoader").style.display = "none";
                } catch (eee) { }
            });
    }

    render() {
        return (
            <React.Fragment>
                <div id="widgetinstallinfoLoader" className="loading">&#8230;</div>
                <div className="chat-body position-relative">
                    <div className="verificationCodeSection">
                        <div className="verificationCodeSection bg-white">
                            <div className="custmize-your-widget custimize-livechat pos-fixed">
                                <div className="install-chat">
                                    <div className="chat-screen text-center pb-5">
                                        <img src={require('../../../../assets/images/instal-chat.svg')} />
                                    </div>
                                    <div className="chat-screen-details text-center">
                                        <h4 className="card-title">Widget has not been added on any website. Please ask owner to do the needful.</h4>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default WidgetInstallationInfo;