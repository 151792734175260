
import React from 'react';
// import db from "../config/firebase";
import moment from "moment-timezone";
import { userLogout, activityLog } from '../services/service';
import { MAIL_FUNCTION_URL, CLOSE_INACTIVE_CHATS_FROM,GATEWAY_URL,FUNCTION_API_KEY } from '../../src/config/siteConfig';
import { saveAgentSession } from "../modules/livechat/components/Comman";
import { logedOutCustomToken } from "../config/SignupLogin";
import firebaseServices from '../firebase';
const dateFormat='X';

class Logout extends React.Component {

    async componentDidMount() {
    setTimeout(async () => {
        if(localStorage.getItem('agent') != null)
        { 
            localStorage.removeItem('login_type');
            localStorage.removeItem('headerStrip')
            try {
                localStorage.removeItem('chatTranslator');
                localStorage.removeItem('changeTextLangArr');
            } catch(erorrr) {
                console.log("Error in removing localstorage data of Translator while logout");
            }

            let userDetails=JSON.parse(localStorage.getItem('agent'));
            if(userDetails.roleType !== 'Owner') {
                await userLogout();
                var domain = 'appypie.com';
                var expiry = 'Thu, 01 Jan 1970 00:00:01 GMT';
                document.cookie = "61be0d8c3c195df8308def9fac389355=0;expires="+ expiry +";path=/;domain="+domain;
            }
            let ownerId = userDetails.ownerId;
            let agentId = userDetails.agentId;
            let ownerIdentifire = userDetails.ownerIdentifire;

            /* Add Agent session start */
            saveAgentSession(userDetails, "logout");
            /* Add Agent session end */ 

            /* For extra security sign in with token start */
            await logedOutCustomToken();
            /* For extra security sign in with token end */

            ////// Saving all inactive chats for automatically flushing Start ////////////
            if(CLOSE_INACTIVE_CHATS_FROM === "all" || CLOSE_INACTIVE_CHATS_FROM === "logout") {
                try {
                    var body = [`ownerId=${ownerId}&agentId=${agentId}`];
                    ///fetch(MAIL_FUNCTION_URL+"/app/closeinactivechats", {
                    fetch(GATEWAY_URL+"/closeinactivechats", {
                        method: "post",
                        body:body,
                        headers: {
                            "X-API-Key" : FUNCTION_API_KEY,
                            "Content-Type": "application/x-www-form-urlencoded",
                            "Accept" : "application/json",
                            "Origin": window.location.origin
                        }
                    })
                    .then(res => res.json())
                    .then(response => { }).catch(e => {});
                } catch(err) {}
            }
            ////// Saving all inactive chats for automatically flushing End ////////////

            let logOutAt=this.timeZoneFormat(Date.now());
            /* commented logs on 26-03-2021 start */
            // firebaseServices.db.collection('users').doc(ownerId).collection('agents').doc(agentId).collection('logs').doc(logOutAt.toString()).set({online_status:1,time:logOutAt,ref:'logout'}).then((docs) => {
                
            // });
            /* commented logs on 26-03-2021 ends */
            firebaseServices.db.collection("users").doc(ownerId).collection("agents").doc(agentId).update({online_status:0}).then(async (ref) => {
                await activityLog('Logout');
                localStorage.removeItem('agent');
                localStorage.removeItem('accessToken');
                localStorage.removeItem('agentData');
                localStorage.removeItem('common_redirect');
                localStorage.removeItem('current_login');
                localStorage.removeItem('companyName');
                localStorage.removeItem('owner_app_access');
                localStorage.removeItem('usernotexist');
                try {
                    localStorage.removeItem('chatTranslator');
                    localStorage.removeItem('changeTextLangArr');
                } catch(erorrr) {
                    console.log("Error in removing localstorage data of Translator while logout");
                }
                window.location.href = "/"+ownerIdentifire+"/login";
            })
        }
        else
        {
            await activityLog('Logout');
            localStorage.removeItem('login_type');
            localStorage.removeItem('agent');
            localStorage.removeItem('headerStrip')
            localStorage.removeItem('agentData');
            localStorage.removeItem('common_redirect');
            localStorage.removeItem('current_login');
            localStorage.removeItem('companyName');
            localStorage.removeItem('owner_app_access');
            localStorage.removeItem('usernotexist');
            try {
                localStorage.removeItem('chatTranslator');
                localStorage.removeItem('changeTextLangArr');
            } catch(erorrr) {
                console.log("Error in removing localstorage data of Translator while logout");
            }
            window.location.href = "/company-info";
        }
        }, 500)
    }

    timeZoneFormat = (date) => {
        var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
        todayUTCDate = parseInt(todayUTCDate);
        return todayUTCDate;
    }

    render() {
        return <>
            <div className="loading" style={{display: 'block' }}>Loading&#8230;</div>;
        </>
    }
}

export default Logout;
