import React, { PureComponent } from 'react';
import Header from './header';
import Chat from './chat';
import Footer from './footer';
import ChatContext from '../../components/ChatContext';

class Livechat extends PureComponent {
    static contextType = ChatContext;  // this.context will work when it defined as is

    // To use global data pass second parameter context in constructor
    constructor(props, context) {
        super(props);
        this.state = {
            notificationStatus : "granted",
            isClosed : false,
            microphoneAccess : false,
            closeMicrophoneNotification : true,
        };
        
    }

    componentDidMount = () => {
        this.chkIsNotBlocked();
        if (this.context.isMobileDevice) {
            document.getElementById('calling_incoming').classList.add('mob_calling_inc');
        }
    }

    chkIsNotBlocked = () => {
        if (!("Notification" in window)) {
            this.setState({ notificationStatus: "not_supported" });
        } else if (Notification.permission === "denied") {
            this.setState({ notificationStatus: "blocked" });
        } else if (Notification.permission !== "denied" || Notification.permission === "default") {
            this.setState({ notificationStatus: "default" });
            // Otherwise, we need to ask the user for permission
            Notification.requestPermission( permission => {
                // If the user accepts, let's create a notification
                if (permission === "denied") {
                    this.setState({ notificationStatus: "blocked" });
                } else {
                    this.setState({ notificationStatus: "granted" });
                }
            });
        }
    }

    //check for microphone access
    checkForMicAccess = () => {
        // if available dont ask for permission if not ask for permission
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ audio: true })

                .then(function(stream) {
                    // permission granted
                    this.setState({ microphoneAccess: true , closeMicrophoneNotification : false});
                })
                .catch(function(err) {
                    // permission denied
                    this.setState({ microphoneAccess: false });
                });
        }
    }




    closeNotification = () => {
        this.setState({ isClosed : true });
    }

    closeMicrophoneNotification = () => {   
        this.setState({ closeMicrophoneNotification : true });
    }

    closeRightSection = () => {
        this.context.updateGlobalData("rightSectionItemToShow", "visitorslist");
    }

    render() {
        return (
            <div className="chat-body position-relative">
                {this.context.goneOffline ? (<div className="re-connecting">
                    <div className="noInternetConnection text-center">
                        <p className="card-body-text" style={{ color: '#000' }}>Internet connection not available</p>
                    </div>
                </div>) : (null)}

                {(this.state.notificationStatus === "blocked" && !this.state.isClosed) ? (<div className="re-connecting notifBlock">
                    <div className="noInternetConnection text-center notif-block-dis">
                        <p className="card-body-text " style={{ color: '#000' }}>Desktop notification for livechat is disabled. To receive desktop notifications please configure your browser settings.</p>

                    </div>
                    <span onClick={this.closeNotification} className="px-2 ml-auto cursorPointer">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14.141" height="14.142" viewBox="0 0 14.141 14.142">
                            <g id="Group_1639" data-name="Group 1639" transform="translate(-5.303 7.071) rotate(-45)">
                                <rect id="Rectangle_1271" data-name="Rectangle 1271" width="2.5" height="17.499" rx="1.25" transform="translate(7.5)" />
                                <rect id="Rectangle_1272" data-name="Rectangle 1272" width="2.5" height="17.499" rx="1.25" transform="translate(17.499 7.5) rotate(90)" />
                            </g>
                        </svg>
                    </span>
                </div>) : (null)}

                <Header />
                <Chat />
                <Footer />

                {/* For Incoming Call alert start */}

                <div id="calling_incoming" className="popupInstallLivechat calling-incoming" style={{ display: "none" }}>
                    <div className="inner-calling-inc">
                        <div className="popupBody pt-5 px-5 text-center">
                            <div className="user-caling-display my-5 py-2">
                                <div className="call-animation">
                                    <img className="img-circle" src={require('../../../../assets/images/boy.png')} alt="" />
                                </div>
                            </div>
                            <div className="py-5 feilds-instruction">
                                <h4 className="card-title mb-0">Incoming Call</h4>
                                <p id="calling_from_number" className="card-body-text">+91-9999999999</p>
                            </div>
                        </div>

                        <div className="footer-calling-popup text-center pb-5 mb-5">
                            <button id="calling_accept_call" onClick={this.closeRightSection} className="btn-calling btn-accept">
                                <span className="mr-3">
                                    <svg id="telephone" xmlns="http://www.w3.org/2000/svg" width="22.94" height="22.94" viewBox="0 0 31.94 31.94">
                                        <g id="Group_4586" data-name="Group 4586" transform="translate(15.97 8.517)">
                                            <g id="Group_4585" data-name="Group 4585">
                                                <path id="Path_2554" data-name="Path 2554" d="M241.065,128a1.065,1.065,0,0,0,0,2.129,4.263,4.263,0,0,1,4.259,4.259,1.065,1.065,0,1,0,2.129,0A6.4,6.4,0,0,0,241.065,128Z" transform="translate(-240 -128)" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="Group_4588" data-name="Group 4588" transform="translate(15.97 4.259)">
                                            <g id="Group_4587" data-name="Group 4587">
                                                <path id="Path_2555" data-name="Path 2555" d="M241.065,64a1.065,1.065,0,1,0,0,2.129,8.526,8.526,0,0,1,8.517,8.517,1.065,1.065,0,0,0,2.129,0A10.659,10.659,0,0,0,241.065,64Z" transform="translate(-240 -64)" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="Group_4590" data-name="Group 4590" transform="translate(15.97)">
                                            <g id="Group_4589" data-name="Group 4589">
                                                <path id="Path_2556" data-name="Path 2556" d="M241.065,0a1.065,1.065,0,0,0,0,2.129,12.789,12.789,0,0,1,12.776,12.776,1.065,1.065,0,1,0,2.129,0A14.923,14.923,0,0,0,241.065,0Z" transform="translate(-240 0)" fill="#fff" />
                                            </g>
                                        </g>
                                        <g id="Group_4592" data-name="Group 4592" transform="translate(0 2.129)">
                                            <g id="Group_4591" data-name="Group 4591">
                                                <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>
                                </span>Accept</button>
                            <button id="calling_reject_call" className="btn-calling btn-reject ml-3">
                                <span className="mr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30.153" height="30.153" viewBox="0 0 42.153 42.153">
                                        <g id="Group_4592" data-name="Group 4592" transform="matrix(-0.695, 0.719, -0.719, -0.695, 42.153, 20.709)">
                                            <g id="Group_4591" data-name="Group 4591">
                                                <path id="Path_2557" data-name="Path 2557" d="M28.746,53.294a19.763,19.763,0,0,1-8.752-2,1.045,1.045,0,0,0-.816-.049,1.074,1.074,0,0,0-.611.543l-1.674,3.464A26.779,26.779,0,0,1,6.563,44.921l3.462-1.678a1.063,1.063,0,0,0,.492-1.427,19.763,19.763,0,0,1-2-8.752A1.065,1.065,0,0,0,7.453,32H1.065A1.065,1.065,0,0,0,0,33.065,28.779,28.779,0,0,0,28.746,61.811a1.065,1.065,0,0,0,1.065-1.065V54.358A1.065,1.065,0,0,0,28.746,53.294Z" transform="translate(0 -32)" fill="#fff" />
                                            </g>
                                        </g>
                                    </svg>

                                </span>Reject</button>
                        </div>
                    </div>
                </div>
                {/* For Incoming Call alert end */}

            </div>  
        );
    }
}

export default Livechat;