import React, { Component, useEffect } from 'react';
// import db from '../../config/firebase';
import { GATEWAY_URL, FUNCTION_API_KEY, PAYMENT_CHECKOUT_URL } from '../../config/siteConfig';
import firebaseServices from '../../firebase';



const ClaimTrial = (props) => {
    const [requestKey, setRequestKey] = React.useState('');
    const [loader, setLoader] = React.useState(true);

    useEffect(() => {
        if (localStorage.getItem('agent') === null) {
            localStorage.setItem('redirect_to', window.location.pathname);
            props.history.push('/login');
        } else {

            let agentData = JSON.parse(localStorage.getItem('agent'));
            console.log(agentData)
            let data_exists = firebaseServices.db.collection('users').doc(agentData.ownerId).get().then((doc) => {
                if (doc.exists) {
                    let data = doc.data();
                    if (data.payment_status === undefined) {
                        // write body into json
                        var body = {
                            "action": "encrypt", "no_of_agents": 0, "netprice": 0, "currency": "$", "plan_name": "Premium", "plan_id": "6", "product_name": "livechat", "plan_period": "yearly", "order_id": "livechat_169337853240366251", "product_id": "169337607633493227", "countryCode": "us", "originalCountryCode": "US", "currencyCode": "USD",
                            stringdata: JSON.stringify({
                                displayMessage: "myArray",
                                curentPlanId: "",
                                currentPlanPeriod: "",
                                orderId: "livechat_" + new Date().getTime(),
                                addonType: 0,
                                noOfAgents: 0,
                                productName: "livechat",
                                planId: "6",
                                planPeriod: "yearly",
                                emailId: agentData.ownerEmail,
                                productId: agentData.ownerId,
                                paymentType: "trialWithoutCC",
                                trialDays: 7,
                                subscriptionType: "subscription",
                                successUrl: `https://chat.appypie.com/${data.company_name}/install-widget?type=success&ptype=trial&cpId=`,
                                cancelUrl: `https://chat.appypie.com/${data.company_name}/paymentfail`,
                                notifyUrl: "https://livechat-gateway-cavg9c0h.uc.gateway.dev/subscription",
                                resetUrl: `https://chat.appypie.com/${data.company_name}/install-widget?type=success&ptype=trial&cpId=`,
                                currencyCode: "USD",
                                countryCode: "us",
                                originalCountryCode: "US",
                                requestState: "",
                                displayAgent: true,
                                trialPaymentMethod: "stripe"
                            })

                        }
                        getRequestKey(body).then((requestResponse) => {
                            if (requestResponse === true) {
                                var form = document.getElementById('checkout');
                                form.submit();
                            } else {
                                console.log("error")
                            }
                        })
                    } else {
                        props.history.push(`/${agentData.ownerIdentifire}`);
                    }
                }
            })
        }
    }, [])



    const getRequestKey = async (requestData) => {
        return new Promise(async (resolve, reject) => {
            //const response = await fetch(MAIL_FUNCTION_URL + "/payment/generateKey", {
            const response = await fetch(GATEWAY_URL + "/generateKey", {
                method: "post",
                body: JSON.stringify(requestData),
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    Accept: 'application/x-www-form-urlencoded',
                    'Content-Type': 'application/json'
                }
            }).then((response) => response.json())
                .then((responseJson) => {
                    if (responseJson.status === 200) {
                        console.log(responseJson.stringdata)

                        setRequestKey(responseJson.stringdata)

                        resolve(true)
                    } else {

                        resolve(false)
                    }
                });
            resolve(response);
        })
    }


    return (
        //loader
        <div className="loader" style={{ display: loader ? 'block' : 'none' }}>
            <div className="loader_inner">
                {/* <img src={} alt="loader" /> */}
            </div>
            <form name="checkout" id="checkout" method="POST" action={PAYMENT_CHECKOUT_URL + "/index/pay"}>
                <input type="hidden" name="request" value={requestKey} />
            </form>
        </div>
        //loader
    )
}

export default ClaimTrial;



