import React, { Component } from 'react';
import { DOMAIN_NAME, COMMON_LOGIN_URL } from '../../config/siteConfig';
import { getClientIpAndCountry } from './Model/DbModel';
import { widgetImage } from "./badgeIconConfig";

class AutoCompanyInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            companyName: ''
        }
    }

    componentDidMount = async () => {
        this.setState({ loader: true });
        getClientIpAndCountry();
        const companyName = localStorage.getItem('companyName')
        if(companyName){
            localStorage.setItem('companyName', companyName);
        } else {
            let company_name = this.getCompanyInfo(20);
            localStorage.setItem('companyName', company_name);
        }

        const rgbColor = { r: 13, g: 132, b: 246, a: 1 };
        const bgColor = "#0d84f6ff";
        localStorage.setItem('settingrgbColor', rgbColor);
        localStorage.setItem('settingbgColor', bgColor);
        localStorage.setItem('clientBackgroundColor', bgColor);
        localStorage.setItem('chatBlackImage', widgetImage.blackImage);
        localStorage.setItem('whiteImage', widgetImage.wtimage);
        localStorage.setItem('fbwtimage', widgetImage.fbwtimage);
        localStorage.setItem('sendButtonClr', bgColor);
        window.location.href = COMMON_LOGIN_URL + "/register?frompage=" + DOMAIN_NAME + "/common-session&website=" + DOMAIN_NAME;
        //this.setState({ loader: false });
    }

    getCompanyInfo = (length) => {
        var result = '';
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    render() {
        return (<>
            <div className="loading" style={{ display: this.state.loader ? 'block' : 'block' }}>Loading&#8230;</div>
        </>);
    }
}

export default AutoCompanyInfo;
