// import db from "../../../config/firebase";
import lf from 'lovefield';
import firebaseServices from "../../../firebase";
const schemaBuilder = lf.schema.create("livechat", 3.1); //lf.schema.create('DBName, version);
// console.log("schemaBuilder", schemaBuilder);


schemaBuilder.createTable("visitors")
    .addColumn('id', lf.Type.STRING)
    .addColumn('chat_id', lf.Type.STRING)
    .addColumn('chat_data', lf.Type.OBJECT)
    .addPrimaryKey(['id']);

schemaBuilder.createTable("translaters")
    .addColumn('msg_id', lf.Type.STRING)
    .addColumn('chat_id', lf.Type.STRING)
    .addColumn('msg', lf.Type.STRING)
    .addColumn('array_msg', lf.Type.OBJECT)
    .addPrimaryKey(['msg_id'])
    .addIndex('idxChatId', ['chat_id'], false, lf.Order.ASC);

schemaBuilder.createTable("deactivevisitors")
    .addColumn('id', lf.Type.STRING)
    .addColumn('chat_id', lf.Type.STRING)
    .addColumn('counter', lf.Type.INTEGER)
    .addPrimaryKey(['id']);

schemaBuilder.createTable("agents").
    addColumn('agent_id', lf.Type.STRING).
    addColumn('first_name', lf.Type.STRING).
    addColumn('last_name', lf.Type.STRING).
    addColumn('alias', lf.Type.STRING).
    addColumn('profile_pic', lf.Type.STRING).
    addColumn('profile_thumbnail', lf.Type.STRING).
    addColumn('timezone', lf.Type.STRING).
    addColumn('email', lf.Type.STRING).
    addColumn('pic', lf.Type.STRING).
    addColumn('thumbnail', lf.Type.STRING).
    addColumn('imageurl', lf.Type.STRING).
    addPrimaryKey(['agent_id']).
    addIndex('idxChatId', ['first_name'], false, lf.Order.ASC);

schemaBuilder.createTable("client_plans").
    addColumn('visitor_id', lf.Type.STRING).
    addColumn('plan_name', lf.Type.STRING).
    addColumn('app_name', lf.Type.STRING).
    addColumn('status', lf.Type.STRING).
    addColumn('app_id', lf.Type.STRING).
    addColumn('agent_name', lf.Type.STRING).
    addPrimaryKey(['visitor_id']);


schemaBuilder.createTable("filters")
    .addColumn('filter_id', lf.Type.STRING)
    .addColumn('type', lf.Type.STRING)
    .addColumn('groupBy', lf.Type.STRING)
    .addColumn('searchText', lf.Type.STRING)
    .addColumn('countries', lf.Type.OBJECT)
    .addColumn('pageUrls', lf.Type.OBJECT)
    .addColumn('devicesUsed', lf.Type.OBJECT)
    .addColumn('total', lf.Type.INTEGER)
    .addPrimaryKey(['filter_id'])
    .addIndex('idxFilterId', ['total'], false, lf.Order.ASC);

    let dbConnect;

    try {
       
        dbConnect = schemaBuilder.connect();
        // useFirebaseServices();
        
    } catch(err) {
        console.log('error');
    }

/*
const checkExistDB = (dbname) => {
    const totaltables = schemaBuilder.getSchema().tables().length;
    console.log(schemaBuilder.getSchema().tables());
}
*/

/* Functions for chat data start */
export const addChatData = (chatId, rowData) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("visitors");
        var row = item.createRow({
            'id': chatId,
            'chat_id': chatId,
            'chat_data' : rowData
        });
        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const getChatData = async (chatId) => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("visitors");
        return firebaseServices.db.select().from(item).where(item.id.eq(chatId)).exec();
    }).then( res => {
        return (res && res.length > 0) ? res[0] : false;
    }).catch(err => {});
}

export const deleteChatData = async (chatId) => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("visitors");
        return firebaseServices.db.delete().from(item).where(item.id.eq(chatId)).exec();
    }).then( res => {
        return "success";
    }).catch(err => {});
}

export const saveInActiveChat = async (ownerId, chatId, visitorStatus) => {
    if (visitorStatus === "ongoing") {
        const chatData = await getChatData(chatId);
        if (!chatData) {
            try {
                firebaseServices.db.collection('users').doc(ownerId).collection('visitors').doc(chatId).collection('chat_data').orderBy('addedon', 'desc').limit(10).get().then(async (docs) => {
                    const tmpChatDataForLF = [];
                    for (const index in docs.docs) {
                        const change = docs.docs[index];
                        const docData = change.data();
                        const docId = change.id;
                        var tmpdocData = docData;
                        tmpdocData.id = docId;
                        tmpChatDataForLF.push(tmpdocData);
                    }
                    addChatData(chatId, tmpChatDataForLF);
                });
            } catch (err) { }
        }
    }
}

/* Functions for chat data End */

/* Functions for translate chat start */

export const addMessage = (chatId, msgId, message, type) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("translaters");
        if(type === "array") {
            const uniqueMsgId = msgId+"_"+chatId;
            var row = item.createRow({
                'msg_id': uniqueMsgId,
                'chat_id': chatId,
                'msg' : "",
                'array_msg': message
            });
        } else {
            var row = item.createRow({
                'msg_id': msgId,
                'chat_id': chatId,
                'msg' : message,
                'array_msg': {}
            });
        }

        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const getMessage = async (msgId, chatId, type) => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("translaters");
        if(type === "array") {
            return firebaseServices.db.select(item.msg,item.array_msg).from(item).where(lf.op.and(item.msg_id.eq(msgId+"_"+chatId), item.chat_id.eq(chatId)) ).exec();
        } else {
            return firebaseServices.db.select(item.msg,item.array_msg).from(item).where(lf.op.and(item.msg_id.eq(msgId), item.chat_id.eq(chatId)) ).exec();
        } 
    }).then( res => {
        if(type === "array") {
            return (res && res.length > 0) ? res[0].array_msg : false;
        } else {
            return (res && res.length > 0) ? res[0].msg : false;
        }
    }).catch(err => {});
}

export const deleteMessage = async (chatId) => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("translaters");
        return firebaseServices.db.delete().from(item).where(item.chat_id.eq(chatId)).exec();
    }).then( res => {
        return "success";
    }).catch(err => {});
}

/* Functions for translate chat end */

/* Functions to chat get deactive start */

export const insertChatCounter = (chatId) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("deactivevisitors");
        var row = item.createRow({
            'id': chatId,
            'chat_id': chatId,
            'counter': 1
        });
        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const updateChatCounter = (chatId, counter) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("deactivevisitors");
        return firebaseServices.db.update(item).set(item.counter, counter+1).where(item.id.eq(chatId)).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const getChatCounter = async (chatId) => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("deactivevisitors");
        return firebaseServices.db.select().from(item).where(item.id.eq(chatId)).exec();
    }).then( res => {
        return (res && res.length > 0) ? res[0].counter : false;
    }).catch(err => {});
}

export const deleteChatCounter = async (chatId) => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("deactivevisitors");
        return firebaseServices.db.delete().from(item).where(item.id.eq(chatId)).exec();
    }).then( res => {
        return "success";
    }).catch(err => {});
}

/* Functions to chat get deactive End */

/* Functions to add agents start here */
export const addAgentsInList = (agentData) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("agents");
        var row = item.createRow({
            'agent_id': agentData.preferred_username ? agentData.preferred_username : agentData.sub,
            'first_name': agentData.first_name,
            'last_name' : agentData.last_name,
            'alias': agentData.alias,
            'profile_pic': agentData.pic && agentData.pic!=='notexist' ? agentData.imageurl + '/' + agentData.pic : '',
            'profile_thumbnail' : agentData.thumbnail && agentData.thumbnail!=='notexist' ? agentData.imageurl + '/' + agentData.thumbnail : '',
            'timezone' : agentData.timezone,
            'email' : agentData.email,
            'pic' : agentData.pic,
            'thumbnail' : agentData.thumbnail,
            'imageurl' : agentData.imageurl ? agentData.imageurl : ''
        });
        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {
        console.log('error at 241', err)
    });
}

export const deleteAgents = async () => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("agents");
        return firebaseServices.db.delete().from(item).exec();
    }).then( res => {
        return "success";
    }).catch(err => {
        console.log('error at indexeddb line 251>>>', err);
        return "failed";
    });
}

export const getAgentListIndexed = async () => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("agents");
        return firebaseServices.db.select().from(item).exec();
    }).then( res => {
        return res;
    }).catch(err => {
        console.log('error at 258 >>>> ', err);
        return err;
    });
}

/* Functions to add agents end here */

/* Whatsapp data Functions start here */

export const saveClientPlan = async (visitor) => {
    const visitorId = visitor.id ? visitor.id : "";
    const agentEnd = visitor.agent_end ? visitor.agent_end : "";
    const email = visitor.client_email ? visitor.client_email : "";
    if (agentEnd !== "VISITOR_END_CHAT" && email !== "" && email !== "noreply@appypie.com") {
        const planData = await getPlanData(visitorId);
        if (!planData) {
            try {
                var body = { method: "agentDetails", email: email, requestSource:"livechat" };
                fetch("https://api.appexecutable.com/webservices/AppOwner.php", {
                    method: "POST",
                    body: JSON.stringify(body),
                    headers: {
                        "Content-Type": "application/json",
                        "Accept": "application/json"
                    }
                })
                .then(res => res.json())
                .then(res => {
                    let message = res.message ? res.message : "";
                    let status = res.status ? res.status : "";
                    let agentName = res.agentName ? res.agentName : "";
                    let list = res.list ? res.list : [];
                    if(message === "Error" && status === "success" && agentName === "") {
                        let planDetail = {};
                        planDetail.app_id = "";
                        planDetail.plan_name = "";
                        planDetail.app_name = "";
                        planDetail.agent_name = "";
                        planDetail.status = "no_data";
                        addPlanData(visitorId, planDetail);
                    } else {
                        let totalNumb = list.length;
                        let planDetail = list[totalNumb - 1];
                        planDetail.app_id = planDetail.appId;
                        planDetail.plan_name = planDetail.appPlan;
                        planDetail.app_name = planDetail.appName;
                        planDetail.agent_name = agentName;
                        planDetail.status = "data";
                        addPlanData(visitorId, planDetail);
                    }
                }).catch(err => {});
            } catch (err) {}
        }
    }
}

export const addPlanData = (chatId, planData) => {
    dbConnect.then(function (db) {
        let item = firebaseServices.db.getSchema().table("client_plans");
        let row = item.createRow({
            'visitor_id': chatId,
            'plan_name' : planData.plan_name,
            'app_name' : planData.app_name,
            'status' : planData.status,
            'app_id' : planData.app_id,
            'agent_name' : planData.agent_name
        });
        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const getPlanData = async (chatId) => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("client_plans");
        return firebaseServices.db.select().from(item).where(item.visitor_id.eq(chatId)).exec();
    }).then( res => {
        return (res && res.length > 0) ? res[0] : false;
    }).catch(err => {});
}

export const deletePlanData = async (chatId) => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("client_plans");
        return firebaseServices.db.delete().from(item).where(item.visitor_id.eq(chatId)).exec();
    }).then( res => {
        return "success";
    }).catch(err => {});
}

/* Whatsapp data Functions End here */

/* Functions for filters,grouping and sorting start */

export const insertOrUpdateFilters = (filterData) => {
    dbConnect.then(function (db) {
        var item = firebaseServices.db.getSchema().table("filters");

        let total = isNaN(parseInt(filterData.total)) ? 0 : parseInt(filterData.total);
        var row = item.createRow({
            'filter_id': "my-filter",
            'type': filterData.type,
            'groupBy': filterData.groupBy,
            'searchText': filterData.searchText,
            'countries': filterData.countries,
            'pageUrls': filterData.pageUrls,
            'devicesUsed': filterData.devicesUsed,
            'total': total
        });
        return firebaseServices.db.insertOrReplace().into(item).values([row]).exec();
    }).then(function (res) {
        return "success";
    }).catch((err) => {});
}

export const getFilters = async () => {
    return await dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("filters");
        return firebaseServices.db.select().from(item).where(item.filter_id.eq("my-filter")).exec();
    }).then( res => {
        return (res && res.length > 0) ? res[0] : false;
    }).catch(err => {});
}

export const deleteFilters = async () => {
    return dbConnect.then( db => {
        var item = firebaseServices.db.getSchema().table("filters");
        return firebaseServices.db.delete().from(item).where(item.filter_id.eq("my-filter")).exec();
    }).then( res => {
        return "success";
    }).catch(err => {});
}

/* Functions for filters,grouping and sorting End */