import React from 'react';
import ReactTooltip from "react-tooltip";
import ApexCharts from "react-apexcharts";
import moment from 'moment-timezone';
// import db from '../../config/firebase';
import { FUNCTION_API_KEY, GATEWAY_URL } from '../../config/siteConfig';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { addDays } from 'date-fns';
import { activityLog, getOwnerDetails, getAllAwsAgents } from "../../services/service";
import { getDateInUTC } from '../../modules/livechat/components/Comman';
import parse from 'html-react-parser';
import dummyData from '../../localization/dummy-data.json';

const conf_chart = { type: 'bar', height: 350, stacked: true, toolbar: { show: false }, zoom: { enabled: false } };
const conf_responsive = [{ breakpoint: 480, options: { legend: { position: 'bottom', offsetX: -10, offsetY: 0 } } }];
const conf_plot_options = { bar: { borderRadius: 8, horizontal: false } };
const conf_legend = { position: 'bottom', offsetX: -10, offsetY: 0 };
const conf_fill = { opacity: 1, colors: ['#77c66d', '#eb867d'] };
const DATE_FORMATES = { miliseconds: "x", hour: 'HH A', day: 'DD MMM', hours_start: "ddd, MMM DD YYYY hh:00 A", hours_end: "hh:00 A", day_start: "ddd, MMM DD YYYY", last_login: "ddd, MMM DD YYYY hh:mm A" };

class IndividualAgent extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            loader: true,
            agentListArray: [],
            agentId: "",
            averageAllTotalOnline: {},
            selectedAgentOnline: 0,
            avgAgentResTime: 0,
            avgAgentChatDuration: 0,
            avgAllAgentResTime: 0,
            avgAllAgentChatDuration: 0,
            agentList: [],
            profilePic: "",
            agentShort: "",
            agentName: "",
            lastActivity: 0,
            onlineLoggedInData: [],
            currentchats: [],
            previouschats: [],
            domain_list: [],
            date_range: "today",
            chart_base: "hourly",
            domain: "all",
            start_date: "",
            end_date: "",
            fill_start_date: addDays(new Date(), -7),
            fill_end_date: new Date(),
            numberOfDays: 0,
            showDateRange: false,
            chats_total: 0,
            chats_served: 0,
            chats_missed: 0,
            prev_chats_total: 0,
            prev_chats_served: 0,
            prev_chats_missed: 0,
            tooltip_total_chats: "",
            tooltip_served_chats: "",
            tooltip_missed_chats: "",
            tooltip_nps_rating: "",
            npscurrentAndPrev: "",
            total_rated_chats: 0,
            chatRatedPercentage: 0,
            series: [{
                name: 'Chats completed',
                color: '#77c66d',
                data: []
            }],
            options: {
                chart: conf_chart,
                responsive: conf_responsive,
                plotOptions: conf_plot_options,
                xaxis: {
                    type: '',
                    categories: []
                },
                legend: conf_legend,
                fill: conf_fill,
                colors: ['#77c66d'],
                dataLabels: {
                    enabled: false,
                    style: {
                        colors: ['#000']
                    }
                },
                tooltip: {
                    enabled: true,
                    custom: this.chatCountToolTip
                },
                title: {
                    align: 'left'
                },
            },
            chatTiming_series: [{
                name: "Response Time",
                color: '#77c66d',
                data: []
            },
            {
                name: "Chat Duration",
                data: []
            }],
            chatTiming_options: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#5bcec1', '#edbc4d'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 5
                },
                xaxis: {
                    type: 'category',
                    convertedCatToNumeric: false,
                    categories: []
                },
                legend: {
                    position: 'bottom',
                    offsetY: 0,  // -25,
                    offsetX: -10, //-5
                    formatter: this.formatLegends
                },
                tooltip: {
                    enabled: true,
                    custom: this.chatTimingsToolTip
                }
            },
            response_options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                colors: ['#5bcec1'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: "end",
                    offsetX: 250,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        colors: ["#8e8e8e"]
                    },
                },
                xaxis: {
                    categories: ["<15s", "15s-30s", "31s-45s", "46s-1m", ">1m"],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                yaxis: {
                    show: true,
                    axisBorder: {
                        show: false
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                tooltip: {
                    enabled: false
                }
            },
            response_series: [{
                name: "Number of Chats",
                data: []
            }],
            chatduration_options: {
                chart: {
                    type: 'bar',
                    height: 350,
                    toolbar: { show: false },
                    zoom: { enabled: false }
                },
                colors: ['#edbc4d'],
                plotOptions: {
                    bar: {
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: true,
                    textAnchor: "end",
                    offsetX: 250,
                    offsetY: 0,
                    style: {
                        fontSize: '14px',
                        colors: ["#8e8e8e"]
                    },
                },
                xaxis: {
                    categories: ["<10m", "10m-30m", "31m-1h", "1h-1.5h", ">1.5h"],
                    labels: {
                        show: false,
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                yaxis: {
                    show: true,
                    axisBorder: {
                        show: false
                    },
                    crosshairs: {
                        show: false,
                    },
                },
                tooltip: {
                    enabled: false
                }
            },
            chatduration_series: [{
                name: "Number of Chats",
                data: []
            }],
            satisfaction_series: [{
                name: "Chats rated",
                data: []
            }],
            satisfaction_options: {
                chart: {
                    height: 350,
                    type: 'line',
                    dropShadow: {
                        enabled: true,
                        color: '#000',
                        top: 18,
                        left: 7,
                        blur: 10,
                        opacity: 0.2
                    },
                    toolbar: {
                        show: false
                    }
                },
                colors: ['#6675be'],
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    curve: 'smooth'
                },
                title: {
                    text: 'Satisfaction',
                    align: 'left'
                },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 5
                },
                xaxis: {
                    categories: ["A", "B", "C"]
                },
                legend: {
                    position: 'bottom',
                    offsetY: 0,  // -25,
                    offsetX: -10 //-5
                },
                tooltip: {
                    enabled: true,
                    custom: this.satisfactionToolTip
                }
            },
            nps_series: [],
            nps_options: {
                chart: {
                    width: 380,
                    type: 'donut',
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            size: '65%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    show: true,
                                    fontSize: '12px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 300,
                                    color: "#77c66d",
                                    offsetY: 30
                                },
                                value: {
                                    show: true,
                                    fontSize: '30px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 400,
                                    color: "#77c66d",
                                    offsetY: -10
                                },
                                total: {
                                    show: true,
                                    showAlways: true,
                                    label: 'Satisfaction',
                                    fontSize: '22px',
                                    fontFamily: 'Helvetica, Arial, sans-serif',
                                    fontWeight: 300,
                                    color: '#77c66d',
                                    formatter: function (w) {
                                        return isNaN(w.globals.seriesTotals[0]) ? "0%" : w.globals.seriesTotals[0] + "%";
                                    }
                                }
                            }
                        }
                    }
                },
                colors: ['#77c66d', '#eb867d'],
                dataLabels: {
                    enabled: false,
                },
                fill: {
                    type: 'gradient',
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }],
                labels: ['Good', 'Bad'],
                // title: {
                //     text: 'NPS',
                //     align: 'left'
                // },
                grid: {
                    borderColor: '#e7e7e7',
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                markers: {
                    size: 1
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    offsetY: 0,  // -25,
                    offsetX: -10 //-5
                }
            }
        }
    }

    secondsToHms = (d, type) => {
        if (type === "all") {
            d = Number(d);
            let avgHTML = '<span class="k-flex align-items-baseline pl-3"><span class="small">(</span>';
            if (d === 0) {
                var hDisplay = "<span class='big'>0</span><span class='small'>h</span>";
                hDisplay += "<span class='big'>0</span><span class='small'>m</span>";
                hDisplay += "<span class='big'>0</span><span class='small'>s</span>";
                avgHTML += hDisplay;
            } else {
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);
                var s = Math.floor(d % 3600 % 60);
                var hDisplay = h > 0 ? "<span class='big'> " + h + " </span>" + "<span class='small'> " + "h" + " </span>" : "";
                var mDisplay = m > 0 ? "<span class='big asa'> " + m + " </span>" + "<span class='small'> " + "m" + "</span>" : "";
                var sDisplay = s > 0 ? "<span class='big'> " + s + " </span>" + "<span class='small'> " + "s" + "</span>" : "";
                avgHTML += hDisplay + mDisplay + sDisplay;
            }
            avgHTML += '<span class="small">)</span></span>';
            return avgHTML;
        } else if (type === "current") {
            d = Number(d);
            if (d === 0) {
                var hDisplay = "<span class='big'>0</span><span class='small'>h</span>";
                hDisplay += "<span class='big'>0</span><span class='small'>m</span>";
                hDisplay += "<span class='big'>0</span><span class='small'>s</span>";
                return hDisplay;
            }

            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            var hDisplay = h > 0 ? "<span class='big'> " + h + " </span>" + "<span class='small'> " + "h" + " </span>" : "";
            var mDisplay = m > 0 ? "<span class='big asa'> " + m + " </span>" + "<span class='small'> " + "m" + "</span>" : "";
            var sDisplay = s > 0 ? "<span class='big'> " + s + " </span>" + "<span class='small'> " + "s" + "</span>" : "";
            return hDisplay + mDisplay + sDisplay;
        } else if (type === "agent") {
            d = Number(d);
            if (d === 0) {
                var hDisplay = "<span class='avggbig ml-2'>0</span><span class='small'>m</span>";
                hDisplay += "<span class='avggbig ml-2'>0</span><span class='small'>s</span>";
                return hDisplay;
            }
            var h = Math.floor(d / 3600);
            var m = Math.floor(d % 3600 / 60);
            var s = Math.floor(d % 3600 % 60);
            //if(h > 0 && )

            var hDisplay = h > 0 ? "<span class='avggbig ml-2'> " + h + " </span>" + "<span class='small'> " + "h" + " </span>" : "";
            var mDisplay = m > 0 ? "<span class='avggbig asa ml-2'> " + m + " </span>" + "<span class='small'> " + "m" + "</span>" : "";
            var sDisplay = s > 0 ? "<span class='avggbig ml-2'> " + s + " </span>" + "<span class='small'> " + "s" + "</span>" : "";
            return hDisplay + mDisplay + sDisplay;
        } else if (type === "all_agent") {
            d = Number(d);
            let avgHTML = '<span class="k-flex align-items-baseline pl-3"><span class="small">(</span>';
            if (d === 0) {
                var hDisplay = "<span class='avggbig'>0</span><span class='small'>m</span>";
                hDisplay += "<span class='avggbig ml-2'>0</span><span class='small'>s</span>";
                avgHTML += hDisplay;
            } else {
                var h = Math.floor(d / 3600);
                var m = Math.floor(d % 3600 / 60);
                var s = Math.floor(d % 3600 % 60);

                var minClsss = ' ml-2';
                var secClsss = ' ml-2';
                if (h <= 0) {
                    minClsss = '';
                }

                if (h > 0 && s > 0 && m <= 0) {
                    // nothing
                } else if (m <= 0) {
                    secClsss = '';
                }

                var hDisplay = h > 0 ? "<span class='avggbig'> " + h + " </span>" + "<span class='small'> " + "h" + " </span>" : "";
                var mDisplay = m > 0 ? "<span class='avggbig asa" + minClsss + "'> " + m + " </span>" + "<span class='small'> " + "m" + "</span>" : "";
                var sDisplay = s > 0 ? "<span class='avggbig" + secClsss + "'> " + s + " </span>" + "<span class='small'> " + "s" + "</span>" : "";
                avgHTML += hDisplay + mDisplay + sDisplay;
            }
            avgHTML += '<span class="small">)</span></span>';
            return avgHTML;
        }
    }

    formatLegends = (seriesName, opts) => {
        if (opts.seriesIndex === 0) {
            return seriesName + "  " + this.secondsToHms(this.state.avgAgentResTime, "agent") + this.secondsToHms(this.state.avgAllAgentResTime, "all_agent");
        } else {
            return seriesName + "  " + this.secondsToHms(this.state.avgAgentChatDuration, "agent") + this.secondsToHms(this.state.avgAllAgentChatDuration, "all_agent");
        }
    }

    chatCountToolTip = (e) => {
        let dataPointIndex = e.dataPointIndex;
        let w = e.w ? e.w : {};
        let config = w.config ? w.config : {};
        let series = config.series ? config.series : {};
        let xaxis = config.xaxis ? config.xaxis : {};
        let categories = xaxis.categories ? xaxis.categories : [];
        let xVal = categories[dataPointIndex];
        let fDate = new Date(xVal);
        let heading;

        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.chart_base === "hourly") {
                let fDate2 = xVal + 3600000; /// Add 1 Hour
                heading = moment(xVal, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        } else {
            if (this.state.chart_base === "hourly") {
                let fDate2 = new Date(fDate);
                fDate2.setHours(fDate2.getHours() + 1);
                heading = moment(fDate).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        }
        var str = "<h5>" + heading + "</h5>";
        for (var i = 0; i < series.length; i++) {
            str += "<p><span>" + series[i].name + "</span>: <span style= \"background-color:" + series[i].color + "\" >" + series[i].data[dataPointIndex] + "</span></p>";
        }
        return str;
    }

    chatTimingsToolTip = (e) => {
        let dataPointIndex = e.dataPointIndex;
        let w = e.w ? e.w : {};
        let config = w.config ? w.config : {};
        let series = config.series ? config.series : {};
        let xaxis = this.state.options.xaxis ? this.state.options.xaxis : {};
        let categories = xaxis.categories ? xaxis.categories : [];
        let xVal = categories[dataPointIndex];
        let fDate = new Date(xVal);
        let heading;

        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.chart_base === "hourly") {
                let fDate2 = xVal + 3600000; /// Add 1 Hour
                heading = moment(xVal, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        } else {
            if (this.state.chart_base === "hourly") {
                let fDate2 = new Date(fDate);
                fDate2.setHours(fDate2.getHours() + 1);
                heading = moment(fDate).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        }
        var str = "<h5>" + heading + "</h5>";
        for (var i = 0; i < series.length; i++) {
            if (series[i].data.length > 0) {
                let tmpMin = parseInt(series[i].data[dataPointIndex]);
                if (tmpMin > 60) {
                    tmpMin = tmpMin / 60;
                    if (Math.floor(tmpMin) > 1) {
                        tmpMin = tmpMin.toFixed(2) + " Hours";
                    } else {
                        tmpMin = tmpMin.toFixed(2) + " Hour";
                    }
                } else {
                    tmpMin += tmpMin > 1 ? " Minutes" : " Minute";
                }
                str += "<p><span>" + series[i].name + "</span>: <span style= \"background-color:" + series[i].color + "\" >" + tmpMin + "</span></p>";
            }
        }
        return str;
    }

    satisfactionToolTip = (e) => {
        let dataPointIndex = e.dataPointIndex;
        let w = e.w ? e.w : {};
        let config = w.config ? w.config : {};
        let series = config.series ? config.series : {};
        let xaxis = this.state.options.xaxis ? this.state.options.xaxis : {};
        let categories = xaxis.categories ? xaxis.categories : [];
        let xVal = categories[dataPointIndex];
        let fDate = new Date(xVal);
        let heading;
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.chart_base === "hourly") {
                let fDate2 = xVal + 3600000; /// Add 1 Hour
                heading = moment(xVal, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        } else {
            if (this.state.chart_base === "hourly") {
                let fDate2 = new Date(fDate);
                fDate2.setHours(fDate2.getHours() + 1);
                heading = moment(fDate).format(DATE_FORMATES.hours_start) + "-" + moment(fDate2).format(DATE_FORMATES.hours_end);
            } else if (this.state.chart_base === "weekly") {
                let fDate2 = new Date(fDate);
                fDate2.setDate(fDate2.getDate() + 6);
                heading = moment(fDate).format(DATE_FORMATES.day_start) + "-" + moment(fDate2).format(DATE_FORMATES.day_start);
            } else {
                heading = moment(fDate).format(DATE_FORMATES.day_start);
            }
        }
        var str = "<h5>" + heading + "</h5>";
        for (var i = 0; i < series.length; i++) {
            if (series[i].data.length > 0) {
                str += "<p><span>" + series[i].name + "</span>: <span style= \"background-color:" + series[i].color + "\" >" + series[i].data[dataPointIndex] + "</span></p>";
            }
        }
        return str;
    }

    formatXAxis = (category) => {
        if (this.agent.org_timezone && this.agent.org_timezone !== "") {
            if (this.state.date_range === "today") {
                return moment(category, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.hour);
            } else if (this.state.date_range === "custom") {
                return moment(category, "x").format(DATE_FORMATES.day);
            } else {
                return moment(category, "x", this.agent.org_timezone).tz(this.agent.org_timezone).format(DATE_FORMATES.day);
            }
        } else {
            if (this.state.date_range === "today") {
                return moment(category).format(DATE_FORMATES.hour);
            } else {
                return moment(category).format(DATE_FORMATES.day);
            }
        }
    }

    getLastActivity = (time) => {

        let actTime;
        if(this.agent.org_timezone) {
            actTime = moment(time).tz(this.agent.org_timezone).format(DATE_FORMATES.last_login);
        } else {
            actTime = moment(time).format(DATE_FORMATES.last_login);
        }

        return '<span class="no-data normal-text">' + actTime + '</span>';
        /* switch (typeof time) {
            case 'number':
                break;
            case 'string':
                time = +new Date(time);
                break;
            case 'object':
                if (time.constructor === Date) time = time.getTime();
                break;
            default:
                time = +new Date();
        }

        var time_formats = [
            [60, 'seconds', 1], // 60
            [120, '1 minute ago', '1 minute from now'], // 60*2
            [3600, 'minutes', 60], // 60*60, 60
            [7200, '1 hour ago', '1 hour from now'], // 60*60*2
            [86400, 'hours', 3600], // 60*60*24, 60*60
            [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
            [604800, 'days', 86400], // 60*60*24*7, 60*60*24
            [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
            [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
            [4838400, '<span class="no-data normal-text"> Last month </span>', 'Next month'], // 60*60*24*7*4*2
            [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
            [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
            [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
            [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
            [58060800000, 'centuries', 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
        ];

        var seconds = (+new Date() - time) / 1000, token = 'ago', list_choice = 1;
        if (seconds == 0) {
            return '<span class="midium">Just now</span>';
        }

        if (seconds < 0) {
            seconds = Math.abs(seconds);
            token = 'from now';
            list_choice = 2;
        }

        var i = 0, format;
        while (format = time_formats[i++]) {
            if (seconds < format[0]) {
                if (typeof format[2] == 'string')
                    return format[list_choice];
                else
                    return "<span class='big'> " + Math.floor(seconds / format[2]) + " </span>" + '<span class="small"> ' + format[1] + '</span><span class="small">' + token + '</span>';
            }
        }

        return time; */
    }

    showProfile = async () => {
        let profilepic = (this.state.agentList[this.state.agentId] && this.state.agentList[this.state.agentId].thumbnail && this.state.agentList[this.state.agentId].thumbnail !== "notexist") ? this.state.agentList[this.state.agentId].imageurl + "/" + this.state.agentList[this.state.agentId].thumbnail : "";
        let agentName = (this.state.agentList[this.state.agentId] && this.state.agentList[this.state.agentId].name) ? this.state.agentList[this.state.agentId].name : this.state.agentList[this.state.agentId].agent_short;
        let last_activity_time = (this.state.agentList[this.state.agentId] && this.state.agentList[this.state.agentId].last_activity_time) ? this.state.agentList[this.state.agentId].last_activity_time : 0;
        if (this.state.widgetStatus === false) {
            last_activity_time = await getDateInUTC();
            last_activity_time = last_activity_time - (2 * 60 * 1000);
        }

        if (last_activity_time > 0) {
            last_activity_time = this.getLastActivity(last_activity_time);
        } else {
            last_activity_time = "<span class='no-data normal-text'>" + "Never logged in" + "</span>"
        }
        //this.getOnlineLogedIn();
        this.setState({ profilePic: profilepic, agentShort: this.state.agentList[this.state.agentId].agent_short, agentName: agentName, lastActivity: last_activity_time });
    }

    componentDidMount = () => {

        this.setState({ agentId: this.props.agentId, chart_base: this.props.chart_base, date_range: this.props.date_range, domain: this.props.domain, agentListArray: this.props.agentListArray, selectedAgentOnline: this.props.selectedAgentOnline, agentList: this.props.agentList, start_date: this.props.start_date, end_date: this.props.end_date, numberOfDays: this.props.numberOfDays, averageAllTotalOnline: this.props.averageAllTotalOnline, avgAgentResTime: this.props.avgAgentResTime, avgAgentChatDuration: this.props.avgAgentChatDuration, avgAllAgentResTime: this.props.avgAllAgentResTime, avgAllAgentChatDuration: this.props.avgAllAgentChatDuration, widgetStatus: this.props.widgetStatus }, () => {
            //this.getOnlineLogedIn();
            this.showProfile();
            if (this.props.widgetStatus === true) {
                this.getchats();
            } else {
                this.loadDummyData();
            }
        });
        activityLog("Opened analytics page for agent");
    }

    loadDummyData = async () => {
        let analyticsData = dummyData.Analytics;
        let serverTimeStamp = await getDateInUTC();
        let counterValue = serverTimeStamp;
        var currentChats = [];
        analyticsData.current.forEach((data) => {
            data.addedon = counterValue;
            counterValue = counterValue - (30 * 60 * 1000)
            currentChats.push(data);
        })

        this.setState({ currentchats: currentChats, previouschats: analyticsData.previous, showDummyData: true }, () => {
            this.loadChats();
        });
    }

    componentDidUpdate = () => {
        ReactTooltip.rebuild();
        if (this.props.agentId !== this.state.agentId) {
            this.setState({ agentId: this.props.agentId }, () => {
                this.showProfile();
                if (this.state.widgetStatus === true) {
                    this.getchats();
                } else {
                    this.loadDummyData();
                }
            });
        }

        if (this.props.date_range === "custom") {
            if (this.props.applyCustom && (this.props.start_date !== this.state.start_date || this.props.end_date !== this.state.end_date || this.props.chart_base !== this.state.chart_base)) {
                this.setState({ date_range: this.props.date_range, chart_base: this.props.chart_base, start_date: this.props.start_date, end_date: this.props.end_date, numberOfDays: this.props.numberOfDays }, () => {
                    this.getchats();
                });
            } else if (!this.props.applyCustom && this.props.chart_base !== this.state.chart_base) {
                this.setState({ chart_base: this.props.chart_base }, () => {
                    if(this.agent.org_timezone && this.agent.org_timezone !== "" && this.state.agentId === "") {
                        this.loadGraphChats();
                    } else if(this.agent.org_timezone && this.agent.org_timezone !== "" && this.state.agentId !== "") {
                        this.loadZoneChats();
                    } else {
                        this.loadChats();
                    }
                });
            }
        } else if (this.props.date_range !== this.state.date_range && this.props.chart_base !== this.state.chart_base) {
            this.setState({ date_range: this.props.date_range, chart_base: this.props.chart_base }, () => {
                this.getchats();
            });
        } else if (this.props.date_range !== this.state.date_range) {
            this.setState({ date_range: this.props.date_range }, () => {
                this.getchats();
            });
        } else if (this.props.chart_base !== this.state.chart_base) {
            this.setState({ chart_base: this.props.chart_base }, () => {
                if(this.agent.org_timezone && this.agent.org_timezone !== "" && this.state.agentId === "") {
                    this.loadGraphChats();
                } else if(this.agent.org_timezone && this.agent.org_timezone !== "" && this.state.agentId !== "") {
                    this.loadZoneChats();
                } else {
                    this.loadChats();
                }
            });
        }

        if (this.props.domain !== this.state.domain) {
            this.setState({ domain: this.props.domain }, () => {
                this.getchats();
            });
        }

        if (this.props.selectedAgentOnline !== this.state.selectedAgentOnline) {
            this.setState({ selectedAgentOnline: this.props.selectedAgentOnline });
        }

        if (this.props.averageAllTotalOnline !== this.state.averageAllTotalOnline) {
            this.setState({ averageAllTotalOnline: this.props.averageAllTotalOnline });
        }

        if (this.props.avgAgentResTime !== this.state.avgAgentResTime) {
            this.setState({ avgAgentResTime: this.props.avgAgentResTime });
        }

        if (this.props.avgAgentChatDuration !== this.state.avgAgentChatDuration) {
            this.setState({ avgAgentChatDuration: this.props.avgAgentChatDuration });
        }

        if (this.props.avgAllAgentResTime !== this.state.avgAllAgentResTime) {
            this.setState({ avgAllAgentResTime: this.props.avgAllAgentResTime });
        }

        if (this.props.avgAllAgentChatDuration !== this.state.avgAllAgentChatDuration) {
            this.setState({ avgAllAgentChatDuration: this.props.avgAllAgentChatDuration });
        }
    }

    getOnlineLogedIn = () => {
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        var body = [`ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&agentId=${this.state.agentId}&currentDate=${todayDate.getTime()}`];
        ///fetch(MAIL_FUNCTION_URL + "/analytics/getAgentSessions", {
        fetch(GATEWAY_URL + "/getAgentSessions", {
            method: "post",
            body: body,
            headers: {
                "X-API-Key": FUNCTION_API_KEY,
                "Content-Type": "application/x-www-form-urlencoded",
                "Accept": "application/json",
                "Origin": window.location.origin
            }
        }).then(res => res.json()).then(async (response) => {
            if (response.status === "success") {
                this.setState({ onlineLoggedInData: response.data }, () => {
                    this.loadOnlineAgents();
                });
            }
        }).catch((err) => { });
    }

    loadOnlineAgents = async () => {
        let onlineLoggedInData = this.state.onlineLoggedInData;
        let today = new Date();
        today.setHours(0, 0, 0, 0);
        let agentsOnlineTime = {};
        let totalRows = onlineLoggedInData.length;

        if (totalRows > 0) {
            if (this.state.date_range === "today") {
                var serverTimestamp = await getDateInUTC();
                var i = 1;
                onlineLoggedInData.forEach(loginD => {
                    let online = loginD.online ? parseInt(loginD.online) : 0;
                    let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                    let type = loginD.type ? loginD.type : "";
                    let agent_id = loginD.agent_id ? loginD.agent_id : "";

                    if (!offline && i === totalRows) {
                        offline = serverTimestamp;
                    }

                    if (type === "online_offline" && offline) {
                        let onlineD = new Date(online);
                        let houronlineD = onlineD.getHours();
                        let minuteonlineD = onlineD.getMinutes();
                        let offlineD = new Date(offline);
                        let hourofflineD = offlineD.getHours();
                        let minuteofflineD = offlineD.getMinutes();

                        if (agentsOnlineTime[agent_id] === undefined) {
                            agentsOnlineTime[agent_id] = {};
                        }

                        if ((hourofflineD - houronlineD) >= 1) {
                            for (var j = houronlineD; j <= hourofflineD; j++) {
                                var workSeconds = 3600;
                                if (j === houronlineD) {
                                    workSeconds = (60 - eval(minuteonlineD)) * 60;
                                } else if (j === hourofflineD) {
                                    workSeconds = minuteofflineD * 60;
                                }
                                let nxtHour = eval(j) + 1;
                                if (agentsOnlineTime[agent_id][j + "_" + nxtHour] === undefined) {
                                    agentsOnlineTime[agent_id][j + "_" + nxtHour] = [];
                                }
                                agentsOnlineTime[agent_id][j + "_" + nxtHour].push(workSeconds);
                            }
                        } else {
                            let nxtHour = eval(houronlineD) + 1;
                            if (agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] === undefined) {
                                agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour] = [];
                            }
                            agentsOnlineTime[agent_id][houronlineD + "_" + nxtHour].push(((minuteofflineD - minuteonlineD) * 60));
                        }
                    }
                    i++;
                });
            } else {
                onlineLoggedInData.forEach(loginD => {
                    let online = loginD.online ? parseInt(loginD.online) : 0;
                    let offline = loginD.offline ? parseInt(loginD.offline) : 0;
                    let type = loginD.type ? loginD.type : "";
                    let agent_id = loginD.agent_id ? loginD.agent_id : "";

                    if (type === "online_offline" && offline) {

                        let onlineD = new Date(online);
                        onlineD.setHours(0, 0, 0, 0);
                        let offlineD = new Date(onlineD);
                        offlineD.setHours(23, 59, 59, 0);
                        let dayOnlineD = onlineD.getDate();
                        let offlineDy = new Date(offline);
                        let dayOfflineD = offlineDy.getDate();
                        if (agentsOnlineTime[agent_id] === undefined) {
                            agentsOnlineTime[agent_id] = {};
                        }

                        if ((dayOfflineD - dayOnlineD) >= 1) {
                            for (var j = dayOnlineD; j <= dayOfflineD; j++) {
                                var workSeconds = 86400;
                                if (j === dayOnlineD) {
                                    let onlineD22 = new Date(online);
                                    let on2Hours = onlineD22.getHours();
                                    let on2minutes = onlineD22.getMinutes();
                                    let on2Seconds = onlineD22.getSeconds();
                                    let hrs = 24 - on2Hours;
                                    let hrsInMinutes = hrs * 60;
                                    let minusMinutes = hrsInMinutes - on2minutes;
                                    let minusMinutesInSec = minusMinutes * 60;
                                    workSeconds = minusMinutesInSec - on2Seconds;

                                    let fromTime = new Date(online);
                                    fromTime.setHours(0, 0, 0, 0);
                                    let toTime = new Date(online);
                                    toTime.setHours(23, 59, 59, 0);

                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                    }

                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                } else {
                                    if (j === dayOfflineD) {
                                        let onlineD22 = new Date(offline);
                                        let on2Hours = onlineD22.getHours();
                                        let on2minutes = onlineD22.getMinutes();
                                        let on2Seconds = onlineD22.getSeconds();
                                        workSeconds = (on2Hours * 3600) + (on2minutes * 60) + on2Seconds;
                                    }
                                    let fromTime = new Date(online);
                                    fromTime.setDate(j);
                                    fromTime.setHours(0, 0, 0, 0);
                                    let toTime = new Date(fromTime);
                                    toTime.setHours(23, 59, 59, 0);
                                    if (agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] === undefined) {
                                        agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()] = [];
                                    }
                                    agentsOnlineTime[agent_id][fromTime.getTime() + "_" + toTime.getTime()].push(workSeconds);
                                }
                            }
                        } else {
                            let onlineinTime = onlineD.getTime();
                            let offlineinTime = offlineD.getTime();
                            let diffMiliseconds = offline - online;
                            let diffSeconds = 0;
                            if (diffMiliseconds > 0) {
                                diffSeconds = Math.floor(diffMiliseconds / 1000);
                            }
                            if (agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] === undefined) {
                                agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime] = [];
                            }
                            agentsOnlineTime[agent_id][onlineinTime + "_" + offlineinTime].push(diffSeconds);
                        }
                    }
                });
            }
        }

        let totalAgentsOnline = 0;
        if (Object.keys(agentsOnlineTime).length > 0) {
            for (var agentId in agentsOnlineTime) {
                let agentOnlineData = agentsOnlineTime[agentId];
                let grandTotal = 0;
                if (Object.keys(agentOnlineData).length > 0) {
                    for (var time2 in agentOnlineData) {
                        let totalTime = agentOnlineData[time2].reduce(function (a, b) {
                            return a + b;
                        }, 0);
                        grandTotal += totalTime;
                    }
                }
                totalAgentsOnline = grandTotal;
            }
        }

        this.setState({ selectedAgentOnline: totalAgentsOnline });
    }

    getchats = () => {
        this.setState({ loader: true });
        let todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        var body = [`fromPage=individual&ownerId=${this.agent.ownerId}&date_range=${this.state.date_range}&domain=${encodeURIComponent((this.state.domain && this.state.domain !== "all") ? this.state.domain : "")}&start_date=${encodeURIComponent(this.state.start_date)}&end_date=${encodeURIComponent(this.state.end_date)}&numberOfDays=${this.state.numberOfDays}&agentId=${this.state.agentId}&currentDate=${todayDate.getTime()}`];

        if(this.agent.org_timezone && this.agent.org_timezone !== "" && this.state.agentId === "") {
            fetch(GATEWAY_URL + "/getGraphChats", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    let resData = response.data;
                    this.setState({ currentchats: resData.current, previouschats: resData.previous }, () => {
                        this.loadGraphChats();
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        } else {
            fetch(GATEWAY_URL + "/getChats", {
                method: "post",
                body: body,
                headers: {
                    "X-API-Key": FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    var resData = response.data;
                    this.setState({ currentchats: resData.current, previouschats: resData.previous }, () => {
                        if(this.agent.org_timezone) {
                            this.loadZoneChats();
                        } else {
                            this.loadChats();
                        }
                    });
                } else {
                    this.setState({ loader: false });
                }
            }).catch((err) => { });
        }
    }

    loadGraphChats = () => {

        var currentchats = this.state.currentchats;
        var previouschats = this.state.previouschats;
        var options = this.state.options;
        var series = this.state.series;
        var chatTiming_options = this.state.chatTiming_options;
        var chatTiming_series = this.state.chatTiming_series;
        var response_series = this.state.response_series;
        var chatduration_series = this.state.chatduration_series;
        var satisfaction_options = this.state.satisfaction_options;
        var satisfaction_series = this.state.satisfaction_series;
        var nps_series = this.state.nps_series;
        var nps_options = this.state.nps_options;

        this.setState({ series: [], options: {}, chatTiming_options: {}, chatTiming_series: [], response_series: [], chatduration_series: [], satisfaction_options: {}, satisfaction_series: [], nps_series: [], total_rated_chats: 0, nps_options: {} });

        /* For charting start */

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let xaxis_categories = [];
        let xaxis_type = "category";
        let series_data_served = [];
        let tmpCurrServedChats = [];

        let tmpResponseTime = [];
        let tmpChatDuration = [];
        let series_response_time = [];
        let series_chat_duration = [];

        let response_lessthan15 = 0;
        let response_inbtw15_30 = 0;
        let response_inbtw31_45 = 0;
        let response_inbtw46_60 = 0;
        let response_greaterthan60 = 0;

        let duration_lessthan10 = 0;
        let duration_inbtw10_30 = 0;
        let duration_inbtw31_60 = 0;
        let duration_inbtw1h_15 = 0;
        let duration_greaterthan15 = 0;

        let tmpRatings = [];
        let tmpServedRatings = [];
        let tmpMissedRatings = [];
        let series_ratings = [];
        let tmpCategories = [];
        let tmpPrevRatings = [];

        let totalRatedChats = 0;
        let sumOfRatedChats = 0;
        let sumCurrChatTotal = 0;

        if (this.state.chart_base === "daily") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let chatDate = schat.chat_date.value;
                    let dateAddedOn = chatDate.split("-")[2];
                    let monthAddedOn = chatDate.split("-")[1];
                    let yearAddedOn = chatDate.split("-")[0];

                    let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = [];
                    }

                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = [];
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = [];
                    }
                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = [];
                    }
                    tmpCurrServedChats[createIndex].push(schat.served_chats);
                    tmpResponseTime[createIndex].push(schat.response_time);
                    tmpChatDuration[createIndex].push(schat.chat_duration);
                    tmpRatings[createIndex].push(schat.chats_rated);

                    totalRatedChats += schat.chats_rated;
                    sumOfRatedChats += schat.ratings_sum;
                    sumCurrChatTotal += (schat.served_chats + schat.missed_chats);

                    /* Response time chats start */
                    if(schat.res1 > 0) {
                        response_lessthan15 += schat.res1;
                    }
                    if(schat.res2 > 0) {
                        response_inbtw15_30 += schat.res2;
                    }
                    if(schat.res3 > 0) {
                        response_inbtw31_45 += schat.res3;
                    }
                    if(schat.res4 > 0) {
                        response_inbtw46_60 += schat.res4;
                    }
                    if(schat.res5 > 0) {
                        response_greaterthan60 += schat.res5;
                    }

                    /* Response time chats end */

                    /* chat duration start */
                    if(schat.dur1 > 0) {
                        duration_lessthan10 += schat.dur1;
                    }
                    if(schat.dur2 > 0) {
                        duration_inbtw10_30 += schat.dur2;
                    }
                    if(schat.dur3 > 0) {
                        duration_inbtw31_60 += schat.dur3;
                    }
                    if(schat.dur4 > 0) {
                        duration_inbtw1h_15 += schat.dur4;
                    }
                    if(schat.dur5 > 0) {
                        duration_greaterthan15 += schat.dur5;
                    }
                    /* chat duration end */
                });
            }
        } else if (this.state.chart_base === "weekly") {
            if (this.state.date_range === "custom") {
                let stttDate = parseInt(this.state.start_date);
                let stttEndDate = parseInt(this.state.end_date);
                let endDateFormat = moment(stttEndDate).format("YYYY-MM-DD");
                let tmpEndDate222 = moment(endDateFormat).parseZone().tz(this.agent.org_timezone, true);
                let endDateTimestamp = parseInt(tmpEndDate222.format("x"));

                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(stttDate).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let datt = tmpdate.format("DD");
                    let month = tmpdate.format("MM");
                    let year = tmpdate.format("YYYY");
                    let dayOfWeek = parseInt(tmpdate.format("d"));
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));

                    let totalWeekDays = 6 - dayOfWeek;
                    i = i + totalWeekDays;

                    let tmpdate22 = moment(stttDate).add(i, 'days');
                    
                    let toDate22;
                    let gtTime22 = dtTimestamp;

                    if (gtTime22 > endDateTimestamp) {
                        tmpdate22 = tmpEndDate222;
                        toDate22 = endDateTimestamp;
                    } else {
                        let tmpDateOnly22 = tmpdate22.format("YYYY-MM-DD");
                        toDate22 = parseInt(moment(tmpDateOnly22).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    }
                    let datt22 = tmpdate22.format("DD");
                    let month22 = tmpdate22.format("MM");
                    let year22 = tmpdate22.format("YYYY");
                    let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                    tmpCategories.push({ fromDate: dtTimestamp, toDate: toDate22, day: createIndex });
                }
            } else {
                var maxDays = 0
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    let dayOfWeek = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("d"));
                    const splitDate = tmpdate.split("-");
                    let datt = splitDate[2];
                    let month = splitDate[1];
                    let year = splitDate[0];
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ fromDate: tmpdateTimestamp, toDate: (tmpdateTimestamp + 86399000), day: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                        const tmpdateTimestamp22 = parseInt(moment.tz(tmpdate22, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                        const splitDate22 = tmpdate22.split("-");
                        let datt22 = splitDate22[2];
                        let month22 = splitDate22[1];
                        let year22 = splitDate22[0];
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ fromDate: tmpdateTimestamp22, toDate: (tmpdateTimestamp + 86399000), day: createIndex });
                    }
                }
                tmpCategories.reverse();
            }

            currentchats.forEach(schat => {
                let chatDate = schat.chat_date.value;

                totalRatedChats += schat.chats_rated;
                sumOfRatedChats += schat.ratings_sum;
                sumCurrChatTotal += (schat.served_chats + schat.missed_chats);

                let timeAddedOn = parseInt(moment.tz(chatDate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                tmpCategories.forEach(valObj => {
                    if (timeAddedOn >= valObj.fromDate && timeAddedOn <= valObj.toDate) {
                        let createIndex = valObj.day;
                        if (tmpCurrServedChats[createIndex] === undefined) {
                            tmpCurrServedChats[createIndex] = [];
                        }
                        if (tmpResponseTime[createIndex] === undefined) {
                            tmpResponseTime[createIndex] = [];
                        }
                        if (tmpChatDuration[createIndex] === undefined) {
                            tmpChatDuration[createIndex] = [];
                        }
                        if (tmpRatings[createIndex] === undefined) {
                            tmpRatings[createIndex] = [];
                        }
                        tmpCurrServedChats[createIndex].push(schat.served_chats);
                        tmpResponseTime[createIndex].push(schat.response_time);
                        tmpChatDuration[createIndex].push(schat.chat_duration);
                        tmpRatings[createIndex].push(schat.chats_rated);

                        /* Response time chats start */
                        if (schat.res1 > 0) {
                            response_lessthan15 += schat.res1;
                        }
                        if (schat.res2 > 0) {
                            response_inbtw15_30 += schat.res2;
                        }
                        if (schat.res3 > 0) {
                            response_inbtw31_45 += schat.res3;
                        }
                        if (schat.res4 > 0) {
                            response_inbtw46_60 += schat.res4;
                        }
                        if (schat.res5 > 0) {
                            response_greaterthan60 += schat.res5;
                        }

                        /* Response time chats end */

                        /* chat duration start */
                        if (schat.dur1 > 0) {
                            duration_lessthan10 += schat.dur1;
                        }
                        if (schat.dur2 > 0) {
                            duration_inbtw10_30 += schat.dur2;
                        }
                        if (schat.dur3 > 0) {
                            duration_inbtw31_60 += schat.dur3;
                        }
                        if (schat.dur4 > 0) {
                            duration_inbtw1h_15 += schat.dur4;
                        }
                        if (schat.dur5 > 0) {
                            duration_greaterthan15 += schat.dur5;
                        }
                        /* chat duration end */
                    }
                });
            });
        } else if (this.state.chart_base === "hourly") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let chatDate = schat.chat_date.value;
                    let monthAddedOn = chatDate.split("-")[1];
                    let yearAddedOn = chatDate.split("-")[0];

                    let createIndex = schat.chat_hours + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = 0;
                    }
                    tmpCurrServedChats[createIndex] = schat.served_chats;
                    
                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = 0;
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = 0;
                    }

                    tmpResponseTime[createIndex]= schat.response_time;
                    tmpChatDuration[createIndex] = schat.chat_duration;

                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = 0;
                    }
                    tmpRatings[createIndex] = schat.chats_rated;

                    totalRatedChats += schat.chats_rated;
                    sumOfRatedChats += schat.ratings_sum;
                    sumCurrChatTotal += (schat.served_chats + schat.missed_chats);

                    /* Response time chats start */
                    if(schat.res1 > 0) {
                        response_lessthan15 += schat.res1;
                    }
                    if(schat.res2 > 0) {
                        response_inbtw15_30 += schat.res2;
                    }
                    if(schat.res3 > 0) {
                        response_inbtw31_45 += schat.res3;
                    }
                    if(schat.res4 > 0) {
                        response_inbtw46_60 += schat.res4;
                    }
                    if(schat.res5 > 0) {
                        response_greaterthan60 += schat.res5;
                    }

                    /* Response time chats end */

                    /* chat duration start */
                    if(schat.dur1 > 0) {
                        duration_lessthan10 += schat.dur1;
                    }
                    if(schat.dur2 > 0) {
                        duration_inbtw10_30 += schat.dur2;
                    }
                    if(schat.dur3 > 0) {
                        duration_inbtw31_60 += schat.dur3;
                    }
                    if(schat.dur4 > 0) {
                        duration_inbtw1h_15 += schat.dur4;
                    }
                    if(schat.dur5 > 0) {
                        duration_greaterthan15 += schat.dur5;
                    }
                    /* chat duration end */
                });
            }
        }

        if (this.state.date_range === "custom") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(parseInt(this.state.start_date)).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let dayAddedOn = tmpdate.format("DD");
                    let monthAddedOn = tmpdate.format("MM");
                    let yearAddedOn = tmpdate.format("YYYY");
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: dtTimestamp, day: createIndex });
                }

                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined && tmpCurrServedChats[day].length > 0) {
                        let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_data_served.push(tmpTotal);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }
                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_ratings.push(tmpTotal);
                    } else {
                        series_ratings.push(0);
                    }

                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined && tmpCurrServedChats[day].length > 0) {
                        let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_data_served.push(tmpTotal);
                    } else {
                        series_data_served.push(0);
                    }
                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_ratings.push(tmpTotal);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "30_days") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i <= 29; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    const splitDate = tmpdate.split("-");
                    let dayAddedOn = splitDate[2];
                    let monthAddedOn = splitDate[1];
                    let yearAddedOn = splitDate[0];
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
                }
                tmpCategories.reverse();
    
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_data_served.push(tmpTotal);
                    } else {
                        series_data_served.push(0);
                    }
    
                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgInMinutes = 0;
                        if (resTimeSum > 0) {
                            let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                            avgInMinutes = Math.ceil(avgResponseTime / 60);
                        }
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }
    
                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }
    
                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_ratings.push(tmpTotal);
                    } else {
                        series_ratings.push(0);
                    }
                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined && tmpCurrServedChats[day].length > 0) {
                        let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_data_served.push(tmpTotal);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_ratings.push(tmpTotal);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "14_days") {
            if (this.state.chart_base === "weekly") {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            } else {
                for (var i = 0; i <= 13; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    const splitDate = tmpdate.split("-");
                    let dayAddedOn = splitDate[2];
                    let monthAddedOn = splitDate[1];
                    let yearAddedOn = splitDate[0];
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
                }
                tmpCategories.reverse();
    
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_data_served.push(tmpTotal);
                    } else {
                        series_data_served.push(0);
                    }
    
                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgInMinutes = 0;
                        if (resTimeSum > 0) {
                            let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                            avgInMinutes = Math.ceil(avgResponseTime / 60);
                        }
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }
    
                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }
    
                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        series_ratings.push(tmpTotal);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "7_days") {
            for (var i = 0; i <= 6; i++) {
                let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                const splitDate = tmpdate.split("-");
                let dayAddedOn = splitDate[2];
                let monthAddedOn = splitDate[1];
                let yearAddedOn = splitDate[0];
                let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
            }
            tmpCategories.reverse();

            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const day = mydate.day;
                if (tmpCurrServedChats[day] !== undefined) {
                    let tmpTotal = tmpCurrServedChats[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    series_data_served.push(tmpTotal);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                    let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        avgInMinutes = Math.ceil(avgResponseTime / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                    let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                    let avgInMinutes = Math.ceil(avgChatDur / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                    let tmpTotal = tmpRatings[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    series_ratings.push(tmpTotal);
                } else {
                    series_ratings.push(0);
                }
            });
        } else if (this.state.date_range === "today") {
            let todayHour =  parseInt(moment().tz(this.agent.org_timezone).format("HH"));
            for (var i = 0; i <= todayHour; i++) {
                let tmpdate2 = new Date();
                tmpdate2 = tmpdate2.getTime();
                let tmpHourrr = (i).toString().padStart(2, "0") + ":00:00";
                let timeWithHour = moment(tmpdate2).tz(this.agent.org_timezone).format("YYYY-MM-DD "+tmpHourrr);
                let timestampp = parseInt(moment.tz(timeWithHour, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                let monthAddedOn = moment(tmpdate2).tz(this.agent.org_timezone).format("MM");
                let yearAddedOn = moment(tmpdate2).tz(this.agent.org_timezone).format("YYYY");
                let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: timestampp, hour: createIndex });
            }

            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const hour = mydate.hour;
                if (tmpCurrServedChats[hour] !== undefined) {
                    series_data_served.push(tmpCurrServedChats[hour]);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[hour] !== undefined && tmpResponseTime[hour] > 0) {
                    let resTimeSum = tmpResponseTime[hour];
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        avgInMinutes = Math.ceil(tmpResponseTime[hour] / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[hour] !== undefined && tmpChatDuration[hour] > 0) {
                    let chatDurSum = tmpChatDuration[hour];
                    let avgInMinutes = Math.ceil(chatDurSum / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[hour] !== undefined && tmpRatings[hour] > 0) {
                    series_ratings.push(tmpRatings[hour]);
                } else {
                    series_ratings.push(0);
                }
            });
        }

        /* For charting end  */
        series = [{ name: 'Chats completed', color: '#77c66d', data: series_data_served }];
        options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };
        chatTiming_series = [{ name: 'Response Time', color: '#5bcec1', data: series_response_time }, { name: 'Chat Duration', color: '#edbc4d', data: series_chat_duration }];
        chatTiming_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_series = [{ name: 'Chats rated', data: series_ratings }];
        response_series = [{ name: 'Number of Chats', data: [response_lessthan15, response_inbtw15_30, response_inbtw31_45, response_inbtw46_60, response_greaterthan60] }];
        chatduration_series = [{ name: 'Number of Chats', data: [duration_lessthan10, duration_inbtw10_30, duration_inbtw31_60, duration_inbtw1h_15, duration_greaterthan15] }];

        /* NPS start */

        /* for previous period ratings start */

        let prevTotalRatedChats = 0;
        let prevSumOfRatedChats = 0;

        previouschats.forEach(single_chat => {
            prevTotalRatedChats += single_chat.chats_rated;
            prevSumOfRatedChats += single_chat.ratings_sum;
        });

        /* for previous period ratings End */

        let totalRatedPoints = (totalRatedChats * 5);
        var goodPercentage = Math.ceil((sumOfRatedChats / totalRatedPoints) * 100);
        if (isNaN(goodPercentage)) {
            goodPercentage = 0;
        }
        var badPercentage = 100 - goodPercentage;
        var nps_series = [goodPercentage, badPercentage];
        let goodPrevPercentage = 0;
        let totalPrevRatedPoints = prevTotalRatedChats * 5;
        goodPrevPercentage = Math.ceil((prevSumOfRatedChats / totalPrevRatedPoints) * 100);
        if (isNaN(goodPrevPercentage)) {
            goodPrevPercentage = 0;
        }
       
        /* NPS end */

        /* Get percentage start */

        let tooltip_nps_rating = "";
        let npscurrentAndPrev = "";
        if (goodPercentage === goodPrevPercentage) {
            npscurrentAndPrev = "equal";
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%.";
        } else if (goodPercentage > goodPrevPercentage) {
            npscurrentAndPrev = "up";
            let diffpr = goodPercentage - goodPrevPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Increase of " + diffpr + "%";
        } else {
            npscurrentAndPrev = "down";
            let diffpr = goodPrevPercentage - goodPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Decrease of " + diffpr + "%";
        }

        let chatRatedPercentage = (totalRatedChats * 100) / sumCurrChatTotal;
        chatRatedPercentage = chatRatedPercentage.toFixed(2);
        if (isNaN(chatRatedPercentage)) {
            chatRatedPercentage = 0;
        }

        /* Get percentage end  */
        this.setState({ chats_total: sumCurrChatTotal, tooltip_nps_rating: tooltip_nps_rating, series: series, chatTiming_series: chatTiming_series, response_series: response_series, chatduration_series: chatduration_series, satisfaction_series: satisfaction_series, nps_series: nps_series, total_rated_chats: totalRatedChats, chatRatedPercentage: chatRatedPercentage }, () => {
            this.setState({ options: options, chatTiming_options: chatTiming_options, satisfaction_options: satisfaction_options, npscurrentAndPrev: npscurrentAndPrev, loader: false, nps_options: nps_options });
        });
    }

    loadZoneChats = () => {
        var currentchats = this.state.currentchats;
        var previouschats = this.state.previouschats;
        var options = this.state.options;
        var series = this.state.series;
        var chatTiming_options = this.state.chatTiming_options;
        var chatTiming_series = this.state.chatTiming_series;
        var response_series = this.state.response_series;
        var chatduration_series = this.state.chatduration_series;
        var satisfaction_options = this.state.satisfaction_options;
        var satisfaction_series = this.state.satisfaction_series;
        var nps_series = this.state.nps_series;
        var nps_options = this.state.nps_options;

        this.setState({ series: [], options: {}, chatTiming_options: {}, chatTiming_series: [], response_series: [], chatduration_series: [], satisfaction_options: {}, satisfaction_series: [], nps_series: [], total_rated_chats: 0, nps_options: {} });

        /* For charting start */

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let xaxis_categories = [];
        let xaxis_type = "category";
        let series_data_served = [];
        let tmpCurrServedChats = [];

        let tmpResponseTime = [];
        let tmpChatDuration = [];
        let series_response_time = [];
        let series_chat_duration = [];

        let response_lessthan15 = 0;
        let response_inbtw15_30 = 0;
        let response_inbtw31_45 = 0;
        let response_inbtw46_60 = 0;
        let response_greaterthan60 = 0;

        let duration_lessthan10 = 0;
        let duration_inbtw10_30 = 0;
        let duration_inbtw31_60 = 0;
        let duration_inbtw1h_15 = 0;
        let duration_greaterthan15 = 0;

        let tmpRatings = [];
        let tmpServedRatings = [];
        let tmpMissedRatings = [];
        let series_ratings = [];
        let tmpCategories = [];
        let tmpPrevRatings = [];

        if (this.state.chart_base === "daily") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let tmpTimezoneDate = moment(schat.addedon).tz(this.agent.org_timezone);
                    let dateAddedOn = tmpTimezoneDate.format("DD");
                    let monthAddedOn = tmpTimezoneDate.format("MM");
                    let yearAddedOn = tmpTimezoneDate.format("YYYY");

                    let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = [];
                    }
                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = [];
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = [];
                    }
                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = [];
                    }
                    tmpCurrServedChats[createIndex].push(schat.chat_id);
                    let restime = schat.response_time === null ? 0 : schat.response_time;
                    let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;

                    tmpResponseTime[createIndex].push(restime);
                    tmpChatDuration[createIndex].push(chatduration);

                    let rating = schat.rating === null ? 0 : schat.rating;
                    if (rating > 0) {
                        tmpServedRatings.push(rating);
                        tmpRatings[createIndex].push(rating);
                    }

                    /* Response time chats start */
                    if (restime < 15) {
                        response_lessthan15++;
                    } else if (restime >= 15 && restime <= 30) {
                        response_inbtw15_30++;
                    } else if (restime >= 31 && restime <= 45) {
                        response_inbtw31_45++;
                    } else if (restime >= 46 && restime <= 60) {
                        response_inbtw46_60++;
                    } else if (restime > 60) {
                        response_greaterthan60++;
                    }
                    /* Response time chats end */

                    /* chat duration start */
                    if (chatduration < 600) {
                        duration_lessthan10++;
                    } else if (chatduration >= 600 && chatduration <= 1800) {
                        duration_inbtw10_30++;
                    } else if (chatduration >= 1801 && chatduration <= 3600) {
                        duration_inbtw31_60++;
                    } else if (chatduration >= 3601 && chatduration <= 5400) {
                        duration_inbtw1h_15++;
                    } else if (chatduration > 5401) {
                        duration_greaterthan15++;
                    }
                    /* chat duration end */
                });
            }
        } else if (this.state.chart_base === "weekly") {
            if (this.state.date_range === "custom") {
                let stttDate = parseInt(this.state.start_date);
                let stttEndDate = parseInt(this.state.end_date);
                let endDateFormat = moment(stttEndDate).format("YYYY-MM-DD");
                let tmpEndDate222 = moment(endDateFormat).parseZone().tz(this.agent.org_timezone, true);
                let endDateTimestamp = parseInt(tmpEndDate222.format("x"));
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(stttDate).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let datt = tmpdate.format("DD");
                    let month = tmpdate.format("MM");
                    let year = tmpdate.format("YYYY");
                    let dayOfWeek = parseInt(tmpdate.format("d"));
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));

                    let totalWeekDays = 6 - dayOfWeek;
                    i = i + totalWeekDays;

                    let tmpdate22 = moment(stttDate).add(i, 'days');
                    
                    let toDate22;
                    let gtTime22 = dtTimestamp;

                    if (gtTime22 > endDateTimestamp) {
                        tmpdate22 = tmpEndDate222;
                        toDate22 = endDateTimestamp;
                    } else {
                        let tmpDateOnly22 = tmpdate22.format("YYYY-MM-DD");
                        toDate22 = parseInt(moment(tmpDateOnly22).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    }
                    let datt22 = tmpdate22.format("DD");
                    let month22 = tmpdate22.format("MM");
                    let year22 = tmpdate22.format("YYYY");
                    let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                    tmpCategories.push({ fromDate: dtTimestamp, toDate: toDate22, day: createIndex });
                }
            } else {
                var maxDays = 0
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    let dayOfWeek = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("d"));
                    const splitDate = tmpdate.split("-");
                    let datt = splitDate[2];
                    let month = splitDate[1];
                    let year = splitDate[0];
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ fromDate: tmpdateTimestamp, toDate: (tmpdateTimestamp + 86399000), day: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                        const tmpdateTimestamp22 = parseInt(moment.tz(tmpdate22, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                        const splitDate22 = tmpdate22.split("-");
                        let datt22 = splitDate22[2];
                        let month22 = splitDate22[1];
                        let year22 = splitDate22[0];
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ fromDate: tmpdateTimestamp22, toDate: (tmpdateTimestamp + 86399000), day: createIndex });
                    }
                }
                tmpCategories.reverse();
            }
            currentchats.forEach(schat => {
                let tmpTimezoneDate = moment(schat.addedon).tz(this.agent.org_timezone);
                let timeAddedOn = parseInt(tmpTimezoneDate.format("x"));
                tmpCategories.forEach(valObj => {
                    if (timeAddedOn >= valObj.fromDate && timeAddedOn <= valObj.toDate) {
                        let createIndex = valObj.day;
                        if (tmpCurrServedChats[createIndex] === undefined) {
                            tmpCurrServedChats[createIndex] = [];
                        }
                        if (tmpResponseTime[createIndex] === undefined) {
                            tmpResponseTime[createIndex] = [];
                        }
                        if (tmpChatDuration[createIndex] === undefined) {
                            tmpChatDuration[createIndex] = [];
                        }
                        tmpCurrServedChats[createIndex].push(schat.chat_id);
                        let restime = schat.response_time === null ? 0 : schat.response_time;
                        let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;
                        tmpResponseTime[createIndex].push(restime);
                        tmpChatDuration[createIndex].push(chatduration);
                        if (tmpRatings[createIndex] === undefined) {
                            tmpRatings[createIndex] = [];
                        }
                        let rating = schat.rating === null ? 0 : schat.rating;
                        if (rating > 0) {
                            tmpServedRatings.push(rating);
                            tmpRatings[createIndex].push(rating);
                        }

                        /* Response time chats start */
                        if (restime < 15) {
                            response_lessthan15++;
                        } else if (restime >= 15 && restime <= 30) {
                            response_inbtw15_30++;
                        } else if (restime >= 31 && restime <= 45) {
                            response_inbtw31_45++;
                        } else if (restime >= 46 && restime <= 60) {
                            response_inbtw46_60++;
                        } else if (restime > 60) {
                            response_greaterthan60++;
                        }
                        /* Response time chats end */

                        /* chat duration start */
                        if (chatduration < 600) {
                            duration_lessthan10++;
                        } else if (chatduration >= 600 && chatduration <= 1800) {
                            duration_inbtw10_30++;
                        } else if (chatduration >= 1801 && chatduration <= 3600) {
                            duration_inbtw31_60++;
                        } else if (chatduration >= 3601 && chatduration <= 5400) {
                            duration_inbtw1h_15++;
                        } else if (chatduration > 5401) {
                            duration_greaterthan15++;
                        }
                        /* chat duration end */
                    }
                });
            });
        } else if (this.state.chart_base === "hourly") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let tmpTimezoneDate = moment(schat.addedon).tz(this.agent.org_timezone);
                    let hourAddedOn = parseInt(tmpTimezoneDate.format("HH"));
                    let monthAddedOn = tmpTimezoneDate.format("MM");
                    let yearAddedOn = tmpTimezoneDate.format("YYYY");

                    let createIndex = hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = [];
                    }
                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = [];
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = [];
                    }
                    tmpCurrServedChats[createIndex].push(schat.chat_id);
                    let restime = schat.response_time === null ? 0 : schat.response_time;
                    let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;
                    tmpResponseTime[createIndex].push(restime);
                    tmpChatDuration[createIndex].push(chatduration);

                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = [];
                    }
                    let rating = schat.rating === null ? 0 : schat.rating;
                    if (rating > 0) {
                        tmpServedRatings.push(rating);
                        tmpRatings[createIndex].push(rating);
                    }

                    /* Response time chats start */
                    if (restime < 15) {
                        response_lessthan15++;
                    } else if (restime >= 15 && restime <= 30) {
                        response_inbtw15_30++;
                    } else if (restime >= 31 && restime <= 45) {
                        response_inbtw31_45++;
                    } else if (restime >= 46 && restime <= 60) {
                        response_inbtw46_60++;
                    } else if (restime > 60) {
                        response_greaterthan60++;
                    }
                    /* Response time chats end */

                    /* chat duration start */
                    if (chatduration < 600) {
                        duration_lessthan10++;
                    } else if (chatduration >= 600 && chatduration <= 1800) {
                        duration_inbtw10_30++;
                    } else if (chatduration >= 1801 && chatduration <= 3600) {
                        duration_inbtw31_60++;
                    } else if (chatduration >= 3601 && chatduration <= 5400) {
                        duration_inbtw1h_15++;
                    } else if (chatduration > 5401) {
                        duration_greaterthan15++;
                    }
                    /* chat duration end */

                });
            }
        }

        if (this.state.date_range === "custom") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = moment(parseInt(this.state.start_date)).add(i, 'days');
                    let tmpDateOnly = tmpdate.format("YYYY-MM-DD");
                    let dayAddedOn = tmpdate.format("DD");
                    let monthAddedOn = tmpdate.format("MM");
                    let yearAddedOn = tmpdate.format("YYYY");
                    let dtTimestamp = parseInt(moment(tmpDateOnly).parseZone().tz(this.agent.org_timezone, true).format("x"));
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: dtTimestamp, day: createIndex });
                }
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }
                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }

                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }
                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "30_days") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i <= 29; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    const splitDate = tmpdate.split("-");
                    let dayAddedOn = splitDate[2];
                    let monthAddedOn = splitDate[1];
                    let yearAddedOn = splitDate[0];
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
                }
                tmpCategories.reverse();
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }

                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "14_days") {
            if (this.state.chart_base === "weekly") {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            } else {
                for (var i = 0; i <= 13; i++) {
                    let tmpdate = moment().tz(this.agent.org_timezone).subtract(i, 'd').format('YYYY-MM-DD');
                    const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                    const splitDate = tmpdate.split("-");
                    let dayAddedOn = splitDate[2];
                    let monthAddedOn = splitDate[1];
                    let yearAddedOn = splitDate[0];
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
                }
                tmpCategories.reverse();
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "7_days") {
            for(var i = 0; i <= 6; i++) {
                let tmpdate = moment().tz(this.agent.org_timezone).subtract(i,'d').format('YYYY-MM-DD');
                const tmpdateTimestamp = parseInt(moment.tz(tmpdate, 'YYYY-MM-DD', this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                const splitDate = tmpdate.split("-");
                let dayAddedOn = splitDate[2];
                let monthAddedOn = splitDate[1];
                let yearAddedOn = splitDate[0];
                let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: tmpdateTimestamp, day: createIndex });
            }
            tmpCategories.reverse();
            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const day = mydate.day;
                if (tmpCurrServedChats[day] !== undefined) {
                    series_data_served.push(tmpCurrServedChats[day].length);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                    let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        avgInMinutes = Math.ceil(avgResponseTime / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                    let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                    let avgInMinutes = Math.ceil(avgChatDur / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                    series_ratings.push(tmpRatings[day].length);
                } else {
                    series_ratings.push(0);
                }
            });
        } else if (this.state.date_range === "today") {
            let todayHour =  parseInt(moment().tz(this.agent.org_timezone).format("HH"));
            for (var i = 0; i <= todayHour; i++) {
                let tmpdate2 = new Date();
                tmpdate2 = tmpdate2.getTime();
                let tmpHourrr = (i).toString().padStart(2, "0") + ":00:00";
                let timeWithHour = moment(tmpdate2).tz(this.agent.org_timezone).format("YYYY-MM-DD "+tmpHourrr);
                let timestampp = parseInt(moment.tz(timeWithHour, "YYYY-MM-DD HH:mm:ss", this.agent.org_timezone).tz(this.agent.org_timezone).format("x"));
                let monthAddedOn = moment(tmpdate2).tz(this.agent.org_timezone).format("MM");
                let yearAddedOn = moment(tmpdate2).tz(this.agent.org_timezone).format("YYYY");
                let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: timestampp, hour: createIndex });
            }

            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const hour = mydate.hour;
                if (tmpCurrServedChats[hour] !== undefined) {
                    series_data_served.push(tmpCurrServedChats[hour].length);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[hour] !== undefined && tmpResponseTime[hour].length > 0) {
                    let resTimeSum = tmpResponseTime[hour].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[hour].length);
                        avgInMinutes = Math.ceil(avgResponseTime / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[hour] !== undefined && tmpChatDuration[hour].length > 0) {
                    let chatDurSum = tmpChatDuration[hour].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[hour].length);
                    let avgInMinutes = Math.ceil(avgChatDur / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[hour] !== undefined && tmpRatings[hour].length > 0) {
                    series_ratings.push(tmpRatings[hour].length);
                } else {
                    series_ratings.push(0);
                }
            });
        }

        /* For charting end  */
        series = [{ name: 'Chats completed', color: '#77c66d', data: series_data_served }];
        options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };
        chatTiming_series = [{ name: 'Response Time', color: '#5bcec1', data: series_response_time }, { name: 'Chat Duration', color: '#edbc4d', data: series_chat_duration }];
        chatTiming_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_series = [{ name: 'Chats rated', data: series_ratings }];
        response_series = [{ name: 'Number of Chats', data: [response_lessthan15, response_inbtw15_30, response_inbtw31_45, response_inbtw46_60, response_greaterthan60] }];
        chatduration_series = [{ name: 'Number of Chats', data: [duration_lessthan10, duration_inbtw10_30, duration_inbtw31_60, duration_inbtw1h_15, duration_greaterthan15] }];

        /* NPS start */

        /* for previous period ratings start */

        previouschats.forEach(single_chat => {
            let rating = single_chat.rating === null ? 0 : single_chat.rating;
            if (rating > 0) {
                tmpPrevRatings.push(rating);
            }
        });

        let tmpPrevRatedChatsSum = tmpPrevRatings.reduce((all, item) => {
            all += item;
            return all;
        }, 0);

        /* for previous period ratings End */

        let allratedChats = tmpServedRatings.concat(tmpMissedRatings);
        let tmpRatedChatsSum = allratedChats.reduce((all, item) => {
            all += item;
            return all;
        }, 0);
        let totalRatedPoints = (allratedChats.length * 5);
        var goodPercentage = Math.ceil((tmpRatedChatsSum / totalRatedPoints) * 100);
        if (isNaN(goodPercentage)) {
            goodPercentage = 0;
        }
        var badPercentage = 100 - goodPercentage;
        var nps_series = [goodPercentage, badPercentage];
        var goodPrevPercentage = 0;
        let allPrevRatedChats = tmpPrevRatings.length;
        if (allPrevRatedChats > 0) {
            let totalPrevRatedPoints = allPrevRatedChats * 5;
            goodPrevPercentage = Math.ceil((tmpPrevRatedChatsSum / totalPrevRatedPoints) * 100);
            if (isNaN(goodPrevPercentage)) {
                goodPrevPercentage = 0;
            }
        }
        /* NPS end */

        /* Get percentage start */

        let sumCurrChatTotal = currentchats.length;
        let tooltip_nps_rating = "";
        let npscurrentAndPrev = "";
        if (goodPercentage === goodPrevPercentage) {
            npscurrentAndPrev = "equal";
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%.";
        } else if (goodPercentage > goodPrevPercentage) {
            npscurrentAndPrev = "up";
            let diffpr = goodPercentage - goodPrevPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Increase of " + diffpr + "%";
        } else {
            npscurrentAndPrev = "down";
            let diffpr = goodPrevPercentage - goodPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Decrease of " + diffpr + "%";
        }

        let chatRatedPercentage = (allratedChats.length * 100) / sumCurrChatTotal;
        chatRatedPercentage = chatRatedPercentage.toFixed(2);
        if (isNaN(chatRatedPercentage)) {
            chatRatedPercentage = 0;
        }

        /* Get percentage end  */
        this.setState({ chats_total: sumCurrChatTotal, tooltip_nps_rating: tooltip_nps_rating, series: series, chatTiming_series: chatTiming_series, response_series: response_series, chatduration_series: chatduration_series, satisfaction_series: satisfaction_series, nps_series: nps_series, total_rated_chats: allratedChats.length, chatRatedPercentage: chatRatedPercentage }, () => {
            this.setState({ options: options, chatTiming_options: chatTiming_options, satisfaction_options: satisfaction_options, npscurrentAndPrev: npscurrentAndPrev, loader: false, nps_options: nps_options });
        });
    }

    loadChats = () => {

        var currentchats = this.state.currentchats;
        var previouschats = this.state.previouschats;
        var options = this.state.options;
        var series = this.state.series;
        var chatTiming_options = this.state.chatTiming_options;
        var chatTiming_series = this.state.chatTiming_series;
        var response_series = this.state.response_series;
        var chatduration_series = this.state.chatduration_series;
        var satisfaction_options = this.state.satisfaction_options;
        var satisfaction_series = this.state.satisfaction_series;
        var nps_series = this.state.nps_series;
        var nps_options = this.state.nps_options;

        this.setState({ series: [], options: {}, chatTiming_options: {}, chatTiming_series: [], response_series: [], chatduration_series: [], satisfaction_options: {}, satisfaction_series: [], nps_series: [], total_rated_chats: 0, nps_options: {} });

        /* For charting start */

        let today = new Date();
        today.setHours(0, 0, 0, 0);

        let xaxis_categories = [];
        let xaxis_type = "category";
        let series_data_served = [];
        let tmpCurrServedChats = [];

        let tmpResponseTime = [];
        let tmpChatDuration = [];
        let series_response_time = [];
        let series_chat_duration = [];

        let response_lessthan15 = 0;
        let response_inbtw15_30 = 0;
        let response_inbtw31_45 = 0;
        let response_inbtw46_60 = 0;
        let response_greaterthan60 = 0;

        let duration_lessthan10 = 0;
        let duration_inbtw10_30 = 0;
        let duration_inbtw31_60 = 0;
        let duration_inbtw1h_15 = 0;
        let duration_greaterthan15 = 0;

        let tmpRatings = [];
        let tmpServedRatings = [];
        let tmpMissedRatings = [];
        let series_ratings = [];
        let tmpCategories = [];
        let tmpPrevRatings = [];

        if (this.state.chart_base === "daily") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let addedon = new Date(schat.addedon);
                    let dateAddedOn = addedon.getDate();
                    let monthAddedOn = addedon.getMonth() + 1;
                    let yearAddedOn = addedon.getFullYear();
                    let createIndex = dateAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = [];
                    }
                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = [];
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = [];
                    }
                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = [];
                    }
                    tmpCurrServedChats[createIndex].push(schat.chat_id);
                    let restime = schat.response_time === null ? 0 : schat.response_time;
                    let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;

                    tmpResponseTime[createIndex].push(restime);
                    tmpChatDuration[createIndex].push(chatduration);

                    let rating = schat.rating === null ? 0 : schat.rating;
                    if (rating > 0) {
                        tmpServedRatings.push(rating);
                        tmpRatings[createIndex].push(rating);
                    }

                    /* Response time chats start */
                    if (restime < 15) {
                        response_lessthan15++;
                    } else if (restime >= 15 && restime <= 30) {
                        response_inbtw15_30++;
                    } else if (restime >= 31 && restime <= 45) {
                        response_inbtw31_45++;
                    } else if (restime >= 46 && restime <= 60) {
                        response_inbtw46_60++;
                    } else if (restime > 60) {
                        response_greaterthan60++;
                    }
                    /* Response time chats end */

                    /* chat duration start */
                    if (chatduration < 600) {
                        duration_lessthan10++;
                    } else if (chatduration >= 600 && chatduration <= 1800) {
                        duration_inbtw10_30++;
                    } else if (chatduration >= 1801 && chatduration <= 3600) {
                        duration_inbtw31_60++;
                    } else if (chatduration >= 3601 && chatduration <= 5400) {
                        duration_inbtw1h_15++;
                    } else if (chatduration > 5401) {
                        duration_greaterthan15++;
                    }
                    /* chat duration end */
                });
            }
        } else if (this.state.chart_base === "weekly") {
            let stttDate = parseInt(this.state.start_date);
            let stttEndDate = parseInt(this.state.end_date);
            if (this.state.date_range === "custom") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = new Date(stttDate);
                    tmpdate.setDate(tmpdate.getDate() + i);
                    let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                    let datt = tmpdate.getDate();
                    let month = tmpdate.getMonth() + 1;
                    let year = tmpdate.getFullYear();
                    let totalWeekDays = 6 - dayOfWeek;
                    i = i + totalWeekDays;

                    let tmpdate22 = new Date(stttDate);
                    tmpdate22.setDate(tmpdate22.getDate() + i);
                    let toDate22;
                    let gtTime22 = tmpdate22.getTime();

                    if (gtTime22 > stttEndDate) {
                        tmpdate22 = new Date(stttEndDate);
                        toDate22 = stttEndDate;
                    } else {
                        toDate22 = (gtTime22 + 86399000);
                    }
                    let datt22 = tmpdate22.getDate();
                    let month22 = tmpdate22.getMonth() + 1;
                    let year22 = tmpdate22.getFullYear();
                    let createIndex = datt + "-" + datt22 + "_" + month + "-" + month22 + "_" + year + "-" + year22;
                    tmpCategories.push({ fromDate: tmpdate.getTime(), toDate: toDate22, day: createIndex });
                }
            } else {
                var maxDays = 0
                if (this.state.date_range === "14_days") {
                    maxDays = 13;
                } else if (this.state.date_range === "30_days") {
                    maxDays = 29;
                }
                for (var i = 0; i <= maxDays; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayOfWeek = parseInt(moment(tmpdate).format('d'));
                    let datt = tmpdate.getDate();
                    let month = tmpdate.getMonth() + 1;
                    let year = tmpdate.getFullYear();
                    let createIndex;
                    if (dayOfWeek === 0) {
                        createIndex = datt + "-" + datt + "_" + month + "-" + month + "_" + year + "-" + year;
                        tmpCategories.push({ fromDate: tmpdate.getTime(), toDate: (tmpdate.getTime() + 86399000), day: createIndex });
                    } else {
                        i = i + dayOfWeek;
                        if (i > maxDays) {
                            i = maxDays;
                        }
                        let tmpdate22 = new Date(today);
                        tmpdate22.setDate(tmpdate22.getDate() - i);
                        let datt22 = tmpdate22.getDate();
                        let month22 = tmpdate22.getMonth() + 1;
                        let year22 = tmpdate22.getFullYear();
                        createIndex = datt22 + "-" + datt + "_" + month22 + "-" + month + "_" + year22 + "-" + year;
                        tmpCategories.push({ fromDate: tmpdate22.getTime(), toDate: (tmpdate.getTime() + 86399000), day: createIndex });
                    }
                }
                tmpCategories.reverse();
            }

            currentchats.forEach(schat => {
                let addedon = new Date(schat.addedon);
                let timeAddedOn = addedon.getTime();
                tmpCategories.forEach(valObj => {
                    if (timeAddedOn >= valObj.fromDate && timeAddedOn <= valObj.toDate) {

                        let createIndex = valObj.day;
                        if (tmpCurrServedChats[createIndex] === undefined) {
                            tmpCurrServedChats[createIndex] = [];
                        }
                        if (tmpResponseTime[createIndex] === undefined) {
                            tmpResponseTime[createIndex] = [];
                        }
                        if (tmpChatDuration[createIndex] === undefined) {
                            tmpChatDuration[createIndex] = [];
                        }
                        tmpCurrServedChats[createIndex].push(schat.chat_id);
                        let restime = schat.response_time === null ? 0 : schat.response_time;
                        let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;
                        tmpResponseTime[createIndex].push(restime);
                        tmpChatDuration[createIndex].push(chatduration);
                        if (tmpRatings[createIndex] === undefined) {
                            tmpRatings[createIndex] = [];
                        }
                        let rating = schat.rating === null ? 0 : schat.rating;
                        if (rating > 0) {
                            tmpServedRatings.push(rating);
                            tmpRatings[createIndex].push(rating);
                        }

                        /* Response time chats start */
                        if (restime < 15) {
                            response_lessthan15++;
                        } else if (restime >= 15 && restime <= 30) {
                            response_inbtw15_30++;
                        } else if (restime >= 31 && restime <= 45) {
                            response_inbtw31_45++;
                        } else if (restime >= 46 && restime <= 60) {
                            response_inbtw46_60++;
                        } else if (restime > 60) {
                            response_greaterthan60++;
                        }
                        /* Response time chats end */

                        /* chat duration start */
                        if (chatduration < 600) {
                            duration_lessthan10++;
                        } else if (chatduration >= 600 && chatduration <= 1800) {
                            duration_inbtw10_30++;
                        } else if (chatduration >= 1801 && chatduration <= 3600) {
                            duration_inbtw31_60++;
                        } else if (chatduration >= 3601 && chatduration <= 5400) {
                            duration_inbtw1h_15++;
                        } else if (chatduration > 5401) {
                            duration_greaterthan15++;
                        }
                        /* chat duration end */
                    }
                });
            });
        } else if (this.state.chart_base === "hourly") {
            if (currentchats.length > 0) {
                currentchats.forEach(schat => {
                    let addedon = new Date(schat.addedon);
                    let hourAddedOn = addedon.getHours();
                    let monthAddedOn = addedon.getMonth() + 1;
                    let yearAddedOn = addedon.getFullYear();

                    let createIndex = hourAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;

                    if (tmpCurrServedChats[createIndex] === undefined) {
                        tmpCurrServedChats[createIndex] = [];
                    }
                    if (tmpResponseTime[createIndex] === undefined) {
                        tmpResponseTime[createIndex] = [];
                    }
                    if (tmpChatDuration[createIndex] === undefined) {
                        tmpChatDuration[createIndex] = [];
                    }
                    tmpCurrServedChats[createIndex].push(schat.chat_id);
                    let restime = schat.response_time === null ? 0 : schat.response_time;
                    let chatduration = schat.chat_duration === null ? 0 : schat.chat_duration;
                    tmpResponseTime[createIndex].push(restime);
                    tmpChatDuration[createIndex].push(chatduration);

                    if (tmpRatings[createIndex] === undefined) {
                        tmpRatings[createIndex] = [];
                    }
                    let rating = schat.rating === null ? 0 : schat.rating;
                    if (rating > 0) {
                        tmpServedRatings.push(rating);
                        tmpRatings[createIndex].push(rating);
                    }

                    /* Response time chats start */
                    if (restime < 15) {
                        response_lessthan15++;
                    } else if (restime >= 15 && restime <= 30) {
                        response_inbtw15_30++;
                    } else if (restime >= 31 && restime <= 45) {
                        response_inbtw31_45++;
                    } else if (restime >= 46 && restime <= 60) {
                        response_inbtw46_60++;
                    } else if (restime > 60) {
                        response_greaterthan60++;
                    }
                    /* Response time chats end */

                    /* chat duration start */
                    if (chatduration < 600) {
                        duration_lessthan10++;
                    } else if (chatduration >= 600 && chatduration <= 1800) {
                        duration_inbtw10_30++;
                    } else if (chatduration >= 1801 && chatduration <= 3600) {
                        duration_inbtw31_60++;
                    } else if (chatduration >= 3601 && chatduration <= 5400) {
                        duration_inbtw1h_15++;
                    } else if (chatduration > 5401) {
                        duration_greaterthan15++;
                    }
                    /* chat duration end */

                });
            }
        }

        if (this.state.date_range === "custom") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i < this.state.numberOfDays; i++) {
                    let tmpdate = new Date(parseInt(this.state.start_date));
                    tmpdate.setDate(tmpdate.getDate() + i);
                    let dayAddedOn = tmpdate.getDate();
                    let monthAddedOn = tmpdate.getMonth() + 1;
                    let yearAddedOn = tmpdate.getFullYear();
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
                }

                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }
                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }

                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }
                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "30_days") {
            if (this.state.chart_base === "daily") {
                for (var i = 0; i <= 29; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayAddedOn = tmpdate.getDate();
                    let monthAddedOn = tmpdate.getMonth() + 1;
                    let yearAddedOn = tmpdate.getFullYear();
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
                }
                tmpCategories.reverse();
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }

                });
            } else {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "14_days") {
            if (this.state.chart_base === "weekly") {
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.fromDate);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            } else {
                for (var i = 0; i <= 13; i++) {
                    let tmpdate = new Date(today);
                    tmpdate.setDate(tmpdate.getDate() - i);
                    let dayAddedOn = tmpdate.getDate();
                    let monthAddedOn = tmpdate.getMonth() + 1;
                    let yearAddedOn = tmpdate.getFullYear();
                    let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                    tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
                }
                tmpCategories.reverse();
                tmpCategories.forEach(mydate => {
                    xaxis_categories.push(mydate.date);
                    const day = mydate.day;
                    if (tmpCurrServedChats[day] !== undefined) {
                        series_data_served.push(tmpCurrServedChats[day].length);
                    } else {
                        series_data_served.push(0);
                    }

                    if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                        let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        let avgInMinutes = Math.ceil(avgResponseTime / 60);
                        series_response_time.push(avgInMinutes);
                    } else {
                        series_response_time.push(0);
                    }

                    if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                        let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                            all += item;
                            return all;
                        }, 0);
                        let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                        let avgInMinutes = Math.ceil(avgChatDur / 60);
                        series_chat_duration.push(avgInMinutes);
                    } else {
                        series_chat_duration.push(0);
                    }

                    if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                        series_ratings.push(tmpRatings[day].length);
                    } else {
                        series_ratings.push(0);
                    }
                });
            }
        } else if (this.state.date_range === "7_days") {
            for (var i = 0; i <= 6; i++) {
                let tmpdate = new Date(today);
                tmpdate.setDate(tmpdate.getDate() - i);
                let dayAddedOn = tmpdate.getDate();
                let monthAddedOn = tmpdate.getMonth() + 1;
                let yearAddedOn = tmpdate.getFullYear();
                let createIndex = dayAddedOn + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: tmpdate.getTime(), day: createIndex });
            }
            tmpCategories.reverse();
            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const day = mydate.day;
                if (tmpCurrServedChats[day] !== undefined) {
                    series_data_served.push(tmpCurrServedChats[day].length);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[day] !== undefined && tmpResponseTime[day].length > 0) {
                    let resTimeSum = tmpResponseTime[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[day].length);
                        avgInMinutes = Math.ceil(avgResponseTime / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[day] !== undefined && tmpChatDuration[day].length > 0) {
                    let chatDurSum = tmpChatDuration[day].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[day].length);
                    let avgInMinutes = Math.ceil(avgChatDur / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[day] !== undefined && tmpRatings[day].length > 0) {
                    series_ratings.push(tmpRatings[day].length);
                } else {
                    series_ratings.push(0);
                }
            });
        } else if (this.state.date_range === "today") {
            let tmpdate = new Date();
            for (var i = 0; i <= tmpdate.getHours(); i++) {
                let tmpdate2 = new Date();
                tmpdate2.setHours(i);
                let monthAddedOn = tmpdate2.getMonth() + 1;
                let yearAddedOn = tmpdate2.getFullYear();
                let createIndex = i + "_" + monthAddedOn + "_" + yearAddedOn;
                tmpCategories.push({ date: tmpdate2.getTime(), hour: createIndex });
            }

            tmpCategories.forEach(mydate => {
                xaxis_categories.push(mydate.date);
                const hour = mydate.hour;
                if (tmpCurrServedChats[hour] !== undefined) {
                    series_data_served.push(tmpCurrServedChats[hour].length);
                } else {
                    series_data_served.push(0);
                }

                if (tmpResponseTime[hour] !== undefined && tmpResponseTime[hour].length > 0) {
                    let resTimeSum = tmpResponseTime[hour].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgInMinutes = 0;
                    if (resTimeSum > 0) {
                        let avgResponseTime = Math.ceil(resTimeSum / tmpResponseTime[hour].length);
                        avgInMinutes = Math.ceil(avgResponseTime / 60);
                    }
                    series_response_time.push(avgInMinutes);
                } else {
                    series_response_time.push(0);
                }

                if (tmpChatDuration[hour] !== undefined && tmpChatDuration[hour].length > 0) {
                    let chatDurSum = tmpChatDuration[hour].reduce(function (all, item) {
                        all += item;
                        return all;
                    }, 0);
                    let avgChatDur = Math.ceil(chatDurSum / tmpChatDuration[hour].length);
                    let avgInMinutes = Math.ceil(avgChatDur / 60);
                    series_chat_duration.push(avgInMinutes);
                } else {
                    series_chat_duration.push(0);
                }

                if (tmpRatings[hour] !== undefined && tmpRatings[hour].length > 0) {
                    series_ratings.push(tmpRatings[hour].length);
                } else {
                    series_ratings.push(0);
                }
            });
        }

        /* For charting end  */
        series = [{ name: 'Chats completed', color: '#77c66d', data: series_data_served }];
        options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };
        chatTiming_series = [{ name: 'Response Time', color: '#5bcec1', data: series_response_time }, { name: 'Chat Duration', color: '#edbc4d', data: series_chat_duration }];
        chatTiming_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_options.xaxis = {
            type: xaxis_type,
            categories: xaxis_categories,
            labels: {
                formatter: this.formatXAxis
            }
        };

        satisfaction_series = [{ name: 'Chats rated', data: series_ratings }];
        response_series = [{ name: 'Number of Chats', data: [response_lessthan15, response_inbtw15_30, response_inbtw31_45, response_inbtw46_60, response_greaterthan60] }];
        chatduration_series = [{ name: 'Number of Chats', data: [duration_lessthan10, duration_inbtw10_30, duration_inbtw31_60, duration_inbtw1h_15, duration_greaterthan15] }];

        /* NPS start */

        /* for previous period ratings start */

        previouschats.forEach(single_chat => {
            let rating = single_chat.rating === null ? 0 : single_chat.rating;
            if (rating > 0) {
                tmpPrevRatings.push(rating);
            }
        });

        let tmpPrevRatedChatsSum = tmpPrevRatings.reduce((all, item) => {
            all += item;
            return all;
        }, 0);

        /* for previous period ratings End */

        let allratedChats = tmpServedRatings.concat(tmpMissedRatings);
        let tmpRatedChatsSum = allratedChats.reduce((all, item) => {
            all += item;
            return all;
        }, 0);
        let totalRatedPoints = (allratedChats.length * 5);
        var goodPercentage = Math.ceil((tmpRatedChatsSum / totalRatedPoints) * 100);
        if (isNaN(goodPercentage)) {
            goodPercentage = 0;
        }
        var badPercentage = 100 - goodPercentage;
        var nps_series = [goodPercentage, badPercentage];
        var goodPrevPercentage = 0;
        let allPrevRatedChats = tmpPrevRatings.length;
        if (allPrevRatedChats > 0) {
            let totalPrevRatedPoints = allPrevRatedChats * 5;
            goodPrevPercentage = Math.ceil((tmpPrevRatedChatsSum / totalPrevRatedPoints) * 100);
            if (isNaN(goodPrevPercentage)) {
                goodPrevPercentage = 0;
            }
        }
        /* NPS end */

        /* Get percentage start */

        let sumCurrChatTotal = currentchats.length;
        let tooltip_nps_rating = "";
        let npscurrentAndPrev = "";
        if (goodPercentage === goodPrevPercentage) {
            npscurrentAndPrev = "equal";
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%.";
        } else if (goodPercentage > goodPrevPercentage) {
            npscurrentAndPrev = "up";
            let diffpr = goodPercentage - goodPrevPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Increase of " + diffpr + "%";
        } else {
            npscurrentAndPrev = "down";
            let diffpr = goodPrevPercentage - goodPercentage;
            diffpr = diffpr.toFixed(2);
            if (isNaN(diffpr)) {
                diffpr = 0;
            }
            tooltip_nps_rating = "Previous period satisfaction " + goodPrevPercentage + "%. Decrease of " + diffpr + "%";
        }

        let chatRatedPercentage = (allratedChats.length * 100) / sumCurrChatTotal;
        chatRatedPercentage = chatRatedPercentage.toFixed(2);
        if (isNaN(chatRatedPercentage)) {
            chatRatedPercentage = 0;
        }

        /* Get percentage end  */
        this.setState({ chats_total: sumCurrChatTotal, tooltip_nps_rating: tooltip_nps_rating, series: series, chatTiming_series: chatTiming_series, response_series: response_series, chatduration_series: chatduration_series, satisfaction_series: satisfaction_series, nps_series: nps_series, total_rated_chats: allratedChats.length, chatRatedPercentage: chatRatedPercentage }, () => {
            this.setState({ options: options, chatTiming_options: chatTiming_options, satisfaction_options: satisfaction_options, npscurrentAndPrev: npscurrentAndPrev, loader: false, nps_options: nps_options });
        });
    }

    render() {

        let noData = ""
        if (this.state.selectedAgentOnline === 0) {
            noData = "no-data"
        }
        return (
            <>
                <ReactTooltip
                    place={'bottom'}
                    effect={'solid'}
                    multiline={true} />
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 my-4">
                        <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                            <div className="chatReport-total-chats user-profile pt-7 pb-8">
                                <span className="user-pic">
                                    {this.state.profilePic ? (<img src={this.state.profilePic} className="avatar-img" alt="" />) : this.state.agentShort}
                                </span>
                                <span className="profilename-agent">{this.state.agentName}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 my-4">
                        <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                            <div className="chatReport-total-chats">
                                <span className="title-total-chat">Online</span>
                                <div className={`k-flex online-time ${noData}`}>
                                    {parse(this.secondsToHms(this.state.selectedAgentOnline, "current"))}
                                    {parse(this.secondsToHms(this.state.averageAllTotalOnline, "all"))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 my-4">
                        <div className="bg-white left-widgetsetings-box chatReportCol k-flex align-items-center justify-content-center">
                            <div className="chatReport-total-chats">
                                <span className="title-total-chat">Last activity</span>
                                <div className="k-flex align-items-center online-time">
                                    {this.state.lastActivity !== 0 ? parse(this.state.lastActivity) : 0}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 my-4">
                        <div className="bg-white left-widgetsetings-box">
                            <div id="chart" className="py-5 px-5">
                                <div className="chartTitle">
                                    <h5>Chats completed</h5>
                                </div>
                                <div className="chart_center_M w-100"><ApexCharts options={this.state.options} series={this.state.series} type="bar" height={350} /></div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 my-4">
                        <div className="bg-white left-widgetsetings-box">
                            <div id="chart" className="py-5 px-5">
                                <div className="chartTitle">
                                    <h5>Chats Timings</h5>
                                </div>
                                <div className="chart_center_M w-100"><ApexCharts options={this.state.chatTiming_options} series={this.state.chatTiming_series} type="line" height={350} /></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 my-2">
                        <div className="bg-white left-widgetsetings-box py-5 px-5">
                            <div className="chartTitle">
                                <h5>Response Time</h5>
                            </div>
                            <ApexCharts options={this.state.response_options} series={this.state.response_series} type="bar" height={350} />
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 my-2">
                        <div className="bg-white left-widgetsetings-box py-5 px-5">
                            <div className="chartTitle">
                                <h5>Chat Duration</h5>
                            </div>
                            <ApexCharts options={this.state.chatduration_options} series={this.state.chatduration_series} type="bar" height={350} />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 my-4">
                        <div className="bg-white left-widgetsetings-box">
                            <div id="chart3" className="py-5 px-5">
                                <ApexCharts options={this.state.satisfaction_options} series={this.state.satisfaction_series} type="line" height={350} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-6 col-sm-12 col-md-6 col-lg-6 my-4">
                        <div className="bg-white left-widgetsetings-box bb">
                            <div id="chart4" className="py-5 px-5">
                            <h5>CSAT Score</h5>
                                <div className="chartCss">
                                    <ApexCharts options={this.state.nps_options} series={this.state.nps_series} type="donut" width={400} height={330} />
                                </div>
                                <div style={{ margin: "0px auto", width: "400px" }} className="nps-satis">
                                    <div data-tip={this.state.tooltip_nps_rating} className="k-flex justify-content-center">
                                        <p className="numberReport">{this.state.total_rated_chats} {/*this.state.nps_series[0] */}</p>
                                        <p className="iconTop">
                                            {this.state.npscurrentAndPrev === "up" ? (<span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.631" height="18.691" viewBox="0 0 13.631 18.691">
                                                    <g id="Group_1400" data-name="Group 1400" transform="translate(1.414 1)">
                                                        <path id="Path_1786" data-name="Path 1786" d="M-9516.588,932.248v16.228" transform="translate(9522.094 -931.785)" fill="none" stroke="#77c66d" strokeLinecap="round" strokeWidth="2" />
                                                        <path id="Path_1787" data-name="Path 1787" d="M-9521.327,937.252l5.4-5.4,5.4,5.4" transform="translate(9521.327 -931.85)" fill="none" stroke="#77c66d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </svg>
                                            </span>) : (null)}
                                            {this.state.npscurrentAndPrev === "down" ? (<span>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="13.631" height="18.691" viewBox="0 0 13.631 18.691">
                                                    <g id="Group_1401" data-name="Group 1401" transform="translate(1.414 1)">
                                                        <path id="Path_1786" data-name="Path 1786" d="M-9516.588,948.476V932.248" transform="translate(9522.094 -932.248)" fill="none" stroke="#eb867d" strokeLinecap="round" strokeWidth="2" />
                                                        <path id="Path_1787" data-name="Path 1787" d="M-9521.327,931.85l5.4,5.4,5.4-5.4" transform="translate(9521.327 -920.561)" fill="none" stroke="#eb867d" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
                                                    </g>
                                                </svg>
                                            </span>) : (null)}
                                            {this.state.npscurrentAndPrev === "equal" ? (<span >--</span>) : (null)}
                                        </p>
                                    </div>
                                    <p className="title-total-chat">Chats rated ({this.state.chatRatedPercentage + "%"}) {/* this.state.total_rated_chats */}</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default IndividualAgent;