//create react functional component

import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const PopUp = (props) => {
    let pathname = useHistory().location.pathname;
    const agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    console.log(pathname, agent);

    return (
        // sidePannel-view
        <div className={pathname.includes("chathistory") || pathname.includes("integration") ? "popupInstallLivechat popupLivechat access-denied":"popupInstallLivechat popupLivechat access-denied sidePannel-view"} style={{ display: 'block' }} id="Free-user-plan-banner">
            <div className="free_user cc">
                <img src={require('../../../src/assets/img/free-plan.svg')} alt="inactive chatbot" />
                <div className="popupDetails">
                    <h3 className="card-title">Access Denied</h3>
                    <p>
                    Upgrade to a paid plan to unlock all features of Appy Pie Livechat.
                    </p>
                    <button className='btnBlue' onClick={() => window.location.href = `https://${window.location.hostname}/${agent.organization_alias}/subscription`}
                    >Upgrade Now</button>
                </div>
            </div>
        </div>
            )
}

export default PopUp;
