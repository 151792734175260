import React, { useEffect } from 'react';
// import db from '../../config/firebase';
import { WIDGET_DOMAIN } from '../../config/siteConfig';
import queryString from 'query-string'
import firebaseServices from '../../firebase';


const Testwidget = (props) => {

    const [loader, setLoader] = React.useState(true)


    useEffect(() => {
        let ownerId = props.match.params.ownerIden ? props.match.params.ownerIden : false;
        if (ownerId) {
            firebaseServices.db.collection('users').where("organization_alias", "==", ownerId).where("deleted", '==', false).get().then((docs) => {
                let docId;
                if (!docs.empty) {
                    docs.forEach((doc) => {
                        docId = doc.id;
                    })
                    if (docId) {
                        firebaseServices.db.collection('users').doc(docId).collection("chatbots").where("status", '==', 1).limit(1).get().then((cDocs) => {
                            let cDocId;
                            if (!cDocs.empty) {
                                cDocs.forEach((cDoc) => {
                                    cDocId = cDoc.id;
                                })
                                if (docId && cDocId) {
                                    loadScript(docId, cDocId);
                                }
                            }
                        })
                    }
                }
            })
        } else {
           setLoader(false)
        }
    }, [props.match.params.ownerIden])


    const loadScript = (docId, cDocId) => {
        console.log(docId, cDocId);
        let script = document.createElement("script")
        script.id = "appyWidget";
        script.src = WIDGET_DOMAIN + "/widget/buildone.js?cid=" + docId + "-" + cDocId;
        document.getElementsByTagName("head")[0].appendChild(script);
        let firstInterval = setInterval(() => {
            let z = document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890');
            if (z) {
                clearInterval(firstInterval);
                openWidget();
                try {
                    // z.style.display = "none";
                } catch (err) { }
            }
        }, 100);
    }
    const openWidget = () => {
        let intervalVar = setInterval(() => {
            try {
                var x = document.getElementById('Appypie').contentWindow.document.getElementsByClassName('iconBotdynamic');
                var y = document.getElementById('Appypie').contentWindow.document.getElementsByClassName('iconBotdynamicInner');
                if (x && y) {
                    x[0].click();
                    document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890').style.display = "initial !important";
                    document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890').style.marginBottom = "70px";
                    document.getElementById('1234567890ANAS0987654321DHEERAJ09876543211234567890').style.marginRight = "300px";
                    setLoader(false)
                    clearInterval(intervalVar);
                    y[0].click();
                }
            } catch (error) { }
        }, 100)
    }
    return (
        <div className='testWedget-bg'>
            <div className='headerWedget'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex justify-content-between'>
                                <div className='d-flex align-items-center'>
                                    <p className='mr-4'>Create your free LiveChat account to start chatting with your customers</p>
                                    <button className='btn btn-primary' onClick={()=>{
                                        //redirect to https://www.appypie.com/live-chat-software on new page
                                        window.open('https://www.appypie.com/live-chat-software', '_blank');

                                    }}>Get LiveChat</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
            <div className='companyName'>
                {/* /* keep first letter capital */ }
                <h1>{props.match.params.ownerIden.charAt(0).toUpperCase() + props.match.params.ownerIden.slice(1)}</h1>
                            {/* <h1>{props.match.params.ownerIden}</h1> */}
            </div>
            {/* {loader ? (<div className="loading" style={{ display: 'block' }}>Loading&#8230;</div>) : (null)} */}
        </div>
    )
}

export default Testwidget;