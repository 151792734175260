import React from 'react';
import ReactDOM from 'react-dom';

/* css for new fresh design start */
import '../src/assets/css/bootstrap.min.scss';

// import '../src/assets/css/mdb.min.scss';
import '../src/assets/css/layout.scss';
import '../src/assets/css/style.scss';
import '../src/assets/css/responsive.scss';
import '../src/assets/css/phonedialer.scss';
/* css for new fresh design End */



import Apps from './App';
import * as serviceWorker from './serviceWorker';

// console.log = function() {};
console.error = function(){};
console.warn= function(){};
console.trace = function(){};
window.alert = function() {};

ReactDOM.render(<Apps />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
