import React, { PureComponent } from 'react';
import History from './view/history';
import { checkPhoneCall } from '../livechat/components/Twilio';
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';

class Chathistory extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            payment_status: true
        };
    }

    componentDidMount = () => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
        checkPhoneCall();
    }

    render() {
        return (<>
       
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Conversation History</h3>
            </header>
            {this.state.payment_status ? <History />: <PopUp />}
        </>);
    }
}

export default React.memo(Chathistory);