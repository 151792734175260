import React from 'react';
import TextField from '@material-ui/core/TextField';

class Desktop extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        let currentscreen = '';
        let footer;
        if (this.props.view === 'desktop' && (this.props.preview === 'thank_you_message' || this.props.preview === 'feedback') && this.props.values.form_type === "1") {
            if (this.props.values.post_chat_form) {
                footer = <div className="screen-thnks-message">
                    <p><small>{this.props.values.thank_you_message}</small></p>
                    <div className="text-center"><button className="btnBlueSettings py-2 px-5" style={{ fontSize: '10px' }}>{this.props.values.startNewConversationText}</button></div>
                </div>
                let feedBackType = <p className="py-2 rating-star-screen"><span>★</span><span>★</span><span>★</span><span>★</span><span>☆</span></p>;
                if (this.props.values.feedback_type === "1") {
                    feedBackType = <><img alt="" src={require('../../../../assets/img/icon/like.png')} />&nbsp;&nbsp;&nbsp;<img alt="" src={require('../../../../assets/img/icon/dislike.png')} /></>
                }
                if (this.props.preview === "feedback") {
                    footer = <div className="screen-ratings text-center feedback-view">
                        <p><small>{this.props.values.feedback_message}</small></p>
                        {this.props.values.multi_feedback_switch ?
                            (<div className="d-flex">
                                <div className="column">
                                    <div className="rating" id="rating_product">
                                        <h5>Product</h5>
                                        <div className="text-center"><i className="fal fa-thumbs-up"></i>
                                            {feedBackType}
                                        </div>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="rating" id="rating_agent">
                                        <h5>Service/Agent</h5>
                                        <div className="text-center"><i className="fal fa-thumbs-up"></i>
                                            {feedBackType}
                                        </div>
                                    </div>
                                </div>
                            </div>) :
                            (<div className="text-center"><i className="fal fa-thumbs-up"></i>
                                {feedBackType}
                            </div>)
                        }
                        {
                            this.props.values.show_feedback_comment ? (<div className="feedBackCommntOptional">
                                <div className="input-group material-input widget-input">
                                    <TextField className="form-control w-100" rows={4} variant="outlined" label={this.props.values.feedback_comment_label} multiline />
                                </div>
                                {/* <textarea placeholder={this.props.values.feedback_comment_label}></textarea> */}
                                <button className="mt-3">Submit</button></div>) : (null)
                        }
                    </div >
                }
            } else {
                footer = <div className="screen-thnks-message"><div className="text-center"><button className="btnBlueSettings py-2 px-5" style={{ fontSize: '10px' }}>{this.props.values.startNewConversationText}</button></div></div>
            }

            currentscreen = <div className="screen position-relative mr-auto">
                <div className="screen-header px-5 py-3" style={{ background: 'rgb(10, 10, 10)' }}>
                    <div className="k-flex align-items-center">
                        <div className="screenBackArroe">
                            <svg focusable="false" aria-hidden="true">
                                <g fill="none">
                                    <g fill="#FFF">
                                        <polygon transform="translate(-40 -29)translate(47.071068 36.071068)rotate(-315)translate(-47.071068 -36.071068)" points="44.3 38.8 44.3 31.1 42.1 31.1 42.1 40 42.1 41.1 52.1 41.1 52.1 38.8" />
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="screenBackArroe">
                            <p>{this.props.values.company_name}</p>
                        </div>
                        <div className="screenRightNav k-flex align-items-center ml-auto">
                            <div className="screenMenuArrow mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="4" height="18" viewBox="0 0 4 18">
                                    <g id="Group_12768" data-name="Group 12768" transform="translate(-1378.075 -219.72)">
                                        <rect id="Rectangle_1688" data-name="Rectangle 1688" width="4" height="4" transform="translate(1378.075 219.72)" fill="#fff" />
                                        <rect id="Rectangle_1689" data-name="Rectangle 1689" width="4" height="4" transform="translate(1378.075 226.72)" fill="#fff" />
                                        <rect id="Rectangle_1690" data-name="Rectangle 1690" width="4" height="4" transform="translate(1378.075 233.72)" fill="#fff" />
                                    </g>
                                </svg>
                            </div>
                            <div className="screeCloseArrow">
                                <svg xmlns="http://www.w3.org/2000/svg" width="19.243" height="19.243" viewBox="0 0 19.243 19.243">
                                    <g id="Group_12767" data-name="Group 12767" transform="translate(-1435.132 -75)">
                                        <g id="Ellipse_270" data-name="Ellipse 270" transform="translate(1435.132 75)" fill="#fff" stroke="#fff" strokeWidth="1">
                                            <circle cx="9.622" cy="9.622" r="9.622" stroke="none" />
                                            <circle cx="9.622" cy="9.622" r="9.122" fill="none" />
                                        </g>
                                        <path id="Union_5" data-name="Union 5" d="M5.869,7,3.614,4.742,1.361,7A.8.8,0,0,1,.233,5.868L2.487,3.614.233,1.361A.8.8,0,0,1,1.36.233L3.614,2.487,5.869.233A.8.8,0,0,1,7,1.36L4.741,3.615,7,5.869A.8.8,0,1,1,5.869,7Z" transform="translate(1441.078 81.04)" fill="#1c223a" />
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="screen-body">
                    <div className="body-chat">
                        <div className="recived k-flex align-items-flex-end pt-3">
                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                {/* <img alt="" src={this.state.brand_logo} style={{width: 27}} /> */}
                            </span>
                            <div className="message-box">
                                <div className="message message-line" style={{ background: '#E6E6E6' }}>
                                    <span className="chat-Line" style={{ width: '150px', background: '#2C3149' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '130px', background: '#2C3149' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '100px', background: '#2C3149' }}>&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                            <div className="message-box">
                                <div className="message message-line" style={{ background: '#2C3149' }}>
                                    <span className="chat-Line" style={{ width: '150px', background: '#FFFFFF' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '130px', background: '#FFFFFF' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '100px', background: '#FFFFFF' }}>&nbsp;</span>
                                </div>
                            </div>
                            <span className="user"><img alt="" src={require('./../../../../assets/images/avtare-user.svg')} /> </span>
                        </div>
                        <div className="recived k-flex align-items-flex-end pt-3">
                            <span style={{ marginLeft: 10, marginRight: 10 }}>
                                {/* <img alt="" src={this.state.brand_logo} style={{width: 27}} /> */}
                            </span>
                            <div className="message-box">
                                <div className="message message-line" style={{ background: '#E6E6E6' }}>
                                    <span className="chat-Line" style={{ width: '150px', background: '#2C3149' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '130px', background: '#2C3149' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '100px', background: '#2C3149' }}>&nbsp;</span>
                                </div>
                            </div>
                        </div>
                        <div className="sent k-flex align-items-flex-end justify-content-end pt-3">
                            <div className="message-box">
                                <div className="message message-line" style={{ background: '#2C3149' }}>
                                    <span className="chat-Line" style={{ width: '150px', background: '#FFFFFF' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '130px', background: '#FFFFFF' }}>&nbsp;</span>
                                    <span className="chat-Line" style={{ width: '100px', background: '#FFFFFF' }}>&nbsp;</span>
                                </div>
                            </div>
                            <span className="user"><img alt="" src={require('./../../../../assets/images/avtare-user.svg')} /> </span>
                        </div>
                    </div>
                </div>
                <div className="screen-footer">
                    <div className="scr-footer-1 k-flex align-items-center py-2 px-3 border-top position-relative">
                        {footer}
                    </div>
                    <div className="powerby">
                        <p>
                            <span className="powerdBySnapyIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.15 12.64">
                                    <g data-name="Layer 2">
                                        <g data-name="Layer 1">
                                            <path fill="#fcf4a0" d="M6.58.75L.75 7.06h3.54l-1.95 4.83L8.4 5.24H4.57L6.58.75z" />
                                            <path d="M6.58.75l-2 4.49H8.4l-6.06 6.65 2-4.83H.75L6.58.75m0-.75a.67.67 0 0 0-.37.11.65.65 0 0 0-.21.13L.2 6.55a.75.75 0 0 0 .55 1.26h2.43l-1.54 3.8a.76.76 0 0 0 .3.92.8.8 0 0 0 .4.11.74.74 0 0 0 .55-.24L9 5.75a.75.75 0 0 0-.6-1.26H5.73L7.24 1.1a.68.68 0 0 0 .09-.35.75.75 0 0 0-.74-.75zm0 1.5z" fill="#f4a51f" />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            Powered by <img alt="" src="https://chatbot.appypie.com/widget/deffc8a9acefb38beaa89521f4725e2c.svg" style={{ width: '22%', position: 'relative', top: '-2px' }} />
                        </p>
                    </div>
                </div>
            </div>
        }

        return (
            <>
                {currentscreen}
            </>
        )
    }
}

export default Desktop;