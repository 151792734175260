import React, { PureComponent } from 'react';
import Chatform from './view/chatforms';
import LeftPanel from './view/LeftPanel'
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';

class Chatformsettings extends PureComponent {
    constructor(props){
        super();
        this.state = {
            submitForm:false,
            cancelForm:false,
            payment_status:true
        }
    }

    submitForm = (e) => {
        this.setState({
            submitForm:true
        })
    }

    updateValues = () => {
        this.setState({
            submitForm:false,
            cancelForm:false
        })
    }

    cancelForm = (e) => {
        this.setState({
            cancelForm:true
        })
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    componentDidMount = () => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId){
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if(results.status === 200){
                    let _data = results.data;
                    this.setState({
                        payment_status: _data.payment_status !== undefined ? true : false
                    })
                } else {
                    this.setState({
                        payment_status: false
                    })
                }
            }
            )
        }
    }

    render() {
        return (<>
            {this.state.payment_status ? <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6" onClick={this.submitForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4" onClick={this.cancelForm}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>: <PopUp />}
            <LeftPanel />
            <Chatform cancelForm={this.state.cancelForm} updateValues={this.updateValues} submitForm={this.state.submitForm} />
        </>); 
    }
}

export default Chatformsettings;   