import React, { PureComponent } from 'react';
// import db from '../../../config/firebase';
import parse from 'html-react-parser';
import Linkify from 'react-linkify';
import ReactPlayer from 'react-player';
import moment from 'moment-timezone';
import { checkUrl, getDateInUTC, removeHtmlTags, decodeEntities } from '../../livechat/components/Comman';
import ReactTooltip from "react-tooltip";
import firebase from 'firebase/compat/app';
import { activityLog, getOwnerDetails, getAllAwsAgents } from '../../../services/service';
import dummyData from '../../../localization/dummy-data.json';
import TextField from '@material-ui/core/TextField';
import { GOOGLE_TRANSLATION_KEY } from '../../../config/siteConfig';
import firebaseServices from '../../../firebase';

const msgTypes = [1, 2, 6, 7, 11, 12, 13, 15, 21, 22, 23, 41, 42, 44, 45];
class Chat extends PureComponent {

	constructor(props) {
		super(props);
		this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
		this.state = {
			chatdetail: [],
			allData: [],
			chatOrgArray: [],
			filteredIndex: [],
			href: "",
			groupChatAgents: [],
			showVisitorPath: false,
			visitorPathList: [],
			showNotes: false,
			allNotes: [],
			loader: false,
			noteFound: false,
			showAddNote: false,
			notesMsgs: {},
			isTranslated: false,
			agentsImage: {}
		};
	}

	getTimeFromDate = (timestamp) => {
		if (timestamp === null) {
			return;
		}
		var todayDate = new Date().getTime();
		todayDate = Math.floor(todayDate / 1000);
		var todayUTCDate = moment.tz(todayDate, "X", moment.tz.guess(true)).tz("UTC").format("YYYYMMDD");
		var current = parseInt(todayUTCDate);

		timestamp = Math.floor(timestamp / 1000);
		var previousUTCDate = moment.tz(timestamp, "X", "UTC").tz("UTC").format("YYYYMMDD");
		var previous = parseInt(previousUTCDate);

		var dateString = '';
		if (current === previous) {
			dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("hh:mm A");
		} else {
			dateString = moment.tz(timestamp, "X", "UTC").tz(moment.tz.guess(true)).format("DD/MM/YYYY hh:mm A");
		}

		return dateString;
	}

	formatBytes = (bytes, decimals = 2) => {
		if (bytes === 0) return '0 Bytes';
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	}

	loadDummyData = () => {
		let chatData = dummyData.transcript_data;
		var finalChatData = [];
		var chatArray = [];
		var chatMessages = [];
		var counter = 0;
		var totalChats = chatData.length;
		let prom = new Promise((resolve, reject) => {
			chatData.forEach((v, i) => {
				const docData = v;
				const docId = v.id;
				const {
					addedon,
					agent_id,
					msg,
					// agent_profile_img,
					msg_url,
					agent_short,
					contenttype,
					filesize,
					recordingUrl
				} = docData;

				const agent_profile_img = this.state.agentsImage[agent_id] !== undefined ? this.state.agentsImage[agent_id] : this.state.agentsImage['owner'];

				const msg_type = docData.msg_type ? docData.msg_type : 'NA';
				console.log(addedon,
					agent_id,
					msg,
					agent_profile_img,
					msg_url,
					agent_short,
					contenttype,
					filesize, msg_type);

				var docDataTmp = docData;
				docDataTmp.id = docId;
				chatArray.push(docDataTmp);
				let prom2 = new Promise(async (resolve, reject) => {
					resolve(msg);
				});

				prom2.then((trTxt) => {
					var msg = trTxt;
					var msg1 = '';
					var imageContent = '';
					var timespan = '';
					var agentcheck = 2;
					var className = '';
					var content = '';
					var showli = 1;
					var chatBubbleClass = "message-body";

					var msgTime = this.getTimeFromDate(addedon);

					if (msg_type === 1) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						var filMsg = checkUrl(filterMsg);
						if (agent_id === null) {
							msg1 = '<div class="message-content-new bg-white box-shadow-chat">' + filMsg + '</div>';
						} else {
							msg1 = '<div class="bg-primary text-white message-content-new">' + filMsg + '</div>';
						}
					} else if (msg_type === 2) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						if (agent_id === null) {
							msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
						} else {
							msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
						}
					} else if (msg_type === 3) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
						var stringUrl = msg_url.replace(/[0-9]/g, '');
						var msgNew = stringUrl;
						if (stringUrl.length > 15) {
							msgNew = stringUrl.substring(0, 15) + '...';
						}
						var formatedSize = this.formatBytes(filesize, 2);
						let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;

						if (agent_id === null) {
							msg1 = '<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
						} else {
							msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
						}

					} else if (msg_type === 8 && msg !== '') {

						var msgString = '';
						var checkEmpty = true;
						msg.forEach((msgData, i) => {
							if (msgData.A !== "") {
								checkEmpty = false;
							}
							let tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + tmpchtMsg + '</span></p></li>';
						});

						if (!checkEmpty) {
							msg1 = '<div class="message-content-new bg-white box-shadow-chat question-sections-first"><div class="liveChat-Message"><ul class="liveChat-question-answer">' + msgString + '</ul></div></div>';
						} else {
							showli = 0;
						}
					} else if (msg_type === 4) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
						msg1 = '<a href=' + msg + ' src=' + msg + ' download=' + msg_url + '> Download </a>';
					} else if (msg_type === 6) {
						var agentId = docData.agent_id;
						if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
							showli = 0;
						} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "WELCOME_PAID_VISITOR")) {
							showli = 0;
						} else if (docData.action === "VISITOR_END_CHAT" && msg.indexOf("You ended the chat") !== -1) {
							msg1 = '<div class="col-auto"><small class="text-muted">Visitor ended the chat.</small><p class="date-agent">' + msgTime + '</p></div>';
							//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">Visitor ended the chat.</small></div><div class="col"><hr /></div>';
						} else {
							var tt = docData.msg_otheragent;
							if (tt !== undefined) {
								msg1 = '<div class="col-auto"><small class="text-muted">' + tt + '</small><p class="date-agent">' + msgTime + '</p></div>';
								//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + tt + '</small></div><div class="col"><hr /></div>';
							} else {
								msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
								//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + msg + '</small></div><div class="col"><hr /></div>';
							}
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg1 });
						}
						agentcheck = 3;
					} else if (msg_type === 7) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg });
						msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
						//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + msg + '</small></div><div class="col"><hr /></div>';
						agentcheck = 3;
					} else if (msg_type === 11) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						var filMsg = checkUrl(filterMsg);
						if (agent_id === null) {
							msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /></div>`;
						} else {
							msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
						}
					} else if (msg_type === 13) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						var filMsg = checkUrl(filterMsg);
						if (agent_id === null) {
							msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></div>`;
						} else {
							msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
						}
					} else if (msg_type === 12) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						if (agent_id === null) {
							msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
								(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
						} else {
							msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
								(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
						}
						agentcheck = 3;
					} else if (msg_type === 15) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
						var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						var filMsg = checkUrl(filterMsg);
						if (agent_id === null) {
							msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div>`;
						} else {
							msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
						}
					} else if (msg_type === 16) {
						chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
						var stringUrl = msg_url.replace(/[0-9]/g, '');
						var msgNew = stringUrl;
						if (stringUrl.length > 15) {
							msgNew = stringUrl.substring(0, 15) + '...';
						}
						var formatedSize = this.formatBytes(filesize, 2);
						let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
						if (agent_id === null) {
							msg1 = `<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div>`;
						} else {
							msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
						}
					} else if (msg_type === 17) {

					} else {
						//msg_type = 'NA';
						showli = 0;
					}

					if (agent_id === null) {
						className = "message";
						imageContent = '<span class="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile"><span>' + this.props.visitor.client_short + '</span></span>';
						content = msg1;
						timespan = "mt-0";
						var agentClass1 = "d-flex align-items-center";
					} else {
						if (agentcheck === 3) {
							className = "message message-right statusmsg";
						} else {
							className = "message message-right";
						}

						if (agent_profile_img !== '' && agent_profile_img !== null) {
							imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><img class="avatar-img" src="' + agent_profile_img + '" alt="" />';
						} else {
							imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span>' + agent_short + '</span></div>';
						}
						content = msg1;
						timespan = "mt-0";
						var agentClass1 = "d-flex align-items-center justify-content-flexend text-right ";
					}

					if (msg_type === 6 || msg_type === 7 || msg_type === 12) {
						className = "message-divider my-5 mx-lg-4";
						chatBubbleClass = "row align-items-center";
					}

					if (agentcheck === 3) {

					} else if (agent_id === '' || agent_id === 'null' || agent_id === null) {
						agentcheck = 1;
						if (msg_type === 8) {
							agentcheck = 10;
							chatBubbleClass = "message-body";
						}
					}

					if (showli === 1) {
						finalChatData[i] = (<div key={docId} className={className} id={docId}>
							{agentcheck === 1 ? parse(imageContent) : ''}
							<div className={chatBubbleClass}>
								{(msg_type === 1) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}>
									<small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
								{msg_type === 11 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
								{msg_type === 13 || msg_type === 15 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
								{msg_type === 8 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }} >{parse(content)}</Linkify></div></div></div> : ''}
								{msg_type === 2 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his w-100">{parse(content)}<div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
								{msg_type === 3 || msg_type === 16 ? parse(content) : ''}
								{msg_type === 4 ? <div className="msgvideo"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /></div> : ''}
								{msg_type === 6 ? parse(content) : ''}
								{msg_type === 12 ? parse(content) : ''}
								{msg_type === 7 ? parse(content) : ''}
								{msg_type === 17 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src={require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div> : ''}
								{msgTypes.indexOf(msg_type) === -1 ? <div className={timespan}><small className="opacity-65">{msgTime}</small></div> : ''}
							</div>
							{agentcheck === 2 ? parse(imageContent) : ''}
						</div>);
					}
					counter++;
				});
			});
			//////////// loop end ////////////
			let checkCounter = setInterval(() => {
				if (totalChats === counter) {
					clearInterval(checkCounter);
					resolve(finalChatData);
				}
			}, 100);
		});

		prom.then((finalChatData) => {
			console.log('finalChatData >>> ', finalChatData);
			this.setState({ chatdetail: finalChatData, allData: finalChatData, chatOrgArray: chatArray, filteredIndex: chatMessages }, () => {
				try {

				} catch (error) { }
			});
		});
	}

	getChat = () => {

		if (!this.props.visitor.visitorId) {
			return null;
		}

		if (this.props.widgetStatus === false && this.state.showDummyData === true) {
			this.loadDummyData();
			return null;
		}

		///////////// To getting chat messages Start /////////////
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.props.visitor.visitorId).collection('chat_data').orderBy('addedon', 'asc').get().then(docs => {
			var finalChatData = [];
			var chatArray = [];
			var chatMessages = [];
			var counter = 0;
			var totalChats = docs.size;

			let prom = new Promise((resolve, reject) => {
				for (const index in docs.docs) {
					const change = docs.docs[index];
					const docData = change.data();
					const docId = change.id;
					const {
						addedon,
						agent_id,
						msg,
						// agent_profile_img,
						msg_url,
						agent_short,
						contenttype,
						filesize,
						recordingUrl
					} = docData;

					const msg_type = docData.msg_type ? docData.msg_type : 'NA';
					const agent_profile_img = this.state.agentsImage[agent_id] !== undefined ? this.state.agentsImage[agent_id] : this.state.agentsImage['owner'];

					var docDataTmp = docData;
					docDataTmp.id = docId;
					chatArray.push(docDataTmp);
					let prom2 = new Promise(async (resolve, reject) => {
						resolve(msg);
					});

					prom2.then(async (trTxt) => {
						var msg = trTxt;
						var msg1 = '';
						var imageContent = '';
						var timespan = '';
						var agentcheck = 2;
						var className = '';
						var content = '';
						var showli = 1;
						var chatBubbleClass = "message-body";

						var msgTime = this.getTimeFromDate(addedon);

						if (msg_type === 1) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = '<div class="message-content-new bg-white box-shadow-chat">' + filMsg + '</div>';
							} else {
								msg1 = '<div class="bg-primary text-white message-content-new">' + filMsg + '</div>';
							}
						} else if (msg_type === 2) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (agent_id === null) {
								msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
							} else {
								msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
							}
						} else if (msg_type === 3) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
							var stringUrl = msg_url.replace(/[0-9]/g, '');
							var msgNew = stringUrl;
							if (stringUrl.length > 15) {
								msgNew = stringUrl.substring(0, 15) + '...';
							}
							var formatedSize = this.formatBytes(filesize, 2);
							let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;

							if (agent_id === null) {
								msg1 = '<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
							} else {
								msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
							}

						} else if (msg_type === 8 && msg !== '') {

							var msgString = '';
							var checkEmpty = true;
							msg.forEach((msgData, i) => {
								if (msgData.A !== "") {
									checkEmpty = false;
								}
								let tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
								msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + tmpchtMsg + '</span></p></li>';
							});

							if (!checkEmpty) {
								msg1 = '<div class="message-content-new bg-white box-shadow-chat question-sections-first"><div class="liveChat-Message"><ul class="liveChat-question-answer">' + msgString + '</ul></div></div>';
							} else {
								showli = 0;
							}
						} else if (msg_type === 4) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
							msg1 = '<a href=' + msg + ' src=' + msg + ' download=' + msg_url + '> Download </a>';
						} else if (msg_type === 6) {
							var agentId = docData.agent_id;
							if (agentId === "AGENT_EVENT_TRIGGER" && docData.action === "BUSY") {
								showli = 0;
							} else if (agentId === null && (docData.action === "AGENT_TRANSFER" || docData.action === "WELCOME_PAID_VISITOR")) {
								showli = 0;
							} else if (docData.action === "VISITOR_END_CHAT" && msg.indexOf("You ended the chat") !== -1) {
								msg1 = '<div class="col-auto"><small class="text-muted">Visitor ended the chat.</small><p class="date-agent">' + msgTime + '</p></div>';
								//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">Visitor ended the chat.</small></div><div class="col"><hr /></div>';
							} else {
								var tt = docData.msg_otheragent;
								if (tt !== undefined) {
									msg1 = '<div class="col-auto"><small class="text-muted">' + tt + '</small><p class="date-agent">' + msgTime + '</p></div>';
									//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + tt + '</small></div><div class="col"><hr /></div>';
								} else {
									msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
									//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + msg + '</small></div><div class="col"><hr /></div>';
								}
								chatMessages.push({ id: docId, msg_type: msg_type, msg: msg1 });
							}
							agentcheck = 3;
						} else if (msg_type === 7) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg });
							msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
							//msg1 = '<div class="col"><hr /></div><div class="col-auto"><small class="text-muted">' + msg + '</small></div><div class="col"><hr /></div>';
							agentcheck = 3;
						} else if (msg_type === 11) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /></div>`;
							} else {
								msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
							}
						} else if (msg_type === 13) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><span class="sendbysms"><img class="ml-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span></div>`;
							} else {
								msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><span class="sendbysms"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
							}
						} else if (msg_type === 12) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (agent_id === null) {
								msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
									(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
							} else {
								msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
									(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
							}
							agentcheck = 3;
						} else if (msg_type === 15) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div>`;
							} else {
								msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
							}
						} else if (msg_type === 16) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
							var stringUrl = msg_url.replace(/[0-9]/g, '');
							var msgNew = stringUrl;
							if (stringUrl.length > 15) {
								msgNew = stringUrl.substring(0, 15) + '...';
							}
							var formatedSize = this.formatBytes(filesize, 2);
							let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
							if (agent_id === null) {
								msg1 = `<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div>`;
							} else {
								msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
							}
						} else if (msg_type === 17 || msg_type === 23) {
							/// Do nothing, continue
						} else if (msg_type === 21) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div>`;
							} else {
								msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
							}
						} else if (msg_type === 22) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (agent_id === null) {
								msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div>';
							} else {
								msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
							}
						} else if (msg_type === 61) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							let filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							let filMsg = checkUrl(filterMsg);
							msg1 = '<div class="bg-triggers text-white message-content-new">' + filMsg + '</div>';
						} else if (msg_type === 41) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
							var filMsg = checkUrl(filterMsg);
							if (agent_id === null) {
								msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></div>`;
							} else {
								msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
							}
						} else if (msg_type === 42 || msg_type === 44) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="${msg}"></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (msg_type === 43) {
							// Video from instagram
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							msg1 = `<div class="message"><div class="message-body"><div style="display: flex;
							align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
						} else if (msg_type === 45) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							let contentType = await this.contentTypeFromURL(msg);
							if (contentType === 'video/mp4') {
								msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><video class="videomsg" src="${msg}" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="${msg}" download="${msg}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${docData.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
							} else {
								msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="${msg}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${docData.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
							}
						} else if (msg_type === 46) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (docData.repliedToMessage && docData.repliedToMessage.message) {
								let messageToAppend = "";
								if (docData.repliedToMessage.messageType === 42) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${docData.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${docData.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.repliedToMessage.messageType === 43) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><video class="videomsg" src="${docData.repliedToMessage.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${docData.repliedToMessage.message}" download="${docData.repliedToMessage.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.repliedToMessage.messageType === 44) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${docData.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${docData.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.repliedToMessage.messageType === 48) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><audio controls><source class="instaaudio" src="${docData.repliedToMessage.message}" type="audio/ogg"><source class="instaaudio" src="${docData.repliedToMessage.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>${docData.repliedToMessage.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								}
							}
						} else if (msg_type === 47) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (docData.reactOnMessageId && docData.reactOnMessageId.message) {
								if (docData.reactOnMessageId.messageType === 42) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${docData.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${docData.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.reactOnMessageId.messageType === 43) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><video class="videomsg" src="${docData.reactOnMessageId.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${docData.reactOnMessageId.message}" download="${docData.reactOnMessageId.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.reactOnMessageId.messageType === 44) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${docData.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${docData.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else if (docData.reactOnMessageId.messageType === 48) {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><audio controls><source class="instaaudio" src="${docData.reactOnMessageId.message}" type="audio/ogg"><source class="instaaudio" src="${docData.reactOnMessageId.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								} else {
									msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>${docData.reactOnMessageId.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
								}
							}
						} else if (msg_type === 48) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><audio controls><source class="instaaudio" src="${msg}" type='audio/ogg'><source class="instaaudio" src="${msg}" type='audio/mpeg'>Your browser does not support the audio element.</audio></div></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
						} else if (msg_type === 71) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (agent_id === null) {
								msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="${msg}"></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
							} else {
								msg1 = `<div class="d-flex align-items-center justify-content-end"><div class="d-flex align-items-center"><span class="sendbysms"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span><div class="msgimageNew"><a href="${msg}" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src="${msg}" /></a></div></div></div>`;
							}
						} else if (msg_type === 73) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: msg.toLowerCase() });
							if (agent_id === null) {
								msg1 = `<div class="message"><div class="message-body"><div style="display: flex;
								align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
							} else {
								msg1 = `<div class="d-flex align-items-center justify-content-end"><div class="d-flex align-items-center"><span class="sendbysms"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span><div style="display: flex; align-items: center; width: 150px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div></div></div></div>`;
								/* msg1 = `<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></div><div style="display: flex; align-items: center; width: 200px;" class="msgvideo">
								<div class="videoFrame" style="width: 100%; height: 100%;">
								<video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video>
								</div></div></div></div>`; */
							}
						} else if (msg_type === 74) {
							chatMessages.push({ id: docId, msg_type: msg_type, msg: "Attachment" });
							var stringUrl = msg_url.replace(/[0-9]/g, '');
							var msgNew = stringUrl;
							if (stringUrl.length > 15) {
								msgNew = stringUrl.substring(0, 15) + '...';
							}
							var formatedSize = this.formatBytes(filesize, 2);
							let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
							if (agent_id === null) {
								msg1 = `<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div><div class="k-flex align-items-center"><span class="sendbysms"><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span></div></div></div></div></div>`;
							} else {
								msg1 = `<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="k-flex align-items-center"><span class="sendbysms"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></span></div><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div></div></div></div></div>`;
							}
						} else {
							//msg_type = 'NA';
							showli = 0;
						}

						if (docData.hasOwnProperty("msg_deleted") && showli === 1) {
							showli = 0;
						}

						if (agent_id === null) {
							className = "message";
							imageContent = '<span class="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile"><span>' + this.props.visitor.client_short + '</span></span>';
							content = msg1;
							timespan = "mt-0";
							var agentClass1 = "d-flex align-items-center";
						} else {
							if (agentcheck === 3) {
								className = "message message-right statusmsg";
							} else {
								className = "message message-right";
							}

							if (agent_profile_img !== '' && agent_profile_img !== null) {
								imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><img class="avatar-img" src="' + agent_profile_img + '" alt="" />';
							} else {
								if (msg_type === 61) {
									imageContent = '<div style="background-color: #e38511"class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span style="color: #fff">#T</span></div>';
								} else {
									imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span>' + agent_short + '</span></div>';
								}
							}
							content = msg1;
							timespan = "mt-0";
							var agentClass1 = "d-flex align-items-center justify-content-flexend text-right ";
						}

						if (msg_type === 6 || msg_type === 7 || msg_type === 12) {
							className = "message-divider my-5 mx-lg-4";
							chatBubbleClass = "row align-items-center justify-content-center";
						}

						if (agentcheck === 3) {

						} else if (agent_id === '' || agent_id === 'null' || agent_id === null) {
							agentcheck = 1;
							if (msg_type === 8) {
								agentcheck = 10;
								chatBubbleClass = "message-body";
							}
						}

						if (showli === 1) {
							finalChatData[index] = (<div key={docId} className={className} id={docId}>
								{agentcheck === 1 ? parse(imageContent) : ''}
								<div className={chatBubbleClass}>
									{(msg_type === 1) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}>
										<small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
									{msg_type === 11 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
									{msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
									{msg_type === 8 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }} >{parse(content)}</Linkify></div></div></div> : ''}
									{msg_type === 2 || msg_type === 22 || msg_type === 42 || msg_type === 44 || msg_type === 45 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his w-100">{parse(content)}<div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
									{msg_type === 3 || msg_type === 16 ? parse(content) : ''}
									{msg_type === 4 ? <div className="msgvideo"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /></div> : ''}
									{msg_type === 6 || msg_type === 43 || msg_type === 46 || msg_type === 47 || msg_type === 48 || msg_type === 73  || msg_type === 71 || msg_type === 74 ? parse(content) : ''}
									{msg_type === 12 ? parse(content) : ''}
									{msg_type === 7 ? parse(content) : ''}
									{msg_type === 17 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src={require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div> : ''}
									{msg_type === 23 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div></div></div></div> : ''}
									{msgTypes.indexOf(msg_type) === -1 ? <div className={timespan}><small className="opacity-65">{msgTime}</small></div> : ''}
									{(msg_type === 61) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
								</div>
								{agentcheck === 2 ? parse(imageContent) : ''}
							</div>);
						}
						counter++;
					});
				}
				//////////// loop end ////////////
				let checkCounter = setInterval(() => {
					if (totalChats === counter) {
						clearInterval(checkCounter);
						resolve(finalChatData);
					}
				}, 100);
			});

			prom.then((finalChatData) => {
				this.setState({ chatdetail: finalChatData, allData: finalChatData, chatOrgArray: chatArray, filteredIndex: chatMessages }, () => {
					try {

					} catch (error) { }
				});
			});
		});

		///////////// To getting chat messages End /////////////
	}

	contentTypeFromURL(url) {
		var xhttp = new XMLHttpRequest();
		xhttp.open('HEAD', url);
		xhttp.onreadystatechange = function () {
			if (this.readyState == this.DONE) {
				return this.getResponseHeader("Content-Type");
			}
		};
		return xhttp.send();
	}

	nameIntials = (nameData) => {
		var arrayData = nameData.split(' ');
		switch (arrayData.length) {
			case 1:
				return nameData.substr(0, 2).toUpperCase();
				break;
			default:
				return arrayData[0].charAt(0).toUpperCase() + arrayData[arrayData.length - 1].charAt(0).toUpperCase();
		}
	}

	getJoinedAgents = () => {

		if (!this.props.visitor.visitorId) {
			return null;
		}

		///////////// To getting chat messages Start /////////////
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.props.visitor.visitorId).collection('agent_data').get().then(docs => {
			var agentArray = [];
			docs.forEach((agents) => {
				var agent = agents.data();
				agent.agent_short = this.nameIntials(agent.agent_name);
				agentArray.push(agent);
			});

			this.setState({ groupChatAgents: agentArray });
		});

		///////////// To getting chat messages End /////////////
	}

	componentDidMount = async () => {
		let agentsImage = {};
		await getAllAwsAgents().then((getAgentListInAws) => {
			getAgentListInAws.forEach(agent => {
				let imageUrl = agent.imageurl + '/' + agent.pic;
				let agentId = agent.subid;
				agentsImage[`${agentId}`] = agent.pic !== 'notexist' ? imageUrl : '';
			})
		}).then(() => {
			return getOwnerDetails(this.agent.organization_alias ? this.agent.organization_alias : this.agent.ownerIdentifire);
		}).then((ownerDetails) => {
			if (ownerDetails.status === 200) {
				let imageUrl = ownerDetails.data.imageurl + '/' + ownerDetails.data.pic;
				agentsImage['owner'] = ownerDetails.data.pic !== 'notexist' ? imageUrl : '';
			}
		}).then(() => {
			this.setState({ agentsImage: agentsImage });
		})
	}

	componentDidUpdate = (prevProps, preState, snapshot) => {
		if (this.props.visitor.visitorId !== "" && prevProps.visitor.visitorId !== this.props.visitor.visitorId) {
			this.getChat();
			this.getJoinedAgents();
		}
	}

	closeChat = () => {
		try {
			document.getElementById("shadowMasking").style.display = "none";
		} catch (err) { }

		this.setState({ chatdetail: [], allData: [], chatOrgArray: [], showVisitorPath: false, visitorPathList: [], showNotes: false, allNotes: [], noteFound: false, showAddNote: false });
	}

	searchChat = e => {
		var searchText = e.target.value;
		searchText = searchText.trim();
		if (searchText.length === 0) {
			this.setState({ chatdetail: this.state.allData });
			return;
		}
		var q = [];
		this.state.filteredIndex.map((e, j) => {
			if (e.msg_type !== 3 && e.msg_type !== 4 && e.msg_type !== 8) {
				if (e.msg.indexOf(searchText.toLowerCase()) !== -1) {
					q.push(e.id);
				}
			} else {
				q.push(e.id);
			}
		});
		var filteredData = this.state.allData.filter(data => {
			if (q.indexOf(data.key) !== -1) {
				return true;
			} else {
				return false;
			}
		});
		this.setState({ chatdetail: filteredData });
	};

	createFile = (e) => {
		var text = this.createTranscript();
		var data = new Blob([text], { type: "text/plain" });
		var textFile = window.URL.createObjectURL(data);
		document.getElementById("downloadlink").href = textFile;
		this.setState({ href: textFile });
		activityLog("Downloaded transcript");
	};

	createTranscript = () => {
		var str = "Chat Started on " + this.props.visitor.addedon + "\n";
		this.state.chatOrgArray.map(j => {
			var tmpDate = Math.ceil(j.addedon / 1000);
			var utcTime = moment.tz(tmpDate, "X", "UTC").tz(moment.tz.guess(true)).format("hh:mm:ss");
			if (j.msg_type === 8) {
				str = str + `[${utcTime}]: BOT: \n `;
				j.msg.map(e => {
					str = str + `Oues: ${e.Q} \n Ans: ${e.A} \n`;
				});
			} else {
				if (j.agent_id === null && (j.action === "AGENT_TRANSFER" || j.action === "WELCOME_PAID_VISITOR")) {
					return;
				} else {
					if (j.msg_type === 6 || j.msg_type === 7) {
						if (j.msg.trim() === 'You ended the chat.') {
							str += `[${utcTime}]: \t ${this.props.visitor.name} ended the chat  \n`;
						} else {
							str += `[${utcTime}]: \t ${j.msg} \n`;
						}
					} else {
						let jMsg = j.msg_type === 12 ? removeHtmlTags(j.msg) : j.msg;
						if (j.agent_id === null) {
							str += `[${utcTime}]: \t ${this.props.visitor.name}: ${jMsg} \n`;
						} else {
							str += `[${utcTime}]: \t ${j.agent_name ? j.agent_name : "BOT"}: ${jMsg} \n`;
						}
					}
				}
			}
		});
		return str;
	};

	showvisitorPath = () => {
		if (!this.props.visitor.visitorId) {
			return null;
		}

		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('visitors').doc(this.props.visitor.visitorId).get().then((docs) => {
			if (docs.exists) {
				var vData = docs.data();
				this.setState({
					showVisitorPath: true,
					visitorPathList: vData.PageUrl_a
				})
				activityLog("Viewed visitor path");
			}
		})
	}

	backToList = () => {
		this.setState({
			showVisitorPath: false,
			showNotes: false,
			noteFound: false,
			showAddNote: false
		})
	}

	backToNotesList = () => {
		this.setState({
			showVisitorPath: false,
			showNotes: true,
			noteFound: false,
			showAddNote: false
		})
	}

	showNotes = () => {
		if (!this.props.visitor.clEmail) {
			return null;
		}

		let promise1 = new Promise((res, rej) => {
			if (this.props.visitor.clEmail !== '' && this.props.visitor.clEmail !== undefined) {
				firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(this.props.visitor.clEmail).collection('notes').orderBy('addedon', 'desc').get().then((notes) => {
					var notesArr = [];
					notes.forEach((note) => {
						var notes = note.data();
						notes.nid = note.id;
						notes.type = 'email';
						notesArr.push(notes);
					})
					res(notesArr);
				});
			}
		})

		let promise2 = new Promise((Resolve, Reject) => {
			firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(this.props.visitor.id).collection('notes').orderBy('addedon', 'desc').get().then((notes) => {
				var notesArr = [];
				notes.forEach((note) => {
					var notes = note.data();
					notes.nid = note.id;
					notes.type = 'id';
					notesArr.push(notes);
				})
				Resolve(notesArr);
			});
		})

		Promise.all([promise1, promise2]).then(results => {
			var finalResult = [...results[0], ...results[1]];
			finalResult.sort(function (a, b) {
				return b.addedon - a.addedon;
			});
			var notesMsgs = [];
			finalResult.forEach((fData) => {
				notesMsgs[fData.nid] = fData.notes;
			})
			var noteFound = false;
			if (finalResult.length === 0) {
				noteFound = true;
			}
			this.setState({ showNotes: true, allNotes: finalResult, showVisitorPath: false, showAddNote: false, notesMsgs: notesMsgs, noteFound: noteFound });
			activityLog("Viewed notes");
		})

	}

	deleteNote = (noteId, notType) => {
		var notId = notType === 'email' ? this.props.visitor.clEmail : this.props.visitor.id;
		firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(notId).collection('notes').doc(noteId).delete().then(() => {
			this.showNotes();
			activityLog("Deleted note");
		})
	}

	editNote = (noteId) => {
		document.getElementById("note-inner-" + noteId).style.display = "none";
		document.getElementById("note-edit-" + noteId).style.display = "block";
	}

	cancelUpdate = (noteId) => {
		document.getElementById("note-inner-" + noteId).style.display = "block";
		document.getElementById("note-edit-" + noteId).style.display = "none";
	}

	updateNote = async (noteId, notType) => {
		var value = document.getElementById("note-text-" + noteId).value.trim();
		if (value) {
			var notId = notType === 'email' ? this.props.visitor.clEmail : this.props.visitor.id;
			var servertimeStamp = await getDateInUTC();
			var agentName = this.agent.alias ? this.agent.alias : this.agent.name;
			var logs = { agent: this.agent.agentId, time: servertimeStamp };
			firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('notes').doc(notId).collection('notes').doc(noteId).update({
				notes: value,
				last_updated: servertimeStamp,
				last_updated_agent_id: this.agent.agentId,
				last_updated_agent_name: agentName,
				edit_logs: firebase.firestore.FieldValue.arrayUnion(logs)
			}).then(() => {
				this.showNotes();
				document.getElementById("note-inner-" + noteId).style.display = "block";
				document.getElementById("note-edit-" + noteId).style.display = "none";
				activityLog("Updated note");
			})
		}
	}

	addNote = () => {
		this.setState({
			showAddNote: true,
			showNotes: false
		})
	}

	saveNote = async () => {
		var notes = document.getElementById("add-note").value.trim();
		if (notes) {
			var addedon = await getDateInUTC();
			var docId = 'NOTE' + addedon;
			var notId = this.props.visitor.clEmail !== '' && this.props.visitor.clEmail !== 'noreply@appypie.com' ? this.props.visitor.clEmail : this.props.visitor.id;
			firebaseServices.db.collection('users').doc(this.agent.ownerId).collection("notes").doc(notId).collection('notes').doc(docId).set({
				addedon: addedon,
				agent_id: this.agent.agentId,
				agent_name: this.agent.alias ? this.agent.alias : this.agent.name,
				notes: notes,
				clientId: this.props.visitor.id
			}).then((ref) => {
				this.showNotes();
				activityLog("Added note");
			}).catch((error) => {
				console.error("Error adding notes: ", error);
			});
		}
	}

	canceladdNote = () => {
		this.setState({
			showAddNote: false,
			showNotes: true
		})
	}

	textChangeHandle = (e, notId) => {
		var notes = this.state.notesMsgs;
		var target = e.target;
		notes[notId] = target.value;
		this.setState({
			notesMsgs: notes,
			testMsg: target.value
		})
	}

	translateChat = async (e) => {
		this.setState({ loader: true });
		let chatIdesArray = [];
		let chatMessagesArray = [];
		let formDataMsg = [];
		let checkEmpty2 = true;
		var chatMessages = [];
		this.state.chatOrgArray.map(visData => {
			if (visData.msg_type === 1 || visData.msg_type === 7 || visData.msg_type === 13 || visData.msg_type === 15 || visData.msg_type === 21 || visData.msg_type === 41 || visData.msg_type === 46) {
				chatIdesArray.push(visData.id);
				chatMessagesArray.push(visData.msg);
			} else if (visData.msg_type === 8) {
				for (const formKey in visData.msg) {
					if (visData.msg.hasOwnProperty(formKey)) {
						const element = visData.msg[formKey];
						if (element.A !== "") {
							checkEmpty2 = false;
						}
						formDataMsg.push(element.Q);
						formDataMsg.push(element.A);
					}
				}
			}
		});

		if (!checkEmpty2 && formDataMsg.length > 0) {
			let datab1 = {
				"q": formDataMsg,
				"target": "en",
				//source:'en'
			};
			var fData_tr1 = [];
			await fetch("https://translation.googleapis.com/language/translate/v2?key=" + GOOGLE_TRANSLATION_KEY, {
				method: 'POST',
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				headers: {
					'Content-Type': 'application/json'
				},
				redirect: 'follow',
				referrer: 'no-referrer',
				body: JSON.stringify(datab1)
			}).then(res => res.json()).then((result) => {
				const errorCode1 = result.error ? result.error.code : false;
				var trText1 = (result.data && !errorCode1) ? result.data.translations : [];
				for (var i = 0; i < trText1.length; i += 2) {
					fData_tr1.push({ Q: trText1[i].translatedText, A: decodeEntities(trText1[i + 1].translatedText) });
				}
			}).catch(err => { });
		}

		let datab = {
			"q": chatMessagesArray,
			"target": "en"
		};

		let fData_tr = [];
		await fetch("https://translation.googleapis.com/language/translate/v2?key=" + GOOGLE_TRANSLATION_KEY, {
			method: 'POST',
			mode: 'cors',
			cache: 'no-cache',
			credentials: 'same-origin',
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow',
			referrer: 'no-referrer',
			body: JSON.stringify(datab)
		}).then(res => res.json()).then((result) => {
			const errorCode = result.error ? result.error.code : false;
			let trText = (result.data && !errorCode) ? result.data.translations : [];
			for (let i = 0; i < trText.length; i++) {
				fData_tr.push(decodeEntities(trText[i].translatedText));
			}
			var finalChatData = [];
			this.state.chatOrgArray.map(async (visData2, index) => {
				var msg = visData2.msg;
				var recordingUrl = visData2.recordingUrl;
				var msg1 = '';
				var imageContent = '';
				var timespan = '';
				var agentcheck = 2;
				var className = '';
				var content = '';
				var showli = 1;
				var chatBubbleClass = "message-body";
				var msgTime = this.getTimeFromDate(visData2.addedon);

				let msg_type = visData2.msg_type;
				let agent_id = visData2.agent_id;
				let contenttype = visData2.contenttype;
				// let agent_profile_img = visData2.agent_profile_img;
				const agent_profile_img = this.state.agentsImage[agent_id] !== undefined ? this.state.agentsImage[agent_id] : this.state.agentsImage['owner'];
				let msg_url = visData2.msg_url;
				let agent_short = visData2.agent_short;
				let filesize = visData2.filesize;

				if (msg_type === 1 || msg_type === 7 || msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 || msg_type === 46) {
					let indxOfmessage = chatIdesArray.indexOf(visData2.id);
					msg = fData_tr[indxOfmessage];
				} else if (msg_type === 8) {
					if (!checkEmpty2 && fData_tr1.length > 0) {
						msg = fData_tr1;
					}
				}

				if (msg_type === 1) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = '<div class="message-content-new bg-white box-shadow-chat">' + filMsg + '</div>';
					} else {
						msg1 = '<div class="bg-primary text-white message-content-new">' + filMsg + '</div>';
					}
				} else if (msg_type === 2) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					if (agent_id === null) {
						msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
					} else {
						msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
					}
				} else if (msg_type === 3) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
					var stringUrl = msg_url.replace(/[0-9]/g, '');
					var msgNew = stringUrl;
					if (stringUrl.length > 15) {
						msgNew = stringUrl.substring(0, 15) + '...';
					}
					var formatedSize = this.formatBytes(filesize, 2);
					let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
					if (agent_id === null) {
						msg1 = '<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
					} else {
						msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
					}
				} else if (msg_type === 8 && msg !== '') {
					var msgString = '';
					var checkEmpty = true;
					msg.forEach((msgData, i) => {
						if (msgData.A !== "") {
							checkEmpty = false;
						}
						let tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
						msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + tmpchtMsg + '</span></p></li>';
					});

					if (!checkEmpty) {
						msg1 = '<div class="message-content-new bg-white box-shadow-chat question-sections-first"><div class="liveChat-Message"><ul class="liveChat-question-answer">' + msgString + '</ul></div></div>';
					} else {
						showli = 0;
					}
				} else if (msg_type === 4) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
					msg1 = '<a href=' + msg + ' src=' + msg + ' download=' + msg_url + '> Download </a>';
				} else if (msg_type === 6) {
					var agentId = visData2.agent_id;
					if (agentId === "AGENT_EVENT_TRIGGER" && visData2.action === "BUSY") {
						showli = 0;
					} else if (agentId === null && (visData2.action === "AGENT_TRANSFER" || visData2.action === "WELCOME_PAID_VISITOR")) {
						showli = 0;
					} else if (visData2.action === "VISITOR_END_CHAT" && msg.indexOf("You ended the chat") !== -1) {
						msg1 = '<div class="col-auto"><small class="text-muted">Visitor ended the chat.</small><p class="date-agent">' + msgTime + '</p></div>';
					} else {
						var tt = visData2.msg_otheragent;
						if (tt !== undefined) {
							msg1 = '<div class="col-auto"><small class="text-muted">' + tt + '</small><p class="date-agent">' + msgTime + '</p></div>';
						} else {
							msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
						}
					}
					agentcheck = 3;
				} else if (msg_type === 7) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
					agentcheck = 3;
				} else if (msg_type === 11) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /></div>`;
					} else {
						msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
					}
				} else if (msg_type === 13) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></div>`;
					} else {
						msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
					}
				} else if (msg_type === 12) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					if (agent_id === null) {
						msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
							(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
					} else {
						msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
							(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
					}
					agentcheck = 3;
				} else if (msg_type === 15) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div>`;
					} else {
						msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
					}
				} else if (msg_type === 16) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
					var stringUrl = msg_url.replace(/[0-9]/g, '');
					var msgNew = stringUrl;
					if (stringUrl.length > 15) {
						msgNew = stringUrl.substring(0, 15) + '...';
					}
					var formatedSize = this.formatBytes(filesize, 2);
					let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
					if (agent_id === null) {
						msg1 = `<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div>`;
					} else {
						msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
					}
				} else if (msg_type === 17 || msg_type === 23) {
					/// Do nothing, continue
				} else if (msg_type === 21) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div>`;
					} else {
						msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
					}
				} else if (msg_type === 22) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					if (agent_id === null) {
						msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div>';
					} else {
						msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
					}
				} else if (msg_type === 61) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					let filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					let filMsg = checkUrl(filterMsg);
					msg1 = '<div class="bg-triggers text-white message-content-new">' + filMsg + '</div>';
				} else if (msg_type === 41) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					var filMsg = checkUrl(filterMsg);
					if (agent_id === null) {
						msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></div>`;
					} else {
						msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
					}
				} else if (msg_type === 42 || msg_type === 44) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="${msg}"></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
				} else if (msg_type === 43) {
					// Video from instagram
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					msg1 = `<div class="message"><div class="message-body"><div style="display: flex;
					align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
				} else if (msg_type === 45) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					let contentType = await this.contentTypeFromURL(msg);
					if (contentType === 'video/mp4') {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><video class="videomsg" src="${msg}" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="${msg}" download="${msg}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${visData2.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="${msg}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${visData2.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					}
				} else if (msg_type === 46) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					if (visData2.repliedToMessage && visData2.repliedToMessage.message) {
						let messageToAppend = "";
						if (visData2.repliedToMessage.messageType === 42) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${visData2.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.repliedToMessage.messageType === 43) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><video class="videomsg" src="${visData2.repliedToMessage.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${visData2.repliedToMessage.message}" download="${visData2.repliedToMessage.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.repliedToMessage.messageType === 44) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${visData2.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.repliedToMessage.messageType === 48) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><audio controls><source class="instaaudio" src="${visData2.repliedToMessage.message}" type="audio/ogg"><source class="instaaudio" src="${visData2.repliedToMessage.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>${visData2.repliedToMessage.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						}
					}
				} else if (msg_type === 47) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					if (visData2.reactOnMessageId && visData2.reactOnMessageId.message) {
						if (visData2.reactOnMessageId.messageType === 42) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${visData2.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.reactOnMessageId.messageType === 43) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><video class="videomsg" src="${visData2.reactOnMessageId.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${visData2.reactOnMessageId.message}" download="${visData2.reactOnMessageId.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.reactOnMessageId.messageType === 44) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${visData2.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else if (visData2.reactOnMessageId.messageType === 48) {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><audio controls><source class="instaaudio" src="${visData2.reactOnMessageId.message}" type="audio/ogg"><source class="instaaudio" src="${visData2.reactOnMessageId.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						} else {
							msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>${visData2.reactOnMessageId.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
						}
					}
				} else if (msg_type === 48) {
					chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
					msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><audio controls><source class="instaaudio" src="${msg}" type='audio/ogg'><source class="instaaudio" src="${msg}" type='audio/mpeg'>Your browser does not support the audio element.</audio></div></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
				} else {
					showli = 0;
				}

				if (visData2.hasOwnProperty("msg_deleted") && showli === 1) {
					showli = 0;
				}

				if (agent_id === null) {
					className = "message";
					imageContent = '<span class="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile"><span>' + this.props.visitor.client_short + '</span></span>';
					content = msg1;
					timespan = "mt-0";
					var agentClass1 = "d-flex align-items-center";
				} else {
					if (agentcheck === 3) {
						className = "message message-right statusmsg";
					} else {
						className = "message message-right";
					}

					if (agent_profile_img !== '' && agent_profile_img !== null) {
						imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><img class="avatar-img" src="' + agent_profile_img + '" alt="" />';
					} else {
						if (msg_type === 61) {
							imageContent = '<div style="background-color: #e38511"class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span style="color: #fff">#T</span></div>';
						} else {
							imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span>' + agent_short + '</span></div>';
						}
					}
					content = msg1;
					timespan = "mt-0";
					var agentClass1 = "d-flex align-items-center justify-content-flexend text-right ";
				}

				if (msg_type === 6 || msg_type === 7 || msg_type === 12) {
					className = "message-divider my-5 mx-lg-4";
					chatBubbleClass = "row align-items-center";
				}

				if (agentcheck === 3) {

				} else if (agent_id === '' || agent_id === 'null' || agent_id === null) {
					agentcheck = 1;
					if (msg_type === 8) {
						agentcheck = 10;
						chatBubbleClass = "message-body";
					}
				}
			
				if (showli === 1) {
					finalChatData[index] = (<div key={visData2.id} className={className} id={visData2.id}>
						{agentcheck === 1 ? parse(imageContent) : ''}
						<div className={chatBubbleClass}>
							{(msg_type === 1) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}>
								<small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
							{msg_type === 11 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
							{msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
							{msg_type === 8 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }} >{parse(content)}</Linkify></div></div></div> : ''}
							{msg_type === 2 || msg_type === 22 || msg_type === 42 || msg_type === 44 || msg_type === 45 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his w-100">{parse(content)}<div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
							{msg_type === 3 || msg_type === 16 ? parse(content) : ''}
							{msg_type === 4 ? <div className="msgvideo"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /></div> : ''}
							{msg_type === 6 || msg_type === 43 || msg_type === 46 || msg_type === 47 || msg_type === 48 ? parse(content) : ''}
							{msg_type === 12 ? parse(content) : ''}
							{msg_type === 7 ? parse(content) : ''}
							{msg_type === 17 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src={require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div> : ''}
							{msg_type === 23 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div></div></div></div> : ''}
							{(msg_type === 61) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
							{msgTypes.indexOf(msg_type) === -1 ? <div className={timespan}><small className="opacity-65">{msgTime}</small></div> : ''}
						</div>
						{agentcheck === 2 ? parse(imageContent) : ''}
					</div>);
				}
			});
			this.setState({ chatdetail: finalChatData, allData: finalChatData, filteredIndex: chatMessages, isTranslated: true, loader: false });
		}).catch(err => { });
	}

	stopTranslateChat = async (e) => {
		this.setState({ loader: true });
		var chatMessages = [];
		var finalChatData = [];
		this.state.chatOrgArray.map(async (visData2, index) => {
			var msg = visData2.msg;
			var recordingUrl = visData2.recordingUrl;
			var msg1 = '';
			var imageContent = '';
			var timespan = '';
			var agentcheck = 2;
			var className = '';
			var content = '';
			var showli = 1;
			var chatBubbleClass = "message-body";
			var msgTime = this.getTimeFromDate(visData2.addedon);
			let msg_type = visData2.msg_type;
			let agent_id = visData2.agent_id;
			let contenttype = visData2.contenttype;
			// let agent_profile_img = visData2.agent_profile_img;
			const agent_profile_img = this.state.agentsImage[agent_id] !== undefined ? this.state.agentsImage[agent_id] : this.state.agentsImage['owner'];
			let msg_url = visData2.msg_url;
			let agent_short = visData2.agent_short;
			let filesize = visData2.filesize;

			if (msg_type === 1) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = '<div class="message-content-new bg-white box-shadow-chat">' + filMsg + '</div>';
				} else {
					msg1 = '<div class="bg-primary text-white message-content-new">' + filMsg + '</div>';
				}
			} else if (msg_type === 2) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				if (agent_id === null) {
					msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
				} else {
					msg1 = '<div class="form-row py-3"><div class="col"><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
				}
			} else if (msg_type === 3) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
				var stringUrl = msg_url.replace(/[0-9]/g, '');
				var msgNew = stringUrl;
				if (stringUrl.length > 15) {
					msgNew = stringUrl.substring(0, 15) + '...';
				}
				var formatedSize = this.formatBytes(filesize, 2);
				let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
				if (agent_id === null) {
					msg1 = '<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
				} else {
					msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
				}
			} else if (msg_type === 8 && msg !== '') {
				var msgString = '';
				var checkEmpty = true;
				msg.forEach((msgData, i) => {
					if (msgData.A !== "") {
						checkEmpty = false;
					}
					let tmpchtMsg = String(msgData.A).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
					msgString = msgString + '<li><h5 class="heding-text k-flex align-items-baseline"><strong>Ques :- </strong><span>' + msgData.Q + '</span></h5>  <p class="k-flex align-items-baseline"><strong>Ans :- </strong><span id="formAnswer_' + i + '">' + tmpchtMsg + '</span></p></li>';
				});

				if (!checkEmpty) {
					msg1 = '<div class="message-content-new bg-white box-shadow-chat question-sections-first"><div class="liveChat-Message"><ul class="liveChat-question-answer">' + msgString + '</ul></div></div>';
				} else {
					showli = 0;
				}
			} else if (msg_type === 4) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
				msg1 = '<a href=' + msg + ' src=' + msg + ' download=' + msg_url + '> Download </a>';
			} else if (msg_type === 6) {
				var agentId = visData2.agent_id;
				if (agentId === "AGENT_EVENT_TRIGGER" && visData2.action === "BUSY") {
					showli = 0;
				} else if (agentId === null && (visData2.action === "AGENT_TRANSFER" || visData2.action === "WELCOME_PAID_VISITOR")) {
					showli = 0;
				} else if (visData2.action === "VISITOR_END_CHAT" && msg.indexOf("You ended the chat") !== -1) {
					msg1 = '<div class="col-auto"><small class="text-muted">Visitor ended the chat.</small><p class="date-agent">' + msgTime + '</p></div>';
				} else {
					var tt = visData2.msg_otheragent;
					if (tt !== undefined) {
						msg1 = '<div class="col-auto"><small class="text-muted">' + tt + '</small><p class="date-agent">' + msgTime + '</p></div>';
					} else {
						msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
					}
				}
				agentcheck = 3;
			} else if (msg_type === 7) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				msg1 = '<div class="col-auto"><small class="text-muted">' + msg + '</small><p class="date-agent">' + msgTime + '</p></div>';
				agentcheck = 3;
			} else if (msg_type === 11) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /></div>`;
				} else {
					msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via SMS" width="32px" src=${require("../../../../src/assets/images/livechat/sent_via_sms.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
				}
			} else if (msg_type === 13) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /></div>`;
				} else {
					msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via whatsapp" width="32px" src=${require("../../../../src/assets/images/livechat/whatsapp.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
				}
			} else if (msg_type === 12) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				if (agent_id === null) {
					msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
						(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent"> ${msgTime} </p><img alt="" width="13px" src=${require("../../../assets/images/livechat/incoming-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
				} else {
					msg1 = recordingUrl ? (`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div><audio controls class="mt-4"><source src='${recordingUrl}' type='audio/ogg'><source src='${recordingUrl}.mp3' type='audio/mpeg'>Your browser does not support the audio element.</audio>`) :
						(`<div class="col"><hr /></div><div class="col-auto"><p class="date-agent">${msgTime}</p><img alt="" width="13px" src=${require("../../../assets/images/livechat/outgoing-call.png")} /><small class="call-muted">${msg}</small></div><div class="col"><hr /></div>`);
				}
				agentcheck = 3;
			} else if (msg_type === 15) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div>`;
				} else {
					msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
				}
			} else if (msg_type === 16) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: "Attachment" });
				var stringUrl = msg_url.replace(/[0-9]/g, '');
				var msgNew = stringUrl;
				if (stringUrl.length > 15) {
					msgNew = stringUrl.substring(0, 15) + '...';
				}
				var formatedSize = this.formatBytes(filesize, 2);
				let contenttypeTmp = (contenttype === undefined || typeof (contenttype) === undefined) ? "File" : contenttype;
				if (agent_id === null) {
					msg1 = `<div class="message-row"><div class="d-flex"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="${msg}" src="${msg}" download="${msg_url}" target="_blank"><span class="filesNameView">${msgNew}</span><span class="fileSizeView">${formatedSize}</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">${contenttypeTmp}</span></span></a></div><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src=${require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div>`;
				} else {
					msg1 = '<div class="message-row"><div class="d-flex align-items-center justify-content-end"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><a href="' + msg + '" src="' + msg + '" download="' + msg_url + '" target="_blank"><span class="filesNameView">' + msgNew + '</span><span class="fileSizeView">' + formatedSize + '</span><span class="fileDownload">Download</span><span class="whichFile"><span class="whichFileName">' + contenttypeTmp + '</span></span></a></div></div></div></div></div>';
				}
			} else if (msg_type === 17 || msg_type === 23) {
				/// Do nothing, continue
			} else if (msg_type === 21) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div>`;
				} else {
					msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
				}
			} else if (msg_type === 22) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				if (agent_id === null) {
					msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><div class="msgimageVisitor"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div>';
				} else {
					msg1 = '<div class="form-row py-3"><div class="d-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm mr-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg><div class="msgimageNew"><a href="' + msg + '" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded" src=' + msg + ' /></a></div></div></div>';
				}
			} else if (msg_type === 61) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				let filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				let filMsg = checkUrl(filterMsg);
				msg1 = '<div class="bg-triggers text-white message-content-new">' + filMsg + '</div>';
			} else if (msg_type === 41) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				var filterMsg = String(msg).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
				var filMsg = checkUrl(filterMsg);
				if (agent_id === null) {
					msg1 = `<div class="k-flex align-items-center"><div class="message-content-new bg-white box-shadow-chat"> ${filMsg} </div><img class="ml-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></div>`;
				} else {
					msg1 = `<div style="display: inline-block;"><div class="k-flex align-items-center"><img class="mr-4" alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /><div class="bg-primary text-white message-content-new"> ${filMsg} </div></div></div>`;
				}
			} else if (msg_type === 42 || msg_type === 44) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content"><div class="form-row py-3"><div class="col"><div class="msgimageNew msg-img-hover position-relative"><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" src="${msg}"></a></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
			} else if (msg_type === 43) {
				// Video from instagram
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				msg1 = `<div class="message"><div class="message-body"><div style="display: flex;
				align-items: center; width: 200px;" class="msgvideo"><div class="videoFrame" style="width: 100%; height: 100%;"><video class="videomsg" src="${msg}" preload="auto" controls="" style="width: 100%; height: 100%;"></video></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
			} else if (msg_type === 45) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				let contentType = await this.contentTypeFromURL(msg);
				if (contentType === 'video/mp4') {
					msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><video class="videomsg" src="${msg}" preload="auto" controls="" style="max-width: 100px; max-height: 100px" href="${msg}" download="${msg}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${visData2.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
				} else {
					msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to Story - </small><a href="${msg}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 100px; max-height: 100px" src="${msg}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${visData2.storyMsg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
				}
			} else if (msg_type === 46) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				if (visData2.repliedToMessage && visData2.repliedToMessage.message) {
					if (visData2.repliedToMessage.messageType === 42) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${visData2.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.repliedToMessage.messageType === 43) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><video class="videomsg" src="${visData2.repliedToMessage.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${visData2.repliedToMessage.message}" download="${visData2.repliedToMessage.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.repliedToMessage.messageType === 44) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><a href="${visData2.repliedToMessage.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.repliedToMessage.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.repliedToMessage.messageType === 48) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small><audio controls><source class="instaaudio" src="${visData2.repliedToMessage.message}" type="audio/ogg"><source class="instaaudio" src="${visData2.repliedToMessage.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">Replied to - </small>${visData2.repliedToMessage.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					}
				}
			} else if (msg_type === 47) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				if (visData2.reactOnMessageId && visData2.reactOnMessageId.message) {
					if (visData2.reactOnMessageId.messageType === 42) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${visData2.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.reactOnMessageId.messageType === 43) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><video class="videomsg" src="${visData2.reactOnMessageId.message}" preload="auto" controls="" style="width: 130px; height: 100px" href="${visData2.reactOnMessageId.message}" download="${visData2.reactOnMessageId.message}"></video></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.reactOnMessageId.messageType === 44) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><a href="${visData2.reactOnMessageId.message}" class="openattachment" target="_blank"><img data-action="zoom" alt="" class="img-fluid rounded attachment" style="max-width: 50px; max-height: 50px" src="${visData2.reactOnMessageId.message}"></a></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else if (visData2.reactOnMessageId.messageType === 48) {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small><audio controls><source class="instaaudio" src="${visData2.reactOnMessageId.message}" type="audio/ogg"><source class="instaaudio" src="${visData2.reactOnMessageId.message}" type="audio/mpeg">Your browser does not support the audio element.</audio></div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					} else {
						msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="mt-0"><small class="message_reply" style="background: #eee;padding: 7px;border-radius: 5px;">React on - </small>${visData2.reactOnMessageId.message}</div><div class="d-flex align-items-center"><div class="message-content"><div class="k-flex align-items-center justify-content-center"><div class="message-content-new bg-white box-shadow-chat">${msg}</div><span class="sendbysms"><img alt="Sent via instagram messanger" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div><div class="mt-0"><small class="opacity-65"></small></div></div></div></div></div></div>`;
					}
				}
			} else if (msg_type === 48) {
				chatMessages.push({ id: visData2.id, msg_type: msg_type, msg: msg.toLowerCase() });
				msg1 = `<div class="message"><div class="message-body"><div class="message-row"><div class="d-flex align-items-center"><div class="message-content text-white message-c-q-s-parent"><div class="media"><div class="msgfilesNew"><audio controls><source class="instaaudio" src="${msg}" type='audio/ogg'><source class="instaaudio" src="${msg}" type='audio/mpeg'>Your browser does not support the audio element.</audio></div></div></div><span class="sendbysms"><img alt="Sent via instagram dm" width="32px" src=${require("../../../assets/images/livechat/56px-Instagram_icon.png")} /></span></div></div><div class="mt-0"><small class="opacity-65"></small></div></div></div>`;
			} else {
				showli = 0;
			}

			if (visData2.hasOwnProperty("msg_deleted") && showli === 1) {
				showli = 0;
			}

			if (agent_id === null) {
				className = "message";
				imageContent = '<span class="avatar avatar-sm mr-4 mr-lg-5" href="#" data-chat-sidebar-toggle="#chat-1-user-profile"><span>' + this.props.visitor.client_short + '</span></span>';
				content = msg1;
				timespan = "mt-0";
				var agentClass1 = "d-flex align-items-center";
			} else {
				if (agentcheck === 3) {
					className = "message message-right statusmsg";
				} else {
					className = "message message-right";
				}

				if (agent_profile_img !== '' && agent_profile_img !== null) {
					imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><img class="avatar-img" src="' + agent_profile_img + '" alt="" />';
				} else {
					if (msg_type === 61) {
						imageContent = '<div style="background-color: #e38511"class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span style="color: #fff">#T</span></div>';
					} else {
						imageContent = '<div class="avatar avatar-sm ml-4 ml-lg-5 d-none d-lg-block"><span>' + agent_short + '</span></div>';
					}
				}
				content = msg1;
				timespan = "mt-0";
				var agentClass1 = "d-flex align-items-center justify-content-flexend text-right ";
			}

			if (msg_type === 6 || msg_type === 7 || msg_type === 12) {
				className = "message-divider my-5 mx-lg-4";
				chatBubbleClass = "row align-items-center";
			}

			if (agentcheck === 3) {

			} else if (agent_id === '' || agent_id === 'null' || agent_id === null) {
				agentcheck = 1;
				if (msg_type === 8) {
					agentcheck = 10;
					chatBubbleClass = "message-body";
				}
			}

			if (showli === 1) {
				finalChatData[index] = (<div key={visData2.id} className={className} id={visData2.id}>
					{agentcheck === 1 ? parse(imageContent) : ''}
					<div className={chatBubbleClass}>
						{(msg_type === 1) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}>
							<small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
						{msg_type === 11 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
						{msg_type === 13 || msg_type === 15 || msg_type === 21 || msg_type === 41 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
						{msg_type === 8 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }} >{parse(content)}</Linkify></div></div></div> : ''}
						{msg_type === 2 || msg_type === 22 || msg_type === 42 || msg_type === 44 || msg_type === 45 ? <div className="message-row"><div className={agentClass1}><div className="message-content-his w-100">{parse(content)}<div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
						{msg_type === 3 || msg_type === 16 ? parse(content) : ''}
						{msg_type === 4 ? <div className="msgvideo"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /></div> : ''}
						{msg_type === 6 || msg_type === 43 || msg_type === 46 || msg_type === 47 || msg_type === 48 ? parse(content) : ''}
						{msg_type === 12 ? parse(content) : ''}
						{msg_type === 7 ? parse(content) : ''}
						{msg_type === 17 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><img class="ml-4" alt="Sent via facebook" width="32px" src={require("../../../../src/assets/images/livechat/fb2.svg")} /></div></div></div></div></div> : ''}
						{msg_type === 23 ? <div className="message-row"><div className="d-flex"><div className="message-content text-white message-c-q-s-parent"><div className="media"><ReactPlayer className="videoFrame" url={msg} controls width='100%' height='100%' /><div class="k-flex align-items-center"><svg viewBox="0 0 24 24" aria-hidden="true" class="twitter-dm ml-2"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></div></div></div></div></div> : ''}
						{(msg_type === 61) ? <div className="message-row"><div className={agentClass1}><div className="message-content-his"><Linkify properties={{ target: '_blank' }}>{parse(content)}</Linkify><div className={timespan}><small className="opacity-65">{msgTime}</small></div></div></div></div> : ''}
						{msgTypes.indexOf(msg_type) === -1 ? <div className={timespan}><small className="opacity-65">{msgTime}</small></div> : ''}
					</div>
					{agentcheck === 2 ? parse(imageContent) : ''}
				</div>);
			}
		});
		this.setState({ chatdetail: finalChatData, allData: finalChatData, filteredIndex: chatMessages, isTranslated: false, loader: false });
	}

	render() {
		
		if (Object.keys(this.props.visitor).length <= 0) {
			return false;
		}

		var isShow = "none";
		if (this.props.visitor.visitorId) {
			isShow = "block";
		}

		var country = "";
		if (this.props.visitor.country && this.props.visitor.country.indexOf("detected") === -1) {
			country = this.props.visitor.country.toLowerCase();
		}

		var operating = "";
		if (this.props.visitor.country) {
			operating = this.props.visitor.operating.toLowerCase().replace(" ", "-");
		}

		var browser = "";
		if (this.props.visitor.country) {
			browser = this.props.visitor.browser.toLowerCase().replace(" ", "-");
		}

		return (
			<>
				<ReactTooltip />
				<div style={{ display: isShow }} className="rightPanelSection chat-his-rightpanel overflow-x-hidden">
					<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
					<div className="categoryRightPanel chat-his-name">
						{this.state.showVisitorPath ? (<><div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
							<div className="row">
								<div className="col-6 col-xl-8">
									<div className="media text-center text-xl-left" style={{ overflow: 'visible' }}>
										<div className="media-body align-self-center text-truncate" style={{ overflow: 'visible' }}>
											<h6 className="text-truncate mb-n1 k-flex" style={{ overflow: 'visible' }}>
												<span style={{ cursor: "pointer" }} onClick={() => this.backToList()}><img alt="" src={require('../../../assets/images/left-arrow.png')} /> </span>
												<span className="name-chat-header"> Visitor Path</span>
											</h6>
										</div>
									</div>
								</div>
								<div className="col-6 col-xl-4 text-right">
									<ul className="nav k-flex justify-content-end">
										<li className="nav-item list-inline-item ml-2">
											<span className="heder-icon-img icon-rightpanel-icon" onClick={() => { this.props.closeChat(); this.closeChat() }}>
												<img alt="" src={require("../../../assets/images/livechat/right-panel-crose.svg")} />
											</span>
										</li>

									</ul>
								</div>
							</div>
						</div>
							<div className="bodyRightPanel scroll-4">
								<div className="rightPanelMain">
									<div className="chat-his-chat">
										<div className="container-xxl chat-content px-lg-8">
											<div className="py-5 py-lg-5 visitorPathRightPanel visitorPathRightPanelCircle">
												<ul style={{ listStyle: 'none' }}>
													{this.state.visitorPathList.map((pageUrl, i) => {
														return <li key={i}>{pageUrl}</li>
													})}
												</ul>
											</div>
											<div className="end-of-chat"></div>
										</div>
									</div>
								</div>
							</div></>) : (this.state.showNotes ? (<><div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
								<div className="row">
									<div className="col-6 col-xl-8">
										<div className="media text-center text-xl-left" style={{ overflow: 'visible' }}>
											<div className="media-body align-self-center text-truncate" style={{ overflow: 'visible' }}>
												<h6 className="text-truncate mb-n1 k-flex" style={{ overflow: 'visible' }}>
													<span style={{ cursor: "pointer" }} onClick={() => this.backToList()}><img alt="" src={require('../../../assets/images/left-arrow.png')} /> </span>
													<span className="name-chat-header"> Notes</span>
													<button onClick={this.addNote} className="btnAddnotes"> Add </button>
												</h6>
											</div>
										</div>
									</div>
									<div className="col-6 col-xl-4 text-right">
										<ul className="nav k-flex justify-content-end">
											<li className="nav-item list-inline-item ml-2">
												<span className="heder-icon-img icon-rightpanel-icon" onClick={() => { this.props.closeChat(); this.closeChat() }}>
													<img alt="" src={require("../../../assets/images/livechat/right-panel-crose.svg")} />
												</span>
											</li>

										</ul>
									</div>
								</div>
							</div>
								<div className="addNoteHight scrollbar">
									<div className="rightPanelMain">
										<div className="chat-his-chat">
											<div className="container-xxl chat-content px-lg-6">
												<div className="py-5 py-lg-5">
													<ul style={{ listStyle: 'none' }}>
														{this.state.noteFound ? (<div>No notes found</div>) : (null)}
														{this.state.allNotes.map((val, i) => {
															let divId = "note-main-" + val.nid;
															let divInnerId = "note-inner-" + val.nid;
															let divEditId = "note-edit-" + val.nid;
															let divtextId = "note-text-" + val.nid;
															var noteId = val.nid;
															var noteVal = this.state.notesMsgs[noteId];
															var editedOn = val.last_updated ? moment.tz(val.last_updated, "x", "UTC").tz(moment.tz.guess(true)).format("h:mm a Do MMM YYYY") : '';
															var editedBy = val.last_updated_agent_name ? "Edited by " + val.last_updated_agent_name + " on " + editedOn : '';
															return <div id={divId} key={i} className="position-relative mb-4 pb-4">
																<div id={divInnerId} className="notesChatNot">
																	<div>
																		<div className="k-flex align-items-center">
																			<p className="notesDate">{moment.tz(val.addedon, "x", "UTC").tz(moment.tz.guess(true)).format("DD/MM/YYYY")}
																				{val.last_updated ? (<span className="position-relative editedBtnTooltip"><span className="btn-edites" data-tip={editedBy}> Edited</span><span className="titleShowHover"><span><h6> {editedBy}</h6></span></span></span>) : (null)}</p>
																			{this.agent.agentId === val.agent_id ? (<div className="nav-item ml-auto" >
																				<a className="text-muted" style={{ cursor: 'pointer' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																					<span className="heder-icon-img icon-rightpanel-icon">
																						<img alt="" src={require('../../../assets/img/icon/table-more-vertical.svg')} />
																					</span>
																				</a>
																				<div className="dropdown-menu dropdown-menu-right" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(1175px, 151px, 0px)' }}>
																					<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }}
																						onClick={() => this.editNote(val.nid)}>
																						Edit
																					</a>
																					<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }}
																						onClick={() => this.deleteNote(val.nid, val.type)}>
																						Delete
																					</a>
																				</div>
																			</div>) : (null)}
																		</div>

																		<p className="notesDescrption">{val.notes}</p>
																		<p className="notesBy">By {val.agent_name} on chat - {val.clientId}</p>
																	</div>
																</div>
																<div id={divEditId} style={{ display: 'none' }}>
																	<div className="input-group material-input mb-6">
																		<TextField multiline rows={4} label="Note" value={noteVal} id={divtextId} type="text" className="form-control w-150" name={divtextId} onChange={(e) => this.textChangeHandle(e, noteId)} variant="outlined" />
																	</div>
																	{/* <textarea className="w-100P text-notes notTextarea" id={divtextId} rows="4" value={noteVal} onChange={(e) => this.textChangeHandle(e, noteId)}> */}
																	{/* {val.notes} */}
																	{/* </textarea> */}
																	<button className="btnBlueSettings mr-4" onClick={() => this.updateNote(val.nid, val.type)}>Update</button>
																	<button className="btnWhiteSettings" onClick={() => this.cancelUpdate(val.nid)}>Cancel</button>
																</div>
															</div>
														})}
													</ul>
												</div>
												<div className="end-of-chat"></div>
											</div>
										</div>
									</div>
								</div></>) : (this.state.showAddNote ? (<><div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
									<div className="row">
										<div className="col-6 col-xl-8">
											<div className="media text-center text-xl-left" style={{ overflow: 'visible' }}>
												<div className="media-body align-self-center text-truncate" style={{ overflow: 'visible' }}>
													<h6 className="text-truncate mb-n1 k-flex" style={{ overflow: 'visible' }}>
														<span style={{ cursor: "pointer" }} onClick={() => this.backToNotesList()}><img alt="" src={require('../../../assets/images/left-arrow.png')} /> </span>
														<span className="name-chat-header"> Add Note</span>
													</h6>
												</div>
											</div>
										</div>
										<div className="col-6 col-xl-4 text-right">
											<ul className="nav k-flex justify-content-end">
												<li className="nav-item list-inline-item ml-2">
													<span className="heder-icon-img icon-rightpanel-icon" onClick={() => { this.props.closeChat(); this.closeChat() }}>
														<img alt="" src={require("../../../assets/images/livechat/right-panel-crose.svg")} />
													</span>
												</li>

											</ul>
										</div>
									</div>
								</div>
									<div className="bodyRightPanel scroll-4">
										<div className="rightPanelMain">
											<div className="chat-his-chat">
												<div className="container-xxl chat-content px-lg-8">
													<div className="py-5 py-lg-5">
														<div>
															<div className="input-group material-input mb-6">
																<TextField multiline rows={4} label="Note" id="add-note" type="text" className="form-control w-150" name="add-note" variant="outlined" />
															</div>
															{/* <textarea className="w-100P notTextarea" id="add-note" rows="4">
															</textarea> */}
															<button className="btnBlueSettings" onClick={() => this.saveNote()}>Add</button>
															<button className="btnWhiteSettings" onClick={() => this.canceladdNote()}>Cancel</button>
														</div>
													</div>
													<div className="end-of-chat"></div>
												</div>
											</div>
										</div>
									</div></>) : (<><div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
										<div className="row">
											<div className="col-6 col-xl-8">
												<div className="media text-center text-xl-left" style={{ overflow: 'visible' }}>
													<div className="avatar avatar-sm d-none d-xl-inline-block mr-5 mt-3">
														<span>{this.props.visitor.client_short}</span>
														{/*<img alt="" src={require("../../../../assets/images/avatars/1.jpg")} className="avatar-img" alt="" /> */}
													</div>
													<div className="media-body align-self-center text-truncate" style={{ overflow: 'visible', width: '0px' }}>
														<h6 className="text-truncate mb-n1 k-flex liveChatHeadreTootltip" style={{ overflow: 'visible' }}>
															<span className="name-chat-header" title={this.props.visitor.name}>{this.props.visitor.name}</span>
															{(country !== "" && operating !== "" && browser !== "") ? (<ul className="maps-all pl-2">
																<li className={"flag-icon-" + country + " flag all-flas"}>
																	<span className="titleShowHover"><h6><strong>Location:</strong> {(typeof (this.props.visitor.geoDetails.country_name) !== 'undefined') ? this.props.visitor.geoDetails.country_name : ""}</h6> <h6><strong>IP:</strong> {(typeof this.props.visitor.geoDetails.country_ip !== 'undefined') ? this.props.visitor.geoDetails.country_ip : ""}</h6></span>
																</li>
																<li className={operating + " os all-flas"}>
																	<span className="titleShowHover"><h6><strong>OS:</strong> {operating} </h6></span>
																</li>
																<li className={browser + " browser all-flas"}>
																	<span className="titleShowHover"><h6><strong>Browser:</strong> {browser} </h6></span>
																</li>
															</ul>) : (null)}
														</h6>
														<small className="text-muted d-block header-text-width text-truncate">{this.props.visitor.email}</small>
														<small className="text-muted d-block header-text-width text-truncate">{this.props.visitor.addedon}</small>
													</div>
												</div>
											</div>

											<div className="col-6 col-xl-4 text-right">
												<ul className="nav k-flex justify-content-end">
													<li className="nav-item list-inline-item d-none d-xl-block position-relative">
														{this.state.groupChatAgents.length > 0 ? (
															<ul className="user-showings">
																{this.state.groupChatAgents.map((agent, index) =>
																	<li key={index}>
																		{this.state.agentsImage[agent.agent_id] !== undefined ?
																			(this.state.agentsImage[agent.agent_id] === '' ?
																				agent.agent_short :
																				(<img alt="" src={this.state.agentsImage[agent.agent_id]} />)) :
																			(this.state.agentsImage['owner'] === '' ?
																				agent.agent_short :
																				(<img alt="" src={this.state.agentsImage['owner']} />))}
																		<div className="user-hover-show chat-body ">
																			<div className="avatar">
																				<span>{agent.agent_short}</span>
																			</div>
																			<h6 className="text-name-hading name-chat-header">{agent.agent_name}</h6>
																		</div>
																	</li>)}
															</ul>
														) : (null)}
													</li>
													{/* <li>
														<span className="heder-icon-img icon-rightpanel-icon">
															<a
																download="info.txt"
																id="downloadlink"
																href={this.state.href}
																onClick={e => this.createFile(e)}
															>
																<span
																	style={{ margin: "-1px" }}
																	className="rightPanelShowHide"
																>
																	<img alt=""
																		style={{ width: '45%', height: '70%' }}
																		src={require("../../../assets/img/icon/down-arrow.svg")}
																	/>
																</span>
															</a>
														</span>
													</li> */}
													<li className="nav-item list-inline-item ml-2">
														<div className="nav-item list-inline-item" >
															<a className="text-muted" style={{ cursor: 'pointer' }} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																<span className="heder-icon-img icon-rightpanel-icon">
																	<img alt="" src={require('../../../assets/img/icon/table-more-vertical.svg')} />
																</span>
															</a>
															<div className="dropdown-menu dropdown-menu-right px-0 py-2" style={{ position: 'absolute', willChange: 'transform', top: 0, left: 0, transform: 'translate3d(1175px, 151px, 0px)' }}>
																<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} download="info.txt"
																	id="downloadlink"
																	href={this.state.href}
																	onClick={e => this.createFile(e)}>
																	Download
																</a>
																<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} href="javascript:void()"
																	onClick={e => this.showvisitorPath()}>
																	View visitor path
																</a>
																<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} href="javascript:void()"
																	onClick={e => this.showNotes()}>
																	View notes
																</a>
																{this.state.isTranslated ? (<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} href="javascript:void()" onClick={e => this.stopTranslateChat(e)}>
																	Stop Translate
																</a>) : (<a className="dropdown-item d-flex align-items-center edit-agent-clik" style={{ cursor: 'pointer' }} href="javascript:void()" onClick={e => this.translateChat(e)}>
																	Translate Chat
																</a>)}
															</div>
														</div>
													</li>

													<li className="nav-item list-inline-item ml-2">
														<span className="heder-icon-img icon-rightpanel-icon" onClick={() => { this.props.closeChat(); this.closeChat() }}>
															<img alt="" src={require("../../../assets/images/livechat/right-panel-crose.svg")} />
														</span>
													</li>

												</ul>
											</div>
										</div>
									</div>
										<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 py-4">
											<div className="input-group material-input">
												<TextField className="form-control" label="Search" variant="outlined" onChange={(e) => this.searchChat(e)} />
												{/* <input type="text" className="form-control form-control-lg" placeholder="Search" aria-label="Search for messages or users..." onChange={(e) => this.searchChat(e)} /> */}

												<div className="input-group-append">
													<button className="btn btn-ico btn-secondary btn-minimal btn-cus waves-effect waves-light" type="submit">
														<svg xmlns="http://www.w3.org/2000/svg" width="20.618" height="21.356" viewBox="0 0 20.618 21.356">
															<path id="search" d="M21.289,19.452l-5.083-5.286a8.619,8.619,0,1,0-6.6,3.079,8.53,8.53,0,0,0,4.94-1.56l5.121,5.326a1.125,1.125,0,1,0,1.621-1.559ZM9.607,2.249A6.373,6.373,0,1,1,3.233,8.623,6.38,6.38,0,0,1,9.607,2.249Z" transform="translate(-0.984)" fill="#000"></path>
														</svg>
													</button>
												</div>
											</div>
										</div>
										<div className="bodyRightPanel scroll-4">
											<div className="rightPanelMain">
												<div className="chat-his-chat chat-history">
													<div className="container-xxl chat-content px-lg-8">
														<div className="py-5 py-lg-5">
															{this.state.chatdetail}
														</div>
														<div className="end-of-chat"></div>
													</div>
												</div>
											</div>
										</div></>)))}

					</div>
				</div>
			</>
		);
	}
}

export default React.memo(Chat);