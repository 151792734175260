import React from 'react';
// import db, {storageConnetion} from "../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment-timezone";
//import {mailUrl} from "../../config/mailFunctions.js";
import Resizer from 'react-image-file-resizer';
import Reactselect from 'react-select'
import {jwtEncrypt} from '../organization/Model/DbModel';
import {getDateInUTC, removeHtmlTags} from "../livechat/components/Comman";
import { addAgentInAws, getUserDetailsFromEmail, editAgentInAws, getOwnerDetails, activityLog } from "../../services/service";
import { addAgentsInList } from "../livechat/components/Dblovefield";
import { DEFAULT_COMMONLOGIN_BLANK_KEYWORD,GATEWAY_URL,FUNCTION_API_KEY} from "../../config/siteConfig";


import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import firebaseServices from '../../firebase';
/* For Dropdown End */

var timezones = require('../../localization/timezones.json');
var md5 = require("md5");
const dateFormat="X";
const PasswordLength = 40;

class AddAgent extends React.Component {
    constructor(props) {
        super();
        this.Agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {} ;
        this.accessToken = localStorage.getItem('api_access_token') ? localStorage.getItem('api_access_token') : false;
        this.timezoneOptions = [{value:"", label: "Select Timezone"}];
        timezones.forEach(values => {
            this.timezoneOptions.push({
                value: values, label: values
            })
        })
        this.state = {
            showAddAgent:'',
            first_name:'',
            last_name:'',
            alias:'',
            email:'',
            department:[],
            role:'',
            timezone:'',
            status:0,
            first_name_error:'',
            last_name_error:'',
            alias_error:'',
            email_error:'',
            department_error:'',
            ownerId:'',
            profile_picture:'',
            role_error:'',
            timezone_error:'',
            departmentList:[],
            roleList:[],
            loader:false,
            base64file:'',
            selectedTimezone:{value:"", label: "Select Timezone"},
            disabled:true,
            userExistInCommon:false,
            agentId:'',
            profile_img_exist:'',
            oldFileName:'',
            oldThumbnailName:'',
            file_error: ''
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        this.setState({
            showAddAgent:this.props.sendAgentStatus,
            ownerId:userDetails.ownerId
        })
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('departments').get().then((docs) => {
            docs.forEach((doc) => {
                var i=0;
                var departmentList=[];
                docs.forEach((doc)=>{
                    departmentList[i] = doc.data();
                    departmentList[i]['id']=doc.id;
                    i++;
                });
                this.setState({
                    departmentList:departmentList
                })
            })
        })
        .catch(err=>{
            setTimeout(()=>{this.componentDidMount()},500)
            
        })
        
        firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('roles').get().then((data) => {
            var i=0;
            var roleList=[];
            data.forEach((dat) => {
                roleList[i] = dat.data();
                roleList[i]['id']=dat.id;
                i++;
            })
            this.setState({
                roleList:roleList
            })
        }).catch(err=>{
            setTimeout(()=>{this.componentDidMount()},500)
            
        })

        // activityLog('Opened add agent page');
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.showAddAgent !== props.sendAgentStatus) {
          return {
            showAddAgent: props.sendAgentStatus
          }
        }
        return null
    }

    hideAddAgent = () => {
        this.props.getAgentStatus(false);
        this.setState({
            name:'',
            first_name:'',
            last_name:'',
            first_name_error:'',
            last_name_error:'',
            alias:'',
            email:'',
            department:'',
            role:'',
            timezone:'',
            status:0,
            name_error:'',
            alias_error:'',
            email_error:'',
            department_error:'',
            profile_picture:'',
            role_error:'',
            timezone_error:'',
            selectedTimezone: {value:"", label: "Select Timezone"},
            base64file:''
        })
    }

    changehandle = (e) => {
        let target = e.target;
        console.log(target.name);
        
        let values;
        if(target.name==="department") {
            values = [].filter.call(target.options, o => o.selected).map(o => o.value);

        } else {
            values = target.value;
            values = removeHtmlTags(values);
        }
        this.setState({
            [target.name]:values
        })
        let errorName = target.name+'_error';
        if(target.name==='first_name' && errorName!=='' && values==='') {
            this.setState({
                [errorName]:'First name should not be empty'
            })
        } else if(target.name==='email' && errorName!=='' && values==='') {
            this.setState({
                [errorName]:'Email should not be empty'
            })
        } else if(target.name==='email' && errorName!=='' && !this.validateEmail(values)) {
            this.setState({
                [errorName]:'Email is incorrect'
            })
        } else if(errorName!=='' && values==='' && target.name!=='alias' && target.name!=='last_name') {
            this.setState({
                [errorName]:'Plese select a '+target.name
            })
        } else {
            this.setState({
                [errorName]:''
            })
        }
    }

    handleEmail = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.value
        })
        if(target.value === '') {
            this.setState({
                email_error:'Email should not be empty'
            })
        } else if(!this.validateEmail(target.value)) {
            this.setState({
                email_error:'Email is incorrect'
            })
        } else {
            this.setState({
                email_error:''
            })
        }
    }

    checkIfEmailExist = (e) => {
        const email = e.target.value;
        var userDetails = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
        if(this.validateEmail(email) && userDetails && email) {
            this.setState({
                loader: true
            })
            getUserDetailsFromEmail(email).then((results) => {
                if(results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        userExistInCommon:true,
                        first_name:_data.first_name ? _data.first_name : '',
                        last_name:_data.last_name && _data.last_name !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? _data.last_name : '',
                        alias:_data.alias && _data.alias !== DEFAULT_COMMONLOGIN_BLANK_KEYWORD ? _data.alias : '',
                        timezone:_data.timezone ? _data.timezone : '',
                        selectedTimezone:_data.timezone ? {value:_data.timezone, label: _data.timezone} : {value:"", label: "Select Timezone"},
                        agentId:_data.preferred_username ? _data.preferred_username : '',
                        disabled:false,
                        profile_img_exist:_data.pic && _data.pic!=='notexist' ? _data.imageurl + '/' + _data.pic : '',
                        oldFileName:_data.pic ? _data.pic : '',
                        oldThumbnailName:_data.thumbnail ? _data.thumbnail : '',
                        loader: false
                    })
                } else {
                    this.setState({
                        userExistInCommon:false,
                        first_name:"",
                        last_name:"",
                        alias:"",
                        timezone:"",
                        selectedTimezone:{value:"", label: "Select Timezone"},
                        agentId:'',
                        profile_img_exist:'',
                        disabled:false,
                        oldFileName:'',
                        oldThumbnailName:'',
                        loader: false
                    })
                }
            })
        }
    }

    addedExistUser = async () => {
        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).get().then(async (docs) => {
            if(docs.exists) {
                toast.error('User already exists in Livechat');
                this.setState({
                    loader:false
                })
            } else {
                if(this.state.alias!=='') {
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("alias", "==", this.state.alias).where("deletetime","==",0).get().then(async (data) => {
                        if(data.empty===true) {
                            let addedon = await getDateInUTC();
                            let shortName = '';
                            let name = this.state.first_name + ' ' + this.state.last_name;
                            let names = name.trim().split(" ");
                            if(names.length>1){
                                shortName=names[0].substring(0,1)+names[1].substring(0,1);
                            } else {
                                shortName=names[0].substring(0,2);
                            }  
                            shortName = shortName.toUpperCase();
                            firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).set({
                                addedon:addedon,
                                agent_short:shortName,
                                name:name,
                                departments:this.state.department,
                                online_status:0,
                                alias:this.state.alias,
                                status:1,
                                role:this.state.role,
                                timezone:this.state.timezone,
                                deletetime:0,
                                refId:this.state.agentId
                            }).then(() => {
                                this.sendEmail(name);
                                this.props.getAgentStatus(false);
                                this.setState({
                                    loader:false
                                })
                                toast.success("Agent has been added successfully.");
                                this.setState({
                                    name:'',
                                    alias:'',
                                    email:'',
                                    department:'',
                                    role:'',
                                    timezone:'',
                                    status:0,
                                    name_error:'',
                                    alias_error:'',
                                    email_error:'',
                                    department_error:'',
                                    profile_picture:'',
                                    role_error:'',
                                    timezone_error:'',
                                    selectedTimezone:{value:"", label: "Select Timezone"},
                                    first_name:'',
                                    last_name:'',
                                    userExistInCommon:false,
                                    agentId:'',
                                    oldFileName:'',
                                    oldThumbnailName:''
                                })
                            }).catch((err) => {
                                console.log('error at line 323 in add agent >>> ', err)
                                this.setState({
                                    loader:false
                                })
                                toast.error("Something went wrong! Please try again after some time");
                            })
                            const filesData1 = document.getElementById("profile_picture_add");
                            var filesData = filesData1.files;
                            let file='';
                            let fileName=''
                            if (filesData.length > 0) {
                                file = filesData[0];
                                fileName = file.name;
                            }
                            editAgentInAws(this.state.alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName);
                        } else {
                            this.setState({
                                loader:false
                            })
                            this.generateAlias();
                        }
                    });
                } else {
                    let addedon = await getDateInUTC();
                    let shortName = '';
                    let name = this.state.first_name + ' ' + this.state.last_name;
                    let names = name.trim().split(" ");
                    if(names.length>1){
                        shortName=names[0].substring(0,1)+names[1].substring(0,1);
                    } else {
                        shortName=names[0].substring(0,2);
                    }  
                    shortName = shortName.toUpperCase();
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(this.state.agentId).set({
                        addedon:addedon,
                        agent_short:shortName,
                        name:name,
                        departments:this.state.department,
                        online_status:0,
                        alias:this.state.alias,
                        status:1,
                        role:this.state.role,
                        timezone:this.state.timezone,
                        deletetime:0,
                        refId:this.state.agentId
                    }).then(() => {
                        this.sendEmail(name);
                        this.props.getAgentStatus(false);
                        this.setState({
                            loader:false
                        })
                        toast.success("Agent has been added successfully.");
                        this.setState({
                            name:'',
                            alias:'',
                            email:'',
                            department:'',
                            role:'',
                            timezone:'',
                            status:0,
                            name_error:'',
                            alias_error:'',
                            email_error:'',
                            department_error:'',
                            profile_picture:'',
                            role_error:'',
                            timezone_error:'',
                            selectedTimezone:{value:"", label: "Select Timezone"},
                            first_name:'',
                            last_name:'',
                            userExistInCommon:false,
                            agentId:'',
                            oldFileName:'',
                            oldThumbnailName:''
                        })
                    }).catch((err) => {
                        console.log('error at line 323 in add agent >>> ', err)
                        this.setState({
                            loader:false
                        })
                        toast.error("Something went wrong! Please try again after some time");
                    })
                    const filesData1 = document.getElementById("profile_picture_add");
                    var filesData = filesData1.files;
                    let file='';
                    let fileName=''
                    if (filesData.length > 0) {
                        file = filesData[0];
                        fileName = file.name;
                    }
                    editAgentInAws(this.state.alias, this.state.first_name, this.state.last_name, this.state.email, this.state.timezone, file, fileName, this.state.oldFileName, this.state.oldThumbnailName);
                }
            }
        }) 
        return false;
    }

    handleSubmit = (e) => {
        e.preventDefault();
        let validate = this.validation();
        if(validate===true) {
            this.setState({
                loader:true
            })
            if(this.state.userExistInCommon && this.state.agentId !== '') {
                this.addedExistUser();
            } else {
                let alias = this.state.alias.trim();
                alias = removeHtmlTags(alias);
                let first_name = this.state.first_name.trim();
                first_name = removeHtmlTags(first_name);
                let last_name = this.state.last_name.trim();
                last_name = removeHtmlTags(last_name);
                this.setState({ alias: alias, first_name: first_name, last_name: last_name });
                if(alias!=='') {
                    firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').where("alias", "==", alias).where("deletetime","==",0).get().then((data) => {
                        if(data.empty===true) {
                                const filesData1 = document.getElementById("profile_picture_add");
                                var filesData = filesData1.files;
                                let file='';
                                let fileName=''
                                if (filesData.length > 0) {
                                    file = filesData[0];
                                    fileName = file.name;
                                }
                                this.saveAgent(file,fileName);
                        } else {
                            this.setState({
                                loader:false
                            })
                            this.generateAlias();
                        }
                    })
                } else {
                    const filesData1 = document.getElementById("profile_picture_add");
                    var filesData = filesData1.files;
                    let file='';
                    let fileName=''
                    if (filesData.length > 0) {
                        file = filesData[0];
                        fileName = file.name;
                    }
                    this.saveAgent(file,fileName);
                }
            }
        }
    }

    generateAlias() {
        this.setState({
            alias_error:'Alias is not available'
        })
    }

    generatePassword = () => {
        var length = PasswordLength,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    async imageUploads(file) {
        const ref = firebaseServices.storageConnection;
        let userName = this.state.name.split(' ').join('_');
        let fileExt = (/[.]/.exec(file.name)) ? /[^.]+$/.exec(file.name) : 'png';
        const name = +new Date() + "-" + userName +'.'+fileExt;
        const metadata = {
          contentType: file.type
        };
        let promise1 =  new Promise(async(res,rej) => {
            const task = ref.child('profiles').child(name).put(file, metadata);
            await task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url => {
                res(url)
                // console.log(url);
            })
            .catch(console.error);
        })

        let promise2 = new Promise(async(resolve,reject) => {
            Resizer.imageFileResizer(
                file,
                64,
                64,
                'PNG',
                100,
                0,
                async uri => {
                        const task = ref.child('profiles').child('avatar').child(name).put(uri, metadata);
                        await task
                        .then(snapshot => snapshot.ref.getDownloadURL())
                        .then(url => {
                            resolve(url);
                        })
                        .catch(console.error);
                    },
                    'blob'
            );
        })

        Promise.all([promise1,promise2]).then(result => {

            this.saveAgent(result[0],result[1],name);
        })
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    saveAgent = async(file,fileName) => {
        let ownerEmails = this.Agent.ownerEmail ? this.Agent.ownerEmail : false;
        if(ownerEmails) {
            if(ownerEmails===this.state.email) {
                toast.error("Email Id already exist!");
                this.setState({
                    loader:false
                }, () => {
                    document.getElementById('email').focus();
                })
                return false;
            } else {
                let newPwd = await this.generatePassword();
                let addedon = await getDateInUTC();
                let tokenDetails = {
                    code:md5(newPwd),
                    email:this.state.email,
                    time: addedon
                }
                let token = await jwtEncrypt(tokenDetails);
                let organizationsAlias = this.Agent.organization_alias ? this.Agent.organization_alias : this.Agent.ownerIdentifire;
                let verificationLink = window.location.origin+"/"+organizationsAlias+"/agent-verification/"+token;
                let alias = this.state.alias.trim() ? this.state.alias.trim() : DEFAULT_COMMONLOGIN_BLANK_KEYWORD;
                addAgentInAws(alias, this.state.first_name, this.state.last_name.trim() ? this.state.last_name.trim() : DEFAULT_COMMONLOGIN_BLANK_KEYWORD, this.state.email, this.state.timezone, file, fileName, verificationLink, newPwd).then(async (results) => {
                    if(parseInt(results.status) === 401) {
                        toast.error(results.msg);
                        this.setState({
                            loader:false
                        })
                        return false;
                    } else if(parseInt(results.status) === 200) {
                        var response = results.response;
                        let shortName = '';
                        let name = this.state.first_name + ' ' + this.state.last_name;
                        name = removeHtmlTags(name);
                        let names = name.trim().split(" ");
                        if(names.length>1){
                            shortName=names[0].substring(0,1)+names[1].substring(0,1);
                        } else {
                            shortName=names[0].substring(0,2);
                        }  
                        shortName = shortName.toUpperCase();
                        let userData = JSON.parse(response.userdata);
                        userData = userData.User.Attributes;
                        let refId = userData.filter(item => item.Name === 'preferred_username').map(item => item.Value);
                        refId = refId[0];
                        let thumbnail = userData.filter(item => item.Name === 'custom:thumbnail').map(item => item.Value)[0];
                        let imageulr = userData.filter(item => item.Name === 'custom:imageurl').map(item => item.Value)[0];
                        let pic = userData.filter(item => item.Name === 'custom:pic').map(item => item.Value)[0];
                        let agentDet = { sub: refId, preferred_username : refId, first_name: this.state.first_name, last_name:this.state.last_name, alias: this.state.alias, pic:pic, imageurl: imageulr, thumbnail:thumbnail, timezone:this.state.timezone, email: this.state.email};
                        firebaseServices.db.collection('users').doc(this.state.ownerId).collection('agents').doc(refId).set({
                            addedon:addedon,
                            agent_short:shortName,
                            name:name,
                            departments:this.state.department,
                            online_status:0,
                            alias:alias,
                            status:1,
                            role:this.state.role,
                            timezone:this.state.timezone,
                            deletetime:0,
                            refId:refId
                        }).then(async (ref) => {
                            this.props.getAgentStatus(false);
                            toast.success("Agent has been created successfully. Please ask agent to check email.");
                            this.setState({
                                name:'',
                                alias:'',
                                email:'',
                                department:'',
                                role:'',
                                timezone:'',
                                status:0,
                                name_error:'',
                                alias_error:'',
                                email_error:'',
                                department_error:'',
                                profile_picture:'',
                                role_error:'',
                                timezone_error:'',
                                selectedTimezone:{value:"", label: "Select Timezone"},
                                first_name:'',
                                last_name:'',
                                userExistInCommon:false,
                                agentId:'',
                                profile_img_exist:'',
                                oldFileName:'',
                                oldThumbnailName:'',
                                base64file:'',
                                loader:false
                            })
                        }).catch((error) => {
                            console.error("Error adding document: ", error);
                        });

                        activityLog('Added agent');
                    } else if(parseInt(results.status) === 400) {
                        toast.error("Email Id already exist!");
                        this.setState({
                            loader:false
                        }, () => {
                            document.getElementById('email').focus();
                        })
                        return false;
                    } else {
                        toast.error("Something went wrong. Please try again after some time");
                        this.setState({
                            loader:false
                        })
                    }
                }).catch((err) => {
                    
                })
            }
        } else {
            toast.error("Something went wrong! Please try again after some time!");
                this.setState({
                    loader:false
                }, () => {
                    // document.getElementById('email').focus();
                })
                return false;
        }
    }

    // company_name, agent_name, agent_email, url, verification_code

    sendAgentVerificationEmail = (opt, name) => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let varificationUrl = window.location.origin + "/" + userDetails.ownerIdentifire + "/agent-verification";
        let company_name = userDetails.ownerIdentifire
        let agent_email = this.state.email.toLowerCase()
        var body = [
            `email=${this.state.email.toLowerCase()}&&verification_code=${opt}&url=${varificationUrl}&type=agent_added&agent_name=${name}&company_name=${company_name}&agent_email=${agent_email}`
        ];
        //fetch(mailUrl+'/sendEmail', {
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key": FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    sendEmail = (name) => {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        let company_name = userDetails.company_name ? userDetails.company_name : "";
        let organization_alias = userDetails.ownerIdentifire ? userDetails.ownerIdentifire : userDetails.organization_alias;
        let agent_email = this.state.email.toLowerCase()
        var body = [
            `email=${this.state.email.toLowerCase()}&type=account_created_existed&agent_name=${name}&company_name=${company_name}&agent_email=${agent_email}&organization_alias=${organization_alias}`
        ];
        //fetch(mailUrl + '/sendEmail', {
        fetch(GATEWAY_URL + '/sendEmail', {
            method: "post",
            body: body,
            headers: { "X-API-Key" : FUNCTION_API_KEY, "Content-Type": "application/x-www-form-urlencoded" }
        })
            .then()
            .then(response => {
                //   console.log(response);
            })
            .catch(e => {
                console.log(e, "ERROR");
            });
    }

    makeid = (length) => {
        let result           = '';
        let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    validation = ()=> {
        let returnValue = true;
        let first_name_error = '' ;
        let email_error = '';
        let department_error = '';
        let role_error = '';
        let timezone_error = '';

        if(this.state.first_name===''){
            returnValue = false;
            first_name_error = 'First name should not be empty'
        } else {
            first_name_error = ''
        }

        if(this.state.email==''){
            returnValue = false;
            email_error = 'Email Should not be empty'
        } else if(!this.validateEmail(this.state.email)){
            returnValue = false;
            email_error = 'Email is incorrect'
        } else {
            email_error = ''
        }

        if(this.state.department.length==0){
            returnValue = false;
            department_error = 'Plese select a department'
        } else {
            department_error = ''
        }

        if(this.state.role==''){
            returnValue = false;
            role_error = 'Plese select a role'
        } else {
            role_error = ''
        }

        if(this.state.timezone==''){
            returnValue = false;
            timezone_error = 'Plese select a timezone'
        } else {
            timezone_error = ''
        }
        
        this.setState({
            first_name_error : first_name_error,
            email_error : email_error,
            department_error : department_error,
            role_error : role_error,
            timezone_error : timezone_error
        })

        return returnValue;

    }

    handleFile = async (files) => {
        this.setState({ 'uploadFileError': '' })
        const tempFile = files[0];
        if(tempFile) {
            var fileExtension = tempFile.name.substring(
                tempFile.name.lastIndexOf('.') + 1).toLowerCase();

            if(fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'jpeg' || fileExtension === 'gif') {
                Resizer.imageFileResizer(
                    tempFile,
                    64,
                    64,
                    'PNG',
                    100,
                    0,
                    uri => {
                        this.setState({
                            base64file:uri,
                            file_error:''
                        })
                    },
                    'base64'
                );
            } else {
                this.setState({
                    file_error : 'Please upload valid image',
                    base64file:''
                })
            }
        } else {
            this.setState({
                file_error : '',
                base64file:''
            })
        }
    }

    validateEmail = email => {
		let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
    };
    
    cancelForm = (e) => {
        e.preventDefault();
        this.hideAddAgent();
    }

    selectTimezone = (e) => {
        console.log(e);
        if(e.value==='') {
            this.setState({
                timezone_error: 'Plese select a timezone'
            })
        } else {
            this.setState({
                timezone_error:'',
            })
        }

        this.setState({
            timezone:e.value,
            selectedTimezone:e
        }, () => {
             console.log(this.state.timezone);
        })
    }

    render() {
        let departmentList = this.state.departmentList.map((value,index) => {
            return <option key={index} value={value.id}>{value.name}</option>
        })
        let rolesList = this.state.roleList.map((value, index) => {
            if(value.name !== 'Owner') {
                return <option key={index} value={value.id}>{value.name}</option>
            }
        })
        return(
            <>
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <div className="rightPanelSection add-agent" style={{display: this.state.showAddAgent ? 'block' : 'none' }}>
                <div className="categoryRightPanel">
                    <div className="rightPanelHeader categoryRightPanelHeader bg-lightblack px-4 py-4">
                        <div className="right-panel-header k-flex align-items-center justify-content-center">
                            <h4 className="header-title heding-text text-truncate mr-auto white-text">Add Agent</h4>
                            <ul className="heder-icon">
                                <li className="nav-item list-inline-item closeing-r">
                                    <span className="heder-icon-img" onClick={this.hideAddAgent}>
                                        <img src={ require('../../assets/img/icon/right-panel-crose.svg') } alt="" />
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="bodyRightPanel">
                        <div className="rightPanelMain">
                            <div className="agent-form py-5">
                                <form onSubmit={this.handleSubmit}>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="email" label="Email" variant="outlined" name="email" value={this.state.email} onChange={this.handleEmail} onBlur={this.checkIfEmailExist} autoComplete="off" />
                                        {/* <label className="mb-1 small text-screen1">Email</label>
                                        <input type="text" placeholder="Email" className="w-100" autoComplete="off" name="email" id="email" value={this.state.email} onChange={this.handleEmail} /> */}
                                        { this.state.email_error ? (<p className="error">{this.state.email_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="first_name" label="First Name*" variant="outlined" name="first_name" value={this.state.first_name} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" />
                                        {/* <label className="mb-1 small text-screen1">First Name</label>
                                        <input type="text" placeholder="First name*" className="w-100" name="first_name" value={this.state.first_name} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" /> */}
                                        { this.state.first_name_error ? (<p className="error">{this.state.first_name_error}</p>) : (null) }
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="last_name" label="Last Name" variant="outlined" name="last_name" value={this.state.last_name} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" />
                                       {/*  <label className="mb-1 small text-screen1">Last Name</label>
                                        <input type="text" placeholder="Last name" className="w-100" name="last_name" value={this.state.last_name} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" /> */}
                                        {this.state.last_name_error ? (<p className="error">{this.state.last_name_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <TextField className="form-control w-100" id="alias" label="Alias" variant="outlined" name="alias" value={this.state.alias} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" />
                                        {/* <label className="mb-1 small text-screen1">Alias</label>
                                        <input type="text" placeholder="Alias" className="w-100" name="alias" value={this.state.alias} onChange={this.changehandle} disabled={this.state.disabled} autoComplete="off" /> */}
                                        {this.state.alias_error ? (<p className="error">{this.state.alias_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">
                                        <input type="file" accept="image/*" id="profile_picture_add" name="profile_picture" onChange={(e) => this.handleFile(e.target.files)} disabled={this.state.disabled} style={{display : "none"}} />
                                        {this.state.profile_img_exist !== '' && this.state.base64file === '' ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                            <span><img src={this.state.profile_img_exist} /></span>
                                        </div>) : (null)}
                                        {this.state.base64file!=='' ? (<div className="avatar avatar-sm d-xl-inline-block mt-2">
                                            <span><img src={this.state.base64file} alt="" /></span>
                                        </div>) : null}
                                        <Button variant="contained" onClick={() => { document.getElementById("profile_picture_add").click() }} color="default" startIcon={<CloudUploadIcon />}>Upload Profile Picture</Button>
                                        {this.state.file_error ? (<p className="error">{this.state.file_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="input-group material-input">

                                        <FormControl className="MuiFormControl-root MuiTextField-root form-control w-100 mb-2">
                                            <InputLabel shrink htmlFor="select-multiple-native">Team</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true}} labelId="select-multiple-native" id="select-multiple-department" label="Department" name="department" disabled={this.state.disabled} value={this.state.department?this.state.department:""} onChange={this.changehandle}
                                            inputProps={{
                                                id: 'select-multiple-native',
                                            }}
                                            multiple
                                            native
                                            >
                                                {departmentList}
                                            </Select>
                                        </FormControl>

                                        {/* <label className="mb-1 small text-screen1">Team</label>
                                        <select name="department" multiple className="custom-select cstm-addagent" value={this.state.department} disabled={this.state.disabled} onChange={this.changehandle}>
                                            {departmentList}
                                        </select> */}
                                        {this.state.department_error ? (<p className="error">{this.state.department_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="material-select">
                                        <FormControl variant="outlined" className="material-outlined w-100">
                                            <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
                                            <Select MenuProps={{ disableScrollLock: true}} labelId="demo-simple-select-outlined-label" value={this.state.role?this.state.role:""} id="role" name="role" onChange={this.changehandle} label="Role">
                                                <MenuItem value="">Select Role</MenuItem>
                                                {this.state.roleList.map((value, index) => {
                                                    if(value.name !== 'Owner') {
                                                        return <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                                                    }
                                                })}
                                            </Select>
                                        </FormControl>
                                       {/*  <label className="mb-1 small text-screen1">Role</label>
                                        <select name="role" className="customArrow" value={this.state.role} disabled={this.state.disabled} onChange={this.changehandle}>
                                            <option value="">Select Role</option>
                                            {rolesList}
                                        </select> */}
                                        {this.state.role_error ? (<p className="error">{this.state.role_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <label className="mb-1 small text-screen1">Timezone</label>
                                        <Reactselect name="timezone" options={this.timezoneOptions} placeholder="Select Timezone" onChange={this.selectTimezone} isDisabled={this.state.disabled} value={this.state.selectedTimezone} />
                                        {this.state.timezone_error ? (<p className="error">{this.state.timezone_error}</p>) : (null)}
                                    </div>
                                </div>
                                <div className="form-feilds px-5 py-4">
                                    <div className="wrapperInfo">
                                        <button className="mr-2 btnBlueSettings">Add</button>
                                        <button className="btnWhite" onClick={this.cancelForm}>Cancel</button>
                                    </div>
                                </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default AddAgent;