import React from 'react';
// import db from '../../config/firebase';
import { MAIL_FUNCTION_URL,GATEWAY_URL,FUNCTION_API_KEY} from '../../config/siteConfig';
import { toast, ToastContainer } from 'react-toastify';
import Device from 'react-device';
import { activityLog } from '../../services/service';
import firebaseServices from '../../firebase';
class DeactivatedAccount extends React.Component {
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            deactivated: true,
            myBrowser: '',
            OS: '',
            loader: false,
            ownerEmail : '',
            ownerName : '',
            companyName : '',
            organization_alias : ''
        }
    }

    componentDidMount () {
        if(this.agent.ownerId) {
            firebaseServices.db.collection('users').doc(this.agent.ownerId).onSnapshot((docs) => {
                if(docs.exists) {
                    let _data = docs.data();
                    if(_data.deactivated === true) {

                    } else {
                        toast.success("Your account is activated");
                        setTimeout(() => {
                            window.location.href = "/"+this.agent.ownerIdentifire;
                        }, 3000)
                    }
                }
            })
            activityLog("Opened deactivated account page");
        }
    }

    confirmReactivateAccount = () => {
        this.setState({
            loader: true
        })
        let ownerEmail = this.agent.ownerEmail ? this.agent.ownerEmail : this.agent.email;
        let ownerName = this.agent.agent_name ? this.agent.agent_name : this.agent.name;
        let companyName = this.agent.company_name ? this.agent.company_name : 'NA';
        var body = [`ownerId=${this.agent.ownerId}&ownerEmail=${ownerEmail.toLowerCase()}&browser=${this.state.myBrowser}&OS=${this.state.OS}&ownerName=${ownerName}&companyName=${encodeURIComponent(companyName)}`]
        //fetch(MAIL_FUNCTION_URL+"/loginApi/reactivateAccount", {
        fetch(GATEWAY_URL+"/reactivateAccount", {
            method:"post",
            body:body,
            headers : {"X-API-Key" : FUNCTION_API_KEY,"Content-type": "application/x-www-form-urlencoded" }
        })
        .then(response => response.json())
        .then(response => {
            if(response.status===true) {

            } else {
                toast.error("Something went wrong, please try again after some time!");
                this.setState({
                    loader:false
                })
            }
        });
    }

    onChangee = (deviceInfo) => {
        this.setState({
            UA:deviceInfo.ua,
            myBrowser:deviceInfo.browser.name,
            OS:deviceInfo.os.name
        })
    }

    render () {
        return (
            <>
            <ToastContainer autoClose={5000} />
            <Device onChange={this.onChangee} />
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
                <h3 className="heding-text">Account Deactivated</h3>
                {/* <div className="mobile-menu ml-auto aa" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div> */}
            </header>
            <div className="page-wrapper" data-mobile-height="">
                <div className="main-wrapper container-fluid">
                    <div className="main-heading main-heading-title py-5">
                    </div>
                    <div className="body-section bg-white px-5 py-5">
                        <div className="agent-section " id="resposnive-table-class">
                            <div className="row">
                                {
                                    this.agent.roleType === 'Owner' ? (<div className="popupBody py-5 px-5 my-4 mx-4">
                                    <div className="pb-5 feilds-instruction">
                                        <p className="card-body-text pb-4 text-black">Your account is deactivated. Do you want to reactivate your account?</p>
                                    </div>
                                    <div>
                                        <button className="btnBlueSettings mr-2" onClick={this.confirmReactivateAccount}>Yes</button>
                                        <button className="btnWhiteSettings" onClick={this.cancelReactivateAccount}>No</button>
                                    </div>
                                </div>) : (<div className="popupBody py-5 px-5 my-4 mx-4">
                                    <div className="pb-5 feilds-instruction">
                                        <p className="card-body-text pb-4 text-black">Your account is deactivated. Ask owner to reactivate your account.</p>
                                    </div>
                                </div>)
                                }
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            </>
        )
    }
}

export default DeactivatedAccount;