import React from 'react';
import LeftPanel from "./views/LeftPanel";
// import db from '../../config/firebase';
import { toast } from 'react-toastify';
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';
import firebaseServices from '../../firebase';
const dateFormat="X";

class Agentnotifications extends React.Component{
    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        this.state = {
            loader:false,
            incoming_desktop_notification:'',
            incoming_play_sound:'',
            new_message_desktop_notification:'',
            new_message_play_sound:'',
            payment_status:true
        }
    }

    componentDidMount() {
        this.getValues();
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status:  _data.payment_status !== undefined  ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
    }

    getValues = () => {
        this.setState({
            loader:true
        })
        var notDetails = firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).onSnapshot((docs) => {
            if(docs.exists) {
                let data = docs.data();
                this.setState({
                    incoming_desktop_notification: (data.incoming_desktop_notification !== "undefined" && typeof data.incoming_desktop_notification !== "undefined") ? data.incoming_desktop_notification : true,
                    incoming_play_sound: (data.incoming_play_sound !== "undefined" && typeof data.incoming_play_sound !== "undefined") ? data.incoming_play_sound : true,
                    new_message_desktop_notification: (data.new_message_desktop_notification !== "undefined" && typeof data.new_message_desktop_notification !== "undefined")  ? data.new_message_desktop_notification : true,
                    new_message_play_sound: (data.new_message_play_sound !== "undefined" && typeof data.new_message_play_sound !== "undefined")  ? data.new_message_play_sound : true,
                    loader:false
                })
                notDetails();
            }
        });
    }

    handleChange = (e) => {
        let target = e.target;
        this.setState({
            [target.name]:target.checked
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.saveForm();
    }

    saveForm = () => {
        this.setState({
            loader:true
        });
        let updatedon = this.timeZoneFormat(Date.now());
        firebaseServices.db.collection('users').doc(this.agent.ownerId).collection('agents').doc(this.agent.agentId).update({
            incoming_desktop_notification: this.state.incoming_desktop_notification,
            incoming_play_sound: this.state.incoming_play_sound,
            new_message_desktop_notification: this.state.new_message_desktop_notification,
            new_message_play_sound: this.state.new_message_play_sound,
            updatedon: updatedon
        }).then(() => {
            this.setState({
                loader:false
            })
            toast.success("Notification and Sound details have been updated! Please logout and Login to work properly.");
        }).catch((err) => {
            console.log(err);
        })
    }

    timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
    }

    cancelForm = (e) => {
        e.preventDefault();
        this.getValues();
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render () {
        let mainClass = "page-wrapper sidebar-collapse";
        if(localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent') {
            // mainClass = "page-wrapper";
        }
        // let showLeftPanel = localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent' ? false : true;
        let showLeftPanel = localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent' ? true : true;
        return (
            <>
            <ReactTooltip />
            { showLeftPanel === true ? (<LeftPanel />) : (null) }
            <div className="loading" style={{display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.getValues}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
           {this.state.payment_status? <div className={mainClass} data-mobile-height=""> {/* page-wrapper sidebar-collapse */}
                <div className="main-wrapper container-fluid">
                    <div className="chatform-Preview">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
                                    <div className="bg-white left-widgetsetings-box mb-foter-Fixed">
                                        <form onSubmit={this.handleSubmit} >
                                            <div className="setting-section idle-section pb-1">
                                                <div className="mx-8 mob-mx-4">
                                                    <div className="main-heading main-heading-title py-5">
                                                        <h3 className="heding-text">My Notification & Sound</h3>
                                                    </div>
                                                </div>
                                                
                                                <div className="settings-form-feilds py-5 mx-8 px-5 notification-settings">
                                                    <div className="setting-notification-sound mb-4">
                                                        <h4 className="heding-text">For incoming chats</h4>
                                                    </div>
                                                    <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                        <span className="main-checkbox-all">
                                                            <label className="containerCheckBox containerafterchecked">
                                                                <input type="checkbox" name="incoming_desktop_notification" value={this.state.incoming_desktop_notification} onChange={this.handleChange} checked={this.state.incoming_desktop_notification} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </span>
                                                        <h6 className="card-title mb-0">Show desktop notification for incoming chat<span className="info-icon pl-2">
                                                            <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="On selectiing this option one will be able to see desktop notifications for all incoming chats." />
                                                        </span></h6>
                                                        
                                                    </div>
                                                    <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                        <span className="main-checkbox-all">
                                                            <label className="containerCheckBox containerafterchecked">
                                                                <input type="checkbox" checked={this.state.incoming_play_sound} name="incoming_play_sound" value={this.state.incoming_play_sound} onChange={this.handleChange} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </span>
                                                        <h6 className="card-title mb-0">Play sound for new incoming chat<span className="info-icon pl-2">
                                                        <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="On selecting this option one will be able to hear sound for each incoming chat." />
                                                        </span></h6>
                                                        
                                                    </div>
                                                </div>
                                                <div className="settings-form-feilds py-5 mx-8 px-5 notification-settings">
                                                    <div className="setting-notification-sound mb-4">
                                                        <h4 className="heding-text">For new messages received</h4>
                                                    </div>
                                                    <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                        <span className="main-checkbox-all">
                                                            <label className="containerCheckBox containerafterchecked">
                                                                <input type="checkbox" name="new_message_desktop_notification" value={this.state.new_message_desktop_notification} onChange={this.handleChange} checked={this.state.new_message_desktop_notification} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </span>
                                                        <h6 className="card-title mb-0">Show desktop notification for new messages received.<span className="info-icon pl-2">
                                                        <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="On selecting this option one will be able to see desktop notifictions for all new messages received from visitors." />
                                                        </span></h6>
                                                        
                                                    </div>
                                                    <div className="settings-feld form-feilds k-flex align-items-center mb-4">
                                                        <span className="main-checkbox-all">
                                                            <label className="containerCheckBox containerafterchecked">
                                                                <input type="checkbox" name="new_message_play_sound" value={this.state.new_message_play_sound} onChange={this.handleChange} checked={this.state.new_message_play_sound} />
                                                                <span className="checkmark"></span>
                                                            </label>
                                                        </span>
                                                        <h6 className="card-title mb-0">Play sound for new message received<span className="info-icon pl-2">
                                                        <img alt="" src={ require('../../assets/img/icon/info.png') } data-tip="On selecting this option one will be able to hear sound for each message received." />
                                                        </span></h6>
                                                        
                                                    </div>
                                                </div>
                                                
                                                
                                            </div>                                                                        
                                                <div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed notif-view">
                                                    <div className="text-center k-flex align-items-center">
                                                        <button className="btnBlueSettings mr-2">Save changes</button>
                                                        <button className="btnBorderSettings" onClick={this.cancelForm}>Cancel</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> : <PopUp />}
        </>
        )
    }
}

export default Agentnotifications;