import React from "react";
import LeftPanel from "./views/LeftPanel";
// import db from "../../config/firebase";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import ReactTooltip from "react-tooltip";
import { activityLog } from "../../services/service";

import TextField from '@material-ui/core/TextField';

/* For Dropdown Start */
import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { getOwnerSnapShot } from "../../services/payment";
import PopUp from "../../layout/components/popup";
import firebaseServices from "../../firebase";
import FroalaEditor from "react-froala-wysiwyg";
const siteConfig = require("../../config/siteConfig");
/* For Dropdown End */

const dateFormat = "X";

class Gdpr extends React.Component {
	constructor() {
		super();
		this.state = {
			loader: false,
			consent_from: '',
			consent_content: '',
			privacy_link: '',
			privacy_text: '',
			button_text: '',
			consent_from_error: false,
			consent_content_error: false,
			privacy_link_error: false,
			privacy_text_error: false,
			button_text_error: false,
			ownerId: '',
			payment_status: true
		}
		this.editorRef = React.createRef();
	}

	componentDidMount() {
		let userDetails = JSON.parse(localStorage.getItem('agent'));
		this.setState({
			ownerId: userDetails.ownerId,
			loader: true
		})
		var gdValues = firebaseServices.db.collection('users').doc(userDetails.ownerId).collection('gdpr').doc('DEFAULT').onSnapshot((docs) => {
			if (docs.exists === true) {
				let data = docs.data();
				this.setState({
					consent_content: data.consent_content,
					consent_from: data.consent_from,
					privacy_link: data.privacy_link,
					privacy_text: data.privacy_text,
					button_text: data.button_text,
					loader: false
				})
			} else {
				this.setState({
					loader: false
				})
			}
			gdValues();
		})
		if (userDetails.ownerId) {
			getOwnerSnapShot(userDetails.ownerId, async (results) => {
				if (results.status === 200) {
					let _data = results.data;
					this.setState({
						payment_status: _data.payment_status !== undefined ? true : false
					})

				} else {
					this.setState({
						payment_status: false
					})

				}
			})
		}
		activityLog("Opened gdpr consent page");
	}

	componentDidUpdate(prevProps, prevState) {
    // Check if the editorEnabled state has changed
    if (this.state.consent_from !== prevState.consent_from) {
      this.updateEditorState();
    }
  }
	updateEditorState() {
    // Enable or disable the editor based on the state
    if (this.editorRef.current) {
      if (this.state.consent_from !== 'none') {
        this.editorRef.current.editor.edit.on();
      } else {
        this.editorRef.current.editor.edit.off();
      }
    }
  }

	handleChange = (e) => {
		let target = e.target;
		this.setState({
			[target.name]: target.value
		})
		if (target.name === 'consent_from' && target.value === 'none') {
			this.setState({
				consent_from_error: false,
				consent_content_error: false,
				privacy_link_error: false,
				privacy_text_error: false,
				button_text_error: false,
			})
		}
	}

	handleModelChange = (model) => {
		this.setState({
			consent_content: model
		});
	}
	handleSUbmit = (e) => {
		e.preventDefault();
		this.saveForm();
	}

	saveForm = () => {
		let validate = this.validateForm();
		console.log("validate", validate);	
		if (validate === true) {
			this.setState({
				loader: true
			})
			let updatedon = this.timeZoneFormat(Date.now());
			firebaseServices.db.collection("users").doc(this.state.ownerId).collection('gdpr').doc('DEFAULT').set({ updatedon: updatedon, consent_from: this.state.consent_from, consent_content: this.state.consent_content, privacy_link: this.state.privacy_link, privacy_text: this.state.privacy_text, button_text: this.state.button_text }).then((ref) => {
				this.setState({
					loader: false
				}, () => {
					console.log("Consents details have been Updated!");
					toast.success("Consents details have been Updated!");
					activityLog("Updated gdpr consent");
				})
				// console.log(ref);
			}).catch((err) => {
				console.log(err);
			})
		}
	}

	timeZoneFormat = (date) => {
		var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
		todayUTCDate = parseInt(todayUTCDate);
		return todayUTCDate;
	}

	validateForm = (e) => {
		let valReturn = true;
		if (this.state.consent_from === 'none') {
			return valReturn;
		}
		if (this.state.consent_content === '') {
			valReturn = false;
			this.setState({
				consent_content_error: true
			})
		} else {
			this.setState({
				consent_content_error: false
			})
		}

		// if (this.state.privacy_link === '') {
		// 	valReturn = false;
		// 	this.setState({
		// 		privacy_link_error: 'Field cannot be left blank'
		// 	})
		// } else if (this.isUrlValid(this.state.privacy_link) === false) {
		// 	valReturn = false;
		// 	this.setState({
		// 		privacy_link_error: 'Invalid URL'
		// 	})
		// } else {
		// 	this.setState({
		// 		privacy_link_error: false
		// 	})
		// }

		// if (this.state.privacy_text === '') {
		// 	valReturn = false;
		// 	this.setState({
		// 		privacy_text_error: true
		// 	})
		// } else {
		// 	this.setState({
		// 		privacy_text_error: false
		// 	})
		// }

		if (this.state.button_text === '') {
			valReturn = false;
			this.setState({
				button_text_error: true
			})
		} else {
			this.setState({
				button_text_error: false
			})
		}

		return valReturn;

	}

	isUrlValid = (url) => {
		var res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
		if (res === null)
			return false;
		else
			return true;
	}

	discardChanges = (e) => {
		e.preventDefault();
		this.cancelForm();
	}

	cancelForm = () => {
		this.setState({
			consent_from_error: false,
			consent_content_error: false,
			privacy_link_error: false,
			privacy_text_error: false,
			button_text_error: false
		})
		this.componentDidMount();
	}


	sideMenuePhone = () => {
		var sideMenuePhone = document.getElementById('sidebarnavChild');
		sideMenuePhone.classList.toggle('sideMenue')
	}


	render() {
		console.log("consent", this.state);
		let consent = this.state.consent_from;
		let config_off = {
			initialized() {
				const editor = this;
				console.log("editor on", editor);
				
					editor.edit.off();
					
			}
		}
		let config_onn = {
			initialized() {
				const editor = this;
				console.log("editor on", editor);
				
					editor.edit.on();
				
			}
		}
		return (
			<>
				<ReactTooltip />
				<LeftPanel />
				<div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>
				<header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
					<h3 className="heding-text">Settings</h3>
					<div className="text-center k-flex align-items-center ml-auto">
						<button className="btnBlueSettings mr-2 py-2 px-6 d-none" onClick={this.saveForm}>Save changes</button>
						<button className="btnBorderSettings mr-2 py-2 px-4 d-none" onClick={this.cancelForm}>Cancel</button>
					</div>
					<div className="mobile-menu" onClick={this.sideMenuePhone}>
						<svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
					</div>
				</header>
				{this.state.payment_status ? <div className="page-wrapper sidebar-collapse" data-mobile-height="">
					<div className="main-wrapper container-fluid">
						<div className="chatform-Preview">
							<div className="container-fluid">
								<div className="row">
									<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 my-8">
										<div className="bg-white left-widgetsetings-box mb-foter-Fixed">
											<div className="setting-section pb-1">
												<div className="mx-8">
													<div className="main-heading main-heading-title py-5">
														<h3 className="heding-text">User Consent</h3>
													</div>
												</div>
												<form onSubmit={this.handleSUbmit}>
													<div className="settings-form-feilds mb-8 mx-8">
														<div className="row">
															<div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
																<div className="settings-feld k-flex align-items-center mb-2">
																	<h6 className="card-title mb-0">Ask consent from</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Select the region whose visitors you want to provide consent before starting to chat." />
																	</span>
																</div>
																<div className="material-select">
																	<FormControl variant="outlined" className="material-outlined w-150">
																		{/* <InputLabel id="consent_from-select-outlined-label"></InputLabel> */}
																		<Select MenuProps={{ disableScrollLock: true }} labelId="consent_from-select-outlined-label" id="consent_from-select-outlined" name="consent_from" onChange={this.handleChange} value={this.state.consent_from}>
																			<MenuItem value="Europe">Visitors from Europe</MenuItem>
																			<MenuItem value="North America">Visitors from North America</MenuItem>
																			<MenuItem value="South America">Visitors from South America</MenuItem>
																			<MenuItem value="Asia">Visitors from Asia</MenuItem>
																			<MenuItem value="Australia">Visitors from Australia</MenuItem>
																			<MenuItem value="none">No one</MenuItem>
																			<MenuItem value="all">Every one</MenuItem>
																		</Select>
																	</FormControl>
																	{/* <select className="customArrow" name="consent_from" value={this.state.consent_from} onChange={this.handleChange}>
                                                                        <option value="Europe">Visitors from Europe</option>
                                                                        <option value="North America">Visitors from North America</option>
                                                                        <option value="South America">Visitors from South America</option>
                                                                        <option value="Asia">Visitors from Asia</option>
                                                                        <option value="Australia">Visitors from Australia</option>
                                                                        <option value="none">No one</option>
                                                                        <option value="all">Every one</option>
                                                                    </select> */}
																</div>
															</div>
														</div>
													</div>
													<div className="settings-form-feilds mb-8 mx-8">
														<div className="row">
															<div className="col-xs-12 col-sm-12 col-md-12 form-feilds">
																<div className="settings-feld k-flex align-items-center mb-2">
																	<h6 className="card-title mb-0">Consent content</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Here you can edit the consent which you want to display to visitors." />
																	</span>
																	<p className="text-danger pl-4" style={{ "display": this.state.consent_content_error ? 'block' : 'none' }}>
																		<span className="pr-2">
																			<img alt="" src={require('../../assets/img/icon/warning.svg')} />
																		</span>Field cannot be left blank
																	</p>
																</div>
																<div className="input-group material-input">
																	{/* <TextField className="form-control w-100" rows={6} id="outlined-multiline" value={this.state.consent_content} variant="outlined" name="consent_content" onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false} multiline /> */}
																		<FroalaEditor
																			tag="textarea"
																			config={{
																				placeholderText: this.state.consent_content,
																				heightMin: 80,
																				heightMax: 100,
																				width: 1000,
																				fontSizeUnit: "pt",
																				attribution: false,
																				key: `${siteConfig.FROALAEDITORKEY}`,
																				direction: "ltr",
																				wordPasteKeepFormatting: true,
																				enter: FroalaEditor.ENTER_DIV,
																				enter: FroalaEditor.ENTER_BR,
																				tableStyles: {
																					"no-border": "No border"
																				},
																				useClasses: false,
																				toolbarSticky: false,
																				charCounterCount: false,
																				paragraphFormatSelection: true,
																				linkInsertButtons: [],
																				toolbarButtons: {
																					moreText:{buttons:["bold",
																					"italic",
																					"underline", "insertLink"]},
																					moreParagraph: {
																						buttons: [
																							"align",
																							"paragraphFormat",
																							"paragraphStyle",
																							"lineHeight",
																							"quote",
																						],
																						buttonsVisible: 1,
																					}},
																				linkList: [],
																				readOnly : this.state.consent_from === 'none' ? true : false,
																				// events: this.state.consent_from === 'none' ? config_off : config_onn
																				
																			}}
																			model={this.state.consent_content}
																			onModelChange={this.handleModelChange}
																			ref = {this.editorRef}
																		/>{console.log("consent", this.state.consent_from)}
																	{/* <textarea type="time" rows="6" className="w-100" name="consent_content" value={this.state.consent_content} placeholder="Consent content..." onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false}></textarea> */}
																</div>
															</div>
														</div>
													</div>
													{/* <div className="settings-form-feilds mb-8 mx-8">
														<div className="row">
															<div className="col-xs-12 col-sm-6 col-md-6 form-feilds mb-8">
																<div className="settings-feld k-flex align-items-center mb-2">
																	<h6 className="card-title mb-0">Privacy policy link</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Here you need to add the link of the privacy policy page which will appear in the chat widget" />
																	</span>
																	<p className="text-danger pl-4" style={{ "display": this.state.privacy_link_error ? 'block' : 'none' }}>
																		<span className="pr-2">
																			<img alt="" src={require('../../assets/img/icon/warning.svg')} />
																		</span>{this.state.privacy_link_error}
																	</p>
																</div>
																<div className="input-group material-input">
																	<TextField className="form-control w-100" id="privacy_link-basic" variant="outlined" value={this.state.privacy_link} name="privacy_link" placeholder="Privacy policy link" onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false} />
																	{/* <input type="text" rows="6" className="w-100" name="privacy_link" value={this.state.privacy_link} placeholder="Privacy policy link" onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false} /> */}
																{/* </div>
															</div>
															<div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
																<div className="settings-feld k-flex align-items-center mb-2">
																	<h6 className="card-title mb-0">Privacy policy link text</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Here you need to add the text of the privac page which will appear in the chat widget." />
																	</span>
																	<p className="text-danger pl-4" style={{ "display": this.state.privacy_text_error ? 'block' : 'none' }}>
																		<span className="pr-2">
																			<img alt="" src={require('../../assets/img/icon/warning.svg')} />
																		</span>Field cannot be left blank
																	</p>
																</div>
																<div className="input-group material-input">
																	<TextField className="form-control w-100" id="privacy_text-basic" variant="outlined" value={this.state.privacy_text} name="privacy_text" onChange={this.handleChange} placeholder="Privacy policy link text" disabled={this.state.consent_from === 'none' ? true : false} /> */}
																	{/* <input type="text" rows="6" className="w-100" name="privacy_text" value={this.state.privacy_text} placeholder="Privacy policy link text" onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false} /> */}
																{/* </div>
															</div>
														</div>
													</div> */} 
													<div className="settings-form-feilds mb-8 mx-8">
														<div className="row">
															<div className="col-xs-12 col-sm-6 col-md-6 form-feilds">
																<div className="settings-feld k-flex align-items-center mb-2">
																	<h6 className="card-title mb-0">Consent button text</h6>
																	<span className="info-icon pl-2">
																		<img alt="" src={require('../../assets/img/icon/info.png')} data-tip="Here you need to add text for the consent button." />
																	</span>
																	<p className="text-danger pl-4" style={{ "display": this.state.button_text_error ? 'block' : 'none' }}>
																		<span className="pr-2">
																			<img alt="" src={require('../../assets/img/icon/warning.svg')} />
																		</span>Field cannot be left blank
																	</p>
																</div>
																<div className="input-group material-input">
																	<TextField className="form-control w-100" id="button_text-basic" variant="outlined" value={this.state.button_text} name="button_text" onChange={this.handleChange} placeholder="Yes, I agree" disabled={this.state.consent_from === 'none' ? true : false} />
																	{/* <input type="text" rows="6" className="w-100" name="button_text" value={this.state.button_text} placeholder="Yes, I agree" onChange={this.handleChange} disabled={this.state.consent_from === 'none' ? true : false} /> */}
																</div>
															</div>
														</div>
													</div>
													<div className="chat-feilds py-6 px-8 btn-chat-feilds setting-footer-fixed">
														<div className="text-center k-flex align-items-center">
															<button className="btnBlueSettings mr-2">Save changes</button>
															<button className="btnBorderSettings" onClick={this.discardChanges}>Cancel</button>
														</div>
													</div>
												</form>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div> : <PopUp />}
			</>
		)
	}
}

export default Gdpr;