import React from 'react';
import { NavLink } from 'react-router-dom';
// import db from '../../../config/firebase';
import { getDateInUTC } from '../../livechat/components/Comman';
import moment from "moment-timezone";
import { isMobileDevice } from '../../../modules/livechat/components/Comman';
import { toast } from "react-toastify";
import firebaseServices from '../../../firebase';
class LeftPanel extends React.Component {

    constructor() {
        super();
        this.agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
        console.log(this.agent)
        this.state = {
            payment_status: false,
            ownerSnapshot: "",
            showCannedResp: false,
            showHandsOffChatTranscript: false
        }
    }

    componentDidMount() {
        if (this.agent.ownerId) {
            this.state.ownerSnapshot = firebaseServices.db.collection('users').doc(this.agent.ownerId).onSnapshot(async (docs) => {
                if (docs.exists) {
                    let _data = docs.data();
                    var agent2 = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : false;
                    if (agent2.canned_response !== _data.canned_response) {
                        agent2.canned_response = _data.canned_response;
                        localStorage.setItem('agent', JSON.stringify(agent2));
                    }
                    let paymentStatus = false;
                    if (_data.payment_status === 'complete') {
                        paymentStatus = true;
                    } else {
                        let nextBillingDate = _data.next_billing_date ? _data.next_billing_date : false;
                        if (nextBillingDate) {
                            let serverTimeStamp = await getDateInUTC();
                            let splitDate = nextBillingDate.split('-');
                            let splitDay = parseInt(splitDate[2]);
                            var paymentDate = moment(splitDate[1] + "/" + splitDay + "/" + splitDate[0] + "0:00", "M/D/YYYY H:mm").valueOf();
                            paymentDate = paymentDate + 86400000;
                            if (paymentDate < serverTimeStamp) {
                                paymentStatus = false;
                            } else {
                                paymentStatus = true;
                            }
                        } else {
                            paymentStatus = false;
                        }
                    }
                    this.setState({
                        payment_status: paymentStatus,
                        showCannedResp: _data.canned_response === 1 ? true : false,
                        showHandsOffChatTranscript: _data.connectHubspotEnabled ? _data.connectHubspotEnabled : false
                    })
                } else {
                    this.setState({
                        payment_status: false,
                        showCannedResp: false,
                        showHandsOffChatTranscript: false
                    })
                }
            })
        }
    }

    componentWillUnmount() {
        this.state.ownerSnapshot();
    }

    mobileRemovC = () => {
        if (isMobileDevice()) {
            var mobMenuD = document.getElementById("respMobiMenu");
            mobMenuD.classList.remove("mobileAddCNew");
            //Responsive Main site
            var respMain = document.getElementById("maintoproutediv");
            respMain.classList.remove("responMaineDes");
        }

    }

    render() {
        let showLeftPanel = localStorage.getItem("userRole") && localStorage.getItem("userRole") === 'Agent' ? false : true;
        return (
            <div className="sidebarnavChild" id="sidebarnavChild">
                <div className="tab-content h-100" role="tablist">
                    <div className="tab-pane fade h-100 show active" id="tab-content-dialogs" role="tabpanel">
                        <div className="d-flex flex-column h-100">
                            <div className="hide-scrollbar"> 
                            <div className="left-Menu-notlivechat py-2 drop-scroll">
                                <ul>
                                    {showLeftPanel ? (<li onClick={this.mobileRemovC} className={!this.state.payment_status? "disabled" :""}><NavLink to={"/"+this.agent.ownerIdentifire+"/chatsettingform"} activeClassName="active"> Chat Survey</NavLink></li>) : (null)}
                                    {this.agent.roleType==="Owner" ? (<li><NavLink to={"/"+this.agent.ownerIdentifire+"/data-management"} activeClassName="active"> Data Management</NavLink></li>) : (null)}
                                    {showLeftPanel ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/idle-time"} activeClassName="active"> Timeout Settings</NavLink></li>) : (null)}
                                    {showLeftPanel ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/install-widget"} activeClassName="active"> Install widget</NavLink></li>) : (null)}
                                    {/* <li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/test-setup"} activeClassName="active"> Test Setup</NavLink></li> */}
                                    {showLeftPanel ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/widgetsetting"} activeClassName="active"> Widget Settings</NavLink></li>) : (null)}
                                    {this.agent.triggers_status===1 && showLeftPanel && (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/triggers"} activeClassName="active"> Triggers</NavLink></li>)}
                                    {showLeftPanel ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/gdpr-consent"} activeClassName="active">  User Consent</NavLink></li>) : (null)}
                                    <li onClick={this.mobileRemovC}><NavLink to={"/" + this.agent.ownerIdentifire + "/canned-text"} activeClassName="active">Canned Text</NavLink></li>
                                    {/* {showLeftPanel ? (<li onClick={this.mobileRemovC}><NavLink to={"/" + this.agent.ownerIdentifire + "/previous-chat"} activeClassName="active"> Previous Chat</NavLink></li>) : (null) } */}
                                    {showLeftPanel && this.agent.roleType==="Owner"? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/notifications"} activeClassName="active"> Notifications</NavLink></li>) : (null)}
                                    <li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/my-notifications"} activeClassName="active">My Notifications</NavLink></li>
                                    {this.agent.roleType==="Owner" ? (<li><NavLink to={"/"+this.agent.ownerIdentifire+"/email-preference"} activeClassName="active"> Email Preference</NavLink></li>) : (null)}
                                    {this.agent.roleType === "Owner" ? (<li><NavLink to={"/" + this.agent.ownerIdentifire + "/ticketing"} activeClassName="active"> Ticket Settings</NavLink></li>) : (null)}
                                    {this.agent.roleType==="Owner" && this.agent.phone_call === 1 ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/phone-numbers"} activeClassName="active">Phone Numbers</NavLink></li>) : (null)}
                                    <li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/banned-users"} activeClassName="active">Banned users</NavLink></li>
                                    {/* {this.agent.roleType==="Owner" ? (<li onClick={this.mobileRemovC}><NavLink to={"/"+this.agent.ownerIdentifire+"/subscription"} activeClassName="active"> Subscription</NavLink></li>) : (null)} */}
                                    {/* {this.agent.roleType==="Owner" && this.state.payment_status === true ? (<li><NavLink to={"/"+this.agent.ownerIdentifire+"/call-text-settings"} activeClassName="active">Call and text</NavLink></li>) : (null)} */}
                                    {/* {this.state.showCannedResp == true ? ( // commented for 1494  */}
                                    {/* ) : (null)} */}
                                    {this.agent.roleType==="Owner" && this.state.showHandsOffChatTranscript ? (<li><NavLink to={"/" + this.agent.ownerIdentifire + "/manage-webhooks"} activeClassName="active">Manage Webhooks</NavLink></li>) : (null)}
                                </ul>  
                            </div>  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default LeftPanel;
