import React, { PureComponent } from 'react';
import Basicsettings from './view/basicSettings';
// import Advancesettings from './view/advanceSettings';
import LeftPanel from './view/LeftPanel'
import { getOwnerSnapShot } from '../../services/payment';
import PopUp from '../../layout/components/popup';

class Widgetcustamization extends PureComponent {
    constructor() {
        super();
        this.state = {
            themeColor:{ r: 10, g: 10, b: 10, a: 1 },
            basicSettings:true,
            advancesettings:false,
            widgetImage:'',
            widgetImages:[],
            brandImage:'',
            brandImages:[],
            submitForm:false,
            cancelForm:false,
            payment_status:true
        }
    }

    componentDidMount() {
        let userDetails = JSON.parse(localStorage.getItem('agent'));
        if(userDetails.ownerId) {
            getOwnerSnapShot(userDetails.ownerId, async (results) => {
                if (results.status === 200) {
                    let _data = results.data;
                    this.setState({
                        payment_status: _data.payment_status !== undefined ? true : false
                    })
                    
                } else {
                    this.setState({
                        payment_status: false
                    })
                    
                }
            })
        }
    }

    submitForm = (e) => {
        this.setState({
            submitForm:true
        })
    }

    updateValues = () => {
        this.setState({
            submitForm:false,
            cancelForm:false
        })
    }

    cancelForm = (e) => {
        this.setState({
            cancelForm:true
        })
    }

    getThemeColor = (color) => {
        this.setState({
            themeColor:color
        })
        console.log(color);
    }

    updateCurrentPage = (setting) => {
        this.setState({
            basicSettings:false,
            advancesettings:true
        })
    }

    getwidgetImage = (image) => {
        this.setState({
            widgetImage:image
        })
    }

    getWidgetImages = (images) => {
        this.setState({
            widgetImages:images
        })
    }

    getBrandImage = (image) => {
        this.setState({
            brandImage:image
        })
    }

    getBrandImages = (images) => {
        this.setState({
            brandImages:images
        })
    }

    updatePrePage = () => {
        this.setState({
            basicSettings:true,
            advancesettings:false
        })
    }

    sideMenuePhone = () => {
        var sideMenuePhone = document.getElementById('sidebarnavChild');
        sideMenuePhone.classList.toggle('sideMenue')
    }

    render() {
        return (<>
            <header className="topbar  py-3 px-4 header-heding k-flex align-items-center justify-content-center">
                <h3 className="heding-text">Settings</h3>
                <div className="text-center k-flex align-items-center ml-auto">
                    <button className="btnBlueSettings mr-2 py-2 px-6" onClick={this.submitForm}>Save changes</button>
                    <button className="btnBorderSettings mr-2 py-2 px-4" onClick={this.cancelForm}>Cancel</button>
                </div>
                <div className="mobile-menu" onClick={this.sideMenuePhone}>
                    <svg id="open-menu" xmlns="http://www.w3.org/2000/svg" width="26.073" height="18.83" viewBox="0 0 26.073 18.83"><path id="Path_2519" data-name="Path 2519" d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -114.339)" /><path id="Path_2520" data-name="Path 2520" d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" /><path id="Path_2521" data-name="Path 2521" d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0" transform="translate(0 -228.674)" /></svg>
                </div>
            </header>
            <LeftPanel />
           {this.state.payment_status? <Basicsettings getColor={this.getThemeColor} settings={this.state.basicSettings} updateSettings={this.updateCurrentPage} widgetImages={this.getWidgetImages} widgetImage={this.getwidgetImage} brandImages={this.getBrandImages} brandImage={this.getBrandImage} cancelForm={this.state.cancelForm} updateValues={this.updateValues} submitForm={this.state.submitForm} /> : <PopUp />}
            {/* <Advancesettings themeColor={this.state.themeColor} settings={this.state.advancesettings} updateSettings={this.updatePrePage} widgetImages={this.state.widgetImages} widgetImage={this.state.widgetImage} brandImages={this.state.brandImages} brandImage={this.state.brandImage} /> */}
        </>); 
    }
}

export default Widgetcustamization;