
import firebaseServices from "../../firebase";
import {validation } from "./../../config/SignupLogin";
// import firebase from "./../../config/firebase";
import moment from "moment-timezone";

export const checkIsUserOrOwnerLogin = async () => {
	
    let userDetails = JSON.parse(localStorage.getItem('agent'));
     let commonUserDetails = JSON.parse(localStorage.getItem('userdata'));
     
    if (userDetails && userDetails.ownerId) {
		
        var res = await validation(userDetails.ownerId);
        if (res.valid) {
            /**
             * admin login 
             */
            // this.props.history.push("/");
            //window.location = '/'
            window.location.href= "/"+userDetails.ownerIdentifire;
            return true;
        } else {
            // check if agent login the redierct to inner page
            if (userDetails !== null && userDetails !== undefined) {
                window.location.href = "/" + userDetails.ownerIdentifire;
                return true
            } else {
                return false
            }
        }
    }
    else
    {
		/* added by ruchi for common login*/
			return "COMMONLOGIN";
	}
     
}



export const checkIsOwnerLogin = async () => {
    let userDetails = JSON.parse(localStorage.getItem('agent'));
    let commonUserDetails = JSON.parse(localStorage.getItem('userdata'));
    // alert("aaaaaaaaaaaaaaaaaaaaa");
    if (userDetails && userDetails.ownerId) {
        var res = await validation(userDetails.ownerId);
        if (res.valid) {
            const uid = userDetails.ownerId            
            let agentcRef = await firebaseServices.db.collection('users').doc(`${uid}`).get()
                            .then(documentSnapshot => {
                                if(documentSnapshot.exists ){
                                    return documentSnapshot.data()
                                }
                                return false;

                            })
                            .catch(err=>{
                                console.log(err)
                                return false
                        })
                 return agentcRef
        } else {
            return false
        }
    } 
    else if (commonUserDetails && commonUserDetails._id) {
		/* added by ruchi for common login*/
			return "COMMONLOGIN";
	}
}



/**
 * localtion
 */
export const checkInitialOwnerWithCompanyNameAndAgentName = async () => {
	let userDetails = JSON.parse(localStorage.getItem('agent'));
    let commonUserDetails = JSON.parse(localStorage.getItem('userdata'));
    if (userDetails && userDetails.ownerId) {
        var res = await validation(userDetails.ownerId);
        if (res.valid) {
                 return 'OWNER'
        } else {

            return "AGENT"
        }
    }
    else if (commonUserDetails && commonUserDetails._id) {
		/* added by ruchi for common login*/
			return "COMMONLOGIN";
	}
    else{
        return false;
    } 
}

/**
 * getUtc timestamp 
 */
export const getUTCTimeDate = () => {

    let date = Date.now();
    const dateFormat='X';
	var todayUTCDate = moment.tz(date, "X", moment.tz.guess(true)).tz("UTC").format(dateFormat);
	todayUTCDate = parseInt(todayUTCDate);
    return todayUTCDate;
    
}

/**
 * Cleant A string
 */
export const cleanupString = (stringItem) =>  {
        var specialcharacters = stringItem.trim().replace(/[^\w\s]/gi, '')
      return specialcharacters.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-"); 
      //this.trim(); //this.trim().toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-");
}
