import React, { useState } from "react";
import LeftPanel from "../views/LeftPanel";
import TextField from "@material-ui/core/TextField";

/* For Dropdown Start */
import FormControl from "@material-ui/core/FormControl";
//import InputLabel from '@material-ui/core/InputLabel';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
// import db from "../../../config/firebase";
import { NotificationContainer, NotificationManager } from "react-notifications";
import firebaseServices from "../../../firebase";

var ownerId = localStorage.getItem("agent")
  ? JSON.parse(localStorage.getItem("agent")).ownerId
  : "";

const HandsOffChatTranscript = () => {
  const [listChatoff, setlistChatoff] = useState({});
  const [record, setRecord] = useState([0]);
  const [loader, setLoader] = useState(true);
  const [HubspotURL, SetHubspotURL] = useState([]);
  const [HubspotURL0, SetHubspotURL0] = useState([]);
  React.useEffect(() => {
    firebaseServices.db.collection("users")
      .doc(ownerId)
      .get()
      .then((result) => {
        console.log("----1-->>", result.data().connectHubspotURLS);
        SetHubspotURL(result.data().connectHubspotURLS);
        SetHubspotURL0(result.data().connectHubspotURLS.length);
        setLoader(false)

        if (typeof result.data().connectHubspotURL == "undefined") {
          firebaseServices.db.collection("users").doc(ownerId).update({
            connectHubspotURL: [],
          });
        } else {
          console.log("----2-->>", result.data().connectHubspotURL);
        }
      });
  }, []);
  const addList = () => {
    const recd = [...HubspotURL];
    recd.push("");
    SetHubspotURL(recd);
  };

  const delList = (i) => {
    const delrecd = [...HubspotURL];
    delrecd.splice(i, 1);
    SetHubspotURL(delrecd);
  };
  const UpdateHandoff = () => {
    setLoader(true)
    var urlupdate = [];
    var handoffvalue = document.querySelectorAll(".handoffurl"); //document.getElementsByClassName('handoffurl');
    handoffvalue.forEach((element) => {
      console.log("----", element.value);
      if (element.value){
      urlupdate.push(element.value);
      }
    });

    console.log("- - - - - - -> >.  >  >", HubspotURL);
    SetHubspotURL(urlupdate);
    firebaseServices.db.collection("users").doc(ownerId).update({
      connectHubspotURLS: urlupdate,
    }).then(()=>{
        setLoader(false)
        NotificationManager.success("", 'Webhooks are successfully added.', 2000);

    })
    .catch(err=>{
        NotificationManager.error("", 'Webhook could not be added. Please try again later.', 2000);

        setLoader(false)

    })
    // if(HubspotURL.length == 0 )
    // {console.log("- - - -rerewr- - -> >.  >  >",HubspotURL)

    //     firebaseServices.db.collection('users').doc(ownerId).update(
    //         {
    //         'connectHubspotURLS':urlupdate
    //           }
    //      );

    // }else{
    //     console.log("---d-f------->>>");
    // }

    return;

    firebaseServices.db.collection("users")
      .doc(ownerId)
      .get()
      .then((result) => {
        // if(result.data().connectHubspotURLS)

        if (result.data().connectHubspotURLS == "undefined") {
        } else {
          console.log("---result ", result.data().connectHubspotURLS);
          firebaseServices.db.collection("users").doc(ownerId).update({
            connectHubspotURLS: urlupdate,
          });
        }
      });
  };

  return (
    <>
      <header className="topbar  py-3 px-4 header-heding k-flex align-items-center">
      <NotificationContainer />
        <h3 className="heding-text">Manage Webhooks</h3>
        <div className="mobile-menu ml-auto d-none">
          <svg
            id="open-menu"
            xmlns="http://www.w3.org/2000/svg"
            width="26.073"
            height="18.83"
            viewBox="0 0 26.073 18.83"
          >
            <path
              id="Path_2519"
              data-name="Path 2519"
              d="M24.986,124.841H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
              transform="translate(0 -114.339)"
            />
            <path
              id="Path_2520"
              data-name="Path 2520"
              d="M24.986,2.173H1.086A1.086,1.086,0,0,1,1.086,0h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
            />
            <path
              id="Path_2521"
              data-name="Path 2521"
              d="M24.986,247.5H1.086a1.086,1.086,0,0,1,0-2.173h23.9a1.086,1.086,0,0,1,0,2.173Zm0,0"
              transform="translate(0 -228.674)"
            />
          </svg>
        </div>
      </header>
      <LeftPanel />
      <div className="page-wrapper sidebar-collapse" data-mobile-height="">
        <div className="main-wrapper container-fluid">
          <div className="chatform-Preview">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="main-heading main-heading-title py-5">
                    <h3 className="heding-text">Settings</h3>
                  </div>
                  <div className="bg-white left-widgetsetings-box mb-foter-Fixed px-5 pb-5">
                    <div className="dataManagement-section idle-section all-setting-bg-section-feilds px-5 py-5 mob-card">
                      <div className="chatOffHeadingReviewTitle py-4">
                        <h5 className="card-title mb-1">Manage Webhooks</h5>
                        <p className="card-body-text mb-0">
                          You can create webhooks by pasting URL Link in the box
                          provided to export the Chat transcripts.
                        </p>
                      </div>
                    </div>
                    {loader?<div className="loading" style={{ display:  'block' }}>Loading&#8230;</div>:
                    <div className="handOffFeildsrow">
                    {console.log(record,HubspotURL,"------>>>>>HUBSPOT")}
                    <div>
                              {HubspotURL.map((value, index) => {
                                
                                return (
                                  <div className="handOffFeilds">
                                    <div className="insertHandofink d-flex align-items-center">
                                      <input
                                        type="text"
                                        className="handoffurl"
                                        placeholder="Insert your Link Here"
                                        defaultValue={value?value:""}
                                      />

                                      {HubspotURL.length==1?null:
                                      <span
                                  className="d-inline-block pl-4 cursor-pointer"
                                  onClick={() => delList(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="20"
                                    height="20"
                                    viewBox="0 0 21.936 26.999"
                                  >
                                    <path
                                      id="Icon_metro-bin"
                                      data-name="Icon metro-bin"
                                      d="M24.748,10.365V27.24a1.692,1.692,0,0,1-1.687,1.687H7.874A1.692,1.692,0,0,1,6.186,27.24V10.365ZM19.686,25.553h1.687V13.74H19.686Zm-3.375,0H18V13.74H16.311Zm-3.375,0h1.687V13.74H12.936Zm-3.375,0h1.687V13.74H9.561ZM5.764,5.3h5.484V3.194a1.269,1.269,0,0,1,1.266-1.266H18.42a1.269,1.269,0,0,1,1.266,1.266V5.3H25.17a1.269,1.269,0,0,1,1.266,1.266V8.678H4.5V6.569A1.269,1.269,0,0,1,5.764,5.3Zm7.172,0H18V3.637H12.936Z"
                                      transform="translate(-4.5 -1.928)"
                                      fill="#2680eb"
                                    />
                                  </svg>
                                </span>
                                }
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                      {/* {record.map((row, i) => {
                        // if (i == 0) {
                          return (
                            
                          );
                        // }
                        //  else {
                        //   return (
                        //     <div className="handOffFeilds">
                        //       <div className="insertHandofink d-flex align-items-center">
                        //         <input
                        //           type="text"
                        //           className="handoffurl"
                        //           placeholder="Insert your Link Here"
                        //         />
                        //         <span
                        //           className="d-inline-block pl-4 cursor-pointer"
                        //           onClick={() => delList(i)}
                        //         >
                        //           <svg
                        //             xmlns="http://www.w3.org/2000/svg"
                        //             width="20"
                        //             height="20"
                        //             viewBox="0 0 21.936 26.999"
                        //           >
                        //             <path
                        //               id="Icon_metro-bin"
                        //               data-name="Icon metro-bin"
                        //               d="M24.748,10.365V27.24a1.692,1.692,0,0,1-1.687,1.687H7.874A1.692,1.692,0,0,1,6.186,27.24V10.365ZM19.686,25.553h1.687V13.74H19.686Zm-3.375,0H18V13.74H16.311Zm-3.375,0h1.687V13.74H12.936Zm-3.375,0h1.687V13.74H9.561ZM5.764,5.3h5.484V3.194a1.269,1.269,0,0,1,1.266-1.266H18.42a1.269,1.269,0,0,1,1.266,1.266V5.3H25.17a1.269,1.269,0,0,1,1.266,1.266V8.678H4.5V6.569A1.269,1.269,0,0,1,5.764,5.3Zm7.172,0H18V3.637H12.936Z"
                        //               transform="translate(-4.5 -1.928)"
                        //               fill="#2680eb"
                        //             />
                        //           </svg>
                        //         </span>
                        //       </div>
                        //     </div>
                        //   );
                        // }
                      })} */}

                      {/* {record.map((row, i)=>{
                                            if(i==0){
                                                return(
                                                    <div className="handOffFeilds">
                                                        
                                                        <div className="insertHandofink d-flex align-items-center">
                                                            <input type="text" className="handoffurl" placeholder="Insert your Link Here" />
                                                           
                                                           
                                                        </div>
                                                      
                                                    </div>
                                                ) 
                                            }else{
                                                return(
                                                    <div className="handOffFeilds">
                                                        
                                                        <div className="insertHandofink d-flex align-items-center">
                                                            <input type="text" className="handoffurl"  placeholder="Insert your Link Here" />
                                                            <span className="d-inline-block pl-2 cursor-pointer" onClick={()=>delList(i)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="21.936" height="26.999" viewBox="0 0 21.936 26.999">
                                                                    <path id="Icon_metro-bin" data-name="Icon metro-bin" d="M24.748,10.365V27.24a1.692,1.692,0,0,1-1.687,1.687H7.874A1.692,1.692,0,0,1,6.186,27.24V10.365ZM19.686,25.553h1.687V13.74H19.686Zm-3.375,0H18V13.74H16.311Zm-3.375,0h1.687V13.74H12.936Zm-3.375,0h1.687V13.74H9.561ZM5.764,5.3h5.484V3.194a1.269,1.269,0,0,1,1.266-1.266H18.42a1.269,1.269,0,0,1,1.266,1.266V5.3H25.17a1.269,1.269,0,0,1,1.266,1.266V8.678H4.5V6.569A1.269,1.269,0,0,1,5.764,5.3Zm7.172,0H18V3.637H12.936Z" transform="translate(-4.5 -1.928)" fill="#2680eb" />
                                                                </svg>
                                                            </span>
                                                          
                                                           
                                                        </div>
                                                        
                                                    </div>
                                                )
                                            }
                                            
                                        })} */}
                      <div className="d-flex pl-6 align-items-center webhooks">
                        <button
                          className="mr-5 btnBlueSettings"
                          onClick={() => {
                            UpdateHandoff();
                          }}
                        >
                          Submit
                        </button>
                        <button
                          className="mr-5 btnBorderSettings"
                          onClick={addList}
                        >
                          Add new
                        </button>
                        {/* <span className="pl-2 cursor-pointer" onClick={addList}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21.6"
                            height={27}
                            viewBox="0 0 21.6 27"
                          >
                            <path
                              id="Icon_material-note-add"
                              data-name="Icon material-note-add"
                              d="M19.5,3H8.7A2.7,2.7,0,0,0,6.014,5.7L6,27.3A2.7,2.7,0,0,0,8.686,30H24.9a2.708,2.708,0,0,0,2.7-2.7V11.1Zm2.7,18.9H18.15v4.05h-2.7V21.9H11.4V19.2h4.05V15.15h2.7V19.2H22.2Zm-4.05-9.45V5.025l7.425,7.425Z"
                              transform="translate(-6 -3)"
                              fill="#2680eb"
                            />
                          </svg>
                        </span> */}
                      </div>
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HandsOffChatTranscript;
