import React from 'react';
// import db from '../../../config/firebase';
import "@lottiefiles/lottie-player";
import firebaseServices from '../../../firebase';

class Thankyou extends React.Component{

    constructor() {
        super();
        this.agent = JSON.parse(localStorage.getItem('agent'));
        this.w = window.innerWidth;
        this.h = window.innerHeight;
        this.state={
            successMessage : true,
            filePath: window.location.origin+'/lf20_sTumYD.json'
        }
    }

    componentDidMount() {
        firebaseServices.db.collection('users').doc(this.agent.ownerId).get().then((docs) => {
            if(docs.exists) {
                let data = docs.data();
                if(data.verified === true && data.widget_status === true) {
                    this.setState({ successMessage : true });
                }
            }
        });
    }

    hideSuceesMessage = () => {
       window.location.href = '/'+this.agent.ownerIdentifire;
       return false;
    }


    render(){
        return(
            <>
                <div className="loading" style={{ display: this.state.loader ? 'block' : 'none' }}>Loading&#8230;</div>


                <div className="page-wrapper sidebar-collapse hide-scroll-bottom">
                    <div className="main-wrapper container-fluid">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div class="congratulation-overlay"></div>
                                        <div className="popupInstallLivechat popupLivechat congratulationPopup congratulationPopup2 bg-img" style={{ display: this.state.successMessage ? 'block' : 'none' }}>
                                            <lottie-player
                                                autoplay
                                                mode="normal"
                                                src={this.state.filePath}
                                                style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    left: 0,
                                                    width: "100%",
                                                    height: "100%"
                                                }}
                                            />

    
                                            <div className="popupBody pb-5 px-5 text-center">
                                                <div className="feilds-instruction">
                                                    <h1 className="card-title mb-0">Congratulation!</h1>
                                                    <span className="congratilation-circle my-5">
                                                        <svg id="like" xmlns="http://www.w3.org/2000/svg" width="108.606" height="123.57" viewBox="0 0 108.606 123.57">
                                                            <path id="Path_2459" data-name="Path 2459" d="M242.024,148.406a7.262,7.262,0,0,0-7.24-7.24,7.24,7.24,0,0,0,0-14.481H205.1c4.416-13.25,4.344-17.715,4.344-25.824a10.826,10.826,0,0,0-7.24-10.209,10.034,10.034,0,0,0-3.62-.652H196.41a3.744,3.744,0,0,0-3.476,2.751l-2.027,7.892c-3.041,11.8-12.816,26.042-24.907,26.042v50.683l16.146,5.358a36.092,36.092,0,0,0,11.44,1.883h33.958a7.24,7.24,0,1,0,0-14.481h7.24a7.24,7.24,0,0,0,0-14.481A7.262,7.262,0,0,0,242.024,148.406Z" transform="translate(-133.418 -68.279)" fill="#fedbab" />
                                                            <path id="Path_2460" data-name="Path 2460" d="M329.962,184.608a7.24,7.24,0,0,0,0-14.481h7.24a7.24,7.24,0,1,0,0-14.481,7.24,7.24,0,1,0,0-14.481,7.24,7.24,0,1,0,0-14.481H307.517c4.416-13.25,4.344-17.715,4.344-25.824a10.826,10.826,0,0,0-7.24-10.209A10.034,10.034,0,0,0,301,90v94.608Z" transform="translate(-235.836 -68.279)" fill="#fec478" />
                                                            <g id="Group_2111" data-name="Group 2111">
                                                                <path id="Path_2461" data-name="Path 2461" d="M56.341,284.4H34.62a3.618,3.618,0,0,1-3.62-3.62V215.62A3.618,3.618,0,0,1,34.62,212H56.341A10.873,10.873,0,0,1,67.2,222.861v50.683A10.873,10.873,0,0,1,56.341,284.4Z" transform="translate(-31 -160.834)" fill="#1689fc" />
                                                                <ellipse id="Ellipse_42" data-name="Ellipse 42" cx="3.927" cy="3.491" rx="3.927" ry="3.491" transform="translate(13.993 102.107)" fill="#1689fc" />
                                                                <path id="Path_2462" data-name="Path 2462" d="M293.24,3.62v7.24a3.62,3.62,0,0,1-7.24,0V3.62a3.62,3.62,0,0,1,7.24,0Z" transform="translate(-224.456)" fill="#1689fc" />
                                                                <path id="Path_2463" data-name="Path 2463" d="M196.717,50.836,191.6,45.717a3.62,3.62,0,0,1,5.119-5.119l5.119,5.119a3.62,3.62,0,0,1-5.119,5.119Z" transform="translate(-152.033 -29.995)" fill="#1689fc" />
                                                            </g>
                                                            <g id="Group_2112" data-name="Group 2112" transform="translate(65.164)">
                                                                <path id="Path_2464" data-name="Path 2464" d="M361.313,50.836a3.619,3.619,0,0,1,0-5.119l5.119-5.119a3.62,3.62,0,0,1,5.119,5.119l-5.119,5.119A3.619,3.619,0,0,1,361.313,50.836Z" transform="translate(-345.952 -29.995)" fill="#136ef1" />
                                                                <path id="Path_2465" data-name="Path 2465" d="M304.62,3.62v7.24a3.585,3.585,0,0,1-3.62,3.62V0A3.585,3.585,0,0,1,304.62,3.62Z" transform="translate(-301)" fill="#136ef1" />
                                                            </g>
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div className="pb-5 feilds-instruction">
                                                    <h4 className="card-title mb-0">Your livechat is now live.</h4>
                                                </div>
                                                <div>
                                                    <button className="btnBlueSettings px-10 py-4" onClick={this.hideSuceesMessage}>Join Chat</button>
                                                </div>
                                            </div>
                                            </div>
                                    </div>
                    </div>
                </div>



                
            </>
        );
    }
}

export default Thankyou;