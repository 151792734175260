import React from 'react';
import { MAIL_FUNCTION_URL, DOMAIN_NAME, GATEWAY_URL, FUNCTION_API_KEY } from "../../config/siteConfig";
import qs from 'query-string';

class CbInstaMessenger extends React.Component {

    agent = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : {};
    constructor() {
        super();
        this.state = {
            loader: true,
            error: ""
        }
    }

    componentDidMount = () => {
        let params = qs.parse(this.props.location.search);
        console.log("----------------- Instagram callback  params  --------------------");
        console.log(params);
        if (params.code) {
            let domain_name = DOMAIN_NAME;
            if (window.location.hostname === "localhost") {
                domain_name = "https://localhost:3000";
            }
            let cbUrl = encodeURIComponent(domain_name + "/cb-insta-messenger/pagesetup");
            let body22 = [`ownerId=${encodeURIComponent(this.agent.ownerId)}&code=${params.code}&cbUrl=${cbUrl}`];
            //fetch(MAIL_FUNCTION_URL + "/facebook/ig_verifyuser", {
            fetch( GATEWAY_URL + "/ig_verifyuser", {
                method: "POST",
                body: body22,
                headers: {
                    "X-API-Key" : FUNCTION_API_KEY,
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Accept": "application/json",
                    "Origin": window.location.origin
                }
            }).then(res => res.json()).then(async (response) => {
                if (response.status === "success") {
                    window.close();
                } else {
                    alert('Something went wrong, Please try again after some time!');
                    window.close();
                    return false;
                }
            });
        } else {
            console.log("-----------    Else came   --------------");
            if(params.error === "access_denied") {
                alert('Permissions denied by the user!');
            } else {
                alert('Something went wrong, Please try again after some time!');
            }
            window.close();
            return false;
        }
    }

    render() {
        return <></>;
    }
}

export default CbInstaMessenger;